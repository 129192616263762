import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useFieldArray } from 'react-hook-form'
import * as z from 'zod'
import axios from '@/api/axiosConfig'
import { toast } from 'sonner'
import { Plus, Minus, X } from 'lucide-react'
import { cn } from '@/lib/utils'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogBody,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import { Badge } from '@/components/ui/badge'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs'
import { Separator } from '@/components/ui/separator'
import { ScrollArea } from '@/components/ui/scroll-area'
import { AssigneeSelection } from '@/modals/AssigneeSelection'
import { ANT_COLORS } from '@/configs/colors'
import { IconSelector } from '@/taskboards/custom/components/icon-selector'

import { useUsers } from '@/contexts/UserContext'

interface CreateBoardModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  currentUser: any;
}

interface AccessUser {
  user_id: string;
  is_admin: boolean;
  user: {
    user_id: string;
    name: string;
    surname: string;
    avatar_urls: string[];
  };
}

const formSchema = z.object({
  name: z.string().min(1, 'Please enter a board name'),
  description: z.string().optional(),
  is_public: z.boolean().default(true),
  board_type: z.enum(['taskboard', 'project']).default('taskboard'),
  board_config: z.object({
    requires_deal: z.boolean().default(false),
    requires_work_date: z.boolean().default(false),
    requires_due_date: z.boolean().default(false),
    show_in_my_work: z.boolean().default(true),
    enable_time_tracking: z.boolean().default(true),
    enable_subtasks: z.boolean().default(false),
    simplify_main_tasks: z.boolean().default(false),
    enable_automations: z.boolean().default(false),
    enable_in_flows: z.boolean().default(false),
    icon: z.string().default('FolderKanban'),
    kanban_source: z.string().default('progress'),
    progress_status_options: z.array(z.object({
      label: z.string(),
      color: z.string(),
      description: z.string().optional().nullable(),
      is_completed_state: z.boolean().optional()
    })),
    board_status_options: z.array(z.object({
      label: z.string(),
      color: z.string(),
      description: z.string().optional().nullable()
    })).optional(),
  })
})

interface FieldArrayItem {
  id: string;
  label: string;
  color: string;
}

type StatusOption = {
  label: string;
  color: string;
};

const CreateBoardModal = ({
  open,
  onClose,
  onSuccess,
  currentUser,
}: CreateBoardModalProps) => {
  const navigate = useNavigate()
  const [isPublic, setIsPublic] = useState(true)
  const [showAssigneeModal, setShowAssigneeModal] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [adminUsers, setAdminUsers] = useState<string[]>([])
  const [useBoardStatus, setUseBoardStatus] = useState(false)
  const [kanbanSource, setKanbanSource] = useState('progress')

  const { users } = useUsers();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      is_public: true,
      board_type: 'taskboard',
      board_config: {
        requires_deal: false,
        requires_work_date: false,
        requires_due_date: false,
        show_in_my_work: true,
        enable_time_tracking: true,
        enable_subtasks: false,
        simplify_main_tasks: false,
        enable_automations: false,
        enable_in_flows: false,
        icon: 'FolderKanban',
        kanban_source: 'progress',
        progress_status_options: [
          { label: 'Not Started', color: '#ff4d4f' },
          { label: 'In Progress', color: '#ffa940' },
          { label: 'Completed', color: '#52c41a' }
        ],
        board_status_options: []
      }
    }
  })

  const progressStatusArray = useFieldArray({
    control: form.control,
    name: 'board_config.progress_status_options'
  });

  const boardStatusArray = useFieldArray({
    control: form.control,
    name: 'board_config.board_status_options'
  });

  const handleAssigneeSave = (users: string[]) => {
    setSelectedUsers(users);
    // Ensure admin users are still in the selected users list
    setAdminUsers(prev => prev.filter(id => users.includes(id)));
    setShowAssigneeModal(false);
  };

  const toggleAdminAccess = (userId: string) => {
    // Prevent event propagation
    return (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setAdminUsers(prev => {
        if (prev.includes(userId)) {
          return prev.filter(id => id !== userId);
        } else {
          return [...prev, userId];
        }
      });
    };
  };

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (!values.is_public && selectedUsers.length === 0) {
        toast.error('Private boards must have at least one user with access')
        return
      }

      const boardData = {
        name: values.name,
        description: values.description,
        is_public: values.is_public,
        board_type: values.board_type,
        board_config: {
          requires_deal: values.board_config.requires_deal,
          requires_work_date: values.board_config.requires_work_date,
          requires_due_date: values.board_config.requires_due_date,
          show_in_my_work: values.board_config.show_in_my_work,
          enable_time_tracking: values.board_config.enable_time_tracking,
          enable_subtasks: values.board_config.enable_subtasks,
          simplify_main_tasks: values.board_config.simplify_main_tasks,
          enable_automations: values.board_config.enable_automations,
          enable_in_flows: values.board_config.enable_in_flows,
          icon: values.board_config.icon,
          kanban_source: kanbanSource,
          progress_status_options: values.board_config.progress_status_options.map((status, index) => ({
            label: status.label,
            color: status.color || '#1890ff',
            description: status.description || null,
            order: index
          })),
          board_status_options: values.board_config.board_status_options?.map((status, index) => ({
            label: status.label,
            color: status.color || '#1890ff',
            description: status.description || null,
            order: index
          })) || []
        },
        user_access: !values.is_public ? selectedUsers : [],
        admin_access: !values.is_public ? adminUsers : []
      }

      const response = await axios.post('/custom-board/boards', boardData)
      
      if (response.status === 200) {
        toast.success('Board created successfully')
        form.reset()
        setSelectedUsers([])
        setAdminUsers([])
        setIsPublic(true)
        onSuccess()
        onClose()
        
        navigate(`/boards/custom/${response.data.id}`)
      }
    } catch (error) {
      console.error('Error creating board:', error)
      toast.error('Failed to create board')
    }
  }

  const handlePublicChange = (checked: boolean) => {
    setIsPublic(checked)
    if (!checked && currentUser) {
      setSelectedUsers([currentUser.user_id.toString()])
    } else {
      setSelectedUsers([])
    }
  }

  const renderStatusOptionsList = (type: 'progress' | 'board') => {
    const { fields, append, remove } = type === 'progress' ? progressStatusArray : boardStatusArray;

    return (
      <div className="space-y-4">
        {fields.map((field, index) => (
          <div key={field.id} className="flex items-start gap-4">
            <FormField
              control={form.control}
              name={`board_config.${type}_status_options.${index}.label`}
              render={({ field: labelField }) => (
                <FormItem className="flex-1">
                  <FormLabel className={index === 0 ? 'block' : 'sr-only'}>
                    {type === 'progress' ? 'Progress' : 'Board'} Status Options
                  </FormLabel>
                  <FormControl>
                    <div className="flex flex-col gap-2">
                      <div className="flex gap-2">
                        <Input
                          {...labelField}
                          placeholder="Label (e.g., Not Started)"
                          className="flex-1"
                        />
                        <FormField
                          control={form.control}
                          name={`board_config.${type}_status_options.${index}.color`}
                          render={({ field: colorField }) => (
                            <Select
                              onValueChange={colorField.onChange}
                              value={colorField.value}
                            >
                              <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Select color" />
                              </SelectTrigger>
                              <SelectContent>
                                {ANT_COLORS.map(color => (
                                  <SelectItem
                                    key={color.value}
                                    value={color.value}
                                  >
                                    <div 
                                      className="w-full h-5 flex items-center px-2 rounded"
                                      style={{ 
                                        backgroundColor: color.value,
                                        color: ['#ffec3d', '#fff566', '#ffd666', '#fff1b8'].includes(color.value) ? '#000000' : '#ffffff'
                                      }}
                                    >
                                      {color.name}
                                    </div>
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          )}
                        />
                      </div>
                      <FormField
                        control={form.control}
                        name={`board_config.${type}_status_options.${index}.description`}
                        render={({ field: descriptionField }) => (
                          <FormItem>
                            <FormControl>
                              <Input
                                {...descriptionField}
                                placeholder="Description (optional)"
                                className="flex-1"
                                value={descriptionField.value || ''}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      {type === 'progress' && (
                        <FormField
                          control={form.control}
                          name={`board_config.progress_status_options.${index}.is_completed_state`}
                          render={({ field: completedField }) => (
                            <FormItem className="flex items-center justify-between">
                              <div className="flex items-center gap-2">
                                <Switch
                                  checked={!!completedField.value}
                                  onCheckedChange={completedField.onChange}
                                />
                                <span className="text-sm">Mark as completed state</span>
                              </div>
                            </FormItem>
                          )}
                        />
                      )}
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {(fields.length > 1 || type === 'board') && (
              <Button
                variant="ghost"
                size="icon"
                onClick={() => remove(index)}
                className="text-destructive"
              >
                <Minus className="h-4 w-4" />
              </Button>
            )}
          </div>
        ))}
        <Button
          type="button"
          variant="outline"
          onClick={() => append({
            label: '',
            color: '#1890ff',
            description: '',
            ...(type === 'progress' ? { is_completed_state: false } : {})
          })}
          className="w-full"
        >
          <Plus className="h-4 w-4 mr-2" />
          Add {type === 'progress' ? 'Progress' : 'Board'} Status
        </Button>
      </div>
    );
  };

  const resetModal = () => {
    form.reset()
    setIsPublic(true)
    setKanbanSource('progress')
    setShowAssigneeModal(false)
    setSelectedUsers([])
    setAdminUsers([])
    setUseBoardStatus(false)
  }

  useEffect(() => {
    if (open) {
      resetModal()
    }
  }, [open])

  useEffect(() => {
    if (!isPublic && selectedUsers.length === 0 && currentUser) {
      setSelectedUsers([currentUser.user_id.toString()])
    }
  }, [isPublic])

  return (
    <>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Create New Board</DialogTitle>
            <DialogDescription className="sr-only">
              Create a new board by configuring its name, description, privacy settings, and status options
            </DialogDescription>
          </DialogHeader>

          <Form {...form}>
            <form id="create-board-form" onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col flex-1 min-h-0">
              <Tabs defaultValue="general" className="flex flex-col flex-1 min-h-0">
                <div className="px-6 border-b">
                  <TabsList>
                    <TabsTrigger value="general">General</TabsTrigger>
                    <TabsTrigger value="status">Status Options</TabsTrigger>
                  </TabsList>
                </div>

                <DialogBody maxBodyHeight>
                  <TabsContent value="general" className="space-y-4 mt-0">
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Board Name</FormLabel>
                          <FormControl>
                            <Input placeholder="Enter board name" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="description"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Description</FormLabel>
                          <FormControl>
                            <Input placeholder="Enter board description (optional)" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="board_type"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Board Type</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Select board type" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="taskboard">Taskboard</SelectItem>
                              <SelectItem value="project">Project</SelectItem>
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="board_config.icon"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Board Icon</FormLabel>
                          <FormControl>
                            <IconSelector
                              value={field.value}
                              onChange={field.onChange}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <div className="space-y-4 border rounded-lg p-4">
                      <h3 className="font-medium">Privacy Settings</h3>
                      <FormField
                        control={form.control}
                        name="is_public"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Public Board</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Make this board visible to all users
                              </p>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={(checked) => {
                                  field.onChange(checked);
                                  setIsPublic(checked);
                                  if (!checked) {
                                    // When switching to private, keep current access permissions
                                    if (currentUser) {
                                      setSelectedUsers([currentUser.user_id.toString()]);
                                    }
                                  } else {
                                    setSelectedUsers([]);
                                    setAdminUsers([]);
                                  }
                                }}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      {!isPublic && (
                        <div className="mt-4 space-y-4">
                          <div className="flex items-center justify-between mb-2">
                            <div>
                              <h4 className="text-sm font-medium">User Access</h4>
                              <p className="text-sm text-muted-foreground">
                                Select users who can access this board and manage their permissions
                              </p>
                            </div>
                            <Button
                              variant="outline"
                              size="sm"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowAssigneeModal(true);
                              }}
                            >
                              Select Users
                            </Button>
                          </div>
                          {selectedUsers.length > 0 && (
                            <div className="flex flex-wrap gap-2 mt-2">
                              {selectedUsers.map(userId => {
                                const user = users.find(u => String(u.user_id) === String(userId));
                                if (!user) return null;
                                const isCreator = currentUser && String(currentUser.user_id) === String(userId);
                                return (
                                  <Badge 
                                    key={userId}
                                    variant={isCreator ? "default" : adminUsers.includes(userId) ? "default" : "secondary"}
                                    className={cn(
                                      "flex items-center gap-2",
                                      isCreator && "bg-primary",
                                      !isCreator && adminUsers.includes(userId) && "bg-blue-500"
                                    )}
                                  >
                                    {user.name} {user.surname}
                                    <div className="flex items-center gap-1">
                                      {isCreator ? (
                                        <span className="text-xs ml-1">(Owner)</span>
                                      ) : (
                                        <>
                                          <Button
                                            variant="ghost"
                                            size="sm"
                                            className="h-5 px-1.5 text-xs"
                                            onClick={toggleAdminAccess(userId)}
                                          >
                                            {adminUsers.includes(userId) ? 'Admin ✓' : 'Make Admin'}
                                          </Button>
                                          <Button
                                            variant="ghost"
                                            size="icon"
                                            className="h-4 w-4 p-0 hover:bg-transparent"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              setSelectedUsers(prev => prev.filter(id => id !== userId));
                                              setAdminUsers(prev => prev.filter(id => id !== userId));
                                            }}
                                          >
                                            <X className="h-3 w-3" />
                                          </Button>
                                        </>
                                      )}
                                    </div>
                                  </Badge>
                                );
                              })}
                            </div>
                          )}
                          {selectedUsers.length === 0 && (
                            <p className="text-sm text-muted-foreground italic">
                              No users selected. Only you will have access to this board.
                            </p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="space-y-4 border rounded-lg p-4">
                      <h3 className="font-medium">Task Requirements</h3>
                      <FormField
                        control={form.control}
                        name="board_config.requires_deal"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Require Deal</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Tasks must be linked to a deal
                              </p>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="board_config.requires_work_date"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Require Work Date</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Tasks must have a work date set
                              </p>
                              {form.watch('board_config.simplify_main_tasks') && (
                                <p className="text-sm text-destructive mt-1">
                                  This option is disabled when "Simplify Main Tasks" is enabled
                                </p>
                              )}
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value && !form.watch('board_config.simplify_main_tasks')}
                                onCheckedChange={field.onChange}
                                disabled={form.watch('board_config.simplify_main_tasks')}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="board_config.requires_due_date"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Require Due Date</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Tasks must have a due date set
                              </p>
                              {form.watch('board_config.simplify_main_tasks') && (
                                <p className="text-sm text-destructive mt-1">
                                  This option is disabled when "Simplify Main Tasks" is enabled
                                </p>
                              )}
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value && !form.watch('board_config.simplify_main_tasks')}
                                onCheckedChange={field.onChange}
                                disabled={form.watch('board_config.simplify_main_tasks')}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>

                    <div className="space-y-4 border rounded-lg p-4">
                      <h3 className="font-medium">Additional Settings</h3>
                      <FormField
                        control={form.control}
                        name="board_config.show_in_my_work"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Show in My Work</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Display tasks from this board in the My Work section
                              </p>
                              {form.watch('board_config.simplify_main_tasks') && (
                                <p className="text-sm text-destructive mt-1">
                                  This option is disabled when "Simplify Main Tasks" is enabled
                                </p>
                              )}
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value && !form.watch('board_config.simplify_main_tasks')}
                                onCheckedChange={field.onChange}
                                disabled={form.watch('board_config.simplify_main_tasks')}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="board_config.enable_time_tracking"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Enable Time Tracking</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Allow time tracking on tasks
                              </p>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="board_config.enable_subtasks"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Enable Subtasks</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Allow tasks to have subtasks in this board
                              </p>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      {form.watch('board_config.enable_subtasks') && (
                        <FormField
                          control={form.control}
                          name="board_config.simplify_main_tasks"
                          render={({ field }) => (
                            <FormItem className="flex items-center justify-between">
                              <div>
                                <FormLabel>Simplify Main Tasks</FormLabel>
                                <p className="text-sm text-muted-foreground">
                                  Simplify main tasks to only deal selection. This requires "Require Deal" to be enabled.
                                </p>
                                {field.value && !form.watch('board_config.requires_deal') && (
                                  <p className="text-sm text-destructive mt-1">
                                    This option requires "Require Deal" to be enabled
                                  </p>
                                )}
                              </div>
                              <FormControl>
                                <Switch
                                  checked={field.value}
                                  onCheckedChange={(checked) => {
                                    if (checked && !form.watch('board_config.requires_deal')) {
                                      toast.error('You must enable "Require Deal" to use this option');
                                      return;
                                    }
                                    if (checked) {
                                      // When enabling simplify_main_tasks, disable show_in_my_work and date requirements
                                      form.setValue('board_config.show_in_my_work', false);
                                      form.setValue('board_config.requires_work_date', false);
                                      form.setValue('board_config.requires_due_date', false);
                                    }
                                    field.onChange(checked);
                                  }}
                                  disabled={!form.watch('board_config.requires_deal')}
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      )}

                      <FormField
                        control={form.control}
                        name="board_config.enable_automations"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Enable Automations</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Enable automation rules for tasks and subtasks
                              </p>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="board_config.enable_in_flows"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Enable in Flows</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Make this board available as an option in automation flows
                              </p>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>
                  </TabsContent>

                  <TabsContent value="status" className="space-y-6 mt-0">
                    <div className="p-6 bg-muted rounded-lg space-y-4">
                      <h3 className="text-lg font-semibold">
                        Progress Status Options (Required)
                      </h3>
                      <p className="text-sm text-muted-foreground mb-4">
                        {kanbanSource === 'progress' && (
                          <span className="text-primary font-medium">Currently selected as Kanban stages ✓</span>
                        )}
                      </p>
                      {renderStatusOptionsList('progress')}
                    </div>

                    <Separator />

                    <div className="p-6 bg-muted rounded-lg space-y-4">
                      <div className="flex flex-col gap-4">
                        <div className="flex items-center justify-between">
                          <div>
                            <h3 className="text-lg font-semibold">
                              Additional Board Status
                            </h3>
                            {useBoardStatus && (
                              <p className="text-sm text-muted-foreground mt-1">
                                {kanbanSource === 'board_status' && (
                                  <span className="text-primary font-medium">Currently selected as Kanban stages ✓</span>
                                )}
                              </p>
                            )}
                          </div>
                          <div className="flex items-center gap-4">
                            <div className="flex items-center gap-2">
                              <Switch
                                checked={useBoardStatus}
                                onCheckedChange={(checked) => {
                                  setUseBoardStatus(checked)
                                  if (!checked) {
                                    if (kanbanSource === 'board_status') {
                                      setKanbanSource('progress')
                                    }
                                    form.setValue('board_config.board_status_options', [])
                                  }
                                }}
                              />
                              <span className="text-sm">Enable Board Status</span>
                            </div>
                          </div>
                        </div>
                        {useBoardStatus && (
                          <div className="flex flex-col gap-2">
                            <div className="bg-accent/50 p-3 rounded-md">
                              <div className="flex gap-2 items-center">
                                <p className="text-sm font-medium">Kanban Source:</p>
                                <div className="flex gap-2">
                                  <Button
                                    variant={kanbanSource === 'progress' ? 'default' : 'outline'}
                                    onClick={() => setKanbanSource('progress')}
                                    size="sm"
                                  >
                                    Progress Status
                                  </Button>
                                  <Button
                                    variant={kanbanSource === 'board_status' ? 'default' : 'outline'}
                                    onClick={() => setKanbanSource('board_status')}
                                    size="sm"
                                  >
                                    Board Status
                                  </Button>
                                </div>
                              </div>
                              <p className="text-sm text-muted-foreground mt-2">
                                Select which status type should be used for the Kanban board stages
                              </p>
                            </div>
                            {renderStatusOptionsList('board')}
                          </div>
                        )}
                      </div>
                    </div>
                  </TabsContent>
                </DialogBody>
              </Tabs>

              <DialogFooter>
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit" form="create-board-form">Create Board</Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>

      <AssigneeSelection
        visible={showAssigneeModal}
        onClose={() => setShowAssigneeModal(false)}
        onSave={handleAssigneeSave}
        initialAssignees={selectedUsers}
      />
    </>
  )
}

export default CreateBoardModal