import React, { useState, useEffect } from 'react';
import { Check, Filter, Users, UserCheck, Briefcase, CheckSquare, SplitSquareHorizontal } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogBody, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card";
import { Switch } from "@/components/ui/switch";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { AssigneeSelection } from '@/modals/AssigneeSelection';
import { useUsers } from '@/contexts/UserContext';
import { Board, BoardStatusOption } from '@/types/custom-board';
import { dealDataConfig } from '@/configs/DealDataConfig';
import { AvatarGroup } from "@/components/ui/avatars/avatar-group";

interface CustomTaskFilterProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onFiltersChange: (filters: TaskFilters) => void;
  board: Board | null;
  currentFilters: TaskFilters;
}

export interface TaskFilters {
  assignedUsers: string[];
  subtaskAssignedUsers?: string[];
  progressStatus: number[];
  boardStatus?: number[];
  subtaskFilters?: {
    name: string;
    statusId: number;
  }[];
  dealDataFilters?: {
    field: string;
    operator: 'equals' | 'not_equals';
    value: string;
  }[];
  useOrLogic?: boolean;
}

export function CustomTaskFilter({
  open,
  onOpenChange,
  onFiltersChange,
  board,
  currentFilters
}: CustomTaskFilterProps) {
  const { currentUser, users } = useUsers();
  const [filters, setFilters] = useState<TaskFilters>(currentFilters);
  const [isAssigneeModalOpen, setIsAssigneeModalOpen] = useState(false);
  const [isSubtaskAssigneeModalOpen, setIsSubtaskAssigneeModalOpen] = useState(false);
  const [useOrLogic, setUseOrLogic] = useState(currentFilters.useOrLogic || false);

  useEffect(() => {
    setFilters(currentFilters);
    setUseOrLogic(currentFilters.useOrLogic || false);
  }, [currentFilters]);

  const handleAssignedToMeToggle = (type: 'main' | 'subtask' = 'main') => {
    if (!currentUser?.user_id) return;

    setFilters(prev => {
      const userId = currentUser.user_id.toString();
      const key = type === 'main' ? 'assignedUsers' : 'subtaskAssignedUsers';
      const currentUsers = prev[key] || [];
      const newUsers = currentUsers.includes(userId)
        ? currentUsers.filter(id => id !== userId)
        : [...currentUsers, userId];
      
      return {
        ...prev,
        [key]: newUsers
      };
    });
  };

  const handleStatusToggle = (statusId: number, type: 'progress' | 'board') => {
    setFilters(prev => {
      const key = type === 'progress' ? 'progressStatus' : 'boardStatus';
      const currentStatuses = prev[key] || [];
      
      // If using AND logic, only allow one selection
      if (!useOrLogic) {
        return {
          ...prev,
          [key]: currentStatuses.includes(statusId) ? [] : [statusId]
        };
      }
      
      // OR logic allows multiple selections
      const newStatuses = currentStatuses.includes(statusId)
        ? currentStatuses.filter(id => id !== statusId)
        : [...currentStatuses, statusId];
      
      return {
        ...prev,
        [key]: newStatuses
      };
    });
  };

  const handleSubtaskStatusToggle = (subtaskName: string, statusId: number) => {
    setFilters(prev => {
      const currentFilters = prev.subtaskFilters || [];
      const existingFilter = currentFilters.find(f => f.name === subtaskName);
      
      // If using AND logic, only allow one status per subtask
      if (!useOrLogic) {
        const newFilters = currentFilters.filter(f => f.name !== subtaskName);
        if (!existingFilter || existingFilter.statusId !== statusId) {
          newFilters.push({ name: subtaskName, statusId });
        }
        return {
          ...prev,
          subtaskFilters: newFilters
        };
      }
      
      // OR logic allows multiple selections
      const existingIndex = currentFilters.findIndex(f => 
        f.name === subtaskName && f.statusId === statusId
      );
      
      const newFilters = existingIndex >= 0
        ? currentFilters.filter((_, i) => i !== existingIndex)
        : [...currentFilters, { name: subtaskName, statusId }];
      
      return {
        ...prev,
        subtaskFilters: newFilters
      };
    });
  };

  const handleDealDataFilterChange = (field: string, value: string, operator: 'equals' | 'not_equals' = 'equals') => {
    setFilters(prev => {
      const existingFilters = prev.dealDataFilters || [];
      const existingFilterIndex = existingFilters.findIndex(f => 
        f.field === field && f.value === value && f.operator === operator
      );
      
      // If the filter exists, remove it (toggle off)
      if (existingFilterIndex !== -1) {
        return {
          ...prev,
          dealDataFilters: existingFilters.filter((_, i) => i !== existingFilterIndex)
        };
      }
      
      // If using AND logic, remove any existing filter for this field
      let newFilters = existingFilters;
      if (!useOrLogic) {
        newFilters = existingFilters.filter(f => f.field !== field);
      }
      
      // Add the new filter
      return {
        ...prev,
        dealDataFilters: [...newFilters, { field, value, operator }]
      };
    });
  };

  const isDealDataFilterSelected = (field: string, value: string, operator: 'equals' | 'not_equals' = 'equals') => {
    return filters.dealDataFilters?.some(f => 
      f.field === field && 
      f.value === value && 
      f.operator === operator
    ) || false;
  };

  const handleApplyFilters = () => {
    onFiltersChange({ ...filters, useOrLogic });
    onOpenChange(false);
  };

  const handleClearFilters = () => {
    const emptyFilters: TaskFilters = {
      assignedUsers: [],
      progressStatus: [],
      boardStatus: [],
      subtaskFilters: [],
      dealDataFilters: [],
      useOrLogic: false
    };
    setFilters(emptyFilters);
    setUseOrLogic(false);
    onFiltersChange(emptyFilters);
  };

  const isLightColor = (color: string): boolean => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5;
  };

  const getSelectedUsers = (userIds: string[]) => {
    return users.filter(user => userIds.includes(user.user_id.toString()));
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Filter Tasks</DialogTitle>
            <DialogDescription className="sr-only">Configure task filters</DialogDescription>
          </DialogHeader>

          <DialogBody>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Main Task Assignees */}
              {!board?.board_config?.simplify_main_tasks && (
                <Card>
                  <CardHeader>
                    <CardTitle className="flex items-center gap-2">
                      <Users className="h-4 w-4" />
                      Main Task Assignees
                    </CardTitle>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => handleAssignedToMeToggle('main')}
                      className="w-full justify-start gap-2"
                    >
                      <UserCheck className="h-4 w-4" />
                      Assigned to me
                      {filters.assignedUsers.includes(currentUser?.user_id?.toString() || '') && (
                        <Check className="h-4 w-4 ml-auto" />
                      )}
                    </Button>
                    <Button
                      variant="outline"
                      className="w-full justify-start"
                      onClick={() => setIsAssigneeModalOpen(true)}
                    >
                      <Users className="mr-2 h-4 w-4" />
                      {filters.assignedUsers.length > 0 ? (
                        <AvatarGroup users={getSelectedUsers(filters.assignedUsers)} />
                      ) : (
                        'Select assignees'
                      )}
                    </Button>
                  </CardContent>
                </Card>
              )}

              {/* Subtask Assignee Card - Only if subtasks are enabled */}
              {board?.board_config?.enable_subtasks && (
                <Card>
                  <CardHeader>
                    <CardTitle className="flex items-center gap-2">
                      <SplitSquareHorizontal className="h-4 w-4" />
                      Subtask Assignees
                    </CardTitle>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <Button
                      variant={filters.subtaskAssignedUsers?.includes(currentUser?.user_id?.toString() || '') ? "default" : "outline"}
                      className="w-full justify-start gap-2"
                      onClick={() => handleAssignedToMeToggle('subtask')}
                    >
                      <UserCheck className="h-4 w-4" />
                      Assigned to me
                      {filters.subtaskAssignedUsers?.includes(currentUser?.user_id?.toString() || '') && (
                        <Check className="h-4 w-4 ml-auto" />
                      )}
                    </Button>
                    <Button
                      variant="outline"
                      className="w-full"
                      onClick={() => {
                        // We'll reuse the AssigneeSelection modal but for subtask assignees
                        setIsSubtaskAssigneeModalOpen(true);
                      }}
                    >
                      Select specific users
                    </Button>
                    {filters.subtaskAssignedUsers && filters.subtaskAssignedUsers.length > 0 && (
                      <div className="pt-2">
                        <AvatarGroup 
                          users={getSelectedUsers(filters.subtaskAssignedUsers)}
                          className="justify-center"
                        />
                      </div>
                    )}
                  </CardContent>
                </Card>
              )}

              {/* Progress Status Card */}
              <Card>
                <CardHeader>
                  <CardTitle>Progress Status</CardTitle>
                </CardHeader>
                <CardContent className="space-y-2">
                  {board?.board_config?.progress_status_options.map(status => (
                    <Button
                      key={status.id}
                      variant="outline"
                      className={cn(
                        "w-full justify-start gap-2",
                        filters.progressStatus?.includes(status.id) && "bg-accent"
                      )}
                      onClick={() => handleStatusToggle(status.id, 'progress')}
                    >
                      <div
                        className="w-3 h-3 rounded-full"
                        style={{ backgroundColor: status.color }}
                      />
                      {status.label}
                      {filters.progressStatus?.includes(status.id) && (
                        <Check className="h-4 w-4 ml-auto" />
                      )}
                    </Button>
                  ))}
                </CardContent>
              </Card>

              {/* Board Status Card - Only if enabled */}
              {board?.board_config?.board_status_options && board.board_config.board_status_options.length > 0 && (
                <Card>
                  <CardHeader>
                    <CardTitle>Board Status</CardTitle>
                  </CardHeader>
                  <CardContent className="space-y-2">
                    {board?.board_config?.board_status_options?.map(status => (
                      <Button
                        key={status.id}
                        variant="outline"
                        className={cn(
                          "w-full justify-start gap-2",
                          filters.boardStatus?.includes(status.id) && "bg-accent"
                        )}
                        onClick={() => handleStatusToggle(status.id, 'board')}
                      >
                        <div
                          className="w-3 h-3 rounded-full"
                          style={{ backgroundColor: status.color }}
                        />
                        {status.label}
                        {filters.boardStatus?.includes(status.id) && (
                          <Check className="h-4 w-4 ml-auto" />
                        )}
                      </Button>
                    ))}
                  </CardContent>
                </Card>
              )}

              {/* Subtask Status Card - Only if enabled and configured */}
              {board?.board_config?.enable_subtasks && 
               board?.subtask_config?.predefined_subtasks && 
               board.subtask_config.predefined_subtasks.length > 0 && (
                <Card>
                  <CardHeader>
                    <CardTitle className="flex items-center gap-2">
                      <CheckSquare className="h-4 w-4" />
                      Subtask Status
                    </CardTitle>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    {board?.subtask_config?.predefined_subtasks?.map(subtask => (
                      <div key={subtask.name} className="space-y-2">
                        <h4 className="font-medium text-sm">{subtask.name}</h4>
                        <div className="flex flex-wrap gap-2">
                          {subtask.status_options.map(status => (
                            <Badge
                              key={status.id}
                              variant="outline"
                              className={cn(
                                "cursor-pointer flex items-center gap-1",
                                filters.subtaskFilters?.some(f => 
                                  f.name === subtask.name && f.statusId === status.id
                                ) && "bg-accent"
                              )}
                              onClick={() => handleSubtaskStatusToggle(subtask.name, status.id)}
                              style={{
                                backgroundColor: filters.subtaskFilters?.some(f => 
                                  f.name === subtask.name && f.statusId === status.id
                                ) ? status.color : 'transparent',
                                color: filters.subtaskFilters?.some(f => 
                                  f.name === subtask.name && f.statusId === status.id
                                ) ? (isLightColor(status.color) ? 'black' : 'white') : undefined
                              }}
                            >
                              {status.label}
                              {filters.subtaskFilters?.some(f => 
                                f.name === subtask.name && f.statusId === status.id
                              ) && <Check className="h-3 w-3 ml-1" />}
                            </Badge>
                          ))}
                        </div>
                      </div>
                    ))}
                  </CardContent>
                </Card>
              )}

              {/* Deal Data Card */}
              <Card className="md:col-span-2">
                <CardHeader>
                  <CardTitle className="flex items-center gap-2">
                    <Briefcase className="h-4 w-4" />
                    Deal Data Filters
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {dealDataConfig
                      .filter(field => field.type === 'dropdown')
                      .map(field => (
                        <div key={field.key} className="space-y-2">
                          <h4 className="font-medium text-sm">{field.label}</h4>
                          <div className="space-y-1">
                            {field.options?.map(option => (
                              <div key={option.value} className="flex items-center gap-2">
                                <Button
                                  variant="outline"
                                  size="sm"
                                  className={cn(
                                    "flex-1 justify-start",
                                    isDealDataFilterSelected(field.key, option.value, 'equals') && "bg-accent"
                                  )}
                                  onClick={() => handleDealDataFilterChange(field.key, option.value, 'equals')}
                                >
                                  <div
                                    className="w-2 h-2 rounded-full mr-2"
                                    style={{ backgroundColor: option.color }}
                                  />
                                  {option.label}
                                  {isDealDataFilterSelected(field.key, option.value, 'equals') && (
                                    <Check className="h-3 w-3 ml-auto" />
                                  )}
                                </Button>
                                <Button
                                  variant="outline"
                                  size="sm"
                                  className={cn(
                                    "px-2",
                                    isDealDataFilterSelected(field.key, option.value, 'not_equals') && "bg-destructive text-destructive-foreground"
                                  )}
                                  onClick={() => handleDealDataFilterChange(field.key, option.value, 'not_equals')}
                                >
                                  ≠
                                </Button>
                              </div>
                            ))}
                          </div>
                        </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>
          </DialogBody>

          <DialogFooter className="sm:justify-between">
            <div className="flex items-center gap-2">
              <Switch
                checked={useOrLogic}
                onCheckedChange={setUseOrLogic}
              />
              <span className="text-sm">Use OR logic between filters</span>
            </div>
            <div className="flex gap-2">
              <Button
                variant="outline"
                onClick={handleClearFilters}
              >
                Clear Filters
              </Button>
              <Button onClick={handleApplyFilters}>
                Apply Filters
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Main Task Assignee Selection Modal */}
      <AssigneeSelection
        visible={isAssigneeModalOpen}
        onClose={() => setIsAssigneeModalOpen(false)}
        onSave={(selectedUsers) => {
          setFilters(prev => ({
            ...prev,
            assignedUsers: selectedUsers
          }));
          setIsAssigneeModalOpen(false);
        }}
        initialAssignees={filters.assignedUsers}
        maxAssignees={10}
      />

      {/* Subtask Assignee Selection Modal */}
      <AssigneeSelection
        visible={isSubtaskAssigneeModalOpen}
        onClose={() => setIsSubtaskAssigneeModalOpen(false)}
        onSave={(selectedUsers) => {
          setFilters(prev => ({
            ...prev,
            subtaskAssignedUsers: selectedUsers
          }));
          setIsSubtaskAssigneeModalOpen(false);
        }}
        initialAssignees={filters.subtaskAssignedUsers || []}
        maxAssignees={10}
      />
    </>
  );
}
