import { useEffect, useState } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { TrendingUp, Clock, AlertCircle, DollarSign, Wallet, FileText, Percent } from 'lucide-react'
import axios from '@/api/axiosConfig'
import { BarChartHorizontalMix } from '@/components/ui/charts/bar-horizontal-mix'
import { PieDonutChart } from '@/components/ui/charts/pie-donut'
import { LineChartMix } from '@/components/ui/charts/line-mix'
import { FilteredBarChart } from "@/components/ui/charts/filtered-bar-chart"
import { StatCard } from "@/components/ui/dashboard/stat-card"
import { ScrollArea } from "@/components/ui/scroll-area"
import { formatDate } from "@/lib/utils"
import { LoadingSpinner } from "@/components/ui/loading-spinner"

interface MonthlyReceivablesData {
  onTime: number
  late1to30: number
  late31to60: number
  late61plus: number
  total_invoice_count: number
  overdue_invoice_count: number
  total_value: number
  overdue_value: number
}

interface CustomerReceivables {
  name: string
  total_outstanding: number
  overdue_amount: number
  total_invoice_count: number
  overdue_invoice_count: number
  avg_days_to_pay: number
  customer_id: number
}

interface ProductReceivables {
  name: string
  total_outstanding: number
  product_code: string
}

interface RecentInvoice {
  id: number
  invoice_number: string
  customer_name: string
  amount: number
  due_date: string
  days_overdue: number
  status: 'paid' | 'overdue' | 'pending'
}

interface ReceivablesData {
  monthly_receivables: Record<string, MonthlyReceivablesData>
  customer_receivables: CustomerReceivables[]
  product_receivables: ProductReceivables[]
  recent_invoices: RecentInvoice[]
  total_outstanding: number
  total_overdue: number
  avg_days_to_payment: number
  invoice_count: number
  overdue_percentage: number
}

// Dummy data for development
const dummyData: ReceivablesData = {
  monthly_receivables: {
    '2024-01': { 
      onTime: 150000, 
      late1to30: 25000, 
      late31to60: 15000, 
      late61plus: 10000,
      total_invoice_count: 100,
      overdue_invoice_count: 25,
      total_value: 200000,
      overdue_value: 50000
    },
    '2024-02': { 
      onTime: 180000, 
      late1to30: 30000, 
      late31to60: 12000, 
      late61plus: 8000,
      total_invoice_count: 120,
      overdue_invoice_count: 20,
      total_value: 230000,
      overdue_value: 50000
    },
    '2024-03': { 
      onTime: 165000, 
      late1to30: 28000, 
      late31to60: 18000, 
      late61plus: 12000,
      total_invoice_count: 110,
      overdue_invoice_count: 30,
      total_value: 223000,
      overdue_value: 58000
    }
  },
  customer_receivables: [
    { 
      name: 'ABC Corp', 
      total_outstanding: 45000, 
      overdue_amount: 15000,
      total_invoice_count: 30,
      overdue_invoice_count: 8,
      avg_days_to_pay: 28, 
      customer_id: 1 
    },
    { 
      name: 'XYZ Ltd', 
      total_outstanding: 35000, 
      overdue_amount: 5000,
      total_invoice_count: 25,
      overdue_invoice_count: 3,
      avg_days_to_pay: 15, 
      customer_id: 2 
    },
    { 
      name: '123 Industries', 
      total_outstanding: 25000, 
      overdue_amount: 12000,
      total_invoice_count: 20,
      overdue_invoice_count: 6,
      avg_days_to_pay: 45, 
      customer_id: 3 
    }
  ],
  product_receivables: [
    { name: 'Product A', total_outstanding: 75000, product_code: 'PA001' },
    { name: 'Product B', total_outstanding: 45000, product_code: 'PB001' },
    { name: 'Product C', total_outstanding: 35000, product_code: 'PC001' }
  ],
  recent_invoices: [
    { id: 1, invoice_number: 'INV-001', customer_name: 'ABC Corp', amount: 15000, due_date: '2024-02-15', days_overdue: 0, status: 'pending' },
    { id: 2, invoice_number: 'INV-002', customer_name: 'XYZ Ltd', amount: 22000, due_date: '2024-01-30', days_overdue: 15, status: 'overdue' },
    { id: 3, invoice_number: 'INV-003', customer_name: '123 Industries', amount: 18000, due_date: '2024-02-01', days_overdue: 0, status: 'paid' }
  ],
  total_outstanding: 200000,
  total_overdue: 65000,
  avg_days_to_payment: 25,
  invoice_count: 150,
  overdue_percentage: 32.5
}

export default function ReceivablesDash() {
  const [receivablesData, setReceivablesData] = useState<ReceivablesData | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        // For now, use dummy data
        setReceivablesData(dummyData)
        // Later, uncomment this to fetch real data
        // const response = await axios.get('/dashboard/receivables-analytics')
        // setReceivablesData(response.data)
      } catch (error) {
        console.error('Error fetching receivables data:', error)
      }
    }
    fetchData()
  }, [])

  if (!receivablesData) return <LoadingSpinner className="h-[400px]" />

  // Transform data for charts
  const monthlyTrendData = Object.entries(receivablesData.monthly_receivables).map(([month, data]) => ({
    month,
    'On Time': data.onTime,
    '1-30 Days Late': data.late1to30,
    '31-60 Days Late': data.late31to60,
    '60+ Days Late': data.late61plus
  }))

  // New data transformation for overdue percentages by month
  const monthlyOverdueData = Object.entries(receivablesData.monthly_receivables).map(([month, data]) => ({
    month,
    'Overdue Count %': (data.overdue_invoice_count / data.total_invoice_count) * 100,
    'Overdue Value %': (data.overdue_value / data.total_value) * 100
  }))

  const customerData = receivablesData.customer_receivables.map((customer, index) => ({
    name: customer.name,
    value: customer.total_outstanding,
    overdue: customer.overdue_amount,
    overdue_percent: (customer.overdue_invoice_count / customer.total_invoice_count) * 100,
    fill: `var(--chart-${(index % 12) + 1})`
  }))

  const productData = receivablesData.product_receivables.map((product, index) => ({
    name: product.name,
    value: product.total_outstanding,
    fill: `var(--chart-${(index % 12) + 1})`
  }))

  const receivablesFilters = [
    { id: 'On Time', label: 'On Time', color: 'hsl(var(--chart-1))' },
    { id: '1-30 Days Late', label: '1-30 Days', color: 'hsl(var(--chart-2))' },
    { id: '31-60 Days Late', label: '31-60 Days', color: 'hsl(var(--chart-3))' },
    { id: '60+ Days Late', label: '60+ Days', color: 'hsl(var(--chart-4))' }
  ]

  return (
    <div className="p-8 space-y-8">
      {/* Top Stats */}
      <div className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-4">
        <StatCard
          title="Total Outstanding"
          value={receivablesData.total_outstanding}
          icon={DollarSign}
          valueFormatter={(val) => new Intl.NumberFormat('da-DK', { 
            style: 'currency', 
            currency: 'DKK',
            maximumFractionDigits: 0 
          }).format(val)}
          trend={{
            value: receivablesData.overdue_percentage,
            isUpward: false,
            label: "overdue"
          }}
        />
        <StatCard
          title="Total Overdue"
          value={receivablesData.total_overdue}
          icon={AlertCircle}
          valueFormatter={(val) => new Intl.NumberFormat('da-DK', { 
            style: 'currency', 
            currency: 'DKK',
            maximumFractionDigits: 0 
          }).format(val)}
        />
        <StatCard
          title="Overdue Invoices"
          value={receivablesData.overdue_percentage}
          icon={Percent}
          suffix="%"
          description="of total invoices"
        />
        <StatCard
          title="Avg. Days to Payment"
          value={receivablesData.avg_days_to_payment}
          icon={Clock}
          suffix="days"
        />
      </div>

      {/* Charts Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Recent Invoices - First column */}
        <Card>
          <CardHeader>
            <CardTitle>Recent Invoices</CardTitle>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[400px] pr-4">
              <div className="space-y-4">
                {receivablesData.recent_invoices.map((invoice) => (
                  <div 
                    key={invoice.id} 
                    className="flex items-center justify-between p-2 rounded-lg border border-border hover:bg-muted/50 transition-colors"
                  >
                    <div>
                      <div className="font-medium text-foreground">{invoice.customer_name}</div>
                      <div className="text-sm text-muted-foreground">
                        {invoice.invoice_number} - Due: {formatDate(invoice.due_date)}
                      </div>
                      <div className={`text-sm ${
                        invoice.status === 'overdue' ? 'text-destructive' : 
                        invoice.status === 'paid' ? 'text-green-500' :
                        'text-muted-foreground'
                      }`}>
                        {invoice.status === 'overdue' ? `${invoice.days_overdue} days overdue` :
                         invoice.status === 'paid' ? 'Paid' :
                         'Pending'}
                      </div>
                    </div>
                    <div className="font-medium text-foreground">
                      {new Intl.NumberFormat('da-DK', { 
                        style: 'currency', 
                        currency: 'DKK',
                        maximumFractionDigits: 0 
                      }).format(invoice.amount)}
                    </div>
                  </div>
                ))}
              </div>
            </ScrollArea>
          </CardContent>
        </Card>

        {/* Monthly Receivables Distribution */}
        <div className="lg:col-span-2">
          <FilteredBarChart
            title="Monthly Receivables Distribution"
            description="Payment status by month"
            data={monthlyTrendData}
            filters={receivablesFilters}
            xAxisKey="month"
            valueFormatter={(value) => `${value.toLocaleString()} kr.`}
          />
        </div>

        {/* Monthly Overdue Trends */}
        <div className="min-h-[400px]">
          <LineChartMix
            data={monthlyOverdueData}
            title="Monthly Overdue Trends"
            categories={['Overdue Count %', 'Overdue Value %']}
            index="month"
            valueFormatter={(value: number) => `${value.toFixed(1)}%`}
          />
        </div>

        {/* Customer Overdue Analysis */}
        <div className="min-h-[400px]">
          <BarChartHorizontalMix
            title="Customer Overdue Analysis"
            data={customerData.map(customer => ({
              name: customer.name,
              value: customer.overdue_percent,
              fill: customer.fill
            }))}
            config={{}}
            description="Overdue invoices % by customer"
            valueFormatter={(value) => `${value.toFixed(1)}%`}
            maxLabelLength={25}
          />
        </div>

        {/* Payment Status Distribution */}
        <div className="min-h-[400px]">
          <PieDonutChart
            title="Payment Status Distribution"
            data={[
              { name: 'On Time', value: receivablesData.monthly_receivables['2024-03'].onTime, fill: 'var(--chart-1)' },
              { name: '1-30 Days Late', value: receivablesData.monthly_receivables['2024-03'].late1to30, fill: 'var(--chart-2)' },
              { name: '31-60 Days Late', value: receivablesData.monthly_receivables['2024-03'].late31to60, fill: 'var(--chart-3)' },
              { name: '60+ Days Late', value: receivablesData.monthly_receivables['2024-03'].late61plus, fill: 'var(--chart-4)' }
            ]}
            config={{
              value: { label: "Amount" }
            }}
            description="Current month payment status"
            centerLabel={{
              value: Object.values(receivablesData.monthly_receivables['2024-03']).reduce((a, b) => typeof b === 'number' ? a + b : a, 0),
              label: "Total"
            }}
            colorMode="theme"
          />
        </div>

        {/* Product Receivables */}
        <div className="min-h-[400px]">
          <BarChartHorizontalMix
            title="Product Receivables"
            data={productData}
            config={{}}
            description="Outstanding amounts by product"
            valueFormatter={(value) => `${value.toLocaleString()} kr.`}
          />
        </div>
      </div>
    </div>
  )
}
