import React, { useEffect, useState } from 'react';
import { Bell, Loader2, Archive, Check } from 'lucide-react';
import { toast } from 'sonner';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogBody,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useNavigate } from 'react-router-dom';
import api from '@/api/axiosConfig';
import { formatDistanceToNow } from 'date-fns';

interface Notification {
  id: number;
  title: string;
  message: string;
  type: string;
  data: Record<string, any>;
  navigation?: {
    route: string;
    params?: Record<string, string | number>;
  };
  is_read: boolean;
  is_archived: boolean;
  created_at: string;
}

interface NotificationsModalProps {
  visible: boolean;
  onClose: () => void;
}

const NotificationsModal: React.FC<NotificationsModalProps> = ({ visible, onClose }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const fetchNotifications = async () => {
    try {
      setLoading(true);
      const response = await api.get('/notifications/');
      setNotifications(response.data.notifications);
      setError(null);
    } catch (err: any) {
      const errorMessage = err.response?.data?.error || err.message || 'Failed to load notifications';
      setError(errorMessage);
      console.error('Error fetching notifications:', {
        message: err.message,
        status: err.response?.status,
        data: err.response?.data
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchNotifications();
    }
  }, [visible]);

  const handleMarkAsRead = async (notificationIds: number[]) => {
    // Optimistically update local state
    const previousNotifications = [...notifications];
    setNotifications(notifications.map(n => 
      notificationIds.includes(n.id) ? { ...n, is_read: true } : n
    ));

    try {
      await api.post('/notifications/mark-read', { notification_ids: notificationIds });
    } catch (err) {
      console.error('Error marking notifications as read:', err);
      // Revert to previous state on error
      setNotifications(previousNotifications);
      // Show error toast
      toast.error('Failed to mark notifications as read');
    }
  };

  const handleArchive = async (notificationIds: number[]) => {
    // Optimistically update local state
    const previousNotifications = [...notifications];
    setNotifications(notifications.filter(n => !notificationIds.includes(n.id)));

    try {
      await api.post('/notifications/archive', { notification_ids: notificationIds });
    } catch (err) {
      console.error('Error archiving notifications:', err);
      // Revert to previous state on error
      setNotifications(previousNotifications);
      // Show error toast
      toast.error('Failed to archive notifications');
    }
  };

  const handleNotificationClick = async (notification: Notification) => {
    if (!notification.is_read) {
      await handleMarkAsRead([notification.id]);
    }
    
    if (notification.navigation) {
      const { route, params } = notification.navigation;
      
      // Special handling for custom board task routes
      if (route === '/boards' && params?.boardId && params?.taskId) {
        navigate(`/custom-boards/${params.boardId}/task/${params.taskId}`);
      } else if (params) {
        const queryParams = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          queryParams.append(key, String(value));
        });
        navigate(`${route}?${queryParams.toString()}`);
      } else {
        navigate(route);
      }
      onClose();
    }
  };

  return (
    <Dialog open={visible} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <Bell className="h-5 w-5" />
            Notifications
          </DialogTitle>
          <DialogDescription className="sr-only">
            Your notifications and updates
          </DialogDescription>
        </DialogHeader>

        <DialogBody maxBodyHeight>
          {loading ? (
            <div className="flex items-center justify-center py-8">
              <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
            </div>
          ) : error ? (
            <div className="flex flex-col items-center justify-center py-8 text-center">
              <p className="text-destructive">{error}</p>
              <Button
                variant="outline"
                size="sm"
                onClick={fetchNotifications}
                className="mt-4"
              >
                Try Again
              </Button>
            </div>
          ) : notifications.length === 0 ? (
            <div className="flex flex-col items-center py-8 text-center">
              <Bell 
                className="h-16 w-16 mb-6 text-muted-foreground" 
                strokeWidth={1.5} 
              />
              <h3 className="text-lg font-semibold mb-2">
                No notifications
              </h3>
              <p className="text-muted-foreground">
                You're all caught up! Check back later for updates.
              </p>
            </div>
          ) : (
            <div className="space-y-4">
              {notifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`
                    relative flex flex-col gap-2 p-4 rounded-lg border cursor-pointer
                    transition-colors duration-200
                    ${notification.is_read ? 'bg-background' : 'bg-muted'}
                    ${notification.navigation ? 'hover:bg-accent' : ''}
                  `}
                  onClick={() => handleNotificationClick(notification)}
                >
                  <div className="flex items-start justify-between gap-2">
                    <h4 className="font-semibold">{notification.title}</h4>
                    <div className="flex items-center gap-2">
                      {!notification.is_read && (
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-8 w-8"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleMarkAsRead([notification.id]);
                          }}
                        >
                          <Check className="h-4 w-4" />
                        </Button>
                      )}
                      <Button
                        variant="ghost"
                        size="icon"
                        className="h-8 w-8"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleArchive([notification.id]);
                        }}
                      >
                        <Archive className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                  <p className="text-sm text-muted-foreground">
                    {notification.message}
                  </p>
                  <span className="text-xs text-muted-foreground">
                    {formatDistanceToNow(new Date(notification.created_at), { addSuffix: true })}
                  </span>
                </div>
              ))}
            </div>
          )}
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};

export default NotificationsModal;