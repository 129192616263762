import React, { useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { Button } from "@/components/ui/button";
import {
  Bold,
  Italic,
  Underline as UnderlineIcon,
  List,
  ListOrdered,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Link as LinkIcon,
  Image as ImageIcon,
  Undo,
  Redo,
  Variable,
} from 'lucide-react';
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { emailVariables, EmailVariable } from '@/configs/EmailVariablesConfig';

interface EmailEditorProps {
  content: string;
  onChange: (content: string) => void;
}

export function EmailEditor({ content, onChange }: EmailEditorProps) {
  const [variableDialogOpen, setVariableDialogOpen] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link.configure({
        openOnClick: false,
      }),
      Image,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
    content,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
  });

  React.useEffect(() => {
    if (editor && content === '') {
      editor.commands.clearContent(true);
    }
  }, [content, editor]);

  if (!editor) {
    return null;
  }

  const toolbarButtons = [
    [
      {
        icon: Bold,
        label: 'Bold',
        action: () => editor.chain().focus().toggleBold().run(),
        isActive: editor.isActive('bold'),
      },
      {
        icon: Italic,
        label: 'Italic',
        action: () => editor.chain().focus().toggleItalic().run(),
        isActive: editor.isActive('italic'),
      },
      {
        icon: UnderlineIcon,
        label: 'Underline',
        action: () => editor.chain().focus().toggleUnderline().run(),
        isActive: editor.isActive('underline'),
      },
    ],
    [
      {
        icon: AlignLeft,
        label: 'Align Left',
        action: () => editor.chain().focus().setTextAlign('left').run(),
        isActive: editor.isActive({ textAlign: 'left' }),
      },
      {
        icon: AlignCenter,
        label: 'Align Center',
        action: () => editor.chain().focus().setTextAlign('center').run(),
        isActive: editor.isActive({ textAlign: 'center' }),
      },
      {
        icon: AlignRight,
        label: 'Align Right',
        action: () => editor.chain().focus().setTextAlign('right').run(),
        isActive: editor.isActive({ textAlign: 'right' }),
      },
    ],
    [
      {
        icon: List,
        label: 'Bullet List',
        action: () => editor.chain().focus().toggleBulletList().run(),
        isActive: editor.isActive('bulletList'),
      },
      {
        icon: ListOrdered,
        label: 'Numbered List',
        action: () => editor.chain().focus().toggleOrderedList().run(),
        isActive: editor.isActive('orderedList'),
      },
    ],
    [
      {
        icon: LinkIcon,
        label: 'Insert Link',
        action: () => {
          const url = window.prompt('Enter URL');
          if (url) {
            editor.chain().focus().setLink({ href: url }).run();
          }
        },
        isActive: editor.isActive('link'),
      },
      {
        icon: ImageIcon,
        label: 'Insert Image',
        action: () => {
          const url = window.prompt('Enter image URL');
          if (url) {
            editor.chain().focus().setImage({ src: url }).run();
          }
        },
      },
    ],
    [
      {
        icon: Undo,
        label: 'Undo',
        action: () => editor.chain().focus().undo().run(),
      },
      {
        icon: Redo,
        label: 'Redo',
        action: () => editor.chain().focus().redo().run(),
      },
    ],
    [
      {
        icon: Variable,
        label: 'Insert Variable',
        action: () => setVariableDialogOpen(true),
      },
    ],
  ];

  const insertVariable = (variable: EmailVariable) => {
    editor?.chain().focus().insertContent(`{{${variable.key}}}`).run();
    setVariableDialogOpen(false);
  };

  return (
    <>
      <div className="flex flex-col gap-2 border border-border rounded-lg p-4">
        <div className="flex flex-wrap gap-1 p-1 border border-border rounded-lg bg-muted/50">
          {toolbarButtons.map((group, groupIndex) => (
            <React.Fragment key={groupIndex}>
              <div className="flex gap-1">
                {group.map((button) => (
                  <Button
                    key={button.label}
                    variant={button.isActive ? "default" : "ghost"}
                    size="icon"
                    className={cn(
                      "h-8 w-8",
                      button.isActive && "bg-primary text-primary-foreground hover:bg-primary/90"
                    )}
                    title={button.label}
                    onClick={button.action}
                  >
                    <button.icon className="h-4 w-4" />
                  </Button>
                ))}
              </div>
              {groupIndex < toolbarButtons.length - 1 && (
                <Separator orientation="vertical" className="mx-1" />
              )}
            </React.Fragment>
          ))}
        </div>
        <EditorContent 
          editor={editor} 
          className={cn(
            "prose prose-sm max-w-none min-h-[300px] focus-visible:outline-none",
            "text-foreground [&_p]:text-foreground [&_h1]:text-foreground [&_h2]:text-foreground [&_h3]:text-foreground",
            "[&_.ProseMirror]:min-h-[200px] [&_.ProseMirror]:p-4",
            "[&_p]:my-2",
            "prose-headings:text-foreground prose-p:text-foreground prose-strong:text-foreground prose-em:text-foreground"
          )}
        />
      </div>

      <Dialog open={variableDialogOpen} onOpenChange={setVariableDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Insert Variable</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4">
            {emailVariables.map((variable) => (
              <Button
                key={variable.key}
                variant="outline"
                onClick={() => insertVariable(variable)}
                className="justify-start"
              >
                <div className="flex flex-col items-start">
                  <span className="font-medium">{variable.label}</span>
                  <span className="text-sm text-muted-foreground">
                    {variable.description}
                  </span>
                </div>
              </Button>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}