import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { ApiConfig, ApiSuccessAction, ApiErrorAction } from '../../../types/node_types';
import { useReactFlow } from 'reactflow';

interface SuccessErrorConfigProps {
  config: ApiConfig;
  elementId: string;
  onChange: (updates: Partial<ApiConfig>) => void;
}

export function SuccessErrorConfig({ config, elementId, onChange }: SuccessErrorConfigProps) {
  const { getNode, getEdges } = useReactFlow();
  const currentNode = getNode(elementId)?.parentNode ? getNode(getNode(elementId)?.parentNode || '') : getNode(elementId);
  
  const connectedNodes = currentNode ? getEdges()
    .filter(edge => edge.source === currentNode.id)
    .map(edge => getNode(edge.target))
    .filter(node => node !== null && node !== undefined)
    : [];

  return (
    <div className="space-y-4">
      <div>
        <Label>On Success</Label>
        <Select
          value={config.successAction?.type || 'next'}
          onValueChange={(value: 'next' | 'node' | 'message') => {
            const successAction: ApiSuccessAction = value === 'message' 
              ? { type: 'message', value: 'Success!' }
              : value === 'node'
                ? { type: 'node', nodeId: connectedNodes[0]?.id || '' }
                : { type: 'next' };
            
            onChange({ successAction });
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select success action" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="next">Go to Next Node</SelectItem>
            <SelectItem value="node">Go to Specific Node</SelectItem>
            <SelectItem value="message">Show Message</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {config.successAction?.type === 'node' && (
        <div>
          <Label>Target Node</Label>
          <Select
            value={config.successAction.nodeId || ''}
            onValueChange={(nodeId: string) => {
              onChange({
                successAction: {
                  type: 'node',
                  nodeId
                }
              });
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select target node" />
            </SelectTrigger>
            <SelectContent>
              {connectedNodes.map((node) => (
                <SelectItem key={node?.id} value={node?.id || ''}>
                  {node?.data?.config?.title || node?.id}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}

      {config.successAction?.type === 'message' && (
        <div>
          <Label>Success Message</Label>
          <Input
            value={config.successAction.value || ''}
            onChange={(e) => {
              onChange({
                successAction: {
                  type: 'message',
                  value: e.target.value
                }
              });
            }}
            placeholder="Enter success message"
          />
        </div>
      )}

      <div>
        <Label>On Error</Label>
        <Select
          value={config.errorAction?.type || 'message'}
          onValueChange={(value: 'message' | 'node') => {
            const errorAction: ApiErrorAction = value === 'message'
              ? { type: 'message', message: 'An error occurred' }
              : { type: 'node', nodeId: connectedNodes[0]?.id || '' };
            
            onChange({ errorAction });
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select error action" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="message">Show Message</SelectItem>
            <SelectItem value="node">Go to Specific Node</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {config.errorAction?.type === 'node' && (
        <div>
          <Label>Target Node</Label>
          <Select
            value={config.errorAction.nodeId || ''}
            onValueChange={(nodeId: string) => {
              onChange({
                errorAction: {
                  type: 'node',
                  nodeId
                }
              });
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select target node" />
            </SelectTrigger>
            <SelectContent>
              {connectedNodes.map((node) => (
                <SelectItem key={node?.id} value={node?.id || ''}>
                  {node?.data?.config?.title || node?.id}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}

      {config.errorAction?.type === 'message' && (
        <div>
          <Label>Error Message</Label>
          <Input
            value={config.errorAction.message || ''}
            onChange={(e) => {
              onChange({
                errorAction: {
                  type: 'message',
                  message: e.target.value
                }
              });
            }}
            placeholder="Enter error message"
          />
        </div>
      )}
    </div>
  );
}
