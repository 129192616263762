import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { format } from 'date-fns'
import { Calendar, UserPlus } from 'lucide-react'
import { toast } from 'sonner'
import axios from '@/api/axiosConfig'

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogBody,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { Calendar as CalendarComponent } from '@/components/ui/calendar'
import { AssigneeSelection } from '@/modals/AssigneeSelection'

import { Task } from '@/types/task'
import { StoredUser } from '@/types/user'
import { Board } from '@/types/custom-board'

interface CreateSubTaskProps {
  open: boolean
  onClose: () => void
  onSubmit: (values: Partial<Task>) => void
  parentTask: Task
  board: Board | null
}

const formSchema = z.object({
  title: z.string().min(1, 'Please enter a title'),
  description: z.string().optional(),
  work_date: z.date().optional(),
  due_date: z.date().optional(),
  assigned_users: z.array(z.string()).optional(),
})

const CreateSubTask: React.FC<CreateSubTaskProps> = ({
  open,
  onClose,
  onSubmit: handleSubmit,
  parentTask,
  board,
}) => {
  const [showAssigneeModal, setShowAssigneeModal] = React.useState(false)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: '',
      description: '',
      assigned_users: [],
    },
  })

  const handleAssigneeSelection = (assignees: string[]) => {
    form.setValue('assigned_users', assignees)
  }

  const submitForm = async (values: z.infer<typeof formSchema>) => {
    try {
      const formattedValues = {
        ...values,
        work_date: values.work_date ? format(values.work_date, 'yyyy-MM-dd') : undefined,
        due_date: values.due_date ? format(values.due_date, 'yyyy-MM-dd') : undefined,
      }

      await axios.post(`/custom-board/tasks/${parentTask.id}/subtasks`, formattedValues);
      await handleSubmit(formattedValues);
      form.reset();
      onClose();
    } catch (error) {
      console.error('Error creating subtask:', error);
      toast.error('Failed to create subtask');
    }
  }

  return (
    <>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Create Subtask</DialogTitle>
            <DialogDescription className="sr-only">
              Create a new subtask for the selected task
            </DialogDescription>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(submitForm)}>
              <DialogBody maxBodyHeight>
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Title</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Description</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="work_date"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Work Date
                          {board?.board_config?.requires_work_date && (
                            <span className="text-destructive"> *</span>
                          )}
                        </FormLabel>
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                className={cn(
                                  'w-full pl-3 text-left font-normal',
                                  !field.value && 'text-muted-foreground'
                                )}
                              >
                                {field.value ? (
                                  format(field.value, 'PPP')
                                ) : (
                                  <span>Pick a date</span>
                                )}
                                <Calendar className="ml-auto h-4 w-4 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="start">
                            <CalendarComponent
                              mode="single"
                              selected={field.value}
                              onSelect={field.onChange}
                              initialFocus
                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="due_date"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Due Date
                          {board?.board_config?.requires_due_date && (
                            <span className="text-destructive"> *</span>
                          )}
                        </FormLabel>
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                className={cn(
                                  'w-full pl-3 text-left font-normal',
                                  !field.value && 'text-muted-foreground'
                                )}
                              >
                                {field.value ? (
                                  format(field.value, 'PPP')
                                ) : (
                                  <span>Pick a date</span>
                                )}
                                <Calendar className="ml-auto h-4 w-4 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="start">
                            <CalendarComponent
                              mode="single"
                              selected={field.value}
                              onSelect={field.onChange}
                              initialFocus
                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="assigned_users"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Assignees</FormLabel>
                        <div className="flex gap-2">
                          <Button
                            type="button"
                            variant="outline"
                            className="w-full"
                            onClick={() => setShowAssigneeModal(true)}
                          >
                            Select Assignees ({field.value?.length || 0})
                          </Button>
                        </div>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </DialogBody>

              <DialogFooter>
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit">Create</Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>

      <AssigneeSelection
        visible={showAssigneeModal}
        onClose={() => setShowAssigneeModal(false)}
        onSave={handleAssigneeSelection}
        initialAssignees={form.getValues('assigned_users') || []}
      />
    </>
  )
}

export default CreateSubTask
