import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { Plus, PlusCircle, Trash2, X } from "lucide-react";
import { nanoid } from "nanoid";
import { ButtonConfig, InputConfig, NodeConfig, NodeElement, SelectConfig } from "../../types/node_types";
import { FlowNode } from '@/onboardingflow/types/flows';
import { DefaultNavigation } from "./RulesConfig/DefaultNavigation";
import { ConditionalTriggers } from "./RulesConfig/ConditionalTriggers";

interface RulesTabProps {
  localConfig: NodeConfig;
  setLocalConfig: (config: NodeConfig) => void;
  nodes: FlowNode[];
  edges: { source: string; target: string; }[];
  nodeId: string;
}

export const RulesTab = ({ localConfig, setLocalConfig, nodes, edges, nodeId }: RulesTabProps) => {
  const getButtonElements = () => {
    return localConfig.rows.flatMap(row => 
      row.elements.filter(el => el.type === 'button')
    );
  };

  const getSelectElements = () => {
    return localConfig.rows.flatMap(row => 
      row.elements.filter(el => el.type === 'select')
    );
  };

  const getConnectedForwardNodes = () => {
    
    // Get all direct connections from the current node
    const directConnections = edges
      .filter(edge => edge.source === nodeId)
      .map(edge => edge.target);
    
    
    // Filter nodes to only include those that are directly connected
    const connectedNodes = nodes.filter(node => 
      node.id !== nodeId && // Not the current node
      directConnections.includes(node.id) // Is directly connected
    ) as FlowNode[];
    
    return connectedNodes;
  };

  const handleAddRule = () => {
    setLocalConfig({
      ...localConfig,
      rules: [
        ...(localConfig.rules || []),
        {
          id: nanoid(),
          trigger: {
            elementId: ''
          },
          targetNodeId: '',
          requirements: []
        }
      ]
    });
  };

  const handleRemoveRule = (ruleId: string) => {
    setLocalConfig({
      ...localConfig,
      rules: localConfig.rules?.filter(rule => rule.id !== ruleId)
    });
  };

  const handleUpdateRule = (ruleId: string, updates: Partial<any>) => {
    setLocalConfig({
      ...localConfig,
      rules: localConfig.rules?.map(rule => 
        rule.id === ruleId ? { ...rule, ...updates } : rule
      )
    });
  };

  const handleDefaultNodeChange = (value: string | undefined) => {
    setLocalConfig({
      ...localConfig,
      defaultNextNodeId: value === '_none' ? undefined : value || undefined,
      defaultTriggerElementId: value === '_none' ? undefined : localConfig.defaultTriggerElementId || `default-next-button-${nanoid()}`
    });
  };

  const handleAddDefaultNextButton = () => {
    const defaultButtonId = 'default-next-button-' + nanoid();
    setLocalConfig({
      ...localConfig,
      rows: [
        ...localConfig.rows,
        {
          id: 'default-next-row-' + nanoid(),
          elements: [
            {
              id: defaultButtonId,
              type: 'button',
              config: {
                label: 'Continue',
                action: 'next',
                variant: 'default'
              }
            }
          ]
        }
      ],
      defaultTriggerElementId: defaultButtonId
    });
  };

  const handleRemoveDefaultNextButton = () => {
    setLocalConfig({
      ...localConfig,
      rows: localConfig.rows.filter(row => 
        !row.elements.some(el => el.id === localConfig.defaultTriggerElementId)
      ),
      defaultNextNodeId: undefined,
      defaultTriggerElementId: undefined
    });
  };

  return (
    <div className="space-y-8">
      <div>
        <DefaultNavigation
          localConfig={localConfig}
          setLocalConfig={setLocalConfig}
          nodes={nodes}
          connectedNodes={getConnectedForwardNodes()}
        />
      </div>

      <div>
        <ConditionalTriggers
          localConfig={localConfig}
          setLocalConfig={setLocalConfig}
          nodes={nodes}
          connectedNodes={getConnectedForwardNodes()}
        />
      </div>
    </div>
  );
}; 