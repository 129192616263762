import { useState, useEffect } from 'react'
import { Users, UserMinus, ArrowLeftRight } from 'lucide-react'
import axios from '../api/axiosConfig'
import { toast } from 'sonner'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogBody, DialogDescription } from '@/components/ui/dialog'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

interface User {
  user_id: string
  email: string
}

interface UserManagementModalProps {
  open: boolean
  onClose: () => void
}

const permissionOptions = [
  { value: 'admin', label: 'Admin' },
  { value: 'accounting', label: 'Accounting' },
  { value: 'payroll', label: 'Payroll' },
  { value: 'sales', label: 'Sales' },
  { value: 'user', label: 'User' },
]

export default function UserManagementModal({ open, onClose }: UserManagementModalProps) {
  const [users, setUsers] = useState<User[]>([])
  const [selectedUser, setSelectedUser] = useState<string | null>(null)
  const [useCustomEmail, setUseCustomEmail] = useState(false)

  const formSchema = z.object({
    email: z.string().superRefine((val, ctx) => {
      if (useCustomEmail) {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid email format"
          });
        }
      } else if (val.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Email prefix is required"
        });
      }
    }),
    permissions: z.enum(['admin', 'accounting', 'payroll', 'sales', 'user']),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      permissions: 'user',
    },
  })

  useEffect(() => {
    if (open) {
      fetchUsers()
      form.reset()
      setUseCustomEmail(false)
      setSelectedUser(null)
    }
  }, [open, form])

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/user')
      setUsers(response.data)
    } catch (error) {
      console.error('Error fetching users:', error)
      toast.error('Failed to fetch users')
    }
  }

  const handleInvite = async (values: z.infer<typeof formSchema>) => {
    const email = useCustomEmail ? values.email : `${values.email}@digi-tal.dk`
    try {
      await axios.post('/user/invite', {
        email,
        permissions: values.permissions,
      })
      toast.success('User invited successfully')
      form.reset()
    } catch (error: any) {
      console.error('Error inviting user:', error)
      toast.error(error.response?.data?.msg || 'Failed to invite user')
    }
  }

  const handleRemoveUser = async () => {
    if (!selectedUser) return
    try {
      await axios.delete(`/user/${selectedUser}`)
      toast.success('User removed successfully')
      setSelectedUser(null)
      fetchUsers()
    } catch (error) {
      console.error('Error removing user:', error)
      toast.error('Failed to remove user')
    }
  }

  const handleModalClose = () => {
    form.reset()
    setUseCustomEmail(false)
    onClose()
  }

  return (
    <Dialog open={open} onOpenChange={handleModalClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>User Management</DialogTitle>
          <DialogDescription className="sr-only">
            Manage user invitations and removals
          </DialogDescription>
        </DialogHeader>
        <DialogBody>
          <Tabs defaultValue="invite" className="w-full">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="invite" className="flex items-center gap-2">
                <Users className="h-4 w-4" />
                Invite Member
              </TabsTrigger>
              <TabsTrigger value="remove" className="flex items-center gap-2">
                <UserMinus className="h-4 w-4" />
                Remove User
              </TabsTrigger>
            </TabsList>

            <TabsContent value="invite" className="mt-4">
              <Form {...form}>
                <form onSubmit={form.handleSubmit(handleInvite)} className="space-y-4">
                  <Button
                    type="button"
                    variant="ghost"
                    className="h-auto p-0 text-sm"
                    onClick={() => setUseCustomEmail(!useCustomEmail)}
                  >
                    {useCustomEmail ? 'Use @digi-tal.dk email' : 'Use custom email'}
                    <ArrowLeftRight className="ml-1 h-4 w-4" />
                  </Button>

                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          {useCustomEmail ? (
                            <Input {...field} />
                          ) : (
                            <div className="flex">
                              <Input
                                {...field}
                                className="rounded-r-none"
                              />
                              <Input
                                value="@digi-tal.dk"
                                disabled
                                className="w-[120px] rounded-l-none bg-muted"
                              />
                            </div>
                          )}
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="permissions"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Permissions</FormLabel>
                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select permissions" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {permissionOptions.map((option) => (
                              <SelectItem key={option.value} value={option.value}>
                                {option.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <Button type="submit" className="float-right">
                    Invite
                  </Button>
                </form>
              </Form>
            </TabsContent>

            <TabsContent value="remove" className="mt-4">
              <div className="space-y-4">
                <Select onValueChange={setSelectedUser} value={selectedUser || undefined}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a user to remove" />
                  </SelectTrigger>
                  <SelectContent>
                    {users.map((user) => (
                      <SelectItem key={user.user_id} value={user.user_id}>
                        {user.email}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <Button
                  variant="destructive"
                  onClick={handleRemoveUser}
                  className="float-right"
                  disabled={!selectedUser}
                >
                  Remove
                </Button>
              </div>
            </TabsContent>
          </Tabs>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}