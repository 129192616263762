import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ApiConfig } from '../../../types/node_types';
import { OnboardingEndpoint, HttpMethod } from '../../../types/endpoints';
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { useEffect, useState } from "react";

interface EndpointConfigProps {
  config: ApiConfig;
  onChange: (updates: Partial<ApiConfig>) => void;
  availableEndpoints: OnboardingEndpoint[];
}

export function EndpointConfig({ config, onChange, availableEndpoints }: EndpointConfigProps) {

  // Clean endpoint value by removing extra quotes
  const cleanEndpoint = (endpoint: string) => endpoint.replace(/"+$/, '').replace(/^"+/, '');

  return (
    <div className="space-y-4">
      <div>
        <Label>API Endpoint</Label>
        <Select
          value={config.endpoint ? cleanEndpoint(config.endpoint) : ''}
          onValueChange={(value) => {
            const selectedEndpoint = availableEndpoints.find((e) => cleanEndpoint(e.endpoint) === value);
            if (selectedEndpoint) {
              onChange({
                endpoint: cleanEndpoint(selectedEndpoint.endpoint),
                method: selectedEndpoint.method as HttpMethod,
                payload: {
                  type: selectedEndpoint.required_fields?.payload?.type || 'static',
                  value: [],
                  staticData: {}
                }
              });
            }
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select an endpoint" />
          </SelectTrigger>
          <SelectContent>
            {availableEndpoints.map((endpoint) => (
              <SelectItem key={endpoint.id} value={cleanEndpoint(endpoint.endpoint)}>
                {endpoint.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {config.endpoint && availableEndpoints && (
        <div className="text-sm text-muted-foreground">
          <p>Method: {config.method}</p>
          <p className="mt-1">
            {availableEndpoints.find((e) => cleanEndpoint(e.endpoint) === cleanEndpoint(config.endpoint))?.description}
          </p>
        </div>
      )}
    </div>
  );
}
