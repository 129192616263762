import { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { 
  NodeConfig, 
  NodeElement, 
  ButtonConfig, 
  InputConfig, 
  TextConfig, 
  SelectConfig,
  NotionGuideConfig,
  VisibilityCondition,
  DealDataConfig,
  TaskConfigType
} from '../types/node_types';
import get from 'lodash/get';

export interface PreviewNodeProps {
  config: NodeConfig;
  onClose?: () => void;
}

const checkVisibilityCondition = (condition: VisibilityCondition, formData: Record<string, any>): boolean => {
  const value = get(formData, condition.elementId);

  switch (condition.condition) {
    case 'equals':
      return value === condition.value;
    case 'notEquals':
      return value !== condition.value;
    case 'hasValue':
      return value !== undefined && value !== null && value !== '';
    case 'isEmpty':
      return value === undefined || value === null || value === '';
    default:
      return true;
  }
};

const isElementVisible = (element: NodeElement, formData: Record<string, any>): boolean => {
  if (!element.visibilityConditions?.length) return true;
  return element.visibilityConditions.every(condition => checkVisibilityCondition(condition, formData));
};

const renderPreviewElement = (element: NodeElement, formData: Record<string, any>, setFormData: (data: Record<string, any>) => void) => {
  switch (element.type) {
    case 'input': {
      const config = element.config as InputConfig;
      return (
        <div className="space-y-2 w-full">
          <Label className="text-center block">{config.label}</Label>
          <Input
            type={config.type}
            placeholder={config.placeholder}
            required={config.required}
            value={formData[element.id] || ''}
            onChange={(e) => setFormData({ ...formData, [element.id]: e.target.value })}
          />
        </div>
      );
    }
    case 'button': {
      const config = element.config as ButtonConfig;
      return (
        <div className="flex justify-center w-full">
          <Button variant={config.variant}>
            {config.label}
          </Button>
        </div>
      );
    }
    case 'text': {
      const config = element.config as TextConfig;
      const className = cn(
        'text-foreground text-center w-full',
        config.variant === 'heading' && 'text-2xl font-bold',
        config.variant === 'subheading' && 'text-xl font-semibold',
        config.variant === 'default' && 'text-base'
      );
      return <p className={className}>{config.content}</p>;
    }
    case 'select': {
      const config = element.config as SelectConfig;
      return (
        <div className="space-y-2 w-full">
          <Label className="text-center block">{config.label}</Label>
          <Select 
            value={formData[element.id] || ''} 
            onValueChange={(value) => setFormData({ ...formData, [element.id]: value })}
          >
            <SelectTrigger>
              <SelectValue placeholder={config.placeholder} />
            </SelectTrigger>
            <SelectContent>
              {config.options.map(option => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      );
    }
    case 'notionGuide': {
      const config = element.config as NotionGuideConfig;
      return (
        <div className="border p-4 rounded-lg w-full text-center">
          <h3 className="font-semibold">{config.title}</h3>
          <p className="text-sm text-muted-foreground">Notion Guide: {config.pageId}</p>
        </div>
      );
    }
    case 'dealData': {
      const config = element.config as DealDataConfig;
      return (
        <div className="border p-4 rounded-lg w-full">
          {config.title && <h3 className="font-semibold mb-2">{config.title}</h3>}
          {config.description && <p className="text-sm text-muted-foreground mb-4">{config.description}</p>}
          <div className={cn(
            'grid gap-4',
            config.layout?.columns === 1 && 'grid-cols-1',
            config.layout?.columns === 2 && 'grid-cols-1 sm:grid-cols-2',
            config.layout?.columns === 3 && 'grid-cols-1 sm:grid-cols-3'
          )}>
            {config.fields?.map((field, index) => (
              <div key={index} className="space-y-1">
                <Label>{field.label}</Label>
                <Input disabled placeholder="Deal data will be populated here" />
              </div>
            ))}
          </div>
        </div>
      );
    }
    case 'taskConfig': {
      const config = element.config as TaskConfigType;
      return (
        <div className="space-y-4">
          {config.recurringTasks && (
            <div>
              <h3 className="font-semibold mb-2">Recurring Tasks</h3>
              <div className="space-y-2">
                {config.recurringTasks.map((task, index) => (
                  <div key={index} className="p-2 border rounded">
                    <p className="font-medium">{task.description}</p>
                    <div className="text-sm text-muted-foreground">
                      <p>Frequency: {task.frequency}</p>
                      <p>Assignee: {task.assignee}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          
          {config.onboardingTasks && (
            <div>
              <h3 className="font-semibold mb-2">Onboarding Tasks</h3>
              <div className="space-y-2">
                {config.onboardingTasks.map((task, index) => (
                  <div key={index} className="p-2 border rounded">
                    <p className="font-medium">{task.description}</p>
                    <div className="text-sm text-muted-foreground">
                      <p>Due Date: {task.due_date}</p>
                      <p>Assignee: {task.assignee}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    }
    default:
      return null;
  }
};

export function PreviewNode({ config, onClose }: PreviewNodeProps) {
  const [formData, setFormData] = useState<Record<string, any>>({});

  return (
    <Dialog open={true} onOpenChange={() => onClose?.()}>
      <DialogContent className="max-w-3xl">
        <DialogHeader className="text-center">
          <DialogTitle className="text-2xl">{config.title}</DialogTitle>
          {config.description && (
            <p className="text-muted-foreground text-center mt-2">{config.description}</p>
          )}
        </DialogHeader>

        <div className="space-y-8 max-w-2xl mx-auto">
          {/* Header Section */}
          {config.header?.notionGuide && (
            <div className="mb-6">
              {renderPreviewElement(
                {
                  id: config.header.id,
                  type: 'notionGuide',
                  config: config.header.notionGuide
                },
                formData,
                setFormData
              )}
            </div>
          )}
          
          {/* Content Rows */}
          <div className="space-y-8">
            {config.rows.map((row) => (
              <div 
                key={row.id} 
                className={cn(
                  'grid gap-6',
                  row.elements.length > 1 && 'grid-cols-1 sm:grid-cols-2'
                )}
              >
                {row.elements.map((element) => (
                  isElementVisible(element, formData) && (
                    <div
                      key={element.id}
                      className={cn(
                        'flex justify-center',
                        element.layout?.width === 'full' && 'col-span-1 sm:col-span-2',
                        element.layout?.width === '1/2' && 'col-span-1',
                        element.layout?.width === '1/3' && 'col-span-1',
                        element.layout?.width === '2/3' && 'col-span-1 sm:col-span-2'
                      )}
                    >
                      {renderPreviewElement(element, formData, setFormData)}
                    </div>
                  )
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-center mt-8">
          <Button variant="outline" onClick={onClose}>
            Close Preview
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}