import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, Routes, Route, useParams, useLocation } from 'react-router-dom';
import { Search, Filter, History, Calendar as CalendarIcon, MoreHorizontal, Eye, Link2, Inbox, Trash2, Star, X, Briefcase, Settings, Plus, SwitchCamera, Users, UserCheck, CheckSquare } from 'lucide-react';
import axios from '../api/axiosConfig';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "@/components/ui/tooltip";

import { Table, ColumnDef } from '@/components/ui/specialized/mod-table';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { toast } from 'sonner';
import { Loading } from "@/components/ui/loading";
import { Badge } from "@/components/ui/badge";
import { Card } from "@/components/ui/card";

import { TaskSchedule } from './modals/TaskSchedule';
import { BoardActivity } from './modals/BoardActivity';
import { CustomBoardActivity } from './modals/CustomBoardActivity';
import { TaskKanban } from './views/TaskKanban';
import { TaskCalendar } from './views/TaskCalendar';
import { useRealTimeUpdates } from '../hooks/useRealTimeUpdates';
import { Task, UnifiedTask, UnifiedTaskType, FrontendTaskType, TaskData } from '@/types/task';
import type { BoardStatusOption } from '@/types/custom-board';
import { StoredUser } from '@/types/user';
import { Board } from '@/types/custom-board';
import { cn } from "@/lib/utils";
import TaskView from './views/TaskView';
import TaskTable from './views/TaskTable';
import { BoardConfigKey } from '@/types/task';
import { DealConfigItem, CardConfig } from '@/types/taskboard';
import { CustomTaskFilter, TaskFilters } from './components/CustomTaskFilter';
import { TaskSorter, SortOption } from './components/TaskSorter';
import { UnifiedColumnDef } from '@/types/unified-board';
import { useUsers } from '@/contexts/UserContext';
import { BoardInfo } from './components/BoardInfo';
import BoardSettings from './custom/modals/BoardSettings';
import CreateTaskModal from './custom/modals/CreateTaskModal';

dayjs.extend(utc);
dayjs.extend(timezone);

const ENABLE_REALTIME = false; // Toggle this to enable/disable real-time updates

interface TaskActionsProps {
  task: Task;
}

interface TaskBoardProps {
  title: string;
  fetchUrl: string;
  columns: UnifiedColumnDef<UnifiedTask>[];
  boardStatusOptions: BoardStatusOption[];
  cardConfig: CardConfig;
  dealDataConfig: DealConfigItem[];
  dealSoftwareConfig: DealConfigItem[];
  taskType: UnifiedTaskType;
  defaultView?: 'table' | 'kanban' | 'calendar';
  users: StoredUser[];
  tasks: UnifiedTask[];
  setTasks: React.Dispatch<React.SetStateAction<UnifiedTask[]>>;
  dealName?: string;
  isCustomBoard?: boolean;
  loading?: boolean;
  boardConfig?: {
    kanban_source?: string;
    progress_status_options?: BoardStatusOption[];
    board_status_options?: BoardStatusOption[];
  };
}

interface ExtendedUnifiedTask extends Omit<UnifiedTask, 'subtasks'> {
  created_at?: string;
  comments?: Array<{
    id: number;
    user_id: number;
    content: string;
    timestamp: string;
  }>;
  updates?: Array<{
    id: number;
    user_id: number;
    type: string;
    timestamp: string;
  }>;
  subtasks?: Array<{
    id: number;
    title: string;
    description?: string;
    work_date?: string | null;
    due_date?: string | null;
    assigned_users?: string[];
    progress_status: string;
    progress_status_id: number;
    created_by: number;
    created_at: string;
    updated_at: string;
    comments?: Array<{
      id: number;
      user_id: number;
      content: string;
      timestamp: string;
    }>;
    updates?: Array<{
      id: number;
      user_id: number;
      type: string;
      timestamp: string;
    }>;
  }>;
}

export function TaskBoards({
  title,
  fetchUrl,
  columns,
  boardStatusOptions,
  cardConfig,
  dealDataConfig,
  dealSoftwareConfig,
  taskType,
  defaultView,
  users: externalUsers,
  tasks: externalTasks,
  setTasks: setExternalTasks,
  dealName = 'Default Deal Name',
  isCustomBoard = false,
  loading = false,
  boardConfig
}: TaskBoardProps) {
  const [filteredTasks, setFilteredTasks] = useState<UnifiedTask[]>([]);
  const [viewMode, setViewMode] = useState<'table' | 'kanban' | 'calendar'>(defaultView || 'table');
  const [searchText, setSearchText] = useState('');
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [isActivityModalVisible, setIsActivityModalVisible] = useState(false);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const [board, setBoard] = useState<Board | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { taskId } = useParams();
  const [users, setUsers] = useState<StoredUser[]>([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState<TaskFilters>({
    assignedUsers: [],
    progressStatus: [],
    boardStatus: [],
    subtaskFilters: [],
    dealDataFilters: [],
    useOrLogic: false
  });
  const [currentSort, setCurrentSort] = useState<SortOption>();
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);
  const [isKanbanReversed, setIsKanbanReversed] = useState(false);
  const { currentUser } = useUsers();

  useEffect(() => {
    const storedUsersData = localStorage.getItem('usersData');
    if (storedUsersData) {
      const usersObject = JSON.parse(storedUsersData);
      const usersArray = Object.values(usersObject) as StoredUser[];
      setUsers(usersArray);
    }
  }, []);

  useEffect(() => {
    if (isCustomBoard) {
      const boardId = fetchUrl.split('/')[3];
      const fetchBoard = async () => {
        try {
          const response = await axios.get(`/custom-board/boards/${boardId}`);
          setBoard(response.data);
        } catch (error) {
          console.error('Error fetching board:', error);
        }
      };
      fetchBoard();
    }
  }, [isCustomBoard, fetchUrl]);

  const fetchTasks = async () => {
    try {
      const response = await axios.get(fetchUrl);
      const tasks = response.data.map((task: any) => {
        // Create the base task object
        const baseTask = {
          ...task,
          task_type: taskType,
          is_custom: isCustomBoard,
          deal_name: task.deal_name || null,
          // Ensure status IDs are numbers
          progress_status_id: task.progress_status_id ? Number(task.progress_status_id) : undefined,
          board_status_id: task.board_status_id ? Number(task.board_status_id) : undefined
        };

        // For custom boards, ensure both status fields are initialized
        if (isCustomBoard && board?.board_config) {
          const { kanban_source, progress_status_options, board_status_options } = board.board_config;
          
          // Initialize both status fields with appropriate defaults
          const defaultProgressStatusId = progress_status_options?.[0]?.id || 1;
          const defaultBoardStatusId = board_status_options?.[0]?.id || 1;
          
          // If either status is missing, use appropriate defaults
          baseTask.progress_status_id = baseTask.progress_status_id || defaultProgressStatusId;
          baseTask.board_status_id = baseTask.board_status_id || defaultBoardStatusId;

          // Get status labels from IDs
          const progressStatus = progress_status_options?.find(opt => opt.id === baseTask.progress_status_id);
          const boardStatus = board_status_options?.find(opt => opt.id === baseTask.board_status_id);

          // Set labels for backwards compatibility
          baseTask.progress_status = progressStatus?.label || 'Not Started';
          baseTask.board_status = boardStatus?.label || 'Low Priority';
        }

        return baseTask;
      });

      // If it's a custom board and subtasks are enabled, ensure we have deal names for all tasks
      if (isCustomBoard && board?.board_config?.enable_subtasks) {
        const tasksWithDeals = await Promise.all(tasks.map(async (task: any) => {
          if (task.deal_id && !task.deal_name) {
            try {
              const dealResponse = await axios.get(`/deals/${task.deal_id}`);
              return {
                ...task,
                deal_name: dealResponse.data.name
              };
            } catch (error) {
              console.error(`Error fetching deal for task ${task.id}:`, error);
              return task;
            }
          }
          return task;
        }));

        setExternalTasks(tasksWithDeals);
      } else {
        setExternalTasks(tasks);
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
      toast.error('Failed to fetch tasks');
    }
  };

  useEffect(() => {
    setExternalTasks([]);
    fetchTasks();
  }, [taskType, fetchUrl, board?.board_config?.enable_subtasks]);

  useEffect(() => {
    filterAndSortTasks();
  }, [externalTasks, searchText, activeFilters, currentSort]);

  const filterAndSortTasks = () => {
    let filtered = externalTasks.filter((task) => {
      const extendedTask = task as ExtendedUnifiedTask;
      // Text search
      const matchesSearch = searchText === '' || Object.values(extendedTask).some(value => 
        String(value).toLowerCase().includes(searchText.toLowerCase())
      );

      if (!matchesSearch) return false;

      if (activeFilters.useOrLogic) {
        // OR logic - at least one filter must match
        if (activeFilters.assignedUsers.length === 0 &&
            activeFilters.progressStatus.length === 0 &&
            (activeFilters.boardStatus?.length || 0) === 0 &&
            (activeFilters.subtaskFilters?.length || 0) === 0 &&
            (activeFilters.dealDataFilters?.length || 0) === 0 &&
            (activeFilters.subtaskAssignedUsers?.length || 0) === 0) {
          return true;
        }

        return (
          // Assigned users
          (activeFilters.assignedUsers.length === 0 || 
            extendedTask.assigned_users?.some(userId => 
              activeFilters.assignedUsers.includes(String(userId))
            )) ||
          
          // Progress status
          (activeFilters.progressStatus.length === 0 ||
            activeFilters.progressStatus.includes(extendedTask.progress_status_id || 0)) ||
          
          // Board status
          ((activeFilters.boardStatus?.length || 0) === 0 ||
            activeFilters.boardStatus?.includes(extendedTask.board_status_id || 0)) ||
          
          // Subtask filters
          ((activeFilters.subtaskFilters?.length || 0) === 0 ||
            activeFilters.subtaskFilters?.some(filter => 
              extendedTask.subtasks?.some(subtask => 
                subtask.title === filter.name &&
                subtask.progress_status_id === filter.statusId
              )
            )) ||
          
          // Subtask assignees
          ((activeFilters.subtaskAssignedUsers?.length || 0) === 0 ||
            extendedTask.subtasks?.some(subtask => 
              subtask.assigned_users?.some(userId =>
                activeFilters.subtaskAssignedUsers?.includes(String(userId))
              )
            )) ||
          
          // Deal data filters
          ((activeFilters.dealDataFilters?.length || 0) === 0 ||
            activeFilters.dealDataFilters?.some(filter => {
              const value = getNestedValue(extendedTask, filter.field);
              return filter.operator === 'equals' 
                ? value === filter.value
                : value !== filter.value;
            }))
        );
      } else {
        // AND logic - all filters must match
        // Assigned users
        if (activeFilters.assignedUsers.length > 0 &&
            !extendedTask.assigned_users?.some(userId => 
              activeFilters.assignedUsers.includes(String(userId))
            )) {
          return false;
        }

        // Progress status
        if (activeFilters.progressStatus.length > 0 &&
            !activeFilters.progressStatus.includes(extendedTask.progress_status_id || 0)) {
          return false;
        }

        // Board status
        if ((activeFilters.boardStatus?.length || 0) > 0 &&
            !activeFilters.boardStatus?.includes(extendedTask.board_status_id || 0)) {
          return false;
        }

        // Subtask filters
        if ((activeFilters.subtaskFilters?.length || 0) > 0 &&
            !activeFilters.subtaskFilters?.every(filter => 
              extendedTask.subtasks?.some(subtask => 
                subtask.title === filter.name &&
                subtask.progress_status_id === filter.statusId
              )
            )) {
          return false;
        }

        // Subtask assignees
        if ((activeFilters.subtaskAssignedUsers?.length || 0) > 0 &&
            !extendedTask.subtasks?.some(subtask => 
              subtask.assigned_users?.some(userId =>
                activeFilters.subtaskAssignedUsers?.includes(String(userId))
              )
            )) {
          return false;
        }

        // Deal data filters
        if ((activeFilters.dealDataFilters?.length || 0) > 0 &&
            !activeFilters.dealDataFilters?.every(filter => {
              const value = getNestedValue(extendedTask, filter.field);
              return filter.operator === 'equals' 
                ? value === filter.value
                : value !== filter.value;
            })) {
          return false;
        }

        return true;
      }
    });

    // Apply sorting
    if (currentSort) {
      filtered.sort((a, b) => {
        const extendedA = a as ExtendedUnifiedTask;
        const extendedB = b as ExtendedUnifiedTask;
        const direction = currentSort.direction === 'asc' ? 1 : -1;
        
        switch (currentSort.field) {
          case 'last_activity':
            const aActivity = getLastActivity(extendedA);
            const bActivity = getLastActivity(extendedB);
            return direction * (
              aActivity && bActivity 
                ? new Date(aActivity).getTime() - new Date(bActivity).getTime()
                : 0
            );
          
          case 'work_date':
            const aWorkDate = extendedA.work_date ? new Date(extendedA.work_date).getTime() : 0;
            const bWorkDate = extendedB.work_date ? new Date(extendedB.work_date).getTime() : 0;
            return direction * (aWorkDate - bWorkDate);
          
          case 'due_date':
            const aDueDate = extendedA.due_date ? new Date(extendedA.due_date).getTime() : 0;
            const bDueDate = extendedB.due_date ? new Date(extendedB.due_date).getTime() : 0;
            return direction * (aDueDate - bDueDate);
          
          default:
            return 0;
        }
      });
    }

    setFilteredTasks(filtered);
  };

  const getLastActivity = (task: ExtendedUnifiedTask): string | null => {
    const dates: string[] = [];
    
    // Add task creation date
    if (task.created_at) {
      dates.push(task.created_at);
    }
    
    // Add comment dates
    if (task.comments?.length) {
      dates.push(...task.comments.map(c => c.timestamp));
    }
    
    // Add update dates
    if (task.updates?.length) {
      dates.push(...task.updates.map(u => u.timestamp));
    }
    
    // Add subtask activity dates
    if (task.subtasks?.length) {
      task.subtasks.forEach(subtask => {
        if (subtask.created_at) dates.push(subtask.created_at);
        if (subtask.comments?.length) {
          dates.push(...subtask.comments.map(c => c.timestamp));
        }
        if (subtask.updates?.length) {
          dates.push(...subtask.updates.map(u => u.timestamp));
        }
      });
    }
    
    if (!dates.length) return null;
    
    return dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0];
  };

  const getNestedValue = (obj: any, path: string): any => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const handleOpenTask = (task: Task) => {
    if (isCustomBoard) {
      navigate(`/custom-boards/${board?.id}/${task.id}`, { 
        state: { 
          tasks: filteredTasks, 
          taskType,
          selectedTask: task 
        }
      });
    } else {
      navigate(`${task.id}`, { 
        state: { 
          tasks: filteredTasks, 
          taskType,
          selectedTask: task 
        }
      });
    }
  };

  const handleCopyTaskLink = (task: Task) => {
    const taskLink = `${window.location.origin}${window.location.pathname}/${task.id}`;
    navigator.clipboard.writeText(taskLink).then(() => {
      toast.success('Task link copied to clipboard');
    }).catch((err) => {
      console.error('Failed to copy task link: ', err);
      toast.error('Failed to copy task link');
    });
  };

  const TaskActions: React.FC<TaskActionsProps> = ({ task }) => (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="h-8 w-8 p-0">
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => handleOpenTask(task)}>
          <Eye className="mr-2 h-4 w-4" />
          Open task
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleCopyTaskLink(task)}>
          <Link2 className="mr-2 h-4 w-4" />
          Copy task link
        </DropdownMenuItem>
        <DropdownMenuItem disabled>
          <Inbox className="mr-2 h-4 w-4" />
          Archive
        </DropdownMenuItem>
        <DropdownMenuItem disabled>
          <Trash2 className="mr-2 h-4 w-4" />
          Delete task
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );

  const mapFrequencyToLabel = (frequency: Task['recurring_type']) => {
    if (!frequency) return 'N/A';
    const mapping: Record<string, string> = {
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      BIWEEKLY: 'Every 2 weeks',
      MONTHLY: 'Monthly',
      QUARTERLY: 'Quarterly',
      YEARLY: 'Yearly'
    };
    return mapping[frequency] || frequency;
  };

  const handleTaskUpdate = async (taskId: string, updatedFields: Partial<UnifiedTask>) => {
    try {
      const endpoint = isCustomBoard 
        ? `/custom-board/tasks/${taskId}`
        : `${fetchUrl}/${taskId}`;
      
      const response = await axios.put<UnifiedTask>(endpoint, updatedFields);
      
      // Find the existing task to preserve its properties
      const existingTask = externalTasks.find(t => t.id.toString() === taskId);
      if (!existingTask) return;

      const updatedTask = {
        ...existingTask,
        ...response.data,
        is_custom: existingTask.is_custom,
        task_type: existingTask.task_type,
        board_config: existingTask.board_config
      };

      setExternalTasks((prevTasks: UnifiedTask[]) => 
        prevTasks.map(task => 
          task.id.toString() === taskId ? updatedTask : task
        )
      );
    } catch (error) {
      console.error('Error updating task:', error);
      throw error;
    }
  };

  const formatDate = (dateString: string) => {
    return dayjs(dateString).format('YYYY-MM-DD');
  };

  const handleAssigneeUpdate = async (taskId: number, newAssignees: string[]) => {
    try {
      if (isCustomBoard) {
        // For custom board tasks, use the custom board task update endpoint
        const response = await axios.put(`/custom-board/tasks/${taskId}`, {
          assigned_users: newAssignees
        });
        
        if (response.status === 200) {
          toast.success('Assignees updated successfully');
          setExternalTasks(prevTasks => 
            prevTasks.map(task => 
              task.id === taskId 
                ? { ...task, assigned_users: newAssignees }
                : task
            )
          );
        }
      } else {
        // For normal tasks, use the work update assignees endpoint
        const backendTaskType = (() => {
          if (['bogforing', 'lon', 'moms', 'arsafslutning'].includes(taskType)) {
            return 'recurring';
          } else if (taskType === 'andre-opgaver') {
            return 'other';
          } else if (taskType === 'activities') {
            return 'activity';
          }
          return taskType;
        })();

        const response = await axios.put(`/work/update_assignees`, {
          task_id: taskId,
          task_type: backendTaskType,
          assignees: newAssignees
        });
        
        if (response.status === 200) {
          toast.success('Assignees updated successfully');
          setExternalTasks(prevTasks => 
            prevTasks.map(task => 
              task.id === taskId 
                ? { ...task, assigned_users: newAssignees }
                : task
            )
          );
        }
      }
    } catch (error) {
      console.error('Error updating assignees:', error);
      toast.error('Failed to update assignees');
    }
  };

  const renderTableView = () => {
    if (loading) {
      return <Loading message="Loading tasks..." size="default" />;
    }

    if (!filteredTasks?.length) {
      return (
        <div className="flex h-[50vh] items-center justify-center text-muted-foreground">
          {searchText ? 'No tasks found matching your search' : 'No tasks found'}
        </div>
      );
    }

    const dataSource = filteredTasks.map(task => ({
      ...task,
      key: task.id.toString(),
      next_work_date: formatDate(task.next_work_date || ''),
      next_due_date: formatDate(task.next_due_date || ''),
    }));

    return (
      <TaskTable
        dataSource={dataSource}
        columns={columns}
        handleOpenTask={handleOpenTask}
        loading={loading}
        onAssigneeUpdate={handleAssigneeUpdate}
        board={board}
        onSubtaskClick={handleSubtaskClick}
        onDataSourceChange={setExternalTasks}
      />
    );
  };

  const handleSubtaskClick = (taskId: number, subtaskId: number) => {
    // Update URL without navigation
    window.history.pushState(
      {}, 
      '', 
      `/custom-boards/${board?.id}/${taskId}/${subtaskId}`
    );
  };

  useEffect(() => {
    // Listen for popstate to handle browser back/forward
    const handlePopState = () => {
      const match = window.location.pathname.match(/\/custom-boards\/\d+\/task\/(\d+)\/subtask\/(\d+)/);
      if (!match) {
        // Reset URL to board view if no subtask in path
        window.history.pushState(
          {}, 
          '', 
          `/custom-boards/${board?.id}`
        );
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [board?.id]);

  const renderKanbanView = () => {
    if (!Array.isArray(filteredTasks)) {
      console.error('Tasks is not an array:', filteredTasks);
      return <div>Loading...</div>;
    }
    
    return (
      <div className="relative -mx-4 overflow-visible">
        <div className="before:absolute before:left-0 before:top-0 before:bottom-0 before:w-4 before:bg-gradient-to-r before:from-background before:via-background/80 before:to-transparent before:z-10 
                              after:absolute after:right-0 after:top-0 after:bottom-0 after:w-4 after:bg-gradient-to-l after:from-background after:via-background/80 after:to-transparent after:z-10
                              before:pointer-events-none after:pointer-events-none">
          <div className="before:absolute before:left-0 before:right-0 before:top-0 before:h-4 before:bg-gradient-to-b before:from-background before:via-background/80 before:to-transparent before:z-10
                                after:absolute after:left-0 after:right-0 after:bottom-0 after:h-4 after:bg-gradient-to-t after:from-background after:via-background/80 after:to-transparent after:z-10
                                before:pointer-events-none after:pointer-events-none">
            <TaskKanban
              tasks={filteredTasks}
              onTaskUpdate={handleTaskUpdate}
              onAssigneeUpdate={handleAssigneeUpdate}
              boardStatusOptions={isCustomBoard 
                ? (isKanbanReversed 
                    ? (board?.board_config?.kanban_source === 'progress' 
                        ? board?.board_config?.board_status_options || [] 
                        : board?.board_config?.progress_status_options || [])
                    : (board?.board_config?.kanban_source === 'progress'
                        ? board?.board_config?.progress_status_options || []
                        : board?.board_config?.board_status_options || []))
                : boardStatusOptions}
              cardConfig={cardConfig}
              dealDataConfig={dealDataConfig}
              dealSoftwareConfig={dealSoftwareConfig}
              taskType={taskType}
              setTasks={setExternalTasks}
              loading={loading}
              board={board}
              isKanbanReversed={isKanbanReversed}
              onSubtaskClick={handleSubtaskClick}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCalendarView = () => (
    <TaskCalendar tasks={filteredTasks} />
  );

  const showScheduleButton = ['bogforing', 'lon', 'moms', 'arsafslutning'].includes(taskType);

  useRealTimeUpdates(taskType, null, (updatedTask: Task) => {
    if (!ENABLE_REALTIME) return;
    
    if (updatedTask && updatedTask.id) {
      setExternalTasks((prevTasks: Task[]) => 
        prevTasks.map(task => 
          task.id === updatedTask.id ? updatedTask : task
        )
      );
    }
  });

  const enhancedColumns: UnifiedColumnDef<UnifiedTask>[] = [
    {
      key: 'actions',
      title: '',
      dataIndex: 'actions' as keyof UnifiedTask,
      cell: ({ row }: { row: { original: UnifiedTask } }) => <TaskActions task={row.original} />,
    },
    ...columns.map((col: UnifiedColumnDef<UnifiedTask>) => ({
      ...col,
      cell: ({ row }: { row: { original: UnifiedTask } }) => {
        const isClickable = col.dataIndex === 'deal_name' || col.dataIndex === 'description';
        const value = row.original[col.dataIndex];
        
        if (col.dataIndex === 'recurring_type') {
          return mapFrequencyToLabel(value as string);
        }

        const displayValue = (() => {
          if (value === null || value === undefined) {
            return '';
          }
          if (typeof value === 'object') {
            if ('Task' in value) {
              return (value as TaskData).Task || '';
            }
            return JSON.stringify(value);
          }
          return String(value);
        })();

        return (
          <div
            className={isClickable ? 'cursor-pointer' : undefined}
            onClick={isClickable ? () => handleOpenTask(row.original) : undefined}
          >
            {col.render ? col.render(value) : displayValue}
          </div>
        );
      }
    }))
  ];

  const handleDealClick = (e: React.MouseEvent, dealId: number) => {
    e.stopPropagation();
    navigate(`/customers/deals?id=${dealId}`);
  };

  const renderTaskContent = (task: UnifiedTask) => {
    const displayTitle = task.is_custom ? task.title : (task.data?.Task || task.description || '');
    
    return (
      <Card 
        className="w-full p-4 hover:shadow-md transition-shadow relative group cursor-grab active:cursor-grabbing"
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
      >
        <div className="space-y-4">
          {/* Header Section - Centered Content */}
          <div className="flex flex-col items-center text-center">
            {task.deal_name && (
              <Badge 
                variant="secondary"
                className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-deal))] text-white hover:bg-[hsl(var(--badge-deal)/.8)]"
                onClick={(e) => {
                  e.stopPropagation();
                  task.deal_id && handleDealClick(e, task.deal_id);
                }}
              >
                <Briefcase className="w-3 h-3 flex-shrink-0 mr-1" />
                <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {task.deal_name}
                </span>
              </Badge>
            )}
            <p className="text-sm text-primary font-medium mt-2 line-clamp-2">
              {displayTitle}
            </p>
          </div>
        </div>
      </Card>
    );
  };

  const handleBoardUpdate = (updatedBoard: Board) => {
    setBoard(updatedBoard);
  };

  const handleCreateTask = async (values: Partial<Task> | Partial<Task>[]) => {
    try {
      // Extract board slug from fetchUrl
      const boardSlug = fetchUrl.split('/')[3];
      
      const isProgressKanban = board?.board_config?.kanban_source === 'progress';
      const statusOptions = isProgressKanban 
        ? board?.board_config?.progress_status_options 
        : board?.board_config?.board_status_options;
      const initialStatusId = board?.board_config?.progress_status_options?.[0]?.id ?? 1;
      const initialBoardStatusId = board?.board_config?.board_status_options?.[0]?.id;

      // Handle bulk task creation
      if (Array.isArray(values)) {
        const tasksData = values.map(value => ({
          ...value,
          board_id: board?.id, // Use board ID from board object
          progress_status_id: value.progress_status_id || initialStatusId,
          board_status_id: value.board_status_id || (isProgressKanban ? undefined : initialBoardStatusId),
          task_type: 'custom' as const,
          is_custom: true,
          board_config: board?.board_config
        }));

        const response = await axios.post(`/custom-board/boards/${board?.id}/tasks`, tasksData);
        
        if (response.status === 200) {
          toast.success('Tasks created successfully');
          
          // Add all new tasks to state with complete data from response
          const newTasks = response.data.map((task: any) => ({
            ...task,
            task_type: 'custom',
            is_custom: true,
            board_config: board?.board_config,
            // Ensure these fields are included from the response
            deal_id: task.deal_id,
            deal_name: task.deal_name,
            progress_status_id: task.progress_status_id,
            board_status_id: task.board_status_id
          }));
          
          setExternalTasks(prevTasks => [...prevTasks, ...newTasks]);
        }
      }
      // Handle single task creation
      else {
        const taskData = {
          ...values,
          board_id: board?.id, // Use board ID from board object
          progress_status_id: values.progress_status_id || initialStatusId,
          board_status_id: values.board_status_id || (isProgressKanban ? undefined : initialBoardStatusId),
          task_type: 'custom' as const,
          is_custom: true,
          board_config: board?.board_config
        };

        const response = await axios.post(`/custom-board/boards/${board?.id}/tasks`, taskData);
        
        if (response.status === 200) {
          toast.success('Task created successfully');
          
          // Add the new task to state with complete data from response
          const newTask = {
            ...response.data,
            task_type: 'custom',
            is_custom: true,
            board_config: board?.board_config,
            // Ensure these fields are included from the response
            deal_id: response.data.deal_id,
            deal_name: response.data.deal_name,
            progress_status_id: response.data.progress_status_id,
            board_status_id: response.data.board_status_id
          };
          
          setExternalTasks(prevTasks => [...prevTasks, newTask]);
        }
      }
    } catch (error) {
      console.error('Error creating task:', error);
      toast.error('Failed to create task');
    }
  };

  const canReverseKanban = () => {
    if (!board?.board_config) return false;
    const { progress_status_options, board_status_options } = board.board_config;
    return taskType === 'custom' && 
           Array.isArray(progress_status_options) && progress_status_options.length > 0 && 
           Array.isArray(board_status_options) && board_status_options.length > 0;
  };

  // Update the isAdmin check to use currentUser from useUsers
  const isAdmin = useMemo(() => {
    if (!currentUser) return false;
    return board?.created_by === currentUser.user_id || 
           board?.access_permissions?.some(p => p.user_id === currentUser.user_id && p.is_admin);
  }, [board, currentUser]);

  const handleFiltersChange = (newFilters: TaskFilters) => {
    setActiveFilters(newFilters);
  };

  const renderFilterBadges = () => {
    // Group filters by category
    const filterGroups = {
      assignees: activeFilters.assignedUsers.map(userId => ({
        type: 'assignee',
        id: userId,
        label: users.find(u => String(u.user_id) === userId)?.name || '',
      })),
      subtaskAssignees: (activeFilters.subtaskAssignedUsers || []).map(userId => ({
        type: 'subtask_assignee',
        id: userId,
        label: users.find(u => String(u.user_id) === userId)?.name || '',
      })),
      progressStatus: activeFilters.progressStatus.map(statusId => ({
        type: 'progress',
        id: statusId,
        label: board?.board_config?.progress_status_options.find(s => s.id === statusId)?.label || '',
      })),
      boardStatus: (activeFilters.boardStatus || []).map(statusId => ({
        type: 'board',
        id: statusId,
        label: board?.board_config?.board_status_options.find(s => s.id === statusId)?.label || '',
      })),
      subtaskStatus: (activeFilters.subtaskFilters || []).reduce((acc, filter) => {
        const subtaskName = filter.name;
        const existingGroup = acc.find(g => g.subtaskName === subtaskName);
        const statusLabel = board?.subtask_config?.predefined_subtasks
          ?.find(s => s.name === filter.name)
          ?.status_options.find(s => s.id === filter.statusId)?.label;

        if (existingGroup) {
          existingGroup.filters.push({
            type: 'subtask',
            id: `${filter.name}-${filter.statusId}`,
            label: statusLabel || '',
          });
        } else {
          acc.push({
            subtaskName,
            filters: [{
              type: 'subtask',
              id: `${filter.name}-${filter.statusId}`,
              label: statusLabel || '',
            }]
          });
        }
        return acc;
      }, [] as { subtaskName: string; filters: Array<{ type: string; id: string; label: string }> }[]),
      dealData: (activeFilters.dealDataFilters || []).reduce((acc, filter) => {
        const fieldKey = filter.field;
        const existingGroup = acc.find(g => g.fieldKey === fieldKey);
        const fieldConfig = dealDataConfig.find(f => f.key === filter.field);
        const optionLabel = fieldConfig?.options?.find(o => o.value === filter.value)?.label;
        const label = `${optionLabel}${filter.operator === 'not_equals' ? ' ≠' : ''}`;

        if (existingGroup) {
          existingGroup.filters.push({
            type: 'deal',
            id: `${filter.field}-${filter.value}-${filter.operator}`,
            label,
          });
        } else {
          acc.push({
            fieldKey,
            fieldLabel: fieldConfig?.label || '',
            filters: [{
              type: 'deal',
              id: `${filter.field}-${filter.value}-${filter.operator}`,
              label,
            }]
          });
        }
        return acc;
      }, [] as { fieldKey: string; fieldLabel: string; filters: Array<{ type: string; id: string; label: string }> }[]),
    };

    const hasAnyFilters = Object.values(filterGroups).some(group => 
      Array.isArray(group) ? group.length > 0 : Object.keys(group).length > 0
    );

    if (!hasAnyFilters) return null;

    // Create a flat array of all filter badges
    const allBadges = [
      // Assignees
      filterGroups.assignees.length > 0 && (
        <Badge 
          key="assignees"
          variant="secondary"
          className="flex items-center gap-1"
        >
          <Users className="h-3 w-3" />
          <span className="max-w-[300px] truncate">
            {filterGroups.assignees.map(a => a.label).join(', ')}
          </span>
          <Button
            variant="ghost"
            size="icon"
            className="h-4 w-4 p-0"
            onClick={() => setActiveFilters(prev => ({ ...prev, assignedUsers: [] }))}
          >
            <X className="h-3 w-3" />
          </Button>
        </Badge>
      ),

      // Subtask Assignees
      filterGroups.subtaskAssignees.length > 0 && (
        <Badge 
          key="subtask-assignees"
          variant="secondary"
          className="flex items-center gap-1"
        >
          <UserCheck className="h-3 w-3" />
          <span className="max-w-[300px] truncate">
            Subtask: {filterGroups.subtaskAssignees.map(a => a.label).join(', ')}
          </span>
          <Button
            variant="ghost"
            size="icon"
            className="h-4 w-4 p-0"
            onClick={() => setActiveFilters(prev => ({ ...prev, subtaskAssignedUsers: [] }))}
          >
            <X className="h-3 w-3" />
          </Button>
        </Badge>
      ),

      // Progress Status
      filterGroups.progressStatus.length > 0 && (
        <Badge 
          key="progress-status"
          variant="secondary"
          className="flex items-center gap-1"
        >
          <Filter className="h-3 w-3" />
          <span className="max-w-[300px] truncate">
            Progress: {filterGroups.progressStatus.map(s => s.label).join(', ')}
          </span>
          <Button
            variant="ghost"
            size="icon"
            className="h-4 w-4 p-0"
            onClick={() => setActiveFilters(prev => ({ ...prev, progressStatus: [] }))}
          >
            <X className="h-3 w-3" />
          </Button>
        </Badge>
      ),

      // Board Status
      filterGroups.boardStatus.length > 0 && (
        <Badge 
          key="board-status"
          variant="secondary"
          className="flex items-center gap-1"
        >
          <Filter className="h-3 w-3" />
          <span className="max-w-[300px] truncate">
            Board: {filterGroups.boardStatus.map(s => s.label).join(', ')}
          </span>
          <Button
            variant="ghost"
            size="icon"
            className="h-4 w-4 p-0"
            onClick={() => setActiveFilters(prev => ({ ...prev, boardStatus: [] }))}
          >
            <X className="h-3 w-3" />
          </Button>
        </Badge>
      ),

      // Subtask Status
      ...filterGroups.subtaskStatus.map(group => (
        <Badge 
          key={`subtask-${group.subtaskName}`}
          variant="secondary"
          className="flex items-center gap-1"
        >
          <CheckSquare className="h-3 w-3" />
          <span className="max-w-[300px] truncate">
            {group.subtaskName}: {group.filters.map(f => f.label).join(', ')}
          </span>
          <Button
            variant="ghost"
            size="icon"
            className="h-4 w-4 p-0"
            onClick={() => setActiveFilters(prev => ({
              ...prev,
              subtaskFilters: (prev.subtaskFilters || []).filter(f => f.name !== group.subtaskName)
            }))}
          >
            <X className="h-3 w-3" />
          </Button>
        </Badge>
      )),

      // Deal Data
      ...filterGroups.dealData.map(group => (
        <Badge 
          key={`deal-${group.fieldKey}`}
          variant="secondary"
          className="flex items-center gap-1"
        >
          <Briefcase className="h-3 w-3" />
          <span className="max-w-[300px] truncate">
            {group.fieldLabel}: {group.filters.map(f => f.label).join(', ')}
          </span>
          <Button
            variant="ghost"
            size="icon"
            className="h-4 w-4 p-0"
            onClick={() => setActiveFilters(prev => ({
              ...prev,
              dealDataFilters: (prev.dealDataFilters || []).filter(f => f.field !== group.fieldKey)
            }))}
          >
            <X className="h-3 w-3" />
          </Button>
        </Badge>
      ))
    ].filter(Boolean);

    const MAX_VISIBLE_FILTERS = 4;
    const visibleBadges = allBadges.slice(0, MAX_VISIBLE_FILTERS);
    const remainingBadges = allBadges.slice(MAX_VISIBLE_FILTERS);

    // Create simplified versions of remaining badges for tooltip
    const remainingBadgeInfo = remainingBadges
      .filter((badge): badge is React.ReactElement => React.isValidElement(badge))
      .map(badge => {
        const spanElement = badge.props.children.find(
          (child: React.ReactNode): child is React.ReactElement => 
            React.isValidElement(child) && child.type === 'span'
        );
        return spanElement?.props.children || '';
      });

    return (
      <div className="flex flex-wrap gap-2 items-center">
        {visibleBadges}
        
        {/* Show remaining count in tooltip */}
        {remainingBadges.length > 0 && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Badge 
                  variant="outline"
                  className="cursor-help"
                >
                  +{remainingBadges.length} more filters
                </Badge>
              </TooltipTrigger>
              <TooltipContent 
                side="bottom" 
                align="start"
                className="max-w-sm p-2"
              >
                <div className="space-y-1">
                  {remainingBadgeInfo.map((content, index) => (
                    <div key={index} className="text-sm">
                      {content}
                    </div>
                  ))}
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}

        {/* Clear all filters button */}
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setActiveFilters({
            assignedUsers: [],
            subtaskAssignedUsers: [],
            progressStatus: [],
            boardStatus: [],
            subtaskFilters: [],
            dealDataFilters: [],
            useOrLogic: false
          })}
        >
          Clear Filters
        </Button>
      </div>
    );
  };

  return (
    <div className="p-4 pt-0 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        {isCustomBoard && board?.description ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <h1 className="text-2xl text-primary cursor-help">{title}</h1>
              </TooltipTrigger>
              <TooltipContent>
                <p>{board.description}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          <h1 className="text-2xl text-primary">{title}</h1>
        )}
        <BoardInfo 
          isCustomBoard={isCustomBoard}
          board={board}
          taskType={taskType}
        />
      </div>
      
      <div className="flex items-center gap-2 mb-6">
        <Tabs 
          defaultValue={defaultView || 'table'} 
          onValueChange={(value) => {
            setViewMode(value as 'table' | 'kanban' | 'calendar');
            // Reset kanban reversal when switching away from kanban view
            if (value !== 'kanban') {
              setIsKanbanReversed(false);
            }
          }} 
          className="w-auto"
        >
          <TabsList>
            <TabsTrigger value="table">
              Table
              {defaultView === 'table' && <Star className="ml-1 h-3 w-3 text-primary" />}
            </TabsTrigger>
            <TabsTrigger value="kanban">
              Kanban
              {defaultView === 'kanban' && <Star className="ml-1 h-3 w-3 text-primary" />}
            </TabsTrigger>
            <TabsTrigger value="calendar" disabled>
              Calendar
              {defaultView === 'calendar' && <Star className="ml-1 h-3 w-3 text-primary" />}
            </TabsTrigger>
          </TabsList>
        </Tabs>
        {viewMode === 'kanban' && canReverseKanban() && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setIsKanbanReversed(!isKanbanReversed)}
                  className={cn(
                    "h-7 w-7",
                    isKanbanReversed && "bg-primary text-primary-foreground hover:bg-primary/90"
                  )}
                >
                  <SwitchCamera className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>
                  {isKanbanReversed 
                    ? `Switch back to ${board?.board_config?.kanban_source === 'progress' ? 'progress' : 'board'} status view`
                    : `Switch to ${board?.board_config?.kanban_source === 'progress' ? 'board' : 'progress'} status view`}
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>

      <div className="flex justify-between items-start mb-6">
        {/* Top row with search and actions */}
        <div className="flex-1 flex gap-2 items-center">
          <div className="relative w-[300px]">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search by company or task name..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="pl-8"
            />
          </div>
          <Button 
            variant="outline" 
            size="icon"
            onClick={() => setIsFilterModalOpen(true)}
          >
            <Filter className="h-4 w-4" />
          </Button>
          <TaskSorter
            currentSort={currentSort}
            onSortChange={setCurrentSort}
          />
        </div>
        <div className="flex gap-2">
          {isCustomBoard && (
            <Button onClick={() => setIsCreateTaskModalOpen(true)}>
              <Plus className="mr-2 h-4 w-4" />
              Create Task
            </Button>
          )}
          <Button variant="outline" onClick={() => setIsActivityModalVisible(true)}>
            <History className="mr-2 h-4 w-4" />
            Activity
          </Button>
          {isCustomBoard && isAdmin && (
            <Button variant="outline" onClick={() => setIsSettingsModalVisible(true)}>
              <Settings className="mr-2 h-4 w-4" />
              Settings
            </Button>
          )}
          {showScheduleButton && (
            <Button variant="outline" onClick={() => setIsScheduleModalVisible(true)}>
              <CalendarIcon className="mr-2 h-4 w-4" />
              Schedule
            </Button>
          )}
        </div>
      </div>

      {/* Active filters row */}
      {renderFilterBadges() && (
        <div className="mb-6">
          {renderFilterBadges()}
        </div>
      )}

      {!taskId && (
        <>
          {viewMode === 'table' && (
            <TaskTable
              dataSource={filteredTasks}
              columns={columns}
              handleOpenTask={handleOpenTask}
              loading={loading}
              onAssigneeUpdate={handleAssigneeUpdate}
              board={board}
              onSubtaskClick={handleSubtaskClick}
              onDataSourceChange={setExternalTasks}
            />
          )}
          {viewMode === 'kanban' && (
            <div className="relative -mx-4 overflow-visible">
              <div className="before:absolute before:left-0 before:top-0 before:bottom-0 before:w-4 before:bg-gradient-to-r before:from-background before:via-background/80 before:to-transparent before:z-10 
                            after:absolute after:right-0 after:top-0 after:bottom-0 after:w-4 after:bg-gradient-to-l after:from-background after:via-background/80 after:to-transparent after:z-10
                            before:pointer-events-none after:pointer-events-none">
                <div className="before:absolute before:left-0 before:right-0 before:top-0 before:h-4 before:bg-gradient-to-b before:from-background before:via-background/80 before:to-transparent before:z-10
                              after:absolute after:left-0 after:right-0 after:bottom-0 after:h-4 after:bg-gradient-to-t after:from-background after:via-background/80 after:to-transparent after:z-10
                              before:pointer-events-none after:pointer-events-none">
                  <TaskKanban
                    tasks={filteredTasks}
                    onTaskUpdate={handleTaskUpdate}
                    onAssigneeUpdate={handleAssigneeUpdate}
                    boardStatusOptions={isCustomBoard 
                      ? (isKanbanReversed 
                          ? (board?.board_config?.kanban_source === 'progress' 
                              ? board?.board_config?.board_status_options || [] 
                              : board?.board_config?.progress_status_options || [])
                          : (board?.board_config?.kanban_source === 'progress'
                              ? board?.board_config?.progress_status_options || []
                              : board?.board_config?.board_status_options || []))
                      : boardStatusOptions}
                    cardConfig={cardConfig}
                    dealDataConfig={dealDataConfig}
                    dealSoftwareConfig={dealSoftwareConfig}
                    taskType={taskType}
                    setTasks={setExternalTasks}
                    loading={loading}
                    board={board}
                    isKanbanReversed={isKanbanReversed}
                    onSubtaskClick={handleSubtaskClick}
                  />
                </div>
              </div>
            </div>
          )}
          {viewMode === 'calendar' && <TaskCalendar tasks={filteredTasks} />}
        </>
      )}

      <Dialog open={isScheduleModalVisible} onOpenChange={setIsScheduleModalVisible}>
        <DialogContent className="max-w-4xl">
          <TaskSchedule onClose={() => setIsScheduleModalVisible(false)} />
        </DialogContent>
      </Dialog>

      {isCustomBoard ? (
        <CustomBoardActivity 
          open={isActivityModalVisible}
          onClose={() => setIsActivityModalVisible(false)} 
          board={board}
        />
      ) : (
        <BoardActivity 
          open={isActivityModalVisible}
          onClose={() => setIsActivityModalVisible(false)} 
          taskType={taskType as FrontendTaskType}
        />
      )}

      {isCustomBoard && board && (
        <BoardSettings
          open={isSettingsModalVisible}
          onClose={() => setIsSettingsModalVisible(false)}
          board={board}
          onBoardUpdate={handleBoardUpdate}
        />
      )}

      <Routes>
        <Route 
          path=":taskId" 
          element={
            <TaskView 
              onClose={() => navigate(`/boards/${taskType}`, { replace: true })}
              tasks={filteredTasks}
              taskType={taskType}
              boardStatusOptions={boardStatusOptions}
              task={(location.state as any)?.selectedTask || filteredTasks.find(t => t.id.toString() === taskId)}
              fromWork={false}
              dealName={dealName}
              getBoardName={() => taskType}
            />
          } 
        />
      </Routes>

      <CustomTaskFilter
        open={isFilterModalOpen}
        onOpenChange={setIsFilterModalOpen}
        onFiltersChange={handleFiltersChange}
        board={board}
        currentFilters={activeFilters}
      />

      {isCustomBoard && board && (
        <CreateTaskModal
          open={isCreateTaskModalOpen}
          onClose={() => setIsCreateTaskModalOpen(false)}
          onSubmit={handleCreateTask}
          board={board}
        />
      )}
    </div>
  );
}

export default TaskBoards;