import { Bar, BarChart, XAxis, YAxis, Cell } from "recharts"
import { LucideIcon } from "lucide-react"
import { ResponsiveContainer } from "recharts"
import { useResizeObserver } from "@/hooks/use-resize-observer"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui"

interface BarChartProps {
  data: Array<{
    name: string
    value: number
    fill: string
    [key: string]: any  // Allow additional properties
  }>
  config: ChartConfig
  title?: string
  description?: string
  valueFormatter?: (value: number) => string
  footerContent?: React.ReactNode
  trendIcon?: LucideIcon
  trendText?: string
  trendDescription?: string
  className?: string
  maxLabelLength?: number
  colorMode?: "theme" | "multi"
}

interface BarData {
  name: string;
  value: number;
  fill: string;
  displayName: string;
  originalName: string;
}

export function BarChartHorizontalMix({
  data,
  config,
  title,
  description,
  valueFormatter = (value) => value.toString(),
  maxLabelLength = 20,
  footerContent,
  trendIcon: TrendIcon,
  trendText,
  trendDescription,
  className,
  colorMode = "multi"
}: BarChartProps) {
  const [containerRef, { width }] = useResizeObserver<HTMLDivElement>()
  const MINIMUM_WIDTH_FOR_LABELS = 400
  const CHART_HEIGHT = 350

  const getLongestLabel = () => {
    return Math.min(
      Math.max(...data.map(item => item.name.length)),
      maxLabelLength
    ) * 4;
  };

  const truncateLabel = (text: string) => {
    if (text.length <= maxLabelLength) return text;
    return `${text.slice(0, maxLabelLength)}...`;
  };

  const processedData = data.map((item, index) => ({
    ...item,
    displayName: truncateLabel(item.name),
    originalName: item.name,
    fill: colorMode === "theme" 
      ? `hsl(var(--chart-${(index % 9) + 1}))`
      : `hsl(var(--chart-multi-${(index % 5) + 1}))`,
  }));

  // Calculate dynamic bar size based on container width
  const barSize = Math.max(
    Math.min(width / (data.length * 2), 20),
    16 // Minimum bar thickness
  )

  return (
    <Card className={`h-full flex flex-col ${className}`}>
      {(title || description) && (
        <CardHeader className="flex-none">
          {title && <CardTitle>{title}</CardTitle>}
          {description && <CardDescription>{description}</CardDescription>}
        </CardHeader>
      )}
      <CardContent 
        ref={containerRef}
        className="flex-1 min-h-0"
      >
        <div className="h-full w-full">
          <ChartContainer config={config}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={processedData}
                layout="vertical"
                margin={{
                  left: width >= MINIMUM_WIDTH_FOR_LABELS ? getLongestLabel() : 8,
                  right: 8,
                  top: 8,
                  bottom: 8
                }}
                barSize={barSize}
              >
                <YAxis
                  dataKey="displayName"
                  type="category"
                  tickLine={false}
                  tickMargin={4}
                  axisLine={false}
                  width={width >= MINIMUM_WIDTH_FOR_LABELS ? getLongestLabel() : 0}
                  interval={0}
                  tick={({ x, y, payload }) => {
                    if (width < MINIMUM_WIDTH_FOR_LABELS) {
                      return (
                        <g>
                          <text
                            x={x}
                            y={y}
                            dy={4}
                            textAnchor="end"
                            fill="currentColor"
                            className="text-xs text-muted-foreground opacity-0"
                          >
                            {payload.value}
                          </text>
                        </g>
                      );
                    }
                    return (
                      <g>
                        <title>{payload.originalName}</title>
                        <text
                          x={x}
                          y={y}
                          dy={4}
                          textAnchor="end"
                          fill="currentColor"
                          className="text-xs text-muted-foreground"
                        >
                          {payload.value}
                        </text>
                      </g>
                    )
                  }}
                />
                <XAxis 
                  dataKey="value" 
                  type="number" 
                  tickFormatter={valueFormatter}
                />
                <ChartTooltip
                  cursor={false}
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div className="rounded-lg border border-border bg-background p-2 shadow-sm">
                          <div className="grid grid-cols-2 gap-2">
                            <div className="flex flex-col">
                              <span className="text-[0.70rem] uppercase text-muted-foreground">
                                {payload[0].payload.originalName}
                              </span>
                              <span className="font-bold text-muted-foreground">
                                {valueFormatter(payload[0].value as number)}
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    }
                    return null
                  }}
                />
                <Bar 
                  dataKey="value" 
                  radius={[4, 4, 0, 0]}
                  fillOpacity={1}
                >
                  {processedData.map((entry, index) => (
                    <Cell 
                      key={`cell-${index}`}
                      fill={entry.fill}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </ChartContainer>
        </div>
      </CardContent>
      {(trendText || trendDescription || footerContent) && (
        <CardFooter className="flex-col items-start gap-2 text-sm">
          {trendText && (
            <div className="flex gap-2 font-medium leading-none">
              {trendText} {TrendIcon && <TrendIcon className="h-4 w-4" />}
            </div>
          )}
          {trendDescription && (
            <div className="leading-none text-muted-foreground">
              {trendDescription}
            </div>
          )}
          {footerContent}
        </CardFooter>
      )}
    </Card>
  )
}
