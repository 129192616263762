import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../api/axiosConfig'
import { toast } from 'sonner'
import { format } from "date-fns"

// UI Components
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Loading } from "@/components/ui/loading"
import {
  Table,
  type ColumnDef
} from "@/components/ui/specialized/mod-table"
import { Input } from "@/components/ui/input"

// Icons
import {
  Users,
  ShoppingBag,
  DollarSign,
  Hash,
  Calendar,
  Clock,
  ShoppingCart,
  FilePlus,
  ClockIcon,
  LayoutGrid,
  Briefcase,
  Building2,
  UserCircle2,
  Package,
  Copy,
  Mail,
  Phone,
  MoreHorizontal,
  Trash2,
  X
} from 'lucide-react'

// Types
import type { Deal, Product, Contact, Account } from '../types/customers'

// Components
import ImportDeals from './modals/ImportDeals'
import DealDetails from './modals/DealDetails'
import { DealDrawer }from '../drawers/DealDrawer'
import { ProductsDrawer } from '../drawers/ProductsDrawer'
import type { Deal as ImportDeal } from './modals/ImportDeals'
import { DeleteDeal } from './modals/DeleteDeal'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { DealFilter, type DealFilters } from '@/components/DealFilter'

const ProductItem = ({ product }: { product: Product }) => (
  <div className="flex flex-col gap-2 p-4 border rounded-lg">
    <TooltipProvider>
      <div className="space-y-2">
        <Tooltip>
          <TooltipTrigger asChild>
            <h4 className="font-semibold">{product.name}</h4>
          </TooltipTrigger>
          <TooltipContent>Product Name: {product.name}</TooltipContent>
        </Tooltip>

        <div className="grid grid-cols-2 gap-2 text-sm">
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2">
                <ShoppingCart className="w-4 h-4" />
                <span>Code: {product.code}</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>Product Code: {product.code}</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2">
                <DollarSign className="w-4 h-4" />
                <span>Price: {product.item_price}</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>Price: {product.item_price}</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2">
                <Hash className="w-4 h-4" />
                <span>Qty: {product.quantity}</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>Quantity: {product.quantity}</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2">
                <DollarSign className="w-4 h-4" />
                <span>Sum: {product.item_price * product.quantity}</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>Sum: {product.item_price * product.quantity}</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2">
                <Calendar className="w-4 h-4" />
                <span>Start: {product.billing_start_date || 'N/A'}</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>Billing Start Date: {product.billing_start_date || 'N/A'}</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2">
                <Clock className="w-4 h-4" />
                <span>Freq: {product.billing_frequency}</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>Billing Frequency: {product.billing_frequency}</TooltipContent>
          </Tooltip>
        </div>
      </div>
    </TooltipProvider>
  </div>
)

interface DealTableRow extends Deal {
  data: {
    value: number;
    currency: string;
    status: string;
  }
}

const Deals = () => {
  const [deals, setDeals] = useState<Deal[]>([])
  const [pendingDeals, setPendingDeals] = useState<ImportDeal[]>([])
  const [loading, setLoading] = useState(true)
  const [importModalVisible, setImportModalVisible] = useState(false)
  const [detailModalVisible, setDetailModalVisible] = useState(false)
  const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null)
  const [totalDeals, setTotalDeals] = useState(0)
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null)
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([])
  const [accountModalVisible, setAccountModalVisible] = useState(false)
  const [contactModalVisible, setContactModalVisible] = useState(false)
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false)
  const [selectedDealId, setSelectedDealId] = useState<number | null>(null)
  const [onboardingDealsCount, setOnboardingDealsCount] = useState(0)
  const navigate = useNavigate()
  const [pageSize, setPageSize] = useState(15)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState("")
  const [filteredData, setFilteredData] = useState<Deal[]>([])
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [selectedDealForDeletion, setSelectedDealForDeletion] = useState<Deal | null>(null)
  const [filterDialogOpen, setFilterDialogOpen] = useState(false)
  const [filters, setFilters] = useState<DealFilters>({
    status: [],
    value: {},
    createdAt: {},
    products: [],
    city: [],
    regnskabsprogram: [],
    virksomhedsform: [],
    lønprogram: [],
    lagerstyring: [],
    kreditorstyring: [],
    betalingsgateway: [],
    is_holding: [],
    hvem_er_ansvarlig_for_årsregnskab: [],
    bogføringsfrekvens: [],
    ansvarlig_bogholder: [],
    description: [],
  })
  const [filterLogic, setFilterLogic] = useState<'OR' | 'AND'>('OR')
  const [productsDrawerVisible, setProductsDrawerVisible] = useState(false)

  useEffect(() => {
    fetchDeals()
    fetchPendingDeals()
    //const socket = io(window._env_?.REACT_APP_API_URL || 'http://localhost:5000')
    //socket.on('pending_deal_added', fetchPendingDeals)
    //socket.on('pending_deal_imported', () => {
      //fetchDeals()
      //fetchPendingDeals()
    //})
    //return () => {
      //socket.disconnect()
    //}
  }, [])

  const applyFilters = (dealsToFilter: Deal[]) => {
    // If no filters are active and no search query, return all deals
    const hasActiveFilters = Object.values(filters).some(filter => 
      Array.isArray(filter) ? filter.length > 0 : Object.keys(filter).length > 0
    );
    
    if (!hasActiveFilters && !searchQuery) {
      return dealsToFilter;
    }

    // Apply search first
    let results = searchQuery ? dealsToFilter.filter(deal => {
      const searchTerm = searchQuery.toLowerCase();
      return (
        deal.name.toLowerCase().includes(searchTerm) ||
        (deal.cvr?.toLowerCase() || '').includes(searchTerm) ||
        (deal.email?.toLowerCase() || '').includes(searchTerm) ||
        (deal.phone?.toLowerCase() || '').includes(searchTerm) ||
        (deal.description?.toLowerCase() || '').includes(searchTerm)
      );
    }) : dealsToFilter;

    // If no search results, return empty array
    if (searchQuery && results.length === 0) {
      return [];
    }

    // Then apply other filters if they exist
    if (hasActiveFilters) {
      if (filterLogic === 'OR') {
        // Status filter
        if (filters.status.length > 0) {
          results = results.filter(deal => filters.status.includes(deal.data.status))
        }

        // Value filter
        if (filters.value.min !== undefined) {
          results = results.filter(deal => deal.data.value >= filters.value.min!)
        }
        if (filters.value.max !== undefined) {
          results = results.filter(deal => deal.data.value <= filters.value.max!)
        }

        // Date filter
        if (filters.createdAt.from) {
          results = results.filter(deal => new Date(deal.created_at) >= filters.createdAt.from!)
        }
        if (filters.createdAt.to) {
          results = results.filter(deal => new Date(deal.created_at) <= filters.createdAt.to!)
        }

        // Products filter
        if (filters.products.length > 0) {
          results = results.filter(deal => 
            deal.products.some(product => filters.products.includes(product.name))
          )
        }

        // City filter
        if (filters.city.length > 0) {
          results = results.filter(deal => filters.city.includes(deal.city))
        }

        // Description filter
        if (filters.description.length > 0) {
          results = results.filter(deal => filters.description.includes(deal.description))
        }

        // Regnskabsprogram filter
        if (filters.regnskabsprogram.length > 0) {
          results = results.filter(deal => 
            filters.regnskabsprogram.includes(deal.data.regnskabsprogram)
          )
        }

        // Virksomhedsform filter
        if (filters.virksomhedsform.length > 0) {
          results = results.filter(deal => 
            filters.virksomhedsform.includes(deal.data.virksomhedsform)
          )
        }

        // Software filters
        if (filters.lønprogram.length > 0) {
          results = results.filter(deal => 
            deal.software?.lønprogram && filters.lønprogram.includes(deal.software.lønprogram)
          )
        }

        if (filters.betalingsgateway.length > 0) {
          results = results.filter(deal => 
            deal.software?.betalingsgateway && filters.betalingsgateway.includes(deal.software.betalingsgateway)
          )
        }

        if (filters.lagerstyring.length > 0) {
          results = results.filter(deal => 
            deal.software?.lagerstyring?.some(l => filters.lagerstyring.includes(l))
          )
        }

        if (filters.kreditorstyring.length > 0) {
          results = results.filter(deal => 
            deal.software?.kreditorstyring?.some(k => filters.kreditorstyring.includes(k))
          )
        }

        if (filters.is_holding.length > 0) {
          results = results.filter(deal => 
            filters.is_holding.includes(String(deal.is_holding) as 'true' | 'false' | 'null')
          )
        }

        if (filters.hvem_er_ansvarlig_for_årsregnskab.length > 0) {
          results = results.filter(deal => 
            filters.hvem_er_ansvarlig_for_årsregnskab.includes(deal.data.hvem_er_ansvarlig_for_årsregnskab)
          )
        }

        if (filters.bogføringsfrekvens.length > 0) {
          results = results.filter(deal => 
            filters.bogføringsfrekvens.includes(deal.data.bogføringsfrekvens)
          )
        }

        if (filters.ansvarlig_bogholder.length > 0) {
          results = results.filter(deal => 
            filters.ansvarlig_bogholder.includes(deal.data.ansvarlig_bogholder)
          )
        }
      } else {
        // AND logic
        results = results.filter(deal => {
          const conditions = []

          if (filters.status.length > 0) {
            conditions.push(filters.status.includes(deal.data.status))
          }
          if (filters.value.min !== undefined) {
            conditions.push(deal.data.value >= filters.value.min!)
          }
          if (filters.value.max !== undefined) {
            conditions.push(deal.data.value <= filters.value.max!)
          }
          if (filters.createdAt.from) {
            conditions.push(new Date(deal.created_at) >= filters.createdAt.from!)
          }
          if (filters.createdAt.to) {
            conditions.push(new Date(deal.created_at) <= filters.createdAt.to!)
          }
          if (filters.products.length > 0) {
            conditions.push(deal.products.some(product => 
              filters.products.includes(product.name)
            ))
          }
          if (filters.city.length > 0) {
            conditions.push(filters.city.includes(deal.city))
          }
          if (filters.description.length > 0) {
            conditions.push(filters.description.includes(deal.description))
          }
          if (filters.regnskabsprogram.length > 0) {
            conditions.push(filters.regnskabsprogram.includes(deal.data.regnskabsprogram))
          }
          if (filters.virksomhedsform.length > 0) {
            conditions.push(filters.virksomhedsform.includes(deal.data.virksomhedsform))
          }
          if (filters.lønprogram.length > 0) {
            conditions.push(deal.software?.lønprogram ? filters.lønprogram.includes(deal.software.lønprogram) : false)
          }
          if (filters.betalingsgateway.length > 0) {
            conditions.push(deal.software?.betalingsgateway ? filters.betalingsgateway.includes(deal.software.betalingsgateway) : false)
          }
          if (filters.lagerstyring.length > 0) {
            conditions.push(deal.software?.lagerstyring?.some(l => 
              filters.lagerstyring.includes(l)
            ))
          }
          if (filters.kreditorstyring.length > 0) {
            conditions.push(deal.software?.kreditorstyring?.some(k => 
              filters.kreditorstyring.includes(k)
            ))
          }
          if (filters.is_holding.length > 0) {
            conditions.push(filters.is_holding.includes(String(deal.is_holding) as 'true' | 'false' | 'null'))
          }
          if (filters.hvem_er_ansvarlig_for_årsregnskab.length > 0) {
            conditions.push(filters.hvem_er_ansvarlig_for_årsregnskab.includes(deal.data.hvem_er_ansvarlig_for_årsregnskab))
          }
          if (filters.bogføringsfrekvens.length > 0) {
            conditions.push(filters.bogføringsfrekvens.includes(deal.data.bogføringsfrekvens))
          }
          if (filters.ansvarlig_bogholder.length > 0) {
            conditions.push(filters.ansvarlig_bogholder.includes(deal.data.ansvarlig_bogholder))
          }

          return conditions.length > 0 ? conditions.every(c => c) : true
        })
      }
    }

    return results;
  }

  useEffect(() => {
    const filteredResults = applyFilters(deals);
    setFilteredData(filteredResults);
  }, [deals, filters, filterLogic, searchQuery]);

  const fetchDeals = async () => {
    try {
      const response = await axios.get('/account/deals')
      const allDeals = response.data || []
      const completedDeals = allDeals.filter((deal: Deal) => 
        deal.onboard_status === 'onboarded' || deal.onboard_status === 'migrated'
      )
      const onboardingDeals = allDeals.filter((deal: Deal) => 
        deal.onboard_status === 'onboarding' || deal.onboard_status === 'not_onboarded'
      )
      
      setDeals(completedDeals)
      setTotalDeals(completedDeals.length)
      setOnboardingDealsCount(onboardingDeals.length)
    } catch (error) {
      console.error('Error fetching deals:', error)
      setDeals([])
      setTotalDeals(0)
      setOnboardingDealsCount(0)
    } finally {
      setLoading(false)
    }
  }

  const fetchPendingDeals = async () => {
    try {
      const response = await axios.get('/account/pending-deals')
      setPendingDeals(response.data || [])
    } catch (error) {
      console.error('Error fetching pending deals:', error)
      setPendingDeals([])
    }
  }

  const showDealDetails = (deal: ImportDeal) => {
    setSelectedDeal(deal as any)
    setDetailModalVisible(true)
  }

  const showAccountDetails = async (account: Account) => {
    try {
      const response = await axios.get(`/account/accounts/${account.id}`)
      setSelectedAccount(response.data)
      setAccountModalVisible(true)
    } catch (error) {
      console.error('Error fetching account details:', error)
      // Using Sonner toast instead of antd message
      toast.error('Failed to fetch account details')
    }
  }

  const showContactDetails = (contact: Contact) => {
    setSelectedContact(contact)
    setContactModalVisible(true)
  }

  const showProductsDetails = (products: Product[]) => {
    setSelectedProducts(products)
    setProductsDrawerVisible(true)
  }

  const handleImportSuccess = (importedDealIds: string | string[]) => {
    // Add console.log to debug
    console.log('handleImportSuccess called with:', importedDealIds);
    console.log('Before update - pendingDeals:', pendingDeals);
    
    if (typeof importedDealIds === 'string') {
      console.log('Removing single deal with ID:', importedDealIds);
      setPendingDeals(prevDeals => {
        // Convert IDs to strings for comparison
        const newDeals = prevDeals.filter(deal => deal.id.toString() !== importedDealIds.toString());
        console.log('After update - pendingDeals:', newDeals);
        return newDeals;
      });
    } else if (Array.isArray(importedDealIds)) {
      console.log('Removing multiple deals with IDs:', importedDealIds);
      setPendingDeals(prevDeals => {
        // Convert IDs to strings for comparison
        const newDeals = prevDeals.filter(deal => 
          !importedDealIds.map(id => id.toString()).includes(deal.id.toString())
        );
        console.log('After update - pendingDeals:', newDeals);
        return newDeals;
      });
    }
    fetchDeals();
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  }

  const handleDealUpdate = async (dealId: string) => {
    try {
      const response = await axios.get(`/account/pending-deals/${dealId}`);
      const updatedDeal = response.data;
      
      setPendingDeals(prevDeals => 
        prevDeals.map(deal => 
          deal.id === dealId ? updatedDeal : deal
        )
      );

      fetchDeals();
    } catch (error) {
      console.error('Error fetching updated deal:', error);
      toast.error('Failed to refresh deal data');
    }
  };

  const columns: ColumnDef<DealTableRow>[] = [
    {
      key: 'name',
      title: 'Deal',
      dataIndex: 'name',
      cell: ({ row }: { row: { original: DealTableRow } }) => (
        <Badge 
          variant="secondary"
          className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-deal))] text-white hover:bg-[hsl(var(--badge-deal)/.8)]"
          onClick={() => {
            setSelectedDealId(row.original.id)
            setDealDrawerVisible(true)
          }}
        >
          <Briefcase className="w-3 h-3 flex-shrink-0" />
          <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
            {row.original.name}
          </span>
        </Badge>
      )
    },
    {
      key: 'cvr',
      title: 'CVR',
      dataIndex: 'cvr'
    },
    {
      key: 'phone',
      title: 'Phone',
      dataIndex: 'phone',
      cell: ({ row }: { row: { original: DealTableRow } }) => (
        <div className="flex items-center gap-2">
          <Phone className="w-4 h-4" />
          {row.original.phone ? (
            <a 
              href={`tel:${row.original.phone}`} 
              className="hover:underline"
              onClick={(e) => e.stopPropagation()}
            >
              {row.original.phone}
            </a>
          ) : (
            <span>N/A</span>
          )}
          {row.original.phone && (
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6 opacity-50 hover:opacity-100"
              onClick={(e) => {
                e.stopPropagation()
                navigator.clipboard.writeText(row.original.phone)
                toast.success('Phone number copied to clipboard')
              }}
            >
              <Copy className="h-3 w-3" />
            </Button>
          )}
        </div>
      )
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      cell: ({ row }: { row: { original: DealTableRow } }) => (
        <div className="flex items-center gap-2">
          <Mail className="w-4 h-4" />
          {row.original.email ? (
            <a 
              href={`mailto:${row.original.email}`} 
              className="hover:underline"
              onClick={(e) => e.stopPropagation()}
            >
              {row.original.email}
            </a>
          ) : (
            <span>N/A</span>
          )}
          {row.original.email && (
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6 opacity-50 hover:opacity-100"
              onClick={(e) => {
                e.stopPropagation()
                navigator.clipboard.writeText(row.original.email)
                toast.success('Email copied to clipboard')
              }}
            >
              <Copy className="h-3 w-3" />
            </Button>
          )}
        </div>
      )
    },
    {
      key: 'value',
      title: 'Value',
      dataIndex: 'data',
      render: (data: DealTableRow['data']) => `${data.value} ${data.currency}`
    },
    {
      key: 'created_at',
      title: 'Created\u00A0At',
      dataIndex: 'created_at',
      render: (date: string) => {
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }).format(new Date(date));
        return formattedDate;
      },
      sortable: {
        enabled: true,
        type: 'date'
      }
    },
    {
      key: 'account',
      title: 'Account',
      dataIndex: 'account',
      cell: ({ row }: { row: { original: DealTableRow } }) => (
        <Badge 
          variant="secondary"
          className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-account))] text-white hover:bg-[hsl(var(--badge-account)/.8)]"
          onClick={() => showAccountDetails(row.original.account)}
        >
          <Building2 className="w-3 h-3 flex-shrink-0" />
          <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
            {row.original.account.company_name}
          </span>
        </Badge>
      )
    },
    {
      key: 'contact',
      title: 'Contact',
      dataIndex: 'contact',
      cell: ({ row }: { row: { original: Deal } }) => {
        // Get the primary contact from the deal
        const contact = row.original.contact

        if (!contact) {
          return <span className="text-muted-foreground">No contact</span>
        }

        return (
          <Badge 
            variant="secondary"
            className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-contact))] text-white hover:bg-[hsl(var(--badge-contact)/.8)]"
            onClick={() => showContactDetails(contact)}
          >
            <UserCircle2 className="w-3 h-3 flex-shrink-0" />
            <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
              {contact.name}
            </span>
          </Badge>
        )
      }
    },
    {
      key: 'products',
      title: 'Products',
      dataIndex: 'products',
      cell: ({ row }: { row: { original: DealTableRow } }) => (
        <Badge 
          variant="secondary"
          className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-product))] text-white hover:bg-[hsl(var(--badge-product)/.8)]"
          onClick={() => showProductsDetails(row.original.products)}
        >
          <Package className="w-3 h-3 flex-shrink-0" />
          <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
            {row.original.products.length} product(s)
          </span>
        </Badge>
      )
    },
    {
      key: 'address',
      title: 'Address',
      dataIndex: 'address'
    },
    {
      key: 'zipcode',
      title: 'Zipcode',
      dataIndex: 'zipcode'
    },
    {
      key: 'city',
      title: 'City',
      dataIndex: 'city'
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
      cell: ({ row }: { row: { original: DealTableRow } }) => (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <span className="block max-w-[250px] truncate">
                {row.original.description}
              </span>
            </TooltipTrigger>
            <TooltipContent>
              <p>{row.original.description}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    },
    {
      key: 'actions',
      title: '',
      dataIndex: 'id',
      cell: ({ row }: { row: { original: DealTableRow } }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon">
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => {
                setSelectedDealForDeletion(row.original)
                setDeleteModalVisible(true)
              }}
              className="text-destructive"
            >
              <Trash2 className="h-4 w-4 mr-2" />
              Delete Deal
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    }
  ]

  const handleFiltersChange = (newFilters: DealFilters, logic: 'OR' | 'AND') => {
    setFilters(newFilters)
    setFilterLogic(logic)
  }

  if (loading) {
    return <Loading message="Loading deals..." />
  }

  return (
    <div className="space-y-4 p-8">
      <div className="flex items-center justify-between">
        <h2 className="text-3xl font-bold text-foreground">Deals</h2>
        <div className="flex items-center gap-2">
          <Button 
            variant="outline"
            className="flex items-center gap-2"
            onClick={() => setImportModalVisible(true)}
          >
            <FilePlus className="w-4 h-4" />
            Import Deals
          </Button>
          <Button 
            variant="outline"
            className="flex items-center gap-2"
            onClick={() => navigate('/customers/onboarding')}
          >
            <ClockIcon className="w-4 h-4" />
            Onboarding
          </Button>
        </div>
      </div>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardContent className="flex items-center gap-4 pt-6">
            <Users className="h-8 w-8 text-primary" />
            <div>
              <p className="text-sm font-medium">Total Deals</p>
              <p className="text-2xl font-bold">{totalDeals}</p>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="flex items-center gap-4 pt-6">
            <ShoppingBag className="h-8 w-8 text-primary" />
            <div>
              <p className="text-sm font-medium">Onboarding</p>
              <p className="text-2xl font-bold">{onboardingDealsCount}</p>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="flex items-center gap-4 pt-6">
            <ClockIcon className="h-8 w-8 text-primary" />
            <div>
              <p className="text-sm font-medium">Pending</p>
              <p className="text-2xl font-bold">{pendingDeals.length}</p>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="flex items-center gap-4 pt-6">
            <LayoutGrid className="h-8 w-8 text-primary" />
            <div>
              <p className="text-sm font-medium">Setup</p>
              <Button 
                variant="link" 
                className="p-0 h-auto font-bold text-2xl"
                onClick={() => navigate('/customers/deals/setup')}
              >
                View Setup
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="w-full flex justify-between items-center mb-4">
        <div className="flex gap-2">
          <Input 
            placeholder="Search deals..."
            value={searchQuery}
            onChange={handleSearch}
            className="max-w-sm"
          />
          <Button
            variant="outline"
            onClick={() => setFilterDialogOpen(true)}
          >
            Filter
          </Button>
        </div>
      </div>

      {/* Active Filters */}
      {(filters.status.length > 0 || 
        filters.products.length > 0 || 
        filters.city.length > 0 ||
        filters.value.min !== undefined ||
        filters.value.max !== undefined ||
        filters.createdAt.from !== undefined ||
        filters.createdAt.to !== undefined ||
        filters.regnskabsprogram.length > 0 ||
        filters.virksomhedsform.length > 0 ||
        filters.lønprogram.length > 0 ||
        filters.lagerstyring.length > 0 ||
        filters.kreditorstyring.length > 0 ||
        filters.betalingsgateway.length > 0 ||
        filters.is_holding.length > 0 ||
        filters.hvem_er_ansvarlig_for_årsregnskab.length > 0 ||
        filters.bogføringsfrekvens.length > 0 ||
        filters.ansvarlig_bogholder.length > 0
      ) && (
        <div className="mb-4 flex flex-wrap items-center gap-2">
          <span className="text-xs font-medium text-muted-foreground">Filters:</span>
          
          {filters.status.map(status => (
            <Badge key={status} variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">Status:</span>
              <span className="ml-1">{status}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  status: prev.status.filter(s => s !== status)
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          ))}

          {filters.products.map(product => (
            <Badge key={product} variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">Product:</span>
              <span className="ml-1">{product}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  products: prev.products.filter(p => p !== product)
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          ))}

          {filters.city.map(city => (
            <Badge key={city} variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">City:</span>
              <span className="ml-1">{city}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  city: prev.city.filter(c => c !== city)
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          ))}

          {filters.value.min !== undefined && (
            <Badge key="min-value" variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">Min:</span>
              <span className="ml-1">${filters.value.min}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  value: { ...prev.value, min: undefined }
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          )}

          {filters.value.max !== undefined && (
            <Badge key="max-value" variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">Max:</span>
              <span className="ml-1">${filters.value.max}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  value: { ...prev.value, max: undefined }
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          )}

          {filters.createdAt.from && (
            <Badge key="date-from" variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">From:</span>
              <span className="ml-1">{format(filters.createdAt.from, 'MMM d, yyyy')}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  createdAt: { ...prev.createdAt, from: undefined }
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          )}

          {filters.createdAt.to && (
            <Badge key="date-to" variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">To:</span>
              <span className="ml-1">{format(filters.createdAt.to, 'MMM d, yyyy')}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  createdAt: { ...prev.createdAt, to: undefined }
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          )}

          {filters.regnskabsprogram.map(prog => (
            <Badge key={prog} variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">Regnskabsprogram:</span>
              <span className="ml-1">{prog}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  regnskabsprogram: prev.regnskabsprogram.filter(p => p !== prog)
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          ))}

          {filters.virksomhedsform.map(form => (
            <Badge key={form} variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">Virksomhedsform:</span>
              <span className="ml-1">{form}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  virksomhedsform: prev.virksomhedsform.filter(f => f !== form)
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          ))}

          {filters.lønprogram.map(prog => (
            <Badge key={prog} variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">Lønprogram:</span>
              <span className="ml-1">{prog}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  lønprogram: prev.lønprogram.filter(p => p !== prog)
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          ))}

          {filters.lagerstyring.map(prog => (
            <Badge key={prog} variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">Lagerstyring:</span>
              <span className="ml-1">{prog}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  lagerstyring: prev.lagerstyring.filter(p => p !== prog)
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          ))}

          {filters.kreditorstyring.map(prog => (
            <Badge key={prog} variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">Kreditorstyring:</span>
              <span className="ml-1">{prog}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  kreditorstyring: prev.kreditorstyring.filter(p => p !== prog)
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          ))}

          {filters.betalingsgateway.map(gateway => (
            <Badge key={gateway} variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">Betalingsgateway:</span>
              <span className="ml-1">{gateway}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  betalingsgateway: prev.betalingsgateway.filter(g => g !== gateway)
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          ))}

          {filters.is_holding.map(value => (
            <Badge key={value} variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">Is Holding:</span>
              <span className="ml-1">{value === 'true' ? 'Yes' : value === 'false' ? 'No' : 'Not Set'}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  is_holding: prev.is_holding.filter(v => v !== value)
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          ))}

          {filters.hvem_er_ansvarlig_for_årsregnskab.map(value => (
            <Badge key={value} variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">Ansvarlig for årsregnskab:</span>
              <span className="ml-1">{value}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  hvem_er_ansvarlig_for_årsregnskab: prev.hvem_er_ansvarlig_for_årsregnskab.filter(v => v !== value)
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          ))}

          {filters.bogføringsfrekvens.map(value => (
            <Badge key={value} variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">Bogføringsfrekvens:</span>
              <span className="ml-1">{value}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  bogføringsfrekvens: prev.bogføringsfrekvens.filter(v => v !== value)
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          ))}

          {filters.ansvarlig_bogholder.map(value => (
            <Badge key={value} variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">Ansvarlig bogholder:</span>
              <span className="ml-1">{value}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  ansvarlig_bogholder: prev.ansvarlig_bogholder.filter(v => v !== value)
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          ))}

          {filters.description.map(value => (
            <Badge key={value} variant="secondary" className="h-6 px-2 text-xs">
              <span className="text-muted-foreground">Description:</span>
              <span className="ml-1">{value}</span>
              <Button
                variant="ghost"
                size="icon"
                className="ml-1 h-3 w-3 p-0 hover:bg-destructive/10 hover:text-destructive"
                onClick={() => setFilters(prev => ({
                  ...prev,
                  description: prev.description.filter(v => v !== value)
                }))}
              >
                <X className="h-2 w-2" />
              </Button>
            </Badge>
          ))}

          <Button
            variant="ghost"
            size="sm"
            className="h-6 px-2 text-xs text-muted-foreground hover:text-destructive"
            onClick={() => setFilters({
              status: [],
              value: {},
              createdAt: {},
              products: [],
              city: [],
              regnskabsprogram: [],
              virksomhedsform: [],
              lønprogram: [],
              lagerstyring: [],
              kreditorstyring: [],
              betalingsgateway: [],
              is_holding: [],
              hvem_er_ansvarlig_for_årsregnskab: [],
              bogføringsfrekvens: [],
              ansvarlig_bogholder: [],
              description: [],
            })}
          >
            Clear all
          </Button>
        </div>
      )}

      <div>
        {filteredData.length === 0 && searchQuery ? (
          <div className="text-center py-8 text-muted-foreground">
            No results found for "{searchQuery}"
          </div>
        ) : (
          <Table 
            data={filteredData.length > 0 || searchQuery ? filteredData : deals}
            columns={columns}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            onPageSizeChange={setPageSize}
          />
        )}
      </div>

      <ImportDeals
        visible={importModalVisible}
        onClose={() => setImportModalVisible(false)}
        pendingDeals={pendingDeals}
        onImportSuccess={handleImportSuccess}
        onShowDealDetails={showDealDetails}
      />

      <DealDetails
        visible={detailModalVisible}
        onClose={() => setDetailModalVisible(false)}
        deal={selectedDeal}
        onUpdateSuccess={handleDealUpdate}
      />

      <Dialog open={accountModalVisible} onOpenChange={setAccountModalVisible}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Account Details</DialogTitle>
          </DialogHeader>
          {selectedAccount && (
            <div className="space-y-4">
              <p><span className="font-semibold">Company Name:</span> {selectedAccount.company_name}</p>
              <p><span className="font-semibold">CVR:</span> {selectedAccount.cvr || 'N/A'}</p>
              <p><span className="font-semibold">Address:</span> {selectedAccount.address || 'N/A'}</p>
              <p><span className="font-semibold">Zipcode:</span> {selectedAccount.zipcode || 'N/A'}</p>
              <p><span className="font-semibold">City:</span> {selectedAccount.city || 'N/A'}</p>
            </div>
          )}
          <DialogFooter>
            <Button variant="outline" onClick={() => setAccountModalVisible(false)}>
              Close
            </Button>
            <Button onClick={() => navigate(`/accounts/${selectedAccount?.id}`)}>
              View Full Details
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={contactModalVisible} onOpenChange={setContactModalVisible}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Contact Details</DialogTitle>
          </DialogHeader>
          {selectedContact && (
            <div className="space-y-4">
              <p><span className="font-semibold">Name:</span> {selectedContact.name}</p>
              <p><span className="font-semibold">Email:</span> {selectedContact.email}</p>
              <p><span className="font-semibold">Phone:</span> {selectedContact.phone}</p>
            </div>
          )}
          <DialogFooter>
            <Button variant="outline" onClick={() => setContactModalVisible(false)}>
              Close
            </Button>
            <Button onClick={() => navigate(`/contacts/${selectedContact?.id}`)}>
              View Full Details
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <DealDrawer
        visible={dealDrawerVisible}
        onClose={() => {
          setDealDrawerVisible(false)
          setSelectedDealId(null)
        }}
        dealId={selectedDealId}
      />

      <ProductsDrawer
        visible={productsDrawerVisible}
        onClose={() => {
          setProductsDrawerVisible(false)
          setSelectedProducts([])
        }}
        products={selectedProducts}
      />

      <DeleteDeal
        visible={deleteModalVisible}
        onClose={() => {
          setDeleteModalVisible(false)
          setSelectedDealForDeletion(null)
        }}
        deal={selectedDealForDeletion}
        onDeleteSuccess={fetchDeals}
      />

      <DealFilter
        open={filterDialogOpen}
        onOpenChange={setFilterDialogOpen}
        onFiltersChange={handleFiltersChange}
        deals={deals}
        currentFilters={filters}
      />
    </div>
  )
}

export default Deals