import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { NodeConfig, ButtonConfig, SelectConfig, NodeElement, RowSection } from "@/onboardingflow/types/node_types";
import { FlowNode } from "@/onboardingflow/types/flows";
import { X, PlusCircle } from "lucide-react";
import { Checkbox } from "@/components/ui/checkbox";

interface ConditionalTriggersProps {
  localConfig: NodeConfig;
  setLocalConfig: (config: NodeConfig) => void;
  nodes: FlowNode[];
  connectedNodes: FlowNode[];
}

export const ConditionalTriggers = ({
  localConfig,
  setLocalConfig,
  nodes,
  connectedNodes
}: ConditionalTriggersProps) => {
  const getButtonElements = () => {
    return localConfig.rows.flatMap((row: RowSection) => 
      row.elements.filter((el: NodeElement) => el.type === 'button')
    );
  };

  const getSelectElements = () => {
    return localConfig.rows.flatMap((row: RowSection) => 
      row.elements.filter((el: NodeElement) => el.type === 'select')
    );
  };

  const handleAddRule = () => {
    setLocalConfig({
      ...localConfig,
      rules: [
        ...(localConfig.rules || []),
        {
          id: crypto.randomUUID(),
          trigger: {
            elementId: ''
          },
          targetNodeId: '',
          requirements: []
        }
      ]
    });
  };

  const handleRemoveRule = (ruleId: string) => {
    setLocalConfig({
      ...localConfig,
      rules: localConfig.rules?.filter(rule => rule.id !== ruleId)
    });
  };

  const handleUpdateRule = (ruleId: string, updates: Partial<any>) => {
    setLocalConfig({
      ...localConfig,
      rules: localConfig.rules?.map(rule => 
        rule.id === ruleId ? { ...rule, ...updates } : rule
      )
    });
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <Label>Conditional Rules</Label>
        <Button
          variant="outline"
          size="sm"
          onClick={handleAddRule}
          className="flex items-center gap-2"
        >
          <PlusCircle className="h-4 w-4" />
          Add Rule
        </Button>
      </div>

      {localConfig.rules?.map((rule) => (
        <div key={rule.id} className="border rounded-lg p-4 space-y-4">
          <div className="flex justify-between items-center">
            <Label>Trigger Rule</Label>
            <Button variant="ghost" size="sm" onClick={() => handleRemoveRule(rule.id)}>
              <X className="h-4 w-4" />
            </Button>
          </div>

          <div className="space-y-4">
            <div>
              <Label>When this element is triggered</Label>
              <Select
                value={rule.trigger.elementId || '_none'}
                onValueChange={(value) => handleUpdateRule(rule.id, {
                  trigger: { ...rule.trigger, elementId: value === '_none' ? '' : value }
                })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select trigger element" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="_none">None</SelectItem>
                  {getButtonElements().map((el: NodeElement) => (
                    <SelectItem key={el.id} value={el.id}>
                      {(el.config as ButtonConfig).label || 'Unnamed Button'}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div>
              <Label>Go to this node</Label>
              <Select
                value={rule.targetNodeId || '_none'}
                onValueChange={(value) => handleUpdateRule(rule.id, {
                  targetNodeId: value === '_none' ? '' : value
                })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select target node" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="_none">None</SelectItem>
                  {connectedNodes.map((node) => (
                    <SelectItem key={node.id} value={node.id}>
                      {node.data.config.title || 'Untitled Node'}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div>
              <Label>Requirements</Label>
              <div className="space-y-2">
                {getSelectElements().map((el: NodeElement) => (
                  <div key={el.id} className="flex items-center space-x-2">
                    <Checkbox
                      id={el.id}
                      checked={rule.requirements?.includes(el.id)}
                      onCheckedChange={(checked) => {
                        handleUpdateRule(rule.id, {
                          requirements: checked
                            ? [...(rule.requirements || []), el.id]
                            : (rule.requirements || []).filter((id: string) => id !== el.id)
                        });
                      }}
                    />
                    <label
                      htmlFor={el.id}
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {(el.config as SelectConfig).label || 'Unnamed Select'}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}; 