import { useState, useEffect } from 'react'
import { toast } from 'sonner'
import axios from '@/api/axiosConfig'
import { Edit, Save, X } from 'lucide-react'

import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerFooter,
} from "@/components/ui/drawer"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Badge } from "@/components/ui/badge"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

import { dealSoftwareConfig } from '@/configs/DealSoftwareConfig'

interface PayrollDrawerProps {
  visible: boolean
  onClose: () => void
  dealId: number | null
  onSave?: () => void
}

interface PayrollConfig {
  salary_type?: string
  deal_software?: {
    lønprogram?: string
  }
  salary_period_hourly?: {
    start?: number
    end?: number
  }
  salary_period_monthly?: {
    start?: number
    end?: number
  }
  mail_reminder_date?: number
}

const formSchema = z.object({
  lønprogram: z.string(),
  salary_type: z.enum(["Monthly", "Hourly", "Mix"]),
  salary_period_hourly_start: z.number().min(-1).max(31).nullable(),
  salary_period_hourly_end: z.number().min(-1).max(31).nullable(),
  salary_period_monthly_start: z.number().min(-1).max(31).nullable(),
  salary_period_monthly_end: z.number().min(-1).max(31).nullable(),
  mail_reminder_date: z.number().min(1).max(31)
})

type FormValues = z.infer<typeof formSchema>

export function PayrollDrawer({ visible, onClose, dealId, onSave }: PayrollDrawerProps) {
  const [loading, setLoading] = useState(false)
  const [salaryType, setSalaryType] = useState<string | null>(null)

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      salary_period_hourly_start: null,
      salary_period_hourly_end: null,
      salary_period_monthly_start: null,
      salary_period_monthly_end: null,
      mail_reminder_date: 1
    }
  })

  useEffect(() => {
    if (dealId && visible) {
      fetchConfig()
    }
  }, [dealId, visible])

  const fetchConfig = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`/lon/lon-config/${dealId}`)
      const config: PayrollConfig = response.data
      setSalaryType(config.salary_type || null)
      
      form.reset({
        lønprogram: config.deal_software?.lønprogram,
        salary_type: config.salary_type as "Monthly" | "Hourly" | "Mix",
        salary_period_hourly_start: config.salary_period_hourly?.start ?? null,
        salary_period_hourly_end: config.salary_period_hourly?.end ?? null,
        salary_period_monthly_start: config.salary_period_monthly?.start ?? null,
        salary_period_monthly_end: config.salary_period_monthly?.end ?? null,
        mail_reminder_date: config.mail_reminder_date ?? 1
      })
    } catch (error) {
      console.error('Error fetching payroll config:', error)
      toast.error('Failed to fetch payroll configuration')
    } finally {
      setLoading(false)
    }
  }

  const handleSalaryTypeChange = (value: string) => {
    setSalaryType(value)
    // Clear irrelevant period values when switching types
    if (value === 'Monthly') {
      form.setValue('salary_period_hourly_start', null)
      form.setValue('salary_period_hourly_end', null)
    } else if (value === 'Hourly') {
      form.setValue('salary_period_monthly_start', null)
      form.setValue('salary_period_monthly_end', null)
    }
  }

  const onSubmit = async (values: FormValues) => {
    try {
      const payload = {
        lønprogram: values.lønprogram,
        salary_type: values.salary_type,
        salary_period_hourly: {
          start: ['Mix', 'Hourly'].includes(values.salary_type) ? values.salary_period_hourly_start : null,
          end: ['Mix', 'Hourly'].includes(values.salary_type) ? values.salary_period_hourly_end : null
        },
        salary_period_monthly: {
          start: ['Mix', 'Monthly'].includes(values.salary_type) ? values.salary_period_monthly_start : null,
          end: ['Mix', 'Monthly'].includes(values.salary_type) ? values.salary_period_monthly_end : null
        },
        mail_reminder_date: values.mail_reminder_date
      }

      await axios.put(`/lon/lon-config/${dealId}`, payload)
      toast.success('Payroll configuration saved successfully')
      if (onSave) onSave()
      onClose()
    } catch (error) {
      console.error('Error saving payroll config:', error)
      toast.error('Failed to save payroll configuration')
    }
  }

  return (
    <Drawer open={visible} onOpenChange={onClose}>
      <DrawerContent className="h-[90vh]">
        <DrawerHeader>
          <DrawerTitle>Edit Payroll Configuration</DrawerTitle>
        </DrawerHeader>
        
        <ScrollArea className="flex-1 px-6">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="lønprogram"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Lønprogram</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a program" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {dealSoftwareConfig
                          .find(config => config.key === 'lønprogram')
                          ?.options?.map(option => (
                            <SelectItem key={option.value} value={option.value}>
                              <Badge variant="outline" className={option.color}>
                                {option.label}
                              </Badge>
                            </SelectItem>
                          )) || null
                        }
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="salary_type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Type</FormLabel>
                    <Select onValueChange={(value) => {
                      field.onChange(value)
                      handleSalaryTypeChange(value)
                    }} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="Monthly">Monthly</SelectItem>
                        <SelectItem value="Hourly">Hourly</SelectItem>
                        <SelectItem value="Mix">Mix</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {(salaryType === 'Mix' || salaryType === 'Hourly') && (
                <div className="space-y-4 rounded-lg bg-muted p-4">
                  <h3 className="font-medium">Period (hourly)</h3>
                  <FormField
                    control={form.control}
                    name="salary_period_hourly_start"
                    render={({ field: { onChange, value, ...field } }) => (
                      <FormItem>
                        <FormLabel>Start Day</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            min={-1}
                            max={31}
                            {...field}
                            value={value?.toString() ?? ''}
                            onChange={e => onChange(e.target.value ? Number(e.target.value) : null)}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="salary_period_hourly_end"
                    render={({ field: { onChange, value, ...field } }) => (
                      <FormItem>
                        <FormLabel>End Day</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            min={-1}
                            max={31}
                            {...field}
                            value={value?.toString() ?? ''}
                            onChange={e => onChange(e.target.value ? Number(e.target.value) : null)}
                          />
                        </FormControl>
                        <p className="text-sm text-muted-foreground italic">-1 = last day of month</p>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}

              {(salaryType === 'Mix' || salaryType === 'Monthly') && (
                <div className="space-y-4 rounded-lg bg-muted p-4">
                  <h3 className="font-medium">Period (monthly)</h3>
                  <FormField
                    control={form.control}
                    name="salary_period_monthly_start"
                    render={({ field: { onChange, value, ...field } }) => (
                      <FormItem>
                        <FormLabel>Start Day</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            min={-1}
                            max={31}
                            {...field}
                            value={value?.toString() ?? ''}
                            onChange={e => onChange(e.target.value ? Number(e.target.value) : null)}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="salary_period_monthly_end"
                    render={({ field: { onChange, value, ...field } }) => (
                      <FormItem>
                        <FormLabel>End Day</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            min={-1}
                            max={31}
                            {...field}
                            value={value?.toString() ?? ''}
                            onChange={e => onChange(e.target.value ? Number(e.target.value) : null)}
                          />
                        </FormControl>
                        <p className="text-sm text-muted-foreground italic">-1 = last day of month</p>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}

              <FormField
                control={form.control}
                name="mail_reminder_date"
                render={({ field: { onChange, value, ...field } }) => (
                  <FormItem>
                    <FormLabel>Mail Reminder Day</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        min={1}
                        max={31}
                        {...field}
                        value={value.toString()}
                        onChange={e => onChange(Number(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </ScrollArea>

        <DrawerFooter className="border-t pt-4">
          <Button type="submit" onClick={form.handleSubmit(onSubmit)} className="w-full">
            <Save className="mr-2 h-4 w-4" />
            Save Changes
          </Button>
          <Button variant="outline" onClick={onClose} className="w-full">
            <X className="mr-2 h-4 w-4" />
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default PayrollDrawer; 