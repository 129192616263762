import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { toast } from 'sonner';
import axios from '@/api/axiosConfig';
import dayjs from 'dayjs';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';
import { CapacityCalendar } from '@/components/ui/specialized/capacity-calendar';

interface UserCapacitySettingsProps {
  visible: boolean;
  onVisibilityChange?: (visible: boolean) => void;
}

interface CapacityData {
  default_daily_capacity: number;
  default_work_days: {
    [key: string]: boolean;
  };
  daily_overrides: {
    [key: string]: number;
  };
}

interface CapacityFormValues {
  default_capacity: {
    hours: number;
    minutes: number;
  };
}

interface DailyCapacityFormValues {
  hours: number;
  minutes: number;
}

export function UserCapacitySettings({ visible, onVisibilityChange }: UserCapacitySettingsProps) {
  const { theme } = useTheme();
  const form = useForm<CapacityFormValues>();
  const capacityForm = useForm<DailyCapacityFormValues>();
  const [capacityData, setCapacityData] = useState<CapacityData | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [capacityModalVisible, setCapacityModalVisible] = useState(false);
  const [workDays, setWorkDays] = useState({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
    sunday: false
  });

  useEffect(() => {
    if (visible) {
      fetchCapacityData();
    }
  }, [visible]);

  const fetchCapacityData = async () => {
    try {
      const response = await axios.get('/user/capacity');
      setCapacityData(response.data);
      setWorkDays(response.data.default_work_days);
      form.setValue('default_capacity', {
        hours: Math.floor(response.data.default_daily_capacity / 60),
        minutes: response.data.default_daily_capacity % 60
      });
    } catch (error) {
      toast.error('Failed to load capacity data');
    }
  };

  const handleDateSelect = (date: Date | undefined) => {
    if (!date) return;
    
    const dateObj = dayjs(date);
    const dayOfWeek = dateObj.format('dddd').toLowerCase();
    const dateStr = dateObj.format('YYYY-MM-DD');
    let initialCapacity = 0;
    
    if (dateStr in (capacityData?.daily_overrides || {})) {
      initialCapacity = capacityData!.daily_overrides[dateStr];
    } else if (capacityData?.default_work_days[dayOfWeek] === true) {
      initialCapacity = capacityData.default_daily_capacity;
    }
    
    capacityForm.setValue('hours', Math.floor(initialCapacity / 60));
    capacityForm.setValue('minutes', initialCapacity % 60);
    
    setSelectedDate(date);
    setCapacityModalVisible(true);
  };

  const handleDefaultCapacityUpdate = async (values: CapacityFormValues) => {
    try {
      const totalMinutes = (values.default_capacity.hours * 60) + 
        (values.default_capacity.minutes || 0);
      
      await axios.put('/user/capacity/default', {
        daily_capacity: totalMinutes,
        work_days: workDays
      });
      toast.success('Default capacity updated');
      fetchCapacityData();
    } catch (error) {
      toast.error('Failed to update default capacity');
    }
  };

  const handleCapacityUpdate = async (values: DailyCapacityFormValues) => {
    if (!selectedDate) return;
    
    try {
      const totalMinutes = (values.hours * 60) + (values.minutes || 0);
      await axios.put('/user/capacity/override', {
        date: dayjs(selectedDate).format('YYYY-MM-DD'),
        capacity: totalMinutes
      });
      toast.success('Capacity override updated');
      fetchCapacityData();
      setCapacityModalVisible(false);
    } catch (error) {
      toast.error('Failed to update capacity override');
    }
  };

  const handleSetDayOff = async () => {
    if (!selectedDate) return;
    
    try {
      await axios.put('/user/capacity/override', {
        date: dayjs(selectedDate).format('YYYY-MM-DD'),
        capacity: 0
      });
      await fetchCapacityData();
      setCapacityModalVisible(false);
      toast.success('Set as day off');
    } catch (error) {
      toast.error('Failed to set day off');
    }
  };

  const handleResetToDefault = async () => {
    if (!selectedDate) return;
    
    try {
      await axios.delete('/user/capacity/override', {
        data: { date: dayjs(selectedDate).format('YYYY-MM-DD') }
      });
      await fetchCapacityData();
      setCapacityModalVisible(false);
      toast.success('Reset to default');
    } catch (error) {
      toast.error('Failed to reset to default');
    }
  };

  return (
    <div className="space-y-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleDefaultCapacityUpdate)} className="space-y-6">
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="default_capacity"
              render={() => (
                <FormItem>
                  <FormLabel className="text-base font-semibold">Default Daily Capacity</FormLabel>
                  <div className="flex gap-4">
                    <FormField
                      control={form.control}
                      name="default_capacity.hours"
                      render={({ field }) => (
                        <FormItem className="flex-1">
                          <FormLabel>Hours</FormLabel>
                          <FormControl>
                            <Input
                              type="number"
                              min={0}
                              max={24}
                              {...field}
                              onChange={(e) => field.onChange(parseInt(e.target.value) || 0)}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="default_capacity.minutes"
                      render={({ field }) => (
                        <FormItem className="flex-1">
                          <FormLabel>Minutes</FormLabel>
                          <FormControl>
                            <Input
                              type="number"
                              min={0}
                              max={59}
                              {...field}
                              onChange={(e) => field.onChange(parseInt(e.target.value) || 0)}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                </FormItem>
              )}
            />

            <FormItem>
              <FormLabel className="text-base font-semibold">Default Work Days</FormLabel>
              <div className="grid grid-cols-4 gap-4">
                {Object.entries(workDays).map(([day, checked]) => (
                  <div key={day} className="flex items-center space-x-2">
                    <Checkbox
                      id={day}
                      checked={checked}
                      onCheckedChange={(checked) => 
                        setWorkDays(prev => ({
                          ...prev,
                          [day]: checked === true
                        }))
                      }
                    />
                    <label
                      htmlFor={day}
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {day.charAt(0).toUpperCase() + day.slice(1)}
                    </label>
                  </div>
                ))}
              </div>
            </FormItem>
          </div>

          <Button type="submit">Save Default Settings</Button>
        </form>
      </Form>

      <div className="mt-6">
        <CapacityCalendar
          selected={selectedDate}
          onSelect={handleDateSelect}
          capacityData={capacityData || undefined}
          classNames={{
            day: cn(
              "h-full w-full p-0 font-normal aria-selected:opacity-100 hover:bg-muted rounded-none",
              theme === 'dark' ? 'hover:bg-muted/50' : 'hover:bg-accent/50'
            ),
          }}
        />
      </div>

      <Dialog open={capacityModalVisible} onOpenChange={setCapacityModalVisible}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Set Capacity for {selectedDate ? dayjs(selectedDate).format('MMMM D, YYYY') : ''}
            </DialogTitle>
          </DialogHeader>

          <Form {...capacityForm}>
            <form onSubmit={capacityForm.handleSubmit(handleCapacityUpdate)} className="space-y-4">
              <div className="flex gap-4">
                <FormField
                  control={capacityForm.control}
                  name="hours"
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormLabel>Hours</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min={0}
                          max={24}
                          {...field}
                          onChange={(e) => field.onChange(parseInt(e.target.value) || 0)}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={capacityForm.control}
                  name="minutes"
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormLabel>Minutes</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min={0}
                          max={59}
                          {...field}
                          onChange={(e) => field.onChange(parseInt(e.target.value) || 0)}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>

              <DialogFooter className="gap-2 sm:gap-0">
                <Button type="submit">
                  Save
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={handleSetDayOff}
                >
                  Set to Zero (Day Off)
                </Button>
                <Button
                  type="button"
                  variant="outline"
                  onClick={handleResetToDefault}
                >
                  Reset to Default
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UserCapacitySettings;