import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '@/api/axiosConfig';
import { showNotificationToast } from '@/components/ui/notification-toast';
import { useNavigate } from 'react-router-dom';

// Add event system for notifications modal
const SHOW_NOTIFICATIONS_EVENT = 'showNotificationsModal';

export const openNotificationsModal = () => {
  window.dispatchEvent(new CustomEvent(SHOW_NOTIFICATIONS_EVENT));
};

interface Notification {
  id: number;
  title: string;
  message: string;
  type: string;
  data: Record<string, any>;
  navigation?: {
    route: string;
    params?: Record<string, string | number>;
  };
  is_read: boolean;
  is_archived: boolean;
  created_at: string;
}

interface NotificationContextType {
  notifications: Notification[];
  unreadCount: number;
  fetchNotifications: () => Promise<void>;
  markAsRead: (notificationIds: number[]) => Promise<void>;
  archiveNotifications: (notificationIds: number[]) => Promise<void>;
  openNotificationsDialog: () => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

const POLLING_INTERVAL = 30000; // Poll every 30 seconds
const NOTIFICATION_THROTTLE = 5000; // Minimum time between alerts in milliseconds

export function NotificationProvider({ children }: { children: React.ReactNode }) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [lastNotificationTime, setLastNotificationTime] = useState(0);
  const [isNotificationsDialogOpen, setIsNotificationsDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (route: string, params?: Record<string, string | number>) => {
    // Special handling for custom board task routes
    if (route === '/boards' && params?.boardId && params?.taskId) {
      navigate(`/custom-boards/${params.boardId}/task/${params.taskId}`);
      return;
    }

    // Default handling for other routes
    if (params) {
      const queryParams = new URLSearchParams();
      Object.entries(params).forEach(([key, value]) => {
        queryParams.append(key, String(value));
      });
      navigate(`${route}?${queryParams.toString()}`);
    } else {
      navigate(route);
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await api.get('/notifications/');
      const newNotifications = response.data.notifications;
      setNotifications(newNotifications);
      setUnreadCount(response.data.unread_count);

      // Check for new notifications to display alerts
      const currentTime = Date.now();
      if (currentTime - lastNotificationTime >= NOTIFICATION_THROTTLE) {
        const recentUnread = newNotifications.filter(
          (n: Notification) => 
            !n.is_read && 
            new Date(n.created_at).getTime() > lastNotificationTime
        );

        // Only show alert if user is not viewing notifications dialog
        if (recentUnread.length > 0 && !isNotificationsDialogOpen) {
          recentUnread.forEach((notification: Notification) => {
            showNotificationToast({
              title: notification.title,
              message: notification.message,
              navigation: notification.navigation,
              onNavigate: handleNavigation
            });
          });
          setLastNotificationTime(currentTime);
        }
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const markAsRead = async (notificationIds: number[]) => {
    try {
      await api.post('/notifications/mark-read', { notification_ids: notificationIds });
      await fetchNotifications(); // Refresh notifications
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  const archiveNotifications = async (notificationIds: number[]) => {
    try {
      await api.post('/notifications/archive', { notification_ids: notificationIds });
      await fetchNotifications(); // Refresh notifications
    } catch (error) {
      console.error('Error archiving notifications:', error);
    }
  };

  useEffect(() => {
    // Initial fetch
    fetchNotifications();

    // Set up polling
    const interval = setInterval(fetchNotifications, POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  const value = {
    notifications,
    unreadCount,
    fetchNotifications,
    markAsRead,
    archiveNotifications,
    openNotificationsDialog: () => setIsNotificationsDialogOpen(true)
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
}; 