import { Task, UnifiedTask } from './task';
import { BoardStatusOption as TaskBoardStatusOption } from '@/taskboards/config/board-status';
import { BoardStatusOption as CustomBoardStatusOption } from './custom-board';
import { ColumnDef as TableColumnDef } from './table';
import { ColumnDef as ModTableColumnDef } from '@/components/ui/specialized/mod-table';

export type UnifiedBoardStatusOption = {
  id: number;
  value: string;
  label: string;
  color: string;
  description?: string | null;
  order?: number;
};

export const convertToUnifiedBoardStatusOption = (
  options: (TaskBoardStatusOption | CustomBoardStatusOption)[] = []
): UnifiedBoardStatusOption[] => {
  return options.map((opt, index) => {
    if ('id' in opt) {
      // This is a custom board status option
      return {
        id: opt.id,
        value: opt.value,
        label: opt.label,
        color: opt.color,
        description: opt.description || null,
        order: opt.order !== undefined ? opt.order : index
      };
    } else {
      // This is a normal taskboard status option
      return {
        id: index + 1,
        value: opt.value,
        label: opt.label,
        color: opt.color,
        description: null,
        order: index
      };
    }
  });
};

export type UnifiedColumnDef<T> = {
  title: string;
  key: string;
  dataIndex: keyof T;
  cell: (props: { row: { original: T } }) => React.ReactNode;
  render?: (value: any) => React.ReactNode;
  sortable?: {
    sorter: (a: T, b: T) => number;
    sortDirections: string[];
  };
  filterable?: boolean;
  filters?: { text: string; value: any }[];
  onFilter?: (value: any, record: T) => boolean;
};

export const createUnifiedColumns = <T extends Task | UnifiedTask>(
  columns: (TableColumnDef<T> | ModTableColumnDef<T>)[]
): UnifiedColumnDef<T>[] => {
  return columns.map(col => ({
    title: col.title,
    key: col.key,
    dataIndex: col.dataIndex as keyof T,
    cell: (props: { row: { original: T } }) => {
      if (col.cell) {
        return col.cell(props);
      }
      const value = props.row.original[col.dataIndex as keyof T];
      return value ? String(value) : '';
    },
  }));
}; 