import React, { useEffect } from 'react';
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { PlusCircle, Trash2 } from "lucide-react";
import { SelectConfig as SelectConfigType } from '@/onboardingflow/types/node_types';
import { DealDataField } from '@/configs/DealDataConfig';
import { useOnboardingStore } from '@/onboardingflow/onboardingStore';
import get from 'lodash/get';

interface SelectConfigProps {
  config: SelectConfigType;
  elementId: string;
  dealDataConfig: DealDataField[];
  onChange: (updates: Partial<SelectConfigType>) => void;
}

export function SelectConfig({ config, elementId, dealDataConfig, onChange }: SelectConfigProps) {
  const deal = useOnboardingStore(state => state.deal);

  useEffect(() => {
    if (deal && config.dealDataKey) {
      const dealValue = get(deal, config.dealDataKey);
      if (dealValue && config.options.some(opt => opt.value === dealValue)) {
        onChange({ selectedValue: dealValue });
      }
    }
  }, [deal, config.dealDataKey, config.options, onChange]);

  return (
    <div className="space-y-4">
      <div>
        <Label>Label</Label>
        <Input
          value={config.label || ''}
          onChange={(e) => onChange({ label: e.target.value })}
          placeholder="Enter label"
        />
      </div>
      <div>
        <Label>Placeholder</Label>
        <Input
          value={config.placeholder || ''}
          onChange={(e) => onChange({ placeholder: e.target.value })}
          placeholder="Enter placeholder"
        />
      </div>
      <div className="flex items-center gap-2">
        <Checkbox
          checked={config.required || false}
          onCheckedChange={(checked) => onChange({ required: !!checked })}
          id="required"
        />
        <Label htmlFor="required">Required</Label>
      </div>
      <div className="flex items-center gap-2">
        <Checkbox
          checked={config.triggerOnChange || false}
          onCheckedChange={(checked) => onChange({ triggerOnChange: !!checked })}
          id="triggerOnChange"
        />
        <Label htmlFor="triggerOnChange">Trigger on Change</Label>
        <p className="text-sm text-muted-foreground ml-2">
          When enabled, form submission will be triggered automatically when this field's value changes.
        </p>
      </div>
      <div>
        <Label>Deal Data Field</Label>
        <p className="text-sm text-muted-foreground mb-2">
          When selected, this field will automatically be populated with the corresponding value from the deal data. Leave empty to disable auto-selection.
        </p>
        <Select
          value={config.dealDataKey || ''}
          onValueChange={(value) => onChange({ dealDataKey: value })}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select deal data field" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="_none">None</SelectItem>
            {dealDataConfig.map((field: DealDataField) => (
              field.type === 'dropdown' && (
                <SelectItem key={field.key} value={field.key}>
                  {field.label}
                </SelectItem>
              )
            ))}
          </SelectContent>
        </Select>
      </div>
      <div>
        <div className="flex items-center justify-between mb-2">
          <Label>Options</Label>
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={() => onChange({ 
              options: [...(config.options || []), { label: '', value: '' }] 
            })}
            className="h-8"
          >
            <PlusCircle className="w-4 h-4 mr-1" />
            Add Option
          </Button>
        </div>
        <div className="space-y-2">
          {(config.options || []).map((option, index) => (
            <div key={index} className="flex items-center gap-2">
              <Input
                value={option.label}
                onChange={(e) => {
                  const newOptions = [...config.options];
                  newOptions[index] = { 
                    ...newOptions[index],
                    label: e.target.value
                  };
                  onChange({ options: newOptions });
                }}
                placeholder="Option label"
                className="flex-1"
              />
              <Input
                value={option.value}
                onChange={(e) => {
                  const newOptions = [...config.options];
                  newOptions[index] = { 
                    ...newOptions[index],
                    value: e.target.value
                  };
                  onChange({ options: newOptions });
                }}
                placeholder="Option value"
                className="flex-1"
              />
              <Button
                type="button"
                variant="ghost"
                size="icon"
                onClick={() => {
                  const newOptions = [...config.options];
                  newOptions.splice(index, 1);
                  onChange({ options: newOptions });
                }}
                className="h-8 w-8"
              >
                <Trash2 className="w-4 h-4" />
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
