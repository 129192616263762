import React from 'react'
import { useUsers } from '@/contexts/UserContext'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

interface EmailSenderProps {
  onSenderChange: (email: string) => void;
}

export function EmailSender({ onSenderChange }: EmailSenderProps) {
  const { currentUser } = useUsers()

  // Set initial value when component mounts
  React.useEffect(() => {
    if (currentUser?.email) {
      console.log('Setting initial sender email:', currentUser.email); // Debug log
      onSenderChange(currentUser.email);
    }
  }, [currentUser?.email]);

  return (
    <div className="flex gap-2 items-center">
      <label className="min-w-[60px] text-sm font-medium text-foreground">From:</label>
      <Select 
        defaultValue={currentUser?.email}
        onValueChange={onSenderChange}
      >
        <SelectTrigger className="w-[300px]">
          <SelectValue placeholder="Select sender email" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value={currentUser?.email || ''}>
            {currentUser?.name} {currentUser?.surname} ({currentUser?.email})
          </SelectItem>
        </SelectContent>
      </Select>
    </div>
  )
}