import { Globe } from '@/components/globe';
import type { GlobeInstance as GlobeInstanceType } from '@/types/globe';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import axios from '../api/axiosConfig';
import ReactDOMServer from 'react-dom/server';
import confetti from 'canvas-confetti';
import { WiDaySunny, WiCloudy, WiRain, WiStrongWind, WiSnow, WiThunderstorm, WiDust } from 'react-icons/wi';
import { StoredUser, UserSettings, LocationInfo, UserColors } from '@/types/user';
import { UnifiedTask, FrontendTaskType } from '@/types/task';
import { 
  Cake, 
  LineChart, 
  Calendar, 
  Users, 
  Settings, 
  Palette, 
  UserPlus,
  Play,
  Pause,
  Folder,
  Globe as GlobeIcon,
  User as UserIcon
} from 'lucide-react';
import UserAvatar from '@/components/user-avatar';
import { cn } from "@/lib/utils";
import { Separator } from "@/components/ui/separator";
import { getQuoteForDate } from '@/configs/HomeQuotes';
import { useUsers } from '@/contexts/UserContext';

// shadcn components
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Skeleton } from "@/components/ui/skeleton";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { overrideOnBeforeRender } from '@/lib/globe-utils';

// Initialize dayjs plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Update the User interface to use our shared types
interface User extends StoredUser {
  nextBirthday?: dayjs.Dayjs
  daysUntilBirthday?: number
  location_info?: LocationInfo & {
    latitude: number;
    longitude: number;
  };
}

interface HomeProps {
  user: User;
}

interface UserWithBirthday extends StoredUser {
  nextBirthday: dayjs.Dayjs;
  daysUntilBirthday: number;
}

interface WeatherData {
  [key: string]: {
    icon: string;
    temperature: number;
    description: string;
  };
}

function Home({ user }: HomeProps) {
  const { users, onlineUsers, isLoading: usersLoading } = useUsers();
  const [isRotating, setIsRotating] = useState(true);
  const navigate = useNavigate();
  
  const [teamBriefing, setTeamBriefing] = useState<string | null>(null);
  const [userBriefing, setUserBriefing] = useState<string | null>(null);
  const [teamBriefingType, setTeamBriefingType] = useState<string>('morning');
  const [userBriefingType, setUserBriefingType] = useState<string>('morning');
  const [weatherData, setWeatherData] = useState<WeatherData>({});
  const [upcomingTasks, setUpcomingTasks] = useState<UnifiedTask[]>([]);
  const [briefingsEnabled, setBriefingsEnabled] = useState(true);
  const [tasksLoading, setTasksLoading] = useState(true);
  const [loading, setLoading] = useState(!user);
  const birthdayCardRef = useRef<HTMLDivElement>(null);
  const [globeInstance, setGlobeInstance] = useState<GlobeInstanceType | null>(null);

  const resources = [
    { title: 'Sales', url: 'https://www.notion.so/Salg-c546324d6b7b4c138818a5b59051d5c2?pvs=25', icon: 'chart-line' },
    { title: 'Meeting Scheduling', url: 'https://www.notion.so/M-debooking-36b6ea9e26714641887d3f6bdc197640', icon: 'calendar' },
    { title: 'Customer Success', url: 'https://www.notion.so/Customer-Succes-126110f44f29481596a9e5886bf8ef28', icon: 'users' },
    { title: 'Operations', url: 'https://www.notion.so/Operations-191749bb9ecf4a4fad37f70eff35857b', icon: 'settings' },
    { title: 'Brand Guidelines', url: 'https://www.notion.so/Brand-Guidelines-6d487abcfb754d0198be6cd45fb0303c', icon: 'palette' },
    { title: 'Employee Onboarding', url: 'https://www.notion.so/Medarbejder-Onboarding-aedead79ae0642c6af2e177ca008c382', icon: 'user-plus' },
  ];

  const getResourceIcon = (iconName: string) => {
    switch (iconName) {
      case 'chart-line':
        return <LineChart className="h-4 w-4" />;
      case 'calendar':
        return <Calendar className="h-4 w-4" />;
      case 'users':
        return <Users className="h-4 w-4" />;
      case 'settings':
        return <Settings className="h-4 w-4" />;
      case 'palette':
        return <Palette className="h-4 w-4" />;
      case 'user-plus':
        return <UserPlus className="h-4 w-4" />;
      default:
        return <LineChart className="h-4 w-4" />;
    }
  };

  useEffect(() => {
    if (user) {
      fetchBriefings();
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchUpcomingTasks();
  }, []);

  const fetchWeatherData = async (users: StoredUser[]) => {
    try {
      const weatherResponse = await axios.get('/user/weather', { 
        params: { user_ids: users.map(user => user.user_id) }
      });
      setWeatherData(weatherResponse.data);
    } catch (error) {
      console.error('Error fetching weather data:', error);
    }
  };

  const fetchUpcomingTasks = async () => {
    try {
      setTasksLoading(true);
      const response = await axios.get('/work/upcoming_tasks');
      setUpcomingTasks(response.data);
    } catch (error) {
      console.error('Error fetching upcoming tasks:', error);
    } finally {
      setTasksLoading(false);
    }
  };

  const fetchBriefings = async () => {
    try {
      const teamResponse = await axios.get('/ai/get_briefing?target=team');
      
      if (!teamResponse.data.is_enabled) {
        setBriefingsEnabled(false);
        return;
      }
      
      setTeamBriefing(teamResponse.data.content);
      setTeamBriefingType(teamResponse.data.type);
  
      if (user && user.user_id) {
        const userResponse = await axios.get(`/ai/get_briefing?target=user&user_id=${user.user_id}`);
        setUserBriefing(userResponse.data.content);
        setUserBriefingType(userResponse.data.type);
      }
    } catch (error) {
      console.error('Error fetching briefings:', error);
      setTeamBriefing(null);
      setUserBriefing(null);
      setTeamBriefingType('morning');
      setUserBriefingType('morning');
    }
  };

  const getGlobeTooltipContent = useCallback((user: StoredUser) => {
    return `
      <div class="z-50 rounded-lg border border-border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95">
        <div class="flex flex-col gap-1">
          <span class="font-medium">${user.name} ${user.surname}</span>
          <span class="text-muted-foreground">${user.location_info?.city}, ${user.location_info?.country}</span>
          <span class="text-muted-foreground">Local time: ${dayjs().tz(user.location_info?.timezone || 'UTC').format('HH:mm')}</span>
          ${weatherData[user.user_id] ? `
            <div class="flex items-center gap-2 text-muted-foreground">
              ${weatherData[user.user_id].temperature}°C ${weatherData[user.user_id].description}
            </div>
          ` : 'Loading weather data...'}
        </div>
      </div>
    `;
  }, [weatherData]);

  const handleGlobeReady = useCallback((globe: GlobeInstanceType) => {
    globe.controls().autoRotate = isRotating;
    globe.controls().autoRotateSpeed = 0.5;
    
    if (users.length > 0) {
      globe
        .pointsData(users)
        .pointLat((d: StoredUser) => d.location_info?.latitude || 0)
        .pointLng((d: StoredUser) => d.location_info?.longitude || 0)
        .pointColor((d: StoredUser) => d.settings?.avatar_color || '#FFFFFF')
        .pointAltitude(0.03)
        .pointRadius(0.5)
        .pointLabel(getGlobeTooltipContent);
        
      // Override onBeforeRender after adding points
      overrideOnBeforeRender(globe);
      
      // Clean materials if applicable
      (globe as any).cleanMaterials?.();
    }

    console.log('Globe instance ready in Home.tsx');
  }, [users, isRotating, getGlobeTooltipContent]);

  const getWeatherIcon = (iconCode: string) => {
    switch (iconCode) {
      case '01d':
      case '01n':
        return <WiDaySunny className="h-6 w-6" />;
      case '02d':
      case '02n':
      case '03d':
      case '03n':
      case '04d':
      case '04n':
        return <WiCloudy className="h-6 w-6" />;
      case '09d':
      case '09n':
      case '10d':
      case '10n':
        return <WiRain className="h-6 w-6" />;
      case '11d':
      case '11n':
        return <WiThunderstorm className="h-6 w-6" />;
      case '13d':
      case '13n':
        return <WiSnow className="h-6 w-6" />;
      case '50d':
      case '50n':
        return <WiDust className="h-6 w-6" />;
      case '23d':
      case '23n':
        return <WiStrongWind className="h-6 w-6" />;
      default:
        return <WiDaySunny className="h-6 w-6" />;
    }
  };

  const toggleRotation = () => {
    if (globeInstance) {
      globeInstance.controls().autoRotate = !isRotating;
      setIsRotating(!isRotating);
    }
  };

  const getLocalTime = (locationInfo: User['location_info']) => {
    if (!locationInfo || !locationInfo.timezone) return 'Unknown';
    return dayjs().tz(locationInfo.timezone).format('HH:mm');
  };

  const getLocation = (locationInfo: User['location_info']) => {
    if (!locationInfo) return 'Unknown';
    return `${locationInfo.city}, ${locationInfo.country}`;
  };

  const getDisplayName = (user: StoredUser) => {
    return `${user.name || ''} ${user.surname || ''}`.trim() || user.email || 'Unknown User';
  };

  const getDisplayFirstName = () => {
    if (!user || !user.name) return '';
    return user.name.split(' ')[0];
  };

  const getUpcomingBirthdays = () => {
    const today = dayjs().startOf('day');
    return users
      .filter((user: StoredUser) => user.birthday)
      .map((user: StoredUser) => {
        const birthday = dayjs(user.birthday).startOf('day');
        let nextBirthday = dayjs(birthday).year(today.year());
        if (nextBirthday.isBefore(today)) {
          nextBirthday = nextBirthday.add(1, 'year');
        }
        const daysUntilBirthday = nextBirthday.diff(today, 'days');
        return {
          ...user,
          nextBirthday,
          daysUntilBirthday
        };
      })
      .sort((a, b) => a.daysUntilBirthday - b.daysUntilBirthday)
      .slice(0, 3);
  };

  const triggerBirthdayConfetti = useCallback((element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const x = (rect.left + rect.width / 2) / window.innerWidth;
    const y = rect.top / window.innerHeight;
  
    const duration = 5 * 1000;
    const animationEnd = Date.now() + duration;
  
    const randomInRange = (min: number, max: number) => Math.random() * (max - min) + min;
  
    (function frame() {
      const timeLeft = animationEnd - Date.now();
  
      confetti({
        particleCount: 5,
        startVelocity: 15,
        spread: 60,
        origin: { x, y: y + 0.2 },
        colors: ['#ffd700', '#ff0000', '#00ff00', '#0000ff', '#ff00ff'],
        ticks: Math.max(50, 200 * (timeLeft / duration)),
        shapes: ['square', 'circle'],
        gravity: 1.2,
        scalar: randomInRange(0.4, 0.6),
        drift: randomInRange(-0.2, 0.2),
        decay: 0.94,
      });
  
      if (timeLeft > 0) {
        requestAnimationFrame(frame);
      }
    }());
  }, []);

  const BirthdayItem = ({ user, isBirthdayToday, isUpcoming }: {
    user: UserWithBirthday;
    isBirthdayToday: boolean;
    isUpcoming: boolean;
  }) => {
    const itemClass = cn(
      "p-4 rounded-lg",
      isBirthdayToday && "bg-primary/10",
      isUpcoming && "bg-muted"
    );

    return (
      <div className={itemClass}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className="flex-shrink-0">
              <UserAvatar 
                user={user} 
                size={32} 
                onClick={() => {}}
                disableTooltip
              />
            </div>
            <div>
              <p className="font-medium">{user.name} {user.surname}</p>
              {!isBirthdayToday && (
                <p className="text-sm text-muted-foreground">
                  {user.nextBirthday.format('MMM D')}
                  {user.daysUntilBirthday === 1 
                    ? ' (Tomorrow)' 
                    : ` (in ${user.daysUntilBirthday} days)`}
                </p>
              )}
            </div>
          </div>
          {isBirthdayToday && (
            <Badge variant="default" className="bg-primary">Today!</Badge>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const birthdaysToday = getUpcomingBirthdays().filter(user => user.daysUntilBirthday === 0);

    if (birthdaysToday.length > 0) {
      if (birthdayCardRef.current) {
        triggerBirthdayConfetti(birthdayCardRef.current);
      } else {
        console.log('Birthday card ref not found');
      }
    }
  }, [users, triggerBirthdayConfetti]);

  const formatBriefingContent = (content: string | null): string => {
    if (!content) return '';
    return content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  };

  const getGreeting = () => {
    const currentHour = dayjs().hour();
    return currentHour < 12 ? 'morning' : 'afternoon';
  };

  const renderTasksList = () => {
    const sortedTasks = [...upcomingTasks].sort((a: UnifiedTask, b: UnifiedTask) => {
      const getTaskDate = (task: UnifiedTask) => {
        if (['moms', 'lon', 'bogforing', 'arsafslutning'].includes(task.task_type)) {
          return dayjs(task.next_work_date);
        } else if (task.task_type === 'andre-opgaver') {
          return dayjs(task.task_date);
        } else {
          return dayjs(task.work_date);
        }
      };

      return getTaskDate(a).diff(getTaskDate(b));
    });

    const getTaskboardName = (task: UnifiedTask) => {
      if (task.task_type === 'custom' && task.board_id) {
        // For custom tasks, we'll show the board name
        const board = task.board_name || 'Custom Board';
        return board;
      }

      // Map task types to display names for regular tasks
      const taskTypeMap: Record<FrontendTaskType, string> = {
        'bogforing': 'Bogføring',
        'lon': 'Løn',
        'moms': 'Moms',
        'arsafslutning': 'Årsafslutning',
        'andre-opgaver': 'Andre Opgaver',
        'activities': 'Activities',
        'custom': 'Custom'
      };

      return taskTypeMap[task.task_type] || task.task_type;
    };

    const getStatusDisplayName = (task: UnifiedTask) => {
      if (!task.status && !task.progress_status) return '';

      if (task.task_type === 'custom' && task.board_config) {
        // For custom tasks, look up the display name in the board configuration
        const statusValue = task.status || task.progress_status;
        const statusConfig = task.board_config.progress_status_options?.find(
          option => option.value === statusValue
        );
        return statusConfig?.label || statusValue;
      }

      // For regular tasks, just capitalize and replace underscores with spaces
      const status = task.status || task.progress_status || '';
      return status.split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    };

    const visibleTasks = sortedTasks.slice(0, 5);
    
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="text-left">Title</TableHead>
            <TableHead className="text-left">Due Date</TableHead>
            <TableHead className="text-left">Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {visibleTasks.map((task: UnifiedTask) => (
            <TableRow
              key={task.id}
              onClick={() => handleTaskClick(task)}
              className="cursor-pointer hover:bg-muted/80"
            >
              <TableCell className="font-medium text-left">
                <div className="flex items-center gap-2">
                  <Badge variant="outline" className="whitespace-nowrap">
                    {getTaskboardName(task)}
                  </Badge>
                  <span>{task.task_type === 'custom' ? task.title : task.description}</span>
                </div>
              </TableCell>
              <TableCell className="text-left">
                {dayjs(['moms', 'lon', 'bogforing', 'arsafslutning'].includes(task.task_type) ? task.next_work_date : 
                  task.task_type === 'andre-opgaver' ? task.task_date : 
                  task.work_date).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell className="text-left">
                <Badge 
                  variant={task.status?.toLowerCase() === 'completed' ? 'default' : 
                    task.status?.toLowerCase() === 'in progress' ? 'secondary' : 'destructive'}
                >
                  {getStatusDisplayName(task)}
                </Badge>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const getDateClass = (date: string) => {
    const today = dayjs().startOf('day');
    const taskDate = dayjs(date).startOf('day');
    
    if (taskDate.isBefore(today)) {
      return 'overdue';
    } else if (taskDate.isSame(today)) {
      return 'today';
    }
    return '';
  };

  const handleTaskClick = (task: UnifiedTask) => {
    navigate(`/work/${task.task_type}/${task.id}`);
  };

  const UserAvatarWrapper = ({ user, status }: { user: StoredUser; status: string }) => {
    const handleClick = () => {
      console.log('Avatar clicked:', user);
    };

    return (
      <div className={status === 'inactive' ? 'opacity-50' : ''}>
        <UserAvatar
          user={user}
          size={32}
          className="cursor-pointer"
          disableTooltip={true}
          onClick={handleClick}
        />
      </div>
    );
  };

  useEffect(() => {
    if (globeInstance && users.length > 0) {
      console.log('Setting pointsData on globe instance');
      globeInstance
        .pointsData(users)
        .pointLat((d: StoredUser) => d.location_info?.latitude || 0)
        .pointLng((d: StoredUser) => d.location_info?.longitude || 0)
        .pointColor((d: StoredUser) => d.settings?.avatar_color || '#FFFFFF')
        .pointAltitude(0.03)
        .pointRadius(0.5)
        .pointLabel(getGlobeTooltipContent);
  
      // Override onBeforeRender after adding points
      overrideOnBeforeRender(globeInstance);
  
      // Clean materials if applicable
      (globeInstance as any).cleanMaterials?.();
    }
  }, [users, globeInstance, getGlobeTooltipContent]);

  const UserTooltipContent = ({ user, weatherData }: { 
    user: StoredUser; 
    weatherData: WeatherData;
  }) => {
    return (
      <div className="flex flex-col gap-1">
        <div className="font-medium">{getDisplayName(user)}</div>
        <div className="text-sm text-muted-foreground">{user.email}</div>
        <div className="text-sm">{getLocation(user.location_info)}</div>
        <div className="text-sm">{getLocalTime(user.location_info)}</div>
        {weatherData[user.user_id] ? (
          <div className="flex items-center gap-1 mt-1">
            {getWeatherIcon(weatherData[user.user_id].icon)}
            <span>
              {weatherData[user.user_id].temperature}°C {weatherData[user.user_id].description}
            </span>
          </div>
        ) : (
          <div className="text-sm text-muted-foreground">
            Loading weather data...
          </div>
        )}
      </div>
    );
  };

  if (loading) {
    return <div className="p-6"><Skeleton className="h-[600px]" /></div>;
  }

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-foreground">
          Good {getGreeting()}, {getDisplayFirstName()}
        </h1>
        {(() => {
          const currentDate = dayjs().format('DD-MM-YYYY');
          const quote = getQuoteForDate(currentDate);
          return (
            <Badge variant="custom" className="bg-primary text-primary-foreground italic text-sm">
              "{quote.text}" - {quote.author}
            </Badge>
          );
        })()}
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <Card className="col-span-1 md:col-span-2">
          <CardHeader>
            <CardTitle className="flex items-center justify-center gap-2 text-primary font-bold">
              <Calendar className="h-4 w-4" />
              Your upcoming tasks
            </CardTitle>
            <Separator />
          </CardHeader>
          <CardContent>
            {tasksLoading ? (
              <div className="space-y-2">
                {[...Array(5)].map((_, i) => (
                  <Skeleton key={i} className="h-12" />
                ))}
              </div>
            ) : upcomingTasks.length > 0 ? (
              <ScrollArea className="h-[300px]">
                <div className="space-y-2">
                  {renderTasksList()}
                </div>
              </ScrollArea>
            ) : (
              <div className="text-center text-muted-foreground py-8">
                No upcoming tasks
              </div>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center justify-center gap-2 text-primary font-bold">
              <Folder className="h-4 w-4" />
              Resources
            </CardTitle>
            <Separator />
          </CardHeader>
          <CardContent>
            <div className="grid gap-2">
              {resources.map((resource, index) => (
                <a
                  key={index}
                  href={resource.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-between p-2 rounded-lg hover:bg-muted transition-colors hover:text-primary"
                >
                  <span>{resource.title}</span>
                  {getResourceIcon(resource.icon)}
                </a>
              ))}
            </div>
          </CardContent>
        </Card>

        <Card className="col-span-1 md:col-span-2">
          <CardHeader>
            <CardTitle className="flex items-center justify-center gap-2 text-primary font-bold">
              <GlobeIcon className="h-4 w-4" />
              Team Map
            </CardTitle>
            <Separator />
          </CardHeader>
          <CardContent className="relative h-[400px]">
            {!usersLoading ? (
              <Globe
                width={400}
                height={400}
                onGlobeReady={handleGlobeReady}
                users={users}
              />
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-muted-foreground">Loading Globe...</p>
              </div>
            )}
            <div className="absolute right-4 top-4 flex flex-col gap-2">
              {onlineUsers.map(user => (
                <TooltipProvider key={user.user_id} delayDuration={200}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className={user.status === 'inactive' ? 'opacity-50' : ''}>
                        <UserAvatar
                          user={user}
                          size={32}
                          className="cursor-pointer border-2 border-background shadow-md"
                          onClick={() => {}}
                          disableTooltip={true}
                        />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="left" className="p-2">
                      <UserTooltipContent user={user} weatherData={weatherData} />
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ))}
            </div>
            <Button
              size="icon"
              variant="outline"
              onClick={toggleRotation}
              className="absolute bottom-4 right-4"
            >
              {isRotating ? <Pause className="h-4 w-4" /> : <Play className="h-4 w-4" />}
            </Button>
          </CardContent>
        </Card>

        <Card className="h-[500px]" ref={birthdayCardRef}>
          <CardHeader>
            <CardTitle className="flex items-center justify-center gap-2 text-primary font-bold">
              <Cake className="h-4 w-4" />
              Upcoming Birthdays
            </CardTitle>
            <Separator />
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[400px]">
              <div className="space-y-2">
                {usersLoading ? (
                  // Show 3 skeleton rows while loading
                  <div className="space-y-3">
                    {[1, 2, 3].map((i) => (
                      <div key={i} className="flex items-center gap-2">
                        <Skeleton className="h-8 w-8 rounded-full" />
                        <div className="space-y-1">
                          <Skeleton className="h-4 w-[150px]" />
                          <Skeleton className="h-3 w-[100px]" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : users.filter((user: StoredUser) => user.birthday).length > 0 ? (
                  <div className="space-y-3">
                    {getUpcomingBirthdays().slice(0, 3).map((user: UserWithBirthday) => (
                      <BirthdayItem
                        key={user.user_id}
                        user={user}
                        isBirthdayToday={user.daysUntilBirthday === 0}
                        isUpcoming={user.daysUntilBirthday <= 3}
                      />
                    ))}
                  </div>
                ) : (
                  <p className="text-sm text-muted-foreground">No upcoming birthdays</p>
                )}
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center justify-center gap-2 font-bold">
              <Users className="h-4 w-4 text-transparent bg-gradient-to-l from-[hsl(var(--digiai-gradient-from))] to-[hsl(var(--digiai-gradient-to))] bg-clip-text" />
              <span className="bg-gradient-to-l from-[hsl(var(--digiai-gradient-from))] to-[hsl(var(--digiai-gradient-to))] text-transparent bg-clip-text">
                Team {teamBriefingType === 'morning' ? "Morning Briefing" : "Afternoon Update"}
              </span>
            </CardTitle>
            <Separator />
          </CardHeader>
          <CardContent>
            {briefingsEnabled ? (
              teamBriefing ? (
                <div 
                  className="prose dark:prose-invert"
                  dangerouslySetInnerHTML={{ __html: formatBriefingContent(teamBriefing) }} 
                />
              ) : (
                <p className="text-muted-foreground">No team briefing has been generated.</p>
              )
            ) : (
              <p className="text-muted-foreground">
                Team briefings are currently disabled by the system administrator.
              </p>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center justify-center gap-2 font-bold">
              <UserIcon className="h-4 w-4 text-transparent bg-gradient-to-l from-[hsl(var(--digiai-gradient-from))] to-[hsl(var(--digiai-gradient-to))] bg-clip-text" />
              <span className="bg-gradient-to-l from-[hsl(var(--digiai-gradient-from))] to-[hsl(var(--digiai-gradient-to))] text-transparent bg-clip-text">
                Your Personal {userBriefingType === 'morning' ? "Morning Briefing" : "Afternoon Update"}
              </span>
            </CardTitle>
            <Separator />
          </CardHeader>
          <CardContent>
            {briefingsEnabled ? (
              userBriefing ? (
                <div 
                  className="prose dark:prose-invert"
                  dangerouslySetInnerHTML={{ __html: formatBriefingContent(userBriefing) }} 
                />
              ) : (
                <p className="text-muted-foreground">No personal briefing has been generated.</p>
              )
            ) : (
              <p className="text-muted-foreground">
                User briefings are currently disabled by the system administrator.
              </p>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default Home;
