import React, { useState, useEffect, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import { cn } from '@/lib/utils';
import { Skeleton } from "@/components/ui/skeleton";
import { useUsers } from '@/contexts/UserContext';
import { buttonVariants } from "@/components/ui/button";

import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  TouchSensor,
  closestCenter,
  UniqueIdentifier,
  useDroppable,
  useDraggable,
  DragOverEvent,
  pointerWithin,
  rectIntersection,
  getFirstCollision,
  CollisionDetection,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';

// Lucide Icons
import { 
  Eye, 
  MessageSquare, 
  Clock, 
  Calendar,
  Briefcase,
  Edit,
  Plus,
  Minus,
  X,
  ChevronDown,
  ChevronUp,
  Trash2,
  ListTodo
} from 'lucide-react';

// Shadcn Components
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Card, CardContent } from "@/components/ui/card";

// Custom Components
import { TaskDateEditor } from '@/components/TaskDateEditor';
import { TaskNotes } from '@/taskboards/modals/TaskNotes';
import { CustomTaskNotes } from '@/taskboards/custom/modals/CustomTaskNotes';
import { TaskCompleteDrawer } from '../drawers/TaskCompleteDrawer';
import { AssigneeSelection } from '@/modals/AssigneeSelection';
import { DealDrawer } from '@/drawers/DealDrawer';
import { KanbanCard, KanbanCardHeader, KanbanCardContent, KANBAN_COLUMN_WIDTH } from "@/components/ui/specialized/kanban-card"
import TaskView from './TaskView'; // Import TaskView component
import { AvatarGroup } from "@/components/ui/avatars/avatar-group";
import { getBoardStatus } from '../config/board-status'; // Import the function to get board statuses

// Types
import { Task, FrontendTaskType, BackendTaskType, mapToBackendTaskType, TaskToComplete, isRecurringTask, UnifiedTask } from '@/types/task';
import { StoredUser } from '@/types/user';
import type { BoardStatusOption } from '@/types/custom-board';
import { SubTask } from '@/types/custom-board';

// Import the SortableTaskCard component
import { SortableTaskCard } from '../components/SortableTaskCard';

// Import the correct types
import { CardConfig } from '@/taskboards/config/card';
import { type DealConfigItem } from '@/types/taskboard';
import { TaskBadge } from "@/components/ui/specialized/task-badge";
import { type ColorScale } from '@/configs/DealDataConfig';
import EditCustomTaskModal from '../custom/modals/EditCustomTaskModal';
import { Board } from '@/types/custom-board';
import { CustomTask } from '@/types/custom-task';
import EditTaskModal from '../components/EditTaskModal';
import CreateSubTask from '../modals/CreateSubTask';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import {
  Check,
} from "lucide-react";

import { ExpandedSubtasks } from '../components/ExpandedSubtasks';

dayjs.extend(utc);
dayjs.extend(timezone);

const ENABLE_REALTIME = false; // Toggle this to enable/disable real-time updates

// Update the kanban styles
const kanbanStyles = {
  board: "flex h-[calc(100vh-100px)] px-4 overflow-x-auto", // Remove overflow-y-hidden to allow vertical overflow
  columnContent: "flex-1",
  columnCount: "bg-white/20 rounded-full px-2 py-1 text-xs",
  column: "h-full flex-1 mr-4 last:mr-0", // Add right margin except for last column
};

// Create a forwarded ref Badge component
const ForwardedBadge = forwardRef<HTMLDivElement, React.ComponentProps<typeof Badge>>((props, ref) => (
  <Badge {...props} />
));
ForwardedBadge.displayName = 'ForwardedBadge';

const DroppableColumn: React.FC<{
  id: string;
  children: React.ReactNode;
  className?: string;
  tasks: UnifiedTask[];
  loading?: boolean;
  status: BoardStatusOption;
  taskType: FrontendTaskType;
}> = ({ id, children, className, tasks, loading, status, taskType }) => {
  
  const { isOver, setNodeRef } = useDroppable({ 
    id: `status-${status.id}`,
    data: {
      type: 'column',
      status: status
    }
  });

  return (
    <div ref={setNodeRef} className={cn(kanbanStyles.column, "h-full flex-1")}>
      <KanbanCard 
        className={cn(
          isOver && "ring-2 ring-primary ring-inset",
          "transition-all duration-200 h-full"
        )}
      >
        <KanbanCardHeader 
          className="sticky top-0 z-10"
          color={status.color}
        >
          <div className="flex items-center gap-2">
            {status.description ? (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className="cursor-help">{status.label}</span>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{status.description}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : (
              status.label
            )}
            <span className="bg-white/20 rounded-full px-2 py-1 text-xs">
              {loading ? '...' : tasks.length}
            </span>
          </div>
        </KanbanCardHeader>
        <KanbanCardContent
          className={cn(
            className,
            "h-full"
          )}
        >
          <div className="space-y-2 min-h-[50px]">
            {loading ? (
              // Show 3 skeleton cards while loading
              Array.from({ length: 3 }).map((_, index) => (
                <TaskCardSkeleton key={`skeleton-${status.id}-${index}`} />
              ))
            ) : (
              <SortableContext items={tasks.map(t => t.id.toString())} strategy={verticalListSortingStrategy}>
                {children}
              </SortableContext>
            )}
          </div>
        </KanbanCardContent>
      </KanbanCard>
    </div>
  );
};

const TaskCardSkeleton = () => {
  return (
    <div className="my-2 px-2">
      <Card className="w-full p-4">
        <div className="space-y-4">
          {/* Deal badge skeleton */}
          <div className="flex flex-col items-center text-center">
            <Skeleton className="h-6 w-32" /> {/* Deal name */}
            
            {/* Description skeleton */}
            <div className="mt-2 space-y-2">
              <Skeleton className="h-4 w-[80%]" />
              <Skeleton className="h-4 w-[60%]" />
            </div>
            
            {/* Frequency badge skeleton */}
            <Skeleton className="mt-2 h-5 w-20" />
          </div>

          {/* Date buttons skeleton */}
          <div className="grid grid-cols-2 gap-2">
            <Skeleton className="h-9 w-full rounded-md" />
            <Skeleton className="h-9 w-full rounded-md" />
          </div>

          {/* Tags skeleton */}
          <div className="flex justify-center gap-2">
            <Skeleton className="h-5 w-16 rounded-full" />
            <Skeleton className="h-5 w-16 rounded-full" />
          </div>

          {/* Footer skeleton */}
          <div className="flex justify-between items-center">
            {/* Avatars skeleton */}
            <div className="flex -space-x-2">
              <Skeleton className="h-8 w-8 rounded-full" />
              <Skeleton className="h-8 w-8 rounded-full" />
            </div>
            
            {/* Action buttons skeleton */}
            <div className="flex gap-2">
              <Skeleton className="h-8 w-8 rounded-md" />
              <Skeleton className="h-8 w-8 rounded-md" />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export interface TaskKanbanProps {
  tasks: UnifiedTask[];
  onTaskUpdate: (taskId: string, updatedFields: Partial<UnifiedTask>) => Promise<void>;
  boardStatusOptions: BoardStatusOption[];
  cardConfig: CardConfig;
  dealDataConfig: DealConfigItem[];
  dealSoftwareConfig: DealConfigItem[];
  taskType: FrontendTaskType;
  setTasks: React.Dispatch<React.SetStateAction<UnifiedTask[]>>;
  loading: boolean;
  onAssigneeUpdate: (taskId: number, newAssignees: string[]) => Promise<void>;
  board: Board | null;
  isKanbanReversed?: boolean;
  onSubtaskClick?: (taskId: number, subtaskId: number) => void;
}

export function TaskKanban({
  tasks,
  onTaskUpdate,
  boardStatusOptions,
  cardConfig,
  dealDataConfig,
  dealSoftwareConfig,
  taskType,
  setTasks,
  loading,
  onAssigneeUpdate,
  board,
  isKanbanReversed = false,
  onSubtaskClick
}: TaskKanbanProps) {
  const { users } = useUsers();
  const [localTasks, setLocalTasks] = useState<UnifiedTask[]>(tasks);
  const navigate = useNavigate();
  const [selectedTask, setSelectedTask] = useState<UnifiedTask | null>(null);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isCompleteDrawerOpen, setIsCompleteDrawerOpen] = useState(false);
  const [completedTask, setCompletedTask] = useState<UnifiedTask | null>(null);
  const [isAssigneeModalOpen, setIsAssigneeModalOpen] = useState(false);
  const [selectedTaskForAssignee, setSelectedTaskForAssignee] = useState<UnifiedTask | null>(null);
  const [isDealDrawerOpen, setIsDealDrawerOpen] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState<number | null>(null);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [activeTask, setActiveTask] = useState<UnifiedTask | null>(null);
  const [isTaskViewOpen, setIsTaskViewOpen] = useState(false);
  const [taskViewTask, setTaskViewTask] = useState<UnifiedTask | null>(null);
  const [originalTaskState, setOriginalTaskState] = useState<UnifiedTask | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedTaskForEdit, setSelectedTaskForEdit] = useState<CustomTask | null>(null);
  const [kanbanColumns, setKanbanColumns] = useState<BoardStatusOption[]>(boardStatusOptions);
  const [isEditTaskModalOpen, setIsEditTaskModalOpen] = useState(false);
  const [selectedTaskForNormalEdit, setSelectedTaskForNormalEdit] = useState<UnifiedTask | null>(null);
  const [showSubtasks, setShowSubtasks] = useState<Record<string, boolean>>({});
  const [isCreateSubTaskModalOpen, setIsCreateSubTaskModalOpen] = useState(false);
  const [selectedTaskForSubtask, setSelectedTaskForSubtask] = useState<UnifiedTask | null>(null);
  const [taskToDelete, setTaskToDelete] = useState<UnifiedTask | null>(null);
  const [lastDraggedOverId, setLastDraggedOverId] = useState<UniqueIdentifier | null>(null);

  useEffect(() => {
    setLocalTasks(tasks);
  }, [tasks]);

  useEffect(() => {
    if (taskType === 'custom' && board?.board_config) {
      const { kanban_source, progress_status_options, board_status_options } = board.board_config;
      
      // Set the appropriate status options based on kanban_source and isKanbanReversed
      let newColumns;
      if (isKanbanReversed) {
        // When reversed, use the opposite of what's configured as the source
        newColumns = kanban_source === 'progress' ? board_status_options || [] : progress_status_options || [];
      } else {
        // Use the configured source
        newColumns = kanban_source === 'progress' ? progress_status_options || [] : board_status_options || [];
      }
      
      setKanbanColumns(newColumns);
    } else {
      // For non-custom boards, use the provided boardStatusOptions
      setKanbanColumns(boardStatusOptions);
    }
  }, [taskType, board?.board_config, boardStatusOptions, isKanbanReversed]);

  // Custom collision detection algorithm
  const collisionDetectionStrategy: CollisionDetection = (args) => {
    // First, let's get the droppable areas we're intersecting with
    const pointerCollisions = pointerWithin(args);
    
    // If we have collisions
    if (pointerCollisions.length > 0) {
      // Get all column collisions (items that start with 'status-')
      const columnCollisions = pointerCollisions.filter(
        collision => String(collision.id).startsWith('status-')
      );

      // If we're over a column, only return that collision
      if (columnCollisions.length > 0) {
        return [columnCollisions[0]];
      }
    }

    // Fallback to closest center for other cases
    return closestCenter(args);
  };

  // Add drag over handler
  const handleDragOver = (event: DragOverEvent) => {
    const { over } = event;
    setLastDraggedOverId(over?.id ?? null);
  };

  // Update the sensors configuration
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 8,
      },
    })
  );

  // Convert custom board status option to standard board status option
  const convertToStandardBoardStatus = (opt: BoardStatusOption): BoardStatusOption => ({
    id: opt.id,
    value: opt.value ?? '',
    label: opt.label ?? '',
    color: opt.color ?? '',
    description: opt.description ?? null,
    order: opt.order
  });

  // Determine which status options to use for kanban columns
  const getKanbanStatusOptions = (): BoardStatusOption[] => {
    if (taskType === 'custom' && board?.board_config) {
      const { kanban_source, progress_status_options, board_status_options } = board.board_config;
      
      let options;
      if (isKanbanReversed) {
        // When reversed, use the opposite of what's configured as the source
        options = kanban_source === 'progress' ? board_status_options : progress_status_options;
      } else {
        // Use the configured source
        options = kanban_source === 'progress' ? progress_status_options : board_status_options;
      }

      return options || [];
    }
    return boardStatusOptions;
  };

  const kanbanStatusOptions = getKanbanStatusOptions();

  const getTasksForStatus = (status: string | BoardStatusOption): UnifiedTask[] => {
    return localTasks.filter((task: UnifiedTask) => {
      if (taskType === 'custom' && board?.board_config) {
        const { kanban_source } = board.board_config;
        
        const currentStatusField = isKanbanReversed
          ? (kanban_source === 'progress' ? 'board_status_id' : 'progress_status_id')
          : (kanban_source === 'progress' ? 'progress_status_id' : 'board_status_id');

        // Handle both string and BoardStatusOption
        const statusId = typeof status === 'string' 
          ? kanbanStatusOptions.find(opt => opt.value === status)?.id 
          : status.id;

        if (statusId === undefined) return false;

        // Compare using status IDs
        const taskStatusId = task[currentStatusField as keyof UnifiedTask];
        return taskStatusId === statusId;
      }
      
      // For non-custom tasks, compare string values
      const statusValue = typeof status === 'string' ? status : status.value;
      return String(task.board_status || '').toLowerCase() === String(statusValue || '').toLowerCase();
    });
  };

  const getColumnColor = (status: string): string => {
    const option = boardStatusOptions.find(o => o.value === status);
    return option?.color || 'gray';
  };

  const handleOpenTask = (taskId: number) => {
    if (board) {
      navigate(`/custom-boards/${board.id}/task/${taskId}`, { 
        state: { 
          tasks,
          taskType,
          selectedTask: tasks.find(t => t.id === taskId)
        },
        replace: true
      });
    } else {
      navigate(`${taskId}`, { 
        state: { 
          tasks,
          taskType,
          selectedTask: tasks.find(t => t.id === taskId)
        },
        replace: true
      });
    }
  };

  const getTagColor = (key: string, value: any): string => {
    const config = dealDataConfig.find(item => item.key === `data.${key}`);
    if (config && config.type === 'dropdown') {
      const option = config.options?.find((opt: any) => opt.value === value);
      return option?.color || 'default';
    }
    return 'default';
  };

  const mapFrequencyToLabel = (frequency?: { freq: string }): string => {
    if (!frequency?.freq) return 'N/A';
    const mapping: Record<string, string> = {
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      MONTHLY: 'Monthly',
      QUARTERLY: 'Quarterly',
      YEARLY: 'Yearly'
    };
    return mapping[frequency.freq.toUpperCase()] || frequency.freq;
  };

  const handleMessageIconClick = async (task: UnifiedTask, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      let endpoint;
      if (taskType === 'custom') {
        endpoint = `/custom-board/tasks/${task.id}`;
      } else {
        // For recurring tasks, map to the correct backend task type
        const backendTaskType = (() => {
          if (['bogforing', 'lon', 'moms', 'arsafslutning'].includes(taskType)) {
            return taskType;
          } else if (taskType === 'andre-opgaver') {
            return 'other';
          } else if (taskType === 'activities') {
            return 'activity';
          }
          return taskType;
        })();
        endpoint = `/taskboard/tasks/${backendTaskType}/${task.id}`;
      }

      const response = await axios.get(endpoint);
      if (response.status === 200) {
        setSelectedTask(response.data);
        setIsNotesModalOpen(true);
      }
    } catch (error) {
      console.error('Error fetching task details:', error);
      toast.error('Failed to fetch task details');
    }
  };

  const getUpdatesCount = (task: UnifiedTask): number => {
    return task.comments?.length || 0;
  };

  const handleDealClick = (e: React.MouseEvent, dealId: number) => {
    e.stopPropagation();
    setSelectedDealId(dealId);
    setIsDealDrawerOpen(true);
  };

  const getAssignedUsers = (task: UnifiedTask | SubTask & { task_type?: string }): StoredUser[] => {
    if (!task.assigned_users?.length) return [];
    
    // Ensure all IDs are strings
    const assigneeIds = task.assigned_users.map((id: string) => String(id));
    
    return assigneeIds
      .map((userId: string) => users.find((u: StoredUser) => String(u.user_id) === userId))
      .filter((u: StoredUser | undefined): u is StoredUser => u !== undefined);
  };

  // Add a function to get tags based on task configuration
  const getTaskTags = (task: UnifiedTask, dealDataConfig: DealConfigItem[], dealSoftwareConfig: DealConfigItem[]): JSX.Element => {
    const tags: { key: string; value: string; color?: string; label: string }[] = [];

    // Helper function to get nested value and handle both data and software paths
    const getNestedValue = (obj: any, path: string) => {
      // Handle both data and software paths
      const parts = path.split('.');
      let value = obj;
      
      // For data fields
      if (parts[0] === 'data') {
        value = obj.deal_data;
        parts.shift(); // Remove 'data'
      }
      // For software fields
      else if (parts[0] === 'software') {
        value = obj.deal_software;
        parts.shift(); // Remove 'software'
      }

      // Navigate through remaining parts
      for (const part of parts) {
        if (value && typeof value === 'object') {
          value = value[part];
        } else {
          return undefined;
        }
      }
      return value;
    };

    // Get values from deal_data
    dealDataConfig.forEach(config => {
      const value = getNestedValue(task, config.key);
      
      if (value) {
        let color;
        
        if (config.type === 'number' && config.colorScale) {
          const numValue = Number(value);
          const scale = config.colorScale.find((s: ColorScale) => numValue <= s.max);
          color = scale?.color;
        } else if (config.type === 'dropdown' && config.options) {
          const option = config.options.find(opt => opt.value === value);
          color = option?.color;
        }
        
        tags.push({
          key: config.key,
          value: value.toString(),
          color,
          label: config.label
        });
      }
    });

    // Get values from deal_software
    dealSoftwareConfig.forEach(config => {
      const value = getNestedValue(task, `software.${config.key}`);
      
      if (value && config.options) {
        const option = config.options.find(opt => opt.value === value);
        
        tags.push({
          key: `software.${config.key}`,
          value: value.toString(),
          color: option?.color,
          label: config.label
        });
      }
    });

    return <TaskBadge items={tags} />;
  };

  // Function to fetch subtasks for a task
  const fetchSubtasks = async (taskId: number) => {
    try {
      const response = await axios.get(`/tasks/${taskId}/subtasks`);
      return response.data || [];
    } catch (error) {
      console.error('Error fetching subtasks:', error);
      toast.error('Failed to load subtasks');
      return [];
    }
  };

  // Function to handle subtask creation
  const handleCreateSubtask = async (taskId: number) => {
    const task = localTasks.find(t => t.id === taskId);
    if (!task) return;

    setSelectedTaskForSubtask(task);
    setIsCreateSubTaskModalOpen(true);
  };

  // Function to handle subtask submission
  const handleSubtaskSubmit = async (values: {
    title: string;
    description?: string;
    work_date?: string;
    due_date?: string;
    assigned_users?: string[];
  }) => {
    if (!selectedTaskForSubtask) return;

    try {
      const response = await axios.post(`/custom-board/tasks/${selectedTaskForSubtask.id}/subtasks`, values);
      
      if (response.status === 200) {
        // Update the tasks state with the new subtask
        setTasks(prevTasks => prevTasks.map(task => {
          if (task.id === selectedTaskForSubtask.id) {
            return {
              ...task,
              subtasks: [...(task.subtasks || []), response.data]
            };
          }
          return task;
        }));

        toast.success('Subtask created successfully');
        setIsCreateSubTaskModalOpen(false);
        setSelectedTaskForSubtask(null);
      }
    } catch (error) {
      console.error('Error creating subtask:', error);
      toast.error('Failed to create subtask');
    }
  };

  // Function to toggle subtask visibility
  const toggleSubtasks = async (taskId: number) => {
    const newShowSubtasks = { ...showSubtasks };
    newShowSubtasks[taskId] = !newShowSubtasks[taskId];
    setShowSubtasks(newShowSubtasks);

    // Fetch subtasks if they haven't been loaded yet
    if (newShowSubtasks[taskId]) {
      const task = localTasks.find(t => t.id === taskId);
      if (task && (!task.subtasks || task.subtasks.length === 0)) {
        const subtasks = await fetchSubtasks(taskId);
        const updatedTask = { ...task, subtasks };
        setLocalTasks(prevTasks =>
          prevTasks.map(t =>
            t.id === taskId ? updatedTask : t
          )
        );
      }
    }
  };

  // Function to handle subtask deletion
  const handleDeleteSubtask = async (taskId: number, subtaskId: number) => {
    try {
      await axios.delete(`/custom-board/subtasks/${subtaskId}`);
      toast.success('Subtask deleted successfully');
      
      // Update both local and external state
      const task = localTasks.find(t => t.id === taskId);
      if (task && task.subtasks) {
        const updatedSubtasks = task.subtasks.filter(st => st.id !== subtaskId);
        const updatedTask = { ...task, subtasks: updatedSubtasks };
        
        // Update both states consistently
        setLocalTasks(prevTasks =>
          prevTasks.map(t =>
            t.id === taskId ? updatedTask : t
          )
        );
        setTasks(prevTasks =>
          prevTasks.map(t =>
            t.id === taskId ? updatedTask : t
          )
        );
      }
    } catch (error) {
      console.error('Error deleting subtask:', error);
      toast.error('Failed to delete subtask');
    }
  };

  const handleDeleteTask = async () => {
    try {
      if (!taskToDelete) return;
      
      await axios.delete(`/custom-board/tasks/${taskToDelete.id}`);
      
      // Update both states immediately to remove the task and its subtasks
      const taskFilter = (t: UnifiedTask) => t.id !== taskToDelete.id;
      setTasks(prevTasks => prevTasks.filter(taskFilter));
      setLocalTasks(prevTasks => prevTasks.filter(taskFilter));
      
      toast.success('Task and all subtasks deleted successfully');
    } catch (error) {
      console.error('Error deleting task:', error);
      toast.error('Failed to delete task');
    } finally {
      setTaskToDelete(null);
    }
  };

  // Function to handle subtask status update
  const handleSubtaskStatusUpdate = async (taskId: number, subtaskId: number, newStatus: string) => {
    try {
      const response = await axios.put(`/custom-board/subtasks/${subtaskId}`, {
        progress_status: newStatus
      });
      
      // Update the local state with the full response data
      const task = localTasks.find(t => t.id === taskId);
      if (task && task.subtasks) {
        const updatedSubtasks = task.subtasks.map(st => 
          st.id === subtaskId ? { ...st, ...response.data } : st
        );
        const updatedTask = { ...task, subtasks: updatedSubtasks };
        setLocalTasks(prevTasks =>
          prevTasks.map(t =>
            t.id === taskId ? updatedTask : t
          )
        );
        setTasks(prevTasks =>
          prevTasks.map(t =>
            t.id === taskId ? updatedTask : t
          )
        );
      }
      
      toast.success('Subtask status updated');
      return response.data;
    } catch (error) {
      console.error('Error updating subtask status:', error);
      toast.error('Failed to update subtask status');
      throw error;
    }
  };

  // Update the helper function to handle null board
  const calculateSubtaskProgress = (task: UnifiedTask, board: Board | null | undefined): number | null => {
    if (!task.subtasks || !board?.subtask_config?.enable_subtask_progress) return null;

    const subtasks = task.subtasks;
    if (subtasks.length === 0) return 0;

    const completedSubtasks = subtasks.filter(subtask => {
      // Find the predefined subtask that matches this subtask
      const predefinedSubtask = board.subtask_config?.predefined_subtasks?.find(
        ps => ps.name === subtask.title
      );

      if (!predefinedSubtask) return false;

      // Check if the current status is marked as a completed state
      const currentStatusOption = predefinedSubtask.status_options?.find(
        so => so.id.toString() === subtask.progress_status
      );

      return currentStatusOption?.is_completed_state || false;
    });

    return (completedSubtasks.length / subtasks.length) * 100;
  };

  const handleSubtaskClick = (taskId: number, subtaskId: number) => {
    const parentTask = localTasks.find(t => t.id === taskId);
    if (!parentTask) return;
    
    const subtask = parentTask.subtasks?.find(s => s.id === subtaskId);
    if (!subtask) return;

    const taskViewData: UnifiedTask = {
      id: parentTask.id,
      key: parentTask.key,
      task_type: parentTask.task_type,
      title: parentTask.title || '',
      description: parentTask.description || '',
      board_status: parentTask.board_status || '',
      progress_status: parentTask.progress_status || '',
      progress_status_id: parentTask.progress_status_id || 0,
      board_status_id: parentTask.board_status_id || 0,
      deal_id: parentTask.deal_id,
      work_date: parentTask.work_date,
      due_date: parentTask.due_date,
      assigned_users: parentTask.assigned_users || [],
      activity_log: parentTask.activity_log || [],
      comments: parentTask.comments || [],
      data: parentTask.data || {},
      board_config: board?.board_config,
      board_id: board?.id
    };
    
    setTaskViewTask(taskViewData);
    setIsTaskViewOpen(true);
  };

  // Pass handleSubtaskClick to ExpandedSubtasks
  const renderTaskContent = (task: UnifiedTask) => {
    const displayTitle = taskType === 'custom' ? task.title : (task.data?.Task || task.description || '');
    const hasSubtasks = task.subtasks && task.subtasks.length > 0;
    const isSubtasksEnabled = board?.board_config?.enable_subtasks;
    const isSimplifiedMainTasks = board?.board_config?.simplify_main_tasks;
    const isExpanded = showSubtasks[task.id] || false;
    
    // Get the secondary status based on kanban source
    const getSecondaryStatus = () => {
      if (taskType !== 'custom' || !board?.board_config) return null;
      
      const { kanban_source, progress_status_options, board_status_options } = board.board_config;
      const isProgressKanban = isKanbanReversed 
        ? kanban_source !== 'progress'
        : kanban_source === 'progress';
      
      // Get the status ID and options based on which view we're in
      const statusId = isProgressKanban ? task.board_status_id : task.progress_status_id;
      const statusOptions = isProgressKanban ? board_status_options : progress_status_options;
      
      // If we don't have options or status ID, return null
      if (!statusOptions || !statusId) return null;
      
      // Find the status config by ID
      const statusConfig = statusOptions.find(opt => opt.id === statusId);
      if (!statusConfig) return null;
      
      return {
        label: statusConfig.label,
        color: statusConfig.color,
        value: statusConfig.value || statusConfig.label.toLowerCase().replace(/\s+/g, '_'),
        options: statusOptions
      };
    };

    const secondaryStatus = getSecondaryStatus();
    const subtaskProgress = calculateSubtaskProgress(task, board);

    // Function to handle status change
    const handleStatusChange = async (newStatusId: number) => {
      try {
        const response = await axios.put(`/custom-board/tasks/${task.id}`, {
          ...(isKanbanReversed
            ? board?.board_config?.kanban_source === 'progress'
              ? { board_status_id: newStatusId }
              : { progress_status_id: newStatusId }
            : board?.board_config?.kanban_source === 'progress'
              ? { board_status_id: newStatusId }
              : { progress_status_id: newStatusId }
          )
        });

        if (response.status === 200) {
          // Update local state
          const updatedTask = {
            ...task,
            ...(isKanbanReversed
              ? board?.board_config?.kanban_source === 'progress'
                ? { board_status_id: newStatusId }
                : { progress_status_id: newStatusId }
              : board?.board_config?.kanban_source === 'progress'
                ? { board_status_id: newStatusId }
                : { progress_status_id: newStatusId }
            )
          };

          setLocalTasks(prevTasks =>
            prevTasks.map(t => t.id === task.id ? updatedTask : t)
          );
          setTasks(prevTasks =>
            prevTasks.map(t => t.id === task.id ? updatedTask : t)
          );

          toast.success('Status updated successfully');
        }
      } catch (error) {
        console.error('Error updating status:', error);
        toast.error('Failed to update status');
      }
    };

    return (
      <div className="space-y-2">
        <Card className={cn(
          "w-full p-4 hover:shadow-md transition-shadow relative group cursor-grab active:cursor-grabbing",
          isExpanded && "mb-0"  // Change mb-4 to mb-0 since we're using space-y-2
        )}>
          <div className="flex flex-col items-center text-center relative">
            {task.deal_name && (
              <ForwardedBadge 
                variant="secondary"
                className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-deal))] text-white hover:bg-[hsl(var(--badge-deal)/.8)]"
                onClick={(e) => {
                  e.stopPropagation();
                  task.deal_id && handleDealClick(e, task.deal_id);
                }}
              >
                <Briefcase className="w-3 h-3 flex-shrink-0 mr-1" />
                <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {task.deal_name}
                </span>
              </ForwardedBadge>
            )}
            <Button
              variant="ghost"
              size="icon"
              className="absolute right-0 top-0 opacity-0 group-hover:opacity-100 group-hover:bg-secondary transition-all hover:text-primary"
              onClick={(e) => {
                e.stopPropagation();
                handleEditTask(task);
              }}
            >
              <Edit className="h-4 w-4" />
            </Button>

            {/* Status Badge - Always show for custom boards */}
            {taskType === 'custom' && board?.board_config && (
              <div className="mt-2">
                {secondaryStatus && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <ForwardedBadge 
                        variant="outline"
                        className="cursor-pointer"
                        style={{ 
                          borderColor: secondaryStatus.color,
                          color: secondaryStatus.color
                        }}
                      >
                        {secondaryStatus.label}
                      </ForwardedBadge>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="center">
                      {secondaryStatus.options.map((option) => (
                        <DropdownMenuItem
                          key={option.id}
                          onClick={() => handleStatusChange(option.id)}
                          className="flex items-center gap-2"
                        >
                          <div
                            className="w-2 h-2 rounded-full"
                            style={{ backgroundColor: option.color }}
                          />
                          {option.label}
                          {option.id === (isKanbanReversed
                            ? board.board_config.kanban_source === 'progress'
                              ? task.board_status_id
                              : task.progress_status_id
                            : board.board_config.kanban_source === 'progress'
                              ? task.board_status_id
                              : task.progress_status_id) && (
                            <Check className="w-4 w-4 ml-auto" />
                          )}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </div>
            )}

            {/* Only show title and frequency if not simplified */}
            {!isSimplifiedMainTasks && (
              <>
                <p className="text-sm text-primary font-medium mt-2 line-clamp-2">
                  {displayTitle}
                </p>
                {cardConfig?.showFrequency && task.frequency && (
                  <ForwardedBadge variant="secondary" className="mt-2">
                    {mapFrequencyToLabel(task.frequency)}
                  </ForwardedBadge>
                )}
              </>
            )}
          </div>

          {/* Progress bar for subtasks */}
          {subtaskProgress !== null && (
            <div className="w-full mt-4">
              <div className="h-1.5 w-full bg-muted rounded-full overflow-hidden">
                <div
                  className="h-full bg-primary transition-all duration-300 ease-in-out"
                  style={{ width: `${subtaskProgress}%` }}
                />
              </div>
              <p className="text-xs text-muted-foreground text-center mt-1">
                {Math.round(subtaskProgress)}% complete
              </p>
            </div>
          )}

          {/* Dates Section - Only show if not simplified */}
          {!isSimplifiedMainTasks && (
            <div className={cn(
              "grid gap-2 mt-3",
              ((task.work_date || task.next_work_date) && (task.due_date || task.next_due_date)) ? "grid-cols-2" : "grid-cols-1 justify-items-center"
            )}>
              {(task.work_date || task.next_work_date) && (
                <TaskDateEditor
                  date={task.work_date || task.next_work_date || null}
                  dateType="work"
                  taskId={task.id}
                  taskType={taskType as FrontendTaskType}
                  onSuccess={(updatedTask) => {
                    // Update the task in local state
                    const updatedTaskData = {
                      ...task,
                      work_date: updatedTask.work_date,
                      next_work_date: updatedTask.next_work_date
                    };
                    setLocalTasks(prevTasks =>
                      prevTasks.map(t =>
                        t.id === task.id ? updatedTaskData : t
                      )
                    );
                  }}
                  variant="compact"
                  className="w-full max-w-[200px]"
                  icon={<Clock className="h-4 w-4 text-muted-foreground" />}
                />
              )}
              {(task.due_date || task.next_due_date) && (
                <TaskDateEditor
                  date={task.due_date || task.next_due_date || null}
                  dateType="due"
                  taskId={task.id}
                  taskType={taskType as FrontendTaskType}
                  onSuccess={(updatedTask) => {
                    // Update the task in local state
                    const updatedTaskData = {
                      ...task,
                      due_date: updatedTask.due_date,
                      next_due_date: updatedTask.next_due_date
                    };
                    setLocalTasks(prevTasks =>
                      prevTasks.map(t =>
                        t.id === task.id ? updatedTaskData : t
                      )
                    );
                  }}
                  variant="compact"
                  className="w-full max-w-[200px]"
                  icon={<Calendar className="h-4 w-4 text-muted-foreground" />}
                />
              )}
            </div>
          )}

          {/* Tags Section - Only show if not simplified */}
          {!isSimplifiedMainTasks && (
            <div className="flex justify-center mt-2">
              {getTaskTags(task, dealDataConfig, dealSoftwareConfig)}
            </div>
          )}

          {/* Footer Section */}
          <div className="flex justify-between items-center">
            {/* Left side: Assignees - Only show if not simplified */}
            <div className="flex-1">
              {!isSimplifiedMainTasks && cardConfig?.showAssignee && getAssignedUsers(task).length > 0 && (
                <div 
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedTaskForAssignee(task);
                    setIsAssigneeModalOpen(true);
                  }}
                >
                  <AvatarGroup
                    users={getAssignedUsers(task)}
                    max={3}
                    className="hover:opacity-80 transition-opacity"
                  />
                </div>
              )}
            </div>
            
            {/* Center: Eye icon */}
            <div className="flex-1 flex justify-center gap-2">
              <Button
                variant="ghost"
                size="icon"
                className="opacity-0 group-hover:opacity-100 transition-opacity"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenTask(task.id);
                }}
              >
                <Eye className="h-4 w-4" />
              </Button>
            </div>
            
            {/* Right side: Message icon and Subtasks */}
            <div className="flex-1 flex justify-end items-center gap-2">
              {!isSimplifiedMainTasks && (
                <Button
                  variant="ghost"
                  size="icon"
                  className="relative"
                  onClick={(e) => handleMessageIconClick(task, e)}
                >
                  <MessageSquare className="h-4 w-4" />
                  {getUpdatesCount(task) > 0 && (
                    <ForwardedBadge 
                      variant="compact"
                      className="absolute -top-2 -right-2 h-4 w-4 flex items-center justify-center"
                    >
                      {getUpdatesCount(task)}
                    </ForwardedBadge>
                  )}
                </Button>
              )}

              {/* Only show subtasks for custom boards with subtasks enabled */}
              {taskType === 'custom' && board?.board_config?.enable_subtasks && (
                <Button
                  variant="ghost"
                  size="icon"
                  className="relative"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSubtasks(task.id);
                  }}
                >
                  <ListTodo className="h-4 w-4" />
                  {hasSubtasks && (
                    <ForwardedBadge 
                      variant="compact"
                      className="absolute -top-2 -right-2 h-4 w-4 flex items-center justify-center"
                    >
                      {task.subtasks?.length || 0}
                    </ForwardedBadge>
                  )}
                </Button>
              )}
            </div>
          </div>

          {/* Expanded Subtasks Section */}
          {isExpanded && taskType === 'custom' && board?.board_config?.enable_subtasks && (
            <ExpandedSubtasks
              task={task}
              board={board}
              onDeleteSubtask={handleDeleteSubtask}
              onStatusUpdate={handleSubtaskStatusUpdate}
              getAssignedUsers={getAssignedUsers}
              onCreateSubtask={handleCreateSubtask}
              onTaskUpdate={handleTaskUpdated}
              onSubtaskClick={handleSubtaskClick}
            />
          )}
        </Card>
      </div>
    );
  };

  const handleCompleteDrawerClose = () => {
    if (completedTask?.rollback) {
      completedTask.rollback();
    }
    if (originalTaskState) {
      setLocalTasks(prevTasks =>
        prevTasks.map(t =>
          t.id === originalTaskState.id ? originalTaskState : t
        )
      );
      setTasks(prevTasks =>
        prevTasks.map(t =>
          t.id === originalTaskState.id ? originalTaskState : t
        )
      );
    }
    setIsCompleteDrawerOpen(false);
    setCompletedTask(null);
    setOriginalTaskState(null);
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveId(active.id);
    const draggedTask = localTasks.find(task => task.id.toString() === active.id);
    setActiveTask(draggedTask || null);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    setActiveId(null);
    setActiveTask(null);
    
    if (!over) return;

    // Extract the status ID from the over.id (remove the 'status-' prefix)
    const targetStatusId = Number(over.id.toString().replace('status-', ''));
    const targetStatus = kanbanColumns.find(col => col.id === targetStatusId);
    
    // Only proceed if we're dropping over a column
    if (!targetStatus) {
      console.log('Invalid drop target - not a column');
      return;
    }

    const taskId = active.id;
    const task = localTasks.find(t => t.id.toString() === taskId.toString());
    
    if (!task) {
      console.error('Task not found:', taskId);
      return;
    }

    try {
      // For custom boards, handle status ID mapping
      if (task.is_custom && board?.board_config) {
        const { kanban_source } = board.board_config;
        const isProgressKanban = isKanbanReversed 
          ? kanban_source !== 'progress'
          : kanban_source === 'progress';

        // Store original task state for rollback
        const originalTask = { ...task };

        // Create updated task with new status
        const updatedTask = {
          ...task,
          ...(isProgressKanban 
            ? { 
                progress_status_id: targetStatus.id,
                progress_status: targetStatus.label
              }
            : { 
                board_status_id: targetStatus.id,
                board_status: targetStatus.label
              }
          )
        };

        // Update both local states immediately for optimistic update
        setLocalTasks(prevTasks => 
          prevTasks.map(t => 
            t.id.toString() === taskId.toString() ? updatedTask : t
          )
        );
        setTasks(prevTasks => 
          prevTasks.map(t => 
            t.id.toString() === taskId.toString() ? updatedTask : t
          )
        );

        // Update the appropriate status field
        const updateData = isProgressKanban
          ? { progress_status_id: targetStatus.id }
          : { board_status_id: targetStatus.id };

        // Make the API call
        await onTaskUpdate(taskId.toString(), updateData);
      } else {
        // Handle non-custom board tasks
        // Store original task state for rollback
        const originalTask = { ...task };

        // Create updated task with new status
        const updatedTask = { 
          ...task, 
          status: targetStatus.value,
          board_status: targetStatus.value // Ensure board_status is updated for visual consistency
        };

        // Update both local states immediately for optimistic update
        setLocalTasks(prevTasks => 
          prevTasks.map(t => 
            t.id.toString() === taskId.toString() ? updatedTask : t
          )
        );
        setTasks(prevTasks => 
          prevTasks.map(t => 
            t.id.toString() === taskId.toString() ? updatedTask : t
          )
        );

        // Make the API call
        await onTaskUpdate(taskId.toString(), { status: targetStatus.value });
      }
    } catch (error) {
      console.error('Error updating task status:', error);
      toast.error('Failed to update task status');

      // On error, revert both states to original
      setLocalTasks(prevTasks => 
        prevTasks.map(t => 
          t.id.toString() === taskId.toString() ? task : t
        )
      );
      setTasks(prevTasks => 
        prevTasks.map(t => 
          t.id.toString() === taskId.toString() ? task : t
        )
      );
    }
  };

  const getBackendTaskType = (frontendTaskType: FrontendTaskType): BackendTaskType => {
    return mapToBackendTaskType(frontendTaskType);
  };

  const handleTaskUpdated = (updatedTask: Task | null) => {
    if (!updatedTask) {
      // Task was deleted
      setTasks(prevTasks => prevTasks.filter(t => t.id !== taskToDelete?.id));
      setTaskToDelete(null);
      return;
    }

    setTasks(prevTasks =>
      prevTasks.map(task =>
        task.id === updatedTask.id
          ? {
              ...task,
              ...updatedTask,
              task_type: task.task_type,
              is_custom: task.is_custom,
              board_config: task.board_config
            }
          : task
      )
    );
  };

  const handleEditTask = (task: UnifiedTask) => {
    if (taskType === 'custom') {
      const customTask: CustomTask = {
        key: task.id.toString(),
        id: task.id,
        task_type: 'custom' as const,
        title: task.title || '',
        description: task.description || '',
        board_status: task.board_status || '',
        progress_status: task.progress_status || '',
        progress_status_id: task.progress_status_id,
        board_status_id: task.board_status_id,
        deal_id: task.deal_id,
        work_date: task.work_date,
        due_date: task.due_date,
        assigned_users: task.assigned_users || [],
        activity_log: task.activity_log || [],
        data: task.data || {}
      };
      
      setSelectedTaskForEdit(customTask);
      setIsEditModalOpen(true);
    } else {
      setSelectedTaskForNormalEdit(task);
      setIsEditTaskModalOpen(true);
    }
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={collisionDetectionStrategy}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <ScrollArea className="h-full w-full">
          <div className={kanbanStyles.board}>
            {kanbanColumns.map((status) => (
              <DroppableColumn
                key={status.id}
                id={status.id.toString()}
                className={kanbanStyles.columnContent}
                tasks={getTasksForStatus(status)}
                loading={loading}
                status={status}
                taskType={taskType}
              >
                {getTasksForStatus(status).map((task) => (
                  <SortableTaskCard
                    key={`task-${task.id}`}
                    id={task.id.toString()}
                    task={task}
                    renderContent={renderTaskContent}
                  />
                ))}
              </DroppableColumn>
            ))}
          </div>
        </ScrollArea>

        <DragOverlay>
          {activeTask ? (
            <div style={{ width: KANBAN_COLUMN_WIDTH }}>
              {renderTaskContent(activeTask)}
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>
      {selectedTask && (
        taskType === 'custom' ? (
          <CustomTaskNotes
            visible={isNotesModalOpen}
            onClose={async (refresh?: boolean) => {
              setIsNotesModalOpen(false);
              if (refresh && selectedTask) {
                try {
                  const response = await axios.get<UnifiedTask>(`/custom-board/tasks/${selectedTask.id}`);
                  const updatedTask = {
                    ...selectedTask,
                    ...response.data
                  };
                  setSelectedTask(updatedTask);
                  setLocalTasks(prevTasks => 
                    prevTasks.map(t => t.id === updatedTask.id ? updatedTask : t)
                  );
                  setTasks(prevTasks => 
                    prevTasks.map(t => t.id === updatedTask.id ? updatedTask : t)
                  );
                } catch (error) {
                  console.error('Error refreshing task:', error);
                  toast.error('Failed to refresh task data');
                }
              }
              setSelectedTask(null);
            }}
            task={{
              key: selectedTask.id.toString(),
              id: selectedTask.id,
              task_type: selectedTask.task_type,
              title: selectedTask.title || 'Untitled Task',
              description: selectedTask.description || '',
              board_status: selectedTask.board_status || '',
              progress_status: selectedTask.progress_status || '',
              progress_status_id: selectedTask.progress_status_id || 0,
              board_status_id: selectedTask.board_status_id || 0,
              deal_id: selectedTask.deal_id,
              work_date: selectedTask.work_date,
              due_date: selectedTask.due_date,
              assigned_users: selectedTask.assigned_users || [],
              activity_log: selectedTask.activity_log || [],
              comments: selectedTask.comments || [],
              data: selectedTask.data || {},
              board_id: board?.id,
              board_config: board?.board_config
            } as CustomTask}
            users={users}
          />
        ) : (
          <TaskNotes
            visible={isNotesModalOpen}
            onClose={() => {
              setIsNotesModalOpen(false);
              setSelectedTask(null);
            }}
            task={selectedTask}
            users={users}
            taskType={taskType}
          />
        )
      )}
      <TaskCompleteDrawer 
        visible={isCompleteDrawerOpen}
        onClose={handleCompleteDrawerClose}
        task={completedTask}
        onComplete={(values) => {
          console.log('Task completed with values:', values);
          setIsCompleteDrawerOpen(false);
          setCompletedTask(null);
        }}
      />
      <AssigneeSelection
        visible={isAssigneeModalOpen}
        onClose={() => {
          setIsAssigneeModalOpen(false);
          setSelectedTaskForAssignee(null);
        }}
        onSave={(newAssignees) => {
          if (selectedTaskForAssignee) {
            onAssigneeUpdate(selectedTaskForAssignee.id, newAssignees);
          }
          setIsAssigneeModalOpen(false);
          setSelectedTaskForAssignee(null);
        }}
        initialAssignees={selectedTaskForAssignee?.assigned_users?.map(id => id.toString()) || []}
        maxAssignees={10}
      />
      <DealDrawer
        visible={isDealDrawerOpen}
        onClose={() => {
          setIsDealDrawerOpen(false);
          setSelectedDealId(null);
        }}
        dealId={selectedDealId}
      />
      {isTaskViewOpen && taskViewTask && (
        <>
          <TaskView
            onClose={() => {
              setIsTaskViewOpen(false);
              setTaskViewTask(null);
            }}
            tasks={tasks}
            taskType={taskType}
            boardStatusOptions={boardStatusOptions}
            task={taskViewTask as UnifiedTask}
            fromWork={false}
            getBoardName={(task) => task.task_type}
            dealName={taskViewTask.deal_name || ''}
            board={board}
            users={users}
          />
        </>
      )}
      {isEditModalOpen && selectedTaskForEdit && board && (
        <EditCustomTaskModal
          visible={isEditModalOpen}
          onClose={(wasDeleted?: boolean) => {
            setIsEditModalOpen(false);
            setSelectedTaskForEdit(null);
            if (wasDeleted) {
              // Remove the task from both local states
              setLocalTasks(prevTasks => prevTasks.filter(t => t.id !== selectedTaskForEdit.id));
              setTasks(prevTasks => prevTasks.filter(t => t.id !== selectedTaskForEdit.id));
            }
          }}
          task={selectedTaskForEdit}
          board={board}
          users={users}
          onSave={handleTaskUpdated}
        />
      )}
      {isEditTaskModalOpen && selectedTaskForNormalEdit && (
        <EditTaskModal
          visible={isEditTaskModalOpen}
          onClose={(wasDeleted?: boolean) => {
            setIsEditTaskModalOpen(false);
            setSelectedTaskForNormalEdit(null);
            if (wasDeleted) {
              // Remove the task from the local state
              setLocalTasks(prevTasks => prevTasks.filter(t => t.id !== selectedTaskForNormalEdit.id));
              setTasks(prevTasks => prevTasks.filter(t => t.id !== selectedTaskForNormalEdit.id));
            }
          }}
          task={selectedTaskForNormalEdit}
          taskType={taskType}
        />
      )}
      {isCreateSubTaskModalOpen && selectedTaskForSubtask && board && (
        <CreateSubTask
          open={isCreateSubTaskModalOpen}
          onClose={() => {
            setIsCreateSubTaskModalOpen(false);
            setSelectedTaskForSubtask(null);
          }}
          onSubmit={handleSubtaskSubmit as (values: Partial<Task>) => void}
          parentTask={selectedTaskForSubtask}
          board={board}
        />
      )}
      {/* Delete Task Alert Dialog */}
      <AlertDialog open={!!taskToDelete} onOpenChange={(open) => !open && setTaskToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Task</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this task? This will also delete all subtasks.
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              className={buttonVariants({ variant: "destructive" })}
              onClick={handleDeleteTask}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

