import React, { useState, useEffect, useMemo } from 'react';
import { Button } from "@/components/ui/button";
import { Mail, Reply } from "lucide-react";
import axios from '../../api/axiosConfig';
import SendEmailModal from '@/modals/SendEmailModal';
import { LoadingSpinner } from '@/components/ui/loading-spinner';
import UserAvatar from '@/components/user-avatar';
import { cn } from "@/lib/utils";
import type { Contact } from '@/types/customers';

interface Email {
  id: string | number;
  sender_name: string;
  sender_email: string;
  recipients: string[];
  subject: string;
  body: string;
  sent_at: string;
  is_incoming: boolean;
}

interface User {
  full_name: string;
  [key: string]: any;
}

interface TaskViewCommunicationProps {
  dealId?: string | number | null;
  contacts?: Contact[] | null;
}

interface EmailItemProps {
  email: Email;
  onReply: (email: Email) => void;
  users: Record<string, User>;
}

const TaskViewCommunication: React.FC<TaskViewCommunicationProps> = ({ dealId, contacts }) => {
  const [emails, setEmails] = useState<Email[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [replyToEmail, setReplyToEmail] = useState<Email | null>(null);
  const [users, setUsers] = useState<Record<string, User>>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [emailsResponse, usersResponse] = await Promise.all([
          axios.get(`/contactmail/emails/${dealId}`),
          axios.get('/user/names')
        ]);
        setEmails(emailsResponse.data);
        setUsers(usersResponse.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (dealId) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [dealId]);

  const handleOpenModal = () => {
    setModalKey(prevKey => prevKey + 1);
    setReplyToEmail(null);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setReplyToEmail(null);
  };

  const handleReply = (email: Email) => {
    setReplyToEmail(email);
    setIsModalVisible(true);
  };

  if (!dealId) {
    return (
      <div className="flex justify-center items-center min-h-[200px] text-muted-foreground">
        <p className="text-sm">No deal associated with this task</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-sm text-destructive text-center p-4">
        {error}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-center">
        <Button
          onClick={handleOpenModal}
          className="w-32"
          disabled={!contacts?.length}
        >
          <Mail className="mr-2 h-4 w-4" />
          Send Email
        </Button>
      </div>
      
      {emails.length === 0 ? (
        <div className="flex flex-col items-center justify-center min-h-[200px] text-muted-foreground">
          <p className="text-sm">No email communication with this customer has been synced</p>
        </div>
      ) : (
        <div className="space-y-5">
          {emails.map((email) => (
            <EmailItem key={email.id} email={email} onReply={handleReply} users={users} />
          ))}
        </div>
      )}
      
      {contacts?.length && isModalVisible && (
        <SendEmailModal 
          key={modalKey}
          visible={isModalVisible} 
          onClose={handleCloseModal} 
          contacts={contacts}
          dealId={dealId || 0}
          replyToEmail={replyToEmail}
        />
      )}
    </div>
  );
};

const EmailItem: React.FC<EmailItemProps> = ({ email, onReply, users }) => {
  const [expanded, setExpanded] = useState(false);
  const [needsExpansion, setNeedsExpansion] = useState(false);

  const senderName = useMemo(() => {
    if (email.is_incoming) {
      return email.sender_name || 'Unknown';
    } else {
      const userInfo = users[email.sender_name];
      return userInfo ? userInfo.full_name : 'Unknown';
    }
  }, [email, users]);

  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  useEffect(() => {
    const checkOverflow = () => {
      const maxHeight = 100;
      const tempDiv = document.createElement('div');
      tempDiv.style.visibility = 'hidden';
      tempDiv.style.position = 'absolute';
      tempDiv.style.width = '300px';
      tempDiv.innerHTML = email.body;
      document.body.appendChild(tempDiv);
      const isOverflowing = tempDiv.scrollHeight > maxHeight;
      setNeedsExpansion(isOverflowing);
      document.body.removeChild(tempDiv);
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [email.body]);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={cn(
      "w-4/5 mx-auto p-5 rounded-lg bg-card",
      "border border-border shadow-sm"
    )}>
      <div className="flex items-center gap-4 mb-3">
        <UserAvatar 
          user={{ 
            user_id: email.is_incoming ? email.sender_email : email.sender_name,
            name: senderName.split(' ')[0],
            surname: senderName.split(' ').slice(1).join(' ')
          }}
          size={32}
        />
        <div className="flex-1">
          <div className="font-medium">{senderName}</div>
          <div className="text-sm text-muted-foreground">{email.sender_email}</div>
          <div className="text-sm italic text-muted-foreground">
            To: {email.recipients.join(', ')}
          </div>
        </div>
        <div className="text-sm text-muted-foreground">
          {new Date(email.sent_at).toLocaleString()}
        </div>
      </div>

      <div className="font-medium mb-2">{email.subject}</div>

      <div className="relative">
        <div className={cn(
          "text-sm whitespace-pre-wrap",
          !expanded && "max-h-[100px] overflow-hidden"
        )}>
          {email.body}
        </div>
        {needsExpansion && !expanded && (
          <div className="absolute bottom-0 left-0 right-0 h-10 bg-gradient-to-t from-card to-transparent" />
        )}
      </div>

      <div className="flex justify-center gap-2 mt-4">
        {needsExpansion && (
          <Button
            variant="outline"
            size="sm"
            onClick={toggleExpansion}
          >
            {expanded ? 'Show less' : 'Read more'}
          </Button>
        )}
        {email.is_incoming && (
          <Button
            variant="outline"
            size="sm"
            onClick={() => onReply(email)}
          >
            <Reply className="mr-2 h-4 w-4" />
            Reply
          </Button>
        )}
      </div>
    </div>
  );
};

export default TaskViewCommunication; 