import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { Loading } from '@/components/ui/loading';
import { ArrowLeft, Plus, Building2, Calendar, ArrowRight } from 'lucide-react';
import { format } from 'date-fns';

interface Deal {
  id: number;
  name: string;
  company: string;
  created_at: string;
  onboard_status: 'not_onboarded' | 'onboarding' | 'completed' | 'paused';
  value: number;
  onboarding?: {
    id: number;
    current_node_id: string;
    completed_nodes: string[];
    form_data: any;
    status: string;
    assigned_user: {
      id: number;
      name: string;
      email: string;
    } | null;
  };
}

export default function OnboardingNew() {
  const navigate = useNavigate();
  const [deals, setDeals] = useState<Deal[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDeals();
  }, []);

  const fetchDeals = async () => {
    try {
      const [newDealsRes, onboardingDealsRes] = await Promise.all([
        axios.get('/onboarding/new-deals'),
        axios.get('/onboarding/current')
      ]);
      
      const allDeals = [...newDealsRes.data, ...onboardingDealsRes.data];
      setDeals(allDeals);
    } catch (error) {
      toast.error('Failed to fetch deals');
      console.error('Error fetching deals:', error);
    } finally {
      setLoading(false);
    }
  };

  const startOnboarding = async (dealId: number) => {
    try {
      const response = await axios.post(`/onboardingflow/start/${dealId}`, {}, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const { onboardingId } = response.data;
      
      // Open in a new window with a specific size
      window.open(
        `/customers/onboarding/flow/${dealId}/${onboardingId}`,
        '_blank',
        'width=1200,height=800'
      );
      
      // Refresh the deals list
      fetchDeals();
    } catch (error) {
      toast.error('Failed to start onboarding');
      console.error('Error starting onboarding:', error);
    }
  };

  const continueOnboarding = (dealId: number, onboardingId: number) => {
    window.open(
      `/customers/onboarding/flow/${dealId}/${onboardingId}`,
      '_blank',
      'width=1200,height=800'
    );
  };

  if (loading) {
    return <Loading message="Loading deals..." />;
  }

  return (
    <div className="p-8">
      <div className="mb-8 space-y-4">
        <Button
          variant="ghost"
          onClick={() => navigate('/customers/onboarding')}
          className="mb-4"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Dashboard
        </Button>
        
        <div>
          <h1 className="text-3xl font-bold text-foreground">Onboardings</h1>
          <p className="text-muted-foreground">
            Start onboarding process for customers
          </p>
        </div>
      </div>

      <Card>
        <CardHeader>
          <CardTitle className="text-xl font-semibold">New Onboardings</CardTitle>
          <CardDescription>
            Deals that are ready to start onboarding. Click on a deal to begin its onboarding process.
          </CardDescription>
        </CardHeader>
        <CardContent>
          {deals.length === 0 ? (
            <div className="text-center text-muted-foreground">
              No new deals waiting to start onboarding.
            </div>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Customer</TableHead>
                  <TableHead>Value</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Assigned To</TableHead>
                  <TableHead>Created</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {deals.map((deal) => (
                  <TableRow key={deal.id}>
                    <TableCell className="font-medium">
                      <div className="space-y-1">
                        <div>{deal.name}</div>
                        <div className="flex items-center text-sm text-muted-foreground">
                          <Building2 className="h-3 w-3 mr-1" />
                          {deal.company}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      ${deal.value.toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <Badge 
                        variant={
                          deal.onboard_status === 'not_onboarded' ? 'secondary' :
                          deal.onboard_status === 'onboarding' ? 'default' :
                          deal.onboard_status === 'completed' ? 'success' :
                          deal.onboard_status === 'paused' ? 'warning' :
                          'secondary'
                        }
                      >
                        {deal.onboard_status === 'not_onboarded' ? 'Not Started' :
                         deal.onboard_status === 'onboarding' ? 'In Progress' :
                         deal.onboard_status === 'completed' ? 'Completed' :
                         deal.onboard_status === 'paused' ? 'Paused' :
                         'Unknown Status'}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      {deal.onboarding?.assigned_user?.name || 'Not Assigned'}
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center text-muted-foreground">
                        <Calendar className="h-4 w-4 mr-2" />
                        {format(new Date(deal.created_at), 'PPP')}
                      </div>
                    </TableCell>
                    <TableCell>
                      {deal.onboard_status === 'not_onboarded' ? (
                        <Button
                          onClick={() => startOnboarding(deal.id)}
                          className="space-x-2"
                        >
                          <Plus className="h-4 w-4" />
                          <span>Start Onboarding</span>
                        </Button>
                      ) : deal.onboard_status === 'onboarding' && deal.onboarding ? (
                        <Button
                          onClick={() => continueOnboarding(deal.id, deal.onboarding!.id)}
                          className="space-x-2"
                          variant="secondary"
                        >
                          <ArrowRight className="h-4 w-4" />
                          <span>Continue</span>
                        </Button>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
    </div>
  );
} 