import { useEffect, useState, useCallback, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import axios from '@/api/axiosConfig'
import {
  ArrowUp,
  ArrowDown,
  Mail,
  RefreshCw,
  FileText,
  User,
  Store,
  Clock,
  Calendar,
  Timer,
  Play,
  Users,
  ShoppingBag,
  BookOpen,
  DollarSign,
  Pause,
  Square,
  Edit,
  Building2,
  Calculator,
  UserPlus
} from 'lucide-react'

import { Dialog, DialogContent, DialogTitle, DialogDescription } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Badge } from '@/components/ui/badge'
import { Textarea } from '@/components/ui/textarea'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { ScrollArea } from '@/components/ui/scroll-area'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'

import TaskViewCommunication from '../components/TaskViewCommunication'
import TaskViewNotes from '../components/TaskViewNotes'
import DealNavBar from '../components/DealNavBar'
import ContactInfoDrawer from '../drawers/ContactInfo'
import { TaskDateEditor } from '@/components/TaskDateEditor'
import TaskViewActivity from '../components/TaskViewActivity'
import { DealDrawer } from '@/drawers/DealDrawer'
import ProductsDrawer from '@/drawers/ProductsDrawer'
import { TaskStatusSelector } from '../components/TaskStatusSelector'
import TaskCompleteDrawer from '../drawers/TaskCompleteDrawer'
import { TimeTrackerContext } from '@/contexts/TimeTrackerContext'
import PayrollDrawer from '@/drawers/PayrollDrawer'
import { dealSoftwareConfig } from '@/configs/DealSoftwareConfig'
import { Skeleton } from "@/components/ui/skeleton"
import TaskViewOverview from '../components/TaskViewOverview'
import { LoadingSpinner } from "@/components/ui/loading-spinner"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { UserAvatar } from '@/components/user-avatar'
import { AssigneeSelection } from "@/modals/AssigneeSelection"
import { TaskViewSubtasks } from '@/taskboards/components/TaskViewSubtasks'

import type { Task, FrontendTaskType, TaskToComplete, EnhancedTask, BoardConfigKey, UnifiedTask } from '@/types/task'
import type { BoardStatusOption, ProgressStatusOption, BoardConfig, SubTask } from '@/types/custom-board'
import type { StoredUser } from '@/types/user'
import { isRecurringTask } from '@/types/task'
import type { Board } from '@/types/custom-board'

dayjs.extend(utc)
dayjs.extend(timezone)

interface DealData {
  name?: string;
  cvr?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  economic_customer_number?: string;
  onboard_status?: string;
}

interface ContactData {
  id?: number;
  name?: string;
  email?: string;
  phone?: string;
}

interface ContactInfo {
  id: number;
  text: string;
  user_id: number;
  is_public: boolean;
}

const PRODUCT_ICONS: Record<string, JSX.Element> = {
  'Bogføring': <Calculator className="h-5 w-5" />,
  'Onboarding': <UserPlus className="h-5 w-5" />,
  'Lønadministration': <Users className="h-5 w-5" />,
  'Årsregnskab': <FileText className="h-5 w-5" />
};

interface TaskViewProps {
  onClose?: () => void
  tasks: UnifiedTask[]
  taskType: string
  boardStatusOptions: BoardStatusOption[]
  task?: UnifiedTask
  fromWork?: boolean
  getBoardName?: (task: UnifiedTask) => string
  dealName?: string
  board?: Board | null
  users?: StoredUser[]
}

interface CustomTask extends Omit<Task, 'work_date' | 'due_date'> {
  board?: {
    board_config: {
      enable_subtasks: boolean;
      simplify_main_tasks: boolean;
      enable_time_tracking: boolean;
      progress_status_options: ProgressStatusOption[];
      board_status_options: BoardStatusOption[];
    };
  };
  progress_status_id?: number;
  board_status_id?: number;
  work_date?: string | null;
  due_date?: string | null;
  expected_workload?: number;
}

const truncateText = (text: string, maxLength: number = 13) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

const fetchContactInfo = async (contactId: number, setContactInfo: (info: any) => void) => {
  try {
    const response = await axios.get(`/account/contacts/${contactId}/info`)
    setContactInfo(response.data.entries)
  } catch (error) {
    console.error('Error fetching contact info:', error)
  }
}

export function TaskView({
  onClose,
  tasks,
  taskType: propTaskType,
  boardStatusOptions,
  task: propTask,
  fromWork,
  getBoardName: propGetBoardName,
  dealName,
  board: propBoard,
  users: propUsers
}: TaskViewProps) {
  const { taskId } = useParams()
  const navigate = useNavigate()
  const [task, setTask] = useState<UnifiedTask | null>(propTask || null)
  const [loading, setLoading] = useState(!propTask)
  const [board, setBoard] = useState<Board | null>(propBoard || null)
  const [activeTab, setActiveTab] = useState(() => {
    // For custom tasks, start with overview tab
    if (propTask?.task_type === 'custom') {
      return 'overview';
    }
    // For non-custom tasks, start with communication tab
    return '1';
  })
  const [taskType, setTaskType] = useState(propTaskType)
  const [accountData, setAccountData] = useState(null)
  const [productsData, setProductsData] = useState([])
  const [contactInfoVisible, setContactInfoVisible] = useState(false)
  const [contactInfo, setContactInfo] = useState<ContactInfo[]>([])
  const [users, setUsers] = useState<StoredUser[]>(propUsers || [])
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false)
  const [accountModalVisible, setAccountModalVisible] = useState(false)
  const [productsDrawerVisible, setProductsDrawerVisible] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [isCompleteDrawerVisible, setIsCompleteDrawerVisible] = useState(false)
  const [taskToComplete, setTaskToComplete] = useState<TaskToComplete | null>(null)
  const [lonConfig, setLonConfig] = useState<{ salary_type?: string; salary_period_hourly?: { start?: number; end?: number; display_start?: string; display_end?: string } }>({})
  const [currentPeriod, setCurrentPeriod] = useState(dayjs().format('YYYY-MM'))
  const [payrollDrawerVisible, setPayrollDrawerVisible] = useState(false)
  const [dealData, setDealData] = useState<DealData>({})
  const [contactData, setContactData] = useState<ContactData>({})
  const [isLoadingDealData, setIsLoadingDealData] = useState(true)
  const isCustomTask = task?.task_type === 'custom';
  const [assigneeModalVisible, setAssigneeModalVisible] = useState(false);

  const { 
    isRunning, 
    isPaused, 
    currentTask,
    startTracking,
    stopTracking,
    pauseTracking,
    resumeTimer
  } = useContext(TimeTrackerContext)

  const isCurrentTask = currentTask?.taskId === task?.id

  useEffect(() => {
    if (propUsers) {
      setUsers(propUsers);
    } else {
      const fetchUsers = async () => {
        try {
          const response = await axios.get('/user')
          setUsers(response.data)
        } catch (error) {
          console.error('Error fetching users:', error)
        }
      }
      fetchUsers()
    }
  }, [propUsers])

  const getBoardName = useCallback((task: UnifiedTask) => {
    if (propGetBoardName) {
      return propGetBoardName(task)
    }
    if (isRecurringTask(task)) {
      const recurringTypeMap: Record<string, string> = {
        'bogforing': 'bogforing',
        'lon': 'lon',
        'moms': 'moms',
        'arsafslutning': 'arsafslutning'
      }
      return recurringTypeMap[task.recurring_type || ''] || task.recurring_type
    }
    return task.task_type
  }, [propGetBoardName])

  const fetchTaskDetails = useCallback(async () => {
    try {
      let apiTaskType = taskType;
      let endpoint;

      if (taskType === 'custom') {
        endpoint = `/custom-board/tasks/${taskId}`;
      } else {
        if (taskType === 'andre-opgaver') {
          apiTaskType = 'other';
        } else if (taskType === 'activities') {
          apiTaskType = 'activity';
        }
        endpoint = `/taskboard/tasks/${apiTaskType}/${taskId}`;
      }

      console.log('Fetching task details for:', { apiTaskType, taskId, endpoint });
      const response = await axios.get(endpoint);
      console.log('Task details response:', response.data);
      
      setTask(response.data);
      
      // Set the initial active tab based on task type
      if (response.data.task_type === 'custom') {
        setActiveTab('overview');
      } else {
        setActiveTab('1');
      }
      
      // Set deal data from the response
      if (response.data.deal) {
        console.log('Setting deal data:', response.data.deal);
        setDealData({
          name: response.data.deal.name,
          cvr: response.data.deal.cvr,
          address: response.data.deal.address,
          zipcode: response.data.deal.zipcode,
          city: response.data.deal.city,
          economic_customer_number: response.data.deal.economic_customer_number,
          onboard_status: response.data.deal.onboard_status
        });
      }
      
      // Set contact data from the first contact if available
      if (response.data.contacts && response.data.contacts.length > 0) {
        console.log('Setting contact data:', response.data.contacts[0]);
        setContactData(response.data.contacts[0]);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching task details:', error);
      setLoading(false);
    }
  }, [taskId, taskType]);

  useEffect(() => {
    if (propTask) {
      setTask({
        ...propTask,
        work_date: propTask.work_date || undefined,
        due_date: propTask.due_date || undefined
      });
      // Update active tab when task changes
      if (propTask.task_type === 'custom') {
        setActiveTab('overview');
      } else {
        setActiveTab('1');
      }
      setLoading(false);
    } else if (taskId) {
      const taskFromList = tasks?.find(t => t.id.toString() === taskId);
      if (taskFromList) {
        setTask({
          ...taskFromList,
          work_date: taskFromList.work_date || undefined,
          due_date: taskFromList.due_date || undefined
        });
        // Update active tab when task is found from list
        if (taskFromList.task_type === 'custom') {
          setActiveTab('overview');
        } else {
          setActiveTab('1');
        }
        setLoading(false);
      } else {
        fetchTaskDetails();
      }
    }
  }, [taskId, propTask, tasks, fetchTaskDetails]);

  const fetchDealData = async () => {
    if (task?.deal_id) {
      setIsLoadingDealData(true)
      try {
        const response = await axios.get(`account/deals/${task.deal_id}`)
        setDealData(response.data)
        if (response.data.contact) {
          setContactData(response.data.contact)
        }
      } catch (error) {
        console.error('Error fetching deal data:', error)
        toast.error('Failed to fetch deal data')
      } finally {
        setIsLoadingDealData(false)
      }
    }
  }

  useEffect(() => {
    if (task?.deal_id) {
      fetchDealData()
    }
  }, [task?.deal_id])

  useEffect(() => {
    if (task?.contact?.id) {
      fetchContactInfo(task.contact.id, setContactInfo)
    }
  }, [task])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    } else {
      navigate(-1)
    }
  }, [onClose, navigate])

  const handlePrevTask = () => {
    const currentIndex = tasks.findIndex(t => t.id === task?.id);
    if (currentIndex > 0) {
      const prevTask = tasks[currentIndex - 1];
      
      if (taskType === 'custom') {
        setTask(prevTask);
        const taskId = prevTask.id;
        fetchTaskDetails();
      } else {
        navigate(`/boards/${taskType}/${prevTask.id}`, {
          state: { 
            fromKanban: true,
            tasks,
            selectedTask: prevTask
          },
          replace: true
        });
      }
    }
  };

  const handleNextTask = () => {
    const currentIndex = tasks.findIndex(t => t.id === task?.id);
    if (currentIndex < tasks.length - 1) {
      const nextTask = tasks[currentIndex + 1];
      
      if (taskType === 'custom') {
        setTask(nextTask);
        const taskId = nextTask.id;
        fetchTaskDetails();
      } else {
        navigate(`/boards/${taskType}/${nextTask.id}`, {
          state: { 
            fromKanban: true,
            tasks,
            selectedTask: nextTask
          },
          replace: true
        });
      }
    }
  };

  const handleStatusChange = async (updatedFields: Partial<CustomTask>) => {
    if (!task) return;
    
    try {
      const response = await axios.put(`/custom-board/tasks/${task.id}`, {
        ...updatedFields,
        due_date: updatedFields.due_date || undefined // Convert null to undefined
      });
      setTask(prevTask => prevTask ? { ...prevTask, ...response.data } : response.data);
    } catch (error) {
      console.error('Error updating task:', error);
      toast.error('Failed to update task');
    }
  };

  const handleProgressStatusChange = (value: string) => {
    const customTask = task as CustomTask;
    const status = customTask.board?.board_config.progress_status_options.find(
      (s: ProgressStatusOption) => s.id === Number(value)
    );
    if (status) {
      handleStatusChange({
        progress_status_id: Number(value),
        progress_status: status.label
      });
    }
  };

  const handleBoardStatusChange = (value: string) => {
    const customTask = task as CustomTask;
    const status = customTask.board?.board_config.board_status_options?.find(
      (s: BoardStatusOption) => s.id === Number(value)
    );
    if (status) {
      handleStatusChange({
        board_status_id: Number(value),
        board_status: status.label
      });
    }
  };

  const handleCompleteDrawerClose = () => {
    if (taskToComplete?.rollback) {
      taskToComplete.rollback()
    }
    setIsCompleteDrawerVisible(false)
    setTaskToComplete(null)
  }

  const handleCompleteDrawerSubmit = async (values: any) => {
    try {
      const response = await axios.put(`/taskboard/tasks/${taskType}/${taskToComplete?.id}/status`, {
        board_status: taskToComplete?.board_status,
        board_status_options: boardStatusOptions,
        completion_data: values
      })

      if (response.status === 200) {
        setIsCompleteDrawerVisible(false)
        setTaskToComplete(null)
        toast.success('Task status updated successfully')
      }
    } catch (error) {
      console.error('Error completing task:', error)
      toast.error('Failed to complete task')
      if (taskToComplete?.rollback) {
        taskToComplete.rollback()
      }
    }
  }

  const renderTaskStatus = (status: string) => {
    return (
      <div className="w-full overflow-hidden rounded-md">
        <TaskStatusSelector
          value={status}
          taskId={task?.id ?? 0}
          taskType={taskType as FrontendTaskType}
          boardStatusOptions={boardStatusOptions}
          progressStatus={task?.status || task?.progress_status || ''}
          onSuccess={handleStatusChange}
          className="w-full"
        />
      </div>
    )
  }

  const formatDate = (dateString: string) => {
    return dayjs(dateString).format('YYYY-MM-DD')
  }

  const tabItems = [
    ...(isCustomTask ? [
      {
        value: "overview",
        label: (
          <div className="flex items-center gap-2">
            <FileText className="h-4 w-4" /> Overview
          </div>
        ),
        content: (
          <div className="flex flex-col h-full">
            <div className="flex-1">
              <TaskViewOverview 
                task={task}
                board={board}
                users={users}
                onDescriptionUpdate={async (newDescription) => {
                  try {
                    const response = await axios.put(`/custom-board/tasks/${task.id}`, {
                      description: newDescription
                    });
                    setTask(prev => prev ? { ...prev, description: newDescription } : null);
                  } catch (error) {
                    console.error('Error updating description:', error);
                    throw error;
                  }
                }}
              />
            </div>
            {task?.task_type === 'custom' && board?.board_config?.enable_subtasks && (
              <div className="mt-auto">
                <TaskViewSubtasks
                  task={task as UnifiedTask}
                  board={board}
                  getAssignedUsers={(task: UnifiedTask | SubTask) => 
                    task.assigned_users?.map((userId: string) => 
                      users.find(u => u.user_id.toString() === userId)
                    ).filter(Boolean) as StoredUser[] || []
                  }
                  onSubtaskCreated={fetchTaskDetails}
                />
              </div>
            )}
          </div>
        )
      }
    ] : []),
    ...(!isCustomTask || (isCustomTask && task?.deal_id) ? [
      {
        value: "1",
        label: (
          <div className="flex items-center gap-2">
            <Mail className="h-4 w-4" /> Communication
          </div>
        ),
        content: (
          <TaskViewCommunication 
            dealId={task?.deal_id} 
            contacts={task?.contacts || []} 
          />
        )
      }
    ] : []),
    {
      value: "2",
      label: (
        <div className="flex items-center gap-2">
          <RefreshCw className="h-4 w-4" /> Notes / Comments
        </div>
      ),
      content: (
        <TaskViewNotes 
          task={task}
          taskType={taskType}
          users={users}
          onUpdate={fetchTaskDetails}
        />
      )
    },
    {
      value: "3",
      label: (
        <div className="flex items-center gap-2">
          <FileText className="h-4 w-4" /> Activity Log
        </div>
      ),
      content: (
        <TaskViewActivity 
          updates={task?.updates || []} 
          users={users}
          taskId={task?.id}
          isCustomTask={task?.task_type === 'custom'}
          task={task}
        />
      )
    }
  ]

  const handleContactInfoClick = () => {
    setContactInfoVisible(true)
  }

  const handleContactInfoClose = () => {
    setContactInfoVisible(false)
    if (task?.contact?.id) {
      fetchContactInfo(task.contact.id, setContactInfo)
    }
  }

  const showAccountDetails = async (account: any) => {
    try {
      const response = await axios.get(`/account/accounts/${account.id}`)
      setSelectedAccount(response.data)
      setAccountModalVisible(true)
    } catch (error) {
      console.error('Error fetching account details:', error)
      toast.error('Failed to fetch account details')
    }
  }

  const getTaskTypeAndRecurringType = (task: UnifiedTask | null) => {
    if (!task) return { taskType: '', recurringType: null };
    
    if (isRecurringTask(task)) {
      return {
        taskType: 'recurring' as const,
        recurringType: propTaskType as BoardConfigKey
      };
    } else if (['andre-opgaver', 'activities'].includes(propTaskType)) {
      return {
        taskType: 'onboarding' as const,
        recurringType: null
      };
    } else {
      return {
        taskType: propTaskType as BoardConfigKey,
        recurringType: null
      };
    }
  };

  const handleStartTimer = () => {
    if (!task) return

    const { taskType, recurringType } = getTaskTypeAndRecurringType(task)
    
    const taskData = {
      taskId: task.id,
      taskType: taskType,
      dealId: task.deal_id,
      recurringType: recurringType,
      dealName: task.deal_name || task.deal?.name,
      taskDescription: task.description || task.data?.Task
    }
    
    startTracking(taskData)
  }

  const renderTimerButton = () => {
    if (!task) return null

    if (isCurrentTask && isRunning) {
      return (
        <div className="flex gap-2 w-full">
          <Button
            variant={isPaused ? "default" : "secondary"}
            className="flex-1 h-10"
            onClick={() => isPaused ? resumeTimer() : pauseTracking()}
          >
            {isPaused ? (
              <Play className="mr-2 h-4 w-4" />
            ) : (
              <Pause className="mr-2 h-4 w-4" />
            )}
            {isPaused ? "Resume Timer" : "Pause Timer"}
          </Button>
          <Button
            variant="destructive"
            className="flex-1 h-10"
            onClick={stopTracking}
          >
            <Square className="mr-2 h-4 w-4" />
            Stop Timer
          </Button>
        </div>
      )
    }

    return (
      <Button
        className="w-full h-10"
        onClick={handleStartTimer}
        disabled={isRunning && !isCurrentTask}
      >
        <Play className="mr-2 h-4 w-4" />
        Start Timer
      </Button>
    )
  }

  const fetchLonConfig = async () => {
    if (task?.deal_id && taskType === 'lon') {
      try {
        const response = await axios.get(`/lon/lon-config/${task.deal_id}`)
        setLonConfig(response.data)
      } catch (error: any) {
        if (error.response?.status !== 404) {
          console.error('Error fetching lon configuration:', error)
          toast.error('Failed to fetch løn configuration')
        }
      }
    }
  }

  useEffect(() => {
    fetchLonConfig()
  }, [task?.deal_id, taskType])

  const handlePayrollConfigSave = () => {
    if (task?.deal_id && taskType === 'lon') {
      fetchLonConfig()
    }
  }

  const shouldShowDealSections = () => {
    if (task?.task_type === 'custom') {
      return !!task.deal_id; // Only show if deal_id exists for custom tasks
    }
    return true; // Always show for normal taskboard tasks
  };

  const renderTaskCard = () => {
    if (!task) return null;

    if (task.task_type === 'custom') {
      const customTask = task as CustomTask;
      const boardConfig = customTask.board?.board_config;
      if (!boardConfig) return null;

      const isSimplified = boardConfig.simplify_main_tasks;

      return (
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <FileText className="h-5 w-5" />
              Task
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            {/* Status Selectors */}
            <div className="space-y-4">
              {/* Progress Status */}
              <div className="space-y-2">
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <Clock className="h-4 w-4" />
                  Progress Status
                </div>
                <div className="w-full overflow-hidden rounded-md">
                  <Select 
                    value={customTask.progress_status_id?.toString()}
                    onValueChange={handleProgressStatusChange}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue>
                        {boardConfig.progress_status_options.find(
                          (status: ProgressStatusOption) => status.id === customTask.progress_status_id
                        ) && (
                          <div className="flex items-center gap-2">
                            <div 
                              className="w-3 h-3 rounded-full" 
                              style={{ backgroundColor: boardConfig.progress_status_options.find(
                                (status: ProgressStatusOption) => status.id === customTask.progress_status_id
                              )?.color }}
                            />
                            {boardConfig.progress_status_options.find(
                              (status: ProgressStatusOption) => status.id === customTask.progress_status_id
                            )?.label}
                          </div>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      {boardConfig.progress_status_options.map((status) => (
                        <SelectItem key={status.id} value={status.id.toString()}>
                          <div className="flex items-center gap-2">
                            <div 
                              className="w-3 h-3 rounded-full" 
                              style={{ backgroundColor: status.color }}
                            />
                            {status.label}
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>

              {/* Board Status (if enabled) */}
              {boardConfig.board_status_options?.length > 0 && (
                <div className="space-y-2">
                  <div className="flex items-center gap-2 text-sm text-muted-foreground">
                    <Clock className="h-4 w-4" />
                    Board Status
                  </div>
                  <div className="w-full overflow-hidden rounded-md">
                    <Select 
                      value={customTask.board_status_id?.toString()}
                      onValueChange={handleBoardStatusChange}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue>
                          {boardConfig.board_status_options.find(
                            (status: BoardStatusOption) => status.id === customTask.board_status_id
                          ) && (
                            <div className="flex items-center gap-2">
                              <div 
                                className="w-3 h-3 rounded-full" 
                                style={{ backgroundColor: boardConfig.board_status_options.find(
                                  (status: BoardStatusOption) => status.id === customTask.board_status_id
                                )?.color }}
                              />
                              {boardConfig.board_status_options.find(
                                (status: BoardStatusOption) => status.id === customTask.board_status_id
                              )?.label}
                            </div>
                          )}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        {boardConfig.board_status_options.map((status: BoardStatusOption) => (
                          <SelectItem key={status.id} value={status.id.toString()}>
                            <div className="flex items-center gap-2">
                              <div 
                                className="w-3 h-3 rounded-full" 
                                style={{ backgroundColor: status.color }}
                              />
                              {status.label}
                            </div>
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              )}
            </div>

            {/* Only show assignees and dates if not simplified */}
            {!isSimplified && (
              <>
                {/* Assignees */}
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                      <Users className="h-4 w-4" />
                      Assignees
                    </div>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="h-8 px-2"
                      onClick={() => setAssigneeModalVisible(true)}
                    >
                      <Edit className="h-4 w-4" />
                    </Button>
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {customTask.assigned_users?.map((userId) => {
                      const user = users.find(u => u.user_id.toString() === userId);
                      if (!user) return null;
                      return (
                        <div key={userId} className="flex items-center gap-2 bg-secondary rounded-lg px-2 py-1">
                          <UserAvatar user={user} size={24} />
                          <span className="text-sm">{user.name}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Dates */}
                <div className="grid grid-cols-2 gap-4">
                  {/* Work Date */}
                  <div className="space-y-2">
                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                      <Clock className="h-4 w-4" />
                      Work Date
                    </div>
                    <TaskDateEditor
                      date={customTask.work_date || null}
                      dateType="work"
                      taskId={customTask.id}
                      taskType="custom"
                      className="w-full"
                      onSuccess={(updatedTask) => setTask(prevTask => 
                        prevTask ? ({ ...prevTask, ...updatedTask }) : updatedTask
                      )}
                    />
                  </div>

                  {/* Due Date */}
                  <div className="space-y-2">
                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                      <Calendar className="h-4 w-4" />
                      Due Date
                    </div>
                    <TaskDateEditor
                      date={customTask.due_date || null}
                      dateType="due"
                      taskId={customTask.id}
                      taskType="custom"
                      className="w-full"
                      onSuccess={(updatedTask) => setTask(prevTask => 
                        prevTask ? ({ ...prevTask, ...updatedTask }) : updatedTask
                      )}
                    />
                  </div>
                </div>
              </>
            )}

            {/* Time Tracking (if enabled and not simplified) */}
            {boardConfig.enable_time_tracking && !isSimplified && (
              <>
                {/* Timer Button */}
                {renderTimerButton()}

                {/* Expected Duration */}
                {customTask.expected_workload && (
                  <div className="mt-4">
                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                      <Timer className="h-4 w-4" />
                      Expected Duration
                    </div>
                    <div className="mt-1 text-sm">
                      {Math.round(customTask.expected_workload)} minutes
                    </div>
                  </div>
                )}
              </>
            )}
          </CardContent>
        </Card>
      );
    }

    // Regular task card (existing code)
    return (
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <FileText className="h-5 w-5" />
            Task
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <Clock className="h-4 w-4" />
                Work Date
              </div>
              <TaskDateEditor
                date={task.next_work_date || null}
                dateType="work"
                taskId={task.id}
                taskType={taskType as FrontendTaskType}
                className="w-full"
                onSuccess={(updatedTask) => setTask(prevTask => 
                  prevTask ? ({ ...prevTask, ...updatedTask }) : updatedTask
                )}
              />
            </div>
            <div className="space-y-2">
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <Calendar className="h-4 w-4" />
                Due Date
              </div>
              <TaskDateEditor
                date={task.next_due_date || null}
                dateType="due"
                taskId={task.id}
                taskType={taskType as FrontendTaskType}
                className="w-full"
                onSuccess={(updatedTask) => setTask(prevTask => 
                  prevTask ? ({ ...prevTask, ...updatedTask }) : updatedTask
                )}
              />
            </div>
          </div>

          {renderTaskStatus(task.board_status || '')}
          {renderTimerButton()}
        </CardContent>
      </Card>
    );
  };

  const handleAssigneeUpdate = async (assignees: string[]) => {
    if (!task) return;
    
    try {
      const response = await axios.put(`/custom-board/tasks/${task.id}`, {
        assigned_users: assignees
      });
      
      setTask(prevTask => prevTask ? { ...prevTask, ...response.data } : response.data);
      setAssigneeModalVisible(false);
    } catch (error) {
      console.error('Error updating assignees:', error);
      toast.error('Failed to update assignees');
    }
  };

  useEffect(() => {
    if (propBoard) {
      setBoard(propBoard);
    } else if (task?.task_type === 'custom' && task.board_id) {
      const fetchBoard = async () => {
        try {
          const response = await axios.get(`/custom-board/boards/${task.board_id}`);
          setBoard(response.data);
        } catch (error) {
          console.error('Error fetching board:', error);
        }
      };
      fetchBoard();
    }
  }, [task, propBoard]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingSpinner size="lg" />
      </div>
    )
  }

  if (!task) return null

  return (
    <Dialog open onOpenChange={handleClose} modal={false}>
      <DialogContent 
        className="max-w-[90vw] h-[90vh] p-0 flex flex-col"
        closeButtonClassName="text-white dark:text-white"
      >
        <div className="sr-only">
          <DialogTitle>Task Details</DialogTitle>
          <DialogDescription>View and manage task details, including contacts, products, and account information.</DialogDescription>
        </div>
        <div className="flex flex-col h-full overflow-hidden">
          <div className="bg-primary text-primary-foreground p-4 rounded-t-lg shrink-0">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <div className="flex gap-2">
                <Button
                  variant="secondary"
                  size="icon"
                  onClick={handlePrevTask}
                  disabled={tasks.findIndex(t => t.id === task?.id) === 0}
                >
                  <ArrowUp className="h-4 w-4" />
                </Button>
                <Button
                  variant="secondary"
                  size="icon"
                  onClick={handleNextTask}
                  disabled={tasks.findIndex(t => t.id === task?.id) === tasks.length - 1}
                >
                  <ArrowDown className="h-4 w-4" />
                </Button>
                </div>
                <h2 className="text-lg font-semibold ml-4">
                  {task?.task_type === 'custom' ? (
                    <>
                      {task.deal_id ? `${dealData.name || dealName}: ` : ''}{task.title}
                    </>
                  ) : (
                    `${dealData.name || dealName || 'N/A'}: ${task.description || task.text}`
                  )}
                </h2>
              </div>
              <div className="mr-8">
                {shouldShowDealSections() && <DealNavBar dealId={task?.deal_id} />}
              </div>
            </div>
          </div>

          <div className="flex flex-1 overflow-hidden">
            <div className="flex-[2] flex flex-col overflow-hidden">
              <Tabs value={activeTab} onValueChange={setActiveTab} className="flex flex-col h-full">
                <div className="border-b bg-muted shrink-0">
                  <TabsList className="w-full h-12 bg-transparent">
                    {tabItems.map((item) => (
                      <TabsTrigger
                        key={item.value}
                        value={item.value}
                        className="flex-1 data-[state=active]:bg-background data-[state=active]:shadow-sm relative px-8"
                      >
                        {item.label}
                        <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary opacity-0 transition-opacity data-[state=active]:opacity-100" />
                      </TabsTrigger>
                    ))}
                  </TabsList>
                </div>
                
                <div className="flex-1 overflow-hidden">
                  <ScrollArea className="h-full">
                    <div className="p-4 space-y-4">
                      {tabItems.map((item) => (
                        <TabsContent key={item.value} value={item.value} className="mt-0 p-0 h-full">
                          {item.content}
                        </TabsContent>
                      ))}
                    </div>
                  </ScrollArea>
                </div>
              </Tabs>
            </div>

            <div className="w-[400px] border-l overflow-hidden">
              <ScrollArea className="h-[calc(90vh-5rem)]">
                <div className="p-4 pb-8 space-y-6">
                  {renderTaskCard()}
                  {/* Payroll Card */}
                  {taskType === 'lon' && shouldShowDealSections() && (
                    <Card>
                      <CardHeader>
                        <div className="flex items-center justify-between">
                          <CardTitle className="flex items-center gap-2">
                            <DollarSign className="h-5 w-5" />
                            Payroll
                          </CardTitle>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => setPayrollDrawerVisible(true)}
                          >
                            <Edit className="h-4 w-4" />
                          </Button>
                        </div>
                      </CardHeader>
                      <CardContent>
                        {!lonConfig?.salary_type ? (
                          <div className="text-sm text-muted-foreground italic">
                            Payroll not configured yet
                          </div>
                        ) : (
                          <div className="space-y-4">
                            {task?.deal_software?.lønprogram && (
                              <div className="flex items-center gap-2">
                                <span className="text-sm text-muted-foreground">Lønprogram:</span>
                                <Badge variant="outline">
                                  {task.deal_software.lønprogram}
                                </Badge>
                              </div>
                            )}
                            <div className="flex items-center gap-2">
                              <span className="text-sm text-muted-foreground">Type:</span>
                              <span>{lonConfig.salary_type}</span>
                            </div>
                            {(lonConfig.salary_period_hourly?.start || lonConfig.salary_period_hourly?.end) && (
                              <div className="flex items-center gap-2">
                                <span className="text-sm text-muted-foreground">Period (hourly):</span>
                                <span>
                                  {`${lonConfig.salary_period_hourly.display_start} - ${
                                    lonConfig.salary_period_hourly.end === -1 
                                      ? `${task.work_date_last_day}`
                                      : lonConfig.salary_period_hourly.display_end
                                  }`}
                                </span>
                              </div>
                            )}
                            {/* ... Payroll history graph would go here ... */}
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  )}

                  {/* Contact Card */}
                  {(!isCustomTask || (isCustomTask && task?.deal_id)) && (
                    <Card>
                      <CardHeader>
                        <div className="flex items-center justify-between">
                          <CardTitle className="flex items-center gap-2">
                            <User className="h-5 w-5" />
                            Contact
                          </CardTitle>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  onClick={handleContactInfoClick}
                                >
                                  <User className="h-4 w-4" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>
                                <div className="space-y-2">
                                  <p className="font-semibold">Contact Details:</p>
                                  {contactInfo.length > 0 ? (
                                    <ul className="list-disc pl-4">
                                      {contactInfo.map((entry, index) => (
                                        <li key={index}>{entry.text}</li>
                                      ))}
                                    </ul>
                                  ) : (
                                    <p>No info added yet, click to add</p>
                                  )}
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      </CardHeader>
                      <CardContent className="space-y-4">
                        {isLoadingDealData ? (
                          <div className="space-y-4">
                            <div className="grid grid-cols-2 gap-4">
                              <div>
                                <Skeleton className="h-4 w-20 mb-2" />
                                <Skeleton className="h-5 w-32" />
                              </div>
                              <div>
                                <Skeleton className="h-4 w-20 mb-2" />
                                <Skeleton className="h-5 w-40" />
                              </div>
                              <div>
                                <Skeleton className="h-4 w-20 mb-2" />
                                <Skeleton className="h-5 w-28" />
                              </div>
                            </div>
                          </div>
                        ) : task?.contacts && task.contacts.length > 0 ? (
                          task.contacts.map((contact, index) => (
                            <div key={index} className="grid grid-cols-2 gap-4 border-b pb-4 last:border-b-0 last:pb-0">
                              <div>
                                <span className="text-sm text-muted-foreground">Name</span>
                                <p>{contact.name || 'N/A'}</p>
                              </div>
                              <div>
                                <span className="text-sm text-muted-foreground">Email</span>
                                <p>{contact.email || 'N/A'}</p>
                              </div>
                              <div>
                                <span className="text-sm text-muted-foreground">Phone</span>
                                <p>{contact.phone || 'N/A'}</p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-sm text-muted-foreground italic">
                            No contacts associated with this deal
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  )}

                  {/* Deal Card */}
                  {(!isCustomTask || (isCustomTask && task?.deal_id)) && (
                    <Card>
                      <CardHeader>
                        <div className="flex items-center justify-between">
                          <CardTitle className="flex items-center gap-2">
                            <Building2 className="h-5 w-5" />
                            Deal
                          </CardTitle>
                          <Button
                            variant="link"
                            onClick={() => setDealDrawerVisible(true)}
                          >
                            <Badge variant="outline" className="text-blue-600">
                              {dealData.name}
                            </Badge>
                          </Button>
                        </div>
                      </CardHeader>
                      <CardContent className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <span className="text-sm text-muted-foreground">CVR</span>
                            <p>{dealData.cvr || 'N/A'}</p>
                          </div>
                          <div>
                            <span className="text-sm text-muted-foreground">Address</span>
                            <p>{`${dealData.address || ''} ${dealData.zipcode || ''} ${dealData.city || ''}`}</p>
                          </div>
                          <div>
                            <span className="text-sm text-muted-foreground">Customer Number</span>
                            <p>{dealData.economic_customer_number || 'N/A'}</p>
                          </div>
                          <div>
                            <span className="text-sm text-muted-foreground">Onboard Status</span>
                            <p>{dealData.onboard_status || 'N/A'}</p>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  )}

                  {/* Products Card */}
                  {(!isCustomTask || (isCustomTask && task?.deal_id)) && (
                    <Card>
                      <CardHeader>
                        <div className="flex items-center justify-between">
                          <CardTitle className="flex items-center gap-2">
                            <ShoppingBag className="h-5 w-5" />
                            Products
                          </CardTitle>
                          <Button
                            variant="link"
                            onClick={() => setProductsDrawerVisible(true)}
                          >
                            <Badge variant="outline" className="text-magenta-600">
                              {task?.products?.length || '0'} product(s)
                            </Badge>
                          </Button>
                        </div>
                      </CardHeader>
                      <CardContent className="grid grid-cols-2 gap-4">
                        {isLoadingDealData ? (
                          <>
                            <div className="flex items-center gap-2 p-2 bg-background rounded-lg shadow">
                              <Skeleton className="h-10 w-10 rounded-md" />
                              <Skeleton className="h-5 w-24" />
                            </div>
                            <div className="flex items-center gap-2 p-2 bg-background rounded-lg shadow">
                              <Skeleton className="h-10 w-10 rounded-md" />
                              <Skeleton className="h-5 w-24" />
                            </div>
                          </>
                        ) : task?.products && task.products.length > 0 ? (
                          task.products.map((product, index) => (
                            <TooltipProvider key={index}>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <div className="flex items-center gap-2 p-2 bg-background rounded-lg shadow hover:shadow-lg transition">
                                    <div className="flex items-center justify-center w-10 h-10 bg-primary rounded-md text-primary-foreground">
                                      {PRODUCT_ICONS[product.name] || <ShoppingBag className="h-5 w-5" />}
                                    </div>
                                    <span className="text-sm font-medium truncate max-w-[100px]">
                                      {truncateText(product.name)}
                                    </span>
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <div className="space-y-2">
                                    <p><span className="font-semibold">Name:</span> {product.name}</p>
                                    <p><span className="font-semibold">Code:</span> {product.code || 'N/A'}</p>
                                    <p><span className="font-semibold">Quantity:</span> {product.quantity || 1}</p>
                                    <p><span className="font-semibold">Price:</span> {product.item_price || 'N/A'}</p>
                                    <p><span className="font-semibold">Billing Start:</span> {product.billing_start_date || 'N/A'}</p>
                                    <p><span className="font-semibold">Frequency:</span> {product.billing_frequency || 'N/A'}</p>
                                  </div>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          ))
                        ) : (
                          <div className="col-span-2 text-sm text-muted-foreground italic">
                            No products associated with this deal
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  )}

                  {/* Account Card */}
                  {(!isCustomTask || (isCustomTask && task?.deal_id)) && (
                    <Card>
                      <CardHeader>
                        <CardTitle className="flex items-center gap-2">
                          <Users className="h-5 w-5" />
                          Account
                        </CardTitle>
                      </CardHeader>
                      <CardContent>
                        {isLoadingDealData ? (
                          <div className="space-y-4">
                            <Skeleton className="h-8 w-40" />
                            <div className="grid grid-cols-2 gap-4">
                              <div>
                                <Skeleton className="h-4 w-20 mb-2" />
                                <Skeleton className="h-5 w-32" />
                              </div>
                              <div>
                                <Skeleton className="h-4 w-20 mb-2" />
                                <Skeleton className="h-5 w-40" />
                              </div>
                              <div>
                                <Skeleton className="h-4 w-20 mb-2" />
                                <Skeleton className="h-5 w-28" />
                              </div>
                              <div>
                                <Skeleton className="h-4 w-20 mb-2" />
                                <Skeleton className="h-5 w-24" />
                              </div>
                            </div>
                          </div>
                        ) : task?.account ? (
                          <div className="space-y-4">
                            <Button
                              variant="link"
                              onClick={() => showAccountDetails(task.account)}
                            >
                              <Badge variant="outline" className="text-green-600">
                                {task.account.company_name}
                              </Badge>
                            </Button>
                            <div className="grid grid-cols-2 gap-4">
                              <div>
                                <span className="text-sm text-muted-foreground">CVR</span>
                                <p>{task.account.cvr || 'N/A'}</p>
                              </div>
                              <div>
                                <span className="text-sm text-muted-foreground">Address</span>
                                <p>{task.account.address || 'N/A'}</p>
                              </div>
                              <div>
                                <span className="text-sm text-muted-foreground">Zipcode</span>
                                <p>{task.account.zipcode || 'N/A'}</p>
                              </div>
                              <div>
                                <span className="text-sm text-muted-foreground">City</span>
                                <p>{task.account.city || 'N/A'}</p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="text-sm text-muted-foreground italic">
                            No account associated with this deal
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  )}
                </div>
              </ScrollArea>
            </div>
          </div>
        </div>

        {/* Drawers and Modals */}
        <ContactInfoDrawer
          visible={contactInfoVisible}
          onClose={handleContactInfoClose}
          contactId={task?.contact?.id}
          contactInfo={contactInfo}
        />
        <DealDrawer
          visible={dealDrawerVisible}
          onClose={() => setDealDrawerVisible(false)}
          dealId={task?.deal_id || null}
        />
        <TaskCompleteDrawer
          visible={isCompleteDrawerVisible}
          onClose={handleCompleteDrawerClose}
          task={taskToComplete as EnhancedTask}
          onComplete={handleCompleteDrawerSubmit}
        />
        <PayrollDrawer
          visible={payrollDrawerVisible}
          onClose={() => setPayrollDrawerVisible(false)}
          dealId={task?.deal_id || null}
          onSave={handlePayrollConfigSave}
        />
        <ProductsDrawer
          visible={productsDrawerVisible}
          onClose={() => setProductsDrawerVisible(false)}
          products={task?.products || []}
        />
        <AssigneeSelection
          visible={assigneeModalVisible}
          onClose={() => setAssigneeModalVisible(false)}
          onSave={handleAssigneeUpdate}
          initialAssignees={task?.assigned_users || []}
        />
      </DialogContent>
    </Dialog>
  )
}

export default TaskView