import { useState, useEffect } from 'react'
import axios from '@/api/axiosConfig'
import { toast } from 'sonner'
import { useNavigate } from 'react-router-dom'

// UI Components
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"
import { Loading } from "@/components/ui/loading"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import {
  Table,
  type ColumnDef
} from "@/components/ui/specialized/mod-table"
import { Input } from "@/components/ui/input"
import { MultiSelect } from "@/components/ui/select/multi-select"
import { Plus } from 'lucide-react'
import { Form } from '@/components/ui/form'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"

// Icons - combine all icon imports into one
import { 
  Users, 
  User, 
  Phone, 
  Mail, 
  Copy, 
  Settings2, 
  Building2 
} from 'lucide-react'

// Types
import type { Contact, Account, Deal } from '../types/customers'
import type { StoredUser } from '@/types/user'

// Components
import { AssigneeSelection } from '../modals/AssigneeSelection'
import { useUsers } from '@/contexts/UserContext'
import { DealDrawer } from "@/drawers/DealDrawer"

const Contacts = () => {
  const { users } = useUsers();
  const [contacts, setContacts] = useState<Contact[]>([])
  const [loading, setLoading] = useState(true)
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)
  const [accountModalVisible, setAccountModalVisible] = useState(false)
  const [assigneeModalVisible, setAssigneeModalVisible] = useState(false)
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(15)
  const [searchQuery, setSearchQuery] = useState("")
  const [filteredData, setFilteredData] = useState<Contact[]>([])
  const [dealModalVisible, setDealModalVisible] = useState(false)
  const [availableDeals, setAvailableDeals] = useState<Deal[]>([])
  const [selectedDeals, setSelectedDeals] = useState<string[]>([])
  const [createModalVisible, setCreateModalVisible] = useState(false)
  const [newContactData, setNewContactData] = useState({
    name: '',
    email: '',
    phone: '',
    account_id: ''
  })
  const navigate = useNavigate()
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false)
  const [selectedDealId, setSelectedDealId] = useState<number | null>(null)
  const [accounts, setAccounts] = useState<Account[]>([])
  const [accountPopoverOpen, setAccountPopoverOpen] = useState(false)

  useEffect(() => {
    fetchContacts()
  }, [])

  useEffect(() => {
    setFilteredData(contacts)
  }, [contacts])

  useEffect(() => {
    if (dealModalVisible) {
      fetchAvailableDeals()
    }
  }, [dealModalVisible])

  useEffect(() => {
    fetchAccounts()
  }, [])

  const fetchContacts = async () => {
    try {
      const response = await axios.get('/contact/')
      setContacts(response.data)
    } catch (error) {
      console.error('Error fetching contacts:', error)
      toast.error('Failed to fetch contacts')
    } finally {
      setLoading(false)
    }
  }

  const showAccountDetails = async (account: Account) => {
    try {
      const response = await axios.get(`/account/accounts/${account.id}`)
      setSelectedAccount(response.data)
      setAccountModalVisible(true)
    } catch (error) {
      console.error('Error fetching account details:', error)
      toast.error('Failed to fetch account details')
    }
  }

  const handleUpdateAircallUser = async (contact: Contact, selectedUsers: string[]) => {
    try {
      const userId = selectedUsers[0]
      await axios.patch(`/account/contacts/${contact.id}/aircall-user`, {
        user_id: userId
      })
      toast.success('Aircall contact updated successfully')
      fetchContacts()
    } catch (error) {
      console.error('Error updating aircall contact:', error)
      toast.error('Failed to update aircall contact')
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchQuery(value)
    if (!value) {
      setFilteredData(contacts)
      return
    }
    
    const filtered = contacts.filter(item => 
      item.name.toLowerCase().includes(value.toLowerCase()) ||
      item.email?.toLowerCase().includes(value.toLowerCase()) ||
      item.phone?.toLowerCase().includes(value.toLowerCase()) ||
      item.accounts.some(account => 
        account.company_name.toLowerCase().includes(value.toLowerCase())
      )
    )
    setFilteredData(filtered)
  }

  const fetchAvailableDeals = async () => {
    try {
      const response = await axios.get('/account/deals')
      setAvailableDeals(response.data)
    } catch (error) {
      console.error('Error fetching deals:', error)
      toast.error('Failed to fetch available deals')
    }
  }

  const handleUpdateDeals = async (contact: Contact, dealIds: string[]) => {
    try {
      await axios.patch(`/contact/${contact.id}/deals`, {
        deal_ids: dealIds
      })
      toast.success('Deals updated successfully')
      fetchContacts()
    } catch (error) {
      console.error('Error updating deals:', error)
      toast.error('Failed to update deals')
    }
  }

  const handleCreateContact = async () => {
    try {
      await axios.post('/contact', newContactData)
      toast.success('Contact created successfully')
      setCreateModalVisible(false)
      fetchContacts()
    } catch (error) {
      console.error('Error creating contact:', error)
      toast.error('Failed to create contact')
    }
  }

  const handleDeleteContact = async (contactId: number) => {
    try {
      await axios.delete(`/contact/${contactId}`)
      toast.success('Contact deleted successfully')
      fetchContacts()
    } catch (error) {
      console.error('Error deleting contact:', error)
      toast.error('Failed to delete contact')
    }
  }

  const fetchAccounts = async () => {
    try {
      const response = await axios.get('/account/accounts')
      setAccounts(response.data)
    } catch (error) {
      console.error('Error fetching accounts:', error)
      toast.error('Failed to fetch accounts')
    }
  }

  const handleUpdateAccount = async (contact: Contact, accountId: string) => {
    try {
      await axios.patch(`/contact/${contact.id}/account`, {
        account_id: accountId
      })
      toast.success('Account updated successfully')
      fetchContacts()
    } catch (error) {
      console.error('Error updating account:', error)
      toast.error('Failed to update account')
    }
  }

  const columns: ColumnDef<Contact>[] = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      cell: ({ row }: { row: { original: Contact } }) => (
        <Badge 
          variant="secondary"
          className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-contact))] text-white hover:bg-[hsl(var(--badge-contact)/.8)]"
          onClick={() => setSelectedContact(row.original)}
        >
          <User className="w-3 h-3 flex-shrink-0" />
          <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
            {row.original.name}
          </span>
        </Badge>
      ),
      sortable: {
        enabled: true
      }
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      cell: ({ row }: { row: { original: Contact } }) => (
        <div className="flex items-center gap-2">
          <Mail className="w-4 h-4" />
          {row.original.email ? (
            <a 
              href={`mailto:${row.original.email}`} 
              className="hover:underline"
              onClick={(e) => e.stopPropagation()}
            >
              {row.original.email}
            </a>
          ) : (
            <span>N/A</span>
          )}
          {row.original.email && (
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6 opacity-50 hover:opacity-100"
              onClick={(e) => {
                e.stopPropagation()
                navigator.clipboard.writeText(row.original.email)
                toast.success('Email copied to clipboard')
              }}
            >
              <Copy className="h-3 w-3" />
            </Button>
          )}
        </div>
      )
    },
    {
      key: 'phone',
      title: 'Phone',
      dataIndex: 'phone',
      cell: ({ row }: { row: { original: Contact } }) => (
        <div className="flex items-center gap-2">
          <Phone className="w-4 h-4" />
          {row.original.phone ? (
            <a 
              href={`tel:${row.original.phone}`} 
              className="hover:underline"
              onClick={(e) => e.stopPropagation()}
            >
              {row.original.phone}
            </a>
          ) : (
            <span>N/A</span>
          )}
          {row.original.phone && (
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6 opacity-50 hover:opacity-100"
              onClick={(e) => {
                e.stopPropagation()
                navigator.clipboard.writeText(row.original.phone)
                toast.success('Phone number copied to clipboard')
              }}
            >
              <Copy className="h-3 w-3" />
            </Button>
          )}
        </div>
      )
    },
    {
      key: 'accounts',
      title: 'Accounts',
      dataIndex: 'accounts',
      cell: ({ row }: { row: { original: Contact } }) => (
        <div className="flex flex-wrap gap-2">
          {row.original.accounts.map((account) => (
            <Badge 
              key={account.id}
              variant="secondary"
              className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-account))] text-white hover:bg-[hsl(var(--badge-account)/.8)]"
              onClick={() => showAccountDetails(account)}
            >
              <Building2 className="w-3 h-3 flex-shrink-0" />
              <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                {account.company_name}
              </span>
            </Badge>
          ))}
          <Popover 
            open={accountPopoverOpen && selectedContact?.id === row.original.id}
            onOpenChange={(open) => {
              setAccountPopoverOpen(open)
              if (!open) setSelectedContact(null)
            }}
          >
            <PopoverTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                onClick={(e) => {
                  e.stopPropagation()
                  setSelectedContact(row.original)
                  setAccountPopoverOpen(true)
                }}
              >
                <Settings2 className="h-4 w-4" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0" align="end">
              <Command>
                <CommandInput placeholder="Search accounts..." />
                <CommandEmpty>No account found.</CommandEmpty>
                <CommandGroup>
                  {accounts.map((account) => (
                    <CommandItem
                      key={account.id}
                      value={account.company_name}
                      onSelect={() => {
                        handleUpdateAccount(row.original, account.id.toString())
                        setAccountPopoverOpen(false)
                        setSelectedContact(null)
                      }}
                    >
                      <Building2 className="mr-2 h-4 w-4" />
                      {account.company_name}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        </div>
      )
    },
    {
      key: 'aircall_user',
      title: 'Aircall Contact',
      dataIndex: 'aircall_user',
      cell: ({ row }: { row: { original: Contact } }) => (
        <div 
          className="cursor-pointer w-[150px]"
          onClick={() => {
            setSelectedContact(row.original)
            setAssigneeModalVisible(true)
          }}
        >
          {row.original.aircall_user ? (
            <div className="flex items-center gap-2">
              <Avatar className="h-8 w-8">
                <AvatarImage 
                  src={row.original.aircall_user.avatar_urls?.medium || row.original.aircall_user.avatar_urls?.small} 
                  alt={row.original.aircall_user.name} 
                />
                <AvatarFallback>
                  {`${row.original.aircall_user.name.charAt(0)}${row.original.aircall_user.surname.charAt(0)}`}
                </AvatarFallback>
              </Avatar>
              <span className="truncate">
                {`${row.original.aircall_user.name} ${row.original.aircall_user.surname}`}
              </span>
            </div>
          ) : (
            <span className="text-muted-foreground whitespace-nowrap">No Aircall Contact</span>
          )}
        </div>
      )
    },
    {
      key: 'deals',
      title: 'Deals',
      dataIndex: 'deals',
      cell: ({ row }: { row: { original: Contact } }) => (
        <div className="flex flex-wrap gap-2">
          {row.original.deals?.map((deal) => (
            <Badge 
              key={deal.id}
              variant="secondary"
              className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-deal))] text-white hover:bg-[hsl(var(--badge-deal)/.8)]"
              onClick={() => {
                setSelectedDealId(deal.id)
                setDealDrawerVisible(true)
              }}
            >
              <Building2 className="w-3 h-3 flex-shrink-0" />
              <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                {deal.name}
              </span>
            </Badge>
          ))}
          <Button
            variant="ghost"
            size="icon"
            onClick={() => {
              setSelectedContact(row.original)
              setSelectedDeals(row.original.deals?.map(d => d.id.toString()) || [])
              setDealModalVisible(true)
            }}
          >
            <Settings2 className="h-4 w-4" />
          </Button>
        </div>
      )
    },
    {
      key: 'actions',
      title: '',
      dataIndex: 'id',
      cell: ({ row }: { row: { original: Contact } }) => (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => handleDeleteContact(row.original.id)}
        >
          Delete
        </Button>
      )
    }
  ]

  if (loading) {
    return <Loading message="Loading contacts..." />
  }

  return (
    <>
      <div className="space-y-4 p-8">
        <div className="flex items-center justify-between">
          <h2 className="text-3xl font-bold text-foreground">Contacts</h2>
          <Button onClick={() => setCreateModalVisible(true)}>
            <Plus className="mr-2 h-4 w-4" />
            Add Contact
          </Button>
        </div>

        <Card>
          <CardContent className="flex items-center gap-4 pt-6">
            <Users className="h-8 w-8 text-primary" />
            <div>
              <p className="text-sm font-medium">Total Contacts</p>
              <p className="text-2xl font-bold">{contacts.length}</p>
            </div>
          </CardContent>
        </Card>

        <div className="w-full flex justify-between items-center mb-4">
          <Input 
            placeholder="Search contacts..."
            value={searchQuery}
            onChange={handleSearch}
            className="max-w-sm"
          />
        </div>

        <div>
          {filteredData.length === 0 && searchQuery ? (
            <div className="text-center py-8 text-muted-foreground">
              No results found for "{searchQuery}"
            </div>
          ) : (
            <Table 
              data={filteredData.length > 0 || searchQuery ? filteredData : contacts}
              columns={columns}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              onPageSizeChange={setPageSize}
            />
          )}
        </div>

        <Dialog open={accountModalVisible} onOpenChange={setAccountModalVisible}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Account Details</DialogTitle>
            </DialogHeader>
            {selectedAccount && (
              <div className="space-y-4">
                <p><span className="font-semibold">Company Name:</span> {selectedAccount.company_name}</p>
                <p><span className="font-semibold">CVR:</span> {selectedAccount.cvr || 'N/A'}</p>
                <p><span className="font-semibold">Address:</span> {selectedAccount.address || 'N/A'}</p>
                <p><span className="font-semibold">Zipcode:</span> {selectedAccount.zipcode || 'N/A'}</p>
                <p><span className="font-semibold">City:</span> {selectedAccount.city || 'N/A'}</p>
              </div>
            )}
            <DialogFooter>
              <Button variant="outline" onClick={() => setAccountModalVisible(false)}>
                Close
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <AssigneeSelection
          visible={assigneeModalVisible}
          onClose={() => {
            setAssigneeModalVisible(false)
            setSelectedContact(null)
          }}
          onSave={(selectedUsers: string[]) => {
            if (selectedContact) {
              handleUpdateAircallUser(selectedContact, selectedUsers)
            }
            setAssigneeModalVisible(false)
            setSelectedContact(null)
          }}
          initialAssignees={selectedContact?.aircall_user_id ? [selectedContact.aircall_user_id] : []}
          maxAssignees={1}
        />

        <Dialog open={dealModalVisible} onOpenChange={setDealModalVisible}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Manage Associated Deals</DialogTitle>
            </DialogHeader>
            {selectedContact && (
              <div className="space-y-4">
                <MultiSelect
                  options={availableDeals.map(deal => ({
                    value: deal.id.toString(),
                    label: deal.name
                  }))}
                  selected={selectedDeals}
                  onChange={setSelectedDeals}
                  placeholder="Select deals..."
                />
              </div>
            )}
            <DialogFooter>
              <Button variant="outline" onClick={() => setDealModalVisible(false)}>
                Cancel
              </Button>
              <Button 
                onClick={() => {
                  if (selectedContact) {
                    handleUpdateDeals(selectedContact, selectedDeals)
                  }
                  setDealModalVisible(false)
                }}
              >
                Save Changes
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Dialog open={createModalVisible} onOpenChange={setCreateModalVisible}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Create New Contact</DialogTitle>
            </DialogHeader>
            <div className="space-y-4">
              <Input
                placeholder="Name"
                value={newContactData.name}
                onChange={(e) => setNewContactData({ ...newContactData, name: e.target.value })}
              />
              <Input
                placeholder="Email"
                value={newContactData.email}
                onChange={(e) => setNewContactData({ ...newContactData, email: e.target.value })}
              />
              <Input
                placeholder="Phone"
                value={newContactData.phone}
                onChange={(e) => setNewContactData({ ...newContactData, phone: e.target.value })}
              />
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setCreateModalVisible(false)}>
                Cancel
              </Button>
              <Button onClick={handleCreateContact}>
                Create Contact
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>

      <DealDrawer
        visible={dealDrawerVisible}
        onClose={() => {
          setDealDrawerVisible(false)
          setSelectedDealId(null)
        }}
        dealId={selectedDealId}
      />
    </>
  )
}

export default Contacts