import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { InputConfig as InputConfigType } from '@/onboardingflow/types/node_types';

interface DealDataConfigField {
  label: string;
  type: string;
}

interface InputConfigProps {
  config: InputConfigType;
  elementId: string;
  dealDataConfig?: Record<string, DealDataConfigField>;
  onChange: (updates: Partial<InputConfigType>) => void;
}

export const InputConfig = ({
  config,
  elementId,
  dealDataConfig,
  onChange
}: InputConfigProps) => {
  return (
    <div className="space-y-4">
      <div>
        <Label>Label</Label>
        <Input
          value={config.label}
          onChange={(e) => onChange({ label: e.target.value })}
          placeholder="Enter label"
        />
      </div>
      <div>
        <Label>Type</Label>
        <Select
          value={config.type}
          onValueChange={(value) => onChange({ type: value as 'text' | 'email' | 'number' })}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="text">Text</SelectItem>
            <SelectItem value="email">Email</SelectItem>
            <SelectItem value="number">Number</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label>Placeholder</Label>
        <Input
          value={config.placeholder}
          onChange={(e) => onChange({ placeholder: e.target.value })}
          placeholder="Enter placeholder text"
        />
      </div>
      <div className="flex items-center gap-2">
        <Checkbox
          checked={config.required}
          onCheckedChange={(checked) => onChange({ required: !!checked })}
          id="required"
        />
        <Label htmlFor="required">Required</Label>
      </div>
      {dealDataConfig && (
        <div>
          <Label>Deal Data Field</Label>
          <Select
            value={config.dealDataKey}
            onValueChange={(value) => onChange({ dealDataKey: value })}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select deal data field" />
            </SelectTrigger>
            <SelectContent>
              {Object.entries(dealDataConfig).map(([key, field]) => (
                <SelectItem key={key} value={key}>
                  {field.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}
    </div>
  );
};
