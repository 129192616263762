import * as React from 'react'
import { useState, useEffect } from 'react'
import type { LucideIcon } from 'lucide-react'
import { 
  BarChart, 
  Calendar, 
  ListTodo, 
  Settings, 
  FileText, 
  Upload, 
  Bot, 
  Monitor,
  Clock,
  Bug,
  LayoutDashboard,
  ClipboardList,
  Users,
  CloudUpload,
  RefreshCw,
  GitBranch
} from 'lucide-react'
import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import ErpHeader from '../components/ErpHeader'
import AdminStatus from './pages/AdminStatus'
import AdminSchedule from './pages/AdminSchedule'
import AdminTasks from './pages/AdminTasks'
import AdminInvoice from './pages/AdminInvoice'
import AdminBackup from './pages/AdminBackup'
import AdminPipedrive from './pages/AdminPipedrive'
import AdminRevibot from './pages/AdminRevibot'
import AdminTesting from './pages/AdminTesting'
import AdminMonitor from './pages/AdminMonitor'
import AdminFlow from './pages/AdminFlow'
import { ScrollArea } from "@/components/ui/scroll-area"

interface TabItem {
  id: string
  icon: LucideIcon
  label: string
  component: React.ReactElement
  sidebarTabs?: {
    id: string
    icon: LucideIcon
    label: string
  }[]
}

interface AdminDashboardProps {
  theme: string
  toggleTheme: () => void
}

export default function AdminDashboard({ theme, toggleTheme }: AdminDashboardProps) {
  const [activeTab, setActiveTab] = useState("status")
  const [activeSidebarTab, setActiveSidebarTab] = useState<string | null>("schedule")

  const tabItems: TabItem[] = [
    { 
      id: "status", 
      icon: BarChart, 
      label: "Status",
      component: <AdminStatus />,
      sidebarTabs: [
        { id: "schedule", icon: Clock, label: "Schedule/Jobs" },
        { id: "errors", icon: Bug, label: "Error Logs" }
      ]
    },
    { 
      id: "schedule", 
      icon: Calendar, 
      label: "Schedule", 
      component: <AdminSchedule />,
      sidebarTabs: [
        { id: "overview", icon: BarChart, label: "Overview" },
        { id: "fetch_dashboard_data", icon: LayoutDashboard, label: "Dashboard Data" },
        { id: "recurring_tasks_processing", icon: ClipboardList, label: "Recurring Tasks" },
        { id: "invoice_processing", icon: FileText, label: "Invoice Processing" },
        { id: "team_briefings", icon: Users, label: "Team Briefings" },
        { id: "db_backups", icon: CloudUpload, label: "DB Backups" },
        { id: "revibot_sync", icon: RefreshCw, label: "Revibot Sync" }
      ]
    },
    { id: "tasks", icon: ListTodo, label: "Tasks & Workload", component: <AdminTasks /> },
    { id: "invoicing", icon: FileText, label: "Invoicing", component: <AdminInvoice /> },
    { id: "backup", icon: Upload, label: "Backups", component: <AdminBackup /> },
    { id: "pipedrive", icon: FileText, label: "Pipedrive", component: <AdminPipedrive /> },
    { id: "revibot", icon: Bot, label: "Revibot", component: <AdminRevibot /> },
    { id: "testing", icon: Settings, label: "Testing", component: <AdminTesting /> },
    { id: "monitor", icon: Monitor, label: "Monitor", component: <AdminMonitor /> },
    { 
      id: "flow", 
      icon: GitBranch, 
      label: "Flow Editor", 
      component: <AdminFlow />,
      sidebarTabs: [
        { id: "endpoints", icon: Settings, label: "API Endpoints" },
        { id: "templates", icon: FileText, label: "Node Templates" }
      ]
    },
  ]

  const currentTab = tabItems.find(tab => tab.id === activeTab)
  
  useEffect(() => {
    if (currentTab?.sidebarTabs?.length) {
      setActiveSidebarTab(currentTab.sidebarTabs[0].id)
    } else {
      setActiveSidebarTab(null)
    }
  }, [activeTab])

  return (
    <div className="min-h-screen flex flex-col bg-background">
      <ErpHeader 
        icon={Settings}
        title="Admin"
      />
      <main className="flex-1 p-6 space-y-4">
        <div className="w-full">
          <header className="border border-border rounded-lg bg-card text-card-foreground shadow-sm">
            <div className="flex h-16 items-center px-4">
              <ScrollArea className="w-full">
                <nav className="flex items-center space-x-4 mx-6 min-w-max">
                  {tabItems.map(({ id, icon: Icon, label }) => (
                    <Button
                      key={id}
                      variant={activeTab === id ? 'outline' : 'ghost'}
                      className={cn(
                        'flex items-center gap-2',
                        activeTab === id && 'border-primary'
                      )}
                      onClick={() => setActiveTab(id)}
                    >
                      <Icon className="h-4 w-4" />
                      <span className="hidden md:inline">{label}</span>
                    </Button>
                  ))}
                </nav>
              </ScrollArea>
            </div>
          </header>

          <div className="mt-6">
            {tabItems.map(({ id, component, sidebarTabs }) => (
              activeTab === id && (
                <div key={id}>
                  {sidebarTabs ? (
                    <div className="flex">
                      <div className="w-[240px] flex-shrink-0 h-[calc(100vh-12rem)] bg-muted p-2 rounded-l-lg">
                        <div className="flex flex-col space-y-1">
                          {sidebarTabs.map(({ id: subId, icon: SubIcon, label }) => (
                            <Button
                              key={subId}
                              variant={activeSidebarTab === subId ? 'default' : 'ghost'}
                              className={cn(
                                "justify-start",
                                activeSidebarTab === subId && "bg-primary text-primary-foreground hover:bg-primary/90"
                              )}
                              onClick={() => setActiveSidebarTab(subId)}
                            >
                              <SubIcon className="mr-2 h-4 w-4" />
                              {label}
                            </Button>
                          ))}
                        </div>
                      </div>
                      <div className="flex-1 p-6">
                        {React.cloneElement(component as React.ReactElement, { 
                          activeTab: activeSidebarTab 
                        })}
                      </div>
                    </div>
                  ) : (
                    component
                  )}
                </div>
              )
            ))}
          </div>
        </div>
      </main>
    </div>
  )
}