import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { toast } from "sonner";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Folder,
  Calculator,
  Building2,
  Bot,
  CreditCard,
  Pencil
} from "lucide-react";
import axios from '../../api/axiosConfig';
import { cn } from "@/lib/utils";

interface NavItem {
  name: string;
  icon: string;
  link?: string;
  enabled: boolean;
}

interface DealNavBarProps {
  dealId?: string | number | null;
}

const iconMap: Record<string, React.ReactNode> = {
  FolderOutlined: <div className="bg-accent/40 p-1.5 rounded-md"><Folder className="h-5 w-5 text-white" /></div>,
  CalculatorOutlined: <div className="bg-accent/40 p-1.5 rounded-md"><Calculator className="h-5 w-5 text-white" /></div>,
  BankOutlined: <div className="bg-accent/40 p-1.5 rounded-md"><Building2 className="h-5 w-5 text-white" /></div>,
  RobotOutlined: <div className="bg-accent/40 p-1.5 rounded-md"><Bot className="h-5 w-5 text-white" /></div>,
  CreditCardOutlined: <div className="bg-accent/40 p-1.5 rounded-md"><CreditCard className="h-5 w-5 text-white" /></div>,
};

const DealNavBar: React.FC<DealNavBarProps> = ({ dealId }) => {
  const [navItems, setNavItems] = useState<NavItem[]>([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const [selectedNav, setSelectedNav] = useState<NavItem | null>(null);
  const [loading, setLoading] = useState(true);
  const [editedItems, setEditedItems] = useState<Record<string, NavItem>>({});

  useEffect(() => {
    if (dealId) {
      fetchNavigation();
    } else {
      setLoading(false);
    }
  }, [dealId]);

  const fetchNavigation = async () => {
    if (!dealId) {
      setNavItems([]);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`/deal/deals/${dealId}/navigation`);
      
      if (response.data && Array.isArray(response.data.items)) {
        setNavItems(response.data.items);
        const initialEdits: Record<string, NavItem> = {};
        response.data.items.forEach((item: NavItem) => {
          initialEdits[item.name] = { ...item };
        });
        setEditedItems(initialEdits);
      } else {
        console.warn('Navigation data is not in expected format:', response.data);
        setNavItems([]);
        setEditedItems({});
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching navigation:', error);
      setNavItems([]);
      setEditedItems({});
      setLoading(false);
    }
  };

  const handleNavClick = (item: NavItem) => {
    if (item.link) {
      window.open(item.link, '_blank', 'noopener,noreferrer');
    } else {
      setSelectedNav(item);
      setLinkModalVisible(true);
    }
  };

  const handleUpdateLink = async (item: NavItem, newLink: string) => {
    if (!dealId) return;

    try {
      const updatedItem = {
        ...item,
        link: newLink
      };
      
      const response = await axios.put(
        `/deal/deals/${dealId}/navigation/${item.name}`,
        updatedItem
      );
      
      if (response.status === 200) {
        toast.success("Link updated successfully");
        await fetchNavigation();
        setLinkModalVisible(false);
        setSelectedNav(null);
      }
    } catch (error) {
      console.error('Error updating link:', error);
      toast.error("Failed to update link");
    }
  };

  const handleEditInputChange = (itemName: string, field: keyof NavItem, value: any) => {
    setEditedItems(prev => ({
      ...prev,
      [itemName]: {
        ...prev[itemName],
        [field]: value
      }
    }));
  };

  const handleSaveAll = async () => {
    if (!dealId) return;

    try {
      const updatedItems = Object.values(editedItems);
      await axios.put(`/deal/deals/${dealId}/navigation`, {
        items: updatedItems
      });
      toast.success("Navigation settings saved");
      await fetchNavigation();
      setEditModalVisible(false);
    } catch (error) {
      console.error('Error saving navigation:', error);
      toast.error("Failed to save navigation settings");
    }
  };

  const resetEditedItems = () => {
    const initialEdits: Record<string, NavItem> = {};
    navItems.forEach(item => {
      initialEdits[item.name] = { ...item };
    });
    setEditedItems(initialEdits);
  };

  const handleEditModalClose = () => {
    setEditModalVisible(false);
    resetEditedItems();
  };

  if (loading) return null;
  if (!dealId) return null;

  return (
    <div className="flex items-center gap-1 mr-4">
      <Button
        variant="ghost"
        size="icon"
        className={cn(
          "h-7 w-7 rounded-full",
          "transition-all hover:-translate-y-0.5 hover:shadow-md",
          "dark:bg-primary/10 dark:hover:bg-primary/20",
          "bg-primary hover:bg-primary/90"
        )}
        onClick={() => setEditModalVisible(true)}
      >
        <Pencil className="h-4 w-4 text-white" />
      </Button>

      <div className="flex gap-2 px-2.5 py-1.5">
        {navItems.filter(item => item.enabled).map((item, index) => (
          <TooltipProvider key={index}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className={cn(
                    "h-9 w-9 rounded-full",
                    "transition-all hover:-translate-y-0.5 hover:shadow-md",
                    "dark:bg-primary/10 dark:hover:bg-primary/20",
                    "bg-primary hover:bg-primary/90"
                  )}
                  onClick={() => handleNavClick(item)}
                >
                  {iconMap[item.icon]}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>{item.name}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ))}
      </div>

      {/* Edit Modal */}
      <Dialog open={editModalVisible} onOpenChange={setEditModalVisible}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Navigation Links</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            {navItems.map((item) => (
              <div key={item.name} className="flex items-center gap-4">
                <div className="flex w-8 justify-center">
                  {iconMap[item.icon]}
                </div>
                <span className="w-24 text-sm font-medium">{item.name}</span>
                <Input
                  placeholder="Enter URL"
                  value={editedItems[item.name]?.link || ''}
                  onChange={(e) => handleEditInputChange(item.name, 'link', e.target.value)}
                  className="flex-1"
                />
                <Switch
                  checked={editedItems[item.name]?.enabled}
                  onCheckedChange={(checked) => handleEditInputChange(item.name, 'enabled', checked)}
                />
              </div>
            ))}
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={handleEditModalClose}>
              Cancel
            </Button>
            <Button onClick={handleSaveAll}>
              Save All
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Quick Add Link Modal */}
      <Dialog open={linkModalVisible} onOpenChange={setLinkModalVisible}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add Link for {selectedNav?.name}</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <Input
              placeholder="Enter URL"
              value={selectedNav?.link || ''}
              onChange={(e) => {
                setSelectedNav(prev => prev ? {
                  ...prev,
                  link: e.target.value
                } : null);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && selectedNav) {
                  handleUpdateLink(selectedNav, selectedNav.link || '');
                }
              }}
            />
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setLinkModalVisible(false);
                setSelectedNav(null);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (selectedNav) {
                  handleUpdateLink(selectedNav, selectedNav.link || '');
                }
              }}
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DealNavBar; 