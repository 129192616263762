import { DealDataConfig as DealDataConfigType } from '@/onboardingflow/types/node_types';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { dealDataConfig } from '@/configs/DealDataConfig';
import { dealSoftwareConfig } from '@/configs/DealSoftwareConfig';
import { PlusCircle, ArrowUp, ArrowDown, Trash2 } from 'lucide-react';

interface DealDataConfigProps {
  config: DealDataConfigType;
  elementId: string;
  onChange: (updates: Partial<DealDataConfigType>) => void;
}

export function DealDataConfig({
  config,
  elementId,
  onChange
}: DealDataConfigProps) {
  // Combine deal data and software configs
  const allFields = [
    ...dealDataConfig.map(field => ({ key: field.key, label: field.label })),
    ...dealSoftwareConfig.map(field => ({ key: `software.${field.key}`, label: `Software: ${field.label}` }))
  ];

  const handleAddField = () => {
    onChange({
      fields: [...(config.fields || []), { key: '', label: '' }]
    });
  };

  const handleRemoveField = (index: number) => {
    const newFields = [...config.fields];
    newFields.splice(index, 1);
    onChange({ fields: newFields });
  };

  const handleFieldChange = (index: number, key: string) => {
    const field = allFields.find(f => f.key === key);
    if (!field) return;

    const newFields = [...config.fields];
    newFields[index] = field;
    onChange({ fields: newFields });
  };

  const handleMoveField = (index: number, direction: 'up' | 'down') => {
    if (
      (direction === 'up' && index === 0) || 
      (direction === 'down' && index === config.fields.length - 1)
    ) return;

    const newFields = [...config.fields];
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    [newFields[index], newFields[newIndex]] = [newFields[newIndex], newFields[index]];
    onChange({ fields: newFields });
  };

  return (
    <div className="space-y-4">
      <div>
        <Label>Title (Optional)</Label>
        <Input
          value={config.title || ''}
          onChange={(e) => onChange({ title: e.target.value })}
          placeholder="Enter section title"
        />
      </div>

      <div>
        <Label>Description (Optional)</Label>
        <Input
          value={config.description || ''}
          onChange={(e) => onChange({ description: e.target.value })}
          placeholder="Enter section description"
        />
      </div>

      <div>
        <Label>Layout</Label>
        <Select
          value={String(config.layout?.columns || 1)}
          onValueChange={(value) => onChange({ 
            layout: { ...config.layout, columns: Number(value) as 1 | 2 | 3 } 
          })}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select layout" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="1">Single Column</SelectItem>
            <SelectItem value="2">Two Columns</SelectItem>
            <SelectItem value="3">Three Columns</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <Label>Deal Data Fields</Label>
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={handleAddField}
          >
            <PlusCircle className="w-4 h-4 mr-2" />
            Add Field
          </Button>
        </div>

        <div className="space-y-2">
          {config.fields?.map((field, index) => (
            <div
              key={index}
              className="flex items-center gap-2 bg-background border rounded-md p-2"
            >
              <div className="flex gap-1">
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  onClick={() => handleMoveField(index, 'up')}
                  disabled={index === 0}
                >
                  <ArrowUp className="w-4 h-4" />
                </Button>
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  onClick={() => handleMoveField(index, 'down')}
                  disabled={index === config.fields.length - 1}
                >
                  <ArrowDown className="w-4 h-4" />
                </Button>
              </div>
              <Select
                value={field.key}
                onValueChange={(value) => handleFieldChange(index, value)}
              >
                <SelectTrigger className="flex-1">
                  <SelectValue placeholder="Select field" />
                </SelectTrigger>
                <SelectContent>
                  {allFields.map((field) => (
                    <SelectItem key={field.key} value={field.key}>
                      {field.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Button
                type="button"
                variant="ghost"
                size="icon"
                onClick={() => handleRemoveField(index)}
              >
                <Trash2 className="w-4 h-4" />
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
} 