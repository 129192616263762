import { useState, useEffect } from 'react'
import { toast } from 'sonner'
import axios from '@/api/axiosConfig'
import { Edit, Trash2, Plus, Lock, Unlock } from 'lucide-react'

import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
} from "@/components/ui/drawer"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { Switch } from "@/components/ui/switch"
import { Badge } from "@/components/ui/badge"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Separator } from "@/components/ui/separator"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"

import UserAvatar from '@/components/user-avatar'

interface ContactInfoEntry {
  id: number
  text: string
  user_id: number
  is_public: boolean
}

interface User {
  user_id: string | number
  name?: string
  surname?: string
  avatar_urls?: {
    medium?: string
    small?: string
  }
  settings?: {
    avatar_color?: string
  }
  status?: string
  initials?: string
}

interface ContactInfoDrawerProps {
  visible: boolean
  onClose: () => void
  contactId: number | null | undefined
  contactInfo: ContactInfoEntry[]
}

const ContactInfoDrawer = ({ visible, onClose, contactId, contactInfo }: ContactInfoDrawerProps) => {
  const [entries, setEntries] = useState<ContactInfoEntry[]>([])
  const [newEntry, setNewEntry] = useState({ text: '', isPublic: true })
  const [editingIndex, setEditingIndex] = useState(-1)
  const [users, setUsers] = useState<Record<number, User>>({})
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [entryToDelete, setEntryToDelete] = useState<number | null>(null)

  useEffect(() => {
    if (visible) {
      setEntries(contactInfo)
      resetStates()
      if (contactInfo && contactInfo.length > 0) {
        fetchUsers(contactInfo.map(entry => entry.user_id))
      }
    }
  }, [visible, contactInfo])

  const resetStates = () => {
    setNewEntry({ text: '', isPublic: true })
    setEditingIndex(-1)
  }

  const fetchUsers = async (userIds: number[]) => {
    try {
      const response = await axios.get('/user/names-and-avatars', {
        params: { user_ids: userIds }
      })
      setUsers(response.data)
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  }

  const getUserInfo = (userId: number) => {
    return users[userId] || {}
  }

  const handleAddEntry = async () => {
    try {
      const response = await axios.post(`/account/contacts/${contactId}/info`, {
        text: newEntry.text,
        is_public: newEntry.isPublic,
      })
      toast.success('Contact info added successfully')
      setNewEntry({ text: '', isPublic: true })
      setEntries(response.data.entries)
      onClose()
    } catch (error) {
      console.error('Error adding contact info:', error)
      toast.error('Failed to add contact info')
    }
  }

  const handleUpdateEntry = async (entry: ContactInfoEntry) => {
    try {
      const response = await axios.put(`/account/contacts/${contactId}/info/${entry.id}`, {
        text: entry.text,
        is_public: entry.is_public,
      })
      toast.success('Contact info updated successfully')
      setEntries(response.data.entries)
      setEditingIndex(-1)
    } catch (error) {
      console.error('Error updating contact info:', error)
      toast.error('Failed to update contact info')
    }
  }

  const handleDeleteEntry = async (entryId: number) => {
    try {
      await axios.delete(`/account/contacts/${contactId}/info/${entryId}`)
      toast.success('Contact info deleted successfully')
      const response = await axios.get(`/account/contacts/${contactId}/info`)
      setEntries(response.data.entries)
    } catch (error) {
      console.error('Error deleting contact info:', error)
      toast.error('Failed to delete contact info')
    }
  }

  const handleClose = () => {
    resetStates()
    onClose()
  }

  return (
    <>
      <Drawer open={visible} onOpenChange={handleClose}>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle>Contact Info</DrawerTitle>
            <DrawerDescription>
              View and manage contact information
            </DrawerDescription>
          </DrawerHeader>
          
          <ScrollArea className="h-[calc(100vh-200px)] px-4">
            <div className="space-y-4">
              {entries.map((entry, index) => {
                const user = getUserInfo(entry.user_id)
                return (
                  <div 
                    key={entry.id}
                    className="flex items-start gap-4 p-4 rounded-lg border bg-card"
                  >
                    <UserAvatar 
                      user={user}
                      size={32}
                      tooltipPlacement="right"
                    />
                    <div className="flex-1 space-y-2">
                      {editingIndex === index ? (
                        <div className="space-y-4">
                          <Textarea
                            value={entry.text}
                            onChange={(e) => {
                              const updatedEntries = [...entries]
                              updatedEntries[index].text = e.target.value
                              setEntries(updatedEntries)
                            }}
                            className="min-h-[80px]"
                          />
                          <div className="flex items-center gap-4">
                            <div className="flex items-center gap-2">
                              <Switch
                                checked={entry.is_public}
                                onCheckedChange={(checked) => {
                                  const updatedEntries = [...entries]
                                  updatedEntries[index].is_public = checked
                                  setEntries(updatedEntries)
                                }}
                              />
                              <span className="text-sm">Public</span>
                            </div>
                            <Button onClick={() => handleUpdateEntry(entry)}>
                              Save
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <p className="text-sm whitespace-pre-wrap">{entry.text}</p>
                          <Badge 
                            variant={entry.is_public ? "default" : "destructive"}
                            className="flex items-center gap-1"
                          >
                            {entry.is_public ? (
                              <Unlock className="h-3 w-3" />
                            ) : (
                              <Lock className="h-3 w-3" />
                            )}
                            {entry.is_public ? 'Public' : 'Private'}
                          </Badge>
                        </>
                      )}
                    </div>
                    <div className="flex gap-2">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => setEditingIndex(index)}
                      >
                        <Edit className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => {
                          setEntryToDelete(entry.id)
                          setDeleteConfirmOpen(true)
                        }}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                )
              })}
            </div>

            <Separator className="my-6" />

            <div className="space-y-4 pb-6">
              <Input
                placeholder="Add new entry"
                value={newEntry.text}
                onChange={(e) => setNewEntry({ ...newEntry, text: e.target.value })}
              />
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2">
                    <Switch
                      checked={newEntry.isPublic}
                      onCheckedChange={(checked) => setNewEntry({ ...newEntry, isPublic: checked })}
                    />
                    <span className="text-sm">Public</span>
                  </div>
                  <Badge 
                    variant={newEntry.isPublic ? "default" : "destructive"}
                    className="flex items-center gap-1"
                  >
                    {newEntry.isPublic ? (
                      <Unlock className="h-3 w-3" />
                    ) : (
                      <Lock className="h-3 w-3" />
                    )}
                    {newEntry.isPublic ? 'Public' : 'Private'}
                  </Badge>
                </div>
                <Button onClick={handleAddEntry} disabled={!newEntry.text.trim()}>
                  <Plus className="h-4 w-4 mr-2" />
                  Add
                </Button>
              </div>
            </div>
          </ScrollArea>
        </DrawerContent>
      </Drawer>

      <AlertDialog open={deleteConfirmOpen} onOpenChange={setDeleteConfirmOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Entry</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this entry? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                if (entryToDelete !== null) {
                  handleDeleteEntry(entryToDelete)
                  setDeleteConfirmOpen(false)
                  setEntryToDelete(null)
                }
              }}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default ContactInfoDrawer 