import { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog'
import { ScrollArea } from '@/components/ui/scroll-area'
import { ArrowRight, Calendar, Clock, MessageSquare, Send, Loader2 } from 'lucide-react'
import axios from '@/api/axiosConfig'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { UserAvatar } from '@/components/user-avatar'
import { Board } from '@/types/custom-board'
import { StoredUser } from '@/types/user'
import { cn } from '@/lib/utils'
import { useUsers } from '@/contexts/UserContext'
import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import { toast } from 'sonner'

dayjs.extend(relativeTime)

interface ActivityChange {
  id: number;
  type: 'progress_status' | 'board_status' | 'assigned_users' | 'work_date' | 'due_date' | 'reply' | 'comment_new' | 'comment_reply' | 'comment_deleted' | 'update';
  old_value: any;
  new_value: any;
  user_id: number;
  timestamp: string;
  task_id: number;
  task_title: string;
  parent_id?: number;
  content?: string;
  data?: {
    id: number;
    content?: string;
    user_id: number;
    parent_id?: number;
    timestamp: string;
    type?: string;
    old_value?: any;
    new_value?: any;
  };
}

interface Activity extends ActivityChange {
  changes?: {
    from: any;
    to: any;
  };
}

interface CustomBoardActivityProps {
  open: boolean
  onClose: () => void
  board: Board | null
  taskId?: number
}

export function CustomBoardActivity({ open, onClose, board, taskId }: CustomBoardActivityProps) {
  const { users } = useUsers()
  const [activities, setActivities] = useState<Activity[]>([])
  const [loading, setLoading] = useState(true)
  const [replyingTo, setReplyingTo] = useState<number | null>(null)
  const [replyContent, setReplyContent] = useState('')
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (open && board) {
      fetchActivities()
    }
  }, [open, board?.id, taskId])

  const fetchActivities = async () => {
    try {
      setLoading(true)
      const endpoint = taskId 
        ? `/custom-board/tasks/${taskId}/activity`
        : `/custom-board/boards/${board?.id}/activity`
      const response = await axios.get(endpoint)
      
      // Sort activities by timestamp in descending order (most recent first)
      const sortedActivities = response.data.sort((a: Activity, b: Activity) => {
        const aTimestamp = a.timestamp || a.data?.timestamp || '';
        const bTimestamp = b.timestamp || b.data?.timestamp || '';
        return new Date(bTimestamp).getTime() - new Date(aTimestamp).getTime();
      });
      
      setActivities(sortedActivities)
    } catch (error) {
      console.error('Error fetching activities:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleReply = async (updateId: number) => {
    if (!replyContent.trim()) return

    try {
      setSubmitting(true)
      const response = await axios.post(`/custom-board/tasks/${taskId}/updates/${updateId}/reply`, {
        content: replyContent.trim()
      })
      
      if (response.status === 200) {
        toast.success('Reply added successfully')
        setReplyContent('')
        setReplyingTo(null)
        fetchActivities() // Refresh activities to show the new reply
      }
    } catch (error) {
      console.error('Error adding reply:', error)
      toast.error('Failed to add reply')
    } finally {
      setSubmitting(false)
    }
  }

  const getStatusColor = (statusId: number | string | null, type: 'progress' | 'board'): string => {
    if (!statusId || !board?.board_config) return '#94a3b8'
    
    const options = type === 'progress' 
      ? board.board_config.progress_status_options 
      : board.board_config.board_status_options

    const status = options?.find(opt => opt.id === Number(statusId))
    return status?.color || '#94a3b8'
  }

  const getStatusLabel = (statusId: number | string | null, type: 'progress' | 'board'): string => {
    if (!statusId || !board?.board_config) return 'None'
    
    const options = type === 'progress' 
      ? board.board_config.progress_status_options 
      : board.board_config.board_status_options

    const status = options?.find(opt => opt.id === Number(statusId))
    return status?.label || 'Unknown'
  }

  const renderActivityContent = (activity: Activity) => {
    // Get the old and new values from either format
    const oldValue = activity.changes?.from ?? activity.old_value
    const newValue = activity.changes?.to ?? activity.new_value

    // For comment activities, check both direct and nested data
    const commentContent = activity.data?.content || activity.content || newValue
    const commentTimestamp = activity.data?.timestamp || activity.timestamp

    switch (activity.type) {
      case 'update':
        if (activity.data?.type === 'progress_status') {
          return (
            <div className="space-y-2 rounded-md bg-muted p-3">
              <div className="flex items-center justify-between">
                <span className="font-medium">Progress Status Changed</span>
                <span className="text-sm text-muted-foreground">
                  {dayjs(activity.timestamp).fromNow()}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span
                  className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                  style={{ backgroundColor: getStatusColor(activity.data.old_value, 'progress') }}
                >
                  {getStatusLabel(activity.data.old_value, 'progress')}
                </span>
                <ArrowRight className="h-4 w-4 text-muted-foreground" />
                <span
                  className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                  style={{ backgroundColor: getStatusColor(activity.data.new_value, 'progress') }}
                >
                  {getStatusLabel(activity.data.new_value, 'progress')}
                </span>
              </div>
            </div>
          )
        }
        return null

      case 'comment_new':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            <div className="flex items-center justify-between">
              <span className="font-medium">New Comment</span>
              <span className="text-sm text-muted-foreground">
                {dayjs(commentTimestamp).fromNow()}
              </span>
            </div>
            <p className="text-sm whitespace-pre-wrap">{commentContent}</p>
          </div>
        )
      case 'comment_reply':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            <div className="flex items-center justify-between">
              <span className="font-medium">Reply</span>
              <span className="text-sm text-muted-foreground">
                {dayjs(activity.timestamp).fromNow()}
              </span>
            </div>
            <p className="text-sm whitespace-pre-wrap">{activity.data?.content || activity.content || newValue}</p>
          </div>
        )
      case 'comment_deleted':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            <div className="flex items-center justify-between">
              <span className="font-medium">Comment Deleted</span>
              <span className="text-sm text-muted-foreground">
                {dayjs(activity.timestamp).fromNow()}
              </span>
            </div>
            <p className="text-sm whitespace-pre-wrap line-through text-muted-foreground">
              {activity.data?.content || oldValue}
            </p>
          </div>
        )
      case 'reply':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            <div className="flex items-center justify-between">
              <span className="font-medium">Reply</span>
              <span className="text-sm text-muted-foreground">
                {dayjs(activity.timestamp).fromNow()}
              </span>
            </div>
            <p className="text-sm whitespace-pre-wrap">{newValue}</p>
          </div>
        )
      case 'progress_status':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            <div className="flex items-center justify-between">
              <span className="font-medium">Progress Status Changed</span>
              <span className="text-sm text-muted-foreground">
                {dayjs(activity.timestamp).fromNow()}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span
                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                style={{ backgroundColor: getStatusColor(oldValue, 'progress') }}
              >
                {getStatusLabel(oldValue, 'progress')}
              </span>
              <ArrowRight className="h-4 w-4 text-muted-foreground" />
              <span
                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                style={{ backgroundColor: getStatusColor(newValue, 'progress') }}
              >
                {getStatusLabel(newValue, 'progress')}
              </span>
            </div>
          </div>
        )
      case 'board_status':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            <div className="flex items-center justify-between">
              <span className="font-medium">Board Status Changed</span>
              <span className="text-sm text-muted-foreground">
                {dayjs(activity.timestamp).fromNow()}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span
                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                style={{ backgroundColor: getStatusColor(oldValue, 'board') }}
              >
                {getStatusLabel(oldValue, 'board')}
              </span>
              <ArrowRight className="h-4 w-4 text-muted-foreground" />
              <span
                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                style={{ backgroundColor: getStatusColor(newValue, 'board') }}
              >
                {getStatusLabel(newValue, 'board')}
              </span>
            </div>
          </div>
        )
      case 'assigned_users':
        const fromUsers = (Array.isArray(oldValue) ? oldValue : [])
          .map(id => users.find(u => u.user_id.toString() === id.toString())?.name)
          .filter(Boolean)
          .join(', ') || 'None'
        const toUsers = (Array.isArray(newValue) ? newValue : [])
          .map(id => users.find(u => u.user_id.toString() === id.toString())?.name)
          .filter(Boolean)
          .join(', ') || 'None'
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            <div className="flex items-center justify-between">
              <span className="font-medium">Assignees Changed</span>
              <span className="text-sm text-muted-foreground">
                {dayjs(activity.timestamp).fromNow()}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="line-through text-muted-foreground">
                {fromUsers}
              </span>
              <ArrowRight className="h-4 w-4 text-muted-foreground" />
              <span>{toUsers}</span>
            </div>
          </div>
        )
      case 'work_date':
      case 'due_date':
        const isWorkDate = activity.type === 'work_date'
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            <div className="flex items-center justify-between">
              <span className="font-medium">
                {isWorkDate ? 'Work Date Updated' : 'Due Date Updated'}
              </span>
              <span className="text-sm text-muted-foreground">
                {dayjs(activity.timestamp).fromNow()}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="line-through text-muted-foreground">
                {oldValue ? dayjs(oldValue).format('DD/MM/YYYY') : 'None'}
              </span>
              <ArrowRight className="h-4 w-4 text-muted-foreground" />
              <span>
                {newValue ? dayjs(newValue).format('DD/MM/YYYY') : 'None'}
              </span>
            </div>
          </div>
        )
      default:
        return null
    }
  }

  const isValidUser = (user: StoredUser | undefined): user is StoredUser => {
    return user !== undefined && 'user_id' in user
  }

  // Group activities by their parent_id
  const groupedActivities = activities.reduce((acc, activity) => {
    if (activity.parent_id) {
      // This is a reply, add it to its parent's replies
      if (!acc[activity.parent_id]) {
        acc[activity.parent_id] = { main: null, replies: [] }
      }
      acc[activity.parent_id].replies.push(activity)
    } else {
      // This is a main activity
      if (!acc[activity.id]) {
        acc[activity.id] = { main: null, replies: [] }
      }
      acc[activity.id].main = activity
    }
    return acc
  }, {} as Record<number, { main: Activity | null, replies: Activity[] }>)

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-h-[90vh] max-w-2xl">
        <DialogHeader>
          <DialogTitle>Recent Activities</DialogTitle>
          <DialogDescription>
            {activities.length} updates in the last 30 days
          </DialogDescription>
        </DialogHeader>

        <ScrollArea className="h-[calc(90vh-160px)] px-4">
          {activities.length > 0 ? (
            <div className="space-y-8 py-4">
              {Object.entries(groupedActivities).map(([id, { main, replies }]) => {
                if (!main) return null
                const user = users.find(u => u.user_id === main.user_id)
                return (
                  <div key={id} className="relative pl-8">
                    <div className="absolute left-0 top-0">
                      {isValidUser(user) && <UserAvatar user={user} className="h-6 w-6" />}
                    </div>
                    <div className="space-y-1">
                      <div className="flex items-center justify-between gap-2">
                        <span className="font-medium">{main.task_title}</span>
                        {user && (
                          <span className="text-sm text-muted-foreground">
                            {user.name} {user.surname}
                          </span>
                        )}
                      </div>
                      {renderActivityContent(main)}
                      
                      {/* Replies section */}
                      <div className="ml-6 mt-2 space-y-3">
                        {replies.map((reply) => {
                          const replyUser = users.find(u => u.user_id === reply.user_id)
                          return (
                            <div key={reply.id} className="relative pl-8">
                              <div className="absolute left-0 top-0">
                                {isValidUser(replyUser) && <UserAvatar user={replyUser} className="h-5 w-5" />}
                              </div>
                              <div className="space-y-1">
                                {replyUser && (
                                  <span className="text-sm text-muted-foreground">
                                    {replyUser.name} {replyUser.surname}
                                  </span>
                                )}
                                {renderActivityContent(reply)}
                              </div>
                            </div>
                          )
                        })}
                      </div>

                      {/* Reply button and form */}
                      {taskId && (
                        <div className="ml-6 mt-2">
                          {replyingTo === main.id ? (
                            <div className="space-y-2">
                              <Textarea
                                placeholder="Write a reply..."
                                value={replyContent}
                                onChange={(e) => setReplyContent(e.target.value)}
                                className="min-h-[80px] resize-none"
                              />
                              <div className="flex justify-end gap-2">
                                <Button
                                  variant="outline"
                                  onClick={() => {
                                    setReplyingTo(null)
                                    setReplyContent('')
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => handleReply(main.id)}
                                  disabled={!replyContent.trim() || submitting}
                                >
                                  {submitting ? (
                                    <>
                                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                      Sending...
                                    </>
                                  ) : (
                                    <>
                                      <Send className="mr-2 h-4 w-4" />
                                      Send Reply
                                    </>
                                  )}
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => setReplyingTo(main.id)}
                              className="text-muted-foreground hover:text-foreground"
                            >
                              <MessageSquare className="mr-2 h-4 w-4" />
                              Reply
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="flex h-[400px] items-center justify-center">
              <p className="text-muted-foreground">No recent activities found</p>
            </div>
          )}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
