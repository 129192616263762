import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogDescription,
  DialogBody,
  DialogFooter
} from '@/components/ui/dialog'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Textarea } from '@/components/ui/textarea'
import { Send, Trash2, Loader2, ChevronDown, ChevronUp, Reply } from 'lucide-react'
import axios from '@/api/axiosConfig'
import dayjs from 'dayjs'
import { UserAvatar } from '@/components/user-avatar'
import { StoredUser } from '@/types/user'
import { CustomTask } from '@/types/custom-task'
import { toast } from 'sonner'

interface CustomTaskNotesProps {
  visible: boolean
  onClose: (refresh?: boolean) => void
  task: CustomTask
  users: StoredUser[]
}

interface Comment {
  id: number
  user_id: number
  content: string
  timestamp: string
  parent_id?: number
}

export function CustomTaskNotes({ visible, onClose, task, users }: CustomTaskNotesProps) {
  const [newComment, setNewComment] = useState('')
  const [loading, setLoading] = useState(false)
  const [replyTo, setReplyTo] = useState<number | null>(null)
  const [expandedThreads, setExpandedThreads] = useState<number[]>([])

  const handleSubmit = async () => {
    if (!newComment.trim()) return

    setLoading(true)
    try {
      const response = await axios.post(`/custom-board/tasks/${task.id}/comments`, {
        content: newComment.trim(),
        parent_id: replyTo
      })

      if (response.status === 200) {
        toast.success(replyTo ? 'Reply added successfully' : 'Comment added successfully')
        setNewComment('')
        setReplyTo(null)
        onClose(true) // Refresh the task data but keep modal open
      }
    } catch (error) {
      console.error('Error adding comment:', error)
      toast.error('Failed to add comment')
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (commentId: number) => {
    try {
      const response = await axios.delete(`/custom-board/tasks/${task.id}/comments/${commentId}`)
      if (response.status === 200) {
        toast.success('Comment deleted successfully')
        onClose(true) // Refresh the task data but keep modal open
      }
    } catch (error) {
      console.error('Error deleting comment:', error)
      toast.error('Failed to delete comment')
    }
  }

  const toggleThread = (commentId: number) => {
    setExpandedThreads(prev => 
      prev.includes(commentId) 
        ? prev.filter(id => id !== commentId)
        : [...prev, commentId]
    )
  }

  // Group comments by parent_id
  const groupComments = (comments: Comment[] = []) => {
    const threads: { [key: number]: Comment[] } = {}
    const mainComments: Comment[] = []

    comments.forEach(comment => {
      if (comment.parent_id) {
        if (!threads[comment.parent_id]) {
          threads[comment.parent_id] = []
        }
        threads[comment.parent_id].push(comment)
      } else {
        mainComments.push(comment)
      }
    })

    return { mainComments, threads }
  }

  const { mainComments, threads } = groupComments(task?.comments)

  const renderComment = (comment: Comment, isReply: boolean = false) => {
    const user = users.find(u => u.user_id === comment.user_id)
    const hasReplies = threads[comment.id]?.length > 0
    const isExpanded = expandedThreads.includes(comment.id)
    const replyCount = threads[comment.id]?.length || 0

    return (
      <div key={comment.id} className={`flex gap-2 relative group ${isReply ? 'ml-8 mt-2' : ''}`}>
        <UserAvatar 
          user={user || { 
            name: 'Unknown',
            surname: 'User',
            user_id: comment.user_id,
          }} 
          size={32} 
        />
        <div className="flex-1 flex flex-col gap-1 ml-2 pr-8">
          <div className="flex justify-between items-start">
            <p className="text-sm font-medium">
              {user ? `${user.name} ${user.surname}` : `User ${comment.user_id}`}
            </p>
            <div className="flex gap-2">
              {!isReply && (
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setReplyTo(replyTo === comment.id ? null : comment.id)}
                  className="opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <Reply className="h-4 w-4" />
                </Button>
              )}
              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleDelete(comment.id)}
                className="opacity-0 group-hover:opacity-100 transition-opacity"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          </div>
          <p className="text-sm whitespace-pre-wrap">{comment.content}</p>
          <p className="text-xs text-muted-foreground">
            {dayjs(comment.timestamp).format('YYYY-MM-DD HH:mm')}
          </p>

          {!isReply && hasReplies && (
            <div className="mt-2">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => toggleThread(comment.id)}
                className="h-auto py-1 px-2 text-xs"
              >
                {isExpanded ? (
                  <>
                    <ChevronUp className="h-3 w-3 mr-1" />
                    Hide {replyCount} {replyCount === 1 ? 'reply' : 'replies'}
                  </>
                ) : (
                  <>
                    <ChevronDown className="h-3 w-3 mr-1" />
                    Show {replyCount} {replyCount === 1 ? 'reply' : 'replies'}
                  </>
                )}
              </Button>
            </div>
          )}

          {!isReply && isExpanded && threads[comment.id]?.map(reply => 
            renderComment(reply, true)
          )}

          {replyTo === comment.id && (
            <div className="mt-2 ml-8">
              <Textarea
                placeholder="Write a reply..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                className="resize-none"
                rows={2}
              />
              <div className="flex justify-end gap-2 mt-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setReplyTo(null)}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  onClick={handleSubmit}
                  disabled={!newComment.trim() || loading}
                >
                  {loading ? (
                    <>
                      <Loader2 className="mr-2 h-3 w-3 animate-spin" />
                      Posting...
                    </>
                  ) : (
                    <>
                      <Send className="mr-2 h-3 w-3" />
                      Reply
                    </>
                  )}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <Dialog open={visible} onOpenChange={() => onClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Task Notes & Comments</DialogTitle>
          <DialogDescription className="sr-only">
            Add or view comments for this task
          </DialogDescription>
        </DialogHeader>
        
        <DialogBody maxBodyHeight>
          <div className="flex flex-col gap-5">
            {(!mainComments || mainComments.length === 0) ? (
              <div className="flex justify-center items-center min-h-[100px] rounded-md">
                <p className="text-muted-foreground">No comments yet</p>
              </div>
            ) : (
              mainComments.map(comment => renderComment(comment))
            )}
          </div>
        </DialogBody>

        <DialogFooter>
          {!replyTo && (
            <div className="flex flex-col gap-2 w-full">
              <Textarea
                placeholder="Write a comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                className="resize-none"
                rows={2}
              />
              <Button
                onClick={handleSubmit}
                disabled={!newComment.trim() || loading}
                className="self-end"
              >
                {loading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Posting...
                  </>
                ) : (
                  <>
                    <Send className="mr-2 h-4 w-4" />
                    Post
                  </>
                )}
              </Button>
            </div>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
