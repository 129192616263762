import * as React from "react"
import { Label, Pie, PieChart } from "recharts"
import { useResizeObserver } from "@/hooks/use-resize-observer"
import { cn } from "@/lib/utils"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui"

interface PieDonutProps {
  data: Array<{
    name: string
    value: number
    fill?: string
    [key: string]: any
  }>
  config: ChartConfig
  title?: string
  description?: string
  footerContent?: React.ReactNode
  centerLabel?: {
    value: number
    label: string
  }
  className?: string
  innerRadius?: number
  outerRadius?: number
  centerLabelFontSize?: string
  variant?: "card" | "plain"
  colorMode?: "theme" | "multi"
}

export function PieDonutChart({
  data,
  config,
  title,
  description,
  footerContent,
  centerLabel,
  className,
  innerRadius = 60,
  outerRadius = 80,
  centerLabelFontSize = "text-3xl",
  variant = "card",
  colorMode = "theme"
}: PieDonutProps) {
  const [containerRef, { width }] = useResizeObserver<HTMLDivElement>()
  
  // Transform data to use appropriate colors
  const dataWithColors = data.map((item, index) => ({
    ...item,
    fill: item.fill || `hsl(var(--chart${colorMode === "theme" ? 
      `-${(index % 9) + 1}` : 
      `-multi-${(index % 5) + 1}`
    }))`
  }))

  // Calculate dynamic radius based on container width
  const dynamicInnerRadius = Math.max(Math.min(width / 4, innerRadius), 40)
  const dynamicOuterRadius = Math.max(Math.min(width / 3, outerRadius), 60)

  // Calculate dynamic font sizes based on container width
  const getValueFontSize = () => {
    if (width < 200) return "text-lg"
    if (width < 300) return "text-xl"
    if (width < 400) return "text-2xl"
    return centerLabelFontSize
  }

  const getLabelFontSize = () => {
    if (width < 200) return "text-xs"
    if (width < 300) return "text-sm"
    return "text-base"
  }

  const ChartContent = (
    <div ref={containerRef} className="flex-1 pb-0">
      <ChartContainer
        config={config}
        className="mx-auto aspect-square max-h-[250px]"
      >
        <PieChart>
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent hideLabel />}
          />
          <Pie
            data={dataWithColors}
            dataKey="value"
            nameKey="name"
            innerRadius={dynamicInnerRadius}
            outerRadius={dynamicOuterRadius}
            strokeWidth={5}
          >
            {centerLabel && (
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={(viewBox?.cy ?? 0) + 24}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className={`fill-foreground ${getValueFontSize()} font-bold`}
                        >
                          {centerLabel.value.toLocaleString()}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox?.cy ?? 0) + 24}
                          className={`fill-muted-foreground ${getLabelFontSize()}`}
                        >
                          {centerLabel.label}
                        </tspan>
                      </text>
                    )
                  }
                }}
              />
            )}
          </Pie>
        </PieChart>
      </ChartContainer>
    </div>
  )

  if (variant === "plain") {
    return ChartContent
  }

  return (
    <Card className={cn("flex flex-col", className)}>
      {(title || description) && (
        <CardHeader className="items-center pb-0">
          {title && <CardTitle>{title}</CardTitle>}
          {description && <CardDescription>{description}</CardDescription>}
        </CardHeader>
      )}
      <CardContent className="flex-1">
        {ChartContent}
      </CardContent>
      {footerContent && (
        <CardFooter className="flex-col gap-2 text-sm">
          {footerContent}
        </CardFooter>
      )}
    </Card>
  )
}
