// frontend/src/components/DealFilter.tsx
import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogDescription } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { X } from "lucide-react"
import { Badge } from "@/components/ui/badge"
import { DatePicker } from "@/components/ui/date/date-picker"
import type { Deal } from '@/types/customers'
import { cn } from "@/lib/utils"
import { dealDataConfig } from '@/configs/DealDataConfig'
import { dealSoftwareConfig } from '@/configs/DealSoftwareConfig'
import { ScrollArea } from "@/components/ui/scroll-area"
import { Command, CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"

type FilterType = 
  | 'status' 
  | 'value' 
  | 'createdAt' 
  | 'products' 
  | 'city'
  | 'regnskabsprogram'
  | 'virksomhedsform'
  | 'hvem_er_ansvarlig_for_årsregnskab'
  | 'ansvarlig_bogholder'
  | 'bogføringsfrekvens'
  | 'is_holding'
  | 'lønprogram'
  | 'lagerstyring'
  | 'kreditorstyring'
  | 'betalingsgateway'
  | 'description'

interface DealFilterProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  onFiltersChange: (filters: DealFilters, filterLogic: 'OR' | 'AND') => void
  deals: Deal[]
  currentFilters: DealFilters
}

export interface DealFilters {
  status: string[]
  value: {
    min?: number
    max?: number
  }
  createdAt: {
    from?: Date
    to?: Date
  }
  products: string[]
  city: string[]
  regnskabsprogram: string[]
  virksomhedsform: string[]
  lønprogram: string[]
  lagerstyring: string[]
  kreditorstyring: string[]
  betalingsgateway: string[]
  is_holding: ('true' | 'false' | 'null')[]
  hvem_er_ansvarlig_for_årsregnskab: string[]
  bogføringsfrekvens: string[]
  ansvarlig_bogholder: string[]
  description: string[]
}

export function DealFilter({ open, onOpenChange, onFiltersChange, deals, currentFilters }: DealFilterProps) {
  const [statusFilter, setStatusFilter] = useState<string[]>(currentFilters.status)
  const [valueFilter, setValueFilter] = useState(currentFilters.value)
  const [createdAtFilter, setCreatedAtFilter] = useState(currentFilters.createdAt)
  const [productsFilter, setProductsFilter] = useState<string[]>(currentFilters.products)
  const [cityFilter, setCityFilter] = useState<string[]>(currentFilters.city)
  const [regnskabsprogramFilter, setRegnskabsprogramFilter] = useState<string[]>(currentFilters.regnskabsprogram || [])
  const [virksomhedsformFilter, setVirksomhedsformFilter] = useState<string[]>(currentFilters.virksomhedsform || [])
  const [lønprogramFilter, setLønprogramFilter] = useState<string[]>(currentFilters.lønprogram || [])
  const [lagerstyringFilter, setLagerstyringFilter] = useState<string[]>(currentFilters.lagerstyring || [])
  const [kreditorstyringFilter, setKreditorstyringFilter] = useState<string[]>(currentFilters.kreditorstyring || [])
  const [betalingsgatewayFilter, setBetalingsgatewayFilter] = useState<string[]>(currentFilters.betalingsgateway || [])
  const [isHoldingFilter, setIsHoldingFilter] = useState<('true' | 'false' | 'null')[]>(currentFilters.is_holding || [])
  const [hvemErAnsvarligFilter, setHvemErAnsvarligFilter] = useState<string[]>(currentFilters.hvem_er_ansvarlig_for_årsregnskab || [])
  const [bogføringsfrekvensFilter, setBogføringsfrekvensFilter] = useState<string[]>(currentFilters.bogføringsfrekvens || [])
  const [ansvarligBogholderFilter, setAnsvarligBogholderFilter] = useState<string[]>(currentFilters.ansvarlig_bogholder || [])
  const [descriptionFilter, setDescriptionFilter] = useState<string[]>(currentFilters.description || [])
  const [filterLogic, setFilterLogic] = useState<'OR' | 'AND'>('OR')

  // Get unique values for options
  const uniqueStatuses = Array.from(new Set(deals.map(deal => deal.data.status)))
  const uniqueProducts = Array.from(new Set(deals.flatMap(deal => 
    deal.products.map(product => product.name)
  )))
  const uniqueCities = Array.from(new Set(deals.map(deal => deal.city).filter(Boolean)))
  const uniqueDescriptions = Array.from(new Set(deals.map(deal => deal.description).filter(Boolean)))

  const filterSections = [
    {
      id: 'status',
      label: 'Status',
      isActive: statusFilter.length > 0,
      component: (
        <div className="space-y-2">
          <Select
            value=""
            onValueChange={(value) => {
              if (!statusFilter.includes(value)) {
                setStatusFilter([...statusFilter, value])
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select status" />
            </SelectTrigger>
            <SelectContent>
              {uniqueStatuses
                .filter(status => !statusFilter.includes(status))
                .map(status => (
                  <SelectItem key={status} value={status}>
                    {status}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          {statusFilter.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {statusFilter.map(value => (
                <Badge
                  key={value}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {value}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0"
                    onClick={() => setStatusFilter(statusFilter.filter(s => s !== value))}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              ))}
            </div>
          )}
        </div>
      )
    },
    {
      id: 'value',
      label: 'Deal Value',
      isActive: valueFilter.min !== undefined || valueFilter.max !== undefined,
      component: (
        <div className="flex gap-2">
          <input
            type="number"
            placeholder="Min value"
            className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
            value={valueFilter.min || ''}
            onChange={(e) => setValueFilter({ ...valueFilter, min: Number(e.target.value) })}
          />
          <input
            type="number"
            placeholder="Max value"
            className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
            value={valueFilter.max || ''}
            onChange={(e) => setValueFilter({ ...valueFilter, max: Number(e.target.value) })}
          />
        </div>
      )
    },
    {
      id: 'createdAt',
      label: 'Created Date',
      isActive: createdAtFilter.from !== undefined || createdAtFilter.to !== undefined,
      component: (
        <div className="flex gap-2">
          <div className="grid gap-2 flex-1">
            <label className="text-sm text-muted-foreground">From</label>
            <DatePicker
              date={createdAtFilter.from}
              onSelect={(date) => setCreatedAtFilter({ ...createdAtFilter, from: date })}
              variant="short"
            />
          </div>
          <div className="grid gap-2 flex-1">
            <label className="text-sm text-muted-foreground">To</label>
            <DatePicker
              date={createdAtFilter.to}
              onSelect={(date) => setCreatedAtFilter({ ...createdAtFilter, to: date })}
              variant="short"
            />
          </div>
        </div>
      )
    },
    {
      id: 'products',
      label: 'Products',
      isActive: productsFilter.length > 0,
      component: (
        <div className="space-y-2">
          <Select
            value=""
            onValueChange={(value) => {
              if (!productsFilter.includes(value)) {
                setProductsFilter([...productsFilter, value])
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select products" />
            </SelectTrigger>
            <SelectContent>
              {uniqueProducts
                .filter(product => !productsFilter.includes(product))
                .map(product => (
                  <SelectItem key={product} value={product}>
                    {product}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          {productsFilter.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {productsFilter.map(value => (
                <Badge
                  key={value}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {value}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0"
                    onClick={() => setProductsFilter(productsFilter.filter(p => p !== value))}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              ))}
            </div>
          )}
        </div>
      )
    },
    {
      id: 'city',
      label: 'City',
      isActive: cityFilter.length > 0,
      component: (
        <div className="space-y-2">
          <Select
            value=""
            onValueChange={(value) => {
              if (!cityFilter.includes(value)) {
                setCityFilter([...cityFilter, value])
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select city" />
            </SelectTrigger>
            <SelectContent>
              {uniqueCities
                .filter(city => !cityFilter.includes(city))
                .map(city => (
                  <SelectItem key={city} value={city}>
                    {city}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          {cityFilter.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {cityFilter.map(value => (
                <Badge
                  key={value}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {value}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0"
                    onClick={() => setCityFilter(cityFilter.filter(c => c !== value))}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              ))}
            </div>
          )}
        </div>
      )
    },
    {
      id: 'regnskabsprogram',
      label: 'Regnskabsprogram',
      isActive: regnskabsprogramFilter.length > 0,
      component: (
        <div className="space-y-2">
          <Select
            value=""
            onValueChange={(value) => {
              if (!regnskabsprogramFilter.includes(value)) {
                setRegnskabsprogramFilter([...regnskabsprogramFilter, value])
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select regnskabsprogram" />
            </SelectTrigger>
            <SelectContent>
              {dealDataConfig
                .find(config => config.key === 'data.regnskabsprogram')
                ?.options
                ?.filter(option => !regnskabsprogramFilter.includes(option.value))
                .map(option => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          {regnskabsprogramFilter.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {regnskabsprogramFilter.map(value => (
                <Badge
                  key={value}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {value}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0"
                    onClick={() => setRegnskabsprogramFilter(regnskabsprogramFilter.filter(v => v !== value))}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              ))}
            </div>
          )}
        </div>
      )
    },
    {
      id: 'virksomhedsform',
      label: 'Virksomhedsform',
      isActive: virksomhedsformFilter.length > 0,
      component: (
        <div className="space-y-2">
          <Select
            value=""
            onValueChange={(value) => {
              if (!virksomhedsformFilter.includes(value)) {
                setVirksomhedsformFilter([...virksomhedsformFilter, value])
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select virksomhedsform" />
            </SelectTrigger>
            <SelectContent>
              {dealDataConfig
                .find(config => config.key === 'data.virksomhedsform')
                ?.options
                ?.filter(option => !virksomhedsformFilter.includes(option.value))
                .map(option => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          {virksomhedsformFilter.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {virksomhedsformFilter.map(value => (
                <Badge
                  key={value}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {value}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0"
                    onClick={() => setVirksomhedsformFilter(virksomhedsformFilter.filter(v => v !== value))}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              ))}
            </div>
          )}
        </div>
      )
    },
    {
      id: 'lønprogram',
      label: 'Lønprogram',
      isActive: lønprogramFilter.length > 0,
      component: (
        <div className="space-y-2">
          <Select
            value=""
            onValueChange={(value) => {
              if (!lønprogramFilter.includes(value)) {
                setLønprogramFilter([...lønprogramFilter, value])
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select lønprogram" />
            </SelectTrigger>
            <SelectContent>
              {dealSoftwareConfig
                .find(config => config.key === 'lønprogram')
                ?.options
                ?.filter(option => !lønprogramFilter.includes(option.value))
                .map(option => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          {lønprogramFilter.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {lønprogramFilter.map(value => (
                <Badge
                  key={value}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {value}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0"
                    onClick={() => setLønprogramFilter(lønprogramFilter.filter(l => l !== value))}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              ))}
            </div>
          )}
        </div>
      )
    },
    {
      id: 'lagerstyring',
      label: 'Lagerstyring',
      isActive: lagerstyringFilter.length > 0,
      component: (
        <div className="space-y-2">
          <Select
            value=""
            onValueChange={(value) => {
              if (!lagerstyringFilter.includes(value)) {
                setLagerstyringFilter([...lagerstyringFilter, value])
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select lagerstyring" />
            </SelectTrigger>
            <SelectContent>
              {dealSoftwareConfig
                .find(config => config.key === 'lagerstyring')
                ?.options
                ?.filter(option => !lagerstyringFilter.includes(option.value))
                .map(option => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          {lagerstyringFilter.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {lagerstyringFilter.map(value => (
                <Badge
                  key={value}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {value}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0"
                    onClick={() => setLagerstyringFilter(lagerstyringFilter.filter(l => l !== value))}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              ))}
            </div>
          )}
        </div>
      )
    },
    {
      id: 'kreditorstyring',
      label: 'Kreditorstyring',
      isActive: kreditorstyringFilter.length > 0,
      component: (
        <div className="space-y-2">
          <Select
            value=""
            onValueChange={(value) => {
              if (!kreditorstyringFilter.includes(value)) {
                setKreditorstyringFilter([...kreditorstyringFilter, value])
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select kreditorstyring" />
            </SelectTrigger>
            <SelectContent>
              {dealSoftwareConfig
                .find(config => config.key === 'kreditorstyring')
                ?.options
                ?.filter(option => !kreditorstyringFilter.includes(option.value))
                .map(option => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          {kreditorstyringFilter.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {kreditorstyringFilter.map(value => (
                <Badge
                  key={value}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {value}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0"
                    onClick={() => setKreditorstyringFilter(kreditorstyringFilter.filter(k => k !== value))}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              ))}
            </div>
          )}
        </div>
      )
    },
    {
      id: 'betalingsgateway',
      label: 'Betalingsgateway',
      isActive: betalingsgatewayFilter.length > 0,
      component: (
        <div className="space-y-2">
          <Select
            value=""
            onValueChange={(value) => {
              if (!betalingsgatewayFilter.includes(value)) {
                setBetalingsgatewayFilter([...betalingsgatewayFilter, value])
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select betalingsgateway" />
            </SelectTrigger>
            <SelectContent>
              {dealSoftwareConfig
                .find(config => config.key === 'betalingsgateway')
                ?.options
                ?.filter(option => !betalingsgatewayFilter.includes(option.value))
                .map(option => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          {betalingsgatewayFilter.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {betalingsgatewayFilter.map(value => (
                <Badge
                  key={value}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {value}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0"
                    onClick={() => setBetalingsgatewayFilter(betalingsgatewayFilter.filter(b => b !== value))}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              ))}
            </div>
          )}
        </div>
      )
    },
    {
      id: 'is_holding',
      label: 'Is Holding',
      isActive: isHoldingFilter.length > 0,
      component: (
        <div className="space-y-2">
          <Select
            value=""
            onValueChange={(value: 'true' | 'false' | 'null') => {
              if (!isHoldingFilter.includes(value)) {
                setIsHoldingFilter([...isHoldingFilter, value])
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select holding status" />
            </SelectTrigger>
            <SelectContent>
              {[
                { value: 'true', label: 'Yes' },
                { value: 'false', label: 'No' },
                { value: 'null', label: 'Not Set' }
              ]
                .filter(option => !isHoldingFilter.includes(option.value as 'true' | 'false' | 'null'))
                .map(option => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          {isHoldingFilter.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {isHoldingFilter.map(value => (
                <Badge
                  key={value}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {value === 'true' ? 'Yes' : value === 'false' ? 'No' : 'Not Set'}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0"
                    onClick={() => setIsHoldingFilter(isHoldingFilter.filter(v => v !== value))}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              ))}
            </div>
          )}
        </div>
      )
    },
    {
      id: 'hvem_er_ansvarlig_for_årsregnskab',
      label: 'Ansvarlig for årsregnskab',
      isActive: hvemErAnsvarligFilter.length > 0,
      component: (
        <div className="space-y-2">
          <Select
            value=""
            onValueChange={(value) => {
              if (!hvemErAnsvarligFilter.includes(value)) {
                setHvemErAnsvarligFilter([...hvemErAnsvarligFilter, value])
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select ansvarlig" />
            </SelectTrigger>
            <SelectContent>
              {dealDataConfig
                .find(config => config.key === 'data.hvem_er_ansvarlig_for_årsregnskab')
                ?.options
                ?.filter(option => !hvemErAnsvarligFilter.includes(option.value))
                .map(option => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          {hvemErAnsvarligFilter.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {hvemErAnsvarligFilter.map(value => (
                <Badge
                  key={value}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {value}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0"
                    onClick={() => setHvemErAnsvarligFilter(hvemErAnsvarligFilter.filter(v => v !== value))}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              ))}
            </div>
          )}
        </div>
      )
    },
    {
      id: 'bogføringsfrekvens',
      label: 'Bogføringsfrekvens',
      isActive: bogføringsfrekvensFilter.length > 0,
      component: (
        <div className="space-y-2">
          <Select
            value=""
            onValueChange={(value) => {
              if (!bogføringsfrekvensFilter.includes(value)) {
                setBogføringsfrekvensFilter([...bogføringsfrekvensFilter, value])
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select frekvens" />
            </SelectTrigger>
            <SelectContent>
              {dealDataConfig
                .find(config => config.key === 'data.bogføringsfrekvens')
                ?.options
                ?.filter(option => !bogføringsfrekvensFilter.includes(option.value))
                .map(option => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          {bogføringsfrekvensFilter.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {bogføringsfrekvensFilter.map(value => (
                <Badge
                  key={value}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {value}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0"
                    onClick={() => setBogføringsfrekvensFilter(bogføringsfrekvensFilter.filter(v => v !== value))}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              ))}
            </div>
          )}
        </div>
      )
    },
    {
      id: 'ansvarlig_bogholder',
      label: 'Ansvarlig bogholder',
      isActive: ansvarligBogholderFilter.length > 0,
      component: (
        <div className="space-y-2">
          <Select
            value=""
            onValueChange={(value) => {
              if (!ansvarligBogholderFilter.includes(value)) {
                setAnsvarligBogholderFilter([...ansvarligBogholderFilter, value])
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select bogholder" />
            </SelectTrigger>
            <SelectContent>
              {dealDataConfig
                .find(config => config.key === 'data.ansvarlig_bogholder')
                ?.options
                ?.filter(option => !ansvarligBogholderFilter.includes(option.value))
                .map(option => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          {ansvarligBogholderFilter.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {ansvarligBogholderFilter.map(value => (
                <Badge
                  key={value}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {value}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0"
                    onClick={() => setAnsvarligBogholderFilter(ansvarligBogholderFilter.filter(v => v !== value))}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              ))}
            </div>
          )}
        </div>
      )
    },
    {
      id: 'description',
      label: 'Description',
      isActive: descriptionFilter.length > 0,
      component: (
        <div className="space-y-2">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className="w-full justify-start text-left font-normal"
              >
                <span className="text-muted-foreground">
                  {descriptionFilter.length > 0
                    ? `${descriptionFilter.length} selected`
                    : "Select description"}
                </span>
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[400px] p-0" align="start">
              <Command>
                <CommandInput placeholder="Search description..." />
                <CommandList>
                  <CommandEmpty>No description found.</CommandEmpty>
                  <CommandGroup>
                    {uniqueDescriptions
                      .filter(description => !descriptionFilter.includes(description))
                      .map(description => (
                        <CommandItem
                          key={description}
                          value={description}
                          onSelect={() => {
                            if (!descriptionFilter.includes(description)) {
                              setDescriptionFilter([...descriptionFilter, description])
                            }
                          }}
                        >
                          {description}
                        </CommandItem>
                      ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          {descriptionFilter.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {descriptionFilter.map(value => (
                <Badge
                  key={value}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {value}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0"
                    onClick={() => setDescriptionFilter(descriptionFilter.filter(v => v !== value))}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              ))}
            </div>
          )}
        </div>
      )
    }
  ]

  const resetFilters = () => {
    setStatusFilter([])
    setValueFilter({})
    setCreatedAtFilter({})
    setProductsFilter([])
    setCityFilter([])
    setRegnskabsprogramFilter([])
    setVirksomhedsformFilter([])
    setLønprogramFilter([])
    setLagerstyringFilter([])
    setKreditorstyringFilter([])
    setBetalingsgatewayFilter([])
    setIsHoldingFilter([])
    setHvemErAnsvarligFilter([])
    setBogføringsfrekvensFilter([])
    setAnsvarligBogholderFilter([])
    setDescriptionFilter([])
  }

  // Update parent component when filters change
  useEffect(() => {
    onFiltersChange({
      status: statusFilter,
      value: valueFilter,
      createdAt: createdAtFilter,
      products: productsFilter,
      city: cityFilter,
      regnskabsprogram: regnskabsprogramFilter,
      virksomhedsform: virksomhedsformFilter,
      lønprogram: lønprogramFilter,
      lagerstyring: lagerstyringFilter,
      kreditorstyring: kreditorstyringFilter,
      betalingsgateway: betalingsgatewayFilter,
      is_holding: isHoldingFilter,
      hvem_er_ansvarlig_for_årsregnskab: hvemErAnsvarligFilter,
      bogføringsfrekvens: bogføringsfrekvensFilter,
      ansvarlig_bogholder: ansvarligBogholderFilter,
      description: descriptionFilter,
    }, filterLogic)
  }, [
    statusFilter,
    valueFilter,
    createdAtFilter,
    productsFilter,
    cityFilter,
    regnskabsprogramFilter,
    virksomhedsformFilter,
    lønprogramFilter,
    lagerstyringFilter,
    kreditorstyringFilter,
    betalingsgatewayFilter,
    isHoldingFilter,
    hvemErAnsvarligFilter,
    bogføringsfrekvensFilter,
    ansvarligBogholderFilter,
    descriptionFilter,
    filterLogic,
  ])

  // Helper function to check if a filter section is active
  const isFilterActive = (filterId: FilterType) => {
    switch (filterId) {
      case 'status':
        return currentFilters.status.length > 0;
      case 'value':
        return currentFilters.value.min !== undefined || currentFilters.value.max !== undefined;
      case 'createdAt':
        return currentFilters.createdAt.from !== undefined || currentFilters.createdAt.to !== undefined;
      case 'products':
        return currentFilters.products.length > 0;
      case 'city':
        return currentFilters.city.length > 0;
      case 'regnskabsprogram':
        return currentFilters.regnskabsprogram.length > 0;
      case 'virksomhedsform':
        return currentFilters.virksomhedsform.length > 0;
      case 'lønprogram':
        return currentFilters.lønprogram.length > 0;
      case 'lagerstyring':
        return currentFilters.lagerstyring.length > 0;
      case 'kreditorstyring':
        return currentFilters.kreditorstyring.length > 0;
      case 'betalingsgateway':
        return currentFilters.betalingsgateway.length > 0;
      case 'is_holding':
        return currentFilters.is_holding.length > 0;
      case 'hvem_er_ansvarlig_for_årsregnskab':
        return currentFilters.hvem_er_ansvarlig_for_årsregnskab.length > 0;
      case 'bogføringsfrekvens':
        return currentFilters.bogføringsfrekvens.length > 0;
      case 'ansvarlig_bogholder':
        return currentFilters.ansvarlig_bogholder.length > 0;
      case 'description':
        return currentFilters.description.length > 0;
      default:
        return false;
    }
  };

  useEffect(() => {
    setStatusFilter(currentFilters.status)
    setValueFilter(currentFilters.value)
    setCreatedAtFilter(currentFilters.createdAt)
    setProductsFilter(currentFilters.products)
    setCityFilter(currentFilters.city)
    setRegnskabsprogramFilter(currentFilters.regnskabsprogram || [])
    setVirksomhedsformFilter(currentFilters.virksomhedsform || [])
    setLønprogramFilter(currentFilters.lønprogram || [])
    setLagerstyringFilter(currentFilters.lagerstyring || [])
    setKreditorstyringFilter(currentFilters.kreditorstyring || [])
    setBetalingsgatewayFilter(currentFilters.betalingsgateway || [])
    setIsHoldingFilter(currentFilters.is_holding || [])
    setHvemErAnsvarligFilter(currentFilters.hvem_er_ansvarlig_for_årsregnskab || [])
    setBogføringsfrekvensFilter(currentFilters.bogføringsfrekvens || [])
    setAnsvarligBogholderFilter(currentFilters.ansvarlig_bogholder || [])
    setDescriptionFilter(currentFilters.description)
  }, [currentFilters])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-3xl max-h-[90vh]">
        <DialogHeader>
          <DialogTitle>Filter Deals</DialogTitle>
          <DialogDescription className="sr-only">
            Filter and search through deals
          </DialogDescription>
        </DialogHeader>

        <div className="flex items-center gap-2 mb-4">
          <span className="text-sm font-medium">Filter Logic:</span>
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              const newLogic = filterLogic === 'OR' ? 'AND' : 'OR'
              setFilterLogic(newLogic)
              onFiltersChange(currentFilters, newLogic)
            }}
          >
            {filterLogic}
          </Button>
        </div>

        <ScrollArea className="h-[60vh] pr-4">
          <div className="space-y-6">
            {filterSections.map(section => (
              <div key={section.id} 
                className={cn(
                  "space-y-2 p-4 rounded-lg border",
                  isFilterActive(section.id as FilterType) ? "border-primary" : "border-input"
                )}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <label className="text-sm font-medium">
                      {section.label}
                    </label>
                    {isFilterActive(section.id as FilterType) && (
                      <Badge variant="secondary" className="text-xs">Active</Badge>
                    )}
                  </div>
                </div>
                {section.component}
              </div>
            ))}
          </div>
        </ScrollArea>

        <DialogFooter className="mt-4">
          <Button variant="outline" onClick={resetFilters}>
            Reset All Filters
          </Button>
          <Button onClick={() => onOpenChange(false)}>
            Apply Filters
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}