import React, { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { format } from 'date-fns'
import { Calendar, Trash2 } from 'lucide-react'
import axios from '../../../api/axiosConfig'
import { toast } from 'sonner'

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogBody,
  DialogDescription,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { Calendar as CalendarComponent } from '@/components/ui/calendar'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { DealSelector } from '@/taskboards/custom/components/DealSelector'
import { CustomTask } from '@/types/custom-task'
import { StoredUser } from '@/types/user'
import { Board } from '@/types/custom-board'

interface User {
  name: string
  user_id: string
}

interface Task {
  id: string
  title: string
  description?: string
  deal_id?: string
  work_date?: string
  due_date?: string
  assigned_users?: string[]
}

export interface EditCustomTaskModalProps {
  visible: boolean
  onClose: (wasDeleted?: boolean) => void
  task: CustomTask
  board: Board | null
  onSave: (updatedTask: CustomTask | null) => void
  users: StoredUser[]
}

const formSchema = z.object({
  title: z.string().min(1, 'Please enter a title'),
  description: z.string().optional(),
  deal_id: z.number().nullable().optional(),
  work_date: z.date().optional(),
  due_date: z.date().optional(),
  assigned_users: z.array(z.string()).optional(),
  progress_status_id: z.number().optional(),
  board_status_id: z.number().optional(),
})

const EditCustomTaskModal: React.FC<EditCustomTaskModalProps> = ({
  visible,
  onClose,
  task,
  onSave,
  board,
  users,
}) => {
  const hasBoardStatus = Boolean(
    board?.board_config?.board_status_options && 
    board.board_config.board_status_options.length > 0
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: task.title,
      description: task.description,
      deal_id: task.deal_id,
      work_date: task.work_date ? new Date(task.work_date) : undefined,
      due_date: task.due_date ? new Date(task.due_date) : undefined,
      assigned_users: task.assigned_users || [],
      progress_status_id: task.progress_status_id || board?.board_config?.progress_status_options?.[0]?.id,
      board_status_id: task.board_status_id || board?.board_config?.board_status_options?.[0]?.id,
    },
  })

  useEffect(() => {
    if (visible && task) {

      form.reset({
        title: task.title,
        description: task.description,
        deal_id: task.deal_id,
        work_date: task.work_date ? new Date(task.work_date) : undefined,
        due_date: task.due_date ? new Date(task.due_date) : undefined,
        assigned_users: task.assigned_users?.map(id => String(id)) || [],
        // Ensure we're using the actual IDs from the task
        progress_status_id: Number(task.progress_status_id),
        board_status_id: Number(task.board_status_id),
      });
      
    }
  }, [visible, task, form]);


  const handleDelete = async () => {
    try {
      const response = await axios.delete(`/custom-board/tasks/${task.id}`);
      
      if (response.status === 200) {
        toast.success('Task deleted successfully');
        onClose(true);
        onSave(null);
      }
    } catch (error) {
      console.error('Error deleting task:', error);
      toast.error('Failed to delete task');
    }
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const formattedValues = {
        ...values,
        work_date: values.work_date ? format(values.work_date, 'yyyy-MM-dd') : undefined,
        due_date: values.due_date ? format(values.due_date, 'yyyy-MM-dd') : undefined,
        progress_status_id: values.progress_status_id,
        board_status_id: hasBoardStatus ? values.board_status_id : undefined,
      }

      const response = await axios.put(
        `/custom-board/tasks/${task.id}`,
        formattedValues,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.status === 200) {
        toast.success('Task updated successfully')
        onSave(response.data)
        onClose()
      }
    } catch (error) {
      console.error('Error updating task:', error)
      toast.error('Failed to update task')
    }
  }

  return (
    <Dialog open={visible} onOpenChange={() => onClose()}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Edit Task</DialogTitle>
          <DialogDescription className="sr-only">
            Edit task details and properties
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogBody maxBodyHeight>
              <div className="space-y-4">
                {!board?.board_config?.simplify_main_tasks && (
                  <>
                    <FormField
                      control={form.control}
                      name="title"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Title</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="description"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Description</FormLabel>
                          <FormControl>
                            <Textarea {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </>
                )}

                <FormField
                  control={form.control}
                  name="deal_id"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Deal
                        {board?.board_config?.requires_deal && (
                          <span className="text-destructive"> *</span>
                        )}
                      </FormLabel>
                      <FormControl>
                        <DealSelector
                          value={field.value?.toString()}
                          onChange={field.onChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {!board?.board_config?.simplify_main_tasks && (
                  <>
                    <FormField
                      control={form.control}
                      name="work_date"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Work Date
                            {board?.board_config?.requires_work_date && (
                              <span className="text-destructive"> *</span>
                            )}
                          </FormLabel>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant="outline"
                                  className={cn(
                                    'w-full pl-3 text-left font-normal',
                                    !field.value && 'text-muted-foreground'
                                  )}
                                >
                                  {field.value ? (
                                    format(field.value, 'PPP')
                                  ) : (
                                    <span>Pick a date</span>
                                  )}
                                  <Calendar className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0" align="start">
                              <CalendarComponent
                                mode="single"
                                selected={field.value}
                                onSelect={field.onChange}
                                initialFocus
                              />
                            </PopoverContent>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="due_date"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Due Date
                            {board?.board_config?.requires_due_date && (
                              <span className="text-destructive"> *</span>
                            )}
                          </FormLabel>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant="outline"
                                  className={cn(
                                    'w-full pl-3 text-left font-normal',
                                    !field.value && 'text-muted-foreground'
                                  )}
                                >
                                  {field.value ? (
                                    format(field.value, 'PPP')
                                  ) : (
                                    <span>Pick a date</span>
                                  )}
                                  <Calendar className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0" align="start">
                              <CalendarComponent
                                mode="single"
                                selected={field.value}
                                onSelect={field.onChange}
                                initialFocus
                              />
                            </PopoverContent>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="assigned_users"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Assigned Users</FormLabel>
                          <Select
                            onValueChange={(value) => field.onChange([...field.value || [], value])}
                            value={field.value?.[0]}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select users" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {users?.map((user) => (
                                <SelectItem key={user.user_id.toString()} value={user.user_id.toString()}>
                                  {user.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </>
                )}

                {board?.board_config?.kanban_source === 'progress' ? (
                  <>
                    <FormField
                      control={form.control}
                      name="progress_status_id"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Progress Status</FormLabel>
                          <Select 
                            onValueChange={(value) => field.onChange(Number(value))} 
                            value={field.value?.toString()}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue>
                                  {board?.board_config?.progress_status_options?.find(
                                    status => status.id === field.value
                                  ) && (
                                    <div className="flex items-center gap-2">
                                      <div 
                                        className="w-3 h-3 rounded-full" 
                                        style={{ backgroundColor: board?.board_config?.progress_status_options?.find(
                                          status => status.id === field.value
                                        )?.color }}
                                      />
                                      {board?.board_config?.progress_status_options?.find(
                                        status => status.id === field.value
                                      )?.label}
                                    </div>
                                  )}
                                </SelectValue>
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {board?.board_config?.progress_status_options?.map((status) => (
                                <SelectItem key={status.id} value={status.id.toString()}>
                                  <div className="flex items-center gap-2">
                                    <div 
                                      className="w-3 h-3 rounded-full" 
                                      style={{ backgroundColor: status.color }}
                                    />
                                    {status.label}
                                  </div>
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    {board?.board_config?.board_status_options && board.board_config.board_status_options.length > 0 && (
                      <FormField
                        control={form.control}
                        name="board_status_id"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Board Status</FormLabel>
                            <Select 
                              onValueChange={(value) => field.onChange(Number(value))} 
                              value={field.value?.toString()}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue>
                                    {board?.board_config?.board_status_options?.find(
                                      status => status.id === field.value
                                    ) && (
                                      <div className="flex items-center gap-2">
                                        <div 
                                          className="w-3 h-3 rounded-full" 
                                          style={{ backgroundColor: board?.board_config?.board_status_options?.find(
                                            status => status.id === field.value
                                          )?.color }}
                                        />
                                        {board?.board_config?.board_status_options?.find(
                                          status => status.id === field.value
                                        )?.label}
                                      </div>
                                    )}
                                  </SelectValue>
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {board?.board_config?.board_status_options?.map((status) => (
                                  <SelectItem key={status.id} value={status.id.toString()}>
                                    <div className="flex items-center gap-2">
                                      <div 
                                        className="w-3 h-3 rounded-full" 
                                        style={{ backgroundColor: status.color }}
                                      />
                                      {status.label}
                                    </div>
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <FormField
                      control={form.control}
                      name="board_status_id"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Board Status</FormLabel>
                          <Select 
                            onValueChange={(value) => field.onChange(Number(value))} 
                            value={field.value?.toString()}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue>
                                  {board?.board_config?.board_status_options?.find(
                                    status => status.id === field.value
                                  ) && (
                                    <div className="flex items-center gap-2">
                                      <div 
                                        className="w-3 h-3 rounded-full" 
                                        style={{ backgroundColor: board?.board_config?.board_status_options?.find(
                                          status => status.id === field.value
                                        )?.color }}
                                      />
                                      {board?.board_config?.board_status_options?.find(
                                        status => status.id === field.value
                                      )?.label}
                                    </div>
                                  )}
                                </SelectValue>
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {board?.board_config?.board_status_options?.map((status) => (
                                <SelectItem key={status.id} value={status.id.toString()}>
                                  <div className="flex items-center gap-2">
                                    <div 
                                      className="w-3 h-3 rounded-full" 
                                      style={{ backgroundColor: status.color }}
                                    />
                                    {status.label}
                                  </div>
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    {board?.board_config?.progress_status_options && board.board_config.progress_status_options.length > 0 && (
                      <FormField
                        control={form.control}
                        name="progress_status_id"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Progress Status</FormLabel>
                            <Select 
                              onValueChange={(value) => field.onChange(Number(value))} 
                              value={field.value?.toString()}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue>
                                    {board?.board_config?.progress_status_options?.find(
                                      status => status.id === field.value
                                    ) && (
                                      <div className="flex items-center gap-2">
                                        <div 
                                          className="w-3 h-3 rounded-full" 
                                          style={{ backgroundColor: board?.board_config?.progress_status_options?.find(
                                            status => status.id === field.value
                                          )?.color }}
                                        />
                                        {board?.board_config?.progress_status_options?.find(
                                          status => status.id === field.value
                                        )?.label}
                                      </div>
                                    )}
                                  </SelectValue>
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {board?.board_config?.progress_status_options?.map((status) => (
                                  <SelectItem key={status.id} value={status.id.toString()}>
                                    <div className="flex items-center gap-2">
                                      <div 
                                        className="w-3 h-3 rounded-full" 
                                        style={{ backgroundColor: status.color }}
                                      />
                                      {status.label}
                                    </div>
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                  </>
                )}
              </div>
            </DialogBody>

            <DialogFooter>
              <div className="flex justify-between w-full">
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button variant="destructive" className="gap-2">
                      <Trash2 className="h-4 w-4" />
                      Delete Task
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Delete Task</AlertDialogTitle>
                      <AlertDialogDescription>
                        Are you sure you want to delete this task?
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction
                        onClick={handleDelete}
                        className="bg-destructive text-destructive-foreground"
                      >
                        Delete
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>

                <div className="flex gap-2">
                  <Button variant="outline" onClick={() => onClose()}>
                    Cancel
                  </Button>
                  <Button type="submit">Save</Button>
                </div>
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default EditCustomTaskModal