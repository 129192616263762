import { create } from 'zustand';
import { Deal } from '@/types/customers';
import { OnboardingEndpoint } from './types/endpoints';

interface OnboardingState {
  deal: Deal | null;
  setDeal: (deal: Deal | null) => void;
  currentNodeId: string | null;
  setCurrentNodeId: (nodeId: string | null) => void;
  formData: Record<string, Record<string, any>>;
  setFormData: (nodeId: string, data: Record<string, any>) => void;
  getNodeFormData: (nodeId: string) => Record<string, any>;
  availableEndpoints: OnboardingEndpoint[];
  setAvailableEndpoints: (endpoints: OnboardingEndpoint[]) => void;
}

export const useOnboardingStore = create<OnboardingState>((set, get) => ({
  deal: null,
  setDeal: (deal) => set({ deal }),
  currentNodeId: null,
  setCurrentNodeId: (nodeId) => set({ currentNodeId: nodeId }),
  formData: {},
  setFormData: (nodeId, data) => set((state) => ({
    formData: {
      ...state.formData,
      [nodeId]: data
    }
  })),
  getNodeFormData: (nodeId) => get().formData[nodeId] || {},
  availableEndpoints: [],
  setAvailableEndpoints: (endpoints) => set({ availableEndpoints: endpoints }),
})); 