import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { LucideIcon, TrendingDown, TrendingUp } from "lucide-react"
import { cn } from "@/lib/utils"

interface StatCardProps {
  title: string
  value: number
  icon: LucideIcon
  suffix?: string
  description?: string
  trend?: {
    value: number
    isUpward: boolean
    label?: string
  }
  className?: string
  valueFormatter?: (value: number) => string
  minWidth?: string
}

export function StatCard({
  title,
  value,
  icon: Icon,
  suffix,
  description,
  trend,
  className,
  valueFormatter = (val) => val.toLocaleString(),
  minWidth = "250px",
}: StatCardProps) {
  return (
    <Card className={cn("min-w-[250px]", className)} style={{ minWidth }}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium text-muted-foreground">{title}</CardTitle>
        <Icon className="h-4 w-4 text-muted-foreground" />
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">
          {valueFormatter(value)}{suffix && ` ${suffix}`}
        </div>
        {(description || trend) && (
          <p className="text-sm text-muted-foreground mt-2 flex items-center gap-1">
            {trend && (
              <>
                {trend.isUpward ? (
                  <TrendingUp className="h-4 w-4 text-green-500" />
                ) : (
                  <TrendingDown className="h-4 w-4 text-red-500" />
                )}
                <span className={cn(
                  trend.isUpward ? "text-green-500" : "text-red-500"
                )}>
                  {trend.value.toFixed(1)}%
                </span>
                {trend.label && ` ${trend.label}`}
              </>
            )}
            {description && (trend ? ` · ${description}` : description)}
          </p>
        )}
      </CardContent>
    </Card>
  )
}