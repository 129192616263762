import { Button } from "@/components/ui/button";
import { BookOpen } from "lucide-react";

interface OnboardingFormGuideProps {
  title: string;
  pageId: string;
}

export function OnboardingFormGuide({ title, pageId }: OnboardingFormGuideProps) {
  const getNotionUrl = (pageId: string) => {
    // Remove any existing notion.so prefix if present
    const cleanId = pageId.replace(/^.*notion\.so\//, '');
    // Convert dashes to hyphens and ensure proper format
    return `https://notion.so/${cleanId.replace(/-/g, '-')}?pvs=4`;
  };

  const handleOpenGuide = () => {
    window.open(getNotionUrl(pageId), '_blank', 'noopener,noreferrer');
  };

  return (
    <Button
      variant="ghost"
      size="icon"
      onClick={handleOpenGuide}
      className="hover:bg-transparent"
      title={`Open Guide: ${title}`}
    >
      <BookOpen className="h-5 w-5 text-muted-foreground hover:text-primary" />
    </Button>
  );
} 