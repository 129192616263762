import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Plus, FolderKanban } from 'lucide-react';
import axios from '@/api/axiosConfig';
import { Button } from '@/components/ui/button';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card';
import { useLanguage } from '@/contexts/LanguageContext';
import { t } from '@/i18n/language';

interface CustomBoard {
  id: number;
  name: string;
  board_type: string;
  description?: string;
  is_public: boolean;
  created_at?: string;
}

const TaskBoardsOverview: React.FC = () => {
  const [customBoards, setCustomBoards] = useState<CustomBoard[]>([]);
  const { language } = useLanguage();

  useEffect(() => {
    const fetchCustomBoards = async () => {
      try {
        const response = await axios.get('/custom-board/boards');
        setCustomBoards(response.data.filter((board: CustomBoard) => 
          board.board_type === 'taskboard'
        ));
      } catch (error) {
        console.error('Error fetching custom boards:', error);
      }
    };

    fetchCustomBoards();
  }, []);

  const defaultBoards = [
    {
      id: 'bogforing',
      name: t('nav-main.taskBoards.bookkeeping', language),
      description: 'Manage bookkeeping tasks',
      url: '/boards/bogforing',
    },
    {
      id: 'lon',
      name: t('nav-main.taskBoards.payroll', language),
      description: 'Handle payroll tasks',
      url: '/boards/lon',
    },
    {
      id: 'moms',
      name: t('nav-main.taskBoards.vat', language),
      description: 'Manage VAT tasks',
      url: '/boards/moms',
    },
    {
      id: 'arsafslutning',
      name: t('nav-main.taskBoards.annualReport', language),
      description: 'Annual report tasks',
      url: '/boards/arsafslutning',
    },
    {
      id: 'andre-opgaver',
      name: t('nav-main.taskBoards.otherTasks', language),
      description: 'Other miscellaneous tasks',
      url: '/boards/andre-opgaver',
    },
    {
      id: 'activities',
      name: t('nav-main.taskBoards.activities', language),
      description: 'Activity tracking board',
      url: '/boards/activities',
    },
  ];

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Task Boards</h1>
        <Button asChild>
          <Link to="/custom-boards/new">
            <Plus className="mr-2 h-4 w-4" />
            Create New Board
          </Link>
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {/* Default Boards */}
        {defaultBoards.map((board) => (
          <Card key={board.id} className="hover:shadow-lg transition-shadow">
            <Link to={board.url}>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <FolderKanban className="h-5 w-5" />
                  {board.name}
                </CardTitle>
                <CardDescription>{board.description}</CardDescription>
              </CardHeader>
            </Link>
          </Card>
        ))}

        {/* Custom Boards */}
        {customBoards.map((board) => (
          <Card key={board.id} className="hover:shadow-lg transition-shadow">
            <Link to={`/custom-boards/${board.id}`}>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <FolderKanban className="h-5 w-5" />
                  {board.name}
                </CardTitle>
                <CardDescription>
                  {board.description || 'Custom task board'}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="text-sm text-muted-foreground">
                  {board.is_public ? 'Public Board' : 'Private Board'}
                </div>
              </CardContent>
            </Link>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default TaskBoardsOverview; 