import { memo } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { FlowNode } from '@/onboardingflow/types/flows';
import { NodeElement, InputConfig, ButtonConfig, TextConfig, RowSection } from '../types/node_types';

const FormNode = memo(({ data }: NodeProps<FlowNode['data']>) => {
  const { config } = data;
  
  const renderElement = (element: NodeElement) => {
    switch (element.type) {
      case 'input':
        const inputConfig = element.config as InputConfig;
        return (
          <div key={element.id} className="space-y-1">
            <label className="text-sm font-medium">{inputConfig.label}</label>
            <input
              type={inputConfig.type}
              placeholder={inputConfig.placeholder}
              className="w-full p-2 border rounded"
            />
          </div>
        );
      case 'button':
        const buttonConfig = element.config as ButtonConfig;
        return (
          <button
            key={element.id}
            className="px-4 py-2 bg-primary text-white rounded"
          >
            {buttonConfig.label}
          </button>
        );
      case 'text':
        const textConfig = element.config as TextConfig;
        return (
          <div key={element.id} className="text-sm">
            {textConfig.content}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Card className="min-w-[300px]">
      <Handle type="target" position={Position.Top} />
      
      <CardHeader>
        <CardTitle>{config.title}</CardTitle>
      </CardHeader>
      
      <CardContent className="p-4 space-y-2">
        {config.rows.map((row: RowSection) => (
          <div key={row.id}>
            {row.elements.map(renderElement)}
          </div>
        ))}
        
        {config.rules && config.rules.length > 0 && (
          <div className="text-xs text-muted-foreground mt-2">
            🔄 {config.rules.length} rule(s)
          </div>
        )}
      </CardContent>

      <Handle type="source" position={Position.Bottom} />
    </Card>
  );
});

FormNode.displayName = 'FormNode';

export default FormNode;