import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { ScrollArea } from "@/components/ui/scroll-area";
import { X, PlusCircle, LayoutGrid, Eye, GripVertical, Plus, Trash2, ChevronUp, ChevronDown,
  Type, Square, FileText, ListFilter, FileQuestion, Webhook
} from "lucide-react";
import { Checkbox } from "@/components/ui/checkbox";
import { cn } from "@/lib/utils";
import { PreviewNode } from '../PreviewNode';
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, arrayMove } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { DragEndEvent } from '@dnd-kit/core';
import { useSensors, useSensor, PointerSensor } from '@dnd-kit/core';
import { 
  NodeConfig, 
  NodeElement, 
  NodeElementType, 
  NodeRule, 
  InputConfig, 
  ButtonConfig, 
  NotionGuideConfig, 
  ApiTriggerConfig, 
  TextConfig, 
  SelectConfig, 
  ElementLayout, 
  RowSection, 
  ElementConfig,
  ApiConfig,
  ApiPayload,
  ApiSuccessAction,
  ApiErrorAction,
  VisibilityCondition,
  DealDataConfig,
  TaskConfigType
} from '@/onboardingflow/types/node_types';
import { FlowNode, ReactFlowNode } from '@/onboardingflow/types/flows';
import { Node, Edge } from 'reactflow';
import { nanoid } from 'nanoid';
import { dealDataConfig, type DealDataField } from '@/configs/DealDataConfig';
import axios from '@/api/axiosConfig';
import { ElementConfigRenderer } from './ElementConfig/ElementConfigRenderer';
import { ElementTypeIcons, ElementTypeLabels, getDefaultConfig } from '../../utils/elementDefaults';
import { LucideIcon } from 'lucide-react';
import { RulesTab } from './RulesTab';
import { OnboardingEndpoint } from '../../types/endpoints';

interface NodeConfigDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  config: NodeConfig;
  onChange: (config: NodeConfig) => void;
  nodes: FlowNode[];
  edges: Edge[];
  availableEndpoints: OnboardingEndpoint[];
  nodeId: string;
  onDelete: () => void;
  onDuplicate: () => void;
}

export const NodeConfigDialog = ({
  open,
  onOpenChange,
  config,
  onChange,
  nodes,
  edges,
  availableEndpoints,
  nodeId,
  onDelete,
  onDuplicate
}: NodeConfigDialogProps) => {
  const [localConfig, setLocalConfig] = useState<NodeConfig>(() => ({
    id: config.id,
    title: config.title || '',
    rows: config.rows || [],
    rules: config.rules || [],
    header: config.header,
    defaultNextNodeId: config.defaultNextNodeId,
    defaultTriggerElementId: config.defaultTriggerElementId
  }));

  useEffect(() => {
    setLocalConfig({
      id: config.id,
      title: config.title || '',
      rows: config.rows || [],
      rules: config.rules || [],
      header: config.header,
      defaultNextNodeId: config.defaultNextNodeId,
      defaultTriggerElementId: config.defaultTriggerElementId
    });
  }, [config]);

  const [showPreview, setShowPreview] = useState(false);
  const [activeRow, setActiveRow] = useState<string | null>(null);
  const [activeElement, setActiveElement] = useState<string | null>(null);
  const [showHeaderConfig, setShowHeaderConfig] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const dialogRef = useRef<HTMLDivElement>(null);

  const handleElementChange = (elementId: string, updates: Partial<ElementConfig>) => {
    setLocalConfig((prev: NodeConfig) => {
      // Handle row elements
      const newRows = prev.rows.map((row: RowSection) => ({
        ...row,
        elements: row.elements.map((element: NodeElement) => {
          if (element.id !== elementId) return element;
          
          // Create new element with proper type discrimination
          if (updates.config) {
            const elementType: NodeElementType = element.type;
            switch (elementType) {
              case 'input':
                return { ...element, config: updates.config } as { id: string; type: 'input'; config: InputConfig; layout?: ElementLayout; visibilityConditions?: VisibilityCondition[] };
              case 'button':
                return { ...element, config: updates.config } as { id: string; type: 'button'; config: ButtonConfig; layout?: ElementLayout; visibilityConditions?: VisibilityCondition[] };
              case 'text':
                return { ...element, config: updates.config } as { id: string; type: 'text'; config: TextConfig; layout?: ElementLayout; visibilityConditions?: VisibilityCondition[] };
              case 'select':
                return { ...element, config: updates.config } as { id: string; type: 'select'; config: SelectConfig; layout?: ElementLayout; visibilityConditions?: VisibilityCondition[] };
              case 'notionGuide':
                return { ...element, config: updates.config } as { id: string; type: 'notionGuide'; config: NotionGuideConfig; layout?: ElementLayout; visibilityConditions?: VisibilityCondition[] };
              case 'apiTrigger':
                return { ...element, config: updates.config } as { id: string; type: 'apiTrigger'; config: ApiTriggerConfig; layout?: ElementLayout; visibilityConditions?: VisibilityCondition[] };
              case 'dealData':
                return { ...element, config: updates.config } as { id: string; type: 'dealData'; config: DealDataConfig; layout?: ElementLayout; visibilityConditions?: VisibilityCondition[] };
              case 'taskConfig':
                return { ...element, config: updates.config } as { id: string; type: 'taskConfig'; config: TaskConfigType; layout?: ElementLayout; visibilityConditions?: VisibilityCondition[] };
              default: {
                const exhaustiveCheck: never = elementType;
                throw new Error(`Unknown element type: ${exhaustiveCheck}`);
              }
            }
          }
          
          // Handle visibility conditions update
          if (updates.visibilityConditions !== undefined) {
            return {
              ...element,
              visibilityConditions: updates.visibilityConditions
            };
          }
          
          return element;
        })
      }));

      // Handle header element
      if (prev.header && prev.header.id === elementId && updates.config) {
        return {
          ...prev,
          header: {
            ...prev.header,
            notionGuide: updates.config as NotionGuideConfig
          },
          rows: newRows
        };
      }

      return {
        ...prev,
        rows: newRows
      };
    });
  };

  const renderElementConfig = (element: NodeElement) => (
    <ElementConfigRenderer
      element={element}
      elementId={element.id}
      availableEndpoints={availableEndpoints}
      localConfig={localConfig}
      dealDataConfig={dealDataConfig}
      onChange={handleElementChange}
    />
  );

  const getElementLabel = (element: NodeElement) => {
    const baseLabel = ElementTypeLabels[element.type] || 'Element';
    const configLabel = (() => {
      switch (element.type) {
        case 'button':
          return (element.config as ButtonConfig).label || 'Unnamed';
        case 'input':
          return (element.config as InputConfig).label || 'Unnamed';
        case 'select':
          return (element.config as SelectConfig).label || 'Unnamed';
        case 'text':
          return (element.config as TextConfig).content?.slice(0, 20) || 'Empty';
        default:
          return 'Unnamed';
      }
    })();

    return (
      <div className="flex items-center gap-2">
        {React.createElement(ElementTypeIcons[element.type] as LucideIcon, { className: "h-4 w-4" })}
        <span>{baseLabel}: {configLabel}</span>
      </div>
    );
  };

  const handleAddElement = (rowId: string, type: NodeElementType) => {
    setLocalConfig((prev: NodeConfig) => {
      const rowIndex = prev.rows.findIndex((r: RowSection) => r.id === rowId);
      if (rowIndex === -1) return prev;

      // Create new element with proper type discrimination
      const newElement: NodeElement = (() => {
        const baseElement = {
          id: nanoid(),
          type,
          config: getDefaultConfig(type)
        };

        switch (type) {
          case 'input':
            return {
              ...baseElement,
              type: 'input' as const,
              config: baseElement.config as InputConfig
            } satisfies NodeElement;
          case 'button':
            return {
              ...baseElement,
              type: 'button' as const,
              config: baseElement.config as ButtonConfig
            } satisfies NodeElement;
          case 'text':
            return {
              ...baseElement,
              type: 'text' as const,
              config: baseElement.config as TextConfig
            } satisfies NodeElement;
          case 'select':
            return {
              ...baseElement,
              type: 'select' as const,
              config: baseElement.config as SelectConfig
            } satisfies NodeElement;
          case 'notionGuide':
            return {
              ...baseElement,
              type: 'notionGuide' as const,
              config: baseElement.config as NotionGuideConfig
            } satisfies NodeElement;
          case 'apiTrigger':
            return {
              ...baseElement,
              type: 'apiTrigger' as const,
              config: baseElement.config as ApiTriggerConfig
            } satisfies NodeElement;
          case 'dealData':
            return {
              ...baseElement,
              type: 'dealData' as const,
              config: baseElement.config as DealDataConfig
            } satisfies NodeElement;
          case 'taskConfig':
            return {
              ...baseElement,
              type: 'taskConfig' as const,
              config: baseElement.config as TaskConfigType
            } satisfies NodeElement;
          default:
            const _exhaustiveCheck: never = type;
            throw new Error(`Unknown element type: ${_exhaustiveCheck}`);
        }
      })();

      const newRows = [...prev.rows];
      newRows[rowIndex] = {
        ...newRows[rowIndex],
        elements: [...newRows[rowIndex].elements, newElement]
      };

      return {
        ...prev,
        rows: newRows
      };
    });
  };

  const handleRemoveElement = (rowId: string, elementId: string) => {
    setLocalConfig((prev: NodeConfig) => ({
      ...prev,
      rows: prev.rows.map((row: RowSection) =>
        row.id === rowId
          ? {
              ...row,
              elements: row.elements.filter((el: NodeElement) => el.id !== elementId)
            }
          : row
      )
    }));
  };

  const handleAddRow = () => {
    const newRow: RowSection = {
      id: crypto.randomUUID(),
      elements: []
    };

    setLocalConfig((prev: NodeConfig) => ({
      ...prev,
      rows: [...prev.rows, newRow]
    }));
  };

  const handleRemoveRow = (rowId: string) => {
    setLocalConfig((prev: NodeConfig) => ({
      ...prev,
      rows: prev.rows.filter((row: RowSection) => row.id !== rowId)
    }));
  };

  const getConnectedForwardNodes = () => {
    return nodes.filter(node => node.id !== config.id);
  };

  const getButtonElements = () => {
    return localConfig.rows
      .flatMap(row => row.elements)
      .filter(el => el.type === 'button');
  };

  const getSelectElements = () => {
    return localConfig.rows
      .flatMap(row => row.elements)
      .filter(el => el.type === 'select');
  };

  const handleAddRule = () => {
    setLocalConfig(prev => ({
      ...prev,
      rules: [...(prev.rules || []), {
        id: crypto.randomUUID(),
        trigger: {
          elementId: '',
        },
        targetNodeId: '',
        requirements: []
      }]
    }));
  };

  const handleUpdateRule = (ruleId: string, updates: Partial<NodeRule>) => {
    setLocalConfig(prev => ({
      ...prev,
      rules: prev.rules?.map(rule =>
        rule.id === ruleId
          ? { ...rule, ...updates }
          : rule
      ) || []
    }));
  };

  const handleRemoveRule = (ruleId: string) => {
    setLocalConfig(prev => ({
      ...prev,
      rules: prev.rules?.filter(rule => rule.id !== ruleId) || []
    }));
  };

  const moveElement = (rowIndex: number, elementIndex: number, direction: 'up' | 'down') => {
    setLocalConfig(prev => {
      const row = prev.rows[rowIndex];
      const newElements = [...row.elements];
      const newIndex = direction === 'up' ? elementIndex - 1 : elementIndex + 1;
      
      if (newIndex < 0 || newIndex >= newElements.length) return prev;
      
      [newElements[elementIndex], newElements[newIndex]] = [newElements[newIndex], newElements[elementIndex]];
      
      const newRows = [...prev.rows];
      newRows[rowIndex] = { ...row, elements: newElements };
      
      return { ...prev, rows: newRows };
    });
  };

  const moveRow = (rowIndex: number, direction: 'up' | 'down') => {
    setLocalConfig(prev => {
      const newIndex = direction === 'up' ? rowIndex - 1 : rowIndex + 1;
      if (newIndex < 0 || newIndex >= prev.rows.length) return prev;
      
      const newRows = [...prev.rows];
      [newRows[rowIndex], newRows[newIndex]] = [newRows[newIndex], newRows[rowIndex]];
      
      return { ...prev, rows: newRows };
    });
  };

  const renderElementsTab = () => (
    <div className="space-y-4">
      <div>
        <Label>Node Title</Label>
        <Input
          value={localConfig.title}
          onChange={(e) => setLocalConfig(prev => ({ ...prev, title: e.target.value }))}
          placeholder="Enter node title"
        />
      </div>

      <div className="border p-4 rounded-lg space-y-2">
        <div className="flex justify-between items-center">
          <Label>Header Section</Label>
          {!localConfig.header && (
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                setLocalConfig(prev => ({
                  ...prev,
                  header: {
                    id: crypto.randomUUID(),
                    notionGuide: {
                      pageId: '',
                      title: ''
                    }
                  }
                }));
                setShowHeaderConfig(true);
              }}
            >
              Add Notion Guide Header
            </Button>
          )}
        </div>
        
        {localConfig.header && (
          <div className="space-y-4">
            {showHeaderConfig ? (
              <>
                <ElementConfigRenderer
                  element={{
                    id: localConfig.header.id,
                    type: 'notionGuide',
                    config: localConfig.header.notionGuide!
                  }}
                  elementId={localConfig.header.id}
                  availableEndpoints={availableEndpoints}
                  localConfig={localConfig}
                  dealDataConfig={dealDataConfig}
                  onChange={handleElementChange}
                />
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setShowHeaderConfig(false)}
                >
                  Done
                </Button>
              </>
            ) : (
              <div className="flex justify-between items-center">
                <div className="text-sm">
                  {localConfig.header.notionGuide?.title || 'Untitled Guide'}
                </div>
                <div className="space-x-2">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setShowHeaderConfig(true)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setLocalConfig(prev => ({ ...prev, header: undefined }))}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <Label>Content Rows</Label>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={handleAddRow}
          >
            Add Row
          </Button>
        </div>

        {localConfig.rows.map((row, rowIndex) => (
          <div key={row.id} className="border p-4 rounded-lg space-y-4">
            <div className="flex justify-between items-center">
              <Label>Row {rowIndex + 1}</Label>
              <div className="flex gap-2">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => moveRow(rowIndex, 'up')}
                  disabled={rowIndex === 0}
                >
                  <ChevronUp className="h-4 w-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => moveRow(rowIndex, 'down')}
                  disabled={rowIndex === localConfig.rows.length - 1}
                >
                  <ChevronDown className="h-4 w-4" />
                </Button>
                <Button variant="ghost" size="icon" onClick={() => handleRemoveRow(row.id)}>
                  <X className="h-4 w-4" />
                </Button>
              </div>
            </div>

            <div className="space-y-4">
              {row.elements.map((element, elementIndex) => (
                <div key={element.id} className="border p-4 rounded-lg">
                  <div className="flex justify-between items-center mb-4">
                    <Label>{getElementLabel(element)}</Label>
                    <div className="flex gap-2">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => moveElement(rowIndex, elementIndex, 'up')}
                        disabled={elementIndex === 0}
                      >
                        <ChevronUp className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => moveElement(rowIndex, elementIndex, 'down')}
                        disabled={elementIndex === row.elements.length - 1}
                      >
                        <ChevronDown className="h-4 w-4" />
                      </Button>
                      <Button variant="ghost" size="icon" onClick={() => handleRemoveElement(row.id, element.id)}>
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>

                  <ElementConfigRenderer
                    element={element}
                    elementId={element.id}
                    availableEndpoints={availableEndpoints}
                    localConfig={localConfig}
                    dealDataConfig={dealDataConfig}
                    onChange={handleElementChange}
                  />
                </div>
              ))}

              <div className="space-y-4">
                <div className="flex gap-2">
                  <Button 
                    variant="outline" 
                    size="sm" 
                    onClick={() => handleAddElement(row.id, 'input')}
                    className="flex items-center gap-2"
                  >
                    {React.createElement(ElementTypeIcons['input'] as LucideIcon, { size: 16 })}
                    Add Input
                  </Button>
                  <Button 
                    variant="outline" 
                    size="sm" 
                    onClick={() => handleAddElement(row.id, 'button')}
                    className="flex items-center gap-2"
                  >
                    {React.createElement(ElementTypeIcons['button'] as LucideIcon, { size: 16 })}
                    Add Button
                  </Button>
                  <Button 
                    variant="outline" 
                    size="sm" 
                    onClick={() => handleAddElement(row.id, 'text')}
                    className="flex items-center gap-2"
                  >
                    {React.createElement(ElementTypeIcons['text'] as LucideIcon, { size: 16 })}
                    Add Text
                  </Button>
                  <Button 
                    variant="outline" 
                    size="sm" 
                    onClick={() => handleAddElement(row.id, 'select')}
                    className="flex items-center gap-2"
                  >
                    {React.createElement(ElementTypeIcons['select'] as LucideIcon, { size: 16 })}
                    Add Select
                  </Button>
                  <Button 
                    variant="outline" 
                    size="sm" 
                    onClick={() => handleAddElement(row.id, 'dealData')}
                    className="flex items-center gap-2"
                  >
                    {React.createElement(ElementTypeIcons['dealData'] as LucideIcon, { size: 16 })}
                    Add Deal Data
                  </Button>
                  <Button 
                    variant="outline" 
                    size="sm" 
                    onClick={() => handleAddElement(row.id, 'taskConfig')}
                    className="flex items-center gap-2"
                  >
                    {React.createElement(ElementTypeIcons['taskConfig'] as LucideIcon, { size: 16 })}
                    Add Task Display
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}

        <Button variant="outline" className="w-full" onClick={handleAddRow}>
          <PlusCircle className="h-4 w-4 mr-2" />
          Add Row
        </Button>
      </div>
    </div>
  );

  const handleElementConfigChange = (elementId: string, updates: Partial<ElementConfig>) => {
    const updatedRows = config.rows.map(row => ({
      ...row,
      elements: row.elements.map(element => 
        element.id === elementId 
          ? { ...element, config: { ...element.config, ...updates } }
          : element
      )
    })) as RowSection[];
    
    onChange({ ...config, rows: updatedRows });
  };

  const handleUpdateVisibility = (elementId: string, conditions: VisibilityCondition[]) => {
    const updatedRows = config.rows.map(row => ({
      ...row,
      elements: row.elements.map(element => 
        element.id === elementId 
          ? { ...element, visibilityConditions: conditions }
          : element
      )
    })) as RowSection[];
    
    onChange({ ...config, rows: updatedRows });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent 
        ref={dialogRef}
        className="max-w-4xl max-h-[90vh] flex flex-col overflow-hidden"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex items-center gap-4">
            <DialogTitle>Configure Node</DialogTitle>
            <Button
              variant="outline"
              size="sm"
              onClick={() => setShowPreview(true)}
              className="flex items-center gap-2"
            >
              <Eye className="h-4 w-4" />
              Preview
            </Button>
          </div>
        </DialogHeader>

        <Tabs defaultValue="elements" className="flex-1 overflow-hidden">
          <TabsList>
            <TabsTrigger value="elements">Elements</TabsTrigger>
            <TabsTrigger value="rules">Rules</TabsTrigger>
          </TabsList>
          <ScrollArea className="flex-1 h-[calc(80vh-8rem)]">
            <div className="p-4">
              <TabsContent value="elements" className="mt-0">
                {renderElementsTab()}
              </TabsContent>
              <TabsContent value="rules" className="mt-0">
                <RulesTab
                  localConfig={localConfig}
                  setLocalConfig={setLocalConfig}
                  nodes={nodes}
                  edges={edges}
                  nodeId={nodeId}
                />
              </TabsContent>
            </div>
          </ScrollArea>
        </Tabs>

        <DialogFooter className="flex justify-between w-full border-t pt-2">
          <div className="flex gap-2">
            <Button variant="destructive" size="sm" onClick={onDelete}>
              <Trash2 className="h-4 w-4 mr-2" />
              Delete Node
            </Button>
            <Button variant="outline" size="sm" onClick={onDuplicate}>
              <Plus className="h-4 w-4 mr-2" />
              Duplicate Node
            </Button>
          </div>
          <div className="flex gap-2">
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              Cancel
            </Button>
            <Button onClick={() => onChange(localConfig)}>
              Save Changes
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>

      {showPreview && (
        <PreviewNode
          config={localConfig}
          onClose={() => setShowPreview(false)}
        />
      )}
    </Dialog>
  );
}