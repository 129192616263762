import * as React from "react"
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from "recharts"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card"
import { useResizeObserver } from "@/hooks/use-resize-observer"

export interface DataPoint {
  [key: string]: string | number | { paid: number; unpaid: number }
}

interface ChartFilter {
  id: string
  label: string
  color: string
}

interface FilteredBarChartProps {
  data: DataPoint[]
  title: React.ReactNode
  description?: string
  filters: ChartFilter[]
  xAxisKey: string
  valueFormatter?: (value: number) => string
  variant?: 'stacked' | 'grouped' | 'grouped-stacked'
  barGap?: number
  barGroupGap?: number
}

export function FilteredBarChart({
  data,
  title,
  description,
  filters,
  xAxisKey,
  valueFormatter = (value: number) => value.toLocaleString(),
  variant = 'stacked',
  barGap = 4,
  barGroupGap = 32,
}: FilteredBarChartProps) {
  const [selectedFilters, setSelectedFilters] = React.useState<string[]>(['all'])
  const [containerRef, { width: containerWidth }] = useResizeObserver()

  const handleFilterClick = (filterId: string) => {
    if (filterId === 'all') {
      setSelectedFilters(['all'])
    } else {
      const newFilters = selectedFilters.filter(f => f !== 'all')
      if (newFilters.includes(filterId)) {
        newFilters.splice(newFilters.indexOf(filterId), 1)
      } else {
        newFilters.push(filterId)
      }
      setSelectedFilters(newFilters.length ? newFilters : ['all'])
    }
  }

  const activeFilters = selectedFilters.includes('all') 
    ? filters.filter(f => f.id !== 'all').map(f => f.id)
    : selectedFilters

  const renderBars = () => {
    // Sort activeFilters to match original filter order
    const sortedActiveFilters = [...activeFilters].sort((a, b) => {
      const indexA = filters.findIndex(f => f.id === a);
      const indexB = filters.findIndex(f => f.id === b);
      return indexA - indexB;
    });

    if (variant === 'stacked') {
      return sortedActiveFilters.map((filterId) => {
        const filter = filters.find(f => f.id === filterId)!
        const originalIndex = filters.findIndex(f => f.id === filterId)
        return (
          <Bar
            key={filterId}
            dataKey={filterId}
            stackId="a"
            fill={`hsl(var(--chart-multi-${((originalIndex % 5) + 1)}))`}
            name={filter.label}
          />
        )
      })
    } else if (variant === 'grouped') {
      return sortedActiveFilters.map((filterId) => {
        const filter = filters.find(f => f.id === filterId)!
        const originalIndex = filters.findIndex(f => f.id === filterId)
        return (
          <Bar
            key={filterId}
            dataKey={filterId}
            fill={`hsl(var(--chart-multi-${((originalIndex % 5) + 1)}))`}
            name={filter.label}
          />
        )
      })
    } else if (variant === 'grouped-stacked') {
      return sortedActiveFilters.map((filterId) => {
        const filter = filters.find(f => f.id === filterId)!
        const originalIndex = filters.findIndex(f => f.id === filterId)
        const baseColor = `hsl(var(--chart-multi-${((originalIndex % 5) + 1)}))`
        const unpaidColor = `hsl(var(--chart-multi-${((originalIndex % 5) + 1)}) / 0.4)`

        return (
          <React.Fragment key={filterId}>
            <Bar
              dataKey={`${filterId}.paid`}
              stackId={filterId}
              fill={baseColor}
              name={`${filter.label} (Paid)`}
            />
            <Bar
              dataKey={`${filterId}.unpaid`}
              stackId={filterId}
              fill={unpaidColor}
              name={`${filter.label} (Unpaid)`}
            />
          </React.Fragment>
        )
      })
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        {description && <CardDescription>{description}</CardDescription>}
        <div className="flex flex-wrap gap-2 mt-4">
          {[
            { 
              id: 'all', 
              label: 'All', 
              color: `hsl(var(--chart-multi-1))` 
            }, 
            ...filters.map((filter, index) => ({
              ...filter,
              color: `hsl(var(--chart-multi-${((index % 5) + 1)}))`
            }))
          ].map((filter) => (
            <Button
              key={filter.id}
              variant={selectedFilters.includes(filter.id) ? "default" : "outline"}
              onClick={() => handleFilterClick(filter.id)}
              className="h-8"
              style={{
                backgroundColor: selectedFilters.includes(filter.id) ? filter.color : 'transparent',
                borderColor: filter.color,
                color: selectedFilters.includes(filter.id) ? 'white' : 'inherit'
              }}
            >
              {filter.label}
            </Button>
          ))}
        </div>
      </CardHeader>
      <CardContent className="pt-4">
        <div ref={containerRef} className="h-[350px] w-full">
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            width={containerWidth || 400}
            height={350}
            barGap={barGap}
            barCategoryGap={barGroupGap}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis 
              dataKey={xAxisKey}
              tickFormatter={(value) => new Date(value).toLocaleDateString('en-US', { month: 'short' })}
            />
            <YAxis tickFormatter={valueFormatter} />
            <Tooltip
              content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  return (
                    <div className="rounded-lg border border-border bg-background p-2 shadow-sm">
                      <div className="grid gap-2">
                        <div className="flex flex-col">
                          <span className="text-[0.70rem] uppercase text-muted-foreground">
                            {new Date(label).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
                          </span>
                          {payload.map((entry) => {
                            const value = typeof entry.value === 'object' 
                              ? (entry.value as any).paid + (entry.value as any).unpaid
                              : entry.value
                            return (
                              <div key={entry.name} className="flex items-center gap-2">
                                <div 
                                  className="h-2 w-2 rounded-full" 
                                  style={{ backgroundColor: entry.color }}
                                />
                                <span className="text-muted-foreground">
                                  {entry.name}: <span className="font-bold">{valueFormatter(value as number)}</span>
                                </span>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )
                }
                return null
              }}
              cursor={{ fill: 'transparent' }}
            />
            <Legend />
            {renderBars()}
          </BarChart>
        </div>
      </CardContent>
    </Card>
  )
}