import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig, isAxiosError } from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';
const isDevelopment = process.env.NODE_ENV === 'development';

const instance = axios.create({
  baseURL: `${API_URL}`,
  withCredentials: true,
});

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (value?: any) => void;
  reject: (reason?: any) => void;
}> = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const manualLogout = sessionStorage.getItem('manualLogout');
    if (manualLogout === 'true') {
      return config;
    }

    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else if (isDevelopment) {
      console.debug('No token in localStorage (development mode)');
    } else {
      console.warn('No token found in localStorage');
    }
    return config;
  },
  error => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async error => {
    const originalRequest = error.config;
    const manualLogout = sessionStorage.getItem('manualLogout');

    if (manualLogout === 'true') {
      return Promise.reject(error);
    }

    // In development, always retry with current token
    if (isDevelopment && error.response?.status === 401) {
      const token = localStorage.getItem('token');
      if (token && originalRequest) {
        originalRequest.headers['Authorization'] = `Bearer ${token}`;
        return instance(originalRequest);
      }
    }

    // Production token refresh logic
    if (!isDevelopment && error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        try {
          const token = await new Promise<string>((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          });
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return instance(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken');
        
        if (!token || !refreshToken) {
          throw new Error('No tokens available');
        }

        // First try to verify the current token
        const verifyResponse = await instance.post('/auth/verify-token', {
          token,
          refresh_token: refreshToken
        });

        if (verifyResponse.data.valid) {
          const { access_token, refresh_token, user } = verifyResponse.data;
          localStorage.setItem('token', access_token);
          localStorage.setItem('refreshToken', refresh_token);
          if (user) {
            localStorage.setItem('user', JSON.stringify(user));
          }

          instance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
          originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
          
          processQueue(null, access_token);
          isRefreshing = false;
          
          return instance(originalRequest);
        }

        // If verification fails, try to refresh the token
        const response = await instance.post('/auth/refresh', {}, {
          headers: { 'Authorization': `Bearer ${refreshToken}` }
        });

        const { access_token, refresh_token } = response.data;
        localStorage.setItem('token', access_token);
        localStorage.setItem('refreshToken', refresh_token);
        
        instance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
        originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
        
        processQueue(null, access_token);
        isRefreshing = false;
        
        return instance(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        isRefreshing = false;
        
        if (!isDevelopment) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('user');
          
          if (!window.location.pathname.includes('/login')) {
            window.location.href = '/login';
          }
        }
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;