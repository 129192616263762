import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { UserAvatar } from '@/components/user-avatar';
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { StoredUser } from '@/types/user';
import axios from '@/api/axiosConfig';
import { LoadingSpinner } from '@/components/ui/loading-spinner';
import { ArrowRight, Settings } from 'lucide-react';
import { Board, BoardStatusOption, SubtaskConfig } from '@/types/custom-board';

interface CustomTask {
  id: number;
  board?: {
    board_config: {
      progress_status_options: BoardStatusOption[];
      board_status_options: BoardStatusOption[];
      enable_subtasks?: boolean;
    };
    subtask_config?: SubtaskConfig;
  };
  subtasks?: Array<{
    id: number;
    title: string;
    status_options?: Array<{
      id: number;
      label: string;
      color: string;
    }>;
  }>;
}

interface Update {
  type: 'work_date' | 'next_work_date' | 'due_date' | 'next_due_date' | 'board_status' | 'progress_status' | 'assigned_users' | 'comment_new' | 'comment_reply' | 'comment_deleted';
  changes?: {
    from: string;
    to: string;
  };
  old_value?: any;
  new_value?: any;
  timestamp: string;
  user_id: string | number;
  subtask_id?: number;
  subtask_title?: string;
  content?: string;
}

interface TaskViewActivityProps {
  updates?: Update[];
  users?: StoredUser[];
  taskId?: number;
  isCustomTask?: boolean;
  task?: CustomTask | null;
}

const TaskViewActivity: React.FC<TaskViewActivityProps> = ({ 
  updates: propUpdates = [], 
  users = [], 
  taskId, 
  isCustomTask,
  task
}) => {
  const [loading, setLoading] = useState(false);
  const [updates, setUpdates] = useState<Update[]>(propUpdates);

  useEffect(() => {
    if (isCustomTask && taskId) {
      fetchCustomTaskActivity();
    } else {
      setUpdates(propUpdates);
    }
  }, [isCustomTask, taskId, propUpdates]);

  const fetchCustomTaskActivity = async () => {
    try {
      setLoading(true);
      // Fetch main task activity
      const response = await axios.get(`/custom-board/tasks/${taskId}/activity`);
      let allUpdates = response.data;

      // If this is a custom task with subtasks enabled and has subtasks
      if (task?.board?.board_config?.enable_subtasks && task.subtasks && task.subtasks.length > 0) {
        // Fetch activity for each subtask
        const subtaskActivities = await Promise.all(
          task.subtasks.map(async (subtask) => {
            const subtaskResponse = await axios.get(`/custom-board/subtasks/${subtask.id}/activity`);
            return subtaskResponse.data.map((update: Update) => ({
              ...update,
              subtask_id: subtask.id,
              subtask_title: subtask.title
            }));
          })
        );

        // Combine all updates
        allUpdates = [
          ...allUpdates,
          ...subtaskActivities.flat()
        ];
      }

      // Sort all updates by timestamp
      allUpdates.sort((a: Update, b: Update) => 
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      );

      setUpdates(allUpdates);
    } catch (error) {
      console.error('Error fetching task activity:', error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (statusId: number | string | null, type: 'progress' | 'board', subtaskId?: number): string => {
    if (!statusId || !task?.board?.board_config) return '#94a3b8';
    
    if (subtaskId && task.subtasks) {
      // Find the subtask
      const subtask = task.subtasks.find(s => s.id === subtaskId);
      if (subtask && task.board.subtask_config?.predefined_subtasks) {
        // Find the status option in the subtask's predefined status options
        const statusOption = task.board.subtask_config.predefined_subtasks
          .find((ps: { name: string }) => ps.name === subtask.title)
          ?.status_options
          ?.find((so: { id: number; color: string }) => so.id === Number(statusId));
        return statusOption?.color || '#94a3b8';
      }
    }
    
    const options = type === 'progress' 
      ? task.board.board_config.progress_status_options 
      : task.board.board_config.board_status_options;

    const status = options?.find(opt => opt.id === Number(statusId));
    return status?.color || '#94a3b8';
  };

  const getStatusLabel = (statusId: number | string | null, type: 'progress' | 'board', subtaskId?: number): string => {
    if (!statusId || !task?.board?.board_config) return 'None';
    
    if (subtaskId && task.subtasks) {
      // Find the subtask
      const subtask = task.subtasks.find(s => s.id === subtaskId);
      if (subtask && task.board.subtask_config?.predefined_subtasks) {
        // Find the status option in the subtask's predefined status options
        const statusOption = task.board.subtask_config.predefined_subtasks
          .find((ps: { name: string }) => ps.name === subtask.title)
          ?.status_options
          ?.find((so: { id: number; label: string }) => so.id === Number(statusId));
        return statusOption?.label || 'Unknown';
      }
    }
    
    const options = type === 'progress' 
      ? task.board.board_config.progress_status_options 
      : task.board.board_config.board_status_options;

    const status = options?.find(opt => opt.id === Number(statusId));
    return status?.label || 'Unknown';
  };

  const renderActivityContent = (update: Update) => {
    const oldValue = update.changes?.from ?? update.old_value;
    const newValue = update.changes?.to ?? update.new_value;

    const activityHeader = (title: string) => (
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span className="font-medium">{title}</span>
          {update.subtask_id && (
            <span className="text-sm text-muted-foreground">
              in subtask "{update.subtask_title}"
            </span>
          )}
        </div>
        <span className="text-sm text-muted-foreground">
          {dayjs(update.timestamp).fromNow()}
        </span>
      </div>
    );

    switch (update.type) {
      case 'progress_status':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            {activityHeader('Progress Status Changed')}
            <div className="flex items-center gap-2">
              <span
                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                style={{ backgroundColor: getStatusColor(oldValue, 'progress', update.subtask_id) }}
              >
                {getStatusLabel(oldValue, 'progress', update.subtask_id)}
              </span>
              <ArrowRight className="h-4 w-4 text-muted-foreground" />
              <span
                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                style={{ backgroundColor: getStatusColor(newValue, 'progress', update.subtask_id) }}
              >
                {getStatusLabel(newValue, 'progress', update.subtask_id)}
              </span>
            </div>
          </div>
        );
      case 'board_status':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            {activityHeader('Board Status Changed')}
            <div className="flex items-center gap-2">
              <span
                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                style={{ backgroundColor: getStatusColor(oldValue, 'board', update.subtask_id) }}
              >
                {getStatusLabel(oldValue, 'board', update.subtask_id)}
              </span>
              <ArrowRight className="h-4 w-4 text-muted-foreground" />
              <span
                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                style={{ backgroundColor: getStatusColor(newValue, 'board', update.subtask_id) }}
              >
                {getStatusLabel(newValue, 'board', update.subtask_id)}
              </span>
            </div>
          </div>
        );
      case 'assigned_users':
        const fromUsers = (Array.isArray(oldValue) ? oldValue : [])
          .map(id => users.find(u => u.user_id.toString() === id.toString())?.name)
          .filter(Boolean)
          .join(', ') || 'None';
        const toUsers = (Array.isArray(newValue) ? newValue : [])
          .map(id => users.find(u => u.user_id.toString() === id.toString())?.name)
          .filter(Boolean)
          .join(', ') || 'None';
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            {activityHeader('Assignees Changed')}
            <div className="flex items-center gap-2">
              <span className="line-through text-muted-foreground">
                {fromUsers}
              </span>
              <ArrowRight className="h-4 w-4 text-muted-foreground" />
              <span>{toUsers}</span>
            </div>
          </div>
        );
      case 'work_date':
      case 'due_date':
        const isWorkDate = update.type === 'work_date';
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            {activityHeader(isWorkDate ? 'Work Date Updated' : 'Due Date Updated')}
            <div className="flex items-center gap-2">
              <span className="line-through text-muted-foreground">
                {oldValue ? dayjs(oldValue).format('DD/MM/YYYY') : 'None'}
              </span>
              <ArrowRight className="h-4 w-4 text-muted-foreground" />
              <span>
                {newValue ? dayjs(newValue).format('DD/MM/YYYY') : 'None'}
              </span>
            </div>
          </div>
        );
      case 'comment_new':
      case 'comment_reply':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            {activityHeader(update.type === 'comment_new' ? 'Comment Added' : 'Reply Added')}
            <div className="flex flex-col gap-1">
              <p className="text-sm whitespace-pre-wrap break-words">
                {update.content}
              </p>
            </div>
          </div>
        );
      case 'comment_deleted':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            {activityHeader('Comment Deleted')}
            <div className="flex flex-col gap-1">
              <p className="text-sm text-muted-foreground line-through whitespace-pre-wrap break-words">
                {update.content}
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  const isValidUser = (user: StoredUser | undefined): user is StoredUser => {
    return user !== undefined && 'user_id' in user;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[100px] rounded-lg p-5">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  if (!updates || updates.length === 0) {
    return (
      <div className="flex justify-center items-center min-h-[100px] rounded-lg p-5">
        <p className="text-sm text-muted-foreground">
          No activity recorded yet
        </p>
      </div>
    );
  }

  return (
    <ScrollArea className="h-full w-full px-5">
      <div className="space-y-8 py-4">
        {updates
          .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
          .map((update, index) => {
            const user = users.find(u => u.user_id === update.user_id);
            const isSystem = update.user_id === 'system';

            return (
              <div key={index} className="relative pl-8">
                <div className="absolute left-0 top-0">
                  {isSystem ? (
                    <div className="h-6 w-6 rounded-full bg-muted flex items-center justify-center">
                      <Settings className="h-4 w-4 text-muted-foreground" />
                    </div>
                  ) : (
                    isValidUser(user) && <UserAvatar user={user} className="h-6 w-6" />
                  )}
                </div>
                <div className="space-y-1">
                  <span className="text-sm text-muted-foreground">
                    {isSystem ? 'System' : user ? `${user.name} ${user.surname}` : 'Unknown User'}
                  </span>
                  {renderActivityContent(update)}
                </div>
              </div>
            );
          })}
      </div>
    </ScrollArea>
  );
}

export default TaskViewActivity; 