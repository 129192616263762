import { useEffect, useState } from "react"
import { FileText, Users, Calendar, CheckCircle } from "lucide-react"
import axios from '@/api/axiosConfig'
import { toast } from "sonner"
import { LoadingSpinner } from "@/components/ui/loading-spinner"
import { Bar, BarChart, XAxis, YAxis, PieChart, Pie, Label } from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui"
import { PieDonutChart } from "@/components/ui/charts/pie-donut"
import { BarChartHorizontalMix } from "@/components/ui/charts/bar-horizontal-mix"

interface TaskStats {
  total_tasks: number
  active_users: number
  tasks_this_month: number
  completed_tasks: number
  task_types: Record<string, number>
  user_assignments: Record<string, {
    total: number
    recurring: number
    onboarding: number
  }>
}

// Add this interface near your other interfaces
interface LabelProps {
  viewBox: {
    cx: number
    cy: number
    [key: string]: number
  }
}

export default function TaskData() {
  const [loading, setLoading] = useState(true)
  const [taskStats, setTaskStats] = useState<TaskStats | null>(null)

  const pieChartConfig: ChartConfig = {
    value: {
      label: "Tasks",
    },
    ...Object.fromEntries(
      Object.entries(taskStats?.task_types || {}).map(([type], index) => [
        type,
        {
          label: type,
          color: `hsl(var(--chart-${index + 1}))`,
        }
      ])
    )
  }

  const barChartConfig: ChartConfig = {
    value: {
      label: "Tasks",
    },
    ...Object.fromEntries(
      Object.keys(taskStats?.user_assignments || {}).map((user, index) => [
        user.split(' ')[0],
        {
          label: user,
          color: `hsl(var(--chart-${index + 1}))`,
        }
      ])
    )
  }

  useEffect(() => {
    fetchTaskData()
  }, [])

  const fetchTaskData = async () => {
    try {
      const response = await axios.get('/dashboard/task-data')
      setTaskStats(response.data)
    } catch (error) {
      console.error('Error fetching task stats:', error)
      toast.error("Failed to fetch task statistics")
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <div className="flex h-[400px] items-center justify-center">
        <LoadingSpinner />
      </div>
    )
  }

  // Prepare data for pie chart
  const pieData = taskStats?.task_types ? 
    Object.entries(taskStats.task_types).map(([type, count], index) => ({
      name: type,
      value: count,
      fill: `hsl(var(--chart-${index + 1}))`
    })) : []

  // Prepare data for bar chart
  const barData = taskStats?.user_assignments ?
    Object.entries(taskStats.user_assignments).map(([user, data], index) => ({
      name: user.split(' ')[0], // First name only
      value: data.total,
      fill: `hsl(var(--chart-${index + 1}))`
    })) : []

  return (
    <div className="space-y-4 pt-4">
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Tasks</CardTitle>
            <FileText className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{taskStats?.total_tasks}</div>
          </CardContent>
        </Card>

        {/* Similar cards for other stats */}
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Active Users</CardTitle>
            <Users className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{taskStats?.active_users}</div>
          </CardContent>
        </Card>

        {/* Add remaining stat cards similarly */}
      </div>

      <div className="grid gap-4 md:grid-cols-2">
        <PieDonutChart
          data={pieData}
          config={pieChartConfig}
          title="Task Type Distribution"
          centerLabel={{
            value: taskStats?.total_tasks || 0,
            label: "Tasks"
          }}
        />

        <BarChartHorizontalMix
          data={barData}
          config={barChartConfig}
          title="User Task Assignments"
        />
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Detailed User Task Distribution</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>User</TableHead>
                <TableHead>Total Tasks</TableHead>
                <TableHead>Recurring Tasks</TableHead>
                <TableHead>Onboarding Tasks</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {taskStats?.user_assignments && 
                Object.entries(taskStats.user_assignments).map(([user, data]) => (
                  <TableRow key={user}>
                    <TableCell>{user}</TableCell>
                    <TableCell>{data.total}</TableCell>
                    <TableCell>{data.recurring}</TableCell>
                    <TableCell>{data.onboarding}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  )
}