import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ApiConfig, ApiPayload } from '../../../types/node_types';
import { DealDataField } from '@/configs/DealDataConfig';
import { dealDataConfig } from '@/configs/DealDataConfig';
import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { Checkbox } from "@/components/ui/checkbox";

interface OnboardingEndpoint {
  id: number;
  name: string;
  endpoint: string;
  method: string;
  description: string;
  required_fields: Record<string, any>;
}

interface FormElement {
  id: string;
  type: string;
  config: {
    label: string;
  };
}

interface FormRow {
  elements: FormElement[];
}

interface LocalConfig {
  rows: FormRow[];
}

interface PayloadConfigProps {
  config: ApiConfig;
  endpoint: OnboardingEndpoint | undefined;
  localConfig: LocalConfig;
  onChange: (updates: Partial<ApiPayload>) => void;
}

export function PayloadConfig({ config, endpoint, localConfig, onChange }: PayloadConfigProps) {
  const requiredFields = endpoint?.required_fields?.payload?.fields || [];
  const selectedFields = config.payload?.value || [];

  return (
    <div className="space-y-4">
      {config.endpoint && (
        <div>
          <Label>Payload Type</Label>
          <Select
            value={config.payload?.type || 'static'}
            onValueChange={(value: ApiPayload['type']) => onChange({
              type: value,
              value: value === 'deal' ? requiredFields : [],
              staticData: value === 'static' ? {} : undefined
            })}
            disabled={!!endpoint?.required_fields?.payload?.type}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select payload type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="static">Static Data</SelectItem>
              <SelectItem value="form">Form Data</SelectItem>
              <SelectItem value="deal">Deal Data</SelectItem>
            </SelectContent>
          </Select>
        </div>
      )}

      {config.endpoint && config.payload?.type === 'deal' && (
        <div className="space-y-2">
          <Label>Deal Data Fields</Label>
          <p className="text-sm text-muted-foreground mb-2">
            Select the deal data fields to include in the payload.
            {requiredFields.length > 0 && (
              <span className="font-medium"> Required fields are pre-selected.</span>
            )}
          </p>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                className="w-full justify-between"
              >
                {selectedFields.length === 0 
                  ? "Select fields..." 
                  : `${selectedFields.length} field${selectedFields.length === 1 ? '' : 's'} selected`}
                <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-full p-0">
              <Command>
                <CommandInput placeholder="Search fields..." />
                <CommandEmpty>No fields found.</CommandEmpty>
                <CommandGroup>
                  {dealDataConfig.map((field) => {
                    const isSelected = selectedFields.includes(field.key);
                    const isRequired = requiredFields.includes(field.key);
                    return (
                      <CommandItem
                        key={field.key}
                        onSelect={() => {
                          if (isRequired) return;
                          onChange({
                            value: isSelected
                              ? selectedFields.filter(key => key !== field.key)
                              : [...selectedFields, field.key],
                            type: 'deal'
                          });
                        }}
                        disabled={isRequired}
                        className="flex items-center gap-2"
                      >
                        <div className={cn(
                          "flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                          (isSelected || isRequired) ? "bg-primary text-primary-foreground" : "opacity-50"
                        )}>
                          {(isSelected || isRequired) && <Check className="h-3 w-3" />}
                        </div>
                        <span>
                          {field.label}
                          {isRequired && <span className="text-destructive ml-1">*</span>}
                        </span>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        </div>
      )}

      {config.payload?.type === 'form' && (
        <div>
          <Label>Form Elements to Include</Label>
          <div className="space-y-2">
            {localConfig.rows.flatMap(row => 
              row.elements.filter(el => 
                el.type === 'input' || el.type === 'select'
              )
            ).map((el) => (
              <div key={el.id} className="flex items-center gap-2">
                <Checkbox
                  checked={config.payload?.formElements?.includes(el.id)}
                  onCheckedChange={(checked: boolean) => {
                    const formElements = config.payload?.formElements || [];
                    onChange({
                      formElements: checked 
                        ? [...formElements, el.id]
                        : formElements.filter(id => id !== el.id),
                      type: config.payload?.type || 'form',
                      value: config.payload?.value || []
                    });
                  }}
                />
                <span className="text-sm">{el.config.label}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
