import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Routes, Route, useLocation, useSearchParams, Navigate, useParams, Outlet, useNavigate } from 'react-router-dom';
import { AppSidebar } from '@/components/app-sidebar';
import { SidebarProvider } from '@/contexts/sidebar-contexts';
import { useSidebar } from "@/contexts/sidebar-contexts";
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';
import { NotificationProvider } from '@/contexts/NotificationContext';
import { StoredUser } from '@/types/user';
import { CardConfig } from '@/taskboards/config/card';
import { DealConfigItem } from '@/types/taskboard';
import { UnifiedTaskType } from '@/types/task';

import { AppHeader } from '@/components/app-header';

import Home from './Home';
import Work from './Work';

import Dashboard from './Dashboard';
import OverviewDash from '../digi-tal-dashboard/OverviewDash';
import TaskDash from '../digi-tal-dashboard/tasks/TaskDash';
import CustomerDash from '../digi-tal-dashboard/CustomerDash';
import SalesDash from '../digi-tal-dashboard/SalesDash';
import ReceivablesDash from '../digi-tal-dashboard/ReceivablesDash';

import Deals from '../customers/Deals';
import Setup from '../customers/Setup';
import Accounts from '../customers/Accounts';
import Contacts from '../customers/Contacts';
import Products from '../customers/Products';
import OnboardingDashboard from '../onboarding/OnboardingDashboard';
import OnboardingNew from '../onboarding/OnboardingNew';
import OnboardingCurrent from '../onboarding/OnboardingCurrent';
import OffboardingDashboard from '../offboarding/OffboardingDashboard';
import ChurnDetails from '../offboarding/ChurnDetails';
import Revibot from './Revibot';
import TaskView from '../taskboards/views/TaskView';
import MigrateDeal from '../onboarding/MigrateDeal';
import OnboardingFlow from '../onboardingflow/OnboardingFlow';

import EmailMain from '../email/EmailMain';

import TaskBoards from '../taskboards/TaskBoards';
import { getTaskTypeConfig, TaskTypeKey } from '../taskboards/config/board-configs';
import { columnConfigs, type ColumnConfigKey } from '@/taskboards/config/columns';
import { getCardConfig } from '../taskboards/config/card';
import { getBoardStatus } from '../taskboards/config/board-status';
import { CardConfigKey } from '../taskboards/config/card';
import { BoardStatusOption } from '../taskboards/config/board-status';
import { type ColumnDef } from '@/types/table';
import { FrontendTaskType, Task } from '@/types/task';
import axios from '@/api/axiosConfig';
import { useUsers } from '@/contexts/UserContext';
import Kyc from '../customers/Kyc';
import TaskBoardsOverview from '../taskboards/views/TaskBoardsOverview';
import CustomBoardList from '@/taskboards/custom/components/CustomBoardList';
import { UnifiedTask } from '@/types/task';
import { Board, SubTask } from '@/types/custom-board';
import { createUnifiedColumns, convertToUnifiedBoardStatusOption, UnifiedColumnDef, UnifiedBoardStatusOption } from '@/types/unified-board';
import { SubtaskView } from '@/taskboards/views/SubtaskView';

interface TaskBoardProps {
  users: StoredUser[];
  key?: string;
  loading?: boolean;
  title: string;
  fetchUrl: string;
  columns: UnifiedColumnDef<UnifiedTask>[];
  boardStatusOptions: BoardStatusOption[];
  cardConfig: CardConfig;
  dealDataConfig: DealConfigItem[];
  dealSoftwareConfig: DealConfigItem[];
  taskType: UnifiedTaskType;
  defaultView?: 'table' | 'kanban' | 'calendar';
  tasks: UnifiedTask[];
  setTasks: React.Dispatch<React.SetStateAction<UnifiedTask[]>>;
  dealName?: string;
  isCustomBoard?: boolean;
  boardConfig?: {
    kanban_source?: string;
    progress_status_options?: BoardStatusOption[];
    board_status_options?: BoardStatusOption[];
  };
}

interface TaskViewProps {
  onClose: () => void;
  tasks: any[];
  taskType: string;
  boardStatusOptions: any[];
  task?: any;
  fromWork: boolean;
  getBoardName: (type: string) => string;
  dealName: string;
}

interface TaskBoardRouterProps {
  users: StoredUser[];
}

type ThemeType = 'light' | 'dark';

interface PagesUser {
  user_id: string;
  name: string;
  surname: string;
  email: string;
  status: string;
  birthday?: string;
  location_info?: {
    city: string;
    country: string;
    timezone: string;
    latitude: number;
    longitude: number;
  };
  settings?: {
    avatar_color: string;
  };
  is_test_user?: boolean;
}

interface PagesProps {
  user: PagesUser;
}

// Update the TaskViewComponent type
type TaskViewComponent = React.FC<{
  onClose: () => void;
  tasks: Task[];
  taskType: string;
  boardStatusOptions: BoardStatusOption[];
  task?: Task;
  fromWork: boolean;
  getBoardName: (type: string) => string;
  dealName: string;
  boardType?: string;
  taskId?: string;
}>;

// Use type assertion with unknown intermediary
const TaskViewWithTypes = TaskView as unknown as TaskViewComponent;

// Add custom board columns and configs
const customBoardColumns: UnifiedColumnDef<UnifiedTask>[] = createUnifiedColumns([
  {
    title: 'Title',
    key: 'title',
    dataIndex: 'title',
    cell: ({ row }) => row.original.title || '',
  },
  {
    title: 'Description',
    key: 'description',
    dataIndex: 'description',
    cell: ({ row }) => row.original.description || '',
  },
]);

const customCardConfig = {
  showFrequency: false,
  showAssignee: true,
  // Add more config options as needed
};

// Add CustomBoardRoute component to handle custom board state
function CustomBoardRoute() {
  const { boardId } = useParams();
  const [board, setBoard] = useState<Board | null>(null);
  const [tasks, setTasks] = useState<UnifiedTask[]>([]);
  const [selectedTask, setSelectedTask] = useState<UnifiedTask | null>(null);
  const [selectedSubtask, setSelectedSubtask] = useState<SubTask | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { users } = useUsers();

  useEffect(() => {
    const fetchBoardAndTasks = async () => {
      try {
        const [boardRes, tasksRes] = await Promise.all([
          axios.get(`/custom-board/boards/${boardId}`),
          axios.get(`/custom-board/boards/${boardId}/tasks`)
        ]);
        setBoard(boardRes.data);
        setTasks(tasksRes.data.map((task: any) => ({
          ...task,
          task_type: 'custom',
          is_custom: true,
          activity_log: task.activity_log || [],
          created_at: task.created_at || new Date().toISOString(),
          updated_at: task.updated_at || new Date().toISOString(),
          board_status: task.board_status || '',
          assigned_users: task.assigned_users || [],
          description: task.description || '',
          title: task.title || ''
        })));
      } catch (error) {
        console.error('Error fetching board data:', error);
      }
    };
    
    if (boardId) {
      fetchBoardAndTasks();
    }
  }, [boardId]);

  // Handle URL changes to update modal state
  useEffect(() => {
    const match = location.pathname.match(/\/custom-boards\/\d+(?:\/task\/(\d+))?(?:\/subtask\/(\d+))?/);
    if (match) {
      const [, taskId, subtaskId] = match;
      const skipTaskView = location.state?.skipTaskView;

      if (taskId && subtaskId) {
        const task = tasks.find(t => t.id.toString() === taskId);
        if (task) {
          const subtask = task.subtasks?.find(s => s.id.toString() === subtaskId);
          if (subtask) {
            // Set task state only if we're not skipping task view
            if (!skipTaskView) {
              setSelectedTask(task);
            }
            setSelectedSubtask({
              ...subtask,
              progress_status_id: Number(subtask.progress_status_id) || 0,
              created_by: subtask.created_by || 0,
              created_at: subtask.created_at || new Date().toISOString(),
              updated_at: subtask.updated_at || new Date().toISOString(),
            });
            // Set task state after subtask if we're skipping task view
            if (skipTaskView) {
              setTimeout(() => {
                setSelectedTask(task);
              }, 0);
            }
          }
        }
      } else if (taskId) {
        const task = tasks.find(t => t.id.toString() === taskId);
        setSelectedTask(task || null);
        setSelectedSubtask(null);
      } else {
        setSelectedTask(null);
        setSelectedSubtask(null);
      }
    }
  }, [location.pathname, location.state, tasks]);

  const handleCloseTask = () => {
    navigate(`/custom-boards/${boardId}`, { replace: true });
    setSelectedTask(null);
    setSelectedSubtask(null);
  };

  const handleCloseSubtask = () => {
    navigate(`/custom-boards/${boardId}`, { replace: true });
    setSelectedSubtask(null);
    setSelectedTask(null);
  };

  if (!board) return null;

  const boardStatusOptions = convertToUnifiedBoardStatusOption(board.board_config?.board_status_options);
  const progressStatusOptions = convertToUnifiedBoardStatusOption(board.board_config?.progress_status_options);

  return (
    <>
      <TaskBoards 
        title={board.name}
        fetchUrl={`/custom-board/boards/${boardId}/tasks`}
        columns={customBoardColumns}
        boardStatusOptions={boardStatusOptions}
        cardConfig={customCardConfig}
        dealDataConfig={[]}
        dealSoftwareConfig={[]}
        taskType="custom"
        defaultView="kanban"
        users={users}
        tasks={tasks}
        setTasks={setTasks}
        isCustomBoard={true}
        boardConfig={{
          kanban_source: board.board_config?.kanban_source,
          progress_status_options: progressStatusOptions,
          board_status_options: boardStatusOptions
        }}
      />
      {/* Only show TaskView if we're not showing a subtask */}
      {selectedTask && !selectedSubtask && !location.state?.skipTaskView && (
        <TaskView 
          onClose={handleCloseTask}
          tasks={tasks}
          taskType="custom"
          boardStatusOptions={boardStatusOptions}
          task={selectedTask}
          fromWork={false}
          getBoardName={() => board.name}
          dealName=""
          board={board}
          users={users}
        />
      )}
      {selectedTask && selectedSubtask && (
        <SubtaskView 
          subtask={selectedSubtask}
          task={selectedTask}
          board={board}
          onClose={handleCloseSubtask}
        />
      )}
    </>
  );
}

// New component to handle task view
function TaskBoardWithTaskView({ 
  board, 
  tasks, 
  setTasks, 
  boardStatusOptions, 
  progressStatusOptions,
  users 
}: { 
  board: Board;
  tasks: UnifiedTask[];
  setTasks: React.Dispatch<React.SetStateAction<UnifiedTask[]>>;
  boardStatusOptions: UnifiedBoardStatusOption[];
  progressStatusOptions: UnifiedBoardStatusOption[];
  users: StoredUser[];
}) {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const selectedTask = tasks.find(t => t.id.toString() === taskId);

  return (
    <>
      <TaskBoards 
        title={board.name}
        fetchUrl={`/custom-board/boards/${board.id}/tasks`}
        columns={customBoardColumns}
        boardStatusOptions={boardStatusOptions}
        cardConfig={customCardConfig}
        dealDataConfig={[]}
        dealSoftwareConfig={[]}
        taskType="custom"
        defaultView="kanban"
        users={users}
        tasks={tasks}
        setTasks={setTasks}
        isCustomBoard={true}
        boardConfig={{
          kanban_source: board.board_config?.kanban_source,
          progress_status_options: progressStatusOptions,
          board_status_options: boardStatusOptions
        }}
      />
      {selectedTask && (
        <TaskView 
          onClose={() => navigate(`/custom-boards/${board.id}`)}
          tasks={tasks}
          taskType="custom"
          boardStatusOptions={boardStatusOptions}
          task={selectedTask}
          fromWork={false}
          getBoardName={() => board.name}
          dealName=""
        />
      )}
    </>
  );
}

// New component to handle subtask view
function TaskBoardWithSubtaskView({ 
  board, 
  tasks, 
  setTasks, 
  boardStatusOptions, 
  progressStatusOptions,
  users 
}: { 
  board: Board;
  tasks: UnifiedTask[];
  setTasks: React.Dispatch<React.SetStateAction<UnifiedTask[]>>;
  boardStatusOptions: UnifiedBoardStatusOption[];
  progressStatusOptions: UnifiedBoardStatusOption[];
  users: StoredUser[];
}) {
  const { taskId, subtaskId } = useParams();
  const navigate = useNavigate();
  const selectedTask = tasks.find(t => t.id.toString() === taskId);
  const selectedSubtask = selectedTask?.subtasks?.find(s => s.id.toString() === subtaskId);

  return (
    <>
      <TaskBoards 
        title={board.name}
        fetchUrl={`/custom-board/boards/${board.id}/tasks`}
        columns={customBoardColumns}
        boardStatusOptions={boardStatusOptions}
        cardConfig={customCardConfig}
        dealDataConfig={[]}
        dealSoftwareConfig={[]}
        taskType="custom"
        defaultView="kanban"
        users={users}
        tasks={tasks}
        setTasks={setTasks}
        isCustomBoard={true}
        boardConfig={{
          kanban_source: board.board_config?.kanban_source,
          progress_status_options: progressStatusOptions,
          board_status_options: boardStatusOptions
        }}
      />
      {selectedTask && selectedSubtask && (
        <SubtaskView 
          subtask={{
            ...selectedSubtask,
            progress_status_id: Number(selectedSubtask.progress_status_id) || 0,
            created_by: selectedSubtask.created_by || 0,
            created_at: selectedSubtask.created_at || new Date().toISOString(),
            updated_at: selectedSubtask.updated_at || new Date().toISOString(),
          }}
          task={selectedTask}
          board={board}
          onClose={() => navigate(`/custom-boards/${board.id}/task/${taskId}`)}
        />
      )}
    </>
  );
}

export function Pages({ user }: PagesProps) {
  const { theme, toggleTheme } = useTheme();
  const { collapsed } = useSidebar();
  const location = useLocation();
  const { users } = useUsers();
  const navigate = useNavigate();
  const isOnboardingForm = location.pathname.includes('/onboarding/customer-onboarding-form');

  useEffect(() => {
    const handleDirectTaskRoute = () => {
      const navigation = window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
      const isDirectNavigation = navigation?.type === 'reload' || navigation?.type === 'navigate';
      const isFromWorkPage = location.state?.fromWork;
      const isTaskBoardPath = location.pathname.startsWith('/boards/');
      const hasNavigated = sessionStorage.getItem('hasNavigated');

      if (isDirectNavigation && !isFromWorkPage && !isTaskBoardPath && !hasNavigated) {
        const match = location.pathname.match(/^\/work\/([^/]+)\/(\d+)/);
        if (match) {
          const [, taskType, taskId] = match;
          let boardType = taskType === 'other' ? 'andre-opgaver' : 
                         taskType === 'activity' ? 'activities' : 
                         taskType;

          sessionStorage.setItem('hasNavigated', 'true');
          navigate(`/boards/${boardType}/${taskId}`, { 
            replace: true,
            state: { directNavigation: true }
          });
        }
      }
    };

    handleDirectTaskRoute();
    return () => sessionStorage.removeItem('hasNavigated');
  }, [location, navigate]);

  return (
    <SidebarProvider>
      <NotificationProvider>
        <div className="flex min-h-screen">
          <AppSidebar 
            user={user}
            onUpdateUsers={() => {}}
          />
          <div className="flex-1 flex flex-col min-w-0">
            <div className="flex h-full flex-col">
              <AppHeader 
                theme={theme}
                toggleTheme={toggleTheme}
              />
              <main className={cn(
                "flex-1 relative",
                isOnboardingForm ? "p-0" : "p-6"
              )}>
                <Routes>
                  <Route path="/home" element={<Home user={user} />} />

                  <Route path="/work" element={<WorkWithTaskView />}>
                    <Route path=":boardType/:taskId" element={null} />
                  </Route>
                  
                  <Route path="/dashboard" element={<Dashboard />}>
                    <Route index element={<OverviewDash />} />
                    <Route path="tasks" element={<TaskDash />} />
                    <Route path="customers" element={<CustomerDash />} />
                    <Route path="sales" element={<SalesDash />} />
                    <Route path="receivables" element={<ReceivablesDash />} />
                  </Route>
                  
                  <Route path="/customers" element={<Outlet />}>
                    <Route index element={<Navigate to="/customers/deals" replace />} />
                    <Route path="deals">
                      <Route index element={<Deals />} />
                      <Route path="setup" element={<Setup />} />
                    </Route>
                    <Route path="accounts" element={<Accounts />} />
                    <Route path="contacts" element={<Contacts />} />
                    <Route path="products" element={<Products />} />
                    <Route path="kyc" element={<Kyc />} />
                    
                    <Route path="onboarding">
                      <Route index element={<OnboardingDashboard />} />
                      <Route path="cases" element={<OnboardingNew />} />
                      <Route path="current" element={<OnboardingCurrent />} />
                      <Route path="migrations" element={<MigrateDeal />} />
                      <Route path="flow/:dealId/:onboardingId" element={<OnboardingFlow />} />
                    </Route>

                    <Route path="offboarding">
                      <Route index element={<OffboardingDashboard />} />
                      <Route path=":id" element={<ChurnDetails />} />
                    </Route>
                  </Route>

                  <Route path="/revibot" element={<Revibot />} />

                  <Route path="/custom-boards/*">
                    <Route index element={<CustomBoardList />} />
                    <Route path=":boardId/*" element={<CustomBoardRoute />} />
                  </Route>

                  <Route path="/boards">
                    <Route index element={<TaskBoardsOverview />} />
                    <Route path=":boardType/*" element={<BoardTypeRouter users={users} />} />
                  </Route>
                  <Route path="/email" element={<EmailMain />} />
                </Routes>
              </main>
            </div>
          </div>
        </div>
      </NotificationProvider>
    </SidebarProvider>
  );
}

function WorkWithTaskView() {
  const location = useLocation();
  const { boardType, taskId } = useParams();
  const [tasks, setTasks] = useState<any[]>([]);
  const [taskType, setTaskType] = useState<string>('');
  const [boardStatusOptions, setBoardStatusOptions] = useState<any[]>([]);

  return (
    <>
      <Work />
      <Outlet />
      {boardType && taskId && (
        <TaskViewWithTypes 
          boardType={boardType}
          taskId={taskId}
          tasks={tasks}
          taskType={taskType}
          boardStatusOptions={boardStatusOptions}
          fromWork={true}
          getBoardName={(type: string) => type}
          dealName=""
          onClose={() => {/* implement close handler */}}
          task={undefined}
        />
      )}
    </>
  );
}

function TaskBoardRouter({ users }: TaskBoardRouterProps) {
  const { boardType } = useParams();
  const [tasks, setTasks] = useState<UnifiedTask[]>([]);
  const [loading, setLoading] = useState(true);
  const taskConfig = getTaskTypeConfig(boardType as TaskTypeKey);
  const boardStatusOptions = getBoardStatus(boardType as TaskTypeKey);

  // Convert regular board status options to include IDs
  const convertedBoardStatusOptions = boardStatusOptions.map((option, index) => ({
    ...option,
    id: index + 1,
    order: index
  }));

  useEffect(() => {
    const fetchTasks = async () => {
      if (!taskConfig || !boardType) return;
      
      try {
        setLoading(true);
        const response = await axios.get(taskConfig.fetchUrl);
        setTasks(response.data.map((task: any) => ({
          ...task,
          task_type: boardType,
          is_custom: false
        })));
      } catch (error) {
        console.error('Error fetching tasks:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, [boardType, taskConfig]);

  if (!taskConfig || !boardType) {
    return null;
  }

  const convertedColumns = createUnifiedColumns(columnConfigs[boardType as ColumnConfigKey] || []);
  const dealSoftwareConfig = taskConfig.dealSoftwareConfig || [];

  return (
    <TaskBoards 
      title={taskConfig.title}
      fetchUrl={taskConfig.fetchUrl}
      columns={convertedColumns}
      boardStatusOptions={convertedBoardStatusOptions}
      cardConfig={getCardConfig(boardType as CardConfigKey)}
      dealDataConfig={taskConfig.dealDataConfig}
      dealSoftwareConfig={dealSoftwareConfig}
      taskType={boardType as FrontendTaskType}
      defaultView={taskConfig.defaultView}
      users={users}
      tasks={tasks}
      setTasks={setTasks}
      loading={loading}
    />
  );
}

function BoardTypeRouter({ users }: { users: StoredUser[] }) {
  const { boardType } = useParams();
  
  if (boardType === 'custom') {
    return <Navigate to="/custom-boards" replace />;
  }
  
  return <TaskBoardRouter users={users} />;
}
