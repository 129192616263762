import React, { useEffect, useRef } from 'react';
import { GlobeInstance } from '@/types/globe';
import { StoredUser } from '@/types/user';
import { initGlobe, overrideOnBeforeRender } from '@/lib/globe-utils';

interface GlobeProps {
  width: number;
  height: number;
  onGlobeReady: (globe: GlobeInstance) => void;
  users: StoredUser[];
}

export function Globe({ width, height, onGlobeReady, users }: GlobeProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const globeRef = useRef<GlobeInstance | null>(null);

  // Filter for registered users only
  const registeredUsers = users.filter(user => 
    user.name && 
    user.surname && 
    user.location_info?.latitude && 
    user.location_info?.longitude
  );

  // Effect for initial globe setup
  useEffect(() => {
    let mounted = true;

    const setupGlobe = async () => {
      if (globeRef.current || !containerRef.current) return;

      try {
        const globe = await initGlobe();

        // Configure globe
        globe
          .globeImageUrl('//unpkg.com/three-globe/example/img/earth-blue-marble.jpg')
          .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')
          .backgroundColor('rgba(0,0,0,0)')
          .width(width)
          .height(height)
          .pointOfView({ lat: 20, lng: 45, altitude: 2.5 })
          .atmosphereColor('lightskyblue')
          .atmosphereAltitude(0.1);

        if (!containerRef.current) {
          console.error('containerRef.current is null');
          return;
        }

        // Apply to DOM
        globe(containerRef.current);
        //console.log('Globe applied to DOM');

        if (mounted) {
          globeRef.current = globe;
          onGlobeReady(globe);
          //console.log('Globe instance ready');
        }
      } catch (error) {
        console.error('Error setting up globe:', error);
      }
    };

    setupGlobe();

    return () => {
      mounted = false;
      if (globeRef.current) {
        // Enhanced cleanup
        if (globeRef.current.scene()) {
          globeRef.current.scene().traverse((object: any) => {
            if (object.material) {
              if (Array.isArray(object.material)) {
                object.material.forEach((m: any) => {
                  m.onBeforeRender = () => {};
                  m.dispose?.();
                });
              } else {
                object.material.onBeforeRender = () => {};
                object.material.dispose?.();
              }
            }
            if (object.geometry) {
              object.geometry.dispose?.();
            }
          });
        }

        if (globeRef.current.controls()) globeRef.current.controls().dispose();
        if (globeRef.current.renderer()) globeRef.current.renderer().dispose();
        globeRef.current = null;
        console.log('Globe instance cleaned up');
      }
    };
  }, [width, height]); // Only recreate globe on size changes

  // Separate effect for updating points
  useEffect(() => {
    if (globeRef.current) {
      //console.log('Updating globe points with registered users:', registeredUsers);
      
      // Clear existing points first
      globeRef.current.pointsData([]);
      
      // Add new points
      globeRef.current
        .pointsData(registeredUsers)
        .pointLat((d: StoredUser) => d.location_info?.latitude || 0)
        .pointLng((d: StoredUser) => d.location_info?.longitude || 0)
        .pointColor((d: StoredUser) => d.settings?.avatar_color || '#FFFFFF')
        .pointAltitude(0.03)
        .pointRadius(0.5);

      // Override onBeforeRender after updating points
      overrideOnBeforeRender(globeRef.current);
    }
  }, [registeredUsers]); // Update points when registered users change

  return (
    <div className="flex justify-center items-center w-full">
      <div 
        ref={containerRef} 
        style={{ 
          width, 
          height,
          position: 'relative',
          overflow: 'hidden',
          background: 'transparent'
        }} 
      />
    </div>
  );
}