import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import { Loading } from '@/components/ui/loading';
import { LoadingSpinner } from '@/components/ui/loading-spinner';
import { Progress } from '@/components/ui/progress';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { NodeConfig } from './types/node_types';
import { NodeRenderer } from './components/NodeRenderer';
import { useOnboardingStore } from './onboardingStore';
import { OnboardingFormGuide } from './components/OnboardingFormGuide';

interface FlowEdge {
  id: string;
  source: string;
  target: string;
  sourceHandle: string | null;
  targetHandle: string | null;
}

interface OnboardingData {
  id: number;
  deal_id: number;
  current_node_id: string;
  completed_nodes: string[];
  form_data: Record<string, any>;
  flow: {
    nodes: Record<string, any>;
    edges: FlowEdge[];
  };
  current_node: {
    id: string;
    data: {
      label: string;
      config: NodeConfig;
    };
  };
}

export default function OnboardingFlow() {
  const { dealId, onboardingId } = useParams<{ dealId: string; onboardingId: string }>();
  const [loading, setLoading] = useState(true);
  const [navigating, setNavigating] = useState(false);
  const [onboarding, setOnboarding] = useState<OnboardingData | null>(null);
  const [currentNode, setCurrentNode] = useState<NodeConfig | null>(null);
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [previousNodes, setPreviousNodes] = useState<string[]>([]);
  const [dealData, setDealData] = useState<any>(null);
  const { 
    setDeal, 
    setCurrentNodeId,
    setFormData: setFormDataInStore,
    currentNodeId,
    availableEndpoints,
    setAvailableEndpoints 
  } = useOnboardingStore();

  useEffect(() => {
    fetchOnboarding();
    if (dealId) {
      fetchDealData();
    }
  }, [dealId, onboardingId]);

  const fetchDealData = async () => {
    try {
      const response = await axios.get(`/account/deals/${dealId}`);
      setDealData(response.data);
      setDeal(response.data);
    } catch (error) {
      toast.error('Failed to fetch deal data');
      console.error('Error fetching deal data:', error);
    }
  };

  const fetchOnboarding = async () => {
    try {
      const response = await axios.get(`/onboardingflow/${onboardingId}`);
      setOnboarding(response.data);
      
      // Set current node and form data
      if (response.data.current_node) {
        setCurrentNode(response.data.current_node.data.config);
        setFormDataInStore(response.data.current_node.id, response.data.form_data || {});
      }
    } catch (error) {
      toast.error('Failed to fetch onboarding data');
      console.error('Error fetching onboarding:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = async (data: Record<string, any>) => {
    try {
      setNavigating(true);
      const currentNodeData = onboarding?.current_node;
      const rules = currentNodeData?.data.config.rules || [];
      
      // Find which button was clicked
      const clickedButtonId = Object.keys(data).find(key => data[key] === true);
      
      console.log('Form data:', data);
      console.log('Clicked button ID:', clickedButtonId);
      console.log('Current node rules:', rules);
      
      // Find matching rule for the clicked button
      let nextNodeId = null;
      
      if (clickedButtonId) {
        // Find rule that matches the clicked button
        const matchingRule = rules.find(rule => rule.trigger?.elementId === clickedButtonId);
        console.log('Matching rule:', matchingRule);
        if (matchingRule) {
          nextNodeId = matchingRule.targetNodeId;
        }
      }
      
      // If no matching rule found, use default next node
      if (!nextNodeId && currentNodeData?.data.config.defaultNextNodeId) {
        nextNodeId = currentNodeData.data.config.defaultNextNodeId;
      }

      // For the first node (Start button), find the first connected node
      if (!nextNodeId && onboarding?.flow.edges) {
        const edge = onboarding.flow.edges.find(edge => edge.source === currentNodeData?.id);
        if (edge) {
          nextNodeId = edge.target;
        }
      }

      console.log('Next node ID:', nextNodeId);

      if (!nextNodeId) {
        toast.error('No next node found');
        return;
      }

      // Save current node to history before navigating
      if (currentNodeData) {
        setPreviousNodes(prev => [...prev, currentNodeData.id]);
      }

      // Navigate to the next node
      const response = await axios.post(`/onboardingflow/${onboardingId}/navigate`, {
        node_id: nextNodeId,
        form_data: data
      });

      if (response.data.success) {
        await fetchOnboarding();
      }
    } catch (error) {
      toast.error('Failed to submit form data');
      console.error('Error submitting form:', error);
    } finally {
      setNavigating(false);
    }
  };

  const handleBack = async () => {
    if (previousNodes.length === 0) return;
    
    try {
      setNavigating(true);
      // Get the last node from history
      const previousNodeId = previousNodes[previousNodes.length - 1];
      
      // Remove the form data for the current node
      const updatedFormData = { ...formData };
      delete updatedFormData[onboarding?.current_node.id || ''];
      
      // Navigate back
      const response = await axios.post(`/onboardingflow/${onboardingId}/navigate`, {
        node_id: previousNodeId,
        form_data: updatedFormData,
        is_back: true
      });

      if (response.data.success) {
        setPreviousNodes(prev => prev.slice(0, -1));
        await fetchOnboarding();
      }
    } catch (error) {
      toast.error('Failed to go back');
      console.error('Error going back:', error);
    } finally {
      setNavigating(false);
    }
  };

  const getProgress = () => {
    if (!onboarding) return 0;
    
    // Find all possible paths from the start node
    const findLongestPath = (nodeId: string, visited = new Set<string>()): number => {
      if (visited.has(nodeId)) return 0;
      visited.add(nodeId);
      
      // Get all edges starting from this node
      const outgoingEdges = onboarding.flow.edges.filter(edge => edge.source === nodeId);
      if (outgoingEdges.length === 0) return 1;
      
      // Find the longest path through each edge
      const pathLengths = outgoingEdges.map(edge => 
        findLongestPath(edge.target, new Set(visited)) + 1
      );
      
      return Math.max(...pathLengths);
    };
    
    // Find the start node (node with no incoming edges)
    const startNodeId = onboarding.flow.nodes[0].id;
    const totalSteps = findLongestPath(startNodeId);
    
    // Calculate current progress based on distance from start
    const findDistanceFromStart = (nodeId: string): number => {
      const visited = new Set<string>();
      const queue: [string, number][] = [[startNodeId, 0]];
      
      while (queue.length > 0) {
        const [currentId, distance] = queue.shift()!;
        if (currentId === nodeId) return distance;
        if (visited.has(currentId)) continue;
        visited.add(currentId);
        
        const edges = onboarding.flow.edges.filter(edge => edge.source === currentId);
        for (const edge of edges) {
          queue.push([edge.target, distance + 1]);
        }
      }
      
      return 0;
    };
    
    const currentDistance = findDistanceFromStart(onboarding.current_node.id);
    return Math.round((currentDistance / (totalSteps - 1)) * 100);
  };

  if (loading) {
    return <Loading message="Loading onboarding form..." />;
  }

  if (!currentNode) {
    return (
      <div className="p-8">
        <Card>
          <CardHeader>
            <CardTitle>Error</CardTitle>
            <CardDescription>
              Could not load the current onboarding step
            </CardDescription>
          </CardHeader>
        </Card>
      </div>
    );
  }

  if (!onboardingId) {
    return (
      <div className="p-8">
        <Card>
          <CardHeader>
            <CardTitle>Error</CardTitle>
            <CardDescription>
              Invalid onboarding ID
            </CardDescription>
          </CardHeader>
        </Card>
      </div>
    );
  }

  return (
    <div className="p-8 max-w-3xl mx-auto">
      <div className="flex justify-between items-start mb-8">
        <div className="space-y-2">
          <h1 className="text-2xl font-bold text-foreground">Onboarding</h1>
          <p className="text-muted-foreground">Customer setup process</p>
        </div>

        {dealData && (
          <div className="text-right">
            <h2 className="font-semibold text-foreground">{dealData.name}</h2>
            <div className="text-sm text-muted-foreground">
              <p>{dealData.data?.contact_name}</p>
              <p>{dealData.data?.contact_email}</p>
            </div>
          </div>
        )}
      </div>

      {currentNode?.header?.notionGuide && (
        <div className="mb-6 flex items-center justify-between bg-muted p-4 rounded-lg">
          <div className="flex items-center gap-2">
            <h3 className="font-semibold text-foreground">Guide: {currentNode.header.notionGuide.title}</h3>
          </div>
          <OnboardingFormGuide
            title={currentNode.header.notionGuide.title}
            pageId={currentNode.header.notionGuide.pageId}
          />
        </div>
      )}

      <div className="mb-8 space-y-4">
        <Progress value={getProgress()} className="mb-2" />
        <p className="text-sm text-muted-foreground text-right">
          {getProgress()}% Complete
        </p>
      </div>

      <div className="relative">
        {navigating && (
          <div className="absolute inset-0 flex items-center justify-center bg-background/80 backdrop-blur-sm rounded-lg z-10">
            <LoadingSpinner size="lg" />
          </div>
        )}
        <NodeRenderer
          config={currentNode}
          formData={formData}
          onSubmit={handleFormSubmit}
          onBack={previousNodes.length > 0 ? handleBack : undefined}
          dealData={dealData}
          onboardingId={onboardingId}
        />
      </div>
    </div>
  );
} 