import * as React from "react"
import { Check, ChevronsUpDown, Loader2 } from "lucide-react"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { useDebounce } from "@/hooks/use-debounce"
import axios from '@/api/axiosConfig'
import { toast } from "sonner"
import { Checkbox } from "@/components/ui/checkbox"

interface Deal {
  id: number
  name: string
  cvr?: string
  has_tasks?: boolean
}

interface DealSelectorProps {
  value?: string | number
  onChange: (value: number) => void
  className?: string
  boardId?: number
  hideDealsWithTasks?: boolean
  onHideDealsWithTasksChange?: (checked: boolean) => void
}

export function DealSelector({ 
  value, 
  onChange, 
  className,
  boardId,
  hideDealsWithTasks = true,
  onHideDealsWithTasksChange
}: DealSelectorProps) {
  const [open, setOpen] = React.useState(false)
  const [allDeals, setAllDeals] = React.useState<Deal[]>([])
  const [loading, setLoading] = React.useState(false)
  const [searchTerm, setSearchTerm] = React.useState("")

  // Filtered deals based on search term and hideDealsWithTasks
  const deals = React.useMemo(() => {
    let filtered = allDeals;
    
    // Filter by search term
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter(deal => 
        deal.name.toLowerCase().includes(searchLower) || 
        deal.cvr?.toLowerCase().includes(searchLower)
      );
    }

    // Filter out deals with tasks if enabled
    if (hideDealsWithTasks) {
      filtered = filtered.filter(deal => !deal.has_tasks);
    }

    return filtered;
  }, [allDeals, searchTerm, hideDealsWithTasks]);

  // Fetch deals only once on component mount
  React.useEffect(() => {
    const fetchDeals = async () => {
      try {
        setLoading(true)
        const response = await axios.get('/custom-board/deals/search', {
          params: { 
            board_id: boardId,
            limit: 1000
          }
        })
        setAllDeals(response.data)
      } catch (error) {
        console.error('Error fetching deals:', error)
        toast.error('Failed to fetch deals')
      } finally {
        setLoading(false)
      }
    }
    fetchDeals()
  }, [boardId])

  // Convert value to number for comparison
  const selectedDeal = allDeals.find((deal) => deal.id === (typeof value === 'string' ? parseInt(value, 10) : value))

  return (
    <div className="space-y-2">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn("w-full justify-between", className)}
          >
            {value ? (
              selectedDeal ? 
                `${selectedDeal.name} ${selectedDeal.cvr ? `(${selectedDeal.cvr})` : ''}` 
                : "Select a deal..."
            ) : "Select a deal..."}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0">
          <Command className="w-full">
            <CommandInput 
              placeholder="Search deals..." 
              value={searchTerm}
              onValueChange={setSearchTerm}
            />
            <CommandList>
              {loading && (
                <div className="flex items-center justify-center py-6">
                  <Loader2 className="h-4 w-4 animate-spin" />
                </div>
              )}
              {!loading && (
                <>
                  <CommandEmpty>No deals found.</CommandEmpty>
                  <CommandGroup>
                    {deals.map((deal) => (
                      <CommandItem
                        key={deal.id}
                        value={deal.name}
                        onSelect={() => {
                          onChange(deal.id)
                          setOpen(false)
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            value === deal.id ? "opacity-100" : "opacity-0"
                          )}
                        />
                        {deal.name} {deal.cvr ? `(${deal.cvr})` : ''}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      {onHideDealsWithTasksChange && (
        <div className="flex items-center space-x-2">
          <Checkbox
            id="hide-deals-with-tasks"
            checked={hideDealsWithTasks}
            onCheckedChange={onHideDealsWithTasksChange}
          />
          <label
            htmlFor="hide-deals-with-tasks"
            className="text-sm text-muted-foreground cursor-pointer"
          >
            Hide deals with existing tasks
          </label>
        </div>
      )}
    </div>
  )
}