import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axiosConfig';
import { format } from 'date-fns';
import { Card } from '@/components/ui/card';
import { Table } from '@/components/ui/specialized/mod-table';
import { Loading } from '@/components/ui/loading';
import { User, CheckCircle, AlertCircle, RefreshCw } from 'lucide-react';
import { ColumnDef } from '@/components/ui/specialized/mod-table';

interface User {
  name: string;
  avatar_color: string;
}

interface Status {
  id: number;
  name: string;
}

interface Activity {
  id: number;
  deal_name: string;
  user: User;
  last_update: string;
  onboarding_start: string;
  status: Status;
  description: string;
}

interface DashboardData {
  newDeals: number | null;
  currentOnboards: number | null;
  completedOnboards: number | null;
  migrationsCount: number;
}

const OnboardingDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState<DashboardData>({
    newDeals: null,
    currentOnboards: null,
    completedOnboards: null,
    migrationsCount: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [activities, setActivities] = useState<Activity[]>([]);

  useEffect(() => {
    fetchDashboardData();
    fetchActivities();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get('/onboarding/deals');
      if (response.data && response.data.dashboardData) {
        setDashboardData(response.data.dashboardData);
      } else {
        console.error('Invalid data format received from server');
        setDashboardData({
          newDeals: 0,
          currentOnboards: 0,
          completedOnboards: 0,
          migrationsCount: 0
        });
      }
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setDashboardData({
        newDeals: 0,
        currentOnboards: 0,
        completedOnboards: 0,
        migrationsCount: 0
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchActivities = async () => {
    try {
      const response = await axios.get('/onboarding/dashboard-activities');
      if (response.data) {
        setActivities(response.data);
      } else {
        setActivities([]);
        console.error('Invalid activities data format received from server');
      }
    } catch (error) {
      console.error('Error fetching activities:', error);
      setActivities([]);
    }
  };

  const handleRowClick = (record: Activity) => {
    if (record && record.id) {
      navigate(`/onboarding/cases/${record.id}`);
    } else {
      console.error('Invalid record or missing id:', record);
    }
  };

  const columns: ColumnDef<Activity>[] = [
    {
      key: 'deal_name',
      title: 'Deal Name',
      dataIndex: 'deal_name'
    },
    {
      key: 'user',
      title: 'User',
      dataIndex: 'user',
      render: (user: User) => (
        <span
          className="px-2 py-1 rounded-full text-white"
          style={{ backgroundColor: user.avatar_color }}
        >
          {user.name}
        </span>
      )
    },
    {
      key: 'last_update',
      title: 'Update',
      dataIndex: 'last_update',
      render: (date: string) => format(new Date(date), 'dd MMM yyyy HH:mm'),
      sortable: {
        enabled: true,
        type: 'date' as const
      }
    },
    {
      key: 'onboarding_start',
      title: 'Onboarding Start',
      dataIndex: 'onboarding_start',
      render: (date: string) => format(new Date(date), 'dd MMM yyyy HH:mm'),
      sortable: {
        enabled: true,
        type: 'date' as const
      }
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status: Status) => (
        <span className={`status status-${status.id}`}>
          {status.name}
        </span>
      )
    },
    {
      key: 'description',
      title: 'Activity',
      dataIndex: 'description'
    }
  ];

  const renderCardContent = (value: number | null) => {
    return (
      <div className="h-12 flex items-center justify-center">
        {isLoading ? (
          <Loading size="sm" message="" />
        ) : (
          <h2 className="text-2xl">{value}</h2>
        )}
      </div>
    );
  };

  if (isLoading) {
    return <Loading message="Loading onboarding dashboard..." />;
  }

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-8 text-foreground">Onboarding Dashboard</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-8 min-w-[200px]">
        {dashboardData.migrationsCount > 0 && (
          <Card className="p-6 text-center cursor-pointer transition-transform hover:shadow-lg hover:-translate-y-1" onClick={() => navigate('/customers/onboarding/migrations')}>
            <RefreshCw className="h-8 w-8 text-primary mx-auto mb-2" />
            {renderCardContent(dashboardData.migrationsCount)}
            <p className="text-gray-600">Pending Migrations</p>
          </Card>
        )}
        <Card className="p-6 text-center cursor-pointer transition-transform hover:shadow-lg hover:-translate-y-1" onClick={() => navigate('/customers/onboarding/cases')}>
          <AlertCircle className="h-8 w-8 text-primary mx-auto mb-2" />
          {renderCardContent(dashboardData.newDeals)}
          <p className="text-gray-600">New Deals</p>
        </Card>
        <Card className="p-6 text-center cursor-pointer transition-transform hover:shadow-lg hover:-translate-y-1" onClick={() => navigate('/customers/onboarding/current')}>
          <User className="h-8 w-8 text-primary mx-auto mb-2" />
          {renderCardContent(dashboardData.currentOnboards)}
          <p className="text-gray-600">Current Onboards</p>
        </Card>
        <Card className="p-6 text-center">
          <CheckCircle className="h-8 w-8 text-primary mx-auto mb-2" />
          {renderCardContent(dashboardData.completedOnboards)}
          <p className="text-gray-600">Completed Onboards</p>
        </Card>
      </div>
      <div className="p-6">
        <h2 className="text-2xl font-semibold mb-6 text-foreground">Latest Updates</h2>
        <Table 
          columns={columns} 
          data={activities}
          pageSize={15}
          className="cursor-pointer [&_tr:hover]:bg-muted"
          onClick={(e) => {
            const row = (e.target as HTMLElement).closest('tr');
            if (row) {
              const rowIndex = Array.from(row.parentElement?.children || []).indexOf(row);
              const record = activities[rowIndex];
              if (record) {
                handleRowClick(record);
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default OnboardingDashboard;