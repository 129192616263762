import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Trophy, Bell, Settings, Users, Moon, Sun } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { 
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator 
} from '@/components/ui/breadcrumb';
import { Separator } from "@/components/ui/separator";
import { SidebarTrigger } from "@/components/ui/sidebar";
import { Link } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import UserManagementModal from '@/modals/UserManagement';
import SettingsModal from '@/settings/Settings';
import NotificationsModal from '@/modals/Notifications';
import AchievementsModal from '@/modals/Achievements';
import ActivityScreen from '@/modals/ActivityScreen';
import { StoredUser } from '@/types/user'
import { AvatarGroup } from "@/components/ui/avatars/avatar-group";
import { useUsers } from '@/contexts/UserContext';
import { hasRequiredPermission } from '@/utils/permissionUtils';
import { AircallStatus } from '@/components/aircall_status'
import api from '@/api/axiosConfig';
import { showNotificationToast } from '@/components/ui/notification-toast';

interface AppHeaderProps {
  theme: string;
  toggleTheme: () => void;
}

export function AppHeader({ theme, toggleTheme }: AppHeaderProps) {
  const { onlineUsers, currentUser } = useUsers();
  const location = useLocation();
  const [showActivityScreen, setShowActivityScreen] = useState(false);
  const [showAchievements, setShowAchievements] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUserManagementModal, setShowUserManagementModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const fetchUnreadCount = async () => {
    try {
      const response = await api.get('/notifications/unread-count');
      setUnreadCount(response.data.unread_count);
    } catch (err) {
      console.error('Error fetching unread count:', err);
    }
  };

  useEffect(() => {
    fetchUnreadCount();
    // Poll for new notifications every minute
    const interval = setInterval(fetchUnreadCount, 300000); // 5 minutes
    return () => clearInterval(interval);
  }, []);

  const breadcrumbs = location.pathname.split('/')
    .filter(Boolean)
    .map((path, index, array) => ({
      label: path.split('-').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' '),
      path: '/' + array.slice(0, index + 1).join('/'),
      isLast: index === array.length - 1
    }));

  const handleThemeToggle = () => {
    toggleTheme();
    
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      user.settings = {
        ...user.settings,
        theme: theme === 'dark' ? 'light' : 'dark'
      };
      localStorage.setItem('user', JSON.stringify(user));
    }
  };

  const filteredOnlineUsers = Object.values(JSON.parse(localStorage.getItem('usersData') || '{}') as Record<string, StoredUser>)
    .filter((user) => {
      const typedUser = user as StoredUser & { is_test_user?: boolean };
      return !typedUser.is_test_user && 
             typedUser.status === 'online' && 
             currentUser &&
             typedUser.user_id !== currentUser.user_id;
    });

  return (
    <header className="sticky top-0 z-40 flex h-16 shrink-0 items-center border-b border-border bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="flex h-16 w-full items-center gap-4 px-5">
        <div className="flex items-center gap-2">
          <SidebarTrigger className="-ml-1 text-foreground hover:text-foreground/80" />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              {breadcrumbs.map((crumb, index) => (
                <React.Fragment key={crumb.path}>
                  <BreadcrumbItem>
                    {crumb.isLast ? (
                      <BreadcrumbPage>{crumb.label}</BreadcrumbPage>
                    ) : (
                      <BreadcrumbLink asChild>
                        <Link to={crumb.path}>{crumb.label}</Link>
                      </BreadcrumbLink>
                    )}
                  </BreadcrumbItem>
                  {!crumb.isLast && <BreadcrumbSeparator />}
                </React.Fragment>
              ))}
            </BreadcrumbList>
          </Breadcrumb>
        </div>

        <div className="ml-auto flex items-center gap-4">
          {currentUser && hasRequiredPermission(currentUser.permissions, 'owner') && (
            <AircallStatus />
          )}

          <div 
            className="cursor-pointer hover:opacity-80"
            onClick={() => setShowActivityScreen(true)}
          >
            {filteredOnlineUsers.length > 0 ? (
              <AvatarGroup 
                users={filteredOnlineUsers} 
                max={5}
                className="flex -space-x-2"
              />
            ) : (
              <div className="flex h-8 w-8 items-center justify-center rounded-full ring-2 ring-background bg-muted">
                <Users className="h-4 w-4 text-foreground" />
              </div>
            )}
          </div>

          <div className="flex items-center gap-1">
            <Button 
              variant="ghost" 
              size="icon"
              onClick={() => setShowAchievements(true)}
            >
              <Trophy className="h-5 w-5 text-foreground" />
            </Button>

            <Button 
              variant="ghost" 
              size="icon"
              className="relative"
              onClick={() => setShowNotifications(true)}
            >
              <Bell className="h-5 w-5 text-foreground" />
              {unreadCount > 0 && (
                <span className="absolute -top-1 -right-1 flex h-5 w-5 items-center justify-center rounded-full bg-primary text-[10px] font-medium text-primary-foreground">
                  {unreadCount > 99 ? '99+' : unreadCount}
                </span>
              )}
            </Button>

            <Button
              variant="ghost"
              size="icon"
              onClick={handleThemeToggle}
            >
              {theme === 'dark' ? (
                <Sun className="h-5 w-5 text-foreground" />
              ) : (
                <Moon className="h-5 w-5 text-foreground" />
              )}
            </Button>
          </div>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <img 
                src="https://digi-tal-spaces.ams3.cdn.digitaloceanspaces.com/ERP/logos/logo.png"
                alt="DigiTal Logo"
                className="h-8 w-8 rounded-lg cursor-pointer hover:opacity-80 transition-opacity"
              />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuGroup>
                <DropdownMenuItem onClick={() => setShowUserManagementModal(true)}>
                  <Users className="mr-2 h-4 w-4" />
                  User Management
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setShowSettingsModal(true)}>
                  <Settings className="mr-2 h-4 w-4" />
                  Settings
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      {/* Add Modals */}
      <ActivityScreen
        visible={showActivityScreen}
        onClose={() => setShowActivityScreen(false)}
      />
      <AchievementsModal
        visible={showAchievements}
        onClose={() => setShowAchievements(false)}
      />
      <NotificationsModal
        visible={showNotifications}
        onClose={() => {
          setShowNotifications(false);
          fetchUnreadCount(); // Refresh count when modal closes
        }}
      />
      <UserManagementModal
        open={showUserManagementModal}
        onClose={() => setShowUserManagementModal(false)}
      />
      <SettingsModal
        visible={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
      />
    </header>
  );
}