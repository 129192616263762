import React, { useState, useEffect } from 'react'
import { toast } from 'sonner'
import axios from '../api/axiosConfig'
import { Mail, Plus, Send, X } from 'lucide-react'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Textarea } from "@/components/ui/textarea"
import { Badge } from "@/components/ui/badge"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Separator } from "@/components/ui/separator"

import type { Contact } from '@/types/customers'

interface Email {
  id: string | number
  message_id?: string
  sender_name: string
  sender_email: string
  recipients: string[]
  subject: string
  body: string
  sent_at: string
  is_incoming: boolean
}

interface SendEmailModalProps {
  visible: boolean
  onClose: () => void
  contacts: Contact[]
  dealId: string | number
  replyToEmail: Email | null
}

const SendEmailModal: React.FC<SendEmailModalProps> = ({
  visible,
  onClose,
  contacts,
  dealId,
  replyToEmail
}) => {
  const [loading, setLoading] = useState(false)
  const [selectedContact, setSelectedContact] = useState<Contact | null>(contacts[0] || null)
  const [ccRecipients, setCcRecipients] = useState<string[]>([])
  const [newCcEmail, setNewCcEmail] = useState('')
  const [subject, setSubject] = useState(replyToEmail ? `Re: ${replyToEmail.subject}` : '')
  const [body, setBody] = useState('')
  const [replyType, setReplyType] = useState<'reply' | 'replyAll'>('reply')

  // Get the current user from localStorage
  const currentUser = JSON.parse(localStorage.getItem('user') || '{}')

  useEffect(() => {
    if (visible) {
      setSelectedContact(contacts[0] || null)
      setCcRecipients([])
      setNewCcEmail('')
      setSubject(replyToEmail ? `Re: ${replyToEmail.subject}` : '')
      setBody('')
      
      if (replyToEmail && replyType === 'replyAll') {
        setCcRecipients(replyToEmail.recipients || [])
      }
    }
  }, [visible, contacts, replyToEmail, replyType])

  const handleSend = async () => {
    if (!selectedContact && !replyToEmail) {
      toast.error('Please select a contact')
      return
    }

    try {
      setLoading(true)
      
      if (replyToEmail) {
        // Sending a reply
        const replyData = {
          body,
          reply_to_email: replyToEmail.message_id,
          user_id: currentUser.user_id,
          reply_type: replyType,
          deal_id: dealId
        }
        await axios.post('/contactmail/send-reply', replyData)
        toast.success('Reply sent successfully!')
      } else {
        // Sending a new email
        const emailData = {
          to: selectedContact?.email,
          cc: ccRecipients,
          subject,
          body,
          deal_id: dealId,
          user_id: currentUser.user_id
        }
        await axios.post('/contactmail/send-email', emailData)
        toast.success('Email sent successfully!')
      }
      
      onClose()
    } catch (error) {
      console.error('Error sending email:', error)
      toast.error('Failed to send email')
    } finally {
      setLoading(false)
    }
  }

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleAddCc = () => {
    if (newCcEmail && isValidEmail(newCcEmail) && !ccRecipients.includes(newCcEmail)) {
      setCcRecipients([...ccRecipients, newCcEmail])
      setNewCcEmail('')
    } else if (newCcEmail && !isValidEmail(newCcEmail)) {
      toast.error('Please enter a valid email address')
    }
  }

  const handleRemoveCc = (email: string) => {
    setCcRecipients(ccRecipients.filter(cc => cc !== email))
  }

  return (
    <Dialog open={visible} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>
            {replyToEmail ? "Reply to Email" : "Compose Email"}
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-6">
          {replyToEmail && (
            <div className="rounded-lg bg-muted p-4 space-y-2">
              <h4 className="font-medium">Replying to:</h4>
              <p><span className="font-medium">From:</span> {replyToEmail.sender_name} ({replyToEmail.sender_email})</p>
              <p><span className="font-medium">Subject:</span> {replyToEmail.subject}</p>
              <p><span className="font-medium">Sent:</span> {new Date(replyToEmail.sent_at).toLocaleString()}</p>
            </div>
          )}

          {!replyToEmail && (
            <>
              <div className="space-y-2">
                <Label>To</Label>
                <Select
                  value={selectedContact?.id.toString()}
                  onValueChange={(value) => setSelectedContact(contacts.find(c => c.id.toString() === value) || null)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a contact" />
                  </SelectTrigger>
                  <SelectContent>
                    {contacts.map(contact => (
                      <SelectItem key={contact.id} value={contact.id.toString()}>
                        {contact.name} ({contact.email})
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <Label>CC</Label>
                <div className="flex flex-wrap gap-2 p-2 min-h-[2.5rem] rounded-md border">
                  {ccRecipients.map(email => (
                    <Badge key={email} variant="secondary" className="gap-1">
                      {email}
                      <Button
                        variant="ghost"
                        size="icon"
                        className="h-4 w-4 p-0 hover:bg-transparent"
                        onClick={() => handleRemoveCc(email)}
                      >
                        <X className="h-3 w-3" />
                      </Button>
                    </Badge>
                  ))}
                  <div className="flex gap-2">
                    <Input
                      value={newCcEmail}
                      onChange={(e) => setNewCcEmail(e.target.value)}
                      placeholder="Add CC email"
                      className="h-8"
                    />
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={handleAddCc}
                      disabled={!newCcEmail}
                    >
                      <Plus className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}

          {!replyToEmail && (
            <div className="space-y-2">
              <Label>Subject</Label>
              <Input
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Email subject"
              />
            </div>
          )}

          <div className="space-y-2">
            <Label>Message</Label>
            <Textarea
              value={body}
              onChange={(e) => setBody(e.target.value)}
              placeholder="Type your message here"
              className="min-h-[200px]"
            />
          </div>

          <Separator />

          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSend} disabled={loading}>
              <Send className="mr-2 h-4 w-4" />
              {loading ? 'Sending...' : (replyToEmail ? 'Send Reply' : 'Send')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default SendEmailModal 