import * as React from "react"
import { useState, useMemo } from "react"
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@/components/ui/table"
import { 
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger 
} from "@/components/ui/tooltip"
import { 
  Avatar,
  AvatarFallback
} from "@/components/ui/avatar"
import dayjs from 'dayjs'
import { cn } from "@/lib/utils"
import { ScrollArea } from "@/components/ui/scroll-area"

import {
  TaskRecord,
  ExpandedTableRecord,
  Task,
  CellData,
  TaskType,
  TASK_TYPE_DISPLAY_NAMES,
  convertMinutesToHours,
  getWorkloadColor
} from '@/types/workload'

const WORKLOAD_CELL_WIDTH = 40

interface TableColumn {
  title: string
  width: number
  fixed?: boolean
  date?: dayjs.Dayjs
}

interface WorkloadTableProps {
  data: TaskRecord[]
  selectedDate: dayjs.Dayjs
  scrollRef: React.RefObject<HTMLDivElement>
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void
}

const generateWeekColumns = (selectedDate: dayjs.Dayjs): TableColumn[] => {
  const startOfMonth = selectedDate.startOf('month')
  const endOfMonth = selectedDate.endOf('month')
  const columns: TableColumn[] = []

  for (let date = startOfMonth; date.isBefore(endOfMonth); date = date.add(1, 'day')) {
    columns.push({
      title: date.format('DD'),
      width: WORKLOAD_CELL_WIDTH,
      date: date
    })
  }

  return columns
}

export function WorkloadTable({ 
  data, 
  selectedDate,
  scrollRef,
  onScroll 
}: WorkloadTableProps) {
  console.log('WorkloadTable received data:', data) // Debug log

  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set())

  const toggleRow = (userId: number) => {
    const newExpanded = new Set(expandedRows)
    if (newExpanded.has(userId.toString())) {
      newExpanded.delete(userId.toString())
    } else {
      newExpanded.add(userId.toString())
    }
    setExpandedRows(newExpanded)
  }

  // Custom cell renderer for workload data
  const WorkloadCell = ({ 
    cellData, 
    date, 
    tasks, 
    capacity 
  }: { 
    cellData: CellData, 
    date: dayjs.Dayjs, 
    tasks?: Task[], 
    capacity: number 
  }) => {
    const workload = cellData?.workload || 0
    const cellTasks = tasks || cellData?.tasks || []
    const dailyCapacity = capacity || cellData?.capacity || 0
    const hours = convertMinutesToHours(workload)
    const capacityHours = convertMinutesToHours(dailyCapacity)
    const isWeekend = date.day() === 0 || date.day() === 6
    const isEmpty = !workload || !capacity

    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div 
              className="h-full w-full min-h-[40px] flex items-center justify-center border border-border"
              style={{ 
                backgroundColor: getWorkloadColor(workload, dailyCapacity, isWeekend),
                cursor: cellTasks.length ? 'pointer' : 'default'
              }}
            >
              <span className={cn(
                "text-sm font-medium",
                isEmpty ? "text-foreground dark:text-foreground" : "text-foreground dark:text-background"
              )}>
                {hours}
              </span>
            </div>
          </TooltipTrigger>
          <TooltipContent side="top" className="max-w-[300px]">
            <div className="space-y-1">
              <div className="font-semibold">
                {hours} / {capacityHours} hours
              </div>
              {cellTasks.length > 0 && (
                <div className="space-y-2">
                  {cellTasks.map((task, index) => (
                    <div key={index}>
                      <div className="font-semibold">
                        {convertMinutesToHours(task.workload)} hours: {task.description}
                      </div>
                      <div className="text-xs italic text-muted-foreground">
                        {task.deal_name}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }

  // Generate columns based on the selected month
  const columns = useMemo<TableColumn[]>(() => {
    const baseColumns: TableColumn[] = [
      {
        title: "User",
        width: 212,
        fixed: true,
      },
      {
        title: "Tasks",
        width: 69,
      },
      {
        title: "Work Hours",
        width: 110,
      }
    ]

    const weekColumns = generateWeekColumns(selectedDate)
    return [...baseColumns, ...weekColumns]
  }, [selectedDate])

  console.log('WorkloadTable columns:', columns) // Debug log

  // Render expanded content for task types
  const renderExpandedContent = (record: TaskRecord) => {
    const taskTypes = Object.keys(record.task_details || {})
    const expandedData = taskTypes.map(taskType => ({
      key: taskType,
      task_type: taskType,
      assigned_tasks: record.task_details[taskType]?.assigned_tasks || 0,
      total_workload: record.task_details[taskType]?.total_workload || 0,
      daily_workload: record.task_details[taskType]?.daily_workload || {},
      daily_capacity: record.daily_capacity
    }))

    return (
      <div className="w-full">
        {expandedData.map((row) => (
          <div 
            key={row.key} 
            className="flex"
            style={{ 
              borderBottom: '1px solid hsl(var(--border))',
              backgroundColor: 'hsl(var(--muted))'
            }}
          >
            {/* Fixed first column */}
            <div 
              className="sticky left-0 z-20 flex items-center px-4"
              style={{ 
                width: '212px',
                minHeight: '40px',
                backgroundColor: 'hsl(var(--muted))',
                borderRight: '1px solid hsl(var(--border))'
              }}
            >
              {TASK_TYPE_DISPLAY_NAMES[row.task_type as TaskType] || row.task_type}
            </div>

            {/* Tasks count column */}
            <div 
              className="flex items-center justify-center"
              style={{ 
                width: '69px',
                minHeight: '40px',
                borderRight: '1px solid hsl(var(--border))'
              }}
            >
              {row.assigned_tasks}
            </div>

            {/* Work hours column */}
            <div 
              className="flex items-center justify-center"
              style={{ 
                width: '110px',
                minHeight: '40px',
                borderRight: '1px solid hsl(var(--border))'
              }}
            >
              {convertMinutesToHours(row.total_workload)}
            </div>

            {/* Date columns */}
            {columns.filter(col => col.date).map(column => {
              const date = column.date!.format('YYYY-MM-DD')
              const cellData = row.daily_workload[date] || {
                workload: 0,
                tasks: [],
                capacity: row.daily_capacity[date] || 0
              }

              return (
                <div 
                  key={date}
                  style={{ 
                    width: `${WORKLOAD_CELL_WIDTH}px`,
                    borderRight: '1px solid hsl(var(--border))'
                  }}
                >
                  <WorkloadCell
                    cellData={cellData}
                    date={column.date!}
                    tasks={cellData.tasks}
                    capacity={row.daily_capacity[date] || 0}
                  />
                </div>
              )
            })}
          </div>
        ))}
      </div>
    )
  }

  return (
    <ScrollArea className="relative w-full border border-border rounded-md">
      <div className="min-w-max">
        <Table>
          <TableHeader>
            <TableRow>
              {columns.map((column, index) => (
                <TableHead
                  key={index}
                  className={cn(
                    "px-2",
                    column.fixed && "sticky left-0 z-20 bg-background"
                  )}
                  style={{ width: column.width }}
                >
                  {column.title}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((row) => (
              <React.Fragment key={row.user.user_id}>
                <TableRow 
                  onClick={() => toggleRow(row.user.user_id)}
                  className="cursor-pointer hover:bg-accent"
                >
                  <TableCell className="sticky left-0 z-20 bg-background">
                    <div className="flex items-center gap-2">
                      <Avatar>
                        <AvatarFallback 
                          style={{ 
                            backgroundColor: row.user?.settings?.avatar_color || '#1890ff',
                            color: 'white'
                          }}
                        >
                          {row.user?.name?.[0]}{row.user?.surname?.[0]}
                        </AvatarFallback>
                      </Avatar>
                      <span className="truncate">
                        {row.user?.name} {row.user?.surname}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className="text-center">
                    {Object.values(row.task_details).reduce((sum, detail) => 
                      sum + (detail.assigned_tasks || 0), 0
                    )}
                  </TableCell>
                  <TableCell className="text-center">
                    {convertMinutesToHours(
                      Object.values(row.task_details).reduce((sum, detail) => 
                        sum + (detail.total_workload || 0), 0
                      )
                    )}
                  </TableCell>
                  {columns.filter(col => col.date).map(column => {
                    const date = column.date!.format('YYYY-MM-DD')
                    const dailyData: CellData = {
                      workload: Object.values(row.task_details).reduce((sum, detail) => 
                        sum + (detail.daily_workload[date]?.workload || 0), 0
                      ),
                      tasks: Object.values(row.task_details).flatMap(detail => 
                        detail.daily_workload[date]?.tasks || []
                      ),
                      capacity: row.daily_capacity[date] || 0
                    }

                    return (
                      <TableCell key={date} className="p-0">
                        <WorkloadCell
                          cellData={dailyData}
                          date={column.date!}
                          tasks={dailyData.tasks}
                          capacity={dailyData.capacity || 0}
                        />
                      </TableCell>
                    )
                  })}
                </TableRow>
                
                {expandedRows.has(row.user.user_id.toString()) && (
                  <TableRow className="bg-muted/50">
                    <TableCell colSpan={columns.length} className="p-0">
                      {renderExpandedContent(row)}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </div>
    </ScrollArea>
  )
}