import { useEffect, useState, ReactNode } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { UserAvatar } from '@/components/user-avatar'
import { TrendingUp, Users, Building2, Package, Clock } from 'lucide-react'
import axios from '@/api/axiosConfig'
import { BarChartHorizontalMix } from '@/components/ui/charts/bar-horizontal-mix'
import { PieDonutChart } from '@/components/ui/charts/pie-donut'
import { FilteredBarChart, DataPoint } from "@/components/ui/charts/filtered-bar-chart"
import { StatCard } from "@/components/ui/dashboard/stat-card"
import { ScrollArea } from "@/components/ui/scroll-area"
import { formatDate } from "@/lib/utils"
import { useUsers } from '@/contexts/UserContext'
import { StoredUser } from "@/types/user"
import { LoadingSpinner } from "@/components/ui/loading-spinner"
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"

interface ProductSale {
  name: string
  total_sales: number
  product_code: string
}

interface CustomerSale {
  name: string
  total_sales: number
  customer_type: string
  customer_id: number
}

interface MonthlySaleData {
  mrr_paid: number;
  mrr_unpaid: number;
  onboarding_paid: number;
  onboarding_unpaid: number;
  other_paid: number;
  other_unpaid: number;
}

interface TransformedMonthlySaleData extends DataPoint {
  month: string;
  mrr: { paid: number; unpaid: number };
  onboarding: { paid: number; unpaid: number };
  other: { paid: number; unpaid: number };
  [key: string]: string | number | { paid: number; unpaid: number };
}

interface RecentSale {
  id: number
  deal_id: number
  deal_name: string
  deal_value: number
  sales_person_id: number
  meeting_booker_id: number | null
  onboarder_id: number | null
  created_at: string
}

interface SalesData {
  monthly_sales: Record<string, MonthlySaleData>
  quarterly_sales: Record<string, number>
  yearly_sales: Record<string, number>
  product_sales: ProductSale[]
  top_customers: CustomerSale[]
  company_type_sales: Record<string, number>
  current_month_total: number
  current_year_total: number
  month_trend: number
  year_trend: number
}

interface ApiResponse {
  with_vat: SalesData
  without_vat: SalesData
  active_customers_count: number
  avg_time_to_revenue: number
  recent_sales: RecentSale[]
}

export default function SalesDash() {
  const { users } = useUsers()
  const [salesData, setSalesData] = useState<ApiResponse | null>(null)
  const [includeVat, setIncludeVat] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/sales-dash/analytics')
        setSalesData(response.data)
      } catch (error) {
        console.error('Error fetching sales data:', error)
      }
    }
    fetchData()
  }, [])

  if (!salesData) return <LoadingSpinner className="h-[400px]" />

  // Get the current data based on VAT toggle
  const currentData = includeVat ? salesData.with_vat : salesData.without_vat
  const { active_customers_count, avg_time_to_revenue, recent_sales } = salesData

  // Transform data for charts
  const monthlyTrendData: TransformedMonthlySaleData[] = Object.entries(currentData.monthly_sales).map(([month, data]) => ({
    month,
    mrr: {
      paid: data.mrr_paid || 0,
      unpaid: data.mrr_unpaid || 0
    },
    onboarding: {
      paid: data.onboarding_paid || 0,
      unpaid: data.onboarding_unpaid || 0
    },
    other: {
      paid: data.other_paid || 0,
      unpaid: data.other_unpaid || 0
    }
  } as TransformedMonthlySaleData))

  const productDistributionData = currentData.product_sales.map((product, index) => ({
    name: product.name,
    value: product.total_sales,
    code: product.product_code,
    fill: `var(--chart-${(index % 12) + 1})`
  }))

  const customerData = currentData.top_customers.map((customer, index) => ({
    name: customer.name,
    value: customer.total_sales,
    type: customer.customer_type,
    fill: `var(--chart-${(index % 12) + 1})`
  }))

  const companyTypeData = Object.entries(currentData.company_type_sales).map(([type, amount]) => ({
    name: type,
    value: amount
  }))

  const salesFilters = [
    { id: 'mrr', label: 'MRR', color: 'hsl(var(--chart-multi-1))' },
    { id: 'onboarding', label: 'Onboarding', color: 'hsl(var(--chart-multi-2))' },
    { id: 'other', label: 'Other', color: 'hsl(var(--chart-multi-3))' },
  ]

  // Add calculation for monthly total
  const currentMonthTotal = Object.values(currentData.monthly_sales).reduce((total, data) => {
    return total + (data.mrr_paid || 0) + (data.mrr_unpaid || 0) +
      (data.onboarding_paid || 0) + (data.onboarding_unpaid || 0) +
      (data.other_paid || 0) + (data.other_unpaid || 0)
  }, 0)
  
  return (
    <div className="p-8 space-y-8">
      {/* Top Stats */}
      <div className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-4">
        <StatCard
          title="Total Sales This Year"
          value={currentData.current_year_total}
          icon={TrendingUp}
          valueFormatter={(val) => new Intl.NumberFormat('da-DK', { 
            style: 'currency', 
            currency: 'DKK',
            maximumFractionDigits: 0 
          }).format(val)}
          trend={{
            value: currentData.year_trend,
            isUpward: currentData.year_trend > 0,
            label: "vs last year"
          }}
        />
        <StatCard
          title="Total Sales This Month"
          value={currentData.current_month_total}
          icon={TrendingUp}
          valueFormatter={(val) => new Intl.NumberFormat('da-DK', { 
            style: 'currency', 
            currency: 'DKK',
            maximumFractionDigits: 0 
          }).format(val)}
          trend={{
            value: currentData.month_trend,
            isUpward: currentData.month_trend > 0,
            label: "vs last month"
          }}
        />
        <StatCard
          title="Active Customers"
          value={salesData.active_customers_count}
          icon={Users}
        />
        <StatCard
          title="Product Categories"
          value={Object.keys(currentData.product_sales).length}
          icon={Package}
        />
        <StatCard
          title="Avg. Time to Revenue"
          value={salesData.avg_time_to_revenue ?? 45}
          icon={Clock}
          suffix="days"
        />
      </div>

      {/* Charts Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Recent Sales - First column */}
        <Card>
          <CardHeader>
            <CardTitle>Recent Sales</CardTitle>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[400px] pr-4">
              <div className="space-y-4">
                {salesData.recent_sales.map((sale: RecentSale) => (
                  <div 
                    key={sale.id} 
                    className="flex items-center justify-between p-2 rounded-lg border border-border hover:bg-muted/50 transition-colors"
                  >
                    <div className="flex items-center gap-4">
                      <div className="flex -space-x-2">
                        <UserAvatar
                          user={users.find(u => u.user_id === sale.sales_person_id) as StoredUser}
                          size={40}
                          className="border-2 border-background"
                          tooltipPlacement="top"
                          tooltipContent={`Sales: ${users.find(u => u.user_id === sale.sales_person_id)?.name || ''} ${users.find(u => u.user_id === sale.sales_person_id)?.surname || ''}`}
                        />
                        {sale.meeting_booker_id && (
                          <UserAvatar
                            user={users.find(u => u.user_id === sale.meeting_booker_id) as StoredUser}
                            size={32}
                            className="border-2 border-background"
                            tooltipPlacement="bottom"
                            tooltipContent={`Meeting Booker: ${users.find(u => u.user_id === sale.meeting_booker_id)?.name || ''} ${users.find(u => u.user_id === sale.meeting_booker_id)?.surname || ''}`}
                          />
                        )}
                        {sale.onboarder_id && (
                          <UserAvatar
                            user={users.find(u => u.user_id === sale.onboarder_id) as StoredUser}
                            size={32}
                            className="border-2 border-background"
                            tooltipPlacement="right"
                            tooltipContent={`Onboarder: ${users.find(u => u.user_id === sale.onboarder_id)?.name || ''} ${users.find(u => u.user_id === sale.onboarder_id)?.surname || ''}`}
                          />
                        )}
                      </div>
                      <div>
                        <div className="font-medium text-foreground">{sale.deal_name}</div>
                        <div className="text-sm text-muted-foreground">
                          {formatDate(sale.created_at)}
                        </div>
                      </div>
                    </div>
                    <div className="font-medium text-foreground">
                      {new Intl.NumberFormat('da-DK', { 
                        style: 'currency', 
                        currency: 'DKK',
                        maximumFractionDigits: 0 
                      }).format(sale.deal_value || 0)}
                    </div>
                  </div>
                ))}
              </div>
            </ScrollArea>
          </CardContent>
        </Card>

        {/* Monthly Sales Trend - Takes up 2 columns */}
        <div className="lg:col-span-2">
          <FilteredBarChart
            title={
              <div className="flex items-center justify-between">
                <span>Monthly Sales Trend</span>
                <div className="flex items-center gap-2">
                  <Label htmlFor="vat-toggle" className="text-sm text-muted-foreground">
                    {includeVat ? 'Including VAT' : 'Excluding VAT'}
                  </Label>
                  <Switch
                    id="vat-toggle"
                    checked={includeVat}
                    onCheckedChange={setIncludeVat}
                  />
                </div>
              </div>
            }
            description="Sales distribution by type"
            data={monthlyTrendData}
            filters={salesFilters}
            xAxisKey="month"
            valueFormatter={(value) => `${value.toLocaleString()} kr.`}
            variant="grouped-stacked"
            barGap={4}
            barGroupGap={32}
          />
        </div>

        {/* Three charts in a row - Each takes up 1 column */}
        <div className="min-h-[200px]">
          <BarChartHorizontalMix
            title="Product Distribution"
            data={productDistributionData}
            config={{}}
            description="Sales by product category"
            valueFormatter={(value) => `${value.toLocaleString()} kr.`}
            maxLabelLength={25}
            className="h-full"
            colorMode="multi"
          />
        </div>

        <div className="min-h-[200px]">
          <PieDonutChart
            title="Company Types"
            data={companyTypeData}
            config={{
              value: { label: "Sales" }
            }}
            description="Sales distribution by company type"
            centerLabel={{
              value: Object.values(currentData.company_type_sales).reduce((a, b) => a + b, 0),
              label: "Total Sales"
            }}
            colorMode="multi"
          />
        </div>

        <div className="min-h-[200px]">
          <BarChartHorizontalMix
            title="Top Customers"
            data={customerData}
            config={{}}
            description="Top 10 customers by sales"
            valueFormatter={(value) => `${value.toLocaleString()} kr.`}
            colorMode="multi"
          />
        </div>
      </div>
    </div>
  )
}