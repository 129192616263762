import { useState } from 'react'
import { Phone, PhoneOff } from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"

type AircallStatus = 'online' | 'away'

export function AircallStatus() {
  const [status, setStatus] = useState<AircallStatus>('online')

  const toggleStatus = (newStatus: AircallStatus) => {
    setStatus(newStatus)
    // TODO: Implement backend integration
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="ghost" 
          size="sm" 
          className={cn(
            "h-8 gap-2 px-3",
            status === 'online' 
              ? "text-green-500 dark:text-green-400 hover:text-green-600 dark:hover:text-green-300" 
              : "text-yellow-500 dark:text-yellow-400 hover:text-yellow-600 dark:hover:text-yellow-300"
          )}
        >
          {status === 'online' ? (
            <>
              <Phone className="h-4 w-4" />
              <span className="text-sm font-medium">Available</span>
            </>
          ) : (
            <>
              <PhoneOff className="h-4 w-4" />
              <span className="text-sm font-medium">Away</span>
            </>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="center">
        <DropdownMenuItem 
          onClick={() => toggleStatus('online')}
          className={cn(
            "gap-2",
            status === 'online' && "bg-accent"
          )}
        >
          <Phone className="h-4 w-4 text-green-500 dark:text-green-400" />
          <span>Available</span>
        </DropdownMenuItem>
        <DropdownMenuItem 
          onClick={() => toggleStatus('away')}
          className={cn(
            "gap-2",
            status === 'away' && "bg-accent"
          )}
        >
          <PhoneOff className="h-4 w-4 text-yellow-500 dark:text-yellow-400" />
          <span>Away</span>
        </DropdownMenuItem>
        <div className="px-2 py-1 text-xs text-right text-muted-foreground/50">
          Aircall Status
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
