interface Quote {
    date: string;
    text: string;
    author: string;
  }
  
  const quotes: Quote[] = [
    {
      date: "01-01-2025",
      text: "All our dreams can come true, if we have the courage to pursue them.",
      author: "Walt Disney"
    },
    {
      date: "02-01-2025",
      text: "Don't sit down and wait for the opportunities to come. Get up and make them.",
      author: "Madam C.J. Walker"
    },
    {
      date: "03-01-2025",
      text: "Champions keep playing until they get it right.",
      author: "Billie Jean King"
    },
    {
      date: "04-01-2025",
      text: "All dreams are within reach. All you have to do is keep moving towards them.",
      author: "Viola Davis"
    },
    {
      date: "05-01-2025",
      text: "Don't count the days, make the days count.",
      author: "Muhammad Ali"
    },
    {
      date: "06-01-2025",
      text: "If you risk nothing, then you risk everything.",
      author: "Geena Davis"
    },
    {
      date: "07-01-2025",
      text: "If you prioritize yourself, you are going to save yourself.",
      author: "Gabrielle Union"
    },
    {
      date: "08-01-2025",
      text: "You can't cross the sea merely by standing and staring at the water.",
      author: "Rabindranath Tagore"
    },
    {
      date: "09-01-2025",
      text: "Success is not final, failure is not fatal: it is the courage to continue that counts.",
      author: "Winston Churchill"
    },
    {
      date: "10-01-2025",
      text: "Wake up determined, go to bed satisfied.",
      author: "Dwayne \"The Rock\" Johnson"
    },
    {
      date: "11-01-2025",
      text: "Perfection is not attainable, but if we chase perfection we can catch excellence.",
      author: "Vince Lombardi"
    },
    {
      date: "12-01-2025",
      text: "The best preparation for tomorrow is doing your best today.",
      author: "H. Jackson Brown Jr."
    },
    {
      date: "13-01-2025",
      text: "Alone we can do so little. Together we can do so much.",
      author: "Helen Keller"
    },
    {
      date: "14-01-2025",
      text: "If everyone is moving forward together, then success takes care of itself.",
      author: "Henry Ford"
    },
    {
      date: "15-01-2025",
      text: "If you are working on something that you really care about, you don't have to be pushed. The vision pulls you.",
      author: "Steve Jobs"
    },
    {
      date: "16-01-2025",
      text: "A successful man is one who can lay a firm foundation with the bricks others have thrown at him.",
      author: "David Brinkley"
    },
    {
      date: "17-01-2025",
      text: "Talent wins games, but teamwork and intelligence win championships.",
      author: "Michael Jordan"
    },
    {
      date: "18-01-2025",
      text: "The secret of getting ahead is getting started.",
      author: "Mark Twain"
    },
    {
      date: "19-01-2025",
      text: "Don't follow the crowd, let the crowd follow you.",
      author: "Margaret Thatcher"
    },
    {
      date: "20-01-2025",
      text: "Good leadership requires you to surround yourself with people of diverse perspectives who can disagree with you without fear of retaliation.",
      author: "Doris Kearns Goodwin"
    },
    {
      date: "21-01-2025",
      text: "The only time you fail is when you fall down and stay down.",
      author: "Stephen Richards"
    },
    {
      date: "22-01-2025",
      text: "Happiness is the only thing that multiplies when you share it.",
      author: "Albert Schweitzer"
    },
    {
      date: "23-01-2025",
      text: "Winning doesn't always mean being first. Winning means you're doing better than you've done before.",
      author: "Bonnie Blair"
    },
    {
      date: "24-01-2025",
      text: "If opportunity doesn't knock, build a door.",
      author: "Milton Berle"
    },
    {
      date: "25-01-2025",
      text: "Success is the sum of small efforts repeated day in and day out.",
      author: "Robert Collier"
    },
    {
      date: "26-01-2025",
      text: "Ha en go dag og husk: 'I dag er den første dag i resten af dit liv'",
      author: "Mia Lyhne"
    },
    {
      date: "27-01-2025",
      text: "Nu er det Malaga, det på Puerto Banús og det ned på tango, stor bøf, Havana Club og så det i byen!",
      author: "Casper Christensen"
    },
    {
      date: "28-01-2025",
      text: "I'm going to make him an offer he can't refuse.",
      author: "The Godfather, 1972"
    },
    {
      date: "29-01-2025",
      text: "And, when you want something, all the universe conspires in helping you to achieve it.",
      author: "Paulo Coelho"
    },
    {
      date: "30-01-2025",
      text: "Don't judge each day by the harvest you reap but by the seeds that you plant.",
      author: "Robert Louis Stevenson"
    },
    {
      date: "31-01-2025",
      text: "Success is not how high you have climbed, but how you make a positive difference to the world.",
      author: "Roy T. Bennett"
    },
    {
      date: "01-02-2025",
      text: "You never fail until you stop trying.",
      author: "Albert Einstein"
    },
    {
      date: "02-02-2025",
      text: "All you need is the plan, the road map, and the courage to press on to your destination.",
      author: "Earl Nightingale"
    },
    {
      date: "03-02-2025",
      text: "Each day provides its own gifts.",
      author: "Marcus Aurelius"
    },
    {
      date: "04-02-2025",
      text: "No act of kindness, no matter how small, is ever wasted.",
      author: "Aesop"
    },
    {
      date: "05-02-2025",
      text: "Always let your conscience be your guide.",
      author: "Jiminy Cricket"
    },
    {
      date: "06-02-2025",
      text: "I want to reach my potential, impact my world, and leave a legacy.",
      author: "Seth Buechley"
    },
    {
      date: "07-02-2025",
      text: "Make your life matter and have fun doing it.",
      author: "Aaron Hurst"
    },
    {
      date: "08-02-2025",
      text: "We may not have hit the peak yet, but we are on the journey up.",
      author: "Brian Nhira"
    },
    {
      date: "09-02-2025",
      text: "YOLO! You only live once, but if you do it right, once is enough.",
      author: "Mae West"
    },
    {
      date: "10-02-2025",
      text: "I can't change the direction of the wind, but I can adjust my sails to always reach my destination.",
      author: "Jimmy Dean"
    },
    {
      date: "11-02-2025",
      text: "Shoot for the moon. Even if you miss, you'll land among the stars.",
      author: "Norman Vincent Peale"
    },
    {
      date: "12-02-2025",
      text: "It is our attitude at the beginning of a difficult task which, more than anything else, will affect its successful outcome.",
      author: "William James"
    },
    {
      date: "13-02-2025",
      text: "The miracle is not that we do this work, but that we are happy to do it.",
      author: "Mother Theresa"
    },
    {
      date: "14-02-2025",
      text: "I never dreamed about success. I worked for it.",
      author: "Esteé Lauder"
    },
    {
      date: "15-02-2025",
      text: "Why do they call it rush hour when nothing moves?",
      author: "Robin Williams"
    },
    {
      date: "16-02-2025",
      text: "Life itself is the most wonderful fairy tale.",
      author: "Hans Christian Andersen"
    },
    {
      date: "17-02-2025",
      text: "It always seems impossible until it's done.",
      author: "Nelson Mandela"
    },
    {
      date: "18-02-2025",
      text: "Laugh as much as you breathe and love as long as you live.",
      author: "Johnny Depp"
    },
    {
      date: "19-02-2025",
      text: "Let the beauty of what you love be what you do.",
      author: "Rumi"
    },
    {
      date: "20-02-2025",
      text: "Be so good they can't ignore you.",
      author: "Steve Martin"
    },
    {
      date: "21-02-2025",
      text: "The successful warrior is the average man, with laser-like focus.",
      author: "Bruce Lee"
    },
    {
      date: "22-02-2025",
      text: "The future belongs to those who believe in the beauty of their dreams.",
      author: "Eleanor Roosevelt"
    },
    {
      date: "23-02-2025",
      text: "Impossible is for the unwilling.",
      author: "John Keats"
    },
    {
      date: "24-02-2025",
      text: "Good things happen to those who hustle.",
      author: "Anaïs Nin"
    },
    {
      date: "25-02-2025",
      text: "Take the risk or lose the chance.",
      author: "Anonymous"
    },
    {
      date: "26-02-2025",
      text: "Life is not a problem to be solved, but a reality to be experienced.",
      author: "Søren Kirkegaard"
    },
    {
      date: "27-02-2025",
      text: "My mama always said, life is like a box of chocolates. You never know what you're gonna get.",
      author: "Forrest Gump"
    },
    {
      date: "28-02-2025",
      text: "Think big thoughts, but relish the small pleasures.",
      author: "H. Jackson Brown, Jr."
    },
    {
      date: "29-02-2025",
      text: "A little progress each day adds up to big results.",
      author: "Anonymous"
    },
    {
      date: "01-03-2025",
      text: "Learn to rest, not to quit.",
      author: "Anonymous"
    },
    {
      date: "02-03-2025",
      text: "Take care of yourself; you can't pour from an empty cup.",
      author: "Anonymous"
    },
    {
      date: "03-03-2025",
      text: "Behind every great team is an even better coffee machine.",
      author: "Anonymous"
    },
    {
      date: "04-03-2025",
      text: "Keep your eyes on the stars, and your feet on the ground.",
      author: "Theodore Roosevelt"
    },
    {
      date: "05-03-2025",
      text: "If you're not willing to risk the usual, you'll have to settle for the ordinary.",
      author: "Jim Rohn"
    },
    {
      date: "06-03-2025",
      text: "Don't limit your challenges; challenge your limits.",
      author: "Anonymous"
    },
    {
      date: "07-03-2025",
      text: "Teamwork makes the dream work.",
      author: "John C. Maxwell"
    },
    {
      date: "08-03-2025",
      text: "Hardships often prepare ordinary people for an extraordinary destiny.",
      author: "C.S. Lewis"
    },
    {
      date: "09-03-2025",
      text: "A strong team can take any vision and turn it into reality.",
      author: "Anonymous"
    },
    {
      date: "10-03-2025",
      text: "Winning teams are built on trust, effort, and celebration.",
      author: "Anonymous"
    },
    {
      date: "11-03-2025",
      text: "Every member of the team plays a role in our success.",
      author: "Anonymous"
    },
    {
      date: "12-03-2025",
      text: "Optimism turns setbacks into comebacks.",
      author: "Anonymous"
    },
    {
      date: "13-03-2025",
      text: "Don't stop until you're proud.",
      author: "Anonymous"
    },
    {
      date: "14-03-2025",
      text: "Optimism is the faith that leads to achievement.",
      author: "Helen Keller"
    }
  ];
  
  export const fallbackQuote: Quote = {
    date: "",
    text: "Life is what happens while you're busy making other plans",
    author: "John Lennon"
  };
  
  export const getQuoteForDate = (date: string): Quote => {
    const quote = quotes.find(q => q.date === date);
    return quote || fallbackQuote;
  };
  
  export default quotes;