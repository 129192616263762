import { TextConfig as TextConfigType } from '@/onboardingflow/types/node_types';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';

interface TextConfigProps {
  config: TextConfigType;
  elementId: string;
  onChange: (updates: Partial<TextConfigType>) => void;
}

export const TextConfig = ({
  config,
  elementId,
  onChange
}: TextConfigProps) => {
  return (
    <div className="space-y-4">
      <div>
        <Label>Content</Label>
        <Textarea
          value={config.content}
          onChange={(e) => onChange({ content: e.target.value })}
          placeholder="Enter text content"
        />
      </div>
      <div>
        <Label>Variant</Label>
        <Select
          value={config.variant}
          onValueChange={(value) => onChange({ variant: value as 'default' | 'muted' | 'heading' | 'subheading' })}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select variant" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="default">Default</SelectItem>
            <SelectItem value="muted">Muted</SelectItem>
            <SelectItem value="heading">Heading</SelectItem>
            <SelectItem value="subheading">Subheading</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};
