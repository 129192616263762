import React from 'react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Send, Save, Trash2 } from 'lucide-react';
import { EmailEditor } from './EmailEditor';
import { EmailRecipients } from './EmailRecipients';
import { EmailAttachments } from './EmailAttachments';
import { EmailTemplates } from './EmailTemplates';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import { toast } from "sonner"
import axios from '@/api/axiosConfig'
import { EmailSender } from './EmailSender';

export default function EmailMain() {
  const [recipients, setRecipients] = React.useState<{
    to: Array<{ email: string; name: string; isCustom?: boolean }>;
    cc: Array<{ email: string; name: string; isCustom?: boolean }>;
    bcc: Array<{ email: string; name: string; isCustom?: boolean }>;
  }>({
    to: [],
    cc: [],
    bcc: []
  });
  const [subject, setSubject] = React.useState('');
  const [content, setContent] = React.useState('');
  const [attachments, setAttachments] = React.useState<Array<{
    id: string;
    name: string;
    size: number;
    progress: number;
  }>>([]);
  const [isSending, setIsSending] = React.useState(false);
  const [senderEmail, setSenderEmail] = React.useState<string>('');

  const handleAddRecipient = (recipient: { 
    email: string; 
    name: string; 
    type: 'to' | 'cc' | 'bcc';
    isCustom?: boolean;
  }) => {
    const { type, ...recipientData } = recipient;
    setRecipients(prev => ({
      ...prev,
      [type]: [...prev[type], recipientData]
    }));
  };

  const handleRemoveRecipient = (email: string, type: 'to' | 'cc' | 'bcc') => {
    setRecipients(prev => ({
      ...prev,
      [type]: prev[type].filter(r => r.email !== email)
    }));
  };

  const handleAddAttachments = (files: FileList) => {
    const newAttachments = Array.from(files).map((file) => ({
      id: Math.random().toString(36).substr(2, 9),
      name: file.name,
      size: file.size,
      progress: 0,
    }));
    setAttachments((prev) => [...prev, ...newAttachments]);
  };

  const handleRemoveAttachment = (id: string) => {
    setAttachments((prev) => prev.filter((a) => a.id !== id));
  };

  const handleDiscard = () => {
    setRecipients({
      to: [],
      cc: [],
      bcc: []
    });
    setSubject('');
    setContent('');
    setAttachments([]);
  };

  const handleSendEmail = async () => {
    console.log('Send button clicked');
    console.log('Current state:', {
      senderEmail,
      recipients,
      subject,
      content,
    });

    if (!senderEmail) {
      toast.error("Please select a sender email");
      return;
    }

    if (recipients.to.length === 0 && recipients.cc.length === 0 && recipients.bcc.length === 0) {
      toast.error("Please add at least one recipient");
      return;
    }

    if (!subject) {
      toast.error("Please add a subject");
      return;
    }

    if (!content) {
      toast.error("Please add some content to your email");
      return;
    }

    setIsSending(true);
    try {
      console.log('Sending request to backend...');
      const response = await axios.post('/mail/send-email', {
        sender: senderEmail,
        recipients: recipients,
        subject: subject,
        content: content,
      });

      console.log('Response received:', response.data);
      toast.success("Email sent successfully");
      handleDiscard();
    } catch (error: any) {
      console.error('Error sending email:', error);
      console.error('Error response:', error.response?.data);
      toast.error(error.response?.data?.error || "Failed to send email");
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="flex flex-col h-full gap-4">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-foreground">New Email</h1>
        <div className="flex gap-2">
          <EmailTemplates
            onSelectTemplate={(template) => {
              setSubject(template.subject);
              setContent(template.content);
            }}
            onSaveAsTemplate={(name, subject, content) => {
              // Implement save template logic
            }}
            disabled
          />
          <Button variant="outline" size="sm" disabled>
            <Save className="h-4 w-4 mr-2" />
            Save Draft
          </Button>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant="outline" size="sm" className="text-destructive">
                <Trash2 className="h-4 w-4 mr-2" />
                Discard
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This will delete your draft and clear all content. This action cannot be undone.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={handleDiscard}>
                  Delete draft
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>

      {/* Email Form */}
      <div className="bg-card rounded-lg border border-border p-4 flex flex-col gap-4">
        <EmailSender onSenderChange={(email) => setSenderEmail(email)} />

        <EmailRecipients
          recipients={recipients}
          onAddRecipient={handleAddRecipient}
          onRemoveRecipient={handleRemoveRecipient}
        />

        <Input
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="w-full"
        />

        <EmailEditor
          content={content}
          onChange={setContent}
        />

        <EmailAttachments
          attachments={attachments}
          onAddAttachments={handleAddAttachments}
          onRemoveAttachment={handleRemoveAttachment}
        />

        {/* Footer */}
        <div className="flex justify-end gap-2">
          <Button 
            onClick={handleSendEmail} 
            disabled={isSending}
            className="flex items-center"
          >
            <Send className="h-4 w-4 mr-2" />
            {isSending ? "Sending..." : "Send Email"}
          </Button>
        </div>
      </div>
    </div>
  );
}