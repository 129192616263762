import React from 'react';
import { Trophy, LineChart } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogBody,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

interface AchievementsModalProps {
  visible: boolean;
  onClose: () => void;
}

const AchievementsModal: React.FC<AchievementsModalProps> = ({ visible, onClose }) => {
  return (
    <Dialog open={visible} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Achievements & Leaderboard</DialogTitle>
          <DialogDescription className="sr-only">
            View your achievements and compare your progress on the leaderboard
          </DialogDescription>
        </DialogHeader>
        <DialogBody>
          <Tabs defaultValue="achievements" className="w-full">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="achievements">Achievements</TabsTrigger>
              <TabsTrigger value="leaderboard">Leaderboard</TabsTrigger>
            </TabsList>
            
            <TabsContent value="achievements">
              <div className="flex flex-col items-center py-8 text-center">
                <Trophy 
                  className="h-16 w-16 mb-6 text-yellow-500 animate-bounce" 
                  strokeWidth={1.5} 
                />
                <div className="max-w-[350px]">
                  <h3 className="text-lg font-semibold mb-4">
                    Achievements Coming Soon!
                  </h3>
                  <p className="text-muted-foreground leading-relaxed">
                    Soon you'll be able to unlock achievements for your contributions 
                    and milestones in Tuesday. Stay tuned for exciting challenges 
                    and rewards! 🏆
                  </p>
                </div>
              </div>
            </TabsContent>
            
            <TabsContent value="leaderboard">
              <div className="flex flex-col items-center py-8 text-center">
                <LineChart 
                  className="h-16 w-16 mb-6 text-blue-500 animate-bounce" 
                  strokeWidth={1.5} 
                />
                <div className="max-w-[350px]">
                  <h3 className="text-lg font-semibold mb-4">
                    Leaderboard Under Construction
                  </h3>
                  <p className="text-muted-foreground leading-relaxed">
                    The race to the top begins soon! Compare your achievements with 
                    teammates and climb the ranks. Coming to Tuesday in a future 
                    update! 📈
                  </p>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};

export default AchievementsModal;