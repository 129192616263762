import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';

interface UserAvatarProps {
  user: {
    user_id: string | number;
    name?: string;
    surname?: string;
    avatar_urls?: {
      medium?: string;
      small?: string;
    };
    settings?: {
      avatar_color?: string;
    };
    status?: string;
    initials?: string;
  };
  size?: number;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  tooltipContent?: string;
  disableTooltip?: boolean;
}

export const UserAvatar = ({
  user,
  size = 32,
  style = {},
  className = '',
  onClick,
  tooltipPlacement = 'top',
  tooltipContent,
  disableTooltip = false,
}: UserAvatarProps) => {
  const { theme } = useTheme();

  if (!user) return null;

  const getInitials = (name?: string, surname?: string) => {
    if (!name && !surname) return '?';
    return `${name ? name[0] : ''}${surname ? surname[0] : ''}`.toUpperCase();
  };

  const initials = user.initials || getInitials(user.name, user.surname);
  const avatarColor = user.settings?.avatar_color || '#f56a00';

  const getAvatarUrl = () => {
    const urls = user.avatar_urls;
    if (!urls || Object.keys(urls).length === 0) return undefined;
    
    if (size > 40 || className.includes('w-full')) {
      return urls.medium;
    }
    return urls.small;
  };

  const avatarStyle = {
    ...style,
    '--avatar-size': `${size}px`,
    width: size,
    height: size,
    backgroundColor: !getAvatarUrl() ? avatarColor : undefined,
  } as React.CSSProperties;

  const getTextColor = (backgroundColor: string) => {
    if (theme === 'dark') {
      return "text-zinc-50";
    }
    const hex = backgroundColor.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5 ? "text-zinc-900" : "text-zinc-50";
  };

  const avatarComponent = (
    <Avatar
      style={avatarStyle}
      className={cn(
        'w-[var(--avatar-size)] h-[var(--avatar-size)]',
        className
      )}
      onClick={onClick}
    >
      {getAvatarUrl() ? (
        <AvatarImage src={getAvatarUrl()} alt={initials} />
      ) : (
        <AvatarFallback 
          className={cn(
            "rounded-full",
            getTextColor(avatarColor)
          )}
          style={{
            backgroundColor: avatarColor,
            fontSize: `${size * 0.4}px`,
          }}
        >
          {initials}
        </AvatarFallback>
      )}
    </Avatar>
  );

  if (disableTooltip) {
    return avatarComponent;
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          {avatarComponent}
        </TooltipTrigger>
        <TooltipContent>
          <p>{tooltipContent || `${user.name || ''} ${user.surname || ''}`.trim() || 'Unknown User'}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default UserAvatar;