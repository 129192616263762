import React, { useState, useEffect } from 'react';
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Send, Trash2, Loader2, ChevronDown, ChevronUp, Reply } from "lucide-react";
import axios from '@/api/axiosConfig';
import dayjs from 'dayjs';
import { UserAvatar } from "@/components/user-avatar";
import { StoredUser } from '@/types/user';
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from 'sonner';
import { cn } from "@/lib/utils";

interface Comment {
  id: string | number;
  content: string;
  timestamp: string;
  user_id: string | number;
  subtask_id?: number;
  subtask_title?: string;
  parent_id?: string | number;
  replies?: Comment[];
}

interface Task {
  id: string | number;
  comments?: Comment[];
  task_type?: string;
  board?: {
    board_config: {
      enable_subtasks?: boolean;
    };
  };
  subtasks?: Array<{
    id: number;
    title: string;
    comments?: Comment[];
  }>;
}

interface TaskViewNotesProps {
  task: Task | null;
  taskType: string;
  users: StoredUser[];
  onUpdate: () => void;
}

const TaskViewNotes: React.FC<TaskViewNotesProps> = ({ task, taskType, users, onUpdate }) => {
  const [newComment, setNewComment] = useState('');
  const [replyTexts, setReplyTexts] = useState<Record<string | number, string>>({});
  const [loading, setLoading] = useState(false);
  const [allComments, setAllComments] = useState<Comment[]>([]);
  const [expandedThreads, setExpandedThreads] = useState<Set<string | number>>(new Set());
  const [replyTo, setReplyTo] = useState<string | number | null>(null);

  useEffect(() => {
    if (task) {
      let comments: Comment[] = task.comments || [];

      // If this is a custom task with subtasks enabled and has subtasks
      if (task.task_type === 'custom' && 
          task.board?.board_config?.enable_subtasks && 
          task.subtasks && 
          task.subtasks.length > 0) {
        // Add comments from subtasks, including replies
        const subtaskComments = task.subtasks.flatMap(subtask => {
          const mainComments = (subtask.comments || []).filter(c => !c.parent_id).map(comment => ({
            ...comment,
            subtask_id: subtask.id,
            subtask_title: subtask.title
          }));
          
          const replies = (subtask.comments || []).filter(c => c.parent_id).map(comment => ({
            ...comment,
            subtask_id: subtask.id,
            subtask_title: subtask.title
          }));

          return [...mainComments, ...replies];
        });
        
        comments = [...comments, ...subtaskComments];
      }

      // Sort all comments by timestamp
      comments.sort((a, b) => 
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      );

      // Group replies with their parent comments
      const commentMap = new Map<string | number, Comment>();
      const rootComments: Comment[] = [];

      // First pass: collect all root comments
      comments.forEach(comment => {
        if (!comment.parent_id) {
          rootComments.push(comment);
          commentMap.set(comment.id, comment);
        }
      });

      // Second pass: attach replies to their parents
      comments.forEach(comment => {
        if (comment.parent_id) {
          const parentComment = commentMap.get(comment.parent_id);
          if (parentComment) {
            if (!parentComment.replies) {
              parentComment.replies = [];
            }
            parentComment.replies.push(comment);
            // Sort replies by timestamp
            parentComment.replies.sort((a, b) => 
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
            );
          }
        }
      });

      setAllComments(rootComments);
    }
  }, [task]);

  const getBackendTaskType = (frontendTaskType: string): string => {
    const taskTypeMap: Record<string, string> = {
      'andre-opgaver': 'other',
      'activities': 'activity',
      'bogforing': 'bogforing',
      'lon': 'lon',
      'moms': 'moms',
      'arsafslutning': 'arsafslutning'
    };
    return taskTypeMap[frontendTaskType] || frontendTaskType;
  };

  const handleSubmit = async () => {
    if (!newComment.trim() || !task) return;

    setLoading(true);
    
    // Create optimistic comment
    const optimisticComment: Comment = {
      id: `temp-${Date.now()}`,
      content: newComment.trim(),
      timestamp: new Date().toISOString(),
      user_id: users[0]?.user_id // Current user
    };

    // Optimistically update UI
    setAllComments(prev => [optimisticComment, ...prev]);
    setNewComment('');

    try {
      // Handle custom tasks differently
      if (task.task_type === 'custom') {
        const response = await axios.post(`/custom-board/tasks/${task.id}/comments`, {
          content: optimisticComment.content
        });
        if (response.status === 200) {
          toast.success('Comment added successfully');
          onUpdate();
        }
      } else {
        const backendTaskType = getBackendTaskType(taskType);
        const response = await axios.post(`/taskboard/tasks/${backendTaskType}/${task.id}/comments`, {
          content: optimisticComment.content
        });
        if (response.status === 200) {
          toast.success('Comment added successfully');
          onUpdate();
        }
      }
    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error('Failed to add comment');
      // Revert optimistic update on error
      setAllComments(prev => prev.filter(c => c.id !== optimisticComment.id));
      setNewComment(optimisticComment.content); // Restore the comment text
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (commentId: string | number, subtaskId?: number) => {
    if (!task) return;
    
    try {
      // Handle custom tasks differently
      if (task.task_type === 'custom') {
        const endpoint = subtaskId 
          ? `/custom-board/subtasks/${subtaskId}/comments/${commentId}`
          : `/custom-board/tasks/${task.id}/comments/${commentId}`;

        const response = await axios.delete(endpoint);
        if (response.status === 200) {
          toast.success('Comment deleted successfully');
          onUpdate();
        }
      } else {
        const backendTaskType = getBackendTaskType(taskType);
        const response = await axios.delete(`/taskboard/tasks/${backendTaskType}/${task.id}/comments/${commentId}`);
        if (response.status === 200) {
          toast.success('Comment deleted successfully');
          onUpdate();
        }
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
      toast.error('Failed to delete comment');
    }
  };

  const toggleThread = (commentId: string | number) => {
    setExpandedThreads(prev => {
      const newSet = new Set(prev);
      if (newSet.has(commentId)) {
        newSet.delete(commentId);
      } else {
        newSet.add(commentId);
      }
      return newSet;
    });
  };

  const getCommentUniqueId = (comment: Comment): string => {
    return comment.subtask_id 
      ? `subtask-${comment.subtask_id}-comment-${comment.id}`
      : `main-${comment.id}`;
  };

  const handleReplyClick = (comment: Comment) => {
    const uniqueId = getCommentUniqueId(comment);
    
    // If already replying to this comment, cancel it
    if (replyTo === uniqueId) {
      setReplyTo(null);
      setReplyTexts(prev => ({ ...prev, [uniqueId]: '' }));
      return;
    }

    // Expand the thread when starting a reply
    setExpandedThreads(prev => {
      const newSet = new Set(prev);
      newSet.add(comment.id);
      return newSet;
    });

    // Set the reply state using the unique ID
    setReplyTo(uniqueId);
  };

  const handleReply = async () => {
    if (!task || !replyTo) return;
    
    const replyText = replyTexts[replyTo];
    if (!replyText?.trim()) return;

    // Find the parent comment based on the unique ID
    const replyToStr = String(replyTo);
    const [type, subtaskId, _, commentId] = replyToStr.split('-');
    const parentId = type === 'subtask' ? Number(commentId) : commentId;
    
    setLoading(true);

    // Create optimistic reply
    const optimisticReply: Comment = {
      id: `temp-${Date.now()}`,
      content: replyText.trim(),
      timestamp: new Date().toISOString(),
      user_id: users[0]?.user_id,
      parent_id: parentId
    };

    // Get the parent comment to determine if it's from a subtask
    const parentComment = allComments.find(c => c.id.toString() === parentId.toString()) || 
      allComments.flatMap(c => c.replies || []).find(c => c.id.toString() === parentId.toString());

    if (parentComment?.subtask_id) {
      optimisticReply.subtask_id = parentComment.subtask_id;
      optimisticReply.subtask_title = parentComment.subtask_title;
    }

    // Optimistically update UI
    setAllComments(prev => prev.map(comment => {
      if (comment.id.toString() === parentId.toString()) {
        return {
          ...comment,
          replies: [...(comment.replies || []), optimisticReply]
        };
      }
      return comment;
    }));

    // Clear reply text and state
    setReplyTexts(prev => ({ ...prev, [replyTo]: '' }));
    setReplyTo(null);

    try {
      if (task.task_type === 'custom') {
        const endpoint = parentComment?.subtask_id
          ? `/custom-board/subtasks/${parentComment.subtask_id}/comments`
          : `/custom-board/tasks/${task.id}/comments`;

        const response = await axios.post(endpoint, {
          content: replyText.trim(),
          parent_id: parentId
        });

        if (response.status === 200) {
          toast.success('Reply added successfully');
          onUpdate();
        }
      } else {
        const backendTaskType = getBackendTaskType(taskType);
        const response = await axios.post(`/taskboard/tasks/${backendTaskType}/${task.id}/comments`, {
          content: replyText.trim(),
          parent_id: parentId
        });
        if (response.status === 200) {
          toast.success('Reply added successfully');
          onUpdate();
        }
      }
    } catch (error) {
      console.error('Error adding reply:', error);
      toast.error('Failed to add reply');
      // Revert optimistic update on error
      setAllComments(prev => prev.map(comment => {
        if (comment.id.toString() === parentId.toString()) {
          return {
            ...comment,
            replies: (comment.replies || []).filter(r => r.id !== optimisticReply.id)
          };
        }
        return comment;
      }));
      // Restore reply text
      setReplyTexts(prev => ({ ...prev, [replyTo]: replyText }));
      setReplyTo(replyTo);
    } finally {
      setLoading(false);
    }
  };

  const renderComment = (comment: Comment, isReply = false) => {
    const user = users.find(u => u.user_id === comment.user_id) || {
      user_id: comment.user_id,
      name: '',
      surname: '',
      email: ''
    };

    const replyCount = comment.replies?.length ?? 0;
    const hasReplies = replyCount > 0;
    const isExpanded = expandedThreads.has(comment.id);
    const uniqueId = getCommentUniqueId(comment);
    const isReplying = replyTo === uniqueId;

    return (
      <div 
        key={uniqueId}
        className={cn(
          "group relative",
          isReply ? "ml-8 mt-4" : "mt-5 first:mt-0"
        )}
      >
        <div className="flex gap-4">
          <div className="flex-shrink-0">
            <UserAvatar user={user} size={32} />
          </div>
          <div className="flex flex-col gap-1 flex-1">
            <div className="flex items-center gap-2">
              <p className="text-sm font-medium">
                {user ? `${user.name} ${user.surname}` : `User ${comment.user_id}`}
              </p>
              {comment.subtask_id && (
                <span className="text-sm text-muted-foreground">
                  in subtask "{comment.subtask_title}"
                </span>
              )}
            </div>
            <p className="text-sm text-foreground whitespace-pre-wrap break-words">
              {comment.content}
            </p>
            <time className="text-xs text-muted-foreground">
              {dayjs(comment.timestamp).format('YYYY-MM-DD HH:mm')}
            </time>

            {!isReply && (
              <div className="flex gap-2 mt-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => handleReplyClick(comment)}
                  className="h-auto py-1 px-2 text-xs hover:bg-muted"
                >
                  <Reply className="h-3 w-3 mr-1" />
                  {isReplying ? 'Cancel Reply' : 'Reply'}
                </Button>
                {hasReplies && (
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => toggleThread(comment.id)}
                    className="h-auto py-1 px-2 text-xs hover:bg-muted"
                  >
                    {isExpanded ? (
                      <>
                        <ChevronUp className="h-3 w-3 mr-1" />
                        Hide {replyCount} {replyCount === 1 ? 'reply' : 'replies'}
                      </>
                    ) : (
                      <>
                        <ChevronDown className="h-3 w-3 mr-1" />
                        Show {replyCount} {replyCount === 1 ? 'reply' : 'replies'}
                      </>
                    )}
                  </Button>
                )}
              </div>
            )}
          </div>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => handleDelete(comment.id, comment.subtask_id)}
            className="absolute right-0 opacity-0 group-hover:opacity-100 transition-opacity"
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>

        {!isReply && (
          <>
            {/* Always render replies if they exist, but control visibility with isExpanded */}
            {hasReplies && (
              <div className={cn("transition-all", isExpanded || isReplying ? "block" : "hidden")}>
                {comment.replies?.map(reply => renderComment(reply, true))}
              </div>
            )}

            {/* Only show reply textarea after existing replies */}
            {isReplying && (
              <div className="mt-2 ml-8">
                <Textarea
                  placeholder="Write a reply..."
                  value={replyTexts[uniqueId] || ''}
                  onChange={(e) => setReplyTexts(prev => ({ ...prev, [uniqueId]: e.target.value }))}
                  className="resize-none"
                  rows={2}
                />
                <div className="flex justify-end gap-2 mt-2">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      setReplyTo(null);
                      setReplyTexts(prev => ({ ...prev, [uniqueId]: '' }));
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    onClick={handleReply}
                    disabled={!replyTexts[uniqueId]?.trim() || loading}
                  >
                    <Send className="h-3 w-3 mr-1" />
                    Reply
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  if (!task) {
    return (
      <div className="flex justify-center items-center min-h-[100px] rounded-lg p-5">
        <p className="text-sm text-muted-foreground">Task not found</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-5 p-5">
      <div className="flex flex-col gap-2">
        <Textarea
          placeholder="Write a comment..."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          className="min-h-[80px] resize-none"
        />
        <div className="flex justify-end">
          <Button
            onClick={handleSubmit}
            disabled={!newComment.trim() || loading}
            className="w-24"
          >
            {loading ? (
              <div className="flex items-center gap-2">
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Posting</span>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <Send className="h-4 w-4" />
                <span>Post</span>
              </div>
            )}
          </Button>
        </div>
      </div>

      <ScrollArea className="flex-1">
        {!allComments.length ? (
          <div className="flex justify-center items-center min-h-[100px] rounded-lg">
            <p className="text-sm text-muted-foreground">No comments yet</p>
          </div>
        ) : (
          <div className="space-y-5">
            {allComments.map(comment => renderComment(comment))}
          </div>
        )}
      </ScrollArea>
    </div>
  );
};

export default TaskViewNotes; 