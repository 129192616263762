import React, { useState, useEffect } from 'react'
import { Plus, X, ChevronDown, ChevronUp } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Badge } from '@/components/ui/badge'
import { Switch } from '@/components/ui/switch'
import { dealDataConfig } from '@/configs/DealDataConfig'
import { Board } from '@/types/custom-board'
import { AssigneeSelection } from '@/modals/AssigneeSelection'
import { useUsers } from '@/contexts/UserContext'
import { StoredUser } from '@/types/user'

interface AutomationRule {
  id: string
  type: 'task' | 'subtask'
  trigger: {
    type: 'status_change'
    value: string
    subtask_id?: string // For when triggering on a specific predefined subtask
  }
  conditions: {
    type: 'deal_data' | 'main_task_status'
    field: string
    operator: 'equals' | 'not_equals'
    value: string
  }[]
  actions: {
    type: 'assign_users' | 'change_status' | 'change_subtask_status'
    users?: string[]
    status_id?: string
    subtask_id?: string // For when changing status of a specific predefined subtask
    target_type?: 'main_task' | 'subtask' // For assign_users to specify what to assign to
  }[]
}

interface AutomationCondition {
  type: 'deal_data' | 'main_task_status';
  field: string;
  operator: 'equals' | 'not_equals';
  value: string;
}

interface ProgressStatusOption {
  id: number;
  label: string;
  color: string;
  description?: string | null;
  order: number;
  is_completed_state?: boolean;
}

interface SubtaskStatusOption {
  value: string;
  label: string;
  color: string;
  is_completed_state: boolean;
}

interface AutomationConfigProps {
  board: Board
  onAutomationsChange: (automations: AutomationRule[]) => void
}

export const AutomationConfig = ({ board, onAutomationsChange }: AutomationConfigProps) => {
  const [automations, setAutomations] = useState<AutomationRule[]>(board.board_config.automations || [])
  const { users } = useUsers()
  const [currentEditingAction, setCurrentEditingAction] = useState<{ automationId: string; actionIndex: number } | null>(null)

  // Sync with parent form when local state changes
  useEffect(() => {
    onAutomationsChange(automations)
  }, [automations, onAutomationsChange])

  const handleAddAutomation = () => {
    const newAutomation: AutomationRule = {
      id: crypto.randomUUID(),
      type: 'task',
      trigger: {
        type: 'status_change',
        value: ''
      },
      conditions: [],
      actions: []
    }
    setAutomations(prev => [...prev, newAutomation])
  }

  const handleDeleteAutomation = (id: string) => {
    setAutomations(prev => prev.filter(a => a.id !== id))
  }

  const handleUpdateAutomation = (automationId: string, updates: Partial<AutomationRule>) => {
    setAutomations(prev => prev.map(a => {
      if (a.id === automationId) {
        return {
          ...a,
          ...updates,
          conditions: updates.conditions?.map(c => ({
            ...c,
            type: 'deal_data' as const,
            operator: c.operator as 'equals' | 'not_equals'
          })) || a.conditions
        };
      }
      return a;
    }));
  }

  const handleAddCondition = (automationId: string) => {
    const newCondition: AutomationCondition = {
      type: 'deal_data',
      field: '',
      operator: 'equals',
      value: ''
    };
    
    setAutomations(prev => prev.map(a => {
      if (a.id === automationId) {
        return {
          ...a,
          conditions: [...a.conditions, newCondition]
        };
      }
      return a;
    }));
  }

  const handleDeleteCondition = (automationId: string, index: number) => {
    setAutomations(prev => prev.map(a => {
      if (a.id === automationId) {
        const newConditions = [...a.conditions]
        newConditions.splice(index, 1)
        return { ...a, conditions: newConditions }
      }
      return a
    }));
  }

  const handleUpdateCondition = (automationId: string, index: number, updates: Partial<AutomationRule['conditions'][0]>) => {
    setAutomations(prev => prev.map(a => {
      if (a.id === automationId) {
        const newConditions = [...a.conditions]
        newConditions[index] = { ...newConditions[index], ...updates }
        return { ...a, conditions: newConditions }
      }
      return a
    }));
  }

  const renderAutomation = (automation: AutomationRule) => {
    const statusOptions = automation.type === 'task'
      ? board.board_config.progress_status_options as ProgressStatusOption[]
      : automation.trigger.subtask_id 
        ? (board.subtask_config?.predefined_subtasks?.find(s => s.name === automation.trigger.subtask_id)?.status_options || [])
          .map(opt => ({
            ...opt,
            value: opt.id.toString()
          })) as SubtaskStatusOption[]
        : board.board_config.progress_status_options as ProgressStatusOption[]

    return (
      <div key={automation.id} className="border rounded-lg p-4 space-y-4">
        <div className="flex justify-between items-start">
          <div className="space-y-4 flex-1">
            {/* Trigger Section */}
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium">Trigger</span>
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  onClick={() => handleDeleteAutomation(automation.id)}
                  className="text-destructive"
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
              <div className="grid grid-cols-1 gap-2">
                <div className="flex items-center gap-2">
                  <span className="text-muted-foreground text-sm min-w-[40px]">When</span>
                  <Select
                    value={automation.type}
                    onValueChange={(value) => handleUpdateAutomation(automation.id, { type: value as 'task' | 'subtask' })}
                  >
                    <SelectTrigger className="w-[140px]">
                      <SelectValue placeholder="Select type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="task">Main Task</SelectItem>
                      <SelectItem value="subtask" disabled={!board.board_config.enable_subtasks}>Subtask</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                {automation.type === 'subtask' && board.subtask_config?.predefined_subtasks && board.subtask_config.predefined_subtasks.length > 0 && (
                  <div className="flex items-center gap-2 ml-[40px]">
                    <Select
                      value={automation.trigger.subtask_id}
                      onValueChange={(value) => handleUpdateAutomation(automation.id, { 
                        trigger: { ...automation.trigger, subtask_id: value } 
                      })}
                    >
                      <SelectTrigger className="w-[200px]">
                        <SelectValue placeholder="Select subtask" />
                      </SelectTrigger>
                      <SelectContent>
                        {board.subtask_config?.predefined_subtasks?.map(subtask => (
                          <SelectItem key={subtask.name} value={subtask.name}>
                            {subtask.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                )}

                <div className="flex items-center gap-2 ml-[40px]">
                  <span className="text-muted-foreground text-sm">changes to</span>
                  <Select
                    value={automation.trigger.value}
                    onValueChange={(value) => handleUpdateAutomation(automation.id, { 
                      trigger: { ...automation.trigger, value } 
                    })}
                  >
                    <SelectTrigger className="w-[200px]">
                      <SelectValue placeholder="Select status" />
                    </SelectTrigger>
                    <SelectContent>
                      {statusOptions.map(status => {
                        // For main task status options
                        if ('id' in status) {
                          return (
                            <SelectItem key={status.id} value={status.id.toString()}>
                              {status.label}
                            </SelectItem>
                          )
                        }
                        // For subtask status options
                        return (
                          <SelectItem key={status.value} value={status.value}>
                            {status.label}
                          </SelectItem>
                        )
                      })}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>

            {/* Conditions Section */}
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium">Conditions</span>
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={() => handleAddCondition(automation.id)}
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Condition
                </Button>
              </div>

              <div className="grid grid-cols-1 gap-2">
                {automation.conditions.map((condition, index) => (
                  <div key={index} className="flex items-center gap-2 bg-muted/50 p-2 rounded-md">
                    <Select
                      value={condition.type}
                      onValueChange={(value) => handleUpdateCondition(automation.id, index, { 
                        type: value as 'deal_data' | 'main_task_status'
                      })}
                    >
                      <SelectTrigger className="w-[140px]">
                        <SelectValue placeholder="Select type" />
                      </SelectTrigger>
                      <SelectContent>
                        {board.board_config.requires_deal && (
                          <SelectItem value="deal_data">Deal Data</SelectItem>
                        )}
                        {automation.type === 'subtask' && (
                          <SelectItem value="main_task_status">Main Task Status</SelectItem>
                        )}
                      </SelectContent>
                    </Select>

                    {condition.type === 'deal_data' && (
                      <>
                        <Select
                          value={condition.field}
                          onValueChange={(value) => handleUpdateCondition(automation.id, index, { field: value })}
                        >
                          <SelectTrigger className="w-[160px]">
                            <SelectValue placeholder="Select field" />
                          </SelectTrigger>
                          <SelectContent>
                            {dealDataConfig.map(field => (
                              <SelectItem key={field.key} value={field.key}>
                                {field.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>

                        <Select
                          value={condition.operator}
                          onValueChange={(value) => handleUpdateCondition(automation.id, index, { 
                            operator: value as 'equals' | 'not_equals' 
                          })}
                        >
                          <SelectTrigger className="w-[120px]">
                            <SelectValue placeholder="Operator" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="equals">Equals</SelectItem>
                            <SelectItem value="not_equals">Not Equals</SelectItem>
                          </SelectContent>
                        </Select>

                        <Input
                          placeholder="Value"
                          value={condition.value}
                          onChange={(e) => handleUpdateCondition(automation.id, index, { value: e.target.value })}
                          className="flex-1 min-w-[100px]"
                        />
                      </>
                    )}

                    {condition.type === 'main_task_status' && (
                      <>
                        <Select
                          value={condition.field}
                          onValueChange={(value) => handleUpdateCondition(automation.id, index, { field: value })}
                        >
                          <SelectTrigger className="w-[160px]">
                            <SelectValue placeholder="Status type" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="progress_status">Progress Status</SelectItem>
                            <SelectItem value="board_status">Board Status</SelectItem>
                          </SelectContent>
                        </Select>

                        <Select
                          value={condition.operator}
                          onValueChange={(value) => handleUpdateCondition(automation.id, index, { 
                            operator: value as 'equals' | 'not_equals' 
                          })}
                        >
                          <SelectTrigger className="w-[120px]">
                            <SelectValue placeholder="Operator" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="equals">Equals</SelectItem>
                            <SelectItem value="not_equals">Not Equals</SelectItem>
                          </SelectContent>
                        </Select>

                        <Select
                          value={condition.value}
                          onValueChange={(value) => handleUpdateCondition(automation.id, index, { value })}
                        >
                          <SelectTrigger className="w-[160px]">
                            <SelectValue placeholder="Select status" />
                          </SelectTrigger>
                          <SelectContent>
                            {condition.field === 'progress_status' ? (
                              board.board_config.progress_status_options.map(status => (
                                <SelectItem key={status.id} value={status.id.toString()}>
                                  {status.label}
                                </SelectItem>
                              ))
                            ) : (
                              board.board_config.board_status_options.map(status => (
                                <SelectItem key={status.id} value={status.id.toString()}>
                                  {status.label}
                                </SelectItem>
                              ))
                            )}
                          </SelectContent>
                        </Select>
                      </>
                    )}

                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      onClick={() => handleDeleteCondition(automation.id, index)}
                      className="text-destructive"
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                ))}
              </div>
            </div>

            {/* Actions Section */}
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium">Actions</span>
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    const newActions = [...(automation.actions || [])];
                    newActions.push({
                      type: 'assign_users',
                      users: []
                    });
                    handleUpdateAutomation(automation.id, { actions: newActions });
                  }}
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Action
                </Button>
              </div>

              <div className="grid grid-cols-1 gap-2">
                {automation.actions.map((action, actionIndex) => (
                  <div key={actionIndex} className="flex items-center gap-2 bg-muted/50 p-2 rounded-md">
                    <Select
                      value={action.type}
                      onValueChange={(value) => {
                        const newActions = [...automation.actions];
                        newActions[actionIndex] = {
                          type: value as 'assign_users' | 'change_status' | 'change_subtask_status',
                          users: action.users,
                          status_id: action.status_id,
                          subtask_id: action.subtask_id,
                          target_type: action.target_type
                        };
                        handleUpdateAutomation(automation.id, { actions: newActions });
                      }}
                    >
                      <SelectTrigger className="w-[140px]">
                        <SelectValue placeholder="Select action" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="assign_users">Assign Users</SelectItem>
                        <SelectItem value="change_status">Change Status</SelectItem>
                        {automation.type === 'subtask' && (
                          <SelectItem value="change_subtask_status">Change Subtask Status</SelectItem>
                        )}
                      </SelectContent>
                    </Select>

                    {action.type === 'assign_users' && (
                      <>
                        <Select
                          value={action.target_type}
                          onValueChange={(value) => {
                            const newActions = [...automation.actions];
                            newActions[actionIndex] = {
                              ...newActions[actionIndex],
                              target_type: value as 'main_task' | 'subtask'
                            };
                            handleUpdateAutomation(automation.id, { actions: newActions });
                          }}
                        >
                          <SelectTrigger className="w-[140px]">
                            <SelectValue placeholder="Select target" />
                          </SelectTrigger>
                          <SelectContent>
                            {!board.board_config.simplify_main_tasks && (
                              <SelectItem value="main_task">Main Task</SelectItem>
                            )}
                            {board.board_config.enable_subtasks && (
                              <SelectItem value="subtask">Subtask</SelectItem>
                            )}
                          </SelectContent>
                        </Select>

                        {action.target_type === 'subtask' && (
                          <Select
                            value={action.subtask_id}
                            onValueChange={(value) => {
                              const newActions = [...automation.actions];
                              newActions[actionIndex] = {
                                ...newActions[actionIndex],
                                subtask_id: value
                              };
                              handleUpdateAutomation(automation.id, { actions: newActions });
                            }}
                          >
                            <SelectTrigger className="w-[160px]">
                              <SelectValue placeholder="Select subtask" />
                            </SelectTrigger>
                            <SelectContent>
                              {board.subtask_config?.predefined_subtasks?.map(subtask => (
                                <SelectItem key={subtask.name} value={subtask.name}>
                                  {subtask.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}

                        <div className="flex-1">
                          <Button
                            type="button"
                            variant="outline"
                            className="w-full justify-start"
                            onClick={() => setCurrentEditingAction({ automationId: automation.id, actionIndex })}
                          >
                            {action.users && action.users.length > 0 ? (
                              <div className="flex flex-wrap gap-1">
                                {action.users?.map(userId => {
                                  const user = users.find(u => String(u.user_id) === String(userId));
                                  return user ? (
                                    <Badge key={userId} variant="secondary">
                                      {user.name} {user.surname}
                                    </Badge>
                                  ) : null;
                                })}
                              </div>
                            ) : (
                              <span className="text-muted-foreground">Select users...</span>
                            )}
                          </Button>
                        </div>
                      </>
                    )}

                    {action.type === 'change_status' && (
                      <>
                        <Select
                          value={action.status_id}
                          onValueChange={(value) => {
                            const newActions = [...automation.actions];
                            newActions[actionIndex] = {
                              ...newActions[actionIndex],
                              status_id: value
                            };
                            handleUpdateAutomation(automation.id, { actions: newActions });
                          }}
                        >
                          <SelectTrigger className="w-[160px]">
                            <SelectValue placeholder="Select status" />
                          </SelectTrigger>
                          <SelectContent>
                            {board.board_config.progress_status_options.map(status => (
                              <SelectItem key={status.id} value={status.id.toString()}>
                                {status.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </>
                    )}

                    {action.type === 'change_subtask_status' && (
                      <>
                        <Select
                          value={action.subtask_id}
                          onValueChange={(value) => {
                            const newActions = [...automation.actions];
                            newActions[actionIndex] = {
                              ...newActions[actionIndex],
                              subtask_id: value
                            };
                            handleUpdateAutomation(automation.id, { actions: newActions });
                          }}
                        >
                          <SelectTrigger className="w-[160px]">
                            <SelectValue placeholder="Select subtask" />
                          </SelectTrigger>
                          <SelectContent>
                            {board.subtask_config?.predefined_subtasks?.map(subtask => (
                              <SelectItem key={subtask.name} value={subtask.name}>
                                {subtask.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>

                        <Select
                          value={action.status_id}
                          onValueChange={(value) => {
                            const newActions = [...automation.actions];
                            newActions[actionIndex] = {
                              ...newActions[actionIndex],
                              status_id: value
                            };
                            handleUpdateAutomation(automation.id, { actions: newActions });
                          }}
                        >
                          <SelectTrigger className="w-[160px]">
                            <SelectValue placeholder="Select status" />
                          </SelectTrigger>
                          <SelectContent>
                            {action.subtask_id && board.subtask_config?.predefined_subtasks?.find(s => s.name === action.subtask_id)?.status_options.map(status => (
                              <SelectItem key={status.id} value={status.id.toString()}>
                                {status.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </>
                    )}

                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      onClick={() => {
                        const newActions = [...automation.actions];
                        newActions.splice(actionIndex, 1);
                        handleUpdateAutomation(automation.id, { actions: newActions });
                      }}
                      className="text-destructive"
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (!board.board_config.enable_automations) {
    return (
      <div className="flex flex-col items-center justify-center p-8 text-center space-y-4">
        <p className="text-muted-foreground">
          Automations are currently disabled for this board.
          Enable them in the General settings to configure automation rules.
        </p>
      </div>
    )
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-lg font-medium">Automation Rules</h3>
          <p className="text-sm text-muted-foreground">
            Configure automation rules for tasks and subtasks
          </p>
        </div>
        <Button type="button" onClick={handleAddAutomation}>
          <Plus className="h-4 w-4 mr-2" />
          Add Rule
        </Button>
      </div>

      <div className="space-y-4">
        {automations.map(renderAutomation)}
      </div>

      {automations.length === 0 && (
        <div className="text-center p-8 border rounded-lg">
          <p className="text-muted-foreground">
            No automation rules configured yet.
            Click "Add Rule" to create your first automation.
          </p>
        </div>
      )}

      {currentEditingAction && (
        <AssigneeSelection
          visible={true}
          onClose={() => setCurrentEditingAction(null)}
          onSave={(users) => {
            const automation = automations.find(a => a.id === currentEditingAction.automationId);
            if (automation) {
              const newActions = [...automation.actions];
              newActions[currentEditingAction.actionIndex] = {
                ...newActions[currentEditingAction.actionIndex],
                users
              };
              handleUpdateAutomation(currentEditingAction.automationId, { actions: newActions });
            }
            setCurrentEditingAction(null);
          }}
          initialAssignees={automations
            .find(a => a.id === currentEditingAction.automationId)
            ?.actions[currentEditingAction.actionIndex]?.users || []}
        />
      )}
    </div>
  )
} 