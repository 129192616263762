import { useState, useEffect } from 'react'
import axios from '@/api/axiosConfig'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import { Label } from '@/components/ui/label'
import { PhoneCall, Info } from 'lucide-react'
import { toast } from 'sonner'
import { dealDataConfig } from '@/configs/DealDataConfig'

interface User {
  id: number
  name: string
  email: string
}

interface AircallConfig {
  routing_rules: {
    priority_list: {
      position: number
      type: string
      value: string | null
    }[]
    fallback_user_id: number | null
  }
  insight_card_fields: string[]
}

const ROUTING_OPTIONS = [
  { value: 'ansvarlig_bogholder', label: 'Ansvarlig Bogholder' },
  { value: 'user', label: 'Specific User' }
]

export default function AircallSettings() {
  const [config, setConfig] = useState<AircallConfig | null>(null)
  const [users, setUsers] = useState<User[]>([])
  const [loading, setLoading] = useState(true)

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/api/users')
      setUsers(response.data)
    } catch (error) {
      toast.error('Failed to fetch users')
    }
  }

  const fetchConfig = async () => {
    try {
      const response = await axios.get('/api/aircall/config')
      setConfig(response.data)
    } catch (error) {
      toast.error('Failed to fetch Aircall configuration')
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchUsers()
    fetchConfig()
  }, [])

  const handleRoutingChange = async (position: number, type: string, value: string | null) => {
    if (!config) return

    const newConfig = {
      ...config,
      routing_rules: {
        ...config.routing_rules,
        priority_list: config.routing_rules.priority_list.map(rule =>
          rule.position === position ? { ...rule, type, value } : rule
        )
      }
    }

    try {
      await axios.put('/api/aircall/config', newConfig)
      setConfig(newConfig)
      toast.success('Aircall settings updated successfully')
    } catch (error) {
      toast.error('Failed to update Aircall settings')
    }
  }

  const handleInsightFieldToggle = async (fieldKey: string) => {
    if (!config) return

    const newFields = config.insight_card_fields.includes(fieldKey)
      ? config.insight_card_fields.filter(f => f !== fieldKey)
      : [...config.insight_card_fields, fieldKey]

    const newConfig = {
      ...config,
      insight_card_fields: newFields
    }

    try {
      await axios.put('/api/aircall/config', newConfig)
      setConfig(newConfig)
      toast.success('Aircall settings updated successfully')
    } catch (error) {
      toast.error('Failed to update Aircall settings')
    }
  }

  if (loading || !config) return null

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <PhoneCall className="h-5 w-5" />
            Call Routing Configuration
          </CardTitle>
          <CardDescription>
            Configure how incoming calls are routed to users
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          {config.routing_rules.priority_list.map((rule) => (
            <div key={rule.position} className="flex items-center gap-4">
              <span className="w-24">Priority {rule.position}:</span>
              <Select
                value={rule.type}
                onValueChange={(value) => handleRoutingChange(rule.position, value, null)}
              >
                <SelectTrigger className="w-[200px]">
                  <SelectValue placeholder="Select type" />
                </SelectTrigger>
                <SelectContent>
                  {ROUTING_OPTIONS.map(option => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              {rule.type === 'user' && (
                <Select
                  value={rule.value?.toString()}
                  onValueChange={(value) => handleRoutingChange(rule.position, 'user', value)}
                >
                  <SelectTrigger className="w-[200px]">
                    <SelectValue placeholder="Select user" />
                  </SelectTrigger>
                  <SelectContent>
                    {users.map((user) => (
                      <SelectItem key={user.id} value={user.id.toString()}>
                        {user.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            </div>
          ))}
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Info className="h-5 w-5" />
            Insight Card Fields
          </CardTitle>
          <CardDescription>
            Select which fields should be displayed in the Aircall insight card
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 gap-4">
            {dealDataConfig.map((field) => (
              <div key={field.key} className="flex items-center space-x-2">
                <Switch
                  id={field.key}
                  checked={config.insight_card_fields.includes(field.key)}
                  onCheckedChange={() => handleInsightFieldToggle(field.key)}
                />
                <Label htmlFor={field.key}>{field.label}</Label>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  )
}