// frontend/src/components/ui/date/birthday-picker.tsx
import * as React from "react"
import { format, setYear } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

interface BirthdayPickerProps {
  date?: Date
  onSelect?: (date: Date | undefined) => void
  className?: string
}

export function BirthdayPicker({ date, onSelect, className }: BirthdayPickerProps) {
  const currentYear = new Date().getFullYear()
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i - 10)
  const [selectedYear, setSelectedYear] = React.useState<number | undefined>(
    date ? date.getFullYear() : undefined
  )
  
  const handleYearSelect = (year: string) => {
    const yearNum = parseInt(year)
    setSelectedYear(yearNum)
    if (date) {
      const newDate = setYear(date, yearNum)
      onSelect?.(newDate)
    }
  }

  const handleDateSelect = (newDate: Date | undefined) => {
    if (newDate && selectedYear) {
      const dateWithSelectedYear = setYear(newDate, selectedYear)
      onSelect?.(dateWithSelectedYear)
    } else {
      onSelect?.(newDate)
    }
  }

  return (
    <div className="flex gap-2">
      <Select
        value={selectedYear?.toString()}
        onValueChange={handleYearSelect}
      >
        <SelectTrigger className="w-[120px]">
          <SelectValue placeholder="Year" />
        </SelectTrigger>
        <SelectContent>
          {years.map((year) => (
            <SelectItem key={year} value={year.toString()}>
              {year}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn(
              "w-[180px] justify-start text-left font-normal",
              !date && "text-muted-foreground",
              className
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? format(date, "MMMM d") : <span>Pick month and day</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={date}
            onSelect={handleDateSelect}
            defaultMonth={date || new Date(selectedYear || currentYear, 0)}
            initialFocus
            disabled={(date) => {
              // Disable future dates
              return date > new Date()
            }}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}