import React from 'react';
import { ArrowDownAZ, ArrowUpAZ, CalendarDays, History, ArrowUpDown } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

export type SortOption = {
  field: 'last_activity' | 'work_date' | 'due_date';
  direction: 'asc' | 'desc';
};

interface TaskSorterProps {
  onSortChange: (sort: SortOption) => void;
  currentSort?: SortOption;
}

export function TaskSorter({ onSortChange, currentSort }: TaskSorterProps) {
  const sortOptions: { label: string; value: SortOption }[] = [
    { 
      label: 'Last Activity (Newest)', 
      value: { field: 'last_activity', direction: 'desc' }
    },
    { 
      label: 'Last Activity (Oldest)', 
      value: { field: 'last_activity', direction: 'asc' }
    },
    { 
      label: 'Work Date (Earliest)', 
      value: { field: 'work_date', direction: 'asc' }
    },
    { 
      label: 'Work Date (Latest)', 
      value: { field: 'work_date', direction: 'desc' }
    },
    { 
      label: 'Due Date (Earliest)', 
      value: { field: 'due_date', direction: 'asc' }
    },
    { 
      label: 'Due Date (Latest)', 
      value: { field: 'due_date', direction: 'desc' }
    },
  ];

  const getIcon = (option: SortOption) => {
    switch (option.field) {
      case 'last_activity':
        return History;
      case 'work_date':
      case 'due_date':
        return CalendarDays;
      default:
        return option.direction === 'asc' ? ArrowUpAZ : ArrowDownAZ;
    }
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <DropdownMenu>
          <TooltipTrigger asChild>
            <DropdownMenuTrigger asChild>
              <Button 
                variant="outline" 
                size="icon"
                className={cn(
                  "h-9 w-9",
                  currentSort && "bg-accent"
                )}
              >
                <ArrowUpDown className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
          </TooltipTrigger>
          <DropdownMenuContent align="end" className="w-56">
            {sortOptions.map((option, index) => {
              const Icon = getIcon(option.value);
              const isActive = currentSort?.field === option.value.field && 
                            currentSort?.direction === option.value.direction;

              // Add separator between different field groups
              const addSeparator = index > 0 && 
                sortOptions[index - 1].value.field !== option.value.field;

              return (
                <React.Fragment key={`${option.value.field}-${option.value.direction}`}>
                  {addSeparator && <DropdownMenuSeparator />}
                  <DropdownMenuItem
                    className={cn(
                      "flex items-center gap-2",
                      isActive && "bg-accent"
                    )}
                    onClick={() => onSortChange(option.value)}
                  >
                    <Icon className="h-4 w-4" />
                    <span>{option.label}</span>
                  </DropdownMenuItem>
                </React.Fragment>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenu>
        <TooltipContent side="bottom">
          <p>Sort tasks</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
