import { ApiConfig as ApiConfigType, ApiPayload } from '../../../types/node_types';
import { EndpointConfig } from './EndpointConfig';
import { PayloadConfig } from './PayloadConfig';
import { SuccessErrorConfig } from './SuccessErrorConfig';
import { OnboardingEndpoint } from '../../../types/endpoints';

interface LocalConfig {
  rows: {
    elements: {
      id: string;
      type: string;
      config: {
        label: string;
      };
    }[];
  }[];
}

interface ApiConfigProps {
  config: ApiConfigType;
  elementId: string;
  onChange: (updates: Partial<ApiConfigType>) => void;
  availableEndpoints: OnboardingEndpoint[];
}

export function ApiConfig({ config, elementId, onChange, availableEndpoints }: ApiConfigProps) {
  const selectedEndpoint = availableEndpoints.find(e => e.endpoint === config.endpoint) || null;

  const handlePayloadChange = (updates: Partial<ApiPayload>) => {
    if (!config.payload) return;
    onChange({
      payload: {
        ...config.payload,
        ...updates
      }
    });
  };

  const defaultLocalConfig: LocalConfig = {
    rows: []
  };

  return (
    <div className="space-y-4">
      <EndpointConfig
        config={config}
        onChange={onChange}
        availableEndpoints={availableEndpoints}
      />

      {selectedEndpoint && (
        <PayloadConfig
          config={config}
          endpoint={selectedEndpoint}
          localConfig={defaultLocalConfig}
          onChange={handlePayloadChange}
        />
      )}

      <SuccessErrorConfig
        config={config}
        elementId={elementId}
        onChange={onChange}
      />
    </div>
  );
} 