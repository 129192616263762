// AdminInvoice.tsx
import { useState, useEffect } from 'react'
import { FileSearch, FileText, Trash2, BookOpen, X, CheckCircle, XCircle, HelpCircle } from 'lucide-react'
import { format } from 'date-fns'
import { toast } from 'sonner'
import axios from '../../api/axiosConfig'

import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { DatePickerWithRange } from '@/components/ui/date/date-picker-with-range'
import { Table, type ColumnDef } from '@/components/ui/specialized/mod-table'
import { Loading } from "@/components/ui/loading"
import { Badge } from "@/components/ui/badge"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"

interface Invoice {
  id: string
  invoice_number: string
  status: string
  secondary_status?: 'approved' | 'not_approved' | 'unsure'
  total_amount: number
  created_at: string
  economic_invoice_id: string
  deal_id: string
}

interface EconomicInvoice {
  draftInvoiceNumber: string
  customer: {
    name: string
  }
  invoiceDate: string
  dueDate: string
  grossAmount: number
  lines: InvoiceLine[]
}

interface InvoiceLine {
  description: string
  quantity: number
  unitNetPrice: number
  totalNetAmount: number
}

interface EconomicLayout {
  layoutNumber: number;
  name: string;
}

interface EconomicUnit {
  unitNumber: number;
  name: string;
}

interface EconomicSettings {
  layouts: EconomicLayout[];
  units: EconomicUnit[];
}

export default function AdminInvoice() {
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [dateRange, setDateRange] = useState<{
    from: Date | null
    to: Date | null
  }>({ from: null, to: null })
  const [loading, setLoading] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null)
  const [economicInvoice, setEconomicInvoice] = useState<EconomicInvoice | null>(null)
  const [showSettings, setShowSettings] = useState(false);
  const [economicSettings, setEconomicSettings] = useState<EconomicSettings | null>(null);
  const [previousInvoice, setPreviousInvoice] = useState<EconomicInvoice | null>(null)
  const [previousInvoiceNumber, setPreviousInvoiceNumber] = useState<string | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(15)

  useEffect(() => {
    fetchInvoices()
  }, [])

  const fetchInvoices = async (start?: Date, end?: Date) => {
    setLoading(true)
    try {
      const response = await axios.get('/invoice/all', {
        params: {
          start: start?.toISOString(),
          end: end?.toISOString()
        }
      })

      console.log('Sample invoice:', response.data[0])
      setInvoices(response.data)
    } catch (error) {
      toast.error('Failed to fetch invoices')
    }
    setLoading(false)
  }

  const handleDateRangeChange = (dates: { from: Date | null; to: Date | null }) => {
    setDateRange(dates)
    if (dates.from && dates.to) {
      fetchInvoices(dates.from, dates.to)
    }
  }

  const handleReviewInvoice = async (invoice: Invoice) => {
    setSelectedInvoice(invoice)
    try {
      // First get the current draft invoice
      const invoiceResponse = await axios.get(`/invoice/economic/${invoice.economic_invoice_id}`)
      setEconomicInvoice(invoiceResponse.data)
      
      // Then try to get the previous invoice, but don't show error if not found
      try {
        const previousResponse = await axios.get(`/invoice/previous-invoice/${invoice.deal_id}/${invoice.economic_invoice_id}`)
        if (previousResponse.data.invoice) {
          setPreviousInvoice(previousResponse.data.invoice)
          setPreviousInvoiceNumber(previousResponse.data.invoice_number)
        }
      } catch (error: any) {
        // Silently handle 404 for previous invoice
        if (error?.response?.status !== 404) {
          console.error('Error fetching previous invoice:', error)
        }
        setPreviousInvoice(null)
        setPreviousInvoiceNumber(null)
      }
    } catch (error) {
      console.error('Error fetching current invoice:', error)
      toast.error('Failed to fetch invoice details')
    }
  }

  const handleBookInvoice = async () => {
    if (!selectedInvoice) return
    try {
      await axios.post(`/invoice/book/${selectedInvoice.id}`)
      toast.success('Invoice booked successfully')
      setSelectedInvoice(null)
      if (dateRange.from && dateRange.to) {
        fetchInvoices(dateRange.from, dateRange.to)
      }
    } catch (error) {
      toast.error('Failed to book invoice')
    }
  }

  const handleDeleteInvoice = async () => {
    if (!selectedInvoice) return
    try {
      await axios.delete(`/invoice/${selectedInvoice.id}`)
      toast.success('Invoice deleted successfully')
      
      // Remove the deleted invoice from the invoices array
      setInvoices(prevInvoices => 
        prevInvoices.filter(invoice => invoice.id !== selectedInvoice.id)
      )
      
      setSelectedInvoice(null)
    } catch (error) {
      toast.error('Failed to delete invoice')
    }
  }

  const handleViewPDF = async () => {
    if (!selectedInvoice) return
    try {
      const response = await axios.get(
        `/invoice/economic/${selectedInvoice.economic_invoice_id}/pdf`,
        { responseType: 'blob' }
      )
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(pdfBlob)
      window.open(pdfUrl, '_blank')
    } catch (error) {
      console.error('Error fetching PDF:', error)
      toast.error('Failed to fetch invoice PDF')
    }
  }

  const handleViewPreviousPDF = async () => {
    if (!previousInvoiceNumber) return
    try {
      const response = await axios.get(
        `/invoice/booked-invoices/${previousInvoiceNumber}/pdf`,
        { responseType: 'blob' }
      )
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(pdfBlob)
      window.open(pdfUrl, '_blank')
    } catch (error) {
      console.error('Error fetching previous PDF:', error)
      toast.error('Failed to fetch previous invoice PDF')
    }
  }

  const formatNumber = (number: number) => {
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  const renderSecondaryStatus = (status: string | undefined) => {
    switch (status) {
      case 'approved':
        return (
          <Badge variant="outline" className="bg-green-100 text-green-800">
            <CheckCircle className="h-3 w-3 mr-1" />
            Approved
          </Badge>
        )
      case 'not_approved':
        return (
          <Badge variant="outline" className="bg-red-100 text-red-800">
            <XCircle className="h-3 w-3 mr-1" />
            Not Approved
          </Badge>
        )
      case 'unsure':
        return (
          <Badge variant="outline" className="bg-yellow-100 text-yellow-800">
            <HelpCircle className="h-3 w-3 mr-1" />
            Unsure
          </Badge>
        )
      default:
        return (
          <Badge variant="outline" className="bg-muted text-muted-foreground">
            Pending Review
          </Badge>
        )
    }
  }

  const columns: ColumnDef<Invoice>[] = [
    { 
      key: 'invoice_number',
      title: 'Invoice Number',
      dataIndex: 'invoice_number',
      sortable: {
        enabled: true,
        type: 'string'
      }
    },
    { 
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      sortable: {
        enabled: true,
        type: 'string'
      }
    },
    {
      key: 'secondary_status',
      title: 'Review Status',
      dataIndex: 'secondary_status',
      render: renderSecondaryStatus,
      sortable: {
        enabled: true,
        type: 'string'
      },
      filterable: true,
      filters: [
        { text: 'Pending Review', value: 'pending' },
        { text: 'Approved', value: 'approved' },
        { text: 'Not Approved', value: 'not_approved' },
        { text: 'Unsure', value: 'unsure' }
      ],
      onFilter: (value: string, record: Invoice) => {
        if (value === 'pending') {
          return record.secondary_status === undefined || record.secondary_status === null;
        }
        return record.secondary_status === value;
      }
    },
    { 
      key: 'total_amount',
      title: 'Total Amount, kr.',
      dataIndex: 'total_amount',
      render: (value) => `${formatNumber(value)} u/moms`
    },
    { 
      key: 'created_at',
      title: 'Created At',
      dataIndex: 'created_at',
      render: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
      sortable: {
        enabled: true,
        type: 'date'
      }
    }
  ]

  const lineColumns: ColumnDef<InvoiceLine>[] = [
    { key: 'description', title: 'Description', dataIndex: 'description' },
    { key: 'quantity', title: 'Quantity', dataIndex: 'quantity' },
    { 
      key: 'unitNetPrice',
      title: 'Unit Price (u/moms)',
      dataIndex: 'unitNetPrice',
      render: (value) => `${formatNumber(value)} kr.`
    },
    { 
      key: 'totalNetAmount',
      title: 'Total (u/moms)',
      dataIndex: 'totalNetAmount',
      render: (value) => `${formatNumber(value)} kr.`
    }
  ]

  const fetchEconomicSettings = async () => {
    try {
      const response = await axios.get('/invoice/economic/settings');
      setEconomicSettings(response.data);
      setShowSettings(true);
    } catch (error) {
      toast.error('Failed to fetch E-conomic settings');
    }
  };

  const formatDate = (dateString: string | null | undefined) => {
    if (!dateString) return 'N/A';
    try {
      return format(new Date(dateString), 'yyyy-MM-dd');
    } catch (error) {
      console.error('Error formatting date:', dateString);
      return 'Invalid Date';
    }
  };

  const resetInvoiceStates = () => {
    setSelectedInvoice(null)
    setEconomicInvoice(null)
    setPreviousInvoice(null)
    setPreviousInvoiceNumber(null)
  }

  const handleStatusUpdate = async (status: 'approved' | 'not_approved' | 'unsure') => {
    if (!selectedInvoice) return
    try {
      console.log('Updating invoice with ID:', selectedInvoice.id)
      
      const response = await axios.put(
        `/invoice/secondary-status/${selectedInvoice.id}`,
        { status }
      )
      
      // Update the invoice in the invoices array
      setInvoices(prevInvoices => 
        prevInvoices.map(invoice => 
          invoice.id === selectedInvoice.id 
            ? { ...invoice, secondary_status: status }
            : invoice
        )
      )
      
      setSelectedInvoice(prev => 
        prev ? { ...prev, secondary_status: status } : null
      )
      
      toast.success('Invoice status updated')
    } catch (error: any) {
      console.error('Error updating status:', error)
      toast.error(error.response?.data?.error || 'Failed to update invoice status')
    }
  }

  return (
    <div className="max-w-[1200px] mx-auto mt-4 p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-foreground">Invoices</h1>
        <DatePickerWithRange value={dateRange} onChange={handleDateRangeChange} />
      </div>

      {loading ? (
        <Loading />
      ) : (
        <Table
          data={invoices}
          columns={columns}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          onPageSizeChange={setPageSize}
          onRowClick={handleReviewInvoice}
        />
      )}

      <Button
        variant="outline"
        onClick={fetchEconomicSettings}
        className="gap-2"
      >
        <FileSearch className="h-4 w-4" />
        View E-conomic Settings
      </Button>

      <Dialog 
        open={!!selectedInvoice} 
        onOpenChange={(open) => {
          if (!open) resetInvoiceStates()
        }}
      >
        <DialogContent className="max-w-4xl">
          <DialogHeader>
            <DialogTitle>Review Invoice</DialogTitle>
          </DialogHeader>

          {economicInvoice && (
            <div className="space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-muted-foreground">Invoice Number</p>
                  <p className="font-medium text-foreground">{economicInvoice.draftInvoiceNumber}</p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">Customer</p>
                  <p className="font-medium text-foreground">{economicInvoice.customer.name}</p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">Invoice Date</p>
                  <p className="font-medium text-foreground">
                    {formatDate(economicInvoice.invoiceDate)}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">Due Date</p>
                  <p className="font-medium text-foreground">
                    {formatDate(economicInvoice.dueDate)}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">Total Amount (excl. VAT)</p>
                  <div className="flex items-center gap-2">
                    <p className="font-medium text-foreground">
                      {formatNumber(economicInvoice.grossAmount / 1.25)} kr.
                    </p>
                    {previousInvoice && 
                     previousInvoice.grossAmount / 1.25 !== economicInvoice.grossAmount / 1.25 && (
                      <Badge variant="outline" className="bg-yellow-100 text-yellow-800">
                        Changed from {formatNumber(previousInvoice.grossAmount / 1.25)} kr.
                      </Badge>
                    )}
                  </div>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">Total Amount (incl. VAT)</p>
                  <div className="flex items-center gap-2">
                    <p className="font-medium text-foreground">
                      {formatNumber(economicInvoice.grossAmount)} kr.
                    </p>
                    {previousInvoice && 
                     previousInvoice.grossAmount !== economicInvoice.grossAmount && (
                      <Badge variant="outline" className="bg-yellow-100 text-yellow-800">
                        Changed from {formatNumber(previousInvoice.grossAmount)} kr.
                      </Badge>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-4 text-foreground">Line Items</h3>
                <div className="relative">
                  {loading && (
                    <div className="absolute inset-0 bg-background/50 backdrop-blur-sm z-10 flex items-center justify-center">
                      <Loading size="sm" message="Loading details..." />
                    </div>
                  )}
                  <Table
                    columns={lineColumns}
                    data={economicInvoice.lines}
                    pageSize={5}
                  />
                </div>
              </div>
            </div>
          )}

          <DialogFooter className="flex flex-col gap-4 sm:flex-col">
            <div className="flex justify-start gap-2 w-full">
              <TooltipProvider delayDuration={0}>
                <div className="flex gap-2">
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => handleStatusUpdate('approved')}
                        className={selectedInvoice?.secondary_status === 'approved' ? 'bg-green-100' : ''}
                      >
                        <CheckCircle className="h-4 w-4 text-green-600" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Approve Invoice</p>
                    </TooltipContent>
                  </Tooltip>

                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => handleStatusUpdate('not_approved')}
                        className={selectedInvoice?.secondary_status === 'not_approved' ? 'bg-red-100' : ''}
                      >
                        <XCircle className="h-4 w-4 text-red-600" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Don't Approve Invoice</p>
                    </TooltipContent>
                  </Tooltip>

                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => handleStatusUpdate('unsure')}
                        className={selectedInvoice?.secondary_status === 'unsure' ? 'bg-yellow-100' : ''}
                      >
                        <HelpCircle className="h-4 w-4 text-yellow-600" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Mark as Unsure</p>
                    </TooltipContent>
                  </Tooltip>
                </div>
              </TooltipProvider>
            </div>
            
            <div className="flex justify-start gap-2 w-full">
              <Button
                variant="outline"
                onClick={handleViewPDF}
                className="gap-2"
              >
                <FileText className="h-4 w-4" />
                View PDF
              </Button>
              {previousInvoiceNumber && (
                <Button
                  variant="outline"
                  onClick={handleViewPreviousPDF}
                  className="gap-2"
                >
                  <FileText className="h-4 w-4" />
                  View Previous PDF
                </Button>
              )}
              <Button
                variant="destructive"
                onClick={handleDeleteInvoice}
                className="gap-2"
              >
                <Trash2 className="h-4 w-4" />
                Delete Invoice
              </Button>
              <Button
                onClick={handleBookInvoice}
                className="gap-2"
              >
                <BookOpen className="h-4 w-4" />
                Book Invoice
              </Button>
              <Button
                variant="outline"
                onClick={() => setSelectedInvoice(null)}
                className="gap-2"
              >
                <X className="h-4 w-4" />
                Cancel
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={showSettings} onOpenChange={setShowSettings}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>E-conomic Settings</DialogTitle>
          </DialogHeader>
          
          {economicSettings && (
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-2">Layouts</h3>
                <div className="grid grid-cols-2 gap-2">
                  {economicSettings.layouts.map((layout) => (
                    <div key={layout.layoutNumber} className="p-2 border rounded">
                      <p className="font-medium">{layout.name}</p>
                      <p className="text-sm text-muted-foreground">ID: {layout.layoutNumber}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">Units</h3>
                <div className="grid grid-cols-2 gap-2">
                  {economicSettings.units.map((unit) => (
                    <div key={unit.unitNumber} className="p-2 border rounded">
                      <p className="font-medium">{unit.name}</p>
                      <p className="text-sm text-muted-foreground">ID: {unit.unitNumber}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <DialogFooter>
            <Button variant="outline" onClick={() => setShowSettings(false)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}