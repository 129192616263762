import React, { useEffect, useState } from 'react'
import { Camera, Loader2 } from 'lucide-react'
import { format } from 'date-fns'
import axios from '../api/axiosConfig'
import { useUsers } from '@/contexts/UserContext'

import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogBody,
  DialogFooter,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

import UserAvatar from '@/components/user-avatar';
import { cn } from '@/lib/utils'
import { useForm } from 'react-hook-form'
import { ProfileData, LocationInfo } from '@/types/user'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import { Check, ChevronsUpDown } from "lucide-react"

interface UserProfileProps {
  visible: boolean
  onClose: () => void
  profileData: ProfileData
  countries: string[]
  onProfileUpdate: (data: any) => Promise<void>
  onAvatarUpdate: () => void
}

interface FormValues {
  name: string
  surname: string
  email: string
  birthday: Date | null
  city: string
  country: string
}

export function UserProfile({
  visible,
  onClose,
  profileData,
  countries,
  onProfileUpdate,
  onAvatarUpdate,
}: UserProfileProps) {
  const { refreshUsers } = useUsers()
  const [showAvatarUpload, setShowAvatarUpload] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [loadingText, setLoadingText] = useState('Uploading...')
  const [openCountry, setOpenCountry] = useState(false)

  const form = useForm<FormValues>({
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      birthday: null,
      city: '',
      country: '',
    },
  })

  const handleSubmit = async (values: any) => {
    try {
      const updatedData = {
        ...values,
        birthday: values.birthday ? format(values.birthday, 'yyyy-MM-dd') : null,
        location_info: {
          city: values.city,
          country: values.country,
        },
      }
      await onProfileUpdate(updatedData)

      // Update local storage
      const storedUser = localStorage.getItem('user')
      if (storedUser) {
        const user = JSON.parse(storedUser)
        const updatedUser = {
          ...user,
          ...updatedData,
        }
        localStorage.setItem('user', JSON.stringify(updatedUser))

        // Update usersData in localStorage
        const storedUsersData = localStorage.getItem('usersData')
        if (storedUsersData) {
          const usersData = JSON.parse(storedUsersData)
          usersData[user.user_id] = {
            ...usersData[user.user_id],
            ...updatedData,
          }
          localStorage.setItem('usersData', JSON.stringify(usersData))
        }
      }

      // Refresh UserContext data
      await refreshUsers()

      onClose()
    } catch (error) {
      console.error('Failed to update profile')
    }
  }

  const handleAvatarUpload = async (file: File) => {
    const formData = new FormData()
    formData.append('avatar', file)

    try {
      setIsUploading(true)
      setLoadingText('Uploading...')
      setShowAvatarUpload(false)
      const response = await axios.post('/user/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      
      if (response.data.avatar_urls) {
        onProfileUpdate({ 
          ...profileData, 
          avatar_urls: response.data.avatar_urls,
          settings: profileData.settings
        })
        onAvatarUpdate()
      }
    } catch (error) {
      console.error('Failed to upload avatar')
    } finally {
      setIsUploading(false)
    }
  }

  const handleAvatarDelete = async () => {
    try {
      setIsUploading(true)
      setLoadingText('Removing...')
      setShowAvatarUpload(false)
      await axios.delete('/user/avatar')
      onProfileUpdate({ 
        ...profileData, 
        avatar_urls: {},
        settings: profileData.settings
      })
      onAvatarUpdate()
    } catch (error) {
      console.error('Failed to remove avatar')
    } finally {
      setIsUploading(false)
    }
  }

  useEffect(() => {
    if (profileData) {
      form.reset({
        name: profileData.name,
        surname: profileData.surname,
        email: profileData.email,
        birthday: profileData.birthday ? new Date(profileData.birthday) : null,
        city: profileData.location_info?.city || '',
        country: profileData.location_info?.country || '',
      })
    }
  }, [profileData, form])

  return (
    <Dialog open={visible} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>My Profile</DialogTitle>
          <DialogDescription className="sr-only">
            Edit your profile information including name, surname, birthday, location and profile picture
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <DialogBody maxBodyHeight>
              <div className="space-y-6">
                <div className="flex flex-col items-center space-y-4">
                  <div 
                    className="relative w-[120px] h-[120px] rounded-full overflow-hidden cursor-pointer"
                    onMouseEnter={() => !isUploading && setShowAvatarUpload(true)}
                    onMouseLeave={() => !isUploading && setShowAvatarUpload(false)}
                  >
                    <UserAvatar 
                      user={profileData}
                      className="w-full h-full"
                    />
                    
                    {isUploading && (
                      <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/70 text-white">
                        <Loader2 className="h-8 w-8 animate-spin mb-2" />
                        <span className="text-sm">{loadingText}</span>
                      </div>
                    )}

                    {profileData?.avatar_urls && Object.keys(profileData.avatar_urls).length > 0 ? (
                      <div className={cn(
                        "absolute inset-0 bg-black/50 flex flex-col items-center justify-center opacity-0 transition-opacity",
                        showAvatarUpload && "opacity-100"
                      )}>
                        <div className="flex flex-col items-center mb-8">
                          <Camera className="h-6 w-6 text-white" />
                        </div>
                        <div className="absolute inset-0 flex">
                          <div className="flex-1 flex items-center justify-center hover:bg-white/20">
                            <input
                              type="file"
                              className="absolute inset-0 opacity-0 cursor-pointer"
                              onChange={(e) => {
                                const file = e.target.files?.[0]
                                if (file) handleAvatarUpload(file)
                              }}
                              accept="image/*"
                            />
                            <span className="text-white text-sm">Change</span>
                          </div>
                          <div 
                            className="flex-1 flex items-center justify-center hover:bg-white/20"
                            onClick={handleAvatarDelete}
                          >
                            <span className="text-white text-sm">Remove</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={cn(
                        "absolute inset-0 bg-black/50 flex flex-col items-center justify-center opacity-0 transition-opacity",
                        showAvatarUpload && "opacity-100"
                      )}>
                        <input
                          type="file"
                          className="absolute inset-0 opacity-0 cursor-pointer"
                          onChange={(e) => {
                            const file = e.target.files?.[0]
                            if (file) handleAvatarUpload(file)
                          }}
                          accept="image/*"
                        />
                        <Camera className="h-6 w-6 text-white mb-2" />
                        <span className="text-white text-sm">Add Image</span>
                      </div>
                    )}
                  </div>

                  <span className="px-3 py-1 text-sm bg-primary text-primary-foreground rounded-full">
                    {profileData?.permissions || 'User'}
                  </span>
                </div>

                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Name</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="surname"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Surname</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>

                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input {...field} readOnly className="bg-muted" />
                        </FormControl>
                      </FormItem>
                    )}
                  />

                  <div className="grid grid-cols-2 gap-4">
                    <FormField
                      control={form.control}
                      name="birthday"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Birthday</FormLabel>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant="outline"
                                  className={cn(
                                    "w-full pl-3 text-left font-normal",
                                    !field.value && "text-muted-foreground"
                                  )}
                                >
                                  {field.value ? (
                                    format(field.value, "PPP")
                                  ) : (
                                    <span>Pick a date</span>
                                  )}
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0" align="start">
                              <Calendar
                                mode="single"
                                selected={field.value || undefined}
                                onSelect={field.onChange}
                                initialFocus
                              />
                            </PopoverContent>
                          </Popover>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="city"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>City</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>

                  <FormField
                    control={form.control}
                    name="country"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Country</FormLabel>
                        <Popover open={openCountry} onOpenChange={setOpenCountry}>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                role="combobox"
                                aria-expanded={openCountry}
                                className="w-full justify-between"
                              >
                                {field.value || "Select country..."}
                                <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-[200px] p-0">
                            <Command>
                              <CommandInput placeholder="Search country..." />
                              <CommandList>
                                <CommandEmpty>No country found.</CommandEmpty>
                                <CommandGroup>
                                  {countries.map((country) => (
                                    <CommandItem
                                      key={country}
                                      value={country}
                                      onSelect={(currentValue) => {
                                        field.onChange(currentValue)
                                        setOpenCountry(false)
                                      }}
                                    >
                                      {country}
                                      <Check
                                        className={cn(
                                          "ml-auto h-4 w-4",
                                          field.value === country ? "opacity-100" : "opacity-0"
                                        )}
                                      />
                                    </CommandItem>
                                  ))}
                                </CommandGroup>
                              </CommandList>
                            </Command>
                          </PopoverContent>
                        </Popover>
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </DialogBody>

            <DialogFooter>
              <Button type="submit">
                Update Profile
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}