interface Product {
  name: string;
  code: string;
  defaultPrice: number;
  frequencies: string[];
}

export const dealProductConfig = {
  products: [
    { 
      name: 'Bogføring', 
      code: '1001',
      defaultPrice: 995,
      frequencies: ['monthly', 'quarterly', 'annually']
    },
    { 
      name: 'Onboarding', 
      code: '1008',
      defaultPrice: 4485,
      frequencies: ['one-time']
    },
    { 
      name: 'Lønadministration', 
      code: '1009',
      defaultPrice: 100,
      frequencies: ['monthly']
    },
    { 
      name: 'Holdingselskaber', 
      code: '1010',
      defaultPrice: 400,
      frequencies: ['monthly', 'annually']
    },
    { 
      name: 'Årsregnskab', 
      code: '1007',
      defaultPrice: 3499,
      frequencies: ['annually', 'one-time']
    }
  ] as Product[]
}

export type { Product }