import { useState } from 'react';
import { toast } from 'sonner';
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import type { Task } from '@/types/task';
import type { Board } from '@/types/custom-board';
import type { StoredUser } from '@/types/user';

interface TaskViewOverviewProps {
  task: Task;
  board: Board | null;
  users: StoredUser[];
  onDescriptionUpdate: (newDescription: string) => Promise<void>;
}

export function TaskViewOverview({ task, board, users, onDescriptionUpdate }: TaskViewOverviewProps) {
  const [description, setDescription] = useState(task.description || '');
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await onDescriptionUpdate(description);
      setIsEditing(false);
      toast.success('Description updated successfully');
    } catch (error) {
      toast.error('Failed to update description');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto">
        <div className="p-4">
          {isEditing ? (
            <>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="min-h-[200px]"
                placeholder="Enter task description..."
              />
              <div className="flex gap-2 justify-end mt-4">
                <Button
                  variant="outline"
                  onClick={() => {
                    setDescription(task.description || '');
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </Button>
                <Button 
                  onClick={handleSave}
                  disabled={isSaving}
                >
                  {isSaving ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="prose dark:prose-invert max-w-none whitespace-pre-wrap">
                {task.description || 'No description provided'}
              </div>
              <Button
                variant="outline"
                onClick={() => setIsEditing(true)}
                className="mt-4"
              >
                Edit Description
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TaskViewOverview;
