import { Type, Square, FileText, ListFilter, FileQuestion, Webhook, Database, CheckSquare } from "lucide-react";
import { NodeElementType, NodeElement, InputConfig, ButtonConfig, TextConfig, SelectConfig, NotionGuideConfig, DealDataConfig, TaskConfigType } from "../types/node_types";

export const ElementTypeIcons: Record<NodeElementType, React.ComponentType> = {
  'input': Type,
  'button': Square,
  'text': FileText,
  'select': ListFilter,
  'notionGuide': FileQuestion,
  'apiTrigger': Webhook,
  'dealData': Database,
  'taskConfig': CheckSquare
};

export const ElementTypeLabels: Record<NodeElementType, string> = {
  'button': 'Button',
  'input': 'Input Field',
  'select': 'Select Field',
  'text': 'Text Block',
  'notionGuide': 'Notion Guide',
  'apiTrigger': 'API Trigger',
  'dealData': 'Deal Data',
  'taskConfig': 'Task Configuration'
};

export const getDefaultConfig = (type: NodeElementType): NodeElement['config'] => {
  switch (type) {
    case 'input':
      return {
        label: '',
        type: 'text',
        placeholder: '',
        required: false
      } as InputConfig;
    case 'button':
      return {
        label: '',
        variant: 'default',
        action: 'next'
      } as ButtonConfig;
    case 'text':
      return {
        content: '',
        variant: 'default'
      } as TextConfig;
    case 'select':
      return {
        label: '',
        placeholder: '',
        required: false,
        options: [{ label: 'Option 1', value: 'option_1' }]
      } as SelectConfig;
    case 'notionGuide':
      return {
        pageId: '',
        title: ''
      } as NotionGuideConfig;
    case 'dealData':
      return {
        fields: [],
        title: 'Deal Information',
        description: '',
        layout: {
          columns: 2
        }
      } as DealDataConfig;
    case 'taskConfig':
      return {
        recurringTasks: [],
        onboardingTasks: []
      } as TaskConfigType;
    default:
      throw new Error(`Unknown element type: ${type}`);
  }
}; 