import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"

const badgeVariants = cva(
  "inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 min-h-[32px] max-h-[32px]",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive/80",
        outline: "text-foreground",
        warning:
          "border-transparent bg-yellow-500 text-foreground shadow hover:bg-yellow-600",
        success:
          "border-transparent bg-green-500 text-foreground shadow hover:bg-green-600",
        info:
          "border-transparent bg-blue-500 text-foreground shadow hover:bg-blue-600",
        attention:
          "border-transparent bg-purple-500 text-foreground shadow hover:bg-purple-600",
        compact:
          "border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80 min-h-[24px] max-h-[24px]",
        custom:
          "border-transparent shadow",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(
      badgeVariants({ variant }), 
      "gap-1.5 inline-flex items-center",
      className
    )} {...props} />
  )
}

export { Badge, badgeVariants }
