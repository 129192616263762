import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { 
  Tag as TagIcon, 
  X as CloseIcon, 
  Edit, 
  Trash2, 
  Save,
  Pin 
} from 'lucide-react';
import { cn } from '@/lib/utils';
import axios from '@/api/axiosConfig';
import { parseISO, format } from 'date-fns';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"

interface StickyTasksProps {
  isVisible: boolean;
  isMaximized?: boolean;
  onOpen: () => void;
  onClose: () => void;
}

interface Task {
  id: string;
  text: string;
  task_date?: string;
  deal_id?: string;
}

interface Deal {
  id: string;
  name: string;
}

interface FilteredDeal {
  value: string;
  id: string;
}

const STICKY_TASKS_DISABLED = true;

const formatTaskDate = (dateString: string) => {
  if (!dateString) return '';
  const date = parseISO(dateString);
  return format(date, "dd/MM 'at' HH:mm");
};

export const StickyTasks: React.FC<StickyTasksProps> = ({ isVisible, isMaximized, onOpen, onClose }) => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [newTask, setNewTask] = useState('');
  const [editingTask, setEditingTask] = useState<Task | null>(null);
  const [deals, setDeals] = useState<Deal[]>([]);
  const [filteredDeals, setFilteredDeals] = useState<FilteredDeal[]>([]);
  const [selectedDealIndex, setSelectedDealIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const taggedDealRef = useRef<Deal | null>(null);

  /*
  useEffect(() => {
    fetchTasks();
  }, []);
  */

  const fetchTasks = async () => {
    try {
      const response = await axios.get('/ptask');
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const addTask = async () => {
    if (newTask.trim() === '') return;
    
    try {
      const response = await axios.post('/ptask', { 
        text: newTask
      });
      setTasks([...tasks, response.data]);
      setNewTask('');
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  const deleteTask = async (taskId: string) => {
    try {
      await axios.delete(`/ptask/${taskId}`);
      setTasks(tasks.filter(task => task.id !== taskId));
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const startEditing = (task: Task) => {
    setEditingTask({ ...task });
  };

  const saveEdit = async () => {
    if (!editingTask) return;
    
    try {
      const response = await axios.put(`/ptask/${editingTask.id}`, { text: editingTask.text });
      setTasks(tasks.map(task => task.id === editingTask.id ? response.data : task));
      setEditingTask(null);
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const handleNewTaskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setNewTask(newValue);
    
    const hashtagMatch = newValue.match(/#(\w+)(?:\s|$)/);
    
    if (hashtagMatch) {
      const taggedDealName = hashtagMatch[1];
      const matchedDeal = deals.find(deal => 
        deal.name.toLowerCase() === taggedDealName.toLowerCase()
      );
      
      if (matchedDeal) {
        taggedDealRef.current = matchedDeal;
      } else {
        const searchText = taggedDealName;
        if (searchText) {
          const filtered = filterDeals(searchText);
          setFilteredDeals(filtered);
          setSelectedDealIndex(0);
        } else {
          setFilteredDeals([]);
        }
      }
    } else {
      setFilteredDeals([]);
    }
  };

  const handleDealSelect = (dealName: string) => {
    const selectedDeal = deals.find(deal => deal.name === dealName);
    if (selectedDeal) {
      taggedDealRef.current = selectedDeal;
      setNewTask(newTask.replace(/#[^#]*$/, `#${dealName} `));
      setFilteredDeals([]);
      inputRef.current?.focus();
    }
  };

  const filterDeals = (searchText: string): FilteredDeal[] => {
    if (!searchText) return [];
    return deals
      .filter(deal => 
        deal.name.toLowerCase().startsWith(searchText.toLowerCase())
      )
      .map(deal => ({ value: deal.name, id: deal.id }));
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (filteredDeals.length > 0) {
      if (e.key === 'Tab') {
        e.preventDefault();
        setSelectedDealIndex((prevIndex) => 
          (prevIndex + 1) % filteredDeals.length
        );
      } else if (e.key === 'Enter') {
        e.preventDefault();
        handleDealSelect(filteredDeals[selectedDealIndex].value);
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      addTask();
    }
  };

  const renderTaskText = (text: string) => {
    const parts = text.split(/(#\w+)/);
    return parts.map((part, index) => {
      if (part.startsWith('#')) {
        return (
          <span key={index} className="inline-flex items-center gap-1 text-blue-500">
            <TagIcon className="h-3 w-3" />
            <span className="bg-blue-100 px-1 rounded">
              {part.slice(1)}
            </span>
          </span>
        );
      }
      return part;
    });
  };

  if (!isMaximized) {
    return (
      <div className={cn(
        'opacity-0 pointer-events-none transition-all duration-300',
        isVisible && 'opacity-100 pointer-events-auto'
      )}>
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div>
                <Button
                  variant="ghost"
                  size="icon"
                  className={cn(
                    '!w-12 !h-12 !rounded-full !bg-gradient-to-br',
                    STICKY_TASKS_DISABLED 
                      ? '!from-gray-400 !to-gray-500' 
                      : '!from-yellow-400 !to-yellow-600',
                    'hover:translate-y-[-2px] hover:scale-105 transition-all duration-300',
                    'shadow-lg hover:shadow-yellow-500/20'
                  )}
                  onClick={onOpen}
                  disabled={STICKY_TASKS_DISABLED}
                >
                  <Pin size={32} className="text-white transform scale-150" />
                </Button>
              </div>
            </TooltipTrigger>
            <TooltipContent side="left">
              <p>{STICKY_TASKS_DISABLED ? "Coming Soon" : "To Do"}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    );
  }

  return (
    <div className={cn(
      'fixed z-50 transition-all duration-300',
      isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none',
      isMaximized && 'bottom-6 right-6'
    )}>
      <Card className="w-80 shadow-2xl flex flex-col">
        <div className="flex items-center justify-between p-4 border-b">
          <div className="flex items-center gap-2">
            <div className="w-2 h-2 rounded-full bg-yellow-500" />
            <h3 className="text-sm font-medium">
              <span className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-transparent bg-clip-text">
                Sticky Tasks
              </span>
            </h3>
          </div>
          <Button 
            variant="ghost" 
            size="icon"
            onClick={onClose}
          >
            <CloseIcon className="h-4 w-4" />
          </Button>
        </div>

        <div className="p-4">
          <Tabs defaultValue="create" className="w-full">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="create">Create</TabsTrigger>
              <TabsTrigger value="view">View</TabsTrigger>
            </TabsList>
            
            <TabsContent value="create" className="space-y-4">
              <div className="relative">
                {filteredDeals.length > 0 && (
                  <Card className="absolute bottom-full w-full mb-1 max-h-32 overflow-auto">
                    <div className="p-1 space-y-1">
                      {filteredDeals.map((deal, index) => (
                        <div
                          key={deal.id}
                          className={cn(
                            "px-2 py-1 rounded cursor-pointer",
                            index === selectedDealIndex ? "bg-yellow-100" : "hover:bg-yellow-50"
                          )}
                          onClick={() => handleDealSelect(deal.value)}
                        >
                          {deal.value}
                        </div>
                      ))}
                    </div>
                  </Card>
                )}
                <Input
                  ref={inputRef}
                  placeholder="Write a new task... (Use # to tag deals)"
                  value={newTask}
                  onChange={handleNewTaskChange}
                  onKeyDown={handleKeyDown}
                  className="w-full bg-background text-foreground border-input"
                />
              </div>
              <Button 
                onClick={addTask} 
                className="w-full bg-gradient-to-br from-yellow-400 to-yellow-500 
                  text-white hover:opacity-90 border-none"
              >
                Stick it!
              </Button>
            </TabsContent>

            <TabsContent value="view">
              <div className="space-y-2">
                {tasks.map((task) => (
                  <div
                    key={task.id}
                    className="flex items-center justify-between p-2 bg-background border border-input rounded"
                  >
                    {editingTask && editingTask.id === task.id ? (
                      <div className="flex items-center gap-2 flex-1">
                        <Input
                          value={editingTask.text}
                          onChange={(e) => setEditingTask({ ...editingTask, text: e.target.value })}
                          onKeyDown={(e) => e.key === 'Enter' && saveEdit()}
                          className="flex-1 bg-background text-foreground border-input"
                        />
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={saveEdit}
                          className="text-yellow-600 hover:text-yellow-700"
                        >
                          <Save className="h-4 w-4" />
                        </Button>
                      </div>
                    ) : (
                      <>
                        <div className="flex flex-col flex-1">
                          <span className="text-foreground">
                            {renderTaskText(task.text)}
                          </span>
                          {task.task_date && (
                            <span className="text-sm text-muted-foreground">
                              {formatTaskDate(task.task_date)}
                            </span>
                          )}
                        </div>
                        <div className="flex gap-1">
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => startEditing(task)}
                            className="text-blue-500 hover:text-blue-600"
                          >
                            <Edit className="h-4 w-4" />
                          </Button>
                          <AlertDialog>
                            <AlertDialogTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                className="text-red-500 hover:text-red-600"
                              >
                                <Trash2 className="h-4 w-4" />
                              </Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                                <AlertDialogDescription>
                                  This will permanently delete this sticky task.
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction
                                  onClick={() => deleteTask(task.id)}
                                  className="bg-red-500 hover:bg-red-600"
                                >
                                  Delete
                                </AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </Card>
    </div>
  );
};

export default StickyTasks;