import { useState, useEffect } from "react"
import { Users, Store, BarChart2, TrendingUp } from "lucide-react"
import axios from '@/api/axiosConfig'
import { toast } from "sonner"
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { PieDonutChart } from "@/components/ui/charts/pie-donut"
import { BarChartHorizontalMix } from "@/components/ui/charts/bar-horizontal-mix"
import { LineChartMix } from "@/components/ui/charts/line-mix"
import { LoadingSpinner } from "@/components/ui/loading-spinner"

// Types
interface CustomerStats {
  total_stats: {
    total_customers: number
    active_customers: number
    total_products: number
    average_products_per_customer: number
  }
  customer_growth: {
    cumulative: Record<string, number>
    monthly: Record<string, number>
  }
  product_distribution: Record<string, { count: number }>
  industry_distribution: Record<string, number>
}

interface MapData {
  locations: Record<string, {
    coordinates: {
      lat: number
      lng: number
    }
    count: number
  }>
}

// Leaflet icon configuration
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
})

export default function CustomerDash() {
  const [loading, setLoading] = useState(true)
  const [mapLoading, setMapLoading] = useState(true)
  const [customerData, setCustomerData] = useState<CustomerStats | null>(null)
  const [mapData, setMapData] = useState<MapData | null>(null)
  const [selectedChart, setSelectedChart] = useState('products')

  useEffect(() => {
    fetchCustomerData()
    fetchMapData()
  }, [])

  const fetchCustomerData = async () => {
    try {
      setLoading(true)
      const response = await axios.get('/dashboard/customers')
      setCustomerData(response.data)
    } catch (error) {
      console.error('Error fetching customer data:', error)
      toast.error("Failed to fetch customer data")
    } finally {
      setLoading(false)
    }
  }

  const fetchMapData = async () => {
    try {
      setMapLoading(true)
      const response = await axios.get('/dashboard/customers/locations')
      setMapData(response.data)
    } catch (error) {
      console.error('Error fetching map data:', error)
      toast.error("Failed to fetch location data")
    } finally {
      setMapLoading(false)
    }
  }

  if (loading) {
    return (
      <div className="flex h-[400px] items-center justify-center">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div className="space-y-6">
      <StatCards customerData={customerData} />
      <div className="grid gap-6 lg:grid-cols-2">
        <ChartSection 
          customerData={customerData} 
          selectedChart={selectedChart} 
          onChartChange={setSelectedChart} 
        />
        <MapSection 
          mapData={mapData} 
          mapLoading={mapLoading} 
        />
      </div>
    </div>
  )
}

// ... (Part 1 code remains the same until the components) ...

function StatCards({ customerData }: { customerData: CustomerStats | null }) {
  const stats = [
    {
      title: "Total Customers",
      value: customerData?.total_stats?.total_customers,
      icon: Users,
    },
    {
      title: "Active Customers",
      value: customerData?.total_stats?.active_customers,
      icon: Store,
    },
    {
      title: "Total Products",
      value: customerData?.total_stats?.total_products,
      icon: BarChart2,
    },
    {
      title: "Avg Products/Customer",
      value: customerData?.total_stats?.average_products_per_customer?.toFixed(2),
      icon: TrendingUp,
    }
  ]

  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
      {stats.map((stat) => (
        <Card key={stat.title}>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">{stat.title}</CardTitle>
            <stat.icon className="h-4 w-4 text-primary" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stat.value}</div>
          </CardContent>
        </Card>
      ))}
    </div>
  )
}

function ChartSection({ 
  customerData, 
  selectedChart, 
  onChartChange 
}: { 
  customerData: CustomerStats | null
  selectedChart: string
  onChartChange: (value: string) => void
}) {
  const renderGrowthChart = () => {
    if (!customerData?.customer_growth?.cumulative) return null

    const data = Object.entries(customerData.customer_growth.cumulative).map(([date, value]) => ({
      date,
      "Total Customers": value,
      "New Customers": customerData.customer_growth.monthly[date] || 0
    }))

    return (
      <LineChartMix
        data={data}
        title="Customer Growth"
        categories={["Total Customers", "New Customers"]}
        index="date"
        colors={["hsl(var(--primary))", "hsl(var(--secondary))"]}
        valueFormatter={(value: number) => value.toString()}
      />
    )
  }

  const renderProductDistribution = () => {
    if (!customerData?.product_distribution) return null

    const data = Object.entries(customerData.product_distribution).map(([product, { count }], index) => ({
      name: product,
      value: count,
      fill: `hsl(var(--chart-${index + 1}))`
    }))

    const config = {
      value: { label: "Products" },
      ...Object.fromEntries(
        Object.keys(customerData.product_distribution).map((product, index) => [
          product,
          {
            label: product,
            color: `hsl(var(--chart-${index + 1}))`
          }
        ])
      )
    }

    return (
      <PieDonutChart
        data={data}
        config={config}
        title="Product Distribution"
        centerLabel={{
          value: data.reduce((sum, item) => sum + item.value, 0),
          label: "Total"
        }}
      />
    )
  }

  const renderIndustryDistribution = () => {
    if (!customerData?.industry_distribution) return null

    const topIndustries = Object.entries(customerData.industry_distribution)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 5)

    const data = topIndustries.map(([industry, count], index) => ({
      name: industry,
      value: count,
      fill: `hsl(var(--chart-${index + 1}))`
    }))

    const config = {
      value: { label: "Customers" },
      ...Object.fromEntries(
        topIndustries.map(([industry], index) => [
          industry,
          {
            label: industry,
            color: `hsl(var(--chart-${index + 1}))`
          }
        ])
      )
    }

    return (
      <BarChartHorizontalMix
        data={data}
        config={config}
        title="Top 5 Industries"
        className="h-[400px]"
      />
    )
  }

  return (
    <Card className="h-fit">
      <CardHeader>
        <div className="flex items-center justify-between">
          <CardTitle>Customer Analytics</CardTitle>
          <Select value={selectedChart} onValueChange={onChartChange}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select chart" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="products">Product Distribution</SelectItem>
              <SelectItem value="growth">Customer Growth</SelectItem>
              <SelectItem value="industries">Industry Distribution</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </CardHeader>
      <CardContent>
        {selectedChart === 'growth' && renderGrowthChart()}
        {selectedChart === 'products' && renderProductDistribution()}
        {selectedChart === 'industries' && renderIndustryDistribution()}
      </CardContent>
    </Card>
  )
}

function MapSection({ mapData, mapLoading }: { mapData: MapData | null, mapLoading: boolean }) {
  if (mapLoading) {
    return (
      <Card>
        <CardContent className="h-[400px] flex items-center justify-center">
          <LoadingSpinner />
        </CardContent>
      </Card>
    )
  }

  if (!mapData?.locations) return null

  return (
    <Card>
      <CardHeader>
        <CardTitle>Customer Distribution Map</CardTitle>
      </CardHeader>
      <CardContent className="h-[400px]">
        <MapContainer
          center={[56.2639, 9.5018] as L.LatLngExpression}
          zoom={7}
          className="h-full w-full"
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {Object.entries(mapData.locations).map(([location, data]) => {
            const [zipcode, ...cityParts] = location.split(' ')
            const city = cityParts.join(' ')
            const { coordinates, count } = data

            if (!coordinates) return null

            return (
              <Marker 
                key={location} 
                position={[coordinates.lat, coordinates.lng]}
              >
                <Popup>
                  <strong>{city}</strong><br />
                  Zipcode: {zipcode}<br />
                  Customers: {count}
                </Popup>
              </Marker>
            )
          })}
        </MapContainer>
      </CardContent>
    </Card>
  )
}