import React from 'react';
import {
  FileText,
  Save,
  MoreVertical,
} from 'lucide-react';
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface EmailTemplate {
  id: string;
  name: string;
  subject: string;
  content: string;
}

interface EmailTemplatesProps {
  onSelectTemplate: (template: EmailTemplate) => void;
  onSaveAsTemplate: (name: string, subject: string, content: string) => void;
  disabled?: boolean;
}

export function EmailTemplates({
  onSelectTemplate,
  onSaveAsTemplate,
  disabled = false,
}: EmailTemplatesProps) {
  const [saveDialogOpen, setSaveDialogOpen] = React.useState(false);
  const [newTemplateName, setNewTemplateName] = React.useState('');

  // Mock templates - replace with actual templates from your backend
  const templates: EmailTemplate[] = [
    {
      id: '1',
      name: 'Welcome Email',
      subject: 'Welcome to Our Service',
      content: 'Dear customer,\n\nWelcome to...',
    },
    // Add more templates
  ];

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button 
            variant="outline" 
            size="sm" 
            className="gap-2"
            disabled={disabled}
          >
            <FileText className="h-4 w-4" />
            Templates
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[200px]">
          {templates.map((template) => (
            <DropdownMenuItem
              key={template.id}
              onClick={() => onSelectTemplate(template)}
            >
              {template.name}
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem
            onClick={() => setSaveDialogOpen(true)}
            className="gap-2"
          >
            <Save className="h-4 w-4" />
            Save as Template
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={saveDialogOpen} onOpenChange={setSaveDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Save as Template</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <Label htmlFor="name">Template Name</Label>
              <Input
                id="name"
                value={newTemplateName}
                onChange={(e) => setNewTemplateName(e.target.value)}
                placeholder="Enter template name"
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setSaveDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // Implement save template logic
                setSaveDialogOpen(false);
              }}
            >
              Save Template
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}