import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Badge } from "@/components/ui/badge"
import { X, Users, Plus, Mail } from "lucide-react"
import { EmailRecipientSelector } from './EmailRecipientSelector'
import { ScrollArea } from "@/components/ui/scroll-area"
import axios from '@/api/axiosConfig'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider
} from "@/components/ui/tooltip"
import { cn } from '@/lib/utils' // Utility for conditional classNames

interface Deal {
  contact: Contact | null
}

interface Contact {
  id: number
  name: string
  email: string
  accounts?: Array<{
    company_name: string
  }>
}

interface Recipient {
  email: string
  name: string
  isCustom?: boolean
  deals?: Deal[]
}

interface Recipients {
  to: Recipient[];
  cc: Recipient[];
  bcc: Recipient[];
}

interface EmailRecipientsProps {
  recipients: Recipients;
  onAddRecipient: (recipient: { 
    email: string; 
    name: string; 
    type: 'to' | 'cc' | 'bcc';
    isCustom?: boolean;
    deals?: Deal[];
  }) => void;
  onRemoveRecipient: (email: string, type: 'to' | 'cc' | 'bcc') => void;
}

const RecipientField = React.memo(({
  type,
  label,
  recipients,
  onAddRecipient,
  onRemoveRecipient,
  contacts,
  isLoading
}: {
  type: 'to' | 'cc' | 'bcc';
  label: string;
  recipients: Recipient[];
  onAddRecipient: (recipient: { email: string; name: string; type: 'to' | 'cc' | 'bcc'; isCustom?: boolean; deals?: Deal[] }) => void;
  onRemoveRecipient: (email: string, type: 'to' | 'cc' | 'bcc') => void;
  contacts: Contact[];
  isLoading: boolean;
}) => {
  const [inputValue, setInputValue] = useState('')
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const suggestionsRef = useRef<HTMLDivElement>(null)

  // Handle clicking outside to close suggestions
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false)
      }
    }

    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowSuggestions(false)
        inputRef.current?.blur()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleEsc)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEsc)
    }
  }, [])

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setInputValue(value)

    if (value.length > 0) {
      const filtered = contacts.filter(contact => 
        contact.email.toLowerCase().includes(value.toLowerCase()) ||
        contact.name.toLowerCase().includes(value.toLowerCase()) ||
        contact.accounts?.some(account => 
          account.company_name.toLowerCase().includes(value.toLowerCase())
        )
      )
      setFilteredContacts(filtered)
      setShowSuggestions(true)
    } else {
      setShowSuggestions(false)
    }
  }, [contacts])

  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault()
    const email = inputValue.trim()
    if (email && !recipients.some(r => r.email === email)) {
      onAddRecipient({
        email,
        name: email,
        type,
        isCustom: true
      })
      setInputValue('')
      setShowSuggestions(false)
    }
  }, [inputValue, onAddRecipient, recipients, type])

  const handleSelectContact = useCallback((contact: Contact) => {
    if (!recipients.some(r => r.email === contact.email)) {
      onAddRecipient({
        email: contact.email,
        name: contact.name,
        type,
        isCustom: false
      })
    }
    setInputValue('')
    setShowSuggestions(false)
    inputRef.current?.focus()
  }, [onAddRecipient, recipients, type])

  return (
    <div className="flex gap-2 items-start">
      <label className="min-w-[60px] text-sm font-medium mt-2 text-foreground">{label}</label>
      <div className="flex-1 space-y-2">
        <div className="flex flex-wrap gap-2">
          {recipients.map((recipient) => (
            <TooltipProvider key={recipient.email}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Badge
                    variant="secondary"
                    className="flex items-center gap-1"
                  >
                    {recipient.name}
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-4 w-4 p-0"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onRemoveRecipient(recipient.email, type);
                      }}
                      aria-label={`Remove ${recipient.name}`}
                    >
                      <X className="h-3 w-3" />
                    </Button>
                  </Badge>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="flex items-center gap-2 text-sm">
                    <Mail className="h-3.5 w-3.5 text-muted-foreground" />
                    <span className="text-foreground">{recipient.email}</span>
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>

        <div className="flex gap-2">
          <div className="flex-1 relative" ref={suggestionsRef}>
            <form onSubmit={handleSubmit}>
              <Input
                ref={inputRef}
                type="email"
                placeholder={isLoading ? "Loading contacts..." : `Add ${type.toUpperCase()} recipient...`}
                value={inputValue}
                onChange={handleInputChange}
                onFocus={() => {
                  if (inputValue) {
                    setShowSuggestions(true)
                  }
                }}
                disabled={isLoading}
                className={cn({
                  "cursor-not-allowed": isLoading,
                })}
              />
            </form>

            {showSuggestions && filteredContacts.length > 0 && (
              <div className="absolute top-full left-0 w-full z-10 bg-popover border border-border rounded-md shadow-md mt-1 max-h-48 overflow-auto">
                <ScrollArea>
                  <div className="p-1">
                    {filteredContacts.map((contact) => (
                      <div
                        key={contact.id}
                        className="px-2 py-1.5 hover:bg-accent cursor-pointer rounded-sm"
                        onMouseDown={(e) => {
                          e.preventDefault() // Prevents the onBlur from firing
                          handleSelectContact(contact)
                        }}
                      >
                        <div className="font-medium text-foreground">{contact.name}</div>
                        <div className="text-sm text-muted-foreground">
                          {contact.email}
                          {contact.accounts?.[0] && (
                            <span className="ml-2">({contact.accounts[0].company_name})</span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </ScrollArea>
              </div>
            )}
          </div>

          <Button
            type="button"
            variant="outline"
            size="icon"
            onClick={() => {
              // Implement logic to open EmailRecipientSelector if needed
              // For example, setting a state in the parent component
            }}
            title="Select from contacts"
            className="border-border text-foreground hover:bg-muted"
          >
            <Users className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  )
})

export function EmailRecipients({ recipients, onAddRecipient, onRemoveRecipient }: EmailRecipientsProps) {
  const [showCC, setShowCC] = useState(false)
  const [showBCC, setShowBCC] = useState(false)
  const [selectorOpen, setSelectorOpen] = useState(false)
  const [activeField, setActiveField] = useState<'to' | 'cc' | 'bcc'>('to')
  const [contacts, setContacts] = useState<Contact[]>([])
  const [isLoading, setIsLoading] = useState(false)
  
  // Refs for EmailRecipientSelector
  const selectorRef = useRef<HTMLDivElement>(null)

  // Fetch contacts when component mounts
  useEffect(() => {
    const fetchContacts = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get<Deal[]>('/account/deals')
        const deals = response.data
        const uniqueContacts = Array.from(
          new Map(
            deals
              .filter((deal): deal is Deal & { contact: Contact } => 
                deal.contact !== null
              )
              .map(deal => [deal.contact.email, deal.contact])
          ).values()
        )
        setContacts(uniqueContacts)
      } catch (error) {
        console.error('Error fetching contacts:', error)
        // Optionally, display an error toast using Sonner
      }
      setIsLoading(false)
    }
    fetchContacts()
  }, [])

  return (
    <div className="space-y-4">
      <RecipientField
        type="to"
        label="To:"
        recipients={recipients.to}
        onAddRecipient={onAddRecipient}
        onRemoveRecipient={onRemoveRecipient}
        contacts={contacts}
        isLoading={isLoading}
      />
      {showCC && (
        <RecipientField
          type="cc"
          label="CC:"
          recipients={recipients.cc}
          onAddRecipient={onAddRecipient}
          onRemoveRecipient={onRemoveRecipient}
          contacts={contacts}
          isLoading={isLoading}
        />
      )}
      {showBCC && (
        <RecipientField
          type="bcc"
          label="BCC:"
          recipients={recipients.bcc}
          onAddRecipient={onAddRecipient}
          onRemoveRecipient={onRemoveRecipient}
          contacts={contacts}
          isLoading={isLoading}
        />
      )}

      <div className="flex gap-2">
        {!showCC && (
          <Button
            type="button"
            variant="ghost"
            size="sm"
            className="text-muted-foreground hover:bg-muted"
            onClick={() => setShowCC(true)}
          >
            <Plus className="h-4 w-4 mr-1" />
            Add CC
          </Button>
        )}
        {!showBCC && (
          <Button
            type="button"
            variant="ghost"
            size="sm"
            className="text-muted-foreground hover:bg-muted"
            onClick={() => setShowBCC(true)}
          >
            <Plus className="h-4 w-4 mr-1" />
            Add BCC
          </Button>
        )}
      </div>

      <EmailRecipientSelector
        open={selectorOpen}
        onOpenChange={setSelectorOpen}
        onAddRecipients={(newRecipients) => {
          newRecipients.forEach(recipient => {
            if (!recipients[activeField].some(r => r.email === recipient.email)) {
              onAddRecipient({
                ...recipient,
                type: activeField,
                isCustom: false
              })
            }
          })
        }}
        // Pass additional props if needed
      />
    </div>
  )
}