import React, { createContext, useContext, useEffect, useState, useRef, ReactNode } from 'react';
import { isAxiosError } from 'axios';
import axios from '../api/axiosConfig';

interface PresenceContextType {
  isAuthenticated: boolean;
}

const PresenceContext = createContext<PresenceContextType>({
  isAuthenticated: false
});

interface PresenceProviderProps {
  children: ReactNode;
}

export function PresenceProvider({ children }: PresenceProviderProps): JSX.Element {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const heartbeatIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const manualLogout = sessionStorage.getItem('manualLogout');
    setIsAuthenticated(!!token && !manualLogout);

    const handleStorageChange = (): void => {
      const newToken = localStorage.getItem('token');
      const manualLogout = sessionStorage.getItem('manualLogout');
      setIsAuthenticated(!!newToken && !manualLogout);
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  useEffect(() => {
    if (!isAuthenticated && heartbeatIntervalRef.current) {
      clearTimeout(heartbeatIntervalRef.current);
      heartbeatIntervalRef.current = null;
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let heartbeatInterval: NodeJS.Timeout;

    const sendHeartbeat = async (): Promise<void> => {
      try {
        await axios.post('/auth/heartbeat');
        scheduleNextHeartbeat();
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 401) {
          // If unauthorized, stop sending heartbeats
          clearTimeout(heartbeatInterval);
        } else {
          console.error('Heartbeat failed:', error);
        }
      }
    };

    const getRandomInterval = (): number => {
      // Random interval between 2-3 minutes (120000-180000 ms)
      return Math.floor(Math.random() * (180000 - 120000) + 120000);
    };

    const scheduleNextHeartbeat = (): void => {
      if (heartbeatInterval) {
        clearTimeout(heartbeatInterval);
      }
      heartbeatInterval = setTimeout(sendHeartbeat, getRandomInterval());
    };

    const handleVisibilityChange = (): void => {
      if (document.hidden) {
        // Clear timeout when tab is hidden
        if (heartbeatInterval) {
          clearTimeout(heartbeatInterval);
        }
      } else if (isAuthenticated) {
        // Only send heartbeat if authenticated
        sendHeartbeat();
      }
    };

    const handleBeforeUnload = async (): Promise<void> => {
      if (isAuthenticated) {
        try {
          const token = localStorage.getItem('token');
          // Only send logout beacon if it wasn't a manual logout
          if (!sessionStorage.getItem('manualLogout')) {
            navigator.sendBeacon(
              `${process.env.REACT_APP_API_URL}/auth/logout`,
              JSON.stringify({ token })
            );
          }
        } catch (error) {
          console.error('Error during logout:', error);
        }
      }
    };

    // Only set up heartbeat if authenticated
    if (isAuthenticated) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
      window.addEventListener('beforeunload', handleBeforeUnload);
      sendHeartbeat(); // Start initial heartbeat
    }

    return () => {
      if (heartbeatInterval) clearTimeout(heartbeatInterval);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isAuthenticated]);

  return (
    <PresenceContext.Provider value={{ isAuthenticated }}>
      {children}
    </PresenceContext.Provider>
  );
}

export const usePresence = (): PresenceContextType => {
  const context = useContext(PresenceContext);
  if (context === undefined) {
    throw new Error('usePresence must be used within a PresenceProvider');
  }
  return context;
};