import { useCallback, useEffect, useState } from "react"

interface ObserverRect {
  width: number
  height: number
}

export function useResizeObserver<T extends HTMLElement = HTMLElement>() {
  const [ref, setRef] = useState<T | null>(null)
  const [rect, setRect] = useState<ObserverRect>({ width: 0, height: 0 })

  const observer = useCallback(() => {
    if (ref) {
      const { width, height } = ref.getBoundingClientRect()
      setRect({ width, height })
    }
  }, [ref])

  useEffect(() => {
    if (!ref) return

    observer()
    const resizeObserver = new ResizeObserver(observer)
    resizeObserver.observe(ref)

    return () => {
      resizeObserver.disconnect()
    }
  }, [ref, observer])

  return [setRef, rect] as const
}