import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ButtonConfig as ButtonConfigType, ApiConfig as ApiConfigType } from '@/onboardingflow/types/node_types';
import { ApiConfig } from '@/onboardingflow/components/node-config/ApiConfig/ApiConfig';
import { Checkbox } from "@/components/ui/checkbox";
import { OnboardingEndpoint } from "@/onboardingflow/types/endpoints";

interface ButtonConfigProps {
  config: ButtonConfigType;
  elementId: string;
  localConfig?: any;
  onChange: (updates: Partial<ButtonConfigType>) => void;
  availableEndpoints: OnboardingEndpoint[];
}

export const ButtonConfig = ({
  config,
  elementId,
  localConfig,
  onChange,
  availableEndpoints
}: ButtonConfigProps) => {
  const defaultApiConfig: ApiConfigType = {
    endpoint: '',
    method: 'POST',
    payload: {
      type: 'static',
      value: [] as string[],
      staticData: {}
    },
    successAction: {
      type: 'next'
    },
    errorAction: {
      type: 'message',
      message: 'An error occurred'
    }
  };

  return (
    <div className="space-y-4">
      <div>
        <Label>Label</Label>
        <Input
          value={config.label}
          onChange={(e) => onChange({ label: e.target.value })}
          placeholder="Enter button text"
        />
      </div>
      <div>
        <Label>Variant</Label>
        <Select
          value={config.variant}
          onValueChange={(value) => onChange({ variant: value as 'default' | 'outline' | 'secondary' | 'big' })}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select variant" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="default">Default</SelectItem>
            <SelectItem value="outline">Outline</SelectItem>
            <SelectItem value="secondary">Secondary</SelectItem>
            <SelectItem value="big">Big</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label>Action</Label>
        <Select
          value={config.action}
          onValueChange={(value) => {
            const updates: Partial<ButtonConfigType> = { action: value as 'next' | 'submit' | 'api' | 'option' };
            if (value === 'api' && !config.apiConfig) {
              updates.apiConfig = defaultApiConfig;
            }
            onChange(updates);
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select action" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="next">Next</SelectItem>
            <SelectItem value="submit">Submit</SelectItem>
            <SelectItem value="api">API</SelectItem>
            <SelectItem value="option">Option</SelectItem>
            <SelectItem value="complete">Complete Onboarding</SelectItem>
          </SelectContent>
        </Select>
      </div>
      {config.action === 'option' && (
        <div>
          <Label>Value</Label>
          <Input
            value={config.value || ''}
            onChange={(e) => onChange({ value: e.target.value })}
            placeholder="Enter option value"
          />
        </div>
      )}
      <div className="flex items-center gap-2">
        <Checkbox
          checked={config.isDefaultTrigger || false}
          onCheckedChange={(checked) => onChange({ isDefaultTrigger: !!checked })}
          id="isDefaultTrigger"
        />
        <Label htmlFor="isDefaultTrigger">Default Trigger</Label>
      </div>
      {config.action === 'api' && (
        <ApiConfig
          config={config.apiConfig || defaultApiConfig}
          elementId={elementId}
          onChange={(updates: Partial<ApiConfigType>) => {
            onChange({ 
              apiConfig: { 
                ...(config.apiConfig || defaultApiConfig), 
                ...updates
              } 
            });
          }}
          availableEndpoints={availableEndpoints}
        />
      )}
    </div>
  );
};
