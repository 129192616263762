import { GlobeInstance } from '@/types/globe';

interface InternalGlobe extends GlobeInstance {
  _init?: (state: any) => any;
}

export const initGlobe = async (): Promise<GlobeInstance> => {
  const { default: GlobeImport } = await import('globe.gl');
  
  // Type assertion to treat GlobeImport as a function returning GlobeInstance
  const Globe = GlobeImport as unknown as () => GlobeInstance;
  
  const globe = Globe() as InternalGlobe;

  // Patch the material creation to override onBeforeRender
  const originalGlobeMaterial = globe.globeMaterial;
  globe.globeMaterial = function() {
    const material = originalGlobeMaterial.call(this);
    if (material) {
      // Override the onBeforeRender property with an empty function
      material.onBeforeRender = () => {};
      material.needsUpdate = true;
    }
    return material;
  };

  return globe as GlobeInstance;
};

/**
 * Recursively traverses the scene and overrides onBeforeRender for all materials.
 * @param globe - The globe instance.
 */
export const overrideOnBeforeRender = (globe: GlobeInstance) => {
  const traverseAndOverride = (object: any) => {
    if (object.material) {
      if (Array.isArray(object.material)) {
        object.material.forEach((m: any) => {
          m.onBeforeRender = () => {};
        });
      } else {
        object.material.onBeforeRender = () => {};
      }
    }

    // If the object has children, traverse them as well
    if (object.children && object.children.length > 0) {
      object.children.forEach((child: any) => traverseAndOverride(child));
    }
  };

  globe.scene().traverse(traverseAndOverride);
};