import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// Add new date formatting functions
export function formatDate(isoString: string, options: Intl.DateTimeFormatOptions = {}) {
  const defaultOptions: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }

  return new Intl.DateTimeFormat(
    'da-DK', 
    { ...defaultOptions, ...options }
  ).format(new Date(isoString))
}

export function formatDateTime(isoString: string) {
  return formatDate(isoString, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}

export function formatShortDate(isoString: string) {
  return formatDate(isoString, {
    day: 'numeric',
    month: 'short'
  })
}