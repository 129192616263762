import { TaskConfigType } from '@/onboardingflow/types/node_types';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';

interface TaskConfigProps {
  config: TaskConfigType;
  elementId: string;
  onChange: (updates: Partial<TaskConfigType>) => void;
}

export function TaskConfig({
  config,
  elementId,
  onChange
}: TaskConfigProps) {
  return (
    <div className="space-y-4">
      <div>
        <Label>Task Display Configuration</Label>
        <p className="text-sm text-muted-foreground">
          This element will automatically display tasks after they are configured.
          No additional configuration is needed.
        </p>
      </div>
    </div>
  );
} 