import { useState, useEffect } from 'react';
import { FlowEditor } from '../FlowEditor';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import { toast } from "sonner";
import axios from '@/api/axiosConfig';
import { FlowNode, FlowEdge } from '@/onboardingflow/types/flows';
import { Loading } from '@/components/ui/loading';

export default function OnboardingFlowEditor() {
  const [isLoading, setIsLoading] = useState(true);
  const [flowData, setFlowData] = useState<{ nodes: FlowNode[]; edges: FlowEdge[] } | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    fetchFlow();
  }, []);

  const fetchFlow = async () => {
    try {
      const response = await axios.get('/onboardingflow/flow');
      setFlowData(response.data);
    } catch (error: any) {
      if (error.response?.status !== 404) {
        toast.error('Failed to load flow configuration');
        console.error('Error loading flow:', error);
      }
      setFlowData({ nodes: [], edges: [] });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async (nodes: FlowNode[], edges: FlowEdge[]) => {
    setIsSaving(true);
    try {
      const response = await axios.post('/onboardingflow/flow', { 
        nodes: nodes.map(node => ({
          ...node,
          type: node.type
        })), 
        edges 
      });
      setFlowData(response.data);
      toast.success('Flow configuration saved successfully');
    } catch (error) {
      toast.error('Failed to save flow configuration');
      console.error('Error saving flow:', error);
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loading message="Loading flow configuration..." />
      </div>
    );
  }

  return (
    <div className="container mx-auto py-6 space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Onboarding Flow Editor</CardTitle>
          <CardDescription>
            Design and configure your customer onboarding process
          </CardDescription>
        </CardHeader>
        <CardContent>
          <FlowEditor
            initialNodes={flowData?.nodes || []}
            initialEdges={flowData?.edges || []}
            onSave={handleSave}
            readOnly={isSaving}
          />
        </CardContent>
      </Card>
    </div>
  );
}