import { useState, useEffect, useMemo } from 'react'
import { FileText, Trash2, BookOpen, X, CheckCircle, XCircle, HelpCircle, Trash } from 'lucide-react'
import { format } from 'date-fns'
import { toast } from 'sonner'
import axios from '../api/axiosConfig'

import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogBody, DialogDescription } from '@/components/ui/dialog'
import { Table, type ColumnDef } from '@/components/ui/specialized/mod-table'
import { Loading } from "@/components/ui/loading"
import { Badge } from "@/components/ui/badge"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { Command, CommandInput } from '@/components/ui/command'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog"

interface Invoice {
  id: string
  deal_id: string
  deal_name: string
  invoice_number: string
  status: string
  secondary_status?: 'approved' | 'not_approved' | 'unsure'
  total_amount: number
  created_at: string
  economic_invoice_id: string
}

interface EconomicInvoice {
  draftInvoiceNumber: string
  customer: {
    name: string
  }
  invoiceDate: string
  dueDate: string
  grossAmount: number
  lines: InvoiceLine[]
}

interface InvoiceLine {
  description: string
  quantity: number
  unitNetPrice: number
  totalNetAmount: number
}

export default function BillingSettings() {
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null)
  const [economicInvoice, setEconomicInvoice] = useState<EconomicInvoice | null>(null)
  const [previousInvoice, setPreviousInvoice] = useState<EconomicInvoice | null>(null)
  const [previousInvoiceNumber, setPreviousInvoiceNumber] = useState<string | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(15)
  const [totalInvoices, setTotalInvoices] = useState<number>(0)
  const [searchQuery, setSearchQuery] = useState("")

  useEffect(() => {
    fetchInvoices()
  }, [])

  const fetchInvoices = async () => {
    setLoading(true)
    try {
      const response = await axios.get('/invoice/all')
      setInvoices(response.data)
      setTotalInvoices(response.data.length)
    } catch (error) {
      toast.error('Failed to fetch invoices')
    }
    setLoading(false)
  }

  const filteredInvoices = useMemo(() => {
    if (!searchQuery) return invoices
    
    const query = searchQuery.toLowerCase()
    return invoices.filter(invoice => 
      invoice.deal_name.toLowerCase().includes(query)
    )
  }, [invoices, searchQuery])

  const handleReviewInvoice = async (invoice: Invoice) => {
    setSelectedInvoice(invoice)
    try {
      const invoiceResponse = await axios.get(`/invoice/economic/${invoice.economic_invoice_id}`)
      setEconomicInvoice(invoiceResponse.data)
      
      try {
        const previousResponse = await axios.get(`/invoice/previous-invoice/${invoice.deal_id}/${invoice.economic_invoice_id}`)
        if (previousResponse.data.invoice) {
          setPreviousInvoice(previousResponse.data.invoice)
          setPreviousInvoiceNumber(previousResponse.data.invoice_number)
        }
      } catch (error: any) {
        if (error?.response?.status !== 404) {
          console.error('Error fetching previous invoice:', error)
        }
        setPreviousInvoice(null)
        setPreviousInvoiceNumber(null)
      }
    } catch (error) {
      console.error('Error fetching current invoice:', error)
      toast.error('Failed to fetch invoice details')
    }
  }

  const handleBookInvoice = async () => {
    if (!selectedInvoice) return
    try {
      await axios.post(`/invoice/book/${selectedInvoice.id}`)
      toast.success('Invoice booked successfully')
      setSelectedInvoice(null)
      fetchInvoices()
    } catch (error) {
      toast.error('Failed to book invoice')
    }
  }

  const handleDeleteInvoice = async () => {
    if (!selectedInvoice) return
    try {
      await axios.delete(`/invoice/${selectedInvoice.id}`)
      toast.success('Invoice deleted successfully')
      setInvoices(prevInvoices => 
        prevInvoices.filter(invoice => invoice.id !== selectedInvoice.id)
      )
      setSelectedInvoice(null)
    } catch (error) {
      toast.error('Failed to delete invoice')
    }
  }

  const handleViewPDF = async () => {
    if (!selectedInvoice) return
    try {
      const response = await axios.get(
        `/invoice/economic/${selectedInvoice.economic_invoice_id}/pdf`,
        { responseType: 'blob' }
      )
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(pdfBlob)
      window.open(pdfUrl, '_blank')
    } catch (error) {
      console.error('Error fetching PDF:', error)
      toast.error('Failed to fetch invoice PDF')
    }
  }

  const handleViewPreviousPDF = async () => {
    if (!previousInvoiceNumber) return
    try {
      const response = await axios.get(
        `/invoice/booked-invoices/${previousInvoiceNumber}/pdf`,
        { responseType: 'blob' }
      )
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(pdfBlob)
      window.open(pdfUrl, '_blank')
    } catch (error) {
      console.error('Error fetching previous PDF:', error)
      toast.error('Failed to fetch previous invoice PDF')
    }
  }

  const formatNumber = (number: number) => {
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  const renderSecondaryStatus = (status: string | undefined) => {
    switch (status) {
      case 'approved':
        return (
          <Badge variant="outline" className="bg-green-100 text-green-800">
            <CheckCircle className="h-3 w-3 mr-1" />
            Approved
          </Badge>
        )
      case 'not_approved':
        return (
          <Badge variant="outline" className="bg-red-100 text-red-800">
            <XCircle className="h-3 w-3 mr-1" />
            Not Approved
          </Badge>
        )
      case 'unsure':
        return (
          <Badge variant="outline" className="bg-yellow-100 text-yellow-800">
            <HelpCircle className="h-3 w-3 mr-1" />
            Unsure
          </Badge>
        )
      default:
        return (
          <Badge variant="outline" className="bg-muted text-muted-foreground">
            Pending Review
          </Badge>
        )
    }
  }

  const columns: ColumnDef<Invoice>[] = [
    { 
      key: 'deal_name',
      title: 'Customer',
      dataIndex: 'deal_name',
      sortable: {
        enabled: true,
        type: 'string'
      }
    },
    { 
      key: 'invoice_number',
      title: 'Invoice Number',
      dataIndex: 'invoice_number',
      sortable: {
        enabled: true,
        type: 'string'
      }
    },
    { 
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      sortable: {
        enabled: true,
        type: 'string'
      }
    },
    {
      key: 'secondary_status',
      title: 'Review Status',
      dataIndex: 'secondary_status',
      render: renderSecondaryStatus,
      sortable: {
        enabled: true,
        type: 'string'
      },
      filterable: true,
      multiSelect: true,
      filters: [
        { text: 'Pending Review', value: 'pending' },
        { text: 'Approved', value: 'approved' },
        { text: 'Not Approved', value: 'not_approved' },
        { text: 'Unsure', value: 'unsure' }
      ],
      onFilter: (values: string[], record: Invoice) => {
        return values.some(value => {
          if (value === 'pending') {
            return record.secondary_status === undefined || record.secondary_status === null;
          }
          return record.secondary_status === value;
        });
      }
    },
    { 
      key: 'total_amount',
      title: 'Total Amount, kr.',
      dataIndex: 'total_amount',
      render: (value) => `${formatNumber(value)} u/moms`
    },
    { 
      key: 'created_at',
      title: 'Created At',
      dataIndex: 'created_at',
      render: (value) => format(new Date(value), 'yyyy-MM-dd'),
      sortable: {
        enabled: true,
        type: 'date'
      }
    }
  ]

  const lineColumns: ColumnDef<InvoiceLine>[] = [
    { key: 'description', title: 'Description', dataIndex: 'description' },
    { key: 'quantity', title: 'Quantity', dataIndex: 'quantity' },
    { 
      key: 'unitNetPrice',
      title: 'Unit Price (u/moms)',
      dataIndex: 'unitNetPrice',
      render: (value) => `${formatNumber(value)} kr.`
    },
    { 
      key: 'totalNetAmount',
      title: 'Total (u/moms)',
      dataIndex: 'totalNetAmount',
      render: (value) => `${formatNumber(value)} kr.`
    }
  ]

  const formatDate = (dateString: string | null | undefined) => {
    if (!dateString) return 'N/A';
    try {
      return format(new Date(dateString), 'yyyy-MM-dd');
    } catch (error) {
      console.error('Error formatting date:', dateString);
      return 'Invalid Date';
    }
  };

  const resetInvoiceStates = () => {
    setSelectedInvoice(null)
    setEconomicInvoice(null)
    setPreviousInvoice(null)
    setPreviousInvoiceNumber(null)
  }

  const handleStatusUpdate = async (status: 'approved' | 'not_approved' | 'unsure') => {
    if (!selectedInvoice) return
    try {
      await axios.put(
        `/invoice/secondary-status/${selectedInvoice.id}`,
        { status }
      )
      
      setInvoices(prevInvoices => 
        prevInvoices.map(invoice => 
          invoice.id === selectedInvoice.id 
            ? { ...invoice, secondary_status: status }
            : invoice
        )
      )
      
      setSelectedInvoice(prev => 
        prev ? { ...prev, secondary_status: status } : null
      )
      
      toast.success('Invoice status updated')
    } catch (error: any) {
      console.error('Error updating status:', error)
      toast.error(error.response?.data?.error || 'Failed to update invoice status')
    }
  }

  const getStatusCounts = () => {
    const counts = {
      pending: 0,
      approved: 0,
      not_approved: 0,
      unsure: 0
    }

    invoices.forEach(invoice => {
      if (!invoice.secondary_status) {
        counts.pending++
      } else {
        counts[invoice.secondary_status]++
      }
    })

    return counts
  }

  const handleDeleteAllDrafts = async () => {
    try {
      await axios.delete('/invoice/drafts')
      toast.success('All draft invoices deleted successfully')
      fetchInvoices()
    } catch (error: any) {
      console.error('Error deleting draft invoices:', error)
      toast.error(error.response?.data?.error || 'Failed to delete draft invoices')
    }
  }

  const getDraftCount = () => {
    return invoices.filter(invoice => invoice.status === 'draft').length
  }

  return (
    <div className="max-w-[1200px] mx-auto mt-4 p-6 space-y-6">
      <div className="flex justify-between items-center">
        <div className="space-y-1">
          <h1 className="text-2xl font-bold text-foreground">Billing Overview</h1>
          {invoices.length > 0 && (
            <p className="text-sm text-muted-foreground">
              Showing {totalInvoices} invoice{totalInvoices !== 1 ? 's' : ''} (
              {getStatusCounts().pending} pending, {getStatusCounts().approved} approved, 
              {' '}{getStatusCounts().unsure} unsure, {getStatusCounts().not_approved} not approved)
            </p>
          )}
        </div>
        <div className="flex items-center gap-4">
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                variant="destructive"
                className="gap-2"
                disabled={getDraftCount() === 0}
              >
                <Trash className="h-4 w-4" />
                Delete All Drafts ({getDraftCount()})
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action will permanently delete all {getDraftCount()} draft invoices from both E-conomic and the local database. This action cannot be undone.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction
                  onClick={handleDeleteAllDrafts}
                  className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
                >
                  Delete All Drafts
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <div className="w-[200px]">
            <Command className="rounded-md border border-input bg-background">
              <CommandInput
                placeholder="Search customers..."
                value={searchQuery}
                onValueChange={setSearchQuery}
              />
            </Command>
          </div>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <Table
          data={filteredInvoices}
          columns={columns}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          onPageSizeChange={setPageSize}
          onRowClick={handleReviewInvoice}
        />
      )}

      <Dialog 
        open={!!selectedInvoice} 
        onOpenChange={(open) => {
          if (!open) resetInvoiceStates()
        }}
      >
        <DialogContent className="max-w-4xl">
          <DialogHeader>
            <DialogTitle>Review Invoice</DialogTitle>
            <DialogDescription className="sr-only">
              Review and manage invoice details
            </DialogDescription>
          </DialogHeader>

          <DialogBody>
            {economicInvoice && (
              <div className="space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm text-muted-foreground">Invoice Number</p>
                    <p className="font-medium text-foreground">{economicInvoice.draftInvoiceNumber}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">Customer</p>
                    <p className="font-medium text-foreground">{economicInvoice.customer.name}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">Invoice Date</p>
                    <p className="font-medium text-foreground">
                      {formatDate(economicInvoice.invoiceDate)}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">Due Date</p>
                    <p className="font-medium text-foreground">
                      {formatDate(economicInvoice.dueDate)}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">Total Amount (excl. VAT)</p>
                    <div className="flex items-center gap-2">
                      <p className="font-medium text-foreground">
                        {formatNumber(economicInvoice.grossAmount / 1.25)} kr.
                      </p>
                      {previousInvoice ? (
                        previousInvoice.grossAmount / 1.25 !== economicInvoice.grossAmount / 1.25 && (
                          <Badge variant="outline" className="bg-yellow-100 text-yellow-800">
                            Changed from {formatNumber(previousInvoice.grossAmount / 1.25)} kr.
                          </Badge>
                        )
                      ) : (
                        <Badge variant="outline" className="bg-blue-100 text-blue-800">
                          First Invoice
                        </Badge>
                      )}
                    </div>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">Total Amount (incl. VAT)</p>
                    <div className="flex items-center gap-2">
                      <p className="font-medium text-foreground">
                        {formatNumber(economicInvoice.grossAmount)} kr.
                      </p>
                      {previousInvoice ? (
                        previousInvoice.grossAmount !== economicInvoice.grossAmount && (
                          <Badge variant="outline" className="bg-yellow-100 text-yellow-800">
                            Changed from {formatNumber(previousInvoice.grossAmount)} kr.
                          </Badge>
                        )
                      ) : (
                        <Badge variant="outline" className="bg-blue-100 text-blue-800">
                          First Invoice
                        </Badge>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <h3 className="text-lg font-semibold mb-4 text-foreground">Line Items</h3>
                  <div className="relative">
                    {loading && (
                      <div className="absolute inset-0 bg-background/50 backdrop-blur-sm z-10 flex items-center justify-center">
                        <Loading size="sm" message="Loading details..." />
                      </div>
                    )}
                    <Table
                      columns={lineColumns}
                      data={economicInvoice.lines}
                      pageSize={5}
                    />
                  </div>
                </div>
              </div>
            )}
          </DialogBody>

          <DialogFooter className="flex flex-col gap-4 sm:flex-col">
            <div className="flex justify-start gap-2 w-full">
              <TooltipProvider delayDuration={0}>
                <div className="flex gap-2">
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => handleStatusUpdate('approved')}
                        className={selectedInvoice?.secondary_status === 'approved' ? 'bg-green-100' : ''}
                      >
                        <CheckCircle className="h-4 w-4 text-green-600" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Approve Invoice</p>
                    </TooltipContent>
                  </Tooltip>

                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => handleStatusUpdate('not_approved')}
                        className={selectedInvoice?.secondary_status === 'not_approved' ? 'bg-red-100' : ''}
                      >
                        <XCircle className="h-4 w-4 text-red-600" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Don't Approve Invoice</p>
                    </TooltipContent>
                  </Tooltip>

                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => handleStatusUpdate('unsure')}
                        className={selectedInvoice?.secondary_status === 'unsure' ? 'bg-yellow-100' : ''}
                      >
                        <HelpCircle className="h-4 w-4 text-yellow-600" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Mark as Unsure</p>
                    </TooltipContent>
                  </Tooltip>
                </div>
              </TooltipProvider>
            </div>
            
            <div className="flex justify-between w-full">
              <div className="flex gap-2">
                <Button
                  variant="outline"
                  onClick={handleViewPDF}
                  className="gap-2"
                >
                  <FileText className="h-4 w-4" />
                  View PDF
                </Button>
                {previousInvoiceNumber && (
                  <Button
                    variant="outline"
                    onClick={handleViewPreviousPDF}
                    className="gap-2"
                  >
                    <FileText className="h-4 w-4" />
                    View Previous PDF
                  </Button>
                )}
                <Button
                  variant="destructive"
                  onClick={handleDeleteInvoice}
                  className="gap-2"
                >
                  <Trash2 className="h-4 w-4" />
                  Delete Invoice
                </Button>
                <Button
                  onClick={handleBookInvoice}
                  className="gap-2"
                >
                  <BookOpen className="h-4 w-4" />
                  Book Invoice
                </Button>
              </div>
              <Button
                variant="outline"
                onClick={() => setSelectedInvoice(null)}
                className="gap-2"
              >
                <X className="h-4 w-4" />
                Cancel
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}