import React, { useState, useEffect } from 'react';
import { cn } from "@/lib/utils";
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import { format } from "date-fns";
import { 
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
} from "@/components/ui/drawer";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Switch } from "@/components/ui/switch";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Calendar as CalendarIcon, Mail } from 'lucide-react';
import { dealDataConfig } from '@/configs/DealDataConfig';
import { dealSoftwareConfig } from '@/configs/DealSoftwareConfig';
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { 
  Receipt, 
  Wallet, 
  AppWindow, 
  FileText,
  Building2
} from 'lucide-react';

interface DealDrawerProps {
  visible: boolean;
  onClose: () => void;
  dealId: number | null;
}

interface DealData {
  [key: string]: any;
}

interface Software {
  [key: string]: any;
}

interface Deal {
  id: number;
  name: string;
  data?: DealData;
  software?: Software;
  [key: string]: any;
}

interface FieldConfig {
  key: string;
  label: string;
  type: 'text' | 'number' | 'dropdown' | 'boolean' | 'date';
  editable?: boolean;
  options?: Array<{
    value: string;
    label: string;
    color: string;
  }>;
  placeholder?: string;
}

interface DataGroup {
  title: string;
  icon: React.ReactNode;
  fields: Array<any>;
}

const dataGroups: DataGroup[] = [
  {
    title: "Company Info",
    icon: <Building2 className="h-5 w-5 text-primary" />,
    fields: [
      'data.virksomhedsform',
      'description',
      'data.value',
      'data.onboarding_opgaver',
      'data.onboarding_dato',
      'is_holding',
      'data.aktiv_kunde',
      'data.hvidvask_compliant',
      'main_mailbox',
      'corpay_mailbox',
      'aiia_mailbox',
      'payment_details.reg',
      'payment_details.konto',
      'data.sidste_fakturadato',
      //'data.deal_source'
    ]
  },
  {
    title: "Accounting",
    icon: <Receipt className="h-5 w-5 text-primary" />,
    fields: [
      'data.momsfrekvens',
      'data.bogføringsfrekvens',
      'data.regnskabsprogram',
      'data.seneste_momsopgørelse',
      'data.ansvarlig_bogholder',
      'data.ansvarlig_revisor_for_årsregnskab',
      'data.hvem_er_ansvarlig_for_årsregnskab',
      'data.hvornår_slutter_næste_regnskabsår',
      'data.kan_bankkonto_integreres_i_bogføringssystem',
      'data.kundenummer_i_economic'
    ]
  },
  {
    title: "Payroll",
    icon: <Wallet className="h-5 w-5 text-primary" />,
    fields: [
      'data.lønfrekvens',
      'data.lønansvarlig',
      'data.ansvarlig_lønbogholder',
      'data.antal_medarbejdere_til_lønhandtering',
      'software.lønprogram'
    ]
  },
  {
    title: "Software",
    icon: <AppWindow className="h-5 w-5 text-primary" />,
    fields: [
      'software.lagerstyring',
      'software.kreditorstyring',
      'software.betalingsgateway',
      'software.abonnementsstyring',
      'software.debitorstyring_kredit',
      'software.debitorstyring_kontant',
      'software.projekt_og_tidsstyring',
      'software.kreditorstyring_kvitteringer',
      'software.andre_softwares',
      'software.lønprogram'
    ]
  }
];

const DealDrawer: React.FC<DealDrawerProps> = ({ visible, onClose, dealId }) => {
  const [deal, setDeal] = useState<Deal | null>(null);
  const [loading, setLoading] = useState(true);
  const [editingFields, setEditingFields] = useState<Record<string, boolean>>({});
  const [changes, setChanges] = useState<Record<string, any>>({});
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("data");

  useEffect(() => {
    if (dealId && visible) {
      fetchDealData();
    }
  }, [dealId, visible]);

  const fetchDealData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/account/deals/${dealId}`);
      setDeal(response.data);
    } catch (error) {
      toast.error('Error fetching deal data');
    } finally {
      setLoading(false);
    }
  };

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : undefined;
    }, obj);
  };

  const handleEdit = (key: string, value: any) => {
    const isSoftwareField = dealSoftwareConfig.some(config => config.key === key);
    
    // Always track the change, even if it's an empty string
    // We'll convert to null only when saving
    if (isSoftwareField) {
      setChanges(prev => ({
        ...prev,
        software: {
          ...(prev.software || {}),
          [key]: value
        }
      }));
    } else if (key === 'data.kundenummer_i_economic') {
      // Special handling for economic customer number - update both places
      setChanges(prev => ({
        ...prev,
        data: {
          ...(prev.data || {}),
          kundenummer_i_economic: value
        },
        economic_customer_number: value
      }));
    } else {
      setChanges(prev => ({
        ...prev,
        [key]: value
      }));
    }
  };

  const handleSave = async () => {
    setConfirmVisible(true);
  };

  const confirmSave = async () => {
    try {
      const updatedChanges: Record<string, any> = {};
      
      Object.entries(changes).forEach(([key, value]) => {
        // Convert empty strings to null for mailbox fields before saving
        const processedValue = (key === 'main_mailbox' || key === 'corpay_mailbox' || key === 'aiia_mailbox') && value === '' 
          ? null 
          : value;

        if (key.startsWith('data.')) {
          if (!updatedChanges.data) {
            updatedChanges.data = { ...(deal?.data || {}) };
          }
          const nestedKey = key.split('.')[1];
          updatedChanges.data[nestedKey] = processedValue;
        } else if (key.startsWith('software.')) {
          if (!updatedChanges.software) {
            updatedChanges.software = { ...(deal?.software || {}) };
          }
          const softwareKey = key.split('.')[1];
          updatedChanges.software[softwareKey] = processedValue;
        } else {
          updatedChanges[key] = processedValue;
        }
      });

      await axios.patch(`/account/deals/${dealId}`, updatedChanges);
      
      setDeal(prev => prev ? {
        ...prev,
        ...(updatedChanges.data ? { data: updatedChanges.data } : {}),
        ...(updatedChanges.software ? { software: updatedChanges.software } : {}),
        ...updatedChanges
      } : null);
      
      setEditingFields({});
      setChanges({});
      setConfirmVisible(false);
      toast.success('Changes saved successfully');
    } catch (error) {
      console.error('Error updating deal data:', error);
      toast.error('Failed to save changes');
    }
  };

  const handleCloseDrawer = () => {
    setEditingFields({});
    setChanges({});
    onClose();
  };

  const renderEditableField = (fieldConfig: FieldConfig, value: any) => {
    const isSoftwareField = dealSoftwareConfig.some(config => config.key === fieldConfig.key);
    
    // Get the current value from changes first, then fall back to original value
    let currentValue = isSoftwareField 
      ? changes.software?.[fieldConfig.key] ?? deal?.software?.[fieldConfig.key] ?? value
      : changes[fieldConfig.key] ?? value;

    // Convert null to empty string for mailbox fields
    if ((fieldConfig.key === 'main_mailbox' || fieldConfig.key === 'corpay_mailbox' || fieldConfig.key === 'aiia_mailbox') && currentValue === null) {
      currentValue = '';
    }

    if (!fieldConfig.editable) {
      return value?.toString() || 'N/A';
    }

    // Special handling for payment details fields
    if (fieldConfig.key === 'payment_details.reg' || fieldConfig.key === 'payment_details.konto') {
      const isReg = fieldConfig.key === 'payment_details.reg';
      const inputValue = currentValue ?? '';
      
      return (
        <Input
          type="text"
          value={inputValue}
          placeholder={fieldConfig.placeholder}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, ''); // Only allow numbers
            if (isReg && value.length <= 4) { // Reg nr max 4 digits
              handleEdit(fieldConfig.key, value);
            } else if (!isReg && value.length <= 10) { // Konto nr max 10 digits
              handleEdit(fieldConfig.key, value);
            }
          }}
          className={cn(
            "h-9 px-2",
            isReg ? "w-20" : "flex-1" // Make reg nr input narrow, konto nr takes remaining space
          )}
        />
      );
    }

    switch (fieldConfig.type) {
      case 'text':
      case 'number':
        if (fieldConfig.key === 'data.value' || fieldConfig.key === 'data.seneste_momsopgørelse') {
          return (
            <div className="relative">
              <Input
                type={fieldConfig.type}
                value={currentValue ?? ''}
                placeholder={fieldConfig.placeholder}
                onChange={(e) => handleEdit(fieldConfig.key, e.target.value)}
                className="h-9 px-2 pr-12"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-sm text-muted-foreground">
                DKK
              </div>
            </div>
          );
        }
        if (fieldConfig.key === 'main_mailbox' || fieldConfig.key === 'corpay_mailbox' || fieldConfig.key === 'aiia_mailbox') {
          return (
            <div className="relative">
              <Input
                type={fieldConfig.type}
                value={currentValue ?? ''}
                placeholder={fieldConfig.placeholder}
                onChange={(e) => handleEdit(fieldConfig.key, e.target.value)}
                className="h-9 px-2 pr-10"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <Mail className="h-4 w-4 text-muted-foreground" />
              </div>
            </div>
          );
        }
        return (
          <Input
            type={fieldConfig.type}
            value={currentValue ?? ''}
            placeholder={fieldConfig.placeholder}
            onChange={(e) => handleEdit(fieldConfig.key, e.target.value)}
            className="h-9 px-2"
          />
        );

      case 'dropdown':
        return (
          <Select
            value={currentValue}
            onValueChange={(value) => handleEdit(fieldConfig.key, value)}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select option" />
            </SelectTrigger>
            <SelectContent>
              {(fieldConfig.options || []).map((option) => (
                <SelectItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        );

      case 'boolean':
        return (
          <Switch
            checked={currentValue}
            onCheckedChange={(checked) => handleEdit(fieldConfig.key, checked)}
            className="data-[state=checked]:bg-primary"
          />
        );

      case 'date':
        return (
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn(
                  "w-full justify-start text-left font-normal",
                  !currentValue && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {currentValue ? format(new Date(currentValue), 'yyyy-MM-dd') : <span>Pick a date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={currentValue ? new Date(currentValue) : undefined}
                onSelect={(date) => {
                  // Format the date as YYYY-MM-DD before saving
                  if (date) {
                    const formattedDate = format(date, 'yyyy-MM-dd');
                    handleEdit(fieldConfig.key, formattedDate);
                  } else {
                    handleEdit(fieldConfig.key, null);
                  }
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        );

      default:
        return currentValue?.toString() || 'N/A';
    }
  };

  const renderGroupedData = () => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 px-4">
        {dataGroups.map((group) => (
          <div 
            key={group.title} 
            className="flex flex-col bg-card rounded-lg shadow-sm"
          >
            <div className="flex items-center gap-2 p-3 border-b bg-muted/30 rounded-t-lg">
              {group.icon}
              <h3 className="font-semibold text-card-foreground">
                {group.title}
              </h3>
            </div>
            <ScrollArea className="h-[calc(85vh-180px)]">
              <div className="p-4 space-y-4">
                {group.fields.map((fieldKey) => {
                  const config = fieldKey.startsWith('software.') 
                    ? dealSoftwareConfig.find(c => `software.${c.key}` === fieldKey)
                    : dealDataConfig.find(c => c.key === fieldKey);
                  
                  if (!config) return null;

                  const value = getNestedValue(deal, fieldKey);
                  
                  // Special handling for payment details to show them on the same row
                  if (fieldKey === 'payment_details.reg') {
                    const kontoConfig = dealDataConfig.find(c => c.key === 'payment_details.konto');
                    const kontoValue = getNestedValue(deal, 'payment_details.konto');
                    
                    return (
                      <div 
                        key={fieldKey} 
                        className="space-y-2 p-2 rounded-md hover:bg-muted/40 transition-colors"
                      >
                        <div className="text-sm font-medium text-muted-foreground">
                          Payment Details
                        </div>
                        <div className="flex gap-2">
                          <div className="flex flex-col gap-1">
                            <div className="text-xs text-muted-foreground">
                              {config.label}
                            </div>
                            {renderEditableField(config, value)}
                          </div>
                          <div className="flex flex-col gap-1 flex-1">
                            <div className="text-xs text-muted-foreground">
                              {kontoConfig?.label}
                            </div>
                            {kontoConfig && renderEditableField(kontoConfig, kontoValue)}
                          </div>
                        </div>
                      </div>
                    );
                  }
                  
                  // Skip konto as it's handled with reg
                  if (fieldKey === 'payment_details.konto') {
                    return null;
                  }

                  return (
                    <div 
                      key={fieldKey} 
                      className="space-y-2 p-2 rounded-md hover:bg-muted/40 transition-colors"
                    >
                      <div className="text-sm font-medium text-muted-foreground">
                        {config.label}
                      </div>
                      <div className="min-h-[32px]">
                        {renderEditableField(config, value)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </ScrollArea>
          </div>
        ))}
      </div>
    );
  };

  const renderChanges = () => {
    return Object.entries(changes).map(([key, value]) => {
      // Handle software changes
      if (key === 'software') {
        return Object.entries(value as Record<string, unknown>).map(([softwareKey, softwareValue]) => {
          const softwareConfig = dealSoftwareConfig.find(config => config.key === softwareKey);
          if (!softwareConfig) return null;

          const originalValue = deal?.software?.[softwareKey];
          
          if (softwareConfig.type === 'dropdown' && softwareConfig.options) {
            const getOptionLabel = (val: string) => {
              const option = softwareConfig.options?.find(opt => opt.value === val);
              return option?.label || val;
            };

            const getOptionColor = (val: string) => {
              const option = softwareConfig.options?.find(opt => opt.value === val);
              return option?.color || '#d9d9d9';
            };

            return (
              <div key={softwareKey} className="flex items-center gap-2 py-2">
                <span className="font-medium min-w-[200px]">{softwareConfig.label}:</span>
                <Badge 
                  variant="custom"
                  className="text-white"
                  style={{ backgroundColor: getOptionColor(String(originalValue)) }}
                >
                  {getOptionLabel(String(originalValue)) || 'N/A'}
                </Badge>
                <span className="text-muted-foreground mx-2">→</span>
                <Badge
                  variant="custom"
                  className="text-white"
                  style={{ backgroundColor: getOptionColor(String(softwareValue)) }}
                >
                  {getOptionLabel(String(softwareValue))}
                </Badge>
              </div>
            );
          }
        }).filter(Boolean);
      }

      // Handle other non-software changes...
      const fieldConfig = dealDataConfig.find(config => config.key === key);
      if (!fieldConfig) return null;

      const originalValue = getNestedValue(deal, key);

      if (fieldConfig.type === 'dropdown' && fieldConfig.options) {
        const getOptionLabel = (value: string) => {
          const option = fieldConfig.options?.find(opt => opt.value === value);
          return option?.label || value;
        };

        const getOptionColor = (value: string) => {
          const option = fieldConfig.options?.find(opt => opt.value === value);
          return option?.color || '#d9d9d9';
        };

        return (
          <div key={key} className="flex items-center gap-2 py-2">
            <span className="font-medium min-w-[200px]">{fieldConfig.label}:</span>
            <Badge 
              variant="custom"
              className="text-white"
              style={{ backgroundColor: getOptionColor(String(originalValue)) }}
            >
              {getOptionLabel(String(originalValue)) || 'N/A'}
            </Badge>
            <span className="text-muted-foreground mx-2">→</span>
            <Badge
              variant="custom"
              className="text-white"
              style={{ backgroundColor: getOptionColor(String(value)) }}
            >
              {getOptionLabel(String(value))}
            </Badge>
          </div>
        );
      }

      // Handle non-dropdown fields
      return (
        <div key={key} className="flex items-center gap-2 py-2">
          <span className="font-medium min-w-[200px]">{fieldConfig.label}:</span>
          <Badge variant="secondary">{String(originalValue) || 'N/A'}</Badge>
          <span className="text-muted-foreground mx-2">→</span>
          <Badge variant="secondary">{String(value)}</Badge>
        </div>
      );
    }).filter(Boolean);
  };

  const isEconomicUser = deal?.data?.regnskabsprogram === 'Economic';

  if (!visible) return null;

  return (
    <>
      <Drawer open={visible} onOpenChange={handleCloseDrawer}>
        <DrawerContent className="h-[85vh]">
          <Tabs value={activeTab} onValueChange={setActiveTab} className="flex flex-col h-full">
            <DrawerHeader className="flex flex-row items-center justify-between space-x-4">
              <div className="flex-shrink-0">
                <DrawerTitle className="text-primary">
                  {deal?.name || 'Deal Details'}
                </DrawerTitle>
                <DrawerDescription>
                  Manage deal information and settings
                </DrawerDescription>
              </div>
              <TabsList className={cn(
                "grid grid-cols-3",
                isEconomicUser && "grid-cols-4",
                "w-[400px]"
              )}>
                <TabsTrigger value="data">Data</TabsTrigger>
                <TabsTrigger value="cleanup">Cleanup</TabsTrigger>
                <TabsTrigger value="setup">Setup</TabsTrigger>
                {isEconomicUser && (
                  <TabsTrigger value="officebot">Officebot</TabsTrigger>
                )}
              </TabsList>
              <div className="flex gap-2 flex-shrink-0">
                <Button 
                  onClick={handleSave} 
                  disabled={!Object.keys(changes).length}
                  className="shrink-0"
                >
                  Save Changes
                </Button>
                <Button 
                  variant="outline" 
                  onClick={handleCloseDrawer}
                  className="shrink-0"
                >
                  Close
                </Button>
              </div>
            </DrawerHeader>

            {loading ? (
              <div className="flex justify-center items-center h-full">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary" />
              </div>
            ) : (
              <div className="flex-1 overflow-hidden">
                <TabsContent value="data" className="mt-0 h-full">
                  {renderGroupedData()}
                </TabsContent>
                <TabsContent value="cleanup" className="mt-0 h-full">
                  {/* Cleanup content will go here */}
                </TabsContent>
                <TabsContent value="setup" className="mt-0 h-full">
                  {/* Setup content will go here */}
                </TabsContent>
                {isEconomicUser && (
                  <TabsContent value="officebot" className="mt-0 h-full">
                    {/* Officebot content will go here */}
                  </TabsContent>
                )}
              </div>
            )}
          </Tabs>
        </DrawerContent>
      </Drawer>

      <Dialog open={confirmVisible} onOpenChange={setConfirmVisible}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Changes</DialogTitle>
            <DialogDescription>
              Are you sure you want to save the following changes?
            </DialogDescription>
          </DialogHeader>
          <div className="py-4">
            {renderChanges()}
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setConfirmVisible(false)}>
              Cancel
            </Button>
            <Button onClick={confirmSave}>
              Save Changes
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export { DealDrawer };
