import { useState, useEffect } from "react"
import { Clock, CheckCircle, Timer, User, Pause, Play } from "lucide-react"
import axios from '@/api/axiosConfig'
import { toast } from "sonner"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { BarChartHorizontalMix } from "@/components/ui/charts/bar-horizontal-mix"
import { LoadingSpinner } from "@/components/ui/loading-spinner"

interface TaskStats {
  total_stats: {
    total_tasks_all_time: number
    total_tasks_current_month: number
    total_active_tasks: number
  }
  stats_by_type: Record<string, {
    total_count: number
    historical_count: number
    current_month_count: number
    active_count: number
    total_duration: number
    historical_duration: number
    avg_duration: number
  }>
}

interface ActiveTask {
  id: string
  task_type: string
  recurring_type: string
  current_duration: number
  is_paused: boolean
}

export default function TaskTimetracking() {
  const [loading, setLoading] = useState(true)
  const [taskStats, setTaskStats] = useState<TaskStats | null>(null)
  const [activeTracking, setActiveTracking] = useState<ActiveTask[]>([])

  useEffect(() => {
    fetchData()
    const interval = setInterval(fetchActiveTracking, 60000)
    return () => clearInterval(interval)
  }, [])

  const fetchData = async () => {
    try {
      setLoading(true)
      const [activeResponse, statsResponse] = await Promise.all([
        axios.get('/dashboard/active-timetracking'),
        axios.get('/dashboard/task-timetracking-data')
      ])
      
      setActiveTracking(activeResponse.data.active_tracking)
      setTaskStats(statsResponse.data)
    } catch (error) {
      console.error('Error fetching data:', error)
      toast.error("Failed to fetch task data")
    } finally {
      setLoading(false)
    }
  }

  const fetchActiveTracking = async () => {
    try {
      const response = await axios.get('/dashboard/active-timetracking')
      setActiveTracking(response.data.active_tracking)
    } catch (error) {
      console.error('Error fetching active tracking:', error)
      toast.error("Failed to update active tasks")
    }
  }

  const formatDuration = (seconds: number) => {
    if (!seconds) return '0h 0m'
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    return `${hours}h ${minutes}m`
  }

  const renderActiveTasks = () => {
    if (!activeTracking?.length) {
      return (
        <div className="flex h-[200px] items-center justify-center text-muted-foreground">
          No active time tracking sessions
        </div>
      )
    }

    return activeTracking.map(task => (
      <div key={task.id} className="border-b py-3 last:border-0">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {task.is_paused ? (
              <Pause className="h-4 w-4 text-warning" />
            ) : (
              <Play className="h-4 w-4 text-success" />
            )}
            <span className="font-medium">
              {task.recurring_type || task.task_type}
            </span>
          </div>
          <div className="flex items-center gap-2 text-primary">
            <Clock className="h-4 w-4" />
            <span className="font-semibold">
              {formatDuration(task.current_duration)}
            </span>
          </div>
        </div>
      </div>
    ))
  }

  const renderCompletionStats = () => {
    if (!taskStats?.total_stats) return null

    const stats = [
      {
        title: "Total Time Tracking Sessions",
        value: taskStats.total_stats.total_tasks_all_time,
        icon: <CheckCircle className="h-4 w-4" />
      },
      {
        title: "Sessions This Month",
        value: taskStats.total_stats.total_tasks_current_month,
        icon: <Timer className="h-4 w-4" />
      },
      {
        title: "Active Sessions",
        value: taskStats.total_stats.total_active_tasks,
        icon: <Clock className="h-4 w-4" />
      }
    ]

    return (
      <div className="grid gap-4 md:grid-cols-3">
        {stats.map((stat, i) => (
          <Card key={i}>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {stat.title}
              </CardTitle>
              {stat.icon}
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{stat.value}</div>
            </CardContent>
          </Card>
        ))}
      </div>
    )
  }

  const renderDurationChart = () => {
    if (!taskStats?.stats_by_type) return null

    const data = Object.entries(taskStats.stats_by_type).map(([type, stats]) => ({
      name: type,
      value: Number((stats.avg_duration / 3600).toFixed(2)),
      fill: `hsl(var(--primary))`
    }))

    const config = {
      value: { label: "Hours" },
      ...Object.fromEntries(
        Object.keys(taskStats.stats_by_type).map(type => [
          type,
          { label: type }
        ])
      )
    }

    return (
      <BarChartHorizontalMix
        data={data}
        config={config}
        className="h-[300px]"
      />
    )
  }

  const renderHistoricalData = () => {
    if (!taskStats?.stats_by_type) return null

    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Task Type</TableHead>
            <TableHead className="text-right">Total Sessions</TableHead>
            <TableHead className="text-right">Sessions This Month</TableHead>
            <TableHead className="text-right">Active Sessions</TableHead>
            <TableHead className="text-right">Total Duration</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.entries(taskStats.stats_by_type).map(([type, stats]) => (
            <TableRow key={type}>
              <TableCell className="font-medium">{type}</TableCell>
              <TableCell className="text-right">
                {stats.total_count + stats.historical_count}
              </TableCell>
              <TableCell className="text-right">
                {stats.current_month_count}
              </TableCell>
              <TableCell className="text-right">
                {stats.active_count}
              </TableCell>
              <TableCell className="text-right">
                {formatDuration(stats.total_duration + stats.historical_duration)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  if (loading) {
    return (
      <div className="flex h-[400px] items-center justify-center">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div className="space-y-4 pt-4">
      <Card>
        <CardHeader>
          <CardTitle>Active Tasks</CardTitle>
        </CardHeader>
        <CardContent>
          {renderActiveTasks()}
        </CardContent>
      </Card>

      <div className="grid gap-6 md:grid-cols-2">
        <Card>
          <CardHeader>
            <CardTitle>Task Completion Stats</CardTitle>
          </CardHeader>
          <CardContent>
            {renderCompletionStats()}
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Average Duration by Task Type</CardTitle>
          </CardHeader>
          <CardContent>
            {renderDurationChart()}
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Historical Task Data</CardTitle>
        </CardHeader>
        <CardContent>
          {renderHistoricalData()}
        </CardContent>
      </Card>
    </div>
  )
}