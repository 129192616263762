import React from 'react';
import * as LucideIcons from 'lucide-react';
import { cn } from '@/lib/utils';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Button } from "@/components/ui/button"
import { Check, ChevronsUpDown } from "lucide-react"

// List of icons we want to make available
const AVAILABLE_ICONS = [
  'Activity', 'AlertCircle', 'Archive', 'BarChart', 'Bell', 'Book', 'Bookmark',
  'Box', 'Briefcase', 'Building', 'Calendar', 'CheckCircle', 'Clipboard',
  'Clock', 'Cloud', 'Code', 'Coffee', 'Cog', 'Command', 'Compass', 'Copy',
  'CreditCard', 'Database', 'File', 'FileText', 'Flag', 'Folder', 'FolderKanban',
  'Gift', 'Globe', 'Heart', 'Home', 'Image', 'Inbox', 'Info', 'Layout',
  'LifeBuoy', 'Link', 'List', 'Lock', 'Mail', 'Map', 'MessageCircle',
  'Monitor', 'Moon', 'Package', 'Paperclip', 'Phone', 'PieChart', 'Pin',
  'Settings', 'Star'
] as const;

type IconName = typeof AVAILABLE_ICONS[number];

interface IconSelectorProps {
  value: string;
  onChange: (value: string) => void;
}

export function IconSelector({ value, onChange }: IconSelectorProps) {
  const [open, setOpen] = React.useState(false);

  const IconComponent = (LucideIcons as any)[value] || LucideIcons.FolderKanban;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between bg-background text-foreground border-input"
        >
          <div className="flex items-center gap-2">
            <IconComponent className="h-4 w-4" />
            <span>{value}</span>
          </div>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          <CommandInput placeholder="Search icons..." className="h-9" />
          <CommandList>
            <CommandEmpty>No icon found.</CommandEmpty>
            <CommandGroup>
              {AVAILABLE_ICONS.map((iconName) => {
                const Icon = (LucideIcons as any)[iconName];
                return (
                  <CommandItem
                    key={iconName}
                    value={iconName}
                    onSelect={(currentValue) => {
                      onChange(iconName);
                      setOpen(false);
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <Icon className="h-4 w-4" />
                      <span>{iconName}</span>
                    </div>
                    <Check
                      className={cn(
                        "ml-auto h-4 w-4",
                        value === iconName ? "opacity-100" : "opacity-0"
                      )}
                    />
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
} 