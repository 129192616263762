import { useState, useEffect, useMemo } from 'react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { toast } from 'sonner'
import axios from '@/api/axiosConfig'
import { Loader2, Archive, AlertTriangle, PowerOff, Plus, X, ChevronLeft, ChevronRight, Trash2, ExternalLink } from 'lucide-react'
import { Table, ColumnDef } from "@/components/ui/specialized/mod-table"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Textarea } from "@/components/ui/textarea"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"

type Owner = {
  id: string
  name: string
  ownershipPercentage: number
  email?: string
}

type KycCase = {
  id: string
  name: string
  url: string
  status: string
  statusText: { en: string }
  riskAssessmentNotes: string
  riskAssessmentConclusion: string
  timeCreated: string
  timeLastEdited: string
  archivedAt?: number
  deactivatedAt?: number
  deletionDate?: number
  riskAssessmentType?: {
    typeName: string
    localizedNames: {
      en: string
    }
  }
  client?: {
    name: string
    legalOwners?: Owner[]
  }
}

type PageInfo = {
  hasPreviousPage: boolean
  beforeCursor: string
  hasNextPage: boolean
  afterCursor: string
}

type Goal = {
  id: string
  acceptance: string
  localizedNames: {
    en: string
  }
}

type DocumentType = {
  typeName: string
  localizedNames: {
    en: string
  }
}

type RiskAssessmentType = {
  typeName: string
  localizedNames: {
    en: string
  }
}

export default function Kyc() {
  const [cvr, setCvr] = useState('')
  const [caseId, setCaseId] = useState('')
  const [loading, setLoading] = useState(false)
  const [createLoading, setCreateLoading] = useState(false)
  const [cases, setCases] = useState<KycCase[]>([])
  const [selectedCase, setSelectedCase] = useState<KycCase | null>(null)
  const [riskAssessment, setRiskAssessment] = useState({
    conclusion: '',
    notes: ''
  })
  const [goals, setGoals] = useState<Goal[]>([])
  const [availableDocuments, setAvailableDocuments] = useState<DocumentType[]>([])
  const [selectedDocument, setSelectedDocument] = useState('')
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null)
  const [totalCount, setTotalCount] = useState(0)
  const [currentCursor, setCurrentCursor] = useState<string | null>(null)
  const [availableRiskTypes, setAvailableRiskTypes] = useState<RiskAssessmentType[]>([])
  const [selectedRiskType, setSelectedRiskType] = useState('')
  const [nextReassessmentDate, setNextReassessmentDate] = useState<string | null>(null)
  const [caseDetails, setCaseDetails] = useState<KycCase | null>(null)
  const [showArchived, setShowArchived] = useState(false)
  const [archivedCases, setArchivedCases] = useState<KycCase[]>([])
  const [archivedPageInfo, setArchivedPageInfo] = useState<PageInfo | null>(null)
  const [archivedTotalCount, setArchivedTotalCount] = useState(0)
  const [statusFilter, setStatusFilter] = useState<string>('')
  const [sortField, setSortField] = useState<'name' | 'timeCreated' | 'timeLastEdited'>('timeCreated')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')
  const [tablePage, setTablePage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  useEffect(() => {
    fetchCases()
  }, [])

  const fetchCases = async (cursor?: string, direction: 'next' | 'prev' = 'next') => {
    try {
      setLoading(true)
      const response = await axios.get('/penneo/cases', {
        params: {
          amount: pageSize,
          ...(cursor && direction === 'next' ? { after: cursor } : {}),
          ...(cursor && direction === 'prev' ? { before: cursor } : {})
        }
      })
      
      if (response.data?.data?.cases) {
        const { items, pageInfo, totalCount } = response.data.data.cases
        const casesData = items.map((item: any) => item.item)
        setCases(casesData)
        setPageInfo({
          hasPreviousPage: pageInfo.hasPreviousPage,
          beforeCursor: pageInfo.beforeCursor,
          hasNextPage: pageInfo.hasNextPage,
          afterCursor: pageInfo.afterCursor
        })
        setTotalCount(totalCount)
      }
    } catch (error: any) {
      console.error('Error fetching cases:', error)
      toast.error(error.response?.data?.error || 'Error fetching cases')
    } finally {
      setLoading(false)
    }
  }

  const createCase = async () => {
    if (!cvr.trim()) {
      toast.error('Please enter a CVR number')
      return
    }

    try {
      setCreateLoading(true)
      const response = await axios.post('/penneo/create-case', { cvr: cvr.trim() })
      
      if (response.data.errors) {
        toast.error(response.data.errors[0]?.message || 'Failed to create case')
        return
      }

      toast.success('Case created successfully')
      fetchCases()
      setCvr('')
    } catch (error: any) {
      console.error('Create case error:', error)
      toast.error(error.response?.data?.error || 'Error creating case')
    } finally {
      setCreateLoading(false)
    }
  }

  const archiveCase = async (id: string) => {
    try {
      setLoading(true)
      await axios.post(`/penneo/case/${id}/archive`)
      toast.success('Case archived successfully')
      fetchCases()
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Error archiving case')
    } finally {
      setLoading(false)
    }
  }

  const updateRiskAssessment = async (id: string) => {
    try {
      setLoading(true)
      await axios.post(`/penneo/case/${id}/risk-assessment`, riskAssessment)
      toast.success('Risk assessment updated successfully')
      fetchCases()
      setSelectedCase(null)
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Error updating risk assessment')
    } finally {
      setLoading(false)
    }
  }

  const deactivateCase = async (id: string) => {
    try {
      setLoading(true)
      await axios.post(`/penneo/case/${id}/deactivate`)
      toast.success('Case deactivated successfully')
      fetchCases()
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Error deactivating case')
    } finally {
      setLoading(false)
    }
  }

  const fetchGoals = async (id: string) => {
    try {
      const response = await axios.get(`/penneo/case/${id}/goals`)
      if (response.data.data?.company?.goals) {
        setGoals(response.data.data.company.goals)
      }
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Error fetching goals')
    }
  }

  const fetchAvailableDocuments = async (id: string) => {
    try {
      const response = await axios.get(`/penneo/case/${id}/available-documents`)
      if (response.data.data?.availableDocumentTypesForCompany) {
        setAvailableDocuments(response.data.data.availableDocumentTypesForCompany)
      }
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Error fetching available documents')
    }
  }

  const addGoal = async (id: string) => {
    if (!selectedDocument) {
      toast.error('Please select a document type')
      return
    }

    try {
      setLoading(true)
      await axios.post(`/penneo/case/${id}/goals`, {
        goalIdentifier: selectedDocument
      })
      toast.success('Goal added successfully')
      fetchGoals(id)
      setSelectedDocument('')
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Error adding goal')
    } finally {
      setLoading(false)
    }
  }

  const removeGoal = async (caseId: string, goalId: string) => {
    try {
      setLoading(true)
      await axios.delete(`/penneo/case/${caseId}/goals/${goalId}`)
      toast.success('Goal removed successfully')
      fetchGoals(caseId)
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Error removing goal')
    } finally {
      setLoading(false)
    }
  }

  const fetchRiskAssessmentTypes = async (id: string) => {
    try {
      const response = await axios.get(`/penneo/case/${id}/risk-assessment-types`)
      if (response.data?.data?.availableRiskAssessmentTypesForCase) {
        setAvailableRiskTypes(response.data.data.availableRiskAssessmentTypesForCase)
      }
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Error fetching risk assessment types')
    }
  }

  const fetchNextReassessmentDate = async (id: string) => {
    try {
      const response = await axios.get(`/penneo/case/${id}/risk-reassessment-date`)
      if (response.data?.data?.riskReassessmentNextDateForCase) {
        setNextReassessmentDate(response.data.data.riskReassessmentNextDateForCase)
      }
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Error fetching next reassessment date')
    }
  }

  const setRiskAssessmentType = async (id: string) => {
    if (!selectedRiskType) {
      toast.error('Please select a risk assessment type')
      return
    }

    try {
      setLoading(true)
      await axios.post(`/penneo/case/${id}/risk-assessment-type`, {
        typeName: selectedRiskType
      })
      toast.success('Risk assessment type updated successfully')
      fetchCases()
      setSelectedRiskType('')
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Error updating risk assessment type')
    } finally {
      setLoading(false)
    }
  }

  const deleteCase = async (id: string) => {
    try {
      setLoading(true)
      await axios.delete(`/penneo/case/${id}`)
      toast.success('Case deleted successfully')
      fetchCases()
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Error deleting case')
    } finally {
      setLoading(false)
    }
  }

  const updateOwnerEmail = async (caseId: string, ownerId: string, email: string) => {
    try {
      setLoading(true)
      await axios.post(`/penneo/case/${caseId}/owner/${ownerId}/email`, { email })
      toast.success('Owner email updated successfully')
      fetchCases()
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Error updating owner email')
    } finally {
      setLoading(false)
    }
  }

  const contactOwners = async (caseId: string) => {
    try {
      setLoading(true)
      await axios.post(`/penneo/case/${caseId}/contact`)
      toast.success('Contact formula sent to owners')
      fetchCases()
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Error contacting owners')
    } finally {
      setLoading(false)
    }
  }

  const fetchCaseDetails = async (id: string) => {
    try {
      setLoading(true)
      const response = await axios.get(`/penneo/case/${id}/details`)
      if (response.data?.data?.case) {
        setCaseDetails(response.data.data.case)
      }
    } catch (error: any) {
      console.error('Error fetching case details:', error)
      toast.error(error.response?.data?.error || 'Error fetching case details')
    } finally {
      setLoading(false)
    }
  }

  const reactivateCase = async (id: string) => {
    try {
      setLoading(true)
      await axios.post(`/penneo/case/${id}/reactivate`)
      toast.success('Case reactivated successfully')
      fetchCases()
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Error reactivating case')
    } finally {
      setLoading(false)
    }
  }

  const fetchArchivedCases = async (cursor?: string, direction: 'next' | 'prev' = 'next') => {
    try {
      setLoading(true)
      const response = await axios.get('/penneo/archived-cases', {
        params: {
          amount: pageSize,
          ...(cursor && direction === 'next' ? { after: cursor } : {}),
          ...(cursor && direction === 'prev' ? { before: cursor } : {})
        }
      })
      
      if (response.data?.data?.cases) {
        const { items, pageInfo, totalCount } = response.data.data.cases
        const casesData = items.map((item: any) => item.item)
        setArchivedCases(casesData)
        setArchivedPageInfo({
          hasPreviousPage: pageInfo.hasPreviousPage,
          beforeCursor: pageInfo.beforeCursor,
          hasNextPage: pageInfo.hasNextPage,
          afterCursor: pageInfo.afterCursor
        })
        setArchivedTotalCount(totalCount)
      }
    } catch (error: any) {
      console.error('Error fetching archived cases:', error)
      toast.error(error.response?.data?.error || 'Error fetching archived cases')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedCase) {
      fetchGoals(selectedCase.id)
      fetchAvailableDocuments(selectedCase.id)
      fetchRiskAssessmentTypes(selectedCase.id)
      fetchNextReassessmentDate(selectedCase.id)
    }
  }, [selectedCase])

  useEffect(() => {
    if (showArchived) {
      fetchArchivedCases()
    }
  }, [showArchived])

  const uniqueStatuses = useMemo(() => {
    const statuses = new Set(cases.map(kycCase => kycCase.status))
    return Array.from(statuses)
  }, [cases])

  const columns = useMemo<ColumnDef<KycCase>[]>(() => [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      sortable: {
        enabled: true,
        type: 'string'
      }
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      cell: ({ row }) => (
        <div className="flex items-center gap-2">
          {row.original.statusText.en}
          {row.original.status === 'DEACTIVATED' && (
            <span className="text-xs bg-secondary text-muted-foreground px-2 py-1 rounded">Deactivated</span>
          )}
          {row.original.status === 'ARCHIVED' && (
            <span className="text-xs bg-secondary text-muted-foreground px-2 py-1 rounded">Archived</span>
          )}
        </div>
      ),
      filterable: true,
      filters: uniqueStatuses.map(status => ({
        text: status,
        value: status
      })),
      onFilter: (value: string, record: KycCase) => record.status === value
    },
    {
      key: 'riskLevel',
      title: 'Risk Level',
      dataIndex: 'riskAssessmentConclusion',
      cell: ({ row }) => (
        <span className={`inline-flex items-center gap-1 ${
          row.original.riskAssessmentConclusion === 'HIGH' ? 'text-red-500' :
          row.original.riskAssessmentConclusion === 'MEDIUM' ? 'text-yellow-500' :
          'text-green-500'
        }`}>
          {row.original.riskAssessmentConclusion || 'UNKNOWN'}
          {row.original.riskAssessmentConclusion === 'HIGH' && (
            <AlertTriangle className="h-4 w-4" />
          )}
        </span>
      ),
      filterable: true,
      filters: [
        { text: 'High', value: 'HIGH' },
        { text: 'Medium', value: 'MEDIUM' },
        { text: 'Low', value: 'LOW' },
        { text: 'Substantial', value: 'SUBSTANTIAL' },
        { text: 'Somewhat Low', value: 'SOMEWHAT_LOW' },
        { text: 'Medium Low', value: 'MEDIUM_LOW' },
        { text: 'Unknown', value: '__NULL__' }
      ],
      onFilter: (value: string, record: KycCase) => value === '__NULL__' ? record.riskAssessmentConclusion === null : record.riskAssessmentConclusion === value
    },
    {
      key: 'riskType',
      title: 'Risk Type',
      dataIndex: 'riskAssessmentType',
      render: (value: KycCase['riskAssessmentType']) => value?.localizedNames.en || 'Not set'
    },
    {
      key: 'timeCreated',
      title: 'Created',
      dataIndex: 'timeCreated',
      render: (value: string) => new Date(value).toLocaleDateString(),
      sortable: {
        enabled: true,
        type: 'date'
      }
    },
    {
      key: 'timeLastEdited',
      title: 'Last Updated',
      dataIndex: 'timeLastEdited',
      render: (value: string) => new Date(value).toLocaleDateString(),
      sortable: {
        enabled: true,
        type: 'date'
      }
    },
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: 'id',
      cell: ({ row }) => (
        <div className="flex gap-2">
          <Dialog>
            <DialogTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  setSelectedCase(row.original)
                  fetchCaseDetails(row.original.id)
                }}
              >
                Details
              </Button>
            </DialogTrigger>
            <DialogContent className="max-w-2xl">
              <DialogHeader>
                <DialogTitle>{selectedCase?.name}</DialogTitle>
                <DialogDescription className="sr-only">Case details and risk assessment</DialogDescription>
              </DialogHeader>
              <Tabs defaultValue="info" className="mt-4">
                <TabsList>
                  <TabsTrigger value="info">Information</TabsTrigger>
                  <TabsTrigger value="risk">Risk Assessment</TabsTrigger>
                  <TabsTrigger value="documents">Documents & Goals</TabsTrigger>
                </TabsList>
                <TabsContent value="info">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <h3 className="font-medium mb-2">Case Information</h3>
                      <div className="space-y-2">
                        <p><span className="font-medium">Status:</span> {selectedCase?.statusText.en}</p>
                        <p><span className="font-medium">Risk Level:</span> {selectedCase?.riskAssessmentConclusion || 'UNKNOWN'}</p>
                        {selectedCase?.archivedAt && (
                          <p><span className="font-medium">Archived At:</span> {new Date(selectedCase.archivedAt * 1000).toLocaleString()}</p>
                        )}
                        {selectedCase?.deactivatedAt && (
                          <p><span className="font-medium">Deactivated At:</span> {new Date(selectedCase.deactivatedAt * 1000).toLocaleString()}</p>
                        )}
                        {selectedCase?.deletionDate && (
                          <p><span className="font-medium">Deletion Date:</span> {new Date(selectedCase.deletionDate * 1000).toLocaleString()}</p>
                        )}
                        {Boolean(selectedCase?.client?.legalOwners?.length) && (
                          <div>
                            <p className="font-medium">Legal Owners:</p>
                            <ul className="space-y-2">
                              {selectedCase?.client?.legalOwners?.map((owner, index) => (
                                <li key={index} className="flex flex-col gap-2 p-2 bg-secondary/50 rounded">
                                  <div>
                                    <p>{owner.name} ({owner.ownershipPercentage}%)</p>
                                    <p className="text-sm text-muted-foreground">
                                      Email: {owner.email || 'Not set'}
                                    </p>
                                  </div>
                                  <div className="flex gap-2">
                                    <Input
                                      placeholder="Enter email"
                                      defaultValue={owner.email || ''}
                                      onChange={(e) => {
                                        const newOwners = [...(selectedCase?.client?.legalOwners || [])];
                                        newOwners[index] = { ...owner, email: e.target.value };
                                        setSelectedCase(prev => ({
                                          ...prev!,
                                          client: {
                                            ...prev!.client!,
                                            legalOwners: newOwners
                                          }
                                        }));
                                      }}
                                    />
                                    <Button
                                      size="sm"
                                      onClick={() => updateOwnerEmail(selectedCase!.id, owner.id, owner.email || '')}
                                      disabled={!owner.email}
                                    >
                                      Update Email
                                    </Button>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabsContent>
                <TabsContent value="risk">
                  <div className="space-y-4">
                    <div>
                      <h3 className="font-medium mb-2">Risk Assessment Type</h3>
                      <div className="flex gap-2">
                        <Select
                          value={selectedRiskType || selectedCase?.riskAssessmentType?.typeName || "NONE"}
                          onValueChange={value => setSelectedRiskType(value === "NONE" ? "" : value)}
                        >
                          <SelectTrigger className="flex-1">
                            <SelectValue placeholder="Select risk assessment type" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="NONE">No type selected</SelectItem>
                            {availableRiskTypes.map((type) => (
                              <SelectItem key={type.typeName} value={type.typeName}>
                                {type.localizedNames.en}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <Button
                          onClick={() => selectedCase && setRiskAssessmentType(selectedCase.id)}
                          disabled={!selectedRiskType || loading}
                        >
                          Update Type
                        </Button>
                      </div>
                    </div>

                    <div>
                      <h3 className="font-medium mb-2">Current Risk Assessment</h3>
                      <div className="space-y-4">
                        <Select
                          value={riskAssessment.conclusion}
                          onValueChange={(value) => setRiskAssessment(prev => ({
                            ...prev,
                            conclusion: value
                          }))}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select risk level" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="LOW">Low</SelectItem>
                            <SelectItem value="MEDIUM">Medium</SelectItem>
                            <SelectItem value="HIGH">High</SelectItem>
                            <SelectItem value="SUBSTANTIAL">Substantial</SelectItem>
                            <SelectItem value="SOMEWHAT_LOW">Somewhat Low</SelectItem>
                            <SelectItem value="MEDIUM_LOW">Medium Low</SelectItem>
                          </SelectContent>
                        </Select>
                        <Textarea
                          placeholder="Risk assessment notes"
                          value={riskAssessment.notes}
                          onChange={(e) => setRiskAssessment(prev => ({
                            ...prev,
                            notes: e.target.value
                          }))}
                        />
                        <Button
                          onClick={() => selectedCase && updateRiskAssessment(selectedCase.id)}
                          disabled={!riskAssessment.conclusion}
                        >
                          Update Risk Assessment
                        </Button>
                      </div>
                    </div>

                    {nextReassessmentDate && (
                      <div>
                        <h3 className="font-medium mb-2">Next Reassessment Date</h3>
                        <p>{new Date(nextReassessmentDate).toLocaleDateString()}</p>
                      </div>
                    )}
                  </div>
                </TabsContent>
                <TabsContent value="documents">
                  <div className="space-y-4">
                    <div>
                      <h3 className="font-medium mb-2">Add Document Goal</h3>
                      <div className="flex gap-2">
                        <Select
                          value={selectedDocument}
                          onValueChange={setSelectedDocument}
                        >
                          <SelectTrigger className="flex-1">
                            <SelectValue placeholder="Select document type" />
                          </SelectTrigger>
                          <SelectContent>
                            {availableDocuments.map((doc) => (
                              <SelectItem key={doc.typeName} value={doc.typeName}>
                                {doc.localizedNames.en}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <Button
                          onClick={() => addGoal(row.original.id)}
                          disabled={!selectedDocument || loading}
                        >
                          <Plus className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                    <div>
                      <h3 className="font-medium mb-2">Current Goals</h3>
                      <div className="space-y-2">
                        {goals.map((goal) => (
                          <div
                            key={goal.id}
                            className="flex items-center justify-between p-2 bg-secondary rounded"
                          >
                            <div>
                              <p>{goal.localizedNames.en}</p>
                              <p className="text-sm text-muted-foreground">
                                Status: {goal.acceptance}
                              </p>
                            </div>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => removeGoal(row.original.id, goal.id)}
                              disabled={loading}
                            >
                              <X className="h-4 w-4" />
                            </Button>
                          </div>
                        ))}
                        {goals.length === 0 && (
                          <p className="text-muted-foreground">No goals added yet</p>
                        )}
                      </div>
                    </div>
                  </div>
                </TabsContent>
              </Tabs>
            </DialogContent>
          </Dialog>
          {!showArchived ? (
            <>
              <TooltipProvider delayDuration={0}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => archiveCase(row.original.id)}
                    >
                      <Archive className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Archive case</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <TooltipProvider delayDuration={0}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => row.original.status === 'DEACTIVATED' ? reactivateCase(row.original.id) : deactivateCase(row.original.id)}
                    >
                      <PowerOff className={`h-4 w-4 ${row.original.status === 'DEACTIVATED' ? 'text-green-500' : ''}`} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{row.original.status === 'DEACTIVATED' ? 'Reactivate case' : 'Deactivate case'}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <TooltipProvider delayDuration={0}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => deleteCase(row.original.id)}
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Delete case</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </>
          ) : null}

          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => window.open(row.original.url, '_blank')}
                >
                  <ExternalLink className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Open in Penneo</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      )
    }
  ], [uniqueStatuses, showArchived])

  return (
    <div className="container mx-auto p-4 space-y-4">
      <Card>
        <CardHeader>
          <CardTitle>Create New KYC Case</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="flex gap-4">
            <Input
              placeholder="Enter CVR number"
              value={cvr}
              onChange={(e) => setCvr(e.target.value)}
              disabled={createLoading}
            />
            <Button 
              onClick={createCase} 
              disabled={createLoading || !cvr.trim()}
            >
              {createLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Creating...
                </>
              ) : (
                'Create Case'
              )}
            </Button>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <div className="flex items-center justify-between">
            <CardTitle>KYC Cases ({totalCount} total)</CardTitle>
            <div className="flex items-center gap-4">
              <Button
                variant="outline"
                onClick={() => setShowArchived(!showArchived)}
              >
                {showArchived ? 'Show Active Cases' : 'Show Archived Cases'}
              </Button>
              {loading && <Loader2 className="h-4 w-4 animate-spin" />}
            </div>
          </div>
        </CardHeader>
        <CardContent>
          {showArchived ? (
            archivedCases.length === 0 && !loading ? (
              <p className="text-muted-foreground text-center py-4">No archived KYC cases found</p>
            ) : (
              <Table
                data={archivedCases}
                columns={columns}
                pageSize={pageSize}
                currentPage={tablePage}
                onPageChange={(page) => {
                  setTablePage(page)
                  const cursor = page > tablePage 
                    ? archivedPageInfo?.afterCursor 
                    : archivedPageInfo?.beforeCursor
                  fetchArchivedCases(cursor, page > tablePage ? 'next' : 'prev')
                }}
                onPageSizeChange={(newSize) => {
                  setPageSize(newSize)
                  setTablePage(1) // Reset to first page when changing page size
                  fetchArchivedCases() // Refetch with new page size
                }}
              />
            )
          ) : (
            cases.length === 0 && !loading ? (
              <p className="text-muted-foreground text-center py-4">No KYC cases found</p>
            ) : (
              <Table
                data={cases}
                columns={columns}
                pageSize={pageSize}
                currentPage={tablePage}
                onPageChange={(page) => {
                  setTablePage(page)
                  const cursor = page > tablePage 
                    ? pageInfo?.afterCursor 
                    : pageInfo?.beforeCursor
                  fetchCases(cursor, page > tablePage ? 'next' : 'prev')
                }}
                onPageSizeChange={(newSize) => {
                  setPageSize(newSize)
                  setTablePage(1) // Reset to first page when changing page size
                  fetchCases() // Refetch with new page size
                }}
              />
            )
          )}
        </CardContent>
      </Card>
    </div>
  )
}