import React, { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Label } from '@/components/ui/label';
import { 
  NodeConfig, 
  NodeElement, 
  SelectConfig, 
  InputConfig, 
  ButtonConfig, 
  TextConfig,
  VisibilityCondition,
  DealDataConfig,
  TaskConfigType,
  RecurringTask,
  OnboardingTask,
  ApiConfig,
  ApiSuccessAction,
  ApiErrorAction
} from '../types/node_types';
import { cn } from '@/lib/utils';
import { OnboardingFormGuide } from './OnboardingFormGuide';
import get from 'lodash/get';
import set from 'lodash/set';
import { useOnboardingStore } from '../onboardingStore';
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';

interface NodeRendererProps {
  config: NodeConfig;
  formData: Record<string, any>;
  onSubmit: (data: Record<string, any>) => void;
  onBack?: () => void;
  dealData?: any;
  onboardingId: string;
  apiResponseData?: Record<string, any>;
}

export function NodeRenderer({ config, formData, onSubmit, onBack, dealData, onboardingId, apiResponseData }: NodeRendererProps) {
  const [localFormData, setLocalFormData] = useState<Record<string, any>>(formData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getNodeFormData } = useOnboardingStore();

  const handleApiCall = async (apiConfig: ApiConfig, dealData: any, formData: Record<string, any>) => {
    const { endpoint, method, payload } = apiConfig;
    let url = endpoint;
    
    // Replace any URL parameters
    if (url.includes('<cvr>') && dealData?.cvr) {
      url = url.replace('<cvr>', dealData.cvr);
    }

    // Prepare payload data
    let data = {};
    if (payload.type === 'deal') {
      data = payload.value.reduce((acc: Record<string, any>, key: string) => {
        acc[key] = dealData?.[key];
        return acc;
      }, {});
    } else if (payload.type === 'form') {
      data = payload.formElements?.reduce((acc: Record<string, any>, id: string) => {
        acc[id] = formData[id];
        return acc;
      }, {}) || {};
    } else if (payload.type === 'static') {
      data = payload.staticData || {};
    }

    return await axios({
      method,
      url,
      data
    });
  };

  const handleApiSuccess = (successAction: ApiSuccessAction) => {
    if ('type' in successAction) {
      if (successAction.type === 'message' && successAction.value) {
        toast.success(successAction.value);
      }
    }
  };

  const handleApiError = (errorAction: ApiErrorAction) => {
    if (errorAction.type === 'message' && errorAction.message) {
      toast.error(errorAction.message);
    }
  };

  const checkVisibilityCondition = (condition: VisibilityCondition): boolean => {
    let value;
    
    if (condition.type === 'dealData') {
      value = get(dealData, condition.elementId);
    } else {
      value = get(localFormData, condition.elementId);
    }

    switch (condition.condition) {
      case 'equals':
        return value === condition.value;
      case 'notEquals':
        return value !== condition.value;
      case 'hasValue':
        return value !== undefined && value !== null && value !== '';
      case 'isEmpty':
        return value === undefined || value === null || value === '';
      default:
        return true;
    }
  };

  const isElementVisible = (element: NodeElement): boolean => {
    if (!element.visibilityConditions?.length) return true;
    return element.visibilityConditions.every(checkVisibilityCondition);
  };

  useEffect(() => {
    // Pre-fill form data from deal data if available
    if (dealData) {
      const newFormData = { ...localFormData };
      config.rows.forEach(row => {
        row.elements.forEach(element => {
          if (element.type === 'select' || element.type === 'input') {
            const config = element.config as SelectConfig | InputConfig;
            const dealDataKey = config.dealDataKey;
            if (dealDataKey) {
              const value = get(dealData, dealDataKey);
              if (value !== undefined) {
                newFormData[element.id] = value;
              }
            }
          }
        });
      });
      setLocalFormData(newFormData);
    }
  }, [dealData, config]);

  const handleButtonClick = async (buttonId: string) => {
    setIsSubmitting(true);
    try {
      const buttonElement = config.rows
        .flatMap(row => row.elements)
        .find(el => el.id === buttonId && el.type === 'button');

      if (buttonElement?.type === 'button' && (buttonElement.config as ButtonConfig).action === 'complete') {
        // Call the complete-onboarding endpoint
        const response = await axios.post(`/onboardingflow/${onboardingId}/complete-onboarding`);
        if (response.data.success) {
          // Close the window by navigating back to the previous page
          window.close();
          return;
        }
      }

      // Create form data with the clicked button
      const data = {
        ...localFormData,
        [buttonId]: true
      };
      await onSubmit(data);
    } catch (error) {
      toast.error('Failed to process button action');
      console.error('Error processing button action:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderElement = (element: NodeElement) => {
    switch (element.type) {
      case 'input': {
        const config = element.config as InputConfig;
        return (
          <Input
            type={config.type || 'text'}
            placeholder={config.placeholder}
            value={localFormData[element.id] || ''}
            onChange={(e) => setLocalFormData({ ...localFormData, [element.id]: e.target.value })}
            required={config.required}
          />
        );
      }
      case 'button': {
        const config = element.config as ButtonConfig;
        if (config.action === 'api' && config.apiConfig) {
          return (
            <Button
              variant={config.variant}
              onClick={async () => {
                try {
                  const response = await handleApiCall(config.apiConfig!, dealData, formData);
                  if (response.data) {
                    // Store API response data in form data
                    setLocalFormData(prev => ({
                      ...prev,
                      apiResponseData: response.data
                    }));
                  }
                  handleApiSuccess(config.apiConfig!.successAction);
                } catch (error) {
                  handleApiError(config.apiConfig!.errorAction);
                }
              }}
            >
              {config.label}
            </Button>
          );
        }
        return (
          <Button
            variant={config.variant}
            onClick={() => handleButtonClick(element.id)}
            disabled={isSubmitting}
            className="w-full"
          >
            {config.label}
          </Button>
        );
      }
      case 'select': {
        const config = element.config as SelectConfig;
        return (
          <Select
            key={element.id}
            value={localFormData[element.id] || ''}
            onValueChange={(value) => setLocalFormData({ ...localFormData, [element.id]: value })}
          >
            <SelectTrigger>
              <SelectValue placeholder={config.placeholder || 'Select an option'} />
            </SelectTrigger>
            <SelectContent>
              {config.options.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        );
      }
      case 'text': {
        const config = element.config as TextConfig;
        return (
          <div className={cn(
            "text-foreground",
            config.variant === 'heading' && "text-2xl font-bold",
            config.variant === 'subheading' && "text-xl font-semibold",
          )}>
            {config.content}
          </div>
        );
      }
      case 'dealData': {
        const config = element.config as DealDataConfig;
        return (
          <div className="border p-4 rounded-lg w-full">
            {config.title && <h3 className="font-semibold mb-2">{config.title}</h3>}
            {config.description && <p className="text-sm text-muted-foreground mb-4">{config.description}</p>}
            <div className={cn(
              'grid gap-4',
              config.layout?.columns === 1 && 'grid-cols-1',
              config.layout?.columns === 2 && 'grid-cols-1 sm:grid-cols-2',
              config.layout?.columns === 3 && 'grid-cols-1 sm:grid-cols-3'
            )}>
              {config.fields?.map((field, index) => {
                const value = get(dealData, field.key);
                return (
                  <div key={index} className="space-y-1">
                    <Label>{field.label}</Label>
                    <Input 
                      value={value || ''}
                      onChange={(e) => {
                        const newData = { ...localFormData };
                        set(newData, field.key, e.target.value);
                        setLocalFormData(newData);
                      }}
                      placeholder={`Enter ${field.label.toLowerCase()}`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      }
      case 'taskConfig': {
        const config = element.config as TaskConfigType;
        // Use API response data if available, otherwise use config data
        const taskData = localFormData.apiResponseData?.tasks || config;
        return (
          <div className="space-y-4">
            {taskData.recurringTasks && taskData.recurringTasks.length > 0 && (
              <div>
                <h3 className="font-semibold mb-2">Recurring Tasks</h3>
                <div className="space-y-2">
                  {taskData.recurringTasks.map((task: RecurringTask, index: number) => (
                    <div key={index} className="p-2 border rounded">
                      <p className="font-medium">{task.description}</p>
                      <div className="text-sm text-muted-foreground">
                        <p>Frequency: {task.frequency}</p>
                        <p>Assignee: {task.assignee}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            
            {taskData.onboardingTasks && taskData.onboardingTasks.length > 0 && (
              <div>
                <h3 className="font-semibold mb-2">Onboarding Tasks</h3>
                <div className="space-y-2">
                  {taskData.onboardingTasks.map((task: OnboardingTask, index: number) => (
                    <div key={index} className="p-2 border rounded">
                      <p className="font-medium">{task.description}</p>
                      <div className="text-sm text-muted-foreground">
                        <p>Due Date: {task.due_date}</p>
                        <p>Assignee: {task.assignee}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-start">
        <div className="space-y-2">
          {config.title && (
            <h2 className="text-2xl font-bold text-foreground">{config.title}</h2>
          )}
          {config.description && (
            <p className="text-muted-foreground">{config.description}</p>
          )}
        </div>
      </div>
      
      <div className="space-y-6">
        {config.rows.map((row, index) => (
          <div 
            key={row.id || index} 
            className={cn(
              'grid gap-4',
              row.elements.length > 1 ? 'grid-cols-2' : 'grid-cols-1'
            )}
          >
            {row.elements.map((element) => (
              isElementVisible(element) && (
                <div 
                  key={element.id}
                  className={cn(
                    'flex flex-col space-y-2',
                    element.type === 'button' && 'justify-end',
                    element.layout?.width === 'full' && 'col-span-2',
                    element.layout?.width === '1/2' && 'col-span-1',
                    element.layout?.width === '1/3' && 'col-span-1 md:col-span-1',
                    element.layout?.width === '2/3' && 'col-span-2 md:col-span-2'
                  )}
                >
                  {(element.type === 'input' || element.type === 'select') && (
                    <label className="text-sm font-medium text-foreground">
                      {(element.config as InputConfig | SelectConfig).label}
                      {(element.config as InputConfig | SelectConfig).required && (
                        <span className="text-destructive ml-1">*</span>
                      )}
                    </label>
                  )}
                  {renderElement(element)}
                </div>
              )
            ))}
          </div>
        ))}

        {/* Navigation Buttons */}
        <div className="flex justify-between items-center mt-8">
          {onBack && (
            <Button variant="outline" onClick={onBack} disabled={isSubmitting}>
              Back
            </Button>
          )}
          {config.defaultTriggerElementId === '_default' && config.defaultNextNodeId && (
            <Button
              variant="default"
              onClick={() => handleButtonClick('_default')}
              disabled={isSubmitting}
              className={cn(!onBack && 'ml-auto')}
            >
              Continue
            </Button>
          )}
        </div>
      </div>
    </div>
  );
} 