import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Card } from "@/components/ui/card";
import { AvatarGroup } from "@/components/ui/avatars/avatar-group";
import { Plus, Check, X, UserPlus, CheckSquare } from 'lucide-react';
import { cn } from "@/lib/utils";
import { StoredUser } from '@/types/user';
import { UnifiedTask } from '@/types/task';
import { Board, SubTask, SubtaskStatusOption } from '@/types/custom-board';
import { AssigneeSelection } from '@/modals/AssigneeSelection';
import { toast } from 'sonner';
import axios from '@/api/axiosConfig';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SubtaskView } from '@/taskboards/views/SubtaskView';
import { useNavigate } from 'react-router-dom';

interface ExpandedSubtasksProps {
  task: UnifiedTask;
  board: Board | null;
  onDeleteSubtask: (taskId: number, subtaskId: number) => Promise<void>;
  onStatusUpdate: (taskId: number, subtaskId: number, newStatus: string) => Promise<SubTask>;
  getAssignedUsers: (task: UnifiedTask | SubTask & { task_type?: string }) => StoredUser[];
  onCreateSubtask: (taskId: number) => void;
  onTaskUpdate?: (task: UnifiedTask) => void;
  onSubtaskClick?: (taskId: number, subtaskId: number) => void;
}

// Helper function to determine if a color is light
const isLightColor = (color: string): boolean => {
  // Handle invalid colors
  if (!color) return false;

  // Convert hex to RGB
  let r: number, g: number, b: number;

  if (color.startsWith('#')) {
    const hex = color.replace('#', '');
    if (hex.length === 3) {
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else {
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    }
  } else {
    return false;
  }

  // Calculate relative luminance using the formula:
  // L = 0.299*R + 0.587*G + 0.114*B
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  
  // If luminance is greater than 0.5, color is considered light
  return luminance > 0.5;
};

export const ExpandedSubtasks: React.FC<ExpandedSubtasksProps> = ({
  task,
  board,
  onDeleteSubtask,
  onStatusUpdate,
  getAssignedUsers,
  onCreateSubtask,
  onTaskUpdate,
  onSubtaskClick
}) => {
  const [isAssigneeModalOpen, setIsAssigneeModalOpen] = useState(false);
  const [selectedSubtask, setSelectedSubtask] = useState<SubTask | null>(null);
  const [localTask, setLocalTask] = useState<UnifiedTask>(task);
  const [selectedSubtaskForView, setSelectedSubtaskForView] = useState<SubTask | null>(null);
  const navigate = useNavigate();

  // Update local task when parent task changes
  useEffect(() => {
    setLocalTask(task);
  }, [task]);

  const getStatusOptions = (subtask: SubTask): SubtaskStatusOption[] => {
    if (!board?.subtask_config?.predefined_subtasks) return [];

    const predefinedSubtask = board.subtask_config.predefined_subtasks.find(
      config => config.name === subtask.title
    );

    return predefinedSubtask?.status_options || [];
  };

  const getStatusColor = (subtask: SubTask, status: string): string => {
    if (!board?.subtask_config?.predefined_subtasks) return '#1890ff';

    const predefinedSubtask = board.subtask_config.predefined_subtasks.find(
      config => config.name === subtask.title
    );

    const statusOption = predefinedSubtask?.status_options?.find(opt => 
      opt.id.toString() === status.toString()
    );

    return statusOption?.color || '#1890ff';
  };

  const getStatusLabel = (subtask: SubTask, status: string): string => {
    if (!board?.subtask_config?.predefined_subtasks) return status;

    const predefinedSubtask = board.subtask_config.predefined_subtasks.find(
      config => config.name === subtask.title
    );

    const statusOption = predefinedSubtask?.status_options?.find(opt => 
      opt.id.toString() === status.toString()
    );

    return statusOption?.label || status;
  };

  const handleStatusUpdate = async (taskId: number, subtaskId: number, newStatus: string) => {
    // Optimistically update local state first
    const updatedSubtasks = localTask.subtasks?.map(st =>
      st.id === subtaskId ? { ...st, progress_status: newStatus } : st
    ) || [];

    const updatedTask = {
      ...localTask,
      subtasks: updatedSubtasks
    };

    // Update local state immediately
    setLocalTask(updatedTask);
    if (onTaskUpdate) {
      onTaskUpdate(updatedTask);
    }

    // Then call the API
    try {
      const response = await onStatusUpdate(taskId, subtaskId, newStatus);
      
      // Update local state with the full response from backend
      const updatedSubtasksWithResponse = localTask.subtasks?.map(st =>
        st.id === subtaskId ? { ...st, ...response, progress_status: newStatus } : st
      ) || [];

      const updatedTaskWithResponse = {
        ...localTask,
        subtasks: updatedSubtasksWithResponse
      };

      setLocalTask(updatedTaskWithResponse);
      if (onTaskUpdate) {
        onTaskUpdate(updatedTaskWithResponse);
      }
    } catch (error) {
      // Revert on error
      setLocalTask(localTask);
      if (onTaskUpdate) {
        onTaskUpdate(localTask);
      }
      toast.error('Failed to update status');
    }
  };

  const handleAssigneeClick = (e: React.MouseEvent, subtask: SubTask) => {
    e.stopPropagation();
    setSelectedSubtask(subtask);
    setIsAssigneeModalOpen(true);
  };

  const handleAssigneeUpdate = async (newAssignees: string[]) => {
    if (!selectedSubtask) return;

    try {
      await axios.put(`/custom-board/subtasks/${selectedSubtask.id}`, {
        assigned_users: newAssignees
      });

      // Create a new subtasks array with the updated assignees
      const updatedSubtasks = localTask.subtasks?.map(st =>
        st.id === selectedSubtask.id ? { ...st, assigned_users: newAssignees } : st
      ) || [];

      // Create a new task object with the updated subtasks
      const updatedTask = {
        ...localTask,
        subtasks: updatedSubtasks
      };

      // Update both local and parent state
      setLocalTask(updatedTask);
      if (onTaskUpdate) {
        onTaskUpdate(updatedTask);
      }

      toast.success('Assignees updated successfully');
      setIsAssigneeModalOpen(false);
      setSelectedSubtask(null);
    } catch (error) {
      console.error('Error updating subtask assignees:', error);
      toast.error('Failed to update assignees');
    }
  };

  const handleSubtaskClick = (e: React.MouseEvent, subtask: SubTask) => {
    e.stopPropagation();
    if (onSubtaskClick) {
      onSubtaskClick(task.id, subtask.id);
      // Navigate directly to the subtask route
      navigate(`/custom-boards/${board?.id}/task/${task.id}/subtask/${subtask.id}`, {
        replace: true,
        state: { skipTaskView: true }
      });
    }
  };

  const subtasks = localTask.subtasks || [];

  return (
    <>
      <Card 
        className="w-full mt-2 p-2 bg-muted/30"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="space-y-4">
          {/* Header */}
          <div className="flex items-center justify-between">
            <h4 className="text-sm font-medium">Subtasks ({subtasks.length})</h4>
            <Button
              variant="outline"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onCreateSubtask(localTask.id);
              }}
            >
              <Plus className="h-4 w-4 mr-2" />
              Add Subtask
            </Button>
          </div>

          {/* Subtasks List */}
          <div className="space-y-2">
            {subtasks.map((subtask) => (
              <Card 
                key={subtask.id} 
                className="w-full p-3 bg-background hover:bg-muted/50 transition-colors"
              >
                <div className="flex items-start justify-between">
                  <div className="flex-1">
                    <div className="flex items-center justify-between mb-2">
                      <div 
                        className="flex items-center gap-2 group/link cursor-pointer"
                        onClick={(e) => handleSubtaskClick(e, {
                          ...subtask,
                          progress_status_id: Number(subtask.progress_status_id) || 0
                        })}
                      >
                        <div className="flex items-center gap-2 group-hover/link:text-primary transition-colors">
                          <CheckSquare className="h-4 w-4" />
                          <span className="font-medium">{subtask.title}</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-4">
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-6 w-6 ml-2 -mt-1"
                          onClick={(e) => onDeleteSubtask(localTask.id, subtask.id)}
                        >
                          <X className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                    {subtask.description && (
                      <p className="text-xs text-muted-foreground mt-1">
                        {subtask.description}
                      </p>
                    )}
                    
                    {/* Dates Section */}
                    {(subtask.work_date || subtask.due_date) && (
                      <div className="flex gap-4 mt-2 text-xs text-muted-foreground">
                        {subtask.work_date && (
                          <div className="flex items-center gap-1">
                            <span className="font-medium">Work date:</span>
                            {new Date(subtask.work_date).toLocaleDateString()}
                          </div>
                        )}
                        {subtask.due_date && (
                          <div className="flex items-center gap-1">
                            <span className="font-medium">Due date:</span>
                            {new Date(subtask.due_date).toLocaleDateString()}
                          </div>
                        )}
                      </div>
                    )}
                    
                    {/* Status Dropdown */}
                    <div className="mt-2">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button 
                            variant="ghost" 
                            className="h-6 p-0 hover:bg-transparent"
                          >
                            <Badge
                              variant="custom"
                              className={cn(
                                "cursor-pointer",
                                "!text-[var(--badge-text-color)]"
                              )}
                              style={{
                                backgroundColor: getStatusColor(subtask as SubTask, subtask.progress_status),
                                ['--badge-text-color' as string]: isLightColor(getStatusColor(subtask as SubTask, subtask.progress_status)) ? '#000000' : '#ffffff'
                              }}
                            >
                              {getStatusLabel(subtask as SubTask, subtask.progress_status)}
                            </Badge>
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="start" className="w-[200px]">
                          {getStatusOptions(subtask as SubTask).map((status) => (
                            <DropdownMenuItem
                              key={status.id}
                              onClick={() => handleStatusUpdate(localTask.id, subtask.id, status.id.toString())}
                              className="flex items-center gap-2 cursor-pointer"
                            >
                              <div
                                className="w-2 h-2 rounded-full"
                                style={{ backgroundColor: status.color }}
                              />
                              <span>{status.label}</span>
                              {status.id.toString() === subtask.progress_status && (
                                <Check className="w-4 w-4 ml-auto" />
                              )}
                            </DropdownMenuItem>
                          ))}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>

                    {/* Assignees Section */}
                    {board?.subtask_config?.enable_assignees && (
                      <div className="mt-2">
                        {subtask.assigned_users?.length ? (
                          <div 
                            className="cursor-pointer hover:opacity-80 transition-opacity inline-flex"
                            onClick={(e) => handleAssigneeClick(e, {
                              ...subtask,
                              progress_status_id: Number(subtask.progress_status_id) || 0
                            })}
                          >
                            <AvatarGroup
                              users={getAssignedUsers({
                                ...subtask,
                                progress_status_id: Number(subtask.progress_status_id) || 0,
                                task_type: localTask.task_type,
                              })}
                              max={2}
                            />
                          </div>
                        ) : (
                          <Button
                            variant="ghost"
                            size="sm"
                            className="h-8 text-muted-foreground hover:text-foreground"
                            onClick={(e) => handleAssigneeClick(e, {
                              ...subtask,
                              progress_status_id: Number(subtask.progress_status_id) || 0
                            })}
                          >
                            <UserPlus className="h-4 w-4 mr-2" />
                            Add assignee
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </Card>

      {/* Assignee Selection Modal */}
      <AssigneeSelection
        visible={isAssigneeModalOpen}
        onClose={() => {
          setIsAssigneeModalOpen(false);
          setSelectedSubtask(null);
        }}
        onSave={handleAssigneeUpdate}
        initialAssignees={selectedSubtask?.assigned_users?.map(id => id.toString()) || []}
      />

      {selectedSubtaskForView && (
        <SubtaskView
          subtask={selectedSubtaskForView}
          task={localTask}
          board={board}
          onClose={() => {
            setSelectedSubtaskForView(null);
            // Update URL back to board view
            navigate(`/custom-boards/${board?.id}`, { replace: true });
          }}
        />
      )}
    </>
  );
};

export default ExpandedSubtasks; 