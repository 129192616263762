import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { NodeConfig, ButtonConfig } from "@/onboardingflow/types/node_types";
import { FlowNode } from "@/onboardingflow/types/flows";
import { Button } from "@/components/ui/button";
import { Plus, Trash2 } from "lucide-react";

interface DefaultNavigationProps {
  localConfig: NodeConfig;
  setLocalConfig: (config: NodeConfig) => void;
  nodes: FlowNode[];
  connectedNodes: FlowNode[];
}

export const DefaultNavigation = ({
  localConfig,
  setLocalConfig,
  nodes,
  connectedNodes
}: DefaultNavigationProps) => {
  const handleDefaultNodeChange = (value: string | undefined) => {
    setLocalConfig({
      ...localConfig,
      defaultNextNodeId: value === '_none' ? undefined : value,
      defaultTriggerElementId: value === '_none' ? undefined : localConfig.defaultTriggerElementId || '_default'
    });
  };

  const handleTriggerElementChange = (value: string) => {
    setLocalConfig({
      ...localConfig,
      defaultTriggerElementId: value === '_none' ? undefined : value
    });
  };

  const getButtonElements = () => {
    return localConfig.rows.flatMap(row => 
      row.elements.filter(el => el.type === 'button')
    );
  };

  return (
    <div className="border p-4 rounded-lg space-y-4">
      <Label className="text-lg font-semibold">Default Navigation</Label>
      
      <div className="space-y-4">
        <div>
          <Label>Default Trigger Element</Label>
          <Select
            value={localConfig.defaultTriggerElementId || '_none'}
            onValueChange={handleTriggerElementChange}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select trigger element" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="_none">None</SelectItem>
              <SelectItem value="_default">Auto-generated Next Button</SelectItem>
              {getButtonElements().map(el => (
                <SelectItem key={el.id} value={el.id}>
                  {(el.config as ButtonConfig).label || 'Unnamed Button'}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label>Default Next Node</Label>
          <Select
            value={localConfig.defaultNextNodeId || '_none'}
            onValueChange={handleDefaultNodeChange}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select default next node" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="_none">None</SelectItem>
              {connectedNodes.map((node) => (
                <SelectItem key={node.id} value={node.id}>
                  {node.data.config.title || 'Untitled Node'}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
}; 