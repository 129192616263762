import { useState } from 'react'
import { 
  ShoppingBag, 
  DollarSign, 
  Hash, 
  Calendar, 
  Clock,
  Loader2
} from 'lucide-react'

import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer"
import { ScrollArea } from "@/components/ui/scroll-area"
import {
  Card,
  CardContent,
} from "@/components/ui/card"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"

import type { Product } from '@/types/customers'

interface ProductsDrawerProps {
  visible: boolean
  onClose: () => void
  products: Product[]
}

export function ProductsDrawer({ visible, onClose, products }: ProductsDrawerProps) {
  const [loading] = useState(false)

  const renderProductDetails = (product: Product) => (
    <Card key={product.id} className="mb-4">
      <CardContent className="pt-6 space-y-3">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <h3 className="font-semibold text-lg truncate">
                {product.name}
              </h3>
            </TooltipTrigger>
            <TooltipContent>
              <p>Product Name: {product.name}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <div className="grid grid-cols-1 gap-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <ShoppingBag className="h-4 w-4" />
                  <span>Code: {product.code}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Product Code: {product.code}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <DollarSign className="h-4 w-4" />
                  <span>Price: {product.item_price}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Price: {product.item_price}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <Hash className="h-4 w-4" />
                  <span>Qty: {product.quantity}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Quantity: {product.quantity}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <DollarSign className="h-4 w-4" />
                  <span>Sum: {product.item_price * product.quantity}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Sum: {product.item_price * product.quantity}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <Calendar className="h-4 w-4" />
                  <span>Start: {product.billing_start_date || 'N/A'}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Billing Start Date: {product.billing_start_date || 'N/A'}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <Clock className="h-4 w-4" />
                  <span>Freq: {product.billing_frequency}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Billing Frequency: {product.billing_frequency}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </CardContent>
    </Card>
  )

  return (
    <Drawer open={visible} onOpenChange={onClose}>
      <DrawerContent className="h-[90vh]">
        <DrawerHeader>
          <DrawerTitle>Products Details</DrawerTitle>
        </DrawerHeader>

        {loading ? (
          <div className="flex-1 flex justify-center items-center">
            <Loader2 className="h-8 w-8 animate-spin" />
          </div>
        ) : (
          <ScrollArea className="flex-1 px-6">
            <div className="pb-6">
              {products && products.length > 0 ? (
                products.map(renderProductDetails)
              ) : (
                <div className="text-center py-8 text-muted-foreground">
                  No products found
                </div>
              )}
            </div>
          </ScrollArea>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default ProductsDrawer 