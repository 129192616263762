import { Deal } from '@/types/customers';

interface Contact {
  name: string;
  email: string;
  deals?: Deal[];
}

export interface EmailVariable {
  key: string;
  label: string;
  description: string;
  getValue: (contact: Contact, deal?: Deal) => string;
}

export const emailVariables: EmailVariable[] = [
  {
    key: 'contact_name',
    label: 'Contact Name',
    description: 'The name of the contact',
    getValue: (contact) => contact?.name || '',
  },
  {
    key: 'deal_name',
    label: 'Deal Name',
    description: 'The name of the primary deal',
    getValue: (contact, deal) => deal?.name || '',
  },
];