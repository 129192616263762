import React, { useState, useEffect, useMemo } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";
import { toast } from "sonner";
import { Info, AlertTriangle, Check, ChevronsUpDown, Trash2 } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import axios from '../../api/axiosConfig';
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import { cn } from "@/lib/utils"
import { buttonVariants } from "@/components/ui/button"

export interface Deal {
  id: string;
  name: string;
  cvr: string;
  source: string;
  is_new_deal: boolean;
  data: {
    title: string;
    value: number;
    currency: string;
    deal_source?: string;
    virksomhedsform?: string;
  };
  recommendations?: {
    recommendations?: Record<string, any>;
    alerts?: string[];
  };
}

interface Account {
  id: string;
  company_name: string;
  cvr: string;
}

interface ImportDealsProps {
  visible: boolean;
  onClose: () => void;
  pendingDeals: Deal[];
  onImportSuccess: (dealIds: string | string[]) => void;
  onShowDealDetails: (deal: Deal) => void;
}

const ImportDeals: React.FC<ImportDealsProps> = ({
  visible,
  onClose,
  pendingDeals,
  onImportSuccess,
  onShowDealDetails
}) => {
  const [selectedDeals, setSelectedDeals] = useState<string[]>([]);
  const [importing, setImporting] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccountIds, setSelectedAccountIds] = useState<Record<string, string>>({});
  const [activeTab, setActiveTab] = useState('new');
  const [showAccountSelector, setShowAccountSelector] = useState<Record<string, boolean>>({});
  const [alertDialogOpen, setAlertDialogOpen] = useState(false)
  const [selectedDealAlerts, setSelectedDealAlerts] = useState<string[]>([])
  const [dealToDelete, setDealToDelete] = useState<string | null>(null)

  useEffect(() => {
    if (visible) {
      fetchAccounts();
    }
  }, [visible]);

  useEffect(() => {
    console.log('pendingDeals in ImportDeals:', pendingDeals);
  }, [pendingDeals]);

  const fetchAccounts = async () => {
    try {
      const response = await axios.get('/account/accounts');
      setAccounts(response.data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const isCrossSelling = (deal: Deal) => {
    return deal.data?.deal_source === 'Cross selling';
  };

  const handleDealSelection = (dealId: string) => {
    const deal = pendingDeals.find(d => d.id === dealId);
    if (deal && isCrossSelling(deal)) {
      toast.warning('Cross selling deals must be imported individually');
      return;
    }
    setSelectedDeals(prevSelected => 
      prevSelected.includes(dealId)
        ? prevSelected.filter(id => id !== dealId)
        : [...prevSelected, dealId]
    );
  };

  const handleAccountSelection = (dealId: string, accountId: string) => {
    setSelectedAccountIds(prevState => ({
      ...prevState,
      [dealId]: accountId
    }));
  };

  const toggleAccountSelector = (dealId: string) => {
    setShowAccountSelector(prevState => ({
      ...prevState,
      [dealId]: !prevState[dealId]
    }));
  };

  const handleImportDeal = async (dealId: string) => {
    const deal = pendingDeals.find(d => d.id === dealId);
    
    if (deal && isCrossSelling(deal) && !selectedAccountIds[dealId]) {
      toast.error('Please select an existing account for Cross selling deals');
      return;
    }

    setImporting(true);
    try {
      console.log('Importing deal with ID:', dealId);
      
      const response = await axios.post(`/account/import-deal/${dealId}`, {
        accountId: selectedAccountIds[dealId]
      });
      
      console.log('Import response:', response.data);
      
      if (response.data.deal_id) {
        onImportSuccess(response.data.deal_id.toString());
      } else {
        onImportSuccess(dealId);
      }
      
      const remainingDealsInCurrentTab = pendingDeals.filter(d => 
        d.id !== dealId && (activeTab === 'new' ? d.is_new_deal : !d.is_new_deal)
      );
      
      if (remainingDealsInCurrentTab.length === 0) {
        onClose();
      }
      
      toast.success('Deal imported successfully');
    } catch (error) {
      console.error('Error importing deal:', error);
      toast.error('Failed to import deal');
    } finally {
      setImporting(false);
    }
  };

  const handleImportAllDeals = async () => {
    const crossSellingDeals = pendingDeals.filter(deal => isCrossSelling(deal));
    if (crossSellingDeals.length > 0) {
      toast.error('Cannot bulk import Cross selling deals');
      return;
    }

    setImporting(true);
    try {
      await axios.post('/account/import-all-deals');
      toast.success('All deals imported successfully');
      onImportSuccess(pendingDeals.map(deal => deal.id));
      onClose();
    } catch (error) {
      console.error('Error importing all deals:', error);
      toast.error('Failed to import all deals');
    } finally {
      setImporting(false);
    }
  };

  const handleImportSelected = async () => {
    setImporting(true);
    try {
      await Promise.all(selectedDeals.map(dealId => 
        axios.post(`/account/import-deal/${dealId}`, {
          accountId: selectedAccountIds[dealId]
        })
      ));
      toast.success('Selected deals imported successfully');
      onImportSuccess(selectedDeals);
      setSelectedDeals([]);
      
      const remainingDealsInCurrentTab = pendingDeals.filter(deal => 
        !selectedDeals.includes(deal.id) && 
        (activeTab === 'new' ? deal.is_new_deal : !deal.is_new_deal)
      );
      
      if (remainingDealsInCurrentTab.length === 0) {
        onClose();
      }
    } catch (error) {
      console.error('Error importing selected deals:', error);
      toast.error('Failed to import selected deals');
    } finally {
      setImporting(false);
    }
  };

  const handleDeleteDeal = async (dealId: string) => {
    try {
      await axios.delete(`/account/pending-deals/${dealId}`)
      toast.success('Deal removed successfully')
      onImportSuccess(dealId)
    } catch (error) {
      console.error('Error deleting deal:', error)
      toast.error('Failed to remove deal')
    }
  }

  const newDeals = useMemo(() => 
    pendingDeals.filter(deal => deal.is_new_deal),
    [pendingDeals]
  );

  const migrationDeals = useMemo(() => 
    pendingDeals.filter(deal => !deal.is_new_deal),
    [pendingDeals]
  );

  const AccountSelector: React.FC<{
    deal: Deal;
    accounts: Account[];
    selectedAccountIds: Record<string, string>;
    onAccountSelect: (dealId: string, accountId: string) => void;
    visible: boolean;
  }> = ({ deal, accounts, selectedAccountIds, onAccountSelect, visible }) => {
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    if (!visible) return null;

    return (
      <div className="p-2 mb-4 bg-background rounded-md">
        <div className="flex items-center gap-2">
          <Info className="h-4 w-4 text-muted-foreground" />
          <span>
            Select an existing account to link this deal to:
          </span>
        </div>
        
        <div className="relative mt-2">
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
            onClick={() => setOpen(!open)}
          >
            {selectedAccountIds[deal.id] ? 
              accounts.find(account => account.id === selectedAccountIds[deal.id])?.company_name 
              : "Search and select an existing account"}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
          
          {open && (
            <div className="absolute top-full w-full z-50 mt-2 rounded-md border bg-popover text-popover-foreground shadow-md">
              <Command>
                <CommandInput 
                  placeholder="Search accounts..." 
                  value={searchValue}
                  onValueChange={setSearchValue}
                />
                <CommandList>
                  <CommandEmpty>No accounts found.</CommandEmpty>
                  <CommandGroup>
                    {accounts
                      .filter(account => 
                        account.company_name.toLowerCase().includes(searchValue.toLowerCase()) ||
                        account.cvr.includes(searchValue)
                      )
                      .map(account => (
                        <CommandItem
                          key={account.id}
                          value={account.id}
                          onSelect={() => {
                            onAccountSelect(deal.id, account.id);
                            setOpen(false);
                            setSearchValue("");
                          }}
                        >
                          <div className="flex flex-col">
                            <span>{account.company_name}</span>
                            <span className="text-sm text-muted-foreground">
                              CVR: {account.cvr}
                            </span>
                          </div>
                          {selectedAccountIds[deal.id] === account.id && (
                            <Check className="ml-auto h-4 w-4" />
                          )}
                        </CommandItem>
                      ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getVirksomhedsformColor = (virksomhedsform: string): string => {
    const colorMap: Record<string, string> = {
      'Enkeltmandsvirksomhed': 'bg-blue-500',
      'Holding': 'bg-green-500',
      'Anpartsselskab': 'bg-yellow-500',
      'Aktieselskab': 'bg-red-500',
      'Interessentskab': 'bg-cyan-500',
      'Forening': 'bg-purple-500',
      'Personligt ejet mindre virksomhed': 'bg-pink-500',
      'Partnerskab': 'bg-indigo-500',
      'Kommanditselskab': 'bg-lime-500',
      'Fond': 'bg-orange-500'
    };
    return colorMap[virksomhedsform] || 'bg-gray-500';
  };

  const getBadgeVariant = (deal: Deal) => {
    if (isCrossSelling(deal)) return "warning";
    if (deal.data?.deal_source === 'Upsell') return "outline";
    return "success";
  };

  const renderDeals = (deals: Deal[]) => (
    <div className="space-y-4">
      {deals.map(deal => (
        <Card key={deal.id} className="w-full">
          <CardHeader className="pb-2">
            <div className="flex justify-between items-center flex-wrap gap-2">
              <h3 className="text-lg font-semibold break-words flex-grow">
                {deal.data.title}
              </h3>
              <div className="flex gap-2 flex-wrap">
                {deal.recommendations?.recommendations && 
                  Object.keys(deal.recommendations.recommendations).length > 0 && (
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Info className="h-5 w-5 text-warning" />
                      </TooltipTrigger>
                      <TooltipContent>
                        This deal has recommendations
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
                {deal.recommendations?.alerts && deal.recommendations.alerts.length > 0 && (
                  <>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            className="h-5 w-5 p-0"
                            onClick={(e) => {
                              e.stopPropagation()
                              setSelectedDealAlerts(deal.recommendations!.alerts!)
                              setAlertDialogOpen(true)
                            }}
                          >
                            <AlertTriangle className="h-5 w-5 text-destructive" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          This deal has {deal.recommendations.alerts.length} alert{deal.recommendations.alerts.length > 1 ? 's' : ''}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>

                    <AlertDialog open={alertDialogOpen} onOpenChange={setAlertDialogOpen}>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>Deal Alerts</AlertDialogTitle>
                          <AlertDialogDescription className="space-y-2">
                            {selectedDealAlerts.map((alert, index) => (
                              <div 
                                key={index}
                                className="flex items-start gap-2 p-2 rounded-md bg-destructive/10"
                              >
                                <AlertTriangle className="h-5 w-5 text-destructive flex-shrink-0 mt-0.5" />
                                <span>{alert}</span>
                              </div>
                            ))}
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Close</AlertDialogCancel>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </>
                )}
                <Badge variant={getBadgeVariant(deal)}>
                  {deal.data.deal_source || 'N/A'}
                </Badge>
                {deal.data.virksomhedsform && (
                  <Badge className={getVirksomhedsformColor(deal.data.virksomhedsform)}>
                    {deal.data.virksomhedsform}
                  </Badge>
                )}
              </div>
            </div>
          </CardHeader>
          <CardContent className="pt-2">
            <div className="space-y-2">
              <p className="text-muted-foreground">Organization: {deal.name}</p>
              <p className="text-muted-foreground">CVR: {deal.cvr}</p>
              <p className="text-muted-foreground">
                Value: {Number(deal.data.value).toLocaleString()} {deal.data.currency}
              </p>
              <p className="text-muted-foreground">
                Source: {deal.source === 'make' ? 'Pipedrive -> Make' : deal.source}
              </p>
              
              {!isCrossSelling(deal) && (
                <>
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id={`deal-${deal.id}`}
                      checked={selectedDeals.includes(deal.id)}
                      onCheckedChange={() => handleDealSelection(deal.id)}
                    />
                    <label htmlFor={`deal-${deal.id}`}>Select for import</label>
                  </div>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => toggleAccountSelector(deal.id)}
                    className="mt-2"
                  >
                    {showAccountSelector[deal.id] ? 'Hide Account Selector' : 'Link to Existing Account'}
                  </Button>
                </>
              )}

              <AccountSelector
                deal={deal}
                accounts={accounts}
                selectedAccountIds={selectedAccountIds}
                onAccountSelect={handleAccountSelection}
                visible={isCrossSelling(deal) || showAccountSelector[deal.id]}
              />

              <div className="flex gap-2 mt-4">
                <Button
                  onClick={() => handleImportDeal(deal.id)}
                  disabled={isCrossSelling(deal) && !selectedAccountIds[deal.id] || importing}
                >
                  Import
                </Button>
                <Button variant="outline" onClick={() => onShowDealDetails(deal)}>
                  View Details
                </Button>
                <Button
                  variant="destructive"
                  size="icon"
                  onClick={() => setDealToDelete(deal.id)}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );

  return (
    <Dialog open={visible} onOpenChange={onClose}>
      <DialogContent className="max-w-[800px] w-[90vw] h-[85vh] flex flex-col p-0">
        <DialogHeader className="p-6 pb-2">
          <DialogTitle>Import Deals</DialogTitle>
          <DialogDescription>
            Import new deals or migrate existing deals from other systems.
          </DialogDescription>
        </DialogHeader>

        <Tabs value={activeTab} onValueChange={setActiveTab} className="flex-1 flex flex-col min-h-0">
          <div className="px-6 mb-4">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="new" className="flex items-center gap-2">
                <span>New Deals</span>
                {newDeals.length > 0 && (
                  <Badge 
                    variant="secondary" 
                    className="min-h-[20px] max-h-[20px] px-1.5 py-0"
                  >
                    {newDeals.length}
                  </Badge>
                )}
              </TabsTrigger>
              <TabsTrigger value="migrate" className="flex items-center gap-2">
                <span>Migrate Deals</span>
                {migrationDeals.length > 0 && (
                  <Badge 
                    variant="secondary" 
                    className="min-h-[20px] max-h-[20px] px-1.5 py-0"
                  >
                    {migrationDeals.length}
                  </Badge>
                )}
              </TabsTrigger>
            </TabsList>
          </div>

          <div className="flex-1 min-h-0">
            <ScrollArea className="h-[calc(85vh-12rem)]">
              <div className="px-6">
                <TabsContent value="new" className="mt-2">
                  {renderDeals(newDeals)}
                </TabsContent>
                <TabsContent value="migrate" className="mt-2">
                  {renderDeals(migrationDeals)}
                </TabsContent>
              </div>
            </ScrollArea>
          </div>
        </Tabs>

        <div className="mt-auto border-t p-4 flex justify-between items-center">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <div className="flex gap-2">
            {activeTab === 'new' && (
              <>
                <Button
                  onClick={handleImportAllDeals}
                  disabled={importing}
                >
                  Import All New Deals
                </Button>
                {selectedDeals.length > 0 && (
                  <Button
                    onClick={handleImportSelected}
                    disabled={importing}
                  >
                    Import Selected ({selectedDeals.length})
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </DialogContent>
      <AlertDialog open={!!dealToDelete} onOpenChange={() => setDealToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently remove the pending deal. This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              className={cn(buttonVariants({ variant: "destructive" }))}
              onClick={() => {
                if (dealToDelete) {
                  handleDeleteDeal(dealToDelete);
                  setDealToDelete(null);
                }
              }}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Dialog>
  );
};

export default ImportDeals;