import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Task } from '@/types/task';
import { cn } from "@/lib/utils";

interface SortableTaskCardProps {
  id: string;
  task: Task;
  renderContent: (task: Task) => React.ReactNode;
}

export function SortableTaskCard({ id, task, renderContent }: SortableTaskCardProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: id,
    data: {
      type: 'task',
      task: task
    }
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : undefined,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={cn(
        "mb-2",
        isDragging && "cursor-grabbing"
      )}
    >
      {renderContent(task)}
    </div>
  );
}