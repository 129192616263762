import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "sonner";
import axios from '@/api/axiosConfig';
import { LoadingSpinner } from '@/components/ui/loading-spinner';
import { Plus, Trash2, Edit2, Play, RotateCcw, X } from 'lucide-react';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Badge } from "@/components/ui/badge";

interface OnboardingEndpoint {
  id: number;
  name: string;
  endpoint: string;
  method: string;
  description: string;
  required_fields: Record<string, any>;
}

interface AdminFlowProps {
  activeTab?: string;
}

export default function AdminFlow({ activeTab = 'endpoints' }: AdminFlowProps) {
  const [endpoints, setEndpoints] = useState<OnboardingEndpoint[]>([]);
  const [loading, setLoading] = useState(true);
  const [editingEndpoint, setEditingEndpoint] = useState<OnboardingEndpoint | null>(null);
  const [testingEndpoint, setTestingEndpoint] = useState<OnboardingEndpoint | null>(null);
  const [testPayload, setTestPayload] = useState<Record<string, any>>({});
  const [testLoading, setTestLoading] = useState(false);
  const [newEndpoint, setNewEndpoint] = useState<Partial<OnboardingEndpoint>>({
    name: '',
    endpoint: '',
    method: 'GET',
    description: '',
    required_fields: {
      payload: {
        type: 'deal',  // can be 'deal', 'form', or 'static'
        fields: []     // list of required field names
      }
    }
  });

  useEffect(() => {
    fetchEndpoints();
  }, []);

  const fetchEndpoints = async () => {
    try {
      const response = await axios.get('/admin/onboarding-endpoints');
      setEndpoints(response.data);
    } catch (error) {
      toast.error('Failed to fetch endpoints');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveEndpoint = async () => {
    try {
      if (editingEndpoint) {
        await axios.put(`/admin/onboarding-endpoints/${editingEndpoint.id}`, editingEndpoint);
        toast.success('Endpoint updated successfully');
      } else {
        await axios.post('/admin/onboarding-endpoints', newEndpoint);
        toast.success('Endpoint created successfully');
        setNewEndpoint({
          name: '',
          endpoint: '',
          method: 'GET',
          description: '',
          required_fields: {
            payload: {
              type: 'deal',  // can be 'deal', 'form', or 'static'
              fields: []     // list of required field names
            }
          }
        });
      }
      fetchEndpoints();
      setEditingEndpoint(null);
    } catch (error) {
      toast.error('Failed to save endpoint');
    }
  };

  const handleDeleteEndpoint = async (id: number) => {
    try {
      await axios.delete(`/admin/onboarding-endpoints/${id}`);
      toast.success('Endpoint deleted successfully');
      fetchEndpoints();
    } catch (error) {
      toast.error('Failed to delete endpoint');
    }
  };

  const handleTestEndpoint = async (endpoint: OnboardingEndpoint) => {
    setTestingEndpoint(endpoint);
    setTestPayload({});
  };

  const handleRunTest = async () => {
    if (!testingEndpoint) return;
    
    setTestLoading(true);
    try {
      const response = await axios.post(
        `/admin/onboarding-endpoints/${testingEndpoint.id}/test`,
        testPayload
      );
      toast.success('Test completed successfully');
      console.log('Test result:', response.data);
    } catch (error) {
      toast.error('Test failed: ' + (error as any)?.response?.data?.error || 'Unknown error');
    } finally {
      setTestLoading(false);
    }
  };

  const handleResetTestDeal = async () => {
    try {
      await axios.post('/admin/test/reset-onboarding-deal');
      toast.success('Test deal reset successfully');
      
      // Open the onboarding page in a new window
      window.open('/customers/onboarding/cases', '_blank');
    } catch (error) {
      toast.error('Failed to reset test deal: ' + (error as any)?.response?.data?.error || 'Unknown error');
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="space-y-6">
      {/* Testing Tools Card */}
      <Card>
        <CardHeader>
          <CardTitle>Testing Tools</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex items-center justify-between border p-4 rounded-lg">
              <div>
                <h3 className="font-semibold">Test Deal (CVR: 44658054)</h3>
                <p className="text-sm text-muted-foreground">
                  Reset the onboarding status and start a new onboarding flow
                </p>
              </div>
              <Button
                onClick={handleResetTestDeal}
                variant="outline"
                className="space-x-2"
              >
                <RotateCcw className="h-4 w-4" />
                <span>Reset & Start New</span>
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Onboarding Flow API Endpoints</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            {/* Add New Endpoint Form */}
            <div className="border p-4 rounded-lg space-y-4">
              <h3 className="text-lg font-semibold">
                {editingEndpoint ? 'Edit Endpoint' : 'Add New Endpoint'}
              </h3>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label>Name</Label>
                  <Input
                    value={editingEndpoint?.name || newEndpoint.name}
                    onChange={(e) => editingEndpoint 
                      ? setEditingEndpoint({ ...editingEndpoint, name: e.target.value })
                      : setNewEndpoint({ ...newEndpoint, name: e.target.value })
                    }
                    placeholder="Enter endpoint name"
                  />
                </div>
                <div className="space-y-2">
                  <Label>Endpoint Path</Label>
                  <Input
                    value={editingEndpoint?.endpoint || newEndpoint.endpoint}
                    onChange={(e) => editingEndpoint
                      ? setEditingEndpoint({ ...editingEndpoint, endpoint: e.target.value })
                      : setNewEndpoint({ ...newEndpoint, endpoint: e.target.value })
                    }
                    placeholder="/api/endpoint"
                  />
                </div>
                <div className="space-y-2">
                  <Label>Method</Label>
                  <Select
                    value={editingEndpoint?.method || newEndpoint.method}
                    onValueChange={(value) => editingEndpoint
                      ? setEditingEndpoint({ ...editingEndpoint, method: value })
                      : setNewEndpoint({ ...newEndpoint, method: value })
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select method" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="GET">GET</SelectItem>
                      <SelectItem value="POST">POST</SelectItem>
                      <SelectItem value="PUT">PUT</SelectItem>
                      <SelectItem value="DELETE">DELETE</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="space-y-2">
                  <Label>Required Fields</Label>
                  <div className="space-y-4 border p-4 rounded-lg">
                    <div className="space-y-2">
                      <Label>Payload Type</Label>
                      <Select
                        value={editingEndpoint?.required_fields?.payload?.type || newEndpoint.required_fields?.payload?.type}
                        onValueChange={(value) => {
                          const updatedFields = {
                            payload: {
                              type: value,
                              fields: editingEndpoint?.required_fields?.payload?.fields || 
                                     newEndpoint.required_fields?.payload?.fields || []
                            }
                          };
                          if (editingEndpoint) {
                            setEditingEndpoint({ 
                              ...editingEndpoint, 
                              required_fields: updatedFields 
                            });
                          } else {
                            setNewEndpoint({ 
                              ...newEndpoint, 
                              required_fields: updatedFields 
                            });
                          }
                        }}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select payload type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="deal">Deal Data</SelectItem>
                          <SelectItem value="form">Form Data</SelectItem>
                          <SelectItem value="static">Static Data</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="space-y-2">
                      <Label>Required Fields</Label>
                      <Input
                        value={(editingEndpoint?.required_fields?.payload?.currentInput || 
                               newEndpoint.required_fields?.payload?.currentInput || '')}
                        onChange={(e) => {
                          const updatedFields = {
                            payload: {
                              type: editingEndpoint?.required_fields?.payload?.type || 
                                    newEndpoint.required_fields?.payload?.type,
                              fields: editingEndpoint?.required_fields?.payload?.fields || 
                                     newEndpoint.required_fields?.payload?.fields || [],
                              currentInput: e.target.value
                            }
                          };
                          
                          if (editingEndpoint) {
                            setEditingEndpoint({ 
                              ...editingEndpoint, 
                              required_fields: updatedFields 
                            });
                          } else {
                            setNewEndpoint({ 
                              ...newEndpoint, 
                              required_fields: updatedFields 
                            });
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ',') {
                            e.preventDefault();
                            const currentInput = (editingEndpoint?.required_fields?.payload?.currentInput || 
                                                newEndpoint.required_fields?.payload?.currentInput || '').trim();
                            
                            if (currentInput) {
                              const currentFields = editingEndpoint?.required_fields?.payload?.fields || 
                                                  newEndpoint.required_fields?.payload?.fields || [];
                              
                              if (!currentFields.includes(currentInput)) {
                                const updatedFields = {
                                  payload: {
                                    type: editingEndpoint?.required_fields?.payload?.type || 
                                          newEndpoint.required_fields?.payload?.type,
                                    fields: [...currentFields, currentInput],
                                    currentInput: ''
                                  }
                                };
                                
                                if (editingEndpoint) {
                                  setEditingEndpoint({ 
                                    ...editingEndpoint, 
                                    required_fields: updatedFields 
                                  });
                                } else {
                                  setNewEndpoint({ 
                                    ...newEndpoint, 
                                    required_fields: updatedFields 
                                  });
                                }
                              }
                            }
                          }
                        }}
                        placeholder="Type a field name and press Enter or comma to add"
                      />
                      <div className="flex flex-wrap gap-2 mt-2">
                        {(editingEndpoint?.required_fields?.payload?.fields || 
                          newEndpoint.required_fields?.payload?.fields || []).map((field: string) => (
                          <Badge
                            key={field}
                            variant="secondary"
                            className="flex items-center gap-1"
                          >
                            {field}
                            <X
                              className="h-3 w-3 cursor-pointer"
                              onClick={() => {
                                const currentFields = editingEndpoint?.required_fields?.payload?.fields || 
                                                    newEndpoint.required_fields?.payload?.fields || [];
                                const updatedFields = {
                                  payload: {
                                    type: editingEndpoint?.required_fields?.payload?.type || 
                                          newEndpoint.required_fields?.payload?.type,
                                    fields: currentFields.filter((f: string) => f !== field),
                                    currentInput: editingEndpoint?.required_fields?.payload?.currentInput || 
                                                newEndpoint.required_fields?.payload?.currentInput || ''
                                  }
                                };
                                
                                if (editingEndpoint) {
                                  setEditingEndpoint({ 
                                    ...editingEndpoint, 
                                    required_fields: updatedFields 
                                  });
                                } else {
                                  setNewEndpoint({ 
                                    ...newEndpoint, 
                                    required_fields: updatedFields 
                                  });
                                }
                              }}
                            />
                          </Badge>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-2">
                <Label>Description</Label>
                <Textarea
                  value={editingEndpoint?.description || newEndpoint.description}
                  onChange={(e) => editingEndpoint
                    ? setEditingEndpoint({ ...editingEndpoint, description: e.target.value })
                    : setNewEndpoint({ ...newEndpoint, description: e.target.value })
                  }
                  placeholder="Enter endpoint description"
                />
              </div>
              <div className="flex justify-end gap-2">
                {editingEndpoint && (
                  <Button
                    variant="outline"
                    onClick={() => setEditingEndpoint(null)}
                  >
                    Cancel
                  </Button>
                )}
                <Button onClick={handleSaveEndpoint}>
                  {editingEndpoint ? 'Update Endpoint' : 'Add Endpoint'}
                </Button>
              </div>
            </div>

            {/* Endpoints List */}
            <div className="space-y-4">
              {endpoints.map((endpoint) => (
                <div
                  key={endpoint.id}
                  className="border p-4 rounded-lg space-y-2"
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h4 className="font-semibold">{endpoint.name}</h4>
                      <p className="text-sm text-muted-foreground">{endpoint.endpoint}</p>
                    </div>
                    <div className="flex gap-2">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleTestEndpoint(endpoint)}
                      >
                        <Play className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => setEditingEndpoint(endpoint)}
                      >
                        <Edit2 className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleDeleteEndpoint(endpoint.id)}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 text-sm">
                    <div>
                      <span className="font-medium">Method:</span> {endpoint.method}
                    </div>
                    <div>
                      <span className="font-medium">Required Fields:</span>{' '}
                      {Object.keys(endpoint.required_fields).join(', ')}
                    </div>
                  </div>
                  {endpoint.description && (
                    <p className="text-sm text-muted-foreground">
                      {endpoint.description}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Test Endpoint Dialog */}
      <Dialog open={!!testingEndpoint} onOpenChange={() => setTestingEndpoint(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Test Endpoint: {testingEndpoint?.name}</DialogTitle>
            <DialogDescription className="sr-only">
              Test an onboarding endpoint with custom payload
            </DialogDescription>
          </DialogHeader>

          {testingEndpoint && (
            <div className="space-y-4">
              <div>
                <Label>Endpoint</Label>
                <p className="text-sm text-muted-foreground">{testingEndpoint.endpoint}</p>
              </div>

              {testingEndpoint.required_fields?.payload?.fields?.length > 0 && (
                <div className="space-y-4">
                  <Label>Required Fields</Label>
                  {testingEndpoint.required_fields.payload.fields.map((field: string) => (
                    <div key={field} className="space-y-2">
                      <Label>{field}</Label>
                      <Input
                        value={testPayload[field] || ''}
                        onChange={(e) => setTestPayload({
                          ...testPayload,
                          [field]: e.target.value
                        })}
                        placeholder={`Enter ${field}`}
                      />
                    </div>
                  ))}
                </div>
              )}

              <div className="flex justify-end gap-2">
                <Button
                  variant="outline"
                  onClick={() => setTestingEndpoint(null)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleRunTest}
                  disabled={testLoading}
                >
                  {testLoading ? <LoadingSpinner /> : 'Run Test'}
                </Button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
} 