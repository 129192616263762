import { useCallback, useState, useEffect } from 'react';
import ReactFlow, {
  Background,
  Controls,
  Panel,
  useNodesState,
  useEdgesState,
  Connection,
  Edge,
  addEdge,
  Node,
  XYPosition,
  ReactFlowProvider
} from 'reactflow';
import { Button } from "@/components/ui/button";
import { Plus, Save } from "lucide-react";
import { FlowNode, FlowEdge, NodeType } from '@/onboardingflow/types/flows';
import 'reactflow/dist/style.css';
import { NodeConfigDialog } from './components/node-config/NodeConfigDialog';
import FormNode from './components/FormNode';
import { nanoid } from 'nanoid';
import { NodeConfig, NodeElement, RowSection } from './types/node_types';
import axios from '@/api/axiosConfig';
import { OnboardingEndpoint } from './types/endpoints';

const nodeTypes = {
  form: FormNode,
};

export interface FlowEditorProps {
  initialNodes?: FlowNode[];
  initialEdges?: FlowEdge[];
  onSave?: (nodes: FlowNode[], edges: FlowEdge[]) => void;
  readOnly?: boolean;
  startNodeId?: string;
}

export function FlowEditor({
  initialNodes = [],
  initialEdges = [],
  onSave,
  readOnly = false,
  startNodeId,
}: FlowEditorProps) {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [configDialogOpen, setConfigDialogOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState<FlowNode | null>(null);
  const [availableEndpoints, setAvailableEndpoints] = useState<OnboardingEndpoint[]>([]);

  const onConnect = useCallback(
    (params: Connection | Edge) => setEdges((prevEdges) => addEdge(params, prevEdges)),
    [setEdges],
  );

  const handleNodeClick = (event: React.MouseEvent, node: Node) => {
    if (node.type === 'form') {
      setSelectedNode(node as FlowNode);
      setConfigDialogOpen(true);
    }
  };

  const handleAddNode = () => {
    const newNode: FlowNode = {
      id: crypto.randomUUID(),
      type: 'form' as NodeType,
      position: { x: 100, y: 100 },
      data: {
        label: 'New Node',
        config: {
          id: nanoid(),
          title: 'New Node',
          rows: [],
          rules: [],
          defaultNextNodeId: undefined
        }
      }
    };
    setNodes(nodes => [...nodes, newNode]);
  };

  const handleDeleteNode = useCallback(() => {
    if (!selectedNode) return;
    setNodes(nodes => nodes.filter(n => n.id !== selectedNode.id));
    setEdges(edges => edges.filter(e => e.source !== selectedNode.id && e.target !== selectedNode.id));
    setConfigDialogOpen(false);
    setSelectedNode(null);
  }, [selectedNode]);

  const handleDuplicateNode = useCallback(() => {
    if (!selectedNode) return;
    const newNodeId = crypto.randomUUID();
    const newNode: FlowNode = {
      ...selectedNode,
      id: newNodeId,
      type: selectedNode.type as NodeType,
      position: {
        x: selectedNode.position.x + 50,
        y: selectedNode.position.y + 50
      },
      data: {
        ...selectedNode.data,
        label: `${selectedNode.data.label} (Copy)`,
        config: {
          ...selectedNode.data.config,
          id: crypto.randomUUID(),
          title: `${selectedNode.data.config.title} (Copy)`,
          header: selectedNode.data.config.header 
            ? { ...selectedNode.data.config.header, id: crypto.randomUUID() }
            : undefined,
          rows: selectedNode.data.config.rows.map((row: RowSection) => ({
            ...row,
            id: crypto.randomUUID(),
            elements: row.elements.map((el: NodeElement) => ({
              ...el,
              id: crypto.randomUUID()
            }))
          }))
        }
      }
    };
    setNodes(nodes => [...nodes, newNode]);
    setConfigDialogOpen(false);
  }, [selectedNode]);

  const handleNodeConfigChange = useCallback((config: NodeConfig) => {
    if (selectedNode) {
      setNodes(nodes.map(node =>
        node.id === selectedNode.id
          ? { ...node, data: { ...node.data, config } }
          : node
      ));
    }
    setConfigDialogOpen(false);
  }, [selectedNode]);

  useEffect(() => {
    if (startNodeId) {
      setNodes(nodes => nodes.map(node => ({
        ...node,
        className: node.id === startNodeId ? 'border-primary border-2' : undefined
      })));
    }
  }, [startNodeId, setNodes]);

  useEffect(() => {
    const fetchEndpoints = async () => {
      try {
        const response = await axios.get<{endpoints: OnboardingEndpoint[]}>('/onboardingflow/available-endpoints');
        
        // Access the endpoints array from the nested structure
        const endpoints = response.data.endpoints;
        if (Array.isArray(endpoints)) {
          setAvailableEndpoints(endpoints.map(endpoint => ({
            ...endpoint,
            method: endpoint.method as OnboardingEndpoint['method']
          })));
        } else {
          console.error('Endpoints is not an array:', endpoints);
          setAvailableEndpoints([]);
        }
      } catch (error) {
        console.error('Failed to fetch endpoints:', error);
      }
    };
    fetchEndpoints();
  }, []);

  const createNewNode = (position: XYPosition) => {
    const newNode: FlowNode = {
      id: nanoid(),
      type: 'form' as NodeType,
      position,
      data: {
        label: 'New Node',
        config: {
          id: nanoid(),
          title: 'New Node',
          rows: [],
          rules: [],
          defaultNextNodeId: undefined
        }
      }
    };
    return newNode;
  };

  return (
    <>
      <div className="h-screen w-full border rounded-lg bg-background">
        <ReactFlow
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeClick={handleNodeClick}
          fitView
          className="bg-background"
        >
          <Background className="bg-muted" />
          <Controls className="bg-background border rounded-md" />
          
          <Panel position="top-right" className="space-x-2">
            {!readOnly && (
              <>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={handleAddNode}
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Node
                </Button>
                <Button
                  variant="default"
                  size="sm"
                  onClick={() => onSave?.(nodes.map(node => ({
                    ...node,
                    type: node.type as NodeType
                  })) as FlowNode[], edges as FlowEdge[])}
                >
                  <Save className="h-4 w-4 mr-2" />
                  Save Flow
                </Button>
              </>
            )}
          </Panel>
        </ReactFlow>
      </div>

      <ReactFlowProvider>
        {selectedNode && (
          <NodeConfigDialog
            open={configDialogOpen}
            onOpenChange={setConfigDialogOpen}
            config={selectedNode.data.config}
            onChange={handleNodeConfigChange}
            nodes={nodes as FlowNode[]}
            edges={edges}
            availableEndpoints={availableEndpoints}
            nodeId={selectedNode.id}
            onDelete={handleDeleteNode}
            onDuplicate={handleDuplicateNode}
          />
        )}
      </ReactFlowProvider>
    </>
  );
}