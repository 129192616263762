import React, { useState, useEffect } from 'react';
import './index.css';  // Tailwind styles
import './AntDesignOverrides.css';
import './styles/globals.css';
import { RouterProvider, createBrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { ConfigProvider, theme, App as AntApp } from 'antd';
import { MessageProvider } from './contexts/MessageContext';
import { TimeTrackerProvider } from './contexts/TimeTrackerContext';
import axios from './api/axiosConfig';
import { isAxiosError } from 'axios';
import { hasRequiredPermission } from './utils/permissionUtils';
import { PresenceProvider } from '@/contexts/ActivityContext';
import { ThemeProvider } from '@/contexts/ThemeContext';
import { User } from '@/types/common';
import { Loading } from "@/components/ui/loading";
import { routerConfig } from './configs/router';
import { LanguageProvider } from '@/contexts/LanguageContext';
import { UserProvider } from '@/contexts/UserContext';
import { Toaster } from "@/components/ui/sonner"
import { TooltipProvider } from "@/components/ui/tooltip"

// Your components
import Login from './pages/Login';
import { Pages } from './pages/Pages';
import CompleteRegistration from './pages/CompleteRegistration';
import OverlayHub from './overlays/OverlayHub';
import MainMenu from './pages/MainMenu';
import DigiDashboard from './digi-tal-dashboard/Digi-Dashboard';
import Bot from './digi-tal-bot/Digi-Regnskabsbot';
import Officebot from './tools/officebot/Officebot';
import LinkedinBot from './tools/gpts/LinkedinBot';
import AdminDashboard from './admin/AdminDashboard';
import ProtectedRoute from './components/ProtectedRoute';

// Onboarding
import OnboardingFlowEditor from './onboardingflow/onboarding/OnboardingFlowEditor';
//import AutomationFlowEditor from './onboardingflow/AutomationFlowEditor';
import OnboardingFlow from './onboardingflow/OnboardingFlow';

function AppContent() {
  const [user, setUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [isVerifying, setIsVerifying] = useState(true);

  useEffect(() => {
    const verifySession = async () => {
      console.log('Verifying session...');
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');
      const storedUser = localStorage.getItem('user');

      console.log('Session verification state:', {
        hasToken: !!token,
        hasRefreshToken: !!refreshToken,
        hasStoredUser: !!storedUser
      });

      if (!token || !refreshToken || !storedUser) {
        setUser(null);
        setIsVerifying(false);
        return;
      }

      // In development, just use stored data
      if (process.env.NODE_ENV === 'development') {
        console.log('Development mode: Using stored credentials');
        setUser(JSON.parse(storedUser));
        setIsVerifying(false);
        return;
      }

      // Production verification
      try {
        const response = await axios.post('/auth/verify-token', {
          token,
          refresh_token: refreshToken
        });

        console.log('Token verification response:', response.data);

        if (response.data.valid) {
          localStorage.setItem('token', response.data.access_token);
          localStorage.setItem('refreshToken', response.data.refresh_token);
          if (response.data.user) {
            localStorage.setItem('user', JSON.stringify(response.data.user));
            setUser(response.data.user);
          } else {
            setUser(JSON.parse(storedUser));
          }
        } else {
          console.log('Token verification failed, clearing auth data');
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('user');
          setUser(null);
        }
      } catch (error) {
        console.error('Token verification error:', error);
        if (process.env.NODE_ENV === 'development') {
          // In development, keep using stored data even if verification fails
          setUser(JSON.parse(storedUser));
        } else if (isAxiosError(error) && error.response?.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('user');
          setUser(null);
        }
      } finally {
        setIsVerifying(false);
      }
    };

    verifySession();
  }, []);

  // Add a guard for routes that require user
  const renderProtectedRoute = (
    Component: React.ComponentType<any>,
    permission: string
  ) => {
    return (
      <ProtectedRoute requiredPermission={permission}>
        {user ? <Component user={user} /> : <Navigate to="/login" />}
      </ProtectedRoute>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <ConfigProvider>
          <MessageProvider>
            <TimeTrackerProvider>
              <PresenceProvider>
                <AntApp>
                  <div className="App" data-theme={theme}>
                    <Outlet />
                    <OverlayHub />
                  </div>
                </AntApp>
              </PresenceProvider>
            </TimeTrackerProvider>
          </MessageProvider>
        </ConfigProvider>
      ),
      children: [
        {
          path: "",
          element: <Login setUser={setUser} />
        },
        {
          path: "login",
          element: <Login setUser={setUser} />
        },
        {
          path: "complete-registration",
          element: <ProtectedRoute><CompleteRegistration /></ProtectedRoute>
        },
        {
          path: "main-menu",
          element: renderProtectedRoute(MainMenu, "user")
        },
        {
          path: "digi-dashboard",
          element: renderProtectedRoute(DigiDashboard, "admin")
        },
        {
          path: "bot",
          element: renderProtectedRoute(Bot, "user")
        },
        {
          path: "officebot",
          element: renderProtectedRoute(Officebot, "user")
        },
        {
          path: "custom-gpts",
          element: (
            <ProtectedRoute requiredPermission="admin">
              <LinkedinBot />
            </ProtectedRoute>
          )
        },
        {
          path: "admin",
          element: renderProtectedRoute(AdminDashboard, "owner")
        },
        {
          path: "flows/onboarding",
          element: renderProtectedRoute(OnboardingFlowEditor, "admin")
        },
        {
          path: "/customers/onboarding/flow/:dealId/:onboardingId",
          element: <OnboardingFlow />
        },
        {
          path: "*",
          element: renderProtectedRoute(Pages, "user")
        }
      ]
    }
  ], routerConfig);

  if (isVerifying) {
    return <Loading message="Loading App..." />;
  }

  return (
    <>
      <RouterProvider router={router} />
      <Toaster />
    </>
  );
}

function App() {
  return (
    <ThemeProvider>
      <UserProvider>
        <LanguageProvider>
          <TooltipProvider>
            <AppContent />
          </TooltipProvider>
        </LanguageProvider>
      </UserProvider>
    </ThemeProvider>
  );
}
export default App;

