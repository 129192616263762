import React from 'react';
import { toast } from "sonner"
import { Button } from "@/components/ui/button"

interface NotificationToastProps {
  title: string
  message: string
  navigation?: {
    route: string
    params?: Record<string, string | number>
  }
  onOpenNotifications?: () => void
  onNavigate?: (route: string, params?: Record<string, string | number>) => void
}

function NotificationToast(props: NotificationToastProps) {
  const handleNavigation = () => {
    if (!props.navigation || !props.onNavigate) return;
    props.onNavigate(props.navigation.route, props.navigation.params);
  };

  return (
    <div className="grid gap-1">
      <div className="font-semibold">{props.title}</div>
      <div className="text-sm text-muted-foreground">{props.message}</div>
      <div className="flex items-center gap-2 mt-2">
        {props.navigation && props.onNavigate && (
          <Button
            variant="secondary"
            size="sm"
            onClick={handleNavigation}
          >
            View
          </Button>
        )}
        <Button
          variant="ghost"
          size="sm"
          onClick={() => {
            if (props.onOpenNotifications) {
              props.onOpenNotifications();
            }
          }}
        >
          Open Notifications
        </Button>
      </div>
    </div>
  );
}

export function showNotificationToast(props: NotificationToastProps) {
  toast(
    <NotificationToast {...props} />,
    {
      duration: 5000,
      className: "notification-toast",
      // Custom styling for notification toasts
      style: {
        background: "var(--background)",
        border: "1px solid var(--border)",
        color: "var(--foreground)",
      },
    }
  )
} 