import React from 'react';
import { 
  NodeElement, 
  ElementConfig, 
  VisibilityCondition,
  InputConfig as InputConfigType,
  ButtonConfig as ButtonConfigType,
  TextConfig as TextConfigType,
  SelectConfig as SelectConfigType,
  DealDataConfig as DealDataConfigType,
  TaskConfigType
} from '@/onboardingflow/types/node_types';
import { InputConfig } from './InputConfig';
import { ButtonConfig } from './ButtonConfig';
import { TextConfig } from './TextConfig';
import { SelectConfig } from './SelectConfig';
import { DealDataConfig } from './DealDataConfig';
import { TaskConfig } from './TaskConfig';
import { DealDataField } from '@/configs/DealDataConfig';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { PlusCircle, Trash2 } from 'lucide-react';
import { OnboardingEndpoint } from "@/onboardingflow/types/endpoints";

interface VisibilityConditionsSectionProps {
  conditions: VisibilityCondition[];
  onChange: (conditions: VisibilityCondition[]) => void;
  localConfig: {
    rows: {
      id: string;
      elements: NodeElement[];
    }[];
  };
  dealDataConfig: DealDataField[];
}

function VisibilityConditionsSection({ conditions, onChange, localConfig, dealDataConfig }: VisibilityConditionsSectionProps) {
  const handleAddCondition = () => {
    const newCondition: VisibilityCondition = {
      type: 'elementValue',
      elementId: '',
      condition: 'equals',
      value: ''
    };
    onChange([...(conditions || []), newCondition]);
  };

  const handleUpdateCondition = (index: number, updates: Partial<VisibilityCondition>) => {
    const newConditions = [...conditions];
    newConditions[index] = { ...newConditions[index], ...updates };
    onChange(newConditions);
  };

  const handleRemoveCondition = (index: number) => {
    const newConditions = conditions.filter((_, i) => i !== index);
    onChange(newConditions);
  };

  // Get all elements from the current node
  const allElements = localConfig.rows.flatMap(row => 
    row.elements.filter(el => el.type === 'input' || el.type === 'select')
  );

  return (
    <div className="space-y-4 border-t pt-4 mt-4">
      <div className="flex items-center justify-between mb-4">
        <div>
          <Label>Visibility Conditions</Label>
          <p className="text-sm text-muted-foreground">
            Configure when this element should be visible based on other elements' values or deal data.
          </p>
        </div>
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={handleAddCondition}
        >
          <PlusCircle className="w-4 h-4 mr-1" />
          Add Condition
        </Button>
      </div>
      <div className="space-y-4">
        {conditions.map((condition, index) => (
          <div key={index} className="border rounded-lg p-4 space-y-4">
            <div className="flex justify-between items-center">
              <Label>Condition {index + 1}</Label>
              <Button
                type="button"
                variant="ghost"
                size="icon"
                onClick={() => handleRemoveCondition(index)}
              >
                <Trash2 className="w-4 h-4" />
              </Button>
            </div>
            <div className="space-y-4">
              <div>
                <Label>Condition Type</Label>
                <Select
                  value={condition.type || 'elementValue'}
                  onValueChange={(value) => handleUpdateCondition(index, { type: value as 'elementValue' | 'dealData' })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select condition type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="elementValue">Element Value</SelectItem>
                    <SelectItem value="dealData">Deal Data</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              {condition.type === 'elementValue' ? (
                <div>
                  <Label>Element</Label>
                  <Select
                    value={condition.elementId}
                    onValueChange={(value) => handleUpdateCondition(index, { elementId: value })}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select element" />
                    </SelectTrigger>
                    <SelectContent>
                      {allElements.map((element) => (
                        <SelectItem key={element.id} value={element.id}>
                          {(element.config as any).label || 'Unnamed Element'}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              ) : (
                <div>
                  <Label>Deal Data Field</Label>
                  <Select
                    value={condition.elementId}
                    onValueChange={(value) => handleUpdateCondition(index, { elementId: value })}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select deal data field" />
                    </SelectTrigger>
                    <SelectContent>
                      {dealDataConfig.map((field) => (
                        <SelectItem key={field.key} value={field.key}>
                          {field.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}

              <div>
                <Label>Condition</Label>
                <Select
                  value={condition.condition}
                  onValueChange={(value) => handleUpdateCondition(index, { condition: value as 'equals' | 'notEquals' | 'hasValue' | 'isEmpty' })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select condition" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="equals">Equals</SelectItem>
                    <SelectItem value="notEquals">Not Equals</SelectItem>
                    <SelectItem value="hasValue">Has Value</SelectItem>
                    <SelectItem value="isEmpty">Is Empty</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              {(condition.condition === 'equals' || condition.condition === 'notEquals') && (
                <div>
                  <Label>Value</Label>
                  <Input
                    value={condition.value || ''}
                    onChange={(e) => handleUpdateCondition(index, { value: e.target.value })}
                    placeholder="Value"
                  />
                  <p className="text-sm text-muted-foreground mt-1">
                    Enter the value that the element should have for this element to be visible.
                  </p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

interface ElementConfigRendererProps {
  element: NodeElement;
  elementId: string;
  onChange: (elementId: string, updates: Partial<ElementConfig>) => void;
  availableEndpoints: OnboardingEndpoint[];
  localConfig: {
    rows: {
      id: string;
      elements: NodeElement[];
    }[];
  };
  dealDataConfig: DealDataField[];
}

export function ElementConfigRenderer({ 
  element, 
  elementId, 
  onChange,
  availableEndpoints,
  localConfig,
  dealDataConfig 
}: ElementConfigRendererProps) {
  const handleVisibilityChange = (conditions: VisibilityCondition[]) => {
    onChange(elementId, { visibilityConditions: conditions });
  };

  const handleSelectChange = (updates: Partial<SelectConfigType>) => {
    const newConfig = { ...element.config, ...updates } as SelectConfigType;
    onChange(elementId, { type: 'select', config: newConfig });
  };

  const handleInputChange = (updates: Partial<InputConfigType>) => {
    const newConfig = { ...element.config, ...updates } as InputConfigType;
    onChange(elementId, { type: 'input', config: newConfig });
  };

  const handleButtonChange = (updates: Partial<ButtonConfigType>) => {
    const newConfig = { ...element.config, ...updates } as ButtonConfigType;
    onChange(elementId, { type: 'button', config: newConfig });
  };

  const handleTextChange = (updates: Partial<TextConfigType>) => {
    const newConfig = { ...element.config, ...updates } as TextConfigType;
    onChange(elementId, { type: 'text', config: newConfig });
  };

  const handleDealDataChange = (updates: Partial<DealDataConfigType>) => {
    const newConfig = { ...element.config, ...updates } as DealDataConfigType;
    onChange(elementId, { 
      type: 'dealData',
      config: newConfig,
      visibilityConditions: element.visibilityConditions
    });
  };

  const handleTaskConfigChange = (updates: Partial<TaskConfigType>) => {
    const newConfig = { ...element.config, ...updates } as TaskConfigType;
    onChange(elementId, { 
      type: 'taskConfig',
      config: newConfig,
      visibilityConditions: element.visibilityConditions
    });
  };

  const renderElementConfig = () => {
    switch (element.type) {
      case 'input':
        return (
          <InputConfig 
            config={element.config as InputConfigType} 
            elementId={elementId}
            dealDataConfig={Object.fromEntries(dealDataConfig.map(field => [field.key, field]))}
            onChange={handleInputChange} 
          />
        );
      case 'button':
        return (
          <ButtonConfig 
            config={element.config as ButtonConfigType} 
            elementId={elementId}
            onChange={handleButtonChange} 
            availableEndpoints={availableEndpoints}
          />
        );
      case 'text':
        return (
          <TextConfig 
            config={element.config as TextConfigType} 
            elementId={elementId}
            onChange={handleTextChange} 
          />
        );
      case 'select':
        return (
          <SelectConfig 
            config={element.config as SelectConfigType} 
            elementId={elementId}
            dealDataConfig={dealDataConfig}
            onChange={handleSelectChange} 
          />
        );
      case 'dealData':
        return (
          <DealDataConfig
            config={element.config as DealDataConfigType}
            elementId={elementId}
            onChange={handleDealDataChange}
          />
        );
      case 'taskConfig':
        return (
          <TaskConfig
            config={element.config as TaskConfigType}
            elementId={elementId}
            onChange={handleTaskConfigChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="space-y-6">
      {renderElementConfig()}
      <VisibilityConditionsSection 
        conditions={element.visibilityConditions || []}
        onChange={handleVisibilityChange}
        localConfig={localConfig}
        dealDataConfig={dealDataConfig}
      />
    </div>
  );
} 